import { Cancel, Edit, Save } from "@mui/icons-material";
import {
  FormControl,
  IconButton,
  InputLabel,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import TaskService from "../../../../../services/TaskService";
import { useTranslation } from "react-i18next";

export const TaskNameModal = ({ data, refreshData, removeFromStore }) => {
  const { t } = useTranslation();
  const [taskName, setTaskName] = useState("");
  const [showEditName, setShowEditName] = useState(false);
  useEffect(() => {
    if (data) {
      setTaskName(data.taskName);
    }
  }, [data]);

  return (
    <>
      <div className="taskTitleContrct">
        <div className="taskContainEditBtnGrp">
          {!showEditName && <div className="taskTitleNM">{data.taskName}</div>}
          {showEditName && (
            <div className="formElement">
              <FormControl className="formControl pRelative">
                <TextField
                  label={t("taskName")}
                  required
                  variant="outlined"
                  className="descptionTextFild"
                  // multiline
                  // rows={4}
                  // maxRows={7}
                  value={taskName}
                  onChange={(e) => setTaskName(e.target.value)}
                />
              </FormControl>
            </div>
          )}
          {data.incoming === "N" && (
            <Tooltip arrow title={t("edit_description")}>
              <IconButton
                className="editTimelineBtn"
                onClick={() => {
                  setShowEditName((prev) => !prev);
                }}
              >
                {!showEditName ? <Edit /> : <Cancel />}
              </IconButton>
            </Tooltip>
          )}
          {data.incoming === "N" && showEditName && (
            <Tooltip arrow title={t("save_description")}>
              <IconButton
                className="editTimelineBtn"
                onClick={() => {
                  console.log("element saving");
                  TaskService.updateTaskName(data.taskId, taskName || "").then(
                    (response) => {
                      if (response.data != 0) {
                        toast.success(t("task_name_updated"));
                        refreshData();
                        removeFromStore();
                      } else {
                        toast.error(t("task_name_could_not_be_updated"));
                      }
                    }
                  );
                }}
              >
                <Save />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    </>
  );
};
