import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import "../css/chat.css";
import ChatContactList from "./ChatContactList";
import ChatConversation from "./ChatConversation";
import CreateChatGroupDetails from "./CreateChatGroupDetails";
import CreateNewChat from "./CreateNewChat";

import { chatSocket } from "../../../socket";
import { GetLoginUserDetails } from "../../../utils/Utils";

let selectedContactRef = null;
let unreadMessageCount = 0;

export default function ChatContainer() {
  const loginUser = GetLoginUserDetails();
  const [selectedContact, setSelectedContact] = useState(null);
  const [refreshContactCount, setRefreshContactCount] = useState(0);
  const [newChatSteps, setNewChatSteps] = useState(0);

  const [onlineUserList, setOnlineUserList] = useState([]);
  const [newMessageObj, setNewMessageObj] = useState(null);
  const [deleteMessageObj, setDeleteMessageObj] = useState(null);

  useEffect(() => {
    chatSocket.emit("joinChatUser", { userId: loginUser.userId });
    chatSocket.emit("requestOnlineUser");
    setInterval(() => chatSocket.emit("requestOnlineUser"), 30000);

    chatSocket.on("responseOnlineUser", (data) => setOnlineUserList(data));
    chatSocket.on("receiveChat", (msg) => setNewMessageObj(msg));
    chatSocket.on("responseDelete", (data) => setDeleteMessageObj(data));

    return () => {
      chatSocket.off("receiveChat");
    };
  }, [chatSocket]);

  // on receive new message start
  useEffect(() => {
    if (newMessageObj != null) {
      if (
        selectedContactRef &&
        selectedContactRef.chatId === newMessageObj.chatId
      ) {
        unreadMessageCount = unreadMessageCount + 1;
      }
      setRefreshContactCount((r) => r + 1);
    }
  }, [newMessageObj]);
  // on receive new message end

  // on delete message start
  useEffect(() => {
    if (deleteMessageObj != null) {
      setRefreshContactCount((r) => r + 1);
    }
  }, [deleteMessageObj]);
  // on delete message end

  useEffect(() => {
    if (selectedContact) {
      unreadMessageCount = selectedContact.unreadChatCount;
      selectedContactRef = selectedContact;
    }
  }, [selectedContact]);

  return (
    <>
      <Box className="chatContainerArea" sx={{ bgcolor: "dark.pageBg" }}>
        <Grid container spacing={0} className="chatContainArea">
          <Grid
            item
            xs={4}
            className={`lftChatGrid ${selectedContact ? "showLftgrid" : ""}`}
          >
            {newChatSteps === 0 ? (
              <ChatContactList
                selectedContact={selectedContact}
                setSelectedContact={setSelectedContact}
                refreshContactCount={refreshContactCount}
                onClickNewChat={() => setNewChatSteps(1)}
                onlineUserList={onlineUserList}
              />
            ) : newChatSteps === 1 ? (
              <CreateNewChat
                onClickBack={() => setNewChatSteps(0)}
                onClickCreateGroup={() => setNewChatSteps(2)}
                onSelectUser={setSelectedContact}
                onlineUserList={onlineUserList}
              />
            ) : (
              <CreateChatGroupDetails
                onClickBack={(step) => setNewChatSteps(step)}
              />
            )}
          </Grid>

          <Grid
            item
            xs={8}
            className={`rftChatGrid  ${selectedContact ? "showRftgrid" : ""}`}
          >
            {selectedContact ? (
              <ChatConversation
                selectedContact={selectedContact}
                setSelectedContact={setSelectedContact}
                refreshContacts={() => setRefreshContactCount((r) => r + 1)}
                onlineUserList={onlineUserList}
                newMessageObj={newMessageObj}
                setNewMessageObj={setDeleteMessageObj}
                deleteMessageObj={deleteMessageObj}
                setDeleteMessageObj={setDeleteMessageObj}
                unreadMessageCount={unreadMessageCount}
              />
            ) : (
              <div className="chatConversationPannel"></div>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
