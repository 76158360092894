import { AttachFile, Description } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import dayjs from "dayjs";
import { Microphone } from "iconoir-react";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
// import ReactDatePicker from "react-datepicker";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { CustomConfirmation } from "../../../common/CustomConfirmation";
import RecordAudio from "../../../common/RecordAudio";
import DashboardServices from "../../../services/DashboardServices";
import { createEvent } from "../../../services/SchedulerService";
import TaskService from "../../../services/TaskService";
import Loaders from "../../../utils/Loaders";
import { convertBlobToBase64, GetLoginUserDetails } from "../../../utils/Utils";
import ConvenientTimeView from "../../scheduler/view/component/calendar/ConvenientTimeView";
import RightFlotingContainer from "./component/RightFlotingContainer";
import RightFlotingForm from "./component/RightFlotingForm";
import TaskUploadFile from "./component/TaskUploadFile";
import { enIN, es } from "date-fns/locale";
import Joyride from "react-joyride";
import { TourContext } from "../../../common/TourProvider";

// Register locales with ReactDatePicker
registerLocale("en", enIN);
registerLocale("es", es);

export default function CreateTaskContainer({ suggestedTaskMeetingSummary }) {
  console.log(
    "suggestedTaskMeetingSummary--->>>>",
    suggestedTaskMeetingSummary
  );
  const { t, i18n } = useTranslation();

  const [activityInput, setActivityInput] = useState("");
  const [showFlotingContainer, setFlotingContainer] = useState(false);
  const [contract, setContract] = useState("");
  const [contractList, setContractList] = useState([]);

  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  // Determine the locale based on the current language
  const currentLocale = i18n.language === "es" ? "es" : "en";

  const [activityList, setActivityList] = useState([]);
  const [taskName, setTaskName] = useState("");
  const [taskDescription, setTaskDescription] = useState("");
  const [activity, setActivity] = useState(null);
  const [userList, setUserList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const loginUserDetail = GetLoginUserDetails();
  const [taskType, setTaskType] = useState("task");
  const [recommendedTemplateList, setRecommendedTemplateList] = useState([]);
  const [addedTemplateList, setAddedTemplateList] = useState([]);
  const [tempOfOrgList, setTemplateOfOrgList] = useState([]);
  const [showFileUploadDialog, setShowFileUploadDialog] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [showRecAudio, setShowRecAudio] = React.useState(false);

  const tempAudioChunk = useRef([]);
  const [recordingLength, setRecordingLength] = useState(0);
  const [recordingLink, setRecordingLink] = useState();
  const [timeSlots, setTimeSlots] = useState([]);
  const [fifteenMinTimeSlot, setFifteenMinTimeSlot] = useState([]);
  const [completion, setCompletion] = useState(new Date());

  const [compTime, setCompTime] = useState("20:00");
  const [priority, setPriority] = useState("0");
  const [autocompleteInputValue, setAutocompleteInputValue] = useState("");
  const [showExpanditureForm, setShowExpenditureForm] = useState(false);

  const [loading, setLoading] = useState(false);

  const [currentWeek, setCurrentWeek] = React.useState([]);
  const [slotDuration, setSlotDuration] = React.useState(15);
  const [startTime, setStartTime] = React.useState(0); //0 for 12:00 AM
  const [endTime, setEndTime] = React.useState(24); //23 for 11:00 PM
  const [rows, setRows] = React.useState([
    ...Array((endTime - startTime) * (60 / slotDuration)).keys(),
  ]); //23 for 11:00 PM
  const [cols, setCols] = React.useState([...Array(currentWeek.length).keys()]); //23 for 11:00 PM
  const [weekStartDay, setWeekStartDay] = React.useState(1);

  const [confirmObj, setConfirmObj] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedObservers, setSelectedObservers] = useState([]);
  const [observerInputValue, setObserverInputValue] = useState("");

  const [defaultWorkWeek, setDefaultWorkWeek] = React.useState([
    {
      id: 1,
      day: `Monday`,
    },
    {
      id: 2,
      day: `Tuesday`,
    },
    {
      id: 3,
      day: `Wednesday`,
    },
    {
      id: 4,
      day: `Thursday`,
    },
    {
      id: 5,
      day: `Friday`,
    },
  ]);

  const [selectedConvenientTime, setSelectedConvenientTime] = useState(null);
  const [hideForm, setHideForm] = useState(false);

  const [meetingStartTime, meetingEndTime] = useMemo(() => {
    let time = new Date();
    let minute = time.getMinutes();
    let hour = time.getHours();
    if (minute < 15) {
      minute = 15;
    } else if (minute < 30) {
      minute = 30;
    } else if (minute < 45) {
      minute = 45;
    } else {
      minute = 0;
      hour = (hour + 1) % 24;
    }
    time.setHours(hour);
    time.setMinutes(minute);

    const meetingEndTime = new Date(time.getTime() + 30 * 60000);
    return [time, meetingEndTime];
  }, []);

  const getFutureDate = (years = 2) => {
    const today = new Date();
    today.setFullYear(today.getFullYear() + years);
    return today;
  };

  const [showConvenientMeeting, setShowConvenientMeeting] = useState(false);

  const [formData, setFormData] = useState(null);

  useEffect(() => {
    if (formData) {
      handleCreateTask(formData);
    }
  }, [formData]);

  useEffect(() => {
    const selectedDate = new Date();

    let day = selectedDate.getDay();
    let diff = null;
    if (weekStartDay > day) {
      diff =
        selectedDate.getDate() - day - (day === 10 ? -6 : 7 - weekStartDay);
    } else {
      diff = selectedDate.getDate() - day + (day === 10 ? -6 : weekStartDay);
    }
    let currentWeekTemp2 = [];
    let tempDate = new Date(new Date());
    currentWeekTemp2.push(new Date(tempDate.setDate(diff)));
    for (let counter = 0; counter < 6; counter++) {
      var date = new Date(currentWeekTemp2[counter]);
      date.setDate(currentWeekTemp2[counter].getDate() + 1);
      currentWeekTemp2.push(date);
    }
    setCurrentWeek(currentWeekTemp2);
    setCols([...Array(currentWeekTemp2.length).keys()]);
  }, []);

  const handleRecurringIndentChange = () => {
    setShowConvenientMeeting((prev) => !prev);
  };

  const closeAllModals = () => {
    setShowFileUploadDialog(false);
    setFlotingContainer(false);
  };

  const computedList = useMemo(() => {
    let filtered = [];
    if (
      autocompleteInputValue.length > 0 &&
      autocompleteInputValue.trim() !== ""
    ) {
      filtered = userList.filter((option) =>
        option.userName
          .toLowerCase()
          .includes(autocompleteInputValue.toLowerCase())
      );
    }

    return filtered;
  }, [autocompleteInputValue, userList]);

  const filteredObserversList = useMemo(() => {
    let filteredList = [];
    if (taskType === "task") {
      filteredList = userList?.filter((user) => {
        // return item;
        return !selectedUsers?.some(
          (recipient) => recipient.userId === user.userId
        );
      });
    } else {
      if (!selectedUsers) {
        return userList;
      } else {
        filteredList = userList?.filter((user) => {
          return !(selectedUsers?.userId === user.userId);
        });
      }
      // filteredList = [];
    }
    return filteredList;
  }, [userList, selectedUsers]);

  const getSuggestions = (inputValue, userList) => {
    // Replace this with your logic to fetch suggestions
    let filtered = [];
    if (inputValue.length > 0 && inputValue.trim !== "") {
      filtered = userList.filter((option) =>
        option.userName.toLowerCase().includes(inputValue.toLowerCase())
      );
    }

    return filtered;
    // return userList.filter((option) =>
    //   option.toLowerCase().includes(inputValue.toLowerCase())
    // );
  };

  const handleChangeObserverAutoCompleteInputValue = (event, newInputValue) => {
    setObserverInputValue(newInputValue);
  };

  const taskDebounce = (cb, delay) => {
    let timer;
    return (...args) => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  };

  const handelChangeTaskName = taskDebounce((e) => {
    const val = e.target.value;
    if (val === "") return;
    setTaskName(e.target.value);
    // ;
    TaskService.checkDuplicateTask(
      parseInt(activity.id),
      loginUserDetail.userId,
      val
    ).then((response) => {
      const resData = response.data;
      if (resData) {
        setConfirmObj({
          title: "Confirmation",
          message: `Same task named '${val}' '${
            (resData.taskStatus === "O" && `Ongoing`) ||
            (resData.taskStatus === "P" && `Pending`) ||
            (resData.taskStatus === "F" && `Assignee Raised Concern`) ||
            (resData.taskStatus === "D" && `Completed but not signed off`)
          }' given to '${resData.assignees}'. Do You Want To Continue?`,
        });
        setShowConfirm(true);
      }
    });
  }, 1000);

  const handleChangeAutoCompleteInputValue = (event, newInputValue) => {
    setAutocompleteInputValue(newInputValue);
  };
  const handleChangeActivityInput = (event, newInputValue) => {
    setActivityInput(newInputValue);
  };

  const initializeTask = () => {
    setContract("");
    setTaskName("");
    setTaskDescription("");
    setActivity(null);
    // setUserList([]);
    setTaskType("task");
    setRecommendedTemplateList([]);
    setAddedTemplateList([]);
    setTemplateOfOrgList([]);
    setDocuments([]);
    setRecordingLength(0);
    setCompTime("20:00");
    setCompletion(new Date());
    setSelectedUsers([]);
    setPriority("0");
    tempAudioChunk.current = [];
    setShowFileUploadDialog(false);
    handleSelectTemplate(null);
    setFlotingContainer(false);
    setLoading(false);
    setShowExpenditureForm(false);
    setSelectedObservers([]);
    setObserverInputValue("");
  };

  useEffect(() => {
    DashboardServices.getContracts(loginUserDetail.orgId).then((response) => {
      if (response.data) {
        setContractList(response.data);
      }
    });

    DashboardServices.getActivityList(loginUserDetail.orgId).then(
      (response) => {
        if (response.data) {
          setActivityList(response.data);
        }
      }
    );

    DashboardServices.getOrgEmployees(loginUserDetail.orgId).then(
      (response) => {
        setUserList(response.data);
      }
    );
    // initializeTask();

    setTimeSlots(generateTimeSlots(30));
    setFifteenMinTimeSlot(generateTimeSlots(15));
    setCompTime("20:00");
  }, []);

  function convert12to24(time12h) {
    const [time, period] = time12h.split(" ");

    let [hours, minutes] = time.split(":");

    // Convert hours to 24-hour format
    hours =
      period === "PM" && hours !== "12"
        ? parseInt(hours, 10) + 12
        : parseInt(hours, 10);

    // Ensure leading zeros for single-digit hours and minutes
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    // Return the time in 24-hour format
    return `${hours}:${minutes}`;
  }

  function generateTimeSlots(intervalMinutes) {
    const slots = [];
    const startDate = new Date();
    startDate.setHours(0, 0, 0, 0); // Set start time to midnight

    const slotsPerHour = 60 / intervalMinutes;

    for (let i = 0; i < 24 * slotsPerHour; i++) {
      const currentSlot = new Date(
        startDate.getTime() + i * intervalMinutes * 60000
      );
      const hours = currentSlot.getHours().toString().padStart(2, "0");
      const minutes = currentSlot.getMinutes().toString().padStart(2, "0");
      const timeString = `${hours}:${minutes}`;
      slots.push(timeString);
    }

    return slots;
  }

  const calculateTimeDifference = (startTime, endTime) => {
    // Assuming startTime and endTime are time strings in the format "hh:mm A"
    const format = " YYYY-MM-DD hh:mm A";
    const startDateTime = dayjs("2024-01-01 " + startTime, { format });
    const endDateTime = dayjs("2024-01-01 " + endTime, { format });

    // Calculate the time difference using dayjs().diff()
    const duration = endDateTime.diff(startDateTime, "minute"); // Specify 'minute' here

    return duration;
  };

  const getStartDate = (data) => {
    // Extract start date and time
    const startDate = dayjs(data.convStartDate);
    const startTime = dayjs(
      `${startDate.format("YYYY-MM-DD")} ${data.convFromTime}`,
      { format: "YYYY-MM-DD hh:mm A" }
    );

    return startDate;
  };

  const shouldDisable = (complTime, time) => {
    const now = new Date();
    const [hours, minutes] = time.split(":").map(Number);
    complTime.setHours(hours, minutes, 0, 0);
    if (now - complTime > 0) {
      return true;
    }
    return false;
  };

  const handleSelectTemplate = (e) => {
    if (activity && activity != "") {
      DashboardServices.getAllTemplatesOfOrganization(
        loginUserDetail.orgId
      ).then((response) => {
        setTemplateOfOrgList(response.data);
      });
      DashboardServices.getTemplatesOfSubActivity(activity.id, 0).then(
        (response) => {
          let notaddedTemplateList = [];
          if (response.data != null && response.data.length > 0) {
            response.data.forEach((template) => {
              let isTemplateAdded = false;
              addedTemplateList.forEach((addedTemplate) => {
                if (template.templateId === addedTemplate.templateId) {
                  isTemplateAdded = true;
                }
              });
              if (!isTemplateAdded) {
                notaddedTemplateList.push(template);
              }
            });
            setRecommendedTemplateList(notaddedTemplateList);
          } else if (response.data && response.data.length === 0) {
            setRecommendedTemplateList([]);
          }
          if (e) {
            setFlotingContainer(true);
          }
        }
      );
    } else {
      toast.error(t("please_select_activity"));
    }
  };

  const handleAudio = async (combinedBlob) => {
    return new Promise((resolve, reject) => {
      let audioFileDataBase64;
      if (tempAudioChunk.current.length > 0) {
        // const combinedTempBlob = getSingleBlob()
        const audioBlob = new Blob(tempAudioChunk.current, {
          type: "audio/wav",
        });
        if (audioBlob) {
          convertBlobToBase64(audioBlob)
            .then((base64Data) => {
              audioFileDataBase64 = base64Data;
              resolve(audioFileDataBase64);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          resolve(audioFileDataBase64);
        }
      } else {
        resolve(audioFileDataBase64);
      }
    });
  };

  const getSingleBlob = () => {
    const promises = [];

    for (const blob of tempAudioChunk.current) {
      const promise = blob.arrayBuffer();
      promises.push(promise);
    }

    Promise.all(promises)
      .then((arrayBuffers) => {
        const concatenatedArrayBuffer = arrayBuffers.reduce((acc, buffer) => {
          const totalLength = acc.byteLength + buffer.byteLength;
          const combined = new Uint8Array(totalLength);
          combined.set(new Uint8Array(acc), 0);
          combined.set(new Uint8Array(buffer), acc.byteLength);
          return combined.buffer;
        }, new ArrayBuffer(0));

        const combinedBlob = new Blob([concatenatedArrayBuffer], {
          type: "audio/wav",
        });
        tempAudioChunk.current = [combinedBlob];
      })
      .catch((error) => {
        console.error("Error occurred while combining Blobs:", error);
      });
  };

  const handleShowRecAudioClose = () => {
    if (tempAudioChunk.current && tempAudioChunk.current.length > 0) {
      getSingleBlob();
    }
    setShowRecAudio(false);
  };

  const handleCreateTask = async (data) => {
    let isValidForm = true;
    if (!activity) {
      toast.error(t("please_select_activity"));
      isValidForm = false;
    }
    if (compTime === null) {
      toast.error(t("please_select_completion_time"));
      isValidForm = false;
    }
    if (taskType === "task" && taskName.trim() === "") {
      toast.error(t("please_enter_a_task_name"));
      isValidForm = false;
    }
    if (taskType === "task" && selectedUsers.length == 0) {
      toast.error(t("please_select_user"));
      isValidForm = false;
    }
    if (taskType !== "task" && selectedUsers === null) {
      toast.error(t("please_select_user"));
      isValidForm = false;
    }
    if (
      taskDescription.trim() === "" &&
      (!tempAudioChunk.current || tempAudioChunk.current.length === 0)
    ) {
      toast.error(
        t("please_provide_a_task_description_or_task_description_audio")
      );
      isValidForm = false;
    }

    let tempPostPoneTill = completion;
    const [hours, minutes] = compTime.split(":").map(Number);
    tempPostPoneTill.setHours(hours, minutes, 0, 0);

    const now = new Date();
    if (now - tempPostPoneTill > 0) {
      toast.error(t("selected_time_already_passed"));
      isValidForm = false;
    }
    if (isValidForm === false) {
      return;
    }
    let taskValueName = "Approval Request";

    let assigneeList = [];
    if (taskType === "task") {
      assigneeList = selectedUsers.map((item) => item.userId);
    } else {
      assigneeList = [selectedUsers.userId];
    }
    let observerList = [];
    observerList = selectedObservers?.map((item) => item.userId);

    const submitDto = {
      approvalRequest: taskType === "task" ? "N" : "Y",
      contractId: parseInt(contract) || 0,
      taskMasterId: parseInt(activity.id),
      subTask: taskType === "task" ? taskName : taskValueName,
      scheduleCompletionTime: tempPostPoneTill.toISOString(),
      taskDescription: taskDescription,
      assigness: assigneeList,
      observers: observerList || [],
      documents: documents,
      previousTaskId: 0,
      priority: parseInt(priority),
    };

    console.log("dto =", submitDto);

    const audioFileDataBase64 = await handleAudio();
    if (audioFileDataBase64) {
      submitDto.audioFileDataBase64 = audioFileDataBase64;
    }
    setLoading(true);

    TaskService.createTask(
      loginUserDetail.userId,
      loginUserDetail.orgId,
      submitDto
    ).then((response) => {
      const retId = response.data.status;
      if (activity.id === 537) {
        submitTASK_FORM_0000000004(retId);
      }
      successfulSubmitCreateTask(response, submitDto);
    });
  };
  const successfulSubmitCreateTask = (response, submitDto) => {
    toast.success(t("task_created_successfully"), {
      position: toast.POSITION.TOP_RIGHT,
    });

    // if template added to the task then api call
    if (addedTemplateList != null && addedTemplateList.length > 0) {
      addedTemplateList.forEach((template) => {
        template.taskDetailId = parseInt(response.data.status);
      });
      DashboardServices.addTaskTemplate(
        loginUserDetail.userId,
        addedTemplateList,
        "NEW"
      ).then((response) => {
        if (response.data && response.data.returnValue === "1") {
          toast.success(t("template_added_successfully"));
        } else {
          toast.error(t("could_not_add_template"));
        }
      });
    }

    if (selectedConvenientTime) {
      const reqDto = {
        title: "Convenient Meeting",
        description: "",
        length: calculateTimeDifference(
          selectedConvenientTime.convFromTime,
          selectedConvenientTime.convToTime
        ),
        startTime: new Date(
          new Date(selectedConvenientTime.convStartDate).toDateString() +
            " " +
            selectedConvenientTime.convFromTime
        ),
        endTime: new Date(
          new Date(selectedConvenientTime.convStartDate).toDateString() +
            " " +
            selectedConvenientTime.convToTime
        ),
        location: " ",
        participants:
          taskType === "task"
            ? selectedUsers.map((user, index) => {
                return {
                  userId: user.userId,
                  userName: user.userName,
                  convener: index === 0 ? "Y" : "N",
                };
              })
            : {
                userId: selectedUsers.userId,
                userName: selectedUsers.userName,
                convener: "Y",
              },
      };

      createEvent(loginUserDetail.orgId, loginUserDetail.userId, reqDto).then(
        (response) => {
          if (response.data.returnValue === "1") {
            const status = response.data.status;
            TaskService.addEventToTask(
              response.status,
              status,
              loginUserDetail.userId
            ).then((res) => {
              if (res.data == 1) {
                toast.success(t("meeting_created_successfully"), {
                  position: toast.POSITION.TOP_RIGHT,
                });
              } else {
                toast.error(t("meeting_could_not_be_created"), {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            });
          } else {
            toast.error(t("meeting_could_not_be_created"));
          }
        }
      );
    }

    initializeTask();
  };

  const handleSubmitPurchaseForm = (data) => {
    const taskFormData = {
      approvalRequest: "Y",
      contractId: parseInt(contract),
      taskMasterId: parseInt(activity.id),
      subTask: "Approval Request",
      scheduleCompletionTime: new Date(),
      taskDescription: taskDescription,
      assigness: [data.expenseApprover],
      documents: [],
      previousTaskId: 0,
      priority: parseInt(priority),
    };

    TaskService.createTask(
      loginUserDetail.userId,
      loginUserDetail.orgId,
      taskFormData
    ).then((response) => {
      const retId = response.data.status;
      TaskService.expenseApprovalRequest(
        loginUserDetail.userId,
        loginUserDetail.orgId,
        retId,
        data
      ).then((approvalResponse) => {
        if (approvalResponse.data) {
          if (approvalResponse.data.returnValue === "1") {
            toast.success(t("expense_approval_request_successful"));
            setShowExpenditureForm(false);
            initializeTask();
          } else {
            toast.error(t("something_went_wrong"));
          }
        }
      });
      // successfulSubmitCreateTask(response, taskFormData);
    });
  };

  const handleSubmitPmsRequest = async (reqDto, approverId) => {
    const taskFormData = {
      approvalRequest: "Y",
      contractId: contract ? parseInt(contract) : "",
      taskMasterId: parseInt(activity.id),
      subTask: "Approval Request",
      scheduleCompletionTime: completion,
      taskDescription: taskDescription,
      assigness: [approverId],
      documents: [],
      previousTaskId: 0,
      priority: parseInt(priority),
    };
    TaskService.createTask(
      loginUserDetail.userId,
      loginUserDetail.orgId,
      taskFormData
    ).then((response) => {
      const taskDtlId = response.data.status;
      TaskService.pmsApprovalRequest(
        loginUserDetail.userId,
        loginUserDetail.orgId,
        taskDtlId,
        reqDto
      ).then((response2) => {
        if (response2.data) {
          if (response2.data.returnValue === "1") {
            toast.success(t("pms_approval_request_successs"));
            setShowExpenditureForm(false);
            initializeTask();
          } else {
            toast.error(t("something_went_wrong"));
          }
        }
      });
    });
  };

  const submitTASK_FORM_0000000004 = async (tDid) => {
    let retStatus = false;
    const reqDto = {
      purpose: formData.purpose,
      contractId: formData.contractId,
      costCenter: formData.costCenterId,
      billTo: formData.billToId,
      shipTo: formData.shipToId,
      scheduledDate: formData.scheduledDate,
      rmks: formData.rmks,
      items: formData.items,
      process: formData.process,
      recurringDocumentFileName:
        formData.recurringDument && formData.recurringDocumentFile.fileName,
      recurringDocumentFileData:
        formData.recurringDument && formData.recurringDocumentFile.fileDataLink,
      expenseRequestType: formData.expenseRequestType,
      tangible: formData.tangible,
      recurring: formData.recurring,
      recurringPeriod: formData.recurringPeriod,
      recurringUntil: formData.recurringUntil,
      estimatedCost: formData.estimatedCost,
    };
    await TaskService.raiseIndent(
      loginUserDetail.userId,
      loginUserDetail.orgId,
      tDid,
      reqDto
    ).then((response) => {
      if (response.data) {
        if (response.data.returnValue === "1") {
          retStatus = true;
          setFormData(null);
        } else {
          toast.error(t("something_went_wrong"));
        }
      }
    });
    return retStatus;
  };

  const selectContract = (event) => {
    setContract(event.target.value);
  };

  const selectActivity = (value) => {
    if (value == null) {
      setActivity(null);
      setShowExpenditureForm(false);
      return;
    }
    if (value.formId && value.formId !== "") {
      setShowExpenditureForm(true);
    } else {
      setShowExpenditureForm(false);
    }
    setActivity(value);
  };

  const selectTaskType = (event) => {
    const value = event.target.value;
    if (value === "task") {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(null);
    }
    setTaskType(value);
  };

  const selectCompTime = (event) => {
    setCompTime(event.target.value);
  };

  const selectPriority = (event) => {
    setPriority(event.target.value);
  };

  useEffect(() => {
    setBookSteps([
      {
        target: ".createTaskStepOne",
        content: "This is where you can create a new task or approval request.",
        disableBeacon: true,
      },
      {
        target: ".createTaskStepTwo",
        content: "Select the contract type from the dropdown list.",
        placement: "right",
        disableBeacon: true,
      },
      {
        target: ".createTaskStepThree",
        content: "Choose or type the activity for the task.",
        placement: "right",
        disableBeacon: true,
      },
      {
        target: ".createTaskStepFour",
        content: "Select whether this is a Task or an Approval Request.",
        placement: "left",
        disableBeacon: true,
      },
      {
        target: ".createTaskStepFive",
        content: "Pick a completion date for the task.",
        placement: "right",
        disableBeacon: true,
      },
      {
        target: ".createTaskStepSix",
        content: "Select the priority of the task.",
        placement: "bottom",
        disableBeacon: true,
      },
      {
        target: ".createTaskStepSeven",
        content: "Assign users who will work on this task.",
        placement: "top",
        disableBeacon: true,
      },
      {
        target: ".createTaskStepEight",
        content: "Observer users who look on this task.",
        placement: "top",
        disableBeacon: true,
      },
      {
        target: ".createTaskStepNine",
        content: "Enter Task Name For The Task.",
        placement: "top",
        disableBeacon: true,
      },
      {
        target: ".createTaskStepTen",
        content: "Enter Task Description For The Task.",
        placement: "top",
        disableBeacon: true,
      },
      {
        target: ".createTaskStepEleven",
        content: "Attach File For The Task",
        placement: "top",
        disableBeacon: true,
      },
      {
        target: ".createTaskStepTwelve",
        content: "Attach File For The Task",
        placement: "top",
        disableBeacon: true,
      },
      {
        target: ".createTaskStepThirteen",
        content:
          "Click here to submit the task after filling in all the details.",
        placement: "top",
        disableBeacon: true,
      },
    ]);
  }, []);

  useEffect(() => {
    console.log("suggestedTaskMeetingSummary 1", suggestedTaskMeetingSummary);
    if (!suggestedTaskMeetingSummary) {
      return;
    }

    if (suggestedTaskMeetingSummary?.activity && activityList.length === 0) {
      return;
    }

    if (
      suggestedTaskMeetingSummary?.recipients?.length > 0 &&
      userList.length === 0
    ) {
      return;
    }

    const activityDto = activityList.find(
      (item) => item.name === suggestedTaskMeetingSummary.activity
    );
    console.log("suggestedTaskMeetingSummary activity", activityDto);
    selectActivity(activityDto);

    if (suggestedTaskMeetingSummary.completion_time) {
      setCompTime(suggestedTaskMeetingSummary.completion_time);
    }
    if (suggestedTaskMeetingSummary.completion_date) {
      setCompletion(new Date(suggestedTaskMeetingSummary.completion_date));
    }
    if (suggestedTaskMeetingSummary.priority) {
      if ("routine" === suggestedTaskMeetingSummary.priority.toLowerCase()) {
        setPriority(0);
      }
      if ("important" === suggestedTaskMeetingSummary.priority.toLowerCase()) {
        setPriority(1);
      }

      if ("critical" === suggestedTaskMeetingSummary.priority.toLowerCase()) {
        setPriority(2);
      }
    }
    if (suggestedTaskMeetingSummary.task_name) {
      setTaskName(suggestedTaskMeetingSummary.task_name);
    }
    if (suggestedTaskMeetingSummary.task_description) {
      setTaskDescription(suggestedTaskMeetingSummary.task_description);
    }
    if (suggestedTaskMeetingSummary.recipients) {
      for (
        let index = 0;
        index < suggestedTaskMeetingSummary.recipients.length;
        index++
      ) {
        const userName = suggestedTaskMeetingSummary.recipients[index];
        const newValue = userList.find(
          (user) => user.userName.toLowerCase() === userName.toLowerCase()
        );
        console.log(
          "suggestedTaskMeetingSummary userDetails------Xxxxxxxxxxxx",
          newValue
        );
        if (!newValue || !newValue.userId) {
          return;
        }
        setSelectedUsers((prevUsers) => [...prevUsers, newValue]);
        if (newValue) {
          if (newValue instanceof Array && newValue.length === 0) {
            return;
          }

          let temp = [];

          if (taskType === "task") {
            temp = selectedObservers?.filter((user) => {
              // return !(item.userId === newValue.userId);
              return !newValue?.some(
                (recipient) => recipient.userId === user.userId
              );
            });
          } else {
            temp = selectedObservers?.filter((item) => {
              return !(item.userId === newValue.userId);
            });
          }

          setSelectedObservers(temp);
        }
      }
    }
  }, [activityList, userList]);

  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["addTaskTS"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        styles={{
          buttonClose: {
            display: "none",
          },
        }}
      />
      <Box
        className="createTaskContainerArea createTaskStepOne"
        sx={{ bgcolor: "dark.pageBg" }}
      >
        <Grid container spacing={0} className="createTaskContainArea">
          <Grid item xs={12} className="pRelative">
            {!showConvenientMeeting && (
              <div className="cerateTaskContainer">
                <div className="crtTaskHeader">
                  {taskType === "task"
                    ? t("create_task")
                    : t("create_approval_request")}
                </div>
                <div className="elementFormContainer">
                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel id="setContract">{t("contract")}</InputLabel>
                      <Select
                        className="formInputField createTaskStepTwo"
                        variant="outlined"
                        labelId="setContract"
                        id="contract-select"
                        value={contract}
                        label={t("contract")}
                        onChange={selectContract}
                      >
                        <MenuItem value={""}>
                          --{t("select_contract")}--
                        </MenuItem>
                        {contractList &&
                          contractList.length > 0 &&
                          contractList.map((item) => (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>

                  <div className="formElement">
                    <FormControl className="formControl">
                      <Autocomplete
                        noOptionsText={t("type_to_search")}
                        className="formAutocompleteField"
                        variant="outlined"
                        value={activity}
                        options={activityList}
                        getOptionLabel={(option) => option.name}
                        inputValue={activityInput}
                        onChange={(e, newValue) => {
                          selectActivity(newValue);
                        }}
                        renderOption={(props, item) => {
                          return (
                            <li {...props} key={item.name + item.id}>
                              {item.name}
                            </li>
                          );
                        }}
                        onInputChange={handleChangeActivityInput}
                        // getOptionSelected={(option) => {
                        //;
                        //   return option.userId;
                        // }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="formAutoComInputField autocompFildPlaceholder createTaskStepThree"
                            placeholder={t("activity")}
                            required
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </div>
                  {!(activity && activity.formId && activity.formId !== "") && (
                    <div className="formElement">
                      <FormControl className="formControl">
                        <RadioGroup
                          className="formRadioGroup createTaskStepFour"
                          labelId="setTasktype"
                          name="setTasktype"
                          value={taskType}
                          onChange={selectTaskType}
                        >
                          <FormControlLabel
                            className="formRadioField"
                            value="task"
                            control={<Radio />}
                            label={t("task")}
                          />
                          <FormControlLabel
                            className="formRadioField"
                            value="approvalRequest"
                            control={<Radio />}
                            label={t("approval_request")}
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  )}

                  {!(activity && activity.formId && activity.formId !== "") && (
                    <div className="formElementGroup">
                      <div className="formElement">
                        <FormControl className="formControl">
                          <InputLabel
                            id="setCompletionDate"
                            className="setCompletionDate"
                          >
                            {t("completion_date")}*
                          </InputLabel>
                          <ReactDatePicker
                            locale={currentLocale}
                            showIcon
                            toggleCalendarOnIconClick
                            labelId="setCompletionDate"
                            className="formDatePicker createTaskStepFive"
                            selected={completion}
                            minDate={new Date()}
                            maxDate={getFutureDate()}
                            dateFormat="dd/MM/yyyy"
                            onChange={(date) => {
                              setCompletion(date);
                            }}
                          />
                        </FormControl>
                      </div>
                      <div className="formElement">
                        <FormControl className="formControl">
                          <InputLabel id="compTime">
                            {t("completion_time")}*
                          </InputLabel>
                          <Select
                            className="formInputField"
                            variant="outlined"
                            labelId="compTime"
                            id="compTime-select"
                            value={compTime}
                            label="Completion Time"
                            onChange={selectCompTime}
                          >
                            {timeSlots &&
                              timeSlots.map((time) => (
                                <MenuItem
                                  value={time}
                                  disabled={shouldDisable(completion, time)}
                                >
                                  {time}
                                </MenuItem>
                              ))}
                            {/* <MenuItem value={"7:00AM"}>7:00AM</MenuItem>
                        <MenuItem value={"8:00AM"}> 8:00AM </MenuItem>
                        <MenuItem value={"9:00AM"}> 9:00AM </MenuItem> */}
                          </Select>
                        </FormControl>
                      </div>

                      <div className="formElement">
                        <FormControl className="formControl">
                          <InputLabel id="selectPriority">
                            {t("priority")}
                          </InputLabel>
                          <Select
                            className="formInputField createTaskStepSix"
                            variant="outlined"
                            labelId="selectPriority"
                            id="priority-select"
                            value={priority}
                            label={t("priority")}
                            onChange={selectPriority}
                          >
                            <MenuItem value={"0"}>{t("routine")}</MenuItem>
                            <MenuItem value={"1"}> {t("important")} </MenuItem>
                            <MenuItem value={"2"}> {t("critical")} </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  )}

                  {!(activity && activity.formId && activity.formId !== "") && (
                    <div className="formElement">
                      <FormControl className="formControl">
                        <Autocomplete
                          noOptionsText={t("please_type_recipient_name")}
                          multiple={taskType === "task" ? true : false}
                          className="formAutocompleteField"
                          variant="outlined"
                          value={selectedUsers}
                          options={computedList}
                          getOptionLabel={(option) => option.userName}
                          inputValue={autocompleteInputValue}
                          onChange={(e, newValue) => {
                            setSelectedUsers(newValue);
                            if (newValue) {
                              if (
                                newValue instanceof Array &&
                                newValue.length === 0
                              ) {
                                return;
                              }

                              let temp = [];

                              if (taskType === "task") {
                                temp = selectedObservers?.filter((user) => {
                                  // return !(item.userId === newValue.userId);
                                  return !newValue?.some(
                                    (recipient) =>
                                      recipient.userId === user.userId
                                  );
                                });
                              } else {
                                temp = selectedObservers?.filter((item) => {
                                  return !(item.userId === newValue.userId);
                                });
                              }

                              setSelectedObservers(temp);
                            }
                          }}
                          onInputChange={handleChangeAutoCompleteInputValue}
                          getOptionSelected={(option) => {
                            return option.userId;
                          }}
                          renderOption={(props, option) => {
                            const usrDtl = option.userDtl
                              ? `(${option.userDtl})`
                              : "";
                            return (
                              <Box
                                component="li"
                                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                {...props}
                              >
                                {`${option.userName} ${usrDtl}`}
                              </Box>
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              className="formAutoComInputField autocompFildPlaceholder createTaskStepSeven"
                              placeholder={
                                taskType === "task"
                                  ? t("assign_recipients") + "*"
                                  : t("assign_recipient") + "*"
                              }
                              InputProps={{
                                ...params.InputProps,
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </div>
                  )}

                  {!(activity && activity.formId && activity.formId !== "") && (
                    <div className="formElement">
                      <FormControl className="formControl">
                        <Autocomplete
                          noOptionsText={t("please_type_observer_name")}
                          // multiple={taskType === "task" ? true : false}
                          multiple={true}
                          className="formAutocompleteField"
                          variant="outlined"
                          value={selectedObservers}
                          options={filteredObserversList}
                          getOptionLabel={(option) => option.userName}
                          inputValue={observerInputValue}
                          onChange={(e, newValue) => {
                            setSelectedObservers(newValue);
                          }}
                          onInputChange={
                            handleChangeObserverAutoCompleteInputValue
                          }
                          getOptionSelected={(option) => {
                            return option.userId;
                          }}
                          renderOption={(props, option) => {
                            const usrDtl = option.userDtl
                              ? `(${option.userDtl})`
                              : "";
                            return (
                              <Box
                                component="li"
                                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                {...props}
                              >
                                {`${option.userName} ${usrDtl}`}
                              </Box>
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              className="formAutoComInputField autocompFildPlaceholder createTaskStepEight"
                              placeholder={
                                taskType === "task"
                                  ? t("assign_observers")
                                  : t("assign_observer")
                              }
                              InputProps={{
                                ...params.InputProps,
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </div>
                  )}

                  {taskType === "task" && selectedUsers.length > 1 && (
                    // || (taskType !== "task" && selectedUsers)
                    <div className="multiFormGroup">
                      <div className="formElement">
                        <FormControl className="formControl">
                          <FormGroup className="formRadioGroup">
                            <FormControlLabel
                              className="formRadioField"
                              control={
                                <Checkbox
                                  checked={showConvenientMeeting}
                                  onChange={handleRecurringIndentChange}
                                />
                              }
                              label={t("create_convenient_meeting")}
                            />
                          </FormGroup>
                        </FormControl>
                      </div>
                    </div>
                  )}

                  {!(activity && activity.formId && activity.formId !== "") &&
                    selectedConvenientTime && (
                      <div className="formElementGroup">
                        <div className="formElement">
                          <FormControl className="formControl">
                            <InputLabel
                              id="setCompletionDate"
                              className="setCompletionDate"
                            >
                              {t("meeting_date")}
                            </InputLabel>
                            <ReactDatePicker
                              locale={currentLocale}
                              toggleCalendarOnIconClick
                              showIcon
                              labelId="setCompletionDate"
                              className="formDatePicker"
                              selected={selectedConvenientTime.convStartDate}
                              minDate={new Date()}
                              dateFormat="dd/MM/yyyy"
                              onChange={(date) => {
                                setCompletion(date);
                              }}
                            />
                          </FormControl>
                        </div>
                        <div className="formElement">
                          <FormControl className="formControl">
                            <InputLabel id="compTime">
                              {t("meeting_start_time")}
                            </InputLabel>
                            <Select
                              className="formInputField"
                              variant="outlined"
                              labelId="compTime"
                              id="compTime-select"
                              value={convert12to24(
                                selectedConvenientTime.convFromTime
                              )}
                              label={t("completion_time")}
                              onChange={selectCompTime}
                            >
                              {fifteenMinTimeSlot &&
                                fifteenMinTimeSlot.map((time) => (
                                  <MenuItem
                                    value={time}
                                    disabled={shouldDisable(completion, time)}
                                  >
                                    {time}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </div>

                        <div className="formElement">
                          <FormControl className="formControl">
                            <InputLabel id="compTime">
                              {t("meeting_end_time")}
                            </InputLabel>
                            <Select
                              className="formInputField"
                              variant="outlined"
                              labelId="compTime"
                              id="compTime-select"
                              value={convert12to24(
                                selectedConvenientTime.convToTime
                              )}
                              label={t("completion_time")}
                              onChange={selectCompTime}
                            >
                              {fifteenMinTimeSlot &&
                                fifteenMinTimeSlot.map((time) => (
                                  <MenuItem
                                    value={time}
                                    disabled={shouldDisable(completion, time)}
                                  >
                                    {time}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    )}

                  {!(activity && activity.formId && activity.formId !== "") &&
                    taskType === "task" && (
                      <div className="formElement">
                        <FormControl className="formControl">
                          <TextField
                            label={t("taskName")}
                            required
                            variant="outlined"
                            className="formTextFieldArea createTaskStepNine"
                            value={taskName}
                            // onChange={(e) => setTaskName(e.target.value)}
                            onChange={(e) => {
                              setTaskName(e.target.value);
                              // handelChangeTaskName(e);
                            }}
                          />
                        </FormControl>
                      </div>
                    )}
                  {!(activity && activity.formId && activity.formId !== "") && (
                    <div className="formElement">
                      <FormControl className="formControl pRelative">
                        <TextField
                          label={t("description")}
                          required
                          variant="outlined"
                          className="descptionTextFild createTaskStepTen"
                          multiline
                          rows={4}
                          maxRows={7}
                          value={taskDescription}
                          onChange={(e) => setTaskDescription(e.target.value)}
                        ></TextField>
                        <Tooltip
                          className="audioNoteTooltip"
                          title={t("record_audio_note")}
                          arrow
                          placement="bottom"
                          style={{ right: "-15px" }}
                        >
                          <IconButton
                            onClick={() => setShowRecAudio(true)}
                            className={
                              tempAudioChunk.current.length > 0
                                ? `audioNote activeStatus`
                                : "audioNote"
                            }
                          >
                            <Microphone />
                          </IconButton>
                                
                        </Tooltip>
                      </FormControl>
                      {/* <Tooltip
                    title={t("record_audio_note")}
                    arrow
                    placement="bottom"
                    sx={{
                      position: "absolute",
                      top: 8,
                      right: 8,
                      fontSize: "small",
                    }}
                  >
                    <IconButton
                      onClick={() => setShowRecAudio(true)}
                      className={
                        tempAudioChunk.current.length > 0 ? `activeStatus` : ""
                      }
                    >
                      <Microphone />
                    </IconButton>
                  </Tooltip> */}
                    </div>
                  )}

                  {/* <div className="formBtnElement">
                  <Button
                    variant="contained"
                    component="label"
                    onClick={() => setShowRecAudio(true)}
                  >
                    <Tooltip
                      title={"record_audio_note"}
                      arrow
                      placement="bottom"
                    >
                      <Microphone />
                    </Tooltip>
                  </Button>
                </div> */}

                  <div className={`formBtnElement`}>
                    {!(
                      activity &&
                      activity.formId &&
                      activity.formId !== ""
                    ) && (
                      <Button
                        variant="outlined"
                        startIcon={<AttachFile />}
                        className={`comBtnOutline  ${
                          documents && documents.length > 0
                            ? "activeStatus"
                            : ""
                        } createTaskStepEleven`}
                        onClick={(e) => {
                          closeAllModals();
                          setShowFileUploadDialog(true);
                        }}
                        s
                      >
                        {t("attach_file")}
                      </Button>
                    )}

                    {!(
                      activity &&
                      activity.formId &&
                      activity.formId !== ""
                    ) && (
                      <Button
                        variant="outlined"
                        startIcon={<Description />}
                        className={`comBtnOutline  ${
                          addedTemplateList && addedTemplateList.length > 0
                            ? "activeStatus"
                            : ""
                        } createTaskStepTwelve`}
                        onClick={(e) => {
                          closeAllModals();
                          handleSelectTemplate(e);
                        }}
                      >
                        {t("select_template")}
                      </Button>
                    )}

                    {activity && activity.formId && activity.formId !== "" && (
                      <Button
                        variant="outlined"
                        startIcon={<Description />}
                        className={`comBtnOutline`}
                        onClick={(e) => {
                          closeAllModals();
                          setShowExpenditureForm(true);
                        }}
                      >
                        {t("view_form")}
                      </Button>
                    )}
                  </div>
                </div>

                <div className="formBtnElement ">
                  <Button
                    className="dfultPrimaryBtn createTaskStepThirteen"
                    onClick={handleCreateTask}
                  >
                    {t("submit")}
                  </Button>
                </div>
              </div>
            )}

            {showConvenientMeeting && (
              <ConvenientTimeView
                usersList={selectedUsers.map((user) => user.userId)}
                meetingStartTime={meetingStartTime}
                meetingEndTime={meetingEndTime}
                // meetingStartTime={new Date()}
                // meetingEndTime={new Date()}
                loginUser={loginUserDetail}
                startTime={startTime}
                endTime={endTime}
                rows={rows}
                slotDuration={slotDuration}
                cols={cols}
                defaultWorkWeek={defaultWorkWeek}
                viewForm={(data) => {
                  if (data === "select") {
                    setHideForm(false);
                  } else {
                    setHideForm(!hideForm);
                  }
                }}
                hideForm={hideForm}
                setSelectedConvenientTime={setSelectedConvenientTime}
                handleClose={() => setShowConvenientMeeting(false)}
                mode={"createTask"}
              />
            )}

            {showFlotingContainer && (
              <RightFlotingContainer
                recommendedTemplateList={recommendedTemplateList}
                setRecommendedTemplateList={setRecommendedTemplateList}
                tempOfOrgList={tempOfOrgList}
                addedTemplateList={addedTemplateList}
                setAddedTemplateList={setAddedTemplateList}
                closeTemplate={() => {
                  setFlotingContainer(false);
                }}
                mode={"new"}
              />
            )}

            {showFileUploadDialog && (
              <div className="RightFlotingContainerArea">
                <TaskUploadFile
                  documents={documents}
                  setDocuments={setDocuments}
                  onClose={(e) => {
                    setShowFileUploadDialog(false);
                  }}
                />
              </div>
            )}

            {showRecAudio && (
              <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
                <div className="addMoreAgendaModel">
                  <div className="modelTitle">{t("audio_message")}</div>
                  {/* <div className="modActionBtnGrp"></div> */}
                  <RecordAudio
                    closeDialog={() => handleShowRecAudioClose()}
                    tempAudioChunk={tempAudioChunk}
                    recordingLength={recordingLength}
                    setRecordingLength={setRecordingLength}
                    recordingLink={recordingLink}
                    setRecordingLink={setRecordingLink}
                  />
                </div>
              </Box>
            )}

            {showExpanditureForm && (
              <RightFlotingForm
                activity={activity}
                handleClose={() => setShowExpenditureForm(false)}
                updateFormData={setFormData}
                contract={contract}
                handleSubmitPurchaseForm={handleSubmitPurchaseForm}
                handleSubmitPmsRequest={handleSubmitPmsRequest}
              />
            )}

            {showConfirm && (
              <CustomConfirmation
                openModal={showConfirm}
                handleCloseModal={(e) => {
                  setShowConfirm(false);
                }}
                title={confirmObj.title}
                positiveButtonName={"Yes"}
                negativeButtonName={"No"}
                handlePositive={() => {}}
                handleNegative={() => {}}
              />
            )}

            {loading && <Loaders />}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
