import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleTheme } from "../redux/reducers/themeSlice";
import { useEffect } from "react";

export default function ToggleMode() {
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.theme.mode);

  useEffect(() => {
    document.body.className = themeMode;
  }, [themeMode]);
  return (
    <>
      <div
        className={`toggle-switch ${themeMode === "lightMode" ? "day" : ""}`}
        onClick={() => dispatch(toggleTheme())}
      >
        <div
          className={`toggle-circle ${
            themeMode === "lightMode" ? "lightMode moon sun" : "darkMode moon"
          }`}
        ></div>
      </div>
    </>
  );
}
