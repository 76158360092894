import { Add, Verified } from "@mui/icons-material";
import { Button, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useMemo } from "react";
import { Search, XLg } from "react-bootstrap-icons";
import { replaceItem } from "../../../../services/AdminService";
import { GetLoginUserDetails } from "../../../../utils/Utils";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export const ReplaceItemModal = ({
  handelCloseReplaceItems,
  itemList,
  originalItem,
  refreshData,
}) => {
  const loginUserDetail = GetLoginUserDetails();
  const { t } = useTranslation();
  const filteredItemList = useMemo(() => {
    return itemList.filter(
      (item) => item.itemId !== originalItem.itemId && item.verified === "Y"
    );
  }, [originalItem, itemList]);

  const [refreshWith, setRefreshWith] = useState(null);

  return (
    <div className="rightFlotingPanel">
      <div className="rightFlotingContainer">
        <div className="rfContHeader">
          <div className="rfcHeadText">{t("replace_with")}</div>
          <div className="rfcHActionBtnGrp">
            <div className="actionBtn closeBtnD10">
              <IconButton onClick={() => handelCloseReplaceItems()}>
                <XLg />
              </IconButton>
            </div>
          </div>
        </div>

        <div className="rfContContain">
          <div className="rfContContainDetails">
            <div className="elementFormContainer">
              <div className="indentFormtable autoHeight">
                <div className="indtableResponsive">
                  <table className="taskListTable">
                    <thead className="taskListtableHeader">
                      <tr>
                        <th>{t("item_name")}</th>
                        <th>{t("description")}</th>
                        <th>{t("category")}</th>
                        <th>{t("type")}</th>
                        <th>{t("hsncd")}</th>
                        <th>{t("uom")}</th>
                        <th>{t("manufacturer")}</th>
                        <th>{t("expirable")}</th>
                        <th>{t("status")}</th>
                        <th className="width100"></th>
                      </tr>
                    </thead>
                    <tbody className="scrolableTableBody">
                      {filteredItemList &&
                        filteredItemList.map((item) => (
                          <>
                            <tr>
                              <td>
                                <div className="tskLstTaskNM">
                                  {item.itemName}
                                </div>
                              </td>
                              <td>
                                <Tooltip title={item.itemName} arrow>
                                  <div className="tskLstTaskDescription">
                                    {item.itemDescription}
                                  </div>
                                </Tooltip>
                              </td>
                              <td>
                                <div className="tskLstTaskNM">
                                  {item.category}
                                </div>
                              </td>
                              <td>
                                <div className="tskLstTaskNM">{item.type}</div>
                              </td>
                              <td>
                                <div className="tskLstTaskNM">{item.hsnCd}</div>
                              </td>
                              <td>
                                <div className="tskLstTaskNM">
                                  {item.defaultUom}
                                </div>
                              </td>
                              <td>
                                <div className="tskLstTaskNM">
                                  {item.manufacturer}
                                </div>
                              </td>
                              <td>
                                <div className="tskLstTaskNM">
                                  {item.expirable === "Y" ? t("yes") : t("no")}
                                </div>
                              </td>

                              {item.verified === "Y" && (
                                <>
                                  <td>
                                    <div className="tskLstTaskNM">
                                      <Verified />
                                      {t("verified")}
                                    </div>
                                  </td>
                                </>
                              )}
                              {item.verified === "P" && <td></td>}

                              <td>
                                <Button
                                  className="addNRwBtn"
                                  onClick={(e) => {
                                    replaceItem(
                                      originalItem.itemId,
                                      loginUserDetail.userId,
                                      item.itemId
                                    ).then((response) => {
                                      if (response.data.returnValue === "1") {
                                        toast.success(
                                          t("item_replace_success")
                                        );
                                        refreshData();
                                        handelCloseReplaceItems();
                                      } else {
                                        toast.error(t("something_went_wrong"));
                                      }
                                    });
                                  }}
                                >
                                  {t("replace")}
                                </Button>
                              </td>
                            </tr>
                          </>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="rfContFooter">
          <div className="formBtnElement">
            <Button className="dfultPrimaryBtn">Replace</Button>
            <Button className="dfultDarkSecondaryBtn">Cancel</Button>
          </div>
        </div> */}
      </div>
    </div>
  );
};
