import React, { useContext } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController,
} from "chart.js";

import {
  Accessible,
  Add,
  ManageHistoryRounded,
  VisibilityOutlined,
} from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import { Bar, Pie } from "react-chartjs-2";
import { ArrowRepeat, Eye } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { BookAppointmentFromNurseStep } from "../../../../../common/tour_step/BookAppointmentFromNurseStep";
import { ViewAppointmentFromNurseStep } from "../../../../../common/tour_step/ViewAppointmentFromNurseStep";
import Joyride from "react-joyride";
import { TourContext } from "../../../../../common/TourProvider";

export default function CardBookAppointment({
  handelOpenViewBookedAppointmentConsultation,
  handelOpenViewBookAppointmentDrList,
  chartDataOfItem,
  fetchDashBoardData,
}) {
  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    LineController,
    BarController
  );

  const { t } = useTranslation();

  const data = {
    // labels: ["Completed", "Queue", "New", "Review"],
    labels:
      chartDataOfItem.todayDoctorWiseUnitAppointment?.length > 0
        ? chartDataOfItem.todayDoctorWiseUnitAppointment.map(
            (item) => item.label
          )
        : [],
    datasets: [
      {
        label: t("count"),
        // data: [12, 19, 3, 5],
        data:
          chartDataOfItem.todayDoctorWiseUnitAppointment?.length > 0
            ? chartDataOfItem.todayDoctorWiseUnitAppointment.map(
                (item) => item.count
              )
            : [],
        backgroundColor: [
          "rgba(79, 212, 0, 1)",
          "rgba(234, 184, 0, 1)",
          "rgba(241, 61, 60, 1)",
          "rgba(254, 162, 59, 1)",
        ],
        borderColor: [
          "rgba(255, 255, 255, .6)",
          "rgba(255, 255, 255, .6)",
          "rgba(255, 255, 255, .6)",
          "rgba(255, 255, 255, .6)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    cutout: "80%",
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 6,
          color: "#d2d2d2",
          font: {
            size: 12,
            weight: "light",
          },
        },
      },
    },
  };

  const barLineData = {
    // labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    labels:
      chartDataOfItem?.todayHourlyUnitAppointment?.length > 0
        ? chartDataOfItem.todayHourlyUnitAppointment.map((item) => item.label)
        : [],
    datasets: [
      {
        label: t("appointment"),
        // data: [12, 19, 3, 5, 2, 3],
        data:
          chartDataOfItem?.todayHourlyUnitAppointment?.length > 0
            ? chartDataOfItem.todayHourlyUnitAppointment.map(
                (item) => item.count
              )
            : [],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const barLineOoptions = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "",
      },
    },
  };

  const bookOrViewAppointMentNS = [
    {
      target: ".nsAppointment",
      content:
        "You can book a doctor appointment by clicking the 'Appointment' button and view all appointments by clicking the 'View Appointment' button.",
      disableBeacon: true,
    },
    {
      target: ".nsrefresh",
      content: "Click here to refresh the dashboard and fetch the latest data.",
      disableBeacon: true,
    },
    {
      target: ".nsAppointmentStepOne",
      content:
        "This chart displays today's unit-wise appointments. Hover over the segments to view details.",
      disableBeacon: true,
    },
    {
      target: ".nsAppointmentStepTwo",
      content:
        "This bar chart shows the hourly distribution of today's appointments.",
      disableBeacon: true,
    },
    {
      target: ".nsDataCount",
      content: "This shows the total number of appointments for the day.",
      disableBeacon: true,
    },
    {
      target: ".nsAppointmentStepThree",
      content: "Click here to view all booked appointments and their details.",
      disableBeacon: true,
    },
    {
      target: ".nsAppointmentStepFour",
      content: "Click here to book a new appointment with a doctor.",
      disableBeacon: true,
    },
  ];

  const { steps, run, setRun } = useContext(TourContext);
  console.log("useContext", run, run["bookOrViewAppointMentNS"] === true);

  const bookAppointmentComp = () => {
    handelOpenViewBookAppointmentDrList();
    if (run["bookOrViewAppointMentNS"] === true) {
      setRun({
        bookappointNs: true,
      });
    }
  };

  const viewAppointmentComp = () => {
    handelOpenViewBookedAppointmentConsultation();
    if (run["bookOrViewAppointMentNS"] === true) {
      setRun({
        viewappointNs: true,
      });
    }
  };

  return (
    <>
      <Joyride
        steps={bookOrViewAppointMentNS}
        run={run["bookOrViewAppointMentNS"] === true}
        continuous={true}
        showSkipButton={true}
        scrollOffset={200}
        styles={{
          buttonClose: {
            display: "none",
          },
        }}
        // styles={{ overlay: { height: "100vh" } }}
      />
      <div className="anDashIndCard nsAppointment">
        <div className="anDashCardArea">
          <div className="andHeader">
            <div className="andCrdNM">
              {t("book_appointment")}
              <IconButton
                className={`refreshIcon nsrefresh`}
                onClick={fetchDashBoardData}
              >
                <ArrowRepeat />
              </IconButton>
            </div>
            <div className="andCrdUnitCount">
              <span title="Attrition">
                {/* <Male /> */}
                {/* This Month: <span className="fbold"> 215421</span> */}
              </span>

              <span title="Tenure">
                {/* <Female /> */}
                {/* This week: <span className="fbold">12154</span> */}
              </span>
            </div>
          </div>

          <div className="andCrdGraf">
            <div className="anDLftGrf nsAppointmentStepOne">
              <div className="centTxtShow">
                <Pie
                  className="hw100"
                  type="doughnut"
                  data={data}
                  options={options}
                />
                <div className="highlighted-text">
                  {/* <span>Total</span> */}
                  <span className="highDataCount nsDataCount">
                    {chartDataOfItem.todayUnitAppointmentCount}
                  </span>
                </div>
              </div>
            </div>
            <div className="anDRightGrf nsAppointmentStepTwo">
              <Bar
                className="hw100"
                type="bar"
                data={barLineData}
                options={barLineOoptions}
              />
              {/* <Bar data={barLineData} options={barLineOoptions} /> */}
            </div>
          </div>

          {/* <div className="andCrdData"></div> */}
          <div className="andCrdFooter">
            <Button
              className="dfultPrimaryBtn nsAppointmentStepThree"
              startIcon={<Eye />}
              // onClick={() => {handelOpenViewBookedAppointmentConsultation()}}
              onClick={viewAppointmentComp}
            >
              {t("view_appointment")}
            </Button>
            <Button
              className="dfultPrimaryBtn nsAppointmentStepFour"
              startIcon={<Accessible />}
              onClick={() => bookAppointmentComp()}
            >
              {t("appointment")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
