import {
  AttachFile,
  CloseFullscreen,
  DataObjectOutlined,
  OpenInFull,
} from "@mui/icons-material";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useMemo, useReducer, useRef, useState } from "react";
import { useEffect } from "react";
import { XLg } from "react-bootstrap-icons";
import DateUtils from "../../../../../utils/DateUtils";
import { Controller, useForm } from "react-hook-form";
// import ReactDatePicker from "react-datepicker";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { getFileDataObj } from "../../../../../utils/FileUtils";
import TaskService from "../../../../../services/TaskService";
import { GetLoginUserDetails } from "../../../../../utils/Utils";
import {
  approveTask,
  getPositionsOfOrganization,
} from "../../../../../services/AdminService";
import { GoodsServicesIncomingForm } from "./GoodsServicesIncomingForm";
import { DataIncomingForm } from "./DataIncomingForm";
import { AdvanceIncomingForm } from "./AdvanceIncomingForm";
import { StatutoryAndPaymentIncoming } from "./StatutoryAndPaymentIncoming";
import { ManpowerIncoming } from "./ManpowerIncoming";
import { ReimbursementIncomingForm } from "./ReimbursementIncomingForm";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { enIN, es } from "date-fns/locale";

// Register locales with ReactDatePicker
registerLocale("en", enIN);
registerLocale("es", es);

export const IncomingFormPreview = ({
  handleClose,
  formData,
  taskId,
  taskNature,
  taskCreatedBy,
  handleSubmitValue,
}) => {
  const fullScreenStyle = {
    width: "100%",
  };

  const { t, i18n } = useTranslation();

  const [ledgerHeadList, setLedgerHeadList] = useState([]);
  const loginUserDetail = GetLoginUserDetails();
  const recurringDocumentRef = useRef();

  // Determine the locale based on the current language
  const currentLocale = i18n.language === "es" ? "es" : "en";

  const { register, control, setValue, watch } = useForm();

  const { ProcessDirect } = watch();

  const initialState = {
    isExpanded: false,
    formDataCopy: null,
    recurring: false,
    recurringPeriodList: [],
    repeatUntil: new Date(),
    recurringDocument: null,
    contractList: [],
    costCenterList: [],
    billToShipToList: [],
    orgAddressList: [],
    positionList: [],
    capexOpex: "",
    ProcessDirect: "",
  };

  const handleUpdateField = (fieldName, state, payload) => {
    let temp = [...state];
    temp[fieldName] = payload;
    return temp;
  };

  const recurringPeriodList = [
    "Daily",
    "Weekly",
    "Monthly",
    "Quarterly",
    "Yearly",
  ];

  const reducer = (state, action) => {
    switch (action.type) {
      case "expandOrShrinkWindow":
        return { ...state, isExpanded: !state.isExpanded };
      case "copyFormData":
        const tempFormData = { ...formData };
        if (tempFormData.serviceGoods && tempFormData.serviceGoods.length > 0) {
          tempFormData.serviceGoods = tempFormData.serviceGoods.map((data) => ({
            ...data,
            editMode: false,
          }));
        }

        if (tempFormData.manPowers && tempFormData.manPowers.length > 0) {
          tempFormData.manPowers = tempFormData.manPowers.map((data) => ({
            ...data,
            editMode: false,
          }));
        }

        if (tempFormData.sips && tempFormData.sips.length > 0) {
          tempFormData.sips = tempFormData.sips.map((data) => ({
            ...data,
            editMode: false,
          }));
        }
        //

        return {
          ...state,
          formDataCopy: tempFormData,
          capexOpex: formData.capexOpex,
        };
      case "updateServiceGoods":
        return {
          ...state,
          formDataCopy: { ...state.formDataCopy, serviceGoods: action.payload },
        };
      case "updateReimbursement":
        return {
          ...state,
          formDataCopy: {
            ...state.formDataCopy,
            serviceGoodsReim: action.payload,
          },
        };
      case "updateManPowers":
        return {
          ...state,
          formDataCopy: { ...state.formDataCopy, manPowers: action.payload },
        };
      case "updateSip":
        return {
          ...state,
          formDataCopy: { ...state.formDataCopy, sips: action.payload },
        };
      case "dataFormUpdate":
        return {
          ...state,
          formDataCopy: { ...state.formDataCopy, ...action.payload },
        };
      case "advanceFormUpdate":
        return {
          ...state,
          formDataCopy: { ...state.formDataCopy, ...action.payload },
        };
      case "setRecurring":
        return { ...state, recurring: action.payload };
      case "setRepeatUntil":
        return { ...state, repeatUntil: action.payload };
      case "setRecurringDocument":
        return { ...state, recurringDocument: action.payload };
      case "setContractList":
        return { ...state, contractList: action.payload };
      case "setCostCenterList":
        return { ...state, costCenterList: action.payload };
      case "setBillToShipToList":
        return { ...state, billToShipToList: action.payload };
      case "setOrgAddressList":
        return { ...state, orgAddressList: action.payload };
      case "setPositionList":
        return { ...state, positionList: action.payload };
      case "setCapexOpex":
        return { ...state, capexOpex: action.payload };
      case "setChangeProcessDirect":
        return {
          ...state,
          formDataCopy: { ...state.formDataCopy, process: action.payload },
        };
      case "setPurpose":
        return {
          ...state,
          formDataCopy: { ...state.formDataCopy, purpose: action.payload },
        };

      case "setCostCenter":
        return {
          ...state,
          formDataCopy: { ...state.formDataCopy, costCenter: action.payload },
        };

      case "setBillTo":
        return {
          ...state,
          formDataCopy: { ...state.formDataCopy, billTo: action.payload },
        };
      case "setShipTo":
        return {
          ...state,
          formDataCopy: { ...state.formDataCopy, shipTo: action.payload },
        };

      case "selectContract":
        return {
          ...state,
          formDataCopy: { ...state.formDataCopy, contractId: action.payload },
        };

      case "addItemToGoodsService":
        let tempGoodsAndServices = [
          ...state.formDataCopy.serviceGoods,
          action.payload,
        ];
        // tempGoodsAndServices.push(action.payload);
        return {
          ...state,
          formDataCopy: {
            ...state.formDataCopy,
            serviceGoods: tempGoodsAndServices,
          },
        };
      case "removeRowByIndex":
        let temp = [...state.formDataCopy.serviceGoods];
        temp.splice(action.payload, 1);

        return {
          ...state,
          formDataCopy: { ...state.formDataCopy, serviceGoods: temp },
        };

      case "removeManPowersRowByIndex":
        let removeManpowerRowTemp = [...state.formDataCopy.manPowers];
        removeManpowerRowTemp.splice(action.payload, 1);

        return {
          ...state,
          formDataCopy: {
            ...state.formDataCopy,
            manPowers: removeManpowerRowTemp,
          },
        };

      case "removeReimbursementByIndex":
        let removeReimbursementTemp = [...state.formDataCopy.serviceGoodsReim];
        removeReimbursementTemp.splice(action.payload, 1);

        return {
          ...state,
          formDataCopy: {
            ...state.formDataCopy,
            serviceGoodsReim: removeReimbursementTemp,
          },
        };

      case "removeSIPRowByIndex":
        let removeSipTemp = [...state.formDataCopy.sips];
        removeSipTemp.splice(action.payload, 1);

        return {
          ...state,
          formDataCopy: {
            ...state.formDataCopy,
            sips: removeSipTemp,
          },
        };

      case "editGoodsServicesIndex":
        const tempFormData1 = { ...state.formDataCopy };
        tempFormData1.serviceGoods[action.payload] = {
          ...tempFormData1.serviceGoods[action.payload],
          editMode: !tempFormData1.serviceGoods[action.payload].editMode,
        };
        return { ...state, formDataCopy: tempFormData1 };

      case "editManpowerIndex":
        const editManpowerIndex = { ...state.formDataCopy };
        editManpowerIndex.manPowers[action.payload].editMode =
          !editManpowerIndex.manPowers[action.payload].editMode;
        return { ...state, formDataCopy: editManpowerIndex };

      case "editReimbursementIndex":
        const editReimbursementIndex = { ...state.formDataCopy };
        editReimbursementIndex.serviceGoodsReim[action.payload].editMode =
          !editReimbursementIndex.serviceGoodsReim[action.payload].editMode;
        return { ...state, formDataCopy: editReimbursementIndex };

      case "editStatutory":
        const editStatutory = { ...state.formDataCopy };
        editStatutory.sips[action.payload].editMode =
          !editStatutory.sips[action.payload].editMode;
        return { ...state, formDataCopy: editStatutory };

      case "addItemToSips":
        let tempGoodsAndServices2 = [
          ...state.formDataCopy.sips,
          action.payload,
        ];
        // tempGoodsAndServices.push(action.payload);
        return {
          ...state,
          formDataCopy: {
            ...state.formDataCopy,
            sips: tempGoodsAndServices2,
          },
        };

      case "addItemToManpower":
        let tempAddToManpower = [
          ...state.formDataCopy.manPowers,
          action.payload,
        ];
        // tempGoodsAndServices.push(action.payload);
        return {
          ...state,
          formDataCopy: {
            ...state.formDataCopy,
            manPowers: tempAddToManpower,
          },
        };

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const filteredBudgetHeadList = useMemo(() => {
    if (state.capexOpex === "CapitalExpenditure") {
      return ledgerHeadList.filter(
        (item) => item.category === "Capital Expense"
      );
    } else {
      return ledgerHeadList.filter(
        (item) => item.category === "Operating Expense"
      );
    }
  }, [ledgerHeadList, state.capexOpex]);

  const validateForm = () => {
    const formData = state.formDataCopy;

    const type = formData;
  };

  function deepEqual(obj1, obj2) {
    if (obj1 === obj2) {
      return true;
    }

    if (
      typeof obj1 !== "object" ||
      obj1 === null ||
      typeof obj2 !== "object" ||
      obj2 === null
    ) {
      return false;
    }

    const { editMode: editMode1, ...propKeys1 } = obj1;
    const { editMode: editMode2, ...propKeys2 } = obj2;

    const keys1 = Object.keys(propKeys1);
    const keys2 = Object.keys(propKeys2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      const val1 = obj1[key];
      const val2 = obj2[key];

      if (Array.isArray(val1) && Array.isArray(val2)) {
        if (!arraysDeepEqual(val1, val2)) {
          return false;
        }
      } else if (typeof val1 === "object" && typeof val2 === "object") {
        if (!deepEqual(val1, val2)) {
          return false;
        }
      } else if (val1 !== val2) {
        return false;
      }
    }

    return true;
  }

  function arraysDeepEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i = 0; i < arr1.length; i++) {
      const val1 = arr1[i];
      const val2 = arr2[i];

      if (Array.isArray(val1) && Array.isArray(val2)) {
        if (!arraysDeepEqual(val1, val2)) {
          return false;
        }
      } else if (typeof val1 === "object" && typeof val2 === "object") {
        if (!deepEqual(val1, val2)) {
          return false;
        }
      } else if (val1 !== val2) {
        return false;
      }
    }

    return true;
  }

  const handleApproveRequest = async (e) => {
    let reqDto = {
      taskDetailId: taskId,
      // orgId: loginUserDetail.orgId,
      // documents: [],
      approvalRmks: "",
      // taskCreator:
      //   taskCreatedBy && taskCreatedBy === loginUserDetail.userId ? "Y" : "N",
      taskForwordToUserIdAfterApproval: "",
    };
    const isEqual = deepEqual(formData, state.formDataCopy);

    let response = null;
    // ;
    // ;
    // return;

    if (!isEqual) {
      // return;
      response = await TaskService.approveIndentWithChanges(
        loginUserDetail.userId,
        loginUserDetail.orgId,
        state.formDataCopy
      );
      if (response.data && response.data.returnValue === "1") {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
        return;
      }
    }

    // return;
    if (taskNature && taskNature === "EXPENSE_APPROVAL_REQUEST") {
      reqDto.additionalInfo = {
        approval_rmks: "Test",
        changes_in_expense_request: isEqual ? "N" : "Y",
      };

      {
        /*
    //turn on when "approveIndentWithChanges" api works
      // if (response.status === "Success") {
      // }
    */
      }
      approveTask(loginUserDetail.userId, reqDto).then((response) => {
        if (response.data && parseInt(response.data.returnValue) > 0) {
          toast.success(response.data.message);
          handleSubmitValue();
        } else {
          toast.error(response.data.message);
        }
      });
    }
  };

  useEffect(() => {
    if (formData) {
      dispatch({ type: "copyFormData" });
    }
  }, [formData]);

  useEffect(() => {
    TaskService.getContracts(loginUserDetail.orgId).then((response) => {
      if (response.data) {
        dispatch({ type: "setContractList", payload: response.data });
      }
    });
    TaskService.getCostCenterOfOrg(loginUserDetail.orgId).then((response) => {
      if (response.data) {
        dispatch({ type: "setCostCenterList", payload: response.data });
      }
    });
    TaskService.getOrgAddresses(loginUserDetail.orgId).then((response) => {
      if (response.data) {
        dispatch({ type: "setBillToShipToList", payload: response.data });
        dispatch({ type: "setOrgAddressList", payload: response.data });
      }
    });
    getPositionsOfOrganization(loginUserDetail.orgId).then((response) => {
      if (response.data) {
        dispatch({ type: "setPositionList", payload: response.data });
      }
    });

    TaskService.getBudgetHeads(loginUserDetail.orgId).then((response) => {
      if (response.data) {
        setLedgerHeadList(response.data);
      }
    });
  }, []);

  return (
    <>
      <div
        className="RightFlotingFormContainerArea"
        style={state.isExpanded ? fullScreenStyle : {}}
      >
        <div className="flottingContainer">
          <div className="flotHeaderPanel">
            <div class="flotHedCon">{t("expense_form_preview")}</div>
            <div class="flotActionBtnGrp">
              <div class="flotActBtn closeBtnD10">
                <Tooltip
                  arrow
                  title={
                    state.isExpanded
                      ? t("exit_full_screen")
                      : t("enter_full_screen")
                  }
                >
                  <IconButton
                    onClick={(e) => {
                      dispatch({ type: "expandOrShrinkWindow" });
                    }}
                  >
                    {state.isExpanded ? <CloseFullscreen /> : <OpenInFull />}
                    {/* <Expand /> */}
                  </IconButton>
                </Tooltip>
              </div>
              <div class="flotActBtn closeBtnD10">
                <IconButton onClick={handleClose}>
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>
          <div className="flotContain">
            <div className="elementFormContainer previewSpace ">
              <div class="taskElementGrpRow">
                <div class="tskElemHeding">{t("expenditure")}</div>
                <div class="taskContractNm">
                  {state &&
                  state.formDataCopy &&
                  state.formDataCopy.expenseCategory === "Reimbursement"
                    ? t("reimbursement")
                    : t("proposedExpenditure")}
                </div>
              </div>
              <div class="taskElementGrpRow">
                <div class="tskElemHeding">{t("expenditureFor")}</div>
                <div class="taskContractNm">
                  {state &&
                  state.formDataCopy &&
                  state.formDataCopy.expenseType === "goods"
                    ? t("goods")
                    : state.formDataCopy &&
                      state.formDataCopy.expenseType === "services"
                    ? t("services")
                    : state.formDataCopy && state.formDataCopy.expenseType}
                </div>
              </div>
              <div class="taskElementGrpRow">
                <div className="formElement">
                  <FormControl className="formControl">
                    {/* <Controller
                      name="capexOpex"
                      control={control}
                      defaultValue={""}
                      render={({ field }) => (
                      )}
                    /> */}
                    <RadioGroup
                      className="formRadioGroup"
                      value={state.capexOpex}
                      onChange={(e) => {
                        if (e.target.value !== formData.capexOpex) {
                          toast.error(
                            t("please_make_sure_you_change_budget_head")
                          );
                        }
                        dispatch({
                          type: "setCapexOpex",
                          payload: e.target.value,
                        });
                      }}
                    >
                      <FormControlLabel
                        className="formRadioField"
                        value="CapitalExpenditure"
                        control={<Radio />}
                        label={t("capital_expenditure")}
                      />
                      <FormControlLabel
                        className="formRadioField"
                        value="OperatingExpenditure"
                        control={<Radio />}
                        label={t("operating_expenditure")}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>

              {state.formDataCopy &&
                state.formDataCopy.expenseCategory !== "Reimbursement" &&
                state.formDataCopy.expenseType !== "Manpower" &&
                state.formDataCopy.expenseType !==
                  "StatutoryInsurancePayments" &&
                state.formDataCopy.expenseType !== "advance" && (
                  <>
                    <div className="formElement">
                      <FormControl className="formControl">
                        <RadioGroup
                          className="formRadioGroup"
                          value={
                            state.formDataCopy && state.formDataCopy.process
                          }
                          onChange={(e) => {
                            dispatch({
                              type: "setChangeProcessDirect",
                              payload: e.target.value,
                            });
                          }}
                        >
                          <FormControlLabel
                            className="formRadioField"
                            value="Y"
                            control={<Radio />}
                            label={t("process")}
                          />
                          <FormControlLabel
                            className="formRadioField"
                            value="N"
                            control={<Radio />}
                            label={t("direct")}
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>

                    {state.formDataCopy &&
                      state.formDataCopy.process === "N" && (
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              label={t("reason_for_purchasing_directly")}
                              variant="outlined"
                              className="formTextFieldArea"
                              {...register("directPurchaseReason")}
                            />
                          </FormControl>
                        </div>
                      )}
                    {/* {state.formDataCopy &&
                      state.formDataCopy.process === "N" && (
                        <div className="formElement">
                          <FormControl className="formControl">
                            <FormGroup className="formRadioGroup">
                              <Controller
                                name="isAdvanceNeeded"
                                control={control}
                                defaultValue={false}
                                render={({ field }) => (
                                  <FormControlLabel
                                    className="formRadioField"
                                    control={<Checkbox {...field} />}
                                    label="Is Advance"
                                  />
                                )}
                              />
                            </FormGroup>
                          </FormControl>
                        </div>
                      )} */}
                  </>
                )}

              {state.formDataCopy &&
                state.formDataCopy.expenseCategory !== "Reimbursement" &&
                state.formDataCopy.expenseType !== "DA/TA" &&
                state.formDataCopy.expenseType !== "Manpower" &&
                state.formDataCopy &&
                state.formDataCopy.process === "Y" &&
                state.formDataCopy.expenseType !== "advance" && (
                  <div className="multiFormGroup">
                    <div className="formElement">
                      <FormControl className="formControl">
                        <FormGroup className="formRadioGroup">
                          {/* <Controller
                          name="recurring"
                          control={control}
                          defaultValue={false}
                          render={({ field }) => (
                            <FormControlLabel
                              className="formRadioField"
                              control={
                                <Checkbox {...field} checked={recurring} />
                              }
                              label="Recurring Expenditure"
                            />
                          )}
                        /> */}
                          <FormControlLabel
                            className="formRadioField"
                            control={
                              <Checkbox
                                checked={state.recurring}
                                onChange={(e) => {
                                  dispatch({
                                    type: "setRecurring",
                                    payload: e.target.checked,
                                  });
                                }}
                              />
                            }
                            label={t("recurringExpenditure")}
                          />
                        </FormGroup>
                      </FormControl>
                    </div>
                    {state.recurring === true && (
                      <div className="formElementGroup">
                        <div className="formElement">
                          <FormControl className="formControl">
                            <InputLabel id="selectPriority">
                              {t("recurringPeriod")}
                            </InputLabel>
                            <Controller
                              name="recurringPeriod"
                              control={control}
                              defaultValue={""}
                              render={({ field }) => (
                                <Select
                                  className="formInputField"
                                  variant="outlined"
                                  labelId="selectPriority"
                                  id="priority-select"
                                  {...field}
                                >
                                  {recurringPeriodList &&
                                    recurringPeriodList.map((period) => {
                                      return (
                                        <MenuItem value={period}>
                                          {period}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              )}
                            />
                          </FormControl>
                        </div>

                        <div className="formElement">
                          <FormControl className="formControl">
                            <InputLabel
                              id="setCompletionDate"
                              className="setCompletionDate"
                            >
                              {t("repeatUntilDate")}*
                            </InputLabel>
                            <ReactDatePicker
                              locale={currentLocale}
                              showIcon
                              labelId="setCompletionDate"
                              className="formDatePicker"
                              minDate={new Date()}
                              dateFormat="dd/MM/yyyy"
                              selected={state.repeatUntil}
                              onChange={(date) => {
                                dispatch({
                                  type: "setRepeatUntil",
                                  payload: date,
                                });
                              }}
                            />
                          </FormControl>
                        </div>

                        <div className="formBtnElement">
                          <Button
                            variant="outlined"
                            startIcon={<AttachFile />}
                            className="comBtnOutline"
                            onClick={(e) => {
                              recurringDocumentRef.current.click();
                            }}
                          >
                            {t("document")}
                          </Button>
                          <input
                            type="file"
                            style={{ display: "none" }}
                            id="fileInput"
                            ref={recurringDocumentRef}
                            onChange={async (e) => {
                              const files = e.target.files;

                              const data = await getFileDataObj(files[0]);

                              dispatch({
                                type: "setRecurringDocument",
                                payload: data,
                              });
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              <div className="formElement">
                <FormControl className="formControl">
                  <TextField
                    autoComplete="off"
                    label={t("purpose")}
                    required
                    variant="outlined"
                    className="descptionTextFild"
                    InputLabelProps={{
                      shrink:
                        state.formDataCopy && state.formDataCopy.purpose !== ""
                          ? true
                          : false,
                    }}
                    multiline
                    minRows={3}
                    maxRows={7}
                    value={state.formDataCopy && state.formDataCopy.purpose}
                    onChange={(e) => {
                      dispatch({
                        type: "setPurpose",
                        payload: e.target.value,
                      });
                    }}
                  />
                </FormControl>
              </div>
              <div className="formElementGroup">
                <div className="formElement">
                  <FormControl className="formControl">
                    <InputLabel
                      id="selectContract"
                      shrink={
                        !!(state.formDataCopy && state.formDataCopy.contractId)
                      }
                    >
                      {t("contract_if_any")}
                    </InputLabel>

                    <Select
                      className="formInputField"
                      variant="outlined"
                      labelId="selectContract"
                      label={t("contract_if_any")}
                      value={
                        state.formDataCopy && state.formDataCopy.contractId
                      }
                      onChange={(e) => {
                        dispatch({
                          type: "selectContract",
                          payload: e.target.value,
                        });
                      }}
                    >
                      <MenuItem value={""}> {t("select_contract")} </MenuItem>
                      {state.contractList.map((value) => {
                        return (
                          <MenuItem value={value.id}>{value.name}</MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
                <div className="formElement">
                  <FormControl className="formControl">
                    <InputLabel
                      id="selectcostCenter"
                      shrink={
                        !!(state.formDataCopy && state.formDataCopy.costCenter)
                      }
                    >
                      {t("cost_center")}*
                    </InputLabel>

                    <Select
                      className="formInputField"
                      variant="outlined"
                      labelId="selectcostCenter"
                      id="select-CostCenter"
                      label={t("cost_center")}
                      value={
                        state.formDataCopy && state.formDataCopy.costCenter
                      }
                      onChange={(e) => {
                        dispatch({
                          type: "setCostCenter",
                          payload: e.target.value,
                        });
                      }}
                    >
                      {state.costCenterList.length > 0 &&
                        state.costCenterList.map((item) => (
                          <MenuItem value={item.id} key={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
              </div>

              {state.formDataCopy &&
                state.formDataCopy.expenseType == "goods" &&
                state.formDataCopy.expenseCategory !== "Reimbursement" && (
                  <div className="formElementGroup">
                    {state.formDataCopy &&
                      state.formDataCopy.expenseType == "goods" &&
                      state.formDataCopy.expenseCategory !==
                        "Reimbursement" && (
                        <div className="formElement">
                          <FormControl className="formControl">
                            <InputLabel id="selectbillto">
                              {t("bill_to")} *
                            </InputLabel>

                            <Select
                              className="formInputField"
                              variant="outlined"
                              labelId="selectbillto"
                              id="select-selectbillto"
                              label={t("bill_to")}
                              value={
                                state.formDataCopy && state.formDataCopy.billTo
                              }
                              onChange={(e) => {
                                dispatch({
                                  type: "setBillTo",
                                  payload: e.target.value,
                                });
                              }}
                            >
                              {state.billToShipToList.length > 0 &&
                                state.billToShipToList.map((item) => (
                                  <MenuItem value={item.id} key={item.id}>
                                    {`${item.unitName}, ${item.buildingNameNo}, ${item.city}`}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </div>
                      )}

                    {state.formDataCopy &&
                      state.formDataCopy.expenseType == "goods" &&
                      state.formDataCopy.expenseCategory !==
                        "Reimbursement" && (
                        <div className="formElement">
                          <FormControl className="formControl">
                            <InputLabel id="selectshipto">
                              {state.formDataCopy.expenseType == "goods"
                                ? t("ship_to")
                                : state.formDataCopy.expenseType == "services"
                                ? t("service_accepting_position")
                                : ""}
                            </InputLabel>

                            <Select
                              className="formInputField"
                              variant="outlined"
                              labelId="selectshipto"
                              id="select-selectshipto"
                              value={
                                state.formDataCopy && state.formDataCopy.shipTo
                              }
                              onChange={(e) => {
                                dispatch({
                                  type: "setShipTo",
                                  payload: e.target.value,
                                });
                              }}
                              label={t("ship_to")}
                            >
                              {state.billToShipToList.length > 0 &&
                                state.billToShipToList.map((item) => (
                                  <MenuItem value={item.id} key={item.id}>
                                    {`${item.unitName}, ${item.buildingNameNo}, ${item.city}`}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </div>
                      )}
                  </div>
                )}

              {state.formDataCopy &&
                state.formDataCopy.expenseCategory !== "Reimbursement" &&
                (state.formDataCopy.expenseType === "goods" ||
                  state.formDataCopy.expenseType === "services") && (
                  <GoodsServicesIncomingForm
                    state={state}
                    dispatch={dispatch}
                    ExpenditureGoods={
                      state.formDataCopy &&
                      state.formDataCopy.expenseType === "goods"
                    }
                    tableDataList={state.formDataCopy.serviceGoods}
                    budgetHeadList={filteredBudgetHeadList}
                  />
                )}

              {state.formDataCopy &&
                state.formDataCopy.expenseType === "DA/TA" && (
                  <DataIncomingForm
                    state={state}
                    dispatch={dispatch}
                    budgetHeadList={filteredBudgetHeadList}
                  />
                )}

              {state.formDataCopy &&
                state.formDataCopy.expenseCategory === "Reimbursement" &&
                state.formDataCopy.expenseType !== "DA/TA" && (
                  <ReimbursementIncomingForm
                    state={state}
                    dispatch={dispatch}
                    budgetHeadList={filteredBudgetHeadList}
                  />
                )}

              {state.formDataCopy &&
                state.formDataCopy.expenseType ===
                  "StatutoryInsurancePayments" && (
                  <StatutoryAndPaymentIncoming
                    state={state}
                    dispatch={dispatch}
                    budgetHeadList={filteredBudgetHeadList}
                  />
                )}

              {state.formDataCopy &&
                state.formDataCopy.expenseType === "Manpower" && (
                  <ManpowerIncoming
                    state={state}
                    dispatch={dispatch}
                    budgetHeadList={filteredBudgetHeadList}
                  />
                )}

              {state.formDataCopy &&
                state.formDataCopy.expenseType === "advance" && (
                  <AdvanceIncomingForm
                    state={state}
                    dispatch={dispatch}
                    budgetHeadList={filteredBudgetHeadList}
                  />
                )}
            </div>
          </div>
          <div className="flotFooterPanel">
            <div className="formBtnElement">
              <Button
                className="dfultPrimaryBtn"
                onClick={(e) => {
                  const validateData = validateForm();
                  handleApproveRequest(e);
                }}
              >
                {t("approveRequest")}
              </Button>
              <Button className="dfultDarkSecondaryBtn" onClick={handleClose}>
                {t("cancel")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
