import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Title,
  Filler,
  Legend,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController,
} from "chart.js";
import { Bar, Chart, Doughnut, Line, Pie } from "react-chartjs-2";
import { Button, IconButton } from "@mui/material";
import { Add } from "@mui/icons-material";
import {
  getDashboardDataOfItem,
  refreshHoliday,
} from "../../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import { ArrowRepeat } from "react-bootstrap-icons";
import { GetLoginUserDetails } from "../../../../../utils/Utils";

export default function CardHolidayWorkingday({
  handelOpenViewHoliday,
  handelOpenAddHoliday,
  // chartDataOfItem,
  menuModuleList,
  userMenuList,
}) {
  const userDetails = GetLoginUserDetails();
  const [chartDataOfItem, setchartDataOfItem] = useState([]);
  console.log("menuModuleList", menuModuleList);
  const { t } = useTranslation();
  // const userDetails = jwtDecode( SecureIndexedDB.getItem("token"));

  // const [chartDataOfItem, setChartDataOfItem] = useState([]);

  // useEffect(() => {
  //   getDashboardDataOfItem(userDetails.orgId, userDetails.userId).then(
  //     (response) => {
  //       console.log("getDashboardDataOfItem", response.data);
  //       setChartDataOfItem(response.data);
  //     }
  //   );
  // }, [userDetails.orgId, userDetails.userId]);

  ChartJS.register(
    ArcElement,
    Tooltip,
    Title,
    Filler,
    Legend,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    LineController,
    BarController
  );

  useEffect(() => {
    fetchEmplyCardData();
  }, []);

  const fetchEmplyCardData = () => {
    const reqDto = {
      unitId: userDetails.signleUnitId,
      orgId: userDetails.orgId,
      userId: userDetails.userId,
      periodDailyMnthly: "",
      dataLimit: 0,
      feedbackFromPatientDoctor: "",
      fromDate: "",
      toDate: "",
      unitDoctor: "",
    };
    refreshHoliday(reqDto).then((response) => {
      console.log("response", response.data);
      setchartDataOfItem(response.data);
    });
  };

  const data = {
    labels: ["Holiday", "Working Day"],
    datasets: [
      {
        data: [30, 70],
        backgroundColor: ["#8c7ae6", "#aaa"],
        borderWidth: 1,
        borderColor: ["#292929", "#292929"],
      },
    ],
  };

  const options = {
    cutout: "40%",

    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 6,
          color: "#d2d2d2",
          font: {
            size: 12,
            weight: "light",
          },
        },
      },
    },
  };

  // curent People status bar and line chart combo start

  const optionsLeave = {
    responsive: true,
    borderRadius: 4,
    plugins: {
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 12,
          color: "#d2d2d2",
          font: {
            size: 12,
            weight: "light",
          },
        },
      },
      title: {
        display: false,
        text: "FYQ 2nd",
      },
    },
  };

  const labels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const mnthWiseHolidayCountData = chartDataOfItem.mnthWiseHolidayCount || [];
  console.log("mnthWiseHolidayCountData", mnthWiseHolidayCountData);

  const dataLeave = {
    labels: mnthWiseHolidayCountData.map((item) => item.label.trim()),
    datasets: [
      {
        label: t("holiday_count"),
        data: mnthWiseHolidayCountData.map((item) => item.count),
        backgroundColor: "rgba(243, 153, 18, 0.8)",
      },
    ],
  };

  // const dataLeave = {
  //   labels,
  //   datasets: [
  //     {
  //       fill: false,
  //       label: "Expense",
  //       data: [1, 2, 3, 4, 2, 1, 2, 3, 1, 2, 4, 5],
  //       borderColor: "rgb(255, 255, 255)",
  //       backgroundColor: "rgba(255, 241, 195, 0.5)",
  //     },
  //     {
  //       label: "Working",
  //       data: [20, 21, 24, 24, 30, 30, 30, 30, 30, 25, 24, 21],
  //       backgroundColor: "rgba(245, 66, 161, 0.8)",
  //       type: "bar",
  //       barThickness: 6,
  //     },
  //     {
  //       label: "Holiday",
  //       data: [2, 3, 1, 4, 2, 1, 1, 3, 4, 2, 5, 2],
  //       backgroundColor: "rgba(243, 153, 18, 0.8)",
  //       type: "bar",
  //       barThickness: 6,
  //     },
  //   ],
  // };
  // curent People status bar and line chart combo end

  return (
    <>
      <div className="anDashIndCard">
        <div className="anDashCardArea">
          <div className="andHeader">
            <div className="andCrdNM">
              {t("holiday_working_day")}
              <IconButton
                className={`refreshIcon`}
                onClick={fetchEmplyCardData}
              >
                <ArrowRepeat />
              </IconButton>
            </div>
            <div className="andCrdUnitCount">
              {/* <span title="Attrition">
                <Male />
                Avl Stock: <span className="fbold">42%</span>
              </span> */}

              <span title="Tenure">
                {/* <Female /> */}
                {t("no_of_holiday")} :{" "}
                <span className="fbold">{chartDataOfItem.holiday}</span>
              </span>
            </div>
          </div>

          <div className="andCrdGraf">
            {/* <div className="anDLftGrf">
              <div className="centTxtShow">
                <Pie
                  className="hw100"
                  type="doughnut"
                  data={data}
                  options={options}
                />
                <div className="highlighted-text"></div>
              </div>
            </div> */}
            <div className="anDRightGrf oneGraph100">
              {/* <Line
                className="hw100"
                type="line"
                data={dataLeave}
                options={optionsLeave}
              /> */}
              <Bar
                className="hw100"
                type="bar"
                data={dataLeave}
                options={optionsLeave}
              />
              {/* <Bar data={barLineData} options={barLineOoptions} /> */}
            </div>
          </div>
          {/* <div className="andCrdData"></div> */}
          <div className="andCrdFooter">
            {userMenuList &&
              menuModuleList &&
              userMenuList.includes(
                menuModuleList.find(
                  (item) => item.menuName === "Holiday / Working Day View"
                ).menuId
              ) && (
                <Button
                  className="dfultPrimaryBtn "
                  onClick={() => handelOpenViewHoliday()}
                >
                  {t("view")}
                </Button>
              )}

            {userMenuList &&
              menuModuleList &&
              userMenuList.includes(
                menuModuleList.find(
                  (item) => item.menuName === "Holiday / Working Day Add"
                ).menuId
              ) && (
                <Button
                  startIcon={<Add />}
                  className="dfultPrimaryBtn "
                  onClick={() => handelOpenAddHoliday()}
                >
                  {t("add")}
                </Button>
              )}
          </div>
        </div>
      </div>
    </>
  );
}
