import { Edit, TextSnippet } from "@mui/icons-material";
import {
  Button,
  IconButton,
  Tooltip,
  styled,
  tooltipClasses,
} from "@mui/material";
import dayjs from "dayjs";
import { Eye } from "iconoir-react";
import React, { useContext, useState } from "react";
import { XLg } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import DashboardServices from "../../../../../../services/DashboardServices";
import zhPatientService from "../../../../../../services/ZhPatientService";
import { centerSocket, doctorSocket } from "../../../../../../socket";
import { GetLoginUserDetails } from "../../../../../../utils/Utils";
import PatientFileUpload from "../../../../../analyticsDashboard/view/component/healthcare/PatientFileUpload";
import { CancelConsultation } from "./CancelConsultation";
import PrescriptionEdit from "./consultation/PrescriptionEdit";
import PrescriptionView from "./consultation/PrescriptionView";
import { TourContext } from "../../../../../../common/TourProvider";

export default function DoctorConsultations(props) {
  const { t } = useTranslation();
  const { steps, run, setRun } = useContext(TourContext);
  let loginUserDetail = GetLoginUserDetails();
  const [documents, setDocuments] = useState([]);
  const [showFileUploadDialog, setShowFileUploadDialog] = useState(false);
  const [prevDocuments, setPreviousDocuments] = useState([]);
  const [previousMedicalRecords, setPreviousMedicalRecords] = useState(null);

  // coustom tooltip start
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 320,
      minWidth: 180,
      maxHeight: 200,
      overflow: "overlay",
      padding: "16px 20px",
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));
  // coustom tooltip end

  const [showPrescription, setShowPrescription] = useState(true);
  const [showCancellationModule, setShowCancellationModule] = useState(false);

  const validatePescriptionData = () => {
    //Validate Symptoms
    let isValid = true;
    if (
      !props.prescriptionData.symptoms ||
      props.prescriptionData.symptoms.length === 0
    ) {
      toast.error(t("symptoms_cannot_be_empty"));
      isValid = false;
    }
    if (
      props.prescriptionData.referType &&
      props.prescriptionData.referType === "otherSpeciality" &&
      (!props.prescriptionData.referDoctorSpeciality ||
        props.prescriptionData.referDoctorSpeciality === "")
    ) {
      toast.error(t("please_select_doctor_speciality"));
      isValid = false;
    }

    // if (
    //   !props.prescriptionData.doctorNote &&
    //   !props.prescriptionData.audioData
    // ) {
    //   toast.error(t("please_enter_doctor_note_or_add_audio_note"));
    //   isValid = false;
    // }

    // if (
    //   !prescriptionData.riskFactor ||
    //   prescriptionData.riskFactor.length === 0
    // ) {
    //   toast.error("Riskfactor cannot be empty.");
    //   isValid = false;
    // }

    // if (
    //   !prescriptionData.investigation ||
    //   prescriptionData.investigation.length === 0
    // ) {
    //   toast.error("Investigation cannot be empty.");
    //   isValid = false;
    // }

    // if (
    //   !prescriptionData.diagonesis ||
    //   prescriptionData.diagonesis.length === 0
    // ) {
    //   toast.error("Diagonesis cannot be empty.");
    //   isValid = false;
    // }

    // if (
    //   !prescriptionData.procedure ||
    //   prescriptionData.procedure.length === 0
    // ) {
    //   toast.error("Procedure cannot be empty.");
    //   isValid = false;
    // }

    if (!isValid) {
      return false;
    }
    return true;
  };

  const getReferStringFromType = (refer) => {
    switch (refer) {
      case "higherFacility":
        return t("advise_higher_facility");

      case "immediateHospitalization":
        return t("advise_immediate_hospitalization");

      case "otherSpeciality":
        return t("refer_to_other_speciality");

      case "medicoLegal":
        return t("refer_to_medico_legal");

      default:
        return "";
    }
  };

  const handleClickShowPrescription = () => {
    const isValid = validatePescriptionData();
    if (isValid) {
      setShowPrescription(!showPrescription);
    }
  };

  const submitPrescription = async (waitForTestResult) => {
    console.log("Code Comitting");
    console.log("all Data=", props.prescriptionData);

    let data = props.prescriptionData;
    let extraData = props.extraData;
    let procedures = [];
    let medicines = [];
    let dieseases = [];
    let lifeStyles = [];
    let diets = [];
    let paramsToMonitor = [];
    let tests = [];
    let vitals = [];
    let foodAllergies = [];
    let signs = [];
    let symptoms = [];
    let riskfactors = [];
    let medicineAllergies = [];
    let comorbidities = [];
    let pastProcedures = [];
    let pastDieseases = [];
    let possibleConditions = [];
    let possibleInvestigations = [];
    let hereditaryDiseases = [];
    let phyExams = [];

    if (data.procedure) {
      procedures = data.procedure.map((item, index) => {
        return {
          procedureName: item.procedure.procedureName,
          procedureId: item.procedure.locallyAdded ? 0 : item.procedure.id,
          planDate: dayjs(item.date).format("DD-MM-YYYY"),
          slNo: index + 1,
        };
      });
    }

    if (data.medication) {
      const timingMapping = {
        stat: "Stat",
        before_breakfast: "Before Breakfast",
        after_breakfast: "After Breakfast",
        before_lunch: "Before Lunch",
        after_lunch: "After Lunch",
        before_evening: "Before Evening",
        after_evening: "After Evening",
        before_dinner: "Before Dinner",
        after_dinner: "After Dinner",
        after_food: "After Food",
        empty_stomach: "Empty Stomach",
      };

      medicines = data.medication.map((item, index) => {
        let brandName = "";
        let brandId = 0;
        if (item.brandName) {
          brandName = item.brandName.brandName;
          brandId = item.brandName.id;
        }

        const timingValues =
          item?.timing?.map((timing) => timingMapping[timing] || timing) || [];
        const timing = timingValues.join(", ");

        return {
          genericId: item.genericName.locallyAdded ? 0 : item.genericName.id,
          brandId: brandId,
          genericName: item.genericName.generic,
          brandName: brandName,
          dosages: item?.dosage?.dosage || "",
          dosagesUnit: item?.dosage?.dosageUnit || "",
          route: item?.root?.route || "",
          durationLabel: item?.duration?.durationName || "",
          durationDays: item?.duration?.days || "",
          // timing: item?.timing?.join(",") || "",
          timing: timing,
          note: item.note,
          adq: 0,
          preExisting: "",
          slNo: index + 1,
          frequency: item?.frequency?.frequency || "",
        };
      });
    }

    if (data.diagonesis) {
      dieseases = data.diagonesis.map((item, index) => {
        const diseaseId = item?.diseaseObj.locallyAdded
          ? 0
          : item.diseaseObj.id;
        return {
          diseaseName: item?.diseaseObj?.diseaseName,
          diseaseId,
          nature: item.status,
          snowmedCt: item?.diseaseObj?.snowmedCt,
          icd11: item?.diseaseObj?.icd11code,
          slNo: index + 1,
        };
      });
    }

    if (data.lifeStyle) {
      lifeStyles = data.lifeStyle.map((item, index) => {
        const lifeStyleId = item.locallyAdded ? 0 : item.id;
        return {
          lifeStyleName: item.lifeStyle,
          lifeStyleId: lifeStyleId,
          slNo: index + 1,
        };
      });
    }

    if (data.diet) {
      diets = data.diet.map((item, index) => {
        let dietId = item.locallyAdded ? 0 : item.id;
        return {
          dietName: item.diet,
          dietId,
          slNo: index + 1,
        };
      });
    }

    if (data.vitals) {
      vitals = data.vitals.map((item) => {
        let vitalValue = item.vitalValue;
        if (item.vitalCode === "BP") {
          if (vitalValue === "/") vitalValue = "";
        }
        if (item.vitalCode === "LMP") {
          vitalValue = dayjs(new Date(vitalValue)).format("DD-MM-YYYY");
        }
        return {
          vitalName: item.vitalName,
          unit: item.unit,
          vitalValue: vitalValue,
          recodedTime: item.recodedTime,
          isNormal: item.isNormal,
          vitalCode: item.vitalCode,
        };
      });
    }

    if (data.investigation) {
      tests = data.investigation.map((item) => {
        return {
          testId: item.testId,
          testName: item.testName,
          location: item.location,
          view: item.view,
          technique: item.technique,
        };
      });
    }

    if (data.itemToMonitor) {
      paramsToMonitor = data.itemToMonitor;
    }

    if (data.allergicFood) {
      foodAllergies = data.allergicFood
        .filter((item) => !item.previouslyAdded)
        .map((item) => {
          return item.foodName;
        });
    }

    if (data.singObj) {
      signs = data.singObj.map((item, index) => {
        let signId = item?.sign?.locallyAdded ? 0 : item?.sign?.id;
        return {
          signName: item.sign.signName,
          signId,
          signNote: item.userNote,
          slNo: index + 1,
        };
      });
    }

    if (data.symptoms) {
      symptoms = data.symptoms.map((item, index) => {
        let symptomId = item.selectedSymptom.locallyAdded
          ? 0
          : item.selectedSymptom.id;
        return {
          symptomName: item.selectedSymptom.symptomName,
          symptomId,
          severity: item.severity,
          since: item.since,
          slNo: index + 1,
        };
      });
    }

    if (data.riskFactor) {
      riskfactors = data.riskFactor.map((item, index) => {
        let riskFactorId = item.riskFactor.locallyAdded
          ? 0
          : item.riskFactor.id;
        return {
          since: item.since,
          riskFactorId,
          riskFactorName: item.riskFactor.riskFactor,
          slNo: index,
        };
      });
    }

    if (data.allergicDrug) {
      medicineAllergies = data.allergicDrug
        ?.filter((item) => !(item.previouslyAdded === true))
        ?.map((item, index) => {
          let type = item.type || "Compound";
          let genericBrandId = 0;
          let genericBrandName = "";
          // if (item.brand) {
          //   type = "Brand";
          //   genericBrandId = item.brand.id;
          //   genericBrandName = item.brand.brandName;
          // } else {
          //   genericBrandId = item.genericItem.locallyAdded
          //     ? 0
          //     : item.genericItem.id;
          //   genericBrandName = item.genericItem.generic;
          // }
          genericBrandId = item.genericItem.locallyAdded
            ? 0
            : item.genericItem.compoundId;
          genericBrandName = item.genericItem.compoundName;
          return {
            type: type,
            genericBrandId: genericBrandId,
            genericBrandName: genericBrandName,
          };
        });
    }

    if (data?.comorbidity) {
      comorbidities = data?.comorbidity?.map((item, index) => {
        return {
          slNo: index + 1,
          diseaseName: item.disease.comorbiditiesDiseasesName,
          diseaseIcdIICode: item.disease.diseaseIcdIICode || "",
          since: item.since,
        };
      });
    }

    if (data?.prevSurgery) {
      pastProcedures = data?.prevSurgery
        ?.filter((item) => !item?.surgery?.previouslyAdded)
        ?.map((item) => {
          return {
            procedureName: item.surgery.procedureName,
            procedureDate: item.date,
          };
        });
    }

    if (data?.pastIllness) {
      pastDieseases = data?.pastIllness
        ?.filter((item) => !item.illness?.previouslyAdded)
        ?.map((item) => {
          return {
            diseaseName: item.illness.diseaseName,
            diagnosisDate: dayjs(item.date).format("DD-MM-YYYY"),
          };
        });
    }

    if (data?.possibleDiagonesis) {
      possibleConditions = data?.possibleDiagonesis?.map((item) => {
        return {
          diseaseName: item.diseaseName,
          diseaseId: item.locallyAdded ? 0 : item.id,
        };
      });
    }

    if (data?.investigation) {
      possibleInvestigations = data?.investigation?.map((item) => {
        return {
          testId: item.locallyAdded ? 0 : item.testId,
          testName: item.testName,
          location: item.location,
          view: item.view,
          technique: item.technique,
        };
      });
    }

    if (data?.familyHistory) {
      hereditaryDiseases = data?.familyHistory
        ?.filter((item) => !item.previouslyAdded)
        ?.map((item) => {
          return {
            diseaseName: item.disease.diseaseName,
            relationship: item.relationShip,
          };
        });
    }

    if (data?.physicalExamList) {
      phyExams = data?.physicalExamList?.map((item, index) => {
        return {
          slNo: index,
          phyExamId: item.issue.phyExamId,
          phyExamImmediateParId: item.issue.phyExamParId,
          phyExamFreeText: item.issue.phyExamName,
        };
      });
    }
    const reqDto = {
      visitId: extraData.queueVisitId,
      doctorUserId: loginUserDetail.userId,
      reviewDate: data.reviewDate ? data.reviewDate : "",
      doctorNote: data.doctorNote,
      patientId: extraData.queuePatientId,
      procedures,
      medicines,
      dieseases,
      lifeStyles,
      diets,
      paramsToMonitor,
      vitals,
      tests,
      foodAllergies,
      signs,
      symptoms,
      riskfactors,
      medicineAllergies,
      comorbidities,
      pastProcedures,
      pastDieseases,
      possibleConditions,
      possibleInvestigations,
      hereditaryDiseases,
      phyExams,
      bmiValue: data.bmiValue || "",
      bmiType: data.bmiType || "",
      referralNote: getReferStringFromType(data.referType) || "",
      refferedToHospital:
        data.referType === "immediateHospitalization" ||
        data.referType === "higherFacility"
          ? data.hospital?.hospitalName
          : "" || "",

      refferedToHospitalId:
        data.referType === "immediateHospitalization" ||
        data.referType === "higherFacility"
          ? data.hospital?.hospitalId
          : "" || "",

      refferedToDoctor:
        data.referType === "otherSpeciality" ? data.doctorName : "" || "",

      refferedToSpecialist:
        data.referType === "otherSpeciality"
          ? data?.referDoctorSpeciality?.specializationName
          : "" || "",

      refferedToSpecialistId:
        data.referType === "otherSpeciality"
          ? data?.referDoctorSpeciality?.specializationId
          : "" || "",

      prescriptionType: data?.prescriptionType || "",
      watchOutFor: data?.watchoutFor || "",
      doctorAudioNote: data?.audioData || "",

      systemicIllnesses:
        data?.systemicIllnesses?.map((item) => item.systemicIllnessName) || [],
      substanceAbuse:
        data?.substanceAbuse?.map((item) => item.substanceAbuseName) || [],
      waitForTestResult,
    };
    console.log("reqDto= ", reqDto);

    // DashboardServices.isPatientInVideo(extraData.queueVisitId).then(
    //   (response) => {
    //     console.log("isPresent =", response.data);
    //   }
    // );

    // return;
    DashboardServices.submitPrescription(reqDto).then((response) => {
      if (response.data.returnValue === "1") {
        props.closeMeeting(true, "Doctor");
        if (extraData?.isDoctor) {
          doctorSocket.emit("consultationDone", {
            pvId: extraData?.queueVisitId,
          });
        }
        toast.success(t("prescription_submitted_successfully"));
      } else {
        toast.error(t("fail_to_submit_prescription"));
      }
    });
  };

  return (
    <>
      <div className="meetRightHeader">
        <HtmlTooltip
          title={
            <>
              <div className="centerDetail">
                <div className="centerName">
                  {t("consultation_with")}{" "}
                  {`${props?.extraData?.queueClinicName}`}
                </div>
                {/* <div className="centrDtlInd">
                  <span className="hedingCCD">{t("technician")} :</span>
                  <span className="ccInfoData">
                    {props.extraData && props.extraData.queuePatientName}
                  </span>
                </div> */}
                {props?.extraData?.queueClinicContactNo && (
                  <div className="centrDtlInd">
                    <span className="hedingCCD">{t("contact")} :</span>
                    <span className="ccInfoData">
                      {props?.extraData?.queueClinicContactNo}
                    </span>
                  </div>
                )}
                {/* <div className="centrDtlInd">
                  <span className="hedingCCD">{t("location")} :</span>
                  <span className="ccInfoData">
                    1104, The Galleria, Miniontech, New Town, Kolkata, 700156,
                    IND.
                  </span>
                </div> */}
              </div>
            </>
          }
        >
          <div className="metRtTitle">
            {/* {`Consultation With ${props?.extraData?.queueClinicName}`} */}
            {t("consultationWithClinic", {
              clinicName: props?.extraData?.queueClinicName,
            })}
          </div>
        </HtmlTooltip>

        <div className="metRtAction">
          {/* <IconButton aria-label="openInNewTab">
            <OpenInNew />
          </IconButton> */}
          <IconButton
            aria-label="Close"
            onClick={() => props.handleMenuCloseButton()}
          >
            <XLg />
          </IconButton>
        </div>
      </div>
      {/* {showPrescription ? (
        <PrescriptionEdit
          updateData={props.updatePrescriptionData}
          data={props.prescriptionData}
          extraData={props.extraData}
        />
      ) : (
        <PrescriptionView
          data={props.prescriptionData}
          extraData={props.extraData}
          closeMeeting={props.closeMeeting}
          updateData={props.updatePrescriptionData}
        />
      )} */}

      <div
        className={
          !showPrescription ? `dNone` : "MeetingTranscriptionContainer"
        }
      >
        <PrescriptionEdit
          updateData={props.updatePrescriptionData}
          data={props.prescriptionData}
          extraData={props.extraData}
          prevDocuments={prevDocuments}
          setPreviousDocuments={setPreviousDocuments}
          setShowFileUploadDialog={setShowFileUploadDialog}
        />
      </div>
      <div
        className={showPrescription ? `dNone` : "MeetingTranscriptionContainer"}
      >
        <PrescriptionView
          data={props.prescriptionData}
          extraData={props.extraData}
          closeMeeting={props.closeMeeting}
          updateData={props.updatePrescriptionData}
        />
      </div>
      {/* <PrescriptionEdit />
      <PrescriptionView /> */}

      {props.extraData?.isDoctor && (
        <div className="MeetRightFooter">
          <div className="meetRightFootBtnGrp">
            <div className="footBtnGroup">
              <Button
                className={
                  showPrescription ? "dfultPrimaryBtn" : "editPrescBtnDark"
                }
                startIcon={showPrescription ? <Eye /> : <Edit />}
                onClick={() => {
                  handleClickShowPrescription();
                  console.log("poiuytrewq" , run["docVideoCallpat"] , run)
                  if (run["docVideoCallpat"] === true) {
                    setRun({
                      docPrescriptionVideoDc: true,
                    });
                  }
                }}
              >
                {t("prescription")}
              </Button>

              {!showPrescription &&
                props?.prescriptionData?.investigation?.length > 0 && (
                  <Button
                    className="dfultPrimaryBtn"
                    onClick={() => {
                      submitPrescription("Y");
                    }}
                  >
                    {t("wait_for_test_result")}
                  </Button>
                )}

              {!showPrescription && (
                <Button
                  startIcon={<TextSnippet />}
                  className={
                    props?.prescriptionData?.investigation?.length > 0
                      ? "dfultDarkSecondaryBtn"
                      : "dfultPrimaryBtn"
                  }
                  onClick={() => {
                    submitPrescription("N");
                    if (run["docPrescriptionVideoDc"] === true) {
                      setRun({
                        drFeeDbackDOC: true,
                      });
                    }
                  }}
                >
                  {t("submit")}
                </Button>
              )}
            </div>

            <Button
              className="dfultDarkSecondaryBtn"
              onClick={() => {
                setShowCancellationModule(true);
              }}
            >
              {t("cancel")}
            </Button>
          </div>
        </div>
      )}

      {showCancellationModule && (
        <CancelConsultation
          closeModal={() => {
            setShowCancellationModule(false);
          }}
          closeMeeting={props.closeMeeting}
          extraData={props.extraData}
        />
      )}

      {showFileUploadDialog && (
        <div className="RightFlotingContainerArea">
          <PatientFileUpload
            documents={documents}
            setDocuments={setDocuments}
            onClose={(e) => {
              setShowFileUploadDialog(false);
            }}
            previousMedicalRecords={previousMedicalRecords}
            setPreviousMedicalRecords={setPreviousMedicalRecords}
            onSave={(data) => {
              const reqDto = data.map((item) => {
                return {
                  fileName: item.fileName,
                  fileLink: item.fileLink,
                  fileData: item.fileData,
                  id: item.fileLink,
                };
              });
              // ;
              //
              // centerSocket.emit("uploadFileToDoctor", {
              //   doctorId: extraData?.doctorId,
              //   data: reqDto,
              // });
              zhPatientService
                .uploadDocumentsInVC(
                  props.extraData?.queueVisitId,
                  props.extraData?.queuePatientId,
                  loginUserDetail.orgId,
                  reqDto
                )
                .then((response) => {
                  if (response.data) {
                    toast.success(t("document_uploaded_successfully"));
                    setPreviousDocuments((prev) => {
                      if (!prev) {
                        return [...response.data];
                      } else {
                        return [...prev, ...response.data];
                      }
                    });

                    if (props.extraData?.doctorId) {
                      centerSocket.emit("uploadFileToDoctor", {
                        doctorId: props.extraData?.doctorId,
                        data: { files: response.data },
                      });
                    }
                  }
                })
                .catch((e) => {
                  toast.error(t("document_upload_unsuccessful"));
                });
            }}
          />
        </div>
      )}
    </>
  );
}
