import {
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Search, SortDown, SortUp } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import DashboardServices from "../../../../../../../services/DashboardServices";
import DateUtils from "../../../../../../../utils/DateUtils";
import {
  calculateDateRangeByRange,
  GetLoginUserDetails,
} from "../../../../../../../utils/Utils";

export default function QueueHistoryTable() {
  const { t } = useTranslation();
  const loginUser = GetLoginUserDetails();

  const [selectedOption, setSelectedOption] = useState("U");
  const [selectedRange, setSelectedRange] = useState("Today");
  const [uniqueTimeSlots, setUniqueTimeSlots] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [filterDataList, setFilterDataList] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [sortedBy, setSortedBy] = useState({
    columnName: null,
    direction: null,
  });

  useEffect(() => {
    const parseTime = (time) => {
      const normalizedTime = time.replace(/(am|pm)$/i, (match) =>
        match.toLowerCase()
      );
      return new Date("1970-01-01T" + normalizedTime);
    };

    const fetchData = async () => {
      const { startDate, endDate } = calculateDateRangeByRange(selectedRange);
      const reqDto = {
        orgId: loginUser.orgId,
        fromDate: DateUtils.getDateInDDMMYYYY(startDate),
        toDate: DateUtils.getDateInDDMMYYYY(endDate),
        doctorUnit: selectedOption,
      };
      const response = await DashboardServices.getQueueHistory(reqDto);
      const dataList = response.data ?? [];
      const uniqueTimes = [];
      const mapData = new Map();
      for (let i = 0; i < dataList.length; i++) {
        const { timeSlot, id } = dataList[i];
        if (!uniqueTimes.includes(timeSlot.toLowerCase())) {
          uniqueTimes.push(timeSlot.toLowerCase());
        }
        const filterById = dataList.filter((item) => item.id === id);
        mapData.set(id, {
          name: filterById[0].doctorName || filterById[0].unitName || "",
          specialization: filterById[0].specialization || "",
          data: filterById,
        });
      }
      const sortedTime = uniqueTimes.sort(
        (a, b) => parseTime(a) - parseTime(b)
      );
      const uniqueDataList = [];
      for (let [_, value] of mapData) {
        uniqueDataList.push(value);
      }
      setUniqueTimeSlots([...sortedTime]);
      setDataList([...uniqueDataList]);
      setFilterDataList([...uniqueDataList]);
    };
    if (selectedOption && selectedRange) {
      fetchData();
    }
  }, [selectedOption, selectedRange]);

  useEffect(() => {
    if (searchKey !== "") {
      const key = searchKey.toLowerCase();
      const searchableFields = ["name", "specialization"];
      const tempList = dataList.filter((item) =>
        searchableFields.some((field) =>
          item[field].toString().toLowerCase().includes(key)
        )
      );
      setFilterDataList([...tempList]);
    } else {
      setFilterDataList([...dataList]);
    }
  }, [searchKey]);

  const handleSort = (columnName) => {
    let direction = "ascending";
    if (
      sortedBy.columnName === columnName &&
      sortedBy.direction === "ascending"
    ) {
      direction = "descending";
    }

    const sortedData = [...filterDataList].sort((a, b) => {
      const aValue = a[columnName];
      const bValue = b[columnName];

      if (aValue == null || bValue == null) return 0;

      if (typeof aValue === "string" && typeof bValue === "string") {
        return direction === "ascending"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }

      if (typeof aValue === "number" && typeof bValue === "number") {
        return direction === "ascending" ? aValue - bValue : bValue - aValue;
      }

      if (dayjs(aValue).isValid() && dayjs(bValue).isValid()) {
        return direction === "ascending"
          ? dayjs(aValue).diff(dayjs(bValue))
          : dayjs(bValue).diff(dayjs(aValue));
      }

      return 0;
    });

    setFilterDataList([...sortedData]);
    setSortedBy({ columnName, direction });
  };

  const renderData = (dataList) => {
    const uiList = [];
    for (let i = 0; i < uniqueTimeSlots.length; i++) {
      const time = uniqueTimeSlots[i];
      const findItem = dataList.find(
        ({ timeSlot }) => timeSlot.toLowerCase() === time
      );
      if (findItem) {
        uiList.push(
          <>
            <td key={`a_${i}`}>
              <div className="tdText">{findItem?.avgQueue || 0}</div>
            </td>
            <td key={`b_${i}`}>
              <div className="tdText">{findItem?.avgConsultation || 0}</div>
            </td>
          </>
        );
      }
    }
    return uiList;
  };

  return (
    <>
      <div className="detailsAnalyticTableContainer">
        <div className="cuHeaderGrp">
          <div className="formSearchGrp">
            <div className="searchTasklist">
              <input
                type="text"
                class="serchTaskInput"
                placeholder="Search"
                value={searchKey}
                onChange={(e) => setSearchKey(e.target.value)}
              />
              <IconButton className="seacrhIcon">
                <Search />
              </IconButton>
            </div>
          </div>
          <div className="addUnitsBtn">
            <FormControl size="small" className="formControl SpecialityFilter">
              <Select
                className="formInputField"
                labelId="filterBy"
                id="filterBy-select"
                value={selectedOption}
                onChange={(e) => setSelectedOption(e.target.value)}
              >
                <MenuItem value="D">{t("doctor")}</MenuItem>
                <MenuItem value="U">{t("unit")}</MenuItem>
              </Select>
            </FormControl>

            <FormControl size="small" className="formControl w110">
              <Select
                className="formInputField"
                labelId="filterBy"
                id="filterBy-select"
                value={selectedRange}
                onChange={(e) => setSelectedRange(e.target.value)}
              >
                <MenuItem value="Today">{t("Today")}</MenuItem>
                <MenuItem value="Yesterday">{t("Yesterday")}</MenuItem>
                <MenuItem value="Last 7 Days">{t("Last_7_Days")}</MenuItem>
                <MenuItem value="This Week">{t("This_Week")}</MenuItem>
                <MenuItem value="Last Week">{t("Last_Week")}</MenuItem>
                <MenuItem value="This Month">{t("This_Month")}</MenuItem>
                <MenuItem value="Last Month">{t("Last_Month")}</MenuItem>
                <MenuItem value="Last 30 Days">{t("Last_30_Days")}</MenuItem>
                <MenuItem value="All Time">{t("All_Time")}</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="TableContainer organizationPosition responsiveTable">
          <table className="taskListTable">
            <thead className="taskListtableHeader">
              <tr>
                {selectedOption === "D" && (
                  <>
                    <th onClick={() => handleSort("doctorName")}>
                      {t("dr_name")}
                      {sortedBy.columnName === "doctorName" && (
                        <span>
                          {sortedBy.direction === "ascending" ? (
                            <IconButton className="filterActive">
                              <SortDown />
                            </IconButton>
                          ) : (
                            <IconButton className="filterActive">
                              <SortUp />
                            </IconButton>
                          )}
                        </span>
                      )}
                    </th>
                    <th onClick={() => handleSort("specialization")}>
                      {t("specialization")}
                      {sortedBy.columnName === "specialization" && (
                        <span>
                          {sortedBy.direction === "ascending" ? (
                            <IconButton className="filterActive">
                              <SortDown />
                            </IconButton>
                          ) : (
                            <IconButton className="filterActive">
                              <SortUp />
                            </IconButton>
                          )}
                        </span>
                      )}
                    </th>
                  </>
                )}

                {selectedOption === "U" && (
                  <th onClick={() => handleSort("unitName")}>
                    {t("unit_name")}
                    {sortedBy.columnName === "unitName" && (
                      <span>
                        {sortedBy.direction === "ascending" ? (
                          <IconButton className="filterActive">
                            <SortDown />
                          </IconButton>
                        ) : (
                          <IconButton className="filterActive">
                            <SortUp />
                          </IconButton>
                        )}
                      </span>
                    )}
                  </th>
                )}

                {uniqueTimeSlots.map((time) => (
                  <th key={time} colSpan={2}>
                    {time.toUpperCase()}
                    <div className="aqacGrp">
                      <div className="aqacInd">
                        <Tooltip title="Average Queue" arrow>
                          <div className="aqacTxt">AQ</div>
                        </Tooltip>
                      </div>
                      <div className="aqacInd">
                        <Tooltip title="Average Consultation" arrow>
                          <div className="aqacTxt">AC</div>
                        </Tooltip>
                      </div>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            {filterDataList && filterDataList.length > 0 && (
              <tbody className="scrolableTableBody">
                {filterDataList.map((item, i) => (
                  <tr key={`main_${i}`}>
                    <td>
                      <div className="tdText">{item?.name || ""}</div>
                    </td>

                    {selectedOption === "D" && (
                      <td>
                        <div className="tdText">
                          {item?.specialization || ""}
                        </div>
                      </td>
                    )}

                    {item.data && item.data.length > 0 && renderData(item.data)}
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </>
  );
}
