import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Title,
  Filler,
  Legend,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController,
} from "chart.js";
import { Bar, Chart, Doughnut, Line, Pie } from "react-chartjs-2";
import { Button, IconButton } from "@mui/material";
import { Add } from "@mui/icons-material";
import {
  getDashboardDataOfItem,
  refreshExternalUser,
} from "../../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import { ArrowRepeat } from "react-bootstrap-icons";
import { GetLoginUserDetails } from "../../../../../utils/Utils";

export default function CardExternalUser({
  handelOpenViewExternalUser,
  handelOpenAddExternalUser,
  // chartDataOfItem,
  menuModuleList,
  userMenuList,
}) {
  console.log("menuModuleList", menuModuleList);
  const { t } = useTranslation();
  // const userDetails = jwtDecode( SecureIndexedDB.getItem("token"));

  // const [chartDataOfItem, setChartDataOfItem] = useState([]);

  // useEffect(() => {
  //   getDashboardDataOfItem(userDetails.orgId, userDetails.userId).then(
  //     (response) => {
  //       console.log("getDashboardDataOfItem", response.data);
  //       setChartDataOfItem(response.data);
  //     }
  //   );
  // }, [userDetails.orgId, userDetails.userId]);

  ChartJS.register(
    ArcElement,
    Tooltip,
    Title,
    Filler,
    Legend,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    LineController,
    BarController
  );

  const userDetails = GetLoginUserDetails();
  const [chartDataOfItem, setchartDataOfItem] = useState([]);

  useEffect(() => {
    fetchEmplyCardData();
  }, []);

  const fetchEmplyCardData = () => {
    const reqDto = {
      unitId: userDetails.signleUnitId,
      orgId: userDetails.orgId,
      userId: userDetails.userId,
      periodDailyMnthly: "",
      dataLimit: 0,
      feedbackFromPatientDoctor: "",
      fromDate: "",
      toDate: "",
      unitDoctor: "",
    };
    refreshExternalUser(reqDto).then((response) => {
      console.log("response", response.data);
      setchartDataOfItem(response.data);
    });
  };

  const data = {
    labels: ["Vender", "Client", "Partner "],
    datasets: [
      {
        data: [30, 42, 24],
        backgroundColor: ["#fcba03", "#60ba00", "#8500ba"],
        borderWidth: 1,
        borderColor: ["#292929", "#292929", "#292929"],
      },
    ],
  };

  const options = {
    cutout: "15%",

    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 6,
          color: "#d2d2d2",
          font: {
            size: 12,
            weight: "light",
          },
        },
      },
    },
  };

  // curent People status bar and line chart combo start

  const optionsLeave = {
    responsive: true,
    borderRadius: 4,
    plugins: {
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 12,
          color: "#d2d2d2",
          font: {
            size: 12,
            weight: "light",
          },
        },
      },
      title: {
        display: false,
        text: "FYQ 2nd",
      },
    },
  };

  const labels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const externalUsersWithCategoryCountData =
    chartDataOfItem.externalUsersWithCategoryCount || [];
  console.log(
    "externalUsersWithCategoryCountData",
    externalUsersWithCategoryCountData
  );

  // Extracting labels and counts from externalUsersWithCategoryCountData
  const labelsOfExternalUser = externalUsersWithCategoryCountData.map(
    (data) => data.label
  );
  const counts = externalUsersWithCategoryCountData.map((data) => data.count);

  // Bar chart data
  const dataLeave = {
    labels: labelsOfExternalUser,
    datasets: [
      {
        label: t("count"),
        backgroundColor: "rgba(54, 162, 235, 0.6)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(54, 162, 235, 0.8)",
        hoverBorderColor: "rgba(54, 162, 235, 1)",
        data: counts,
      },
    ],
  };

  // const dataLeave = {
  //   labels,
  //   datasets: [
  //     {
  //       fill: false,
  //       label: "Expense",
  //       data: [10, 12, 13, 14, 12, 11, 12, 13, 11, 12, 14, 15],
  //       borderColor: "rgb(255, 255, 255)",
  //       backgroundColor: "rgba(255, 241, 195, 0.5)",
  //     },
  //     {
  //       label: "Working",
  //       data: [20, 21, 24, 24, 30, 30, 30, 30, 30, 25, 24, 21],
  //       backgroundColor: "rgba(0, 186, 146, 0.8)",
  //       type: "bar",
  //       barThickness: 6,
  //     },
  //     {
  //       label: "Holiday",
  //       data: [14, 18, 13, 34, 12, 11, 10, 13, 14, 12, 15, 12],
  //       backgroundColor: "rgba(255, 170, 41, 0.8)",
  //       type: "bar",
  //       barThickness: 6,
  //     },
  //   ],
  // };
  // curent People status bar and line chart combo end

  return (
    <>
      <div className="anDashIndCard">
        <div className="anDashCardArea">
          <div className="andHeader">
            <div className="andCrdNM">
              {t("external_user")}
              <IconButton
                className={`refreshIcon`}
                onClick={fetchEmplyCardData}
              >
                <ArrowRepeat />
              </IconButton>
            </div>
            <div className="andCrdUnitCount">
              {/* <span title="Attrition">
                    <Male />
                    Avl Stock: <span className="fbold">42%</span>
                  </span> */}

              <span title="Tenure">
                {/* <Female /> */}
                {t("no_of_user")} :{" "}
                <span className="fbold">
                  {chartDataOfItem.noOfExternalUsers}
                </span>
              </span>
            </div>
          </div>

          <div className="andCrdGraf">
            {/* <div className="anDLftGrf">
              <div className="centTxtShow">
                <Pie
                      className="hw100"
                      type="doughnut"
                      data={data}
                      options={options}
                    />
                <div className="highlighted-text">
                  <span>Total</span>
                  <span className="highDataCount">72M</span>
                </div>
              </div>
            </div> */}
            <div className="anDRightGrf oneGraph100">
              {/* <Line
                    className="hw100"
                    type="line"
                    data={dataLeave}
                    options={optionsLeave}
                  /> */}
              <Bar
                className="hw100"
                type="bar"
                data={dataLeave}
                options={optionsLeave}
              />
              {/* <Bar data={barLineData} options={barLineOoptions} /> */}
            </div>
          </div>
          {/* <div className="andCrdData"></div> */}
          <div className="andCrdFooter">
            {userMenuList &&
              menuModuleList &&
              userMenuList.includes(
                menuModuleList.find(
                  (item) => item.menuName === "External User View"
                ).menuId
              ) && (
                <Button
                  className="dfultPrimaryBtn "
                  onClick={() => handelOpenViewExternalUser()}
                >
                  {t("view")}
                </Button>
              )}

            {userMenuList &&
              menuModuleList &&
              userMenuList.includes(
                menuModuleList.find(
                  (item) => item.menuName === "External User Add"
                ).menuId
              ) && (
                <Button
                  startIcon={<Add />}
                  className="dfultPrimaryBtn "
                  onClick={() => handelOpenAddExternalUser()}
                >
                  {t("add")}
                </Button>
              )}
          </div>
        </div>
      </div>
    </>
  );
}
