import React, { useEffect, useState } from "react";
import TaskService from "../../../../services/TaskService";
import { checkOtp } from "../../../../utils/OtpServiceUtils";
import { GetLoginUserDetails } from "../../../../utils/Utils";
import { useTranslation } from "react-i18next";

export const SalaryStructure = ({ data }) => {
  const loginUser = GetLoginUserDetails();
  const [pmsImpactedEmployeeList, setPmsImpactedEmployeeList] = useState([]);

  const [pmsDetails, setPmsDetails] = useState(null);
  const { t } = useTranslation();

  const fetchPmsInfo = async () => {
    try {
      const { otp, requestId } = await checkOtp(
        "/pms/getPmsDetailsToCommunicateEmployee"
      );
      console.log(
        "getPmsDetailsToCommunicateEmployeeOTP ------->>>>",
        otp,
        requestId
      );

      const reqDto = {
        requestId: requestId,
        otp: otp,
        orgId: loginUser.orgId,
        pmsId: parseInt(data.formDataTransId),
      };

      const res = await TaskService.getPmsDetailsToCommunicateEmployee(reqDto);
      if (res.data) {
        let tempList = [];
        const impactedEmployees = res.data.impactedEmployees || [];
        if (impactedEmployees.length > 0) {
          for (let index = 0; index < impactedEmployees.length; index++) {
            const employeeData = impactedEmployees[index];
            if (employeeData.pmsFlag === "C") {
              // tempList.push(employeeData.userId);
              tempList.push(employeeData);
            }
          }
        }
        setPmsImpactedEmployeeList(tempList);
        setPmsDetails(res.data);
      }
    } catch (error) {
      console.error("Error fetching PMS info:", error);
    }
  };

  useEffect(() => {
    if (data && data.formDataTransId) {
      fetchPmsInfo();
    }
  }, [data]);

  useEffect(() => {
    console.log("salary structure =");
  }, []);

  return (
    <div className="elementFormContainer previewSpace ">
      <div className="pmsDetailInfoGrp">
        {/* <div class="flotHedCon">
          {data && data.taskName ? data.taskName : ""}
        </div> */}
        <div className="pmsDtlIndElement">
          <div className="pmsDtlInfoTitle">{t("pmsCycle")}:</div>
          <div className="pmsDtlInfoDescn">
            <span>{pmsDetails?.cycleStartDate}</span>
            {t("to")}
            <span>{pmsDetails?.cycleEndDate}</span>
          </div>
        </div>
        <div className="pmsDtlIndElement">
          <div className="pmsDtlInfoTitle">{t("pmsApprovedBy")} :</div>
          <div className="pmsDtlInfoDescn">
            <span>{pmsDetails?.approvedByUserName}</span>
          </div>
        </div>
        <div className="pmsDtlIndElement">
          <div className="pmsDtlInfoTitle">{t("pmsFinalReviewedBy")} :</div>
          <div className="pmsDtlInfoDescn">
            <span>{pmsDetails?.finalReviewerUserName}</span>
          </div>
        </div>
        <div className="pmsDtlIndElement">
          <div className="pmsDtlInfoTitle">{t("effective_date_of_pms")} :</div>
          <div className="pmsDtlInfoDescn">
            <span>{pmsDetails?.effectiveDate}</span>
          </div>
        </div>
      </div>
      <div class="tskElemHeding">{t("list_of_employees")}</div>
      <div class="indentFormtable">
        <div class="indtableResponsive">
          <table>
            <thead>
              <tr>
                <th className="w200">{t("name")}</th>
                <th>{t("position")}</th>
                <th className="w200 textCenter">{t("hikeRecommendation")}</th>
              </tr>
            </thead>
            <tbody>
              {pmsDetails?.impactedEmployees &&
                pmsDetails?.impactedEmployees?.map((item) => (
                  <tr key={item.pmsEmpId}>
                    <td>
                      <div className="textOnly">{item.employeeName}</div>
                    </td>
                    <td>
                      <div className="textOnly">{item.currentPosition}</div>
                    </td>
                    <td>
                      <div className="textOnly textCenter">{item.hikePc}%</div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
