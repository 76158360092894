import {
  Add,
  ArrowBackIosNew,
  ArrowForwardIos,
  Cancel,
  DescriptionOutlined,
  Download,
  Edit,
  Refresh,
  Save,
} from "@mui/icons-material";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  DeviceHddFill,
  HeartPulse,
  Play,
  Plus,
  Trash,
} from "react-bootstrap-icons";
import Comorbidity from "./Comorbidity";
import DrugAllergy from "./DrugAllergy";
import FamilyHistory from "./FamilyHistory";
import FoodAllergy from "./FoodAllergy";
import HistoryRiskFactor from "./HistoryRiskFactor";
import Investigations from "./Investigations";
import Medication from "./Medication";
import PossibleDiagnosis from "./PossibleDiagnosis";
import Signs from "./Signs";
import Symptoms from "./Symptoms";
import TestResult from "./TestResult";
import Diagnosis from "./Diagnosis";
import Recommendations from "./Recommendations";
import { v4 as uuidv4 } from "uuid";

import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import OtherOptions from "./OtherOptions";
import { downloadFileFromUrl } from "../../../../../../../utils/FileUtils";
import {
  getHealthDto,
  getVitalSvgAccordingToVitalType,
} from "./consultationUtil";
import DashboardServices from "../../../../../../../services/DashboardServices";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { CustomModal } from "../../../../../../task/view/component/Modals/CustomModal";
import RightFlotingAdmin from "../../../../../../admin/view/component/RightFlotingAdmin";
import DifferentialDiagnosis from "./DifferentialDiagnosis";
import { useTranslation } from "react-i18next";
import PhysicalExamination from "./PhysicalExamination";
import axios from "axios";
import zhAIService from "../../../../../../../services/ZhAIService";
import DrugSafety from "./DrugSafety";
import TestResultInput from "./TestResultInput";
import PatientFileUpload from "../../../../../../analyticsDashboard/view/component/healthcare/PatientFileUpload";
import zhPatientService from "../../../../../../../services/ZhPatientService";
import { GetLoginUserDetails } from "../../../../../../../utils/Utils";
import { centerSocket, doctorSocket } from "../../../../../../../socket";
import { FileCursor } from "../../../../../../../common/FileCursor";
import { PatientVitals } from "../../../../../../analyticsDashboard/view/component/healthcare/PatientVitals";
import { Message } from "./Message";
import DateUtils from "../../../../../../../utils/DateUtils";
import { PatientVitalDoctorSide } from "../../../../../../analyticsDashboard/view/component/healthcare/PatientVitalDoctorSide";
import SecureIndexedDB from "../../../../../../../utils/IndexedDB";
import Joyride from "react-joyride";
import { TourContext } from "../../../../../../../common/TourProvider";
import { useValidateVital } from "../../../../../../analyticsDashboard/view/component/healthcare/hook/useValidateVital";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function PrescriptionEdit({
  updateData,
  data,
  extraData,
  setShowFileUploadDialog,
  setPreviousDocuments,

  prevDocuments,
}) {
  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);
  console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx", extraData);
  const { t } = useTranslation();
  const { validateVitals, VitalErrorList } = useValidateVital();

  const loginUserDetails = GetLoginUserDetails();
  const [vitals, setVitals] = useState([]);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const audioUrl = useRef();
  const [height, setHeight] = useState(null);
  const [weight, setWeight] = useState(null);
  const [bmi, setBmi] = useState("");
  const [heightOutput, setHeightOutput] = useState("");
  const [weightOutput, setWeightOutput] = useState("");
  const [bmiClass, setBmiClass] = useState("");
  const [selectedGroup, setSelectedGroup] = useState(1);
  const [pescriptionData, setPescriptionData] = useState({});
  const [patientType, setPatientType] = useState("statNormal");
  const [vitalUnitList, setVitalUnitList] = useState([]);

  const [vitalUnitMap, setVitalUnitMap] = useState(null);
  const [showAudioModal, setShowAudioModal] = useState(false);
  const [selectedAbuse, setSelectedAbuse] = useState(null);
  const [abuseInputString, setAbuseInputString] = useState("");

  const [lmp, setLmp] = useState(null);

  // const [VitalErrorList, setVitalErrorList] = useState([]);
  // const [showFileUploadDialog, setShowFileUploadDialog] = useState(false);
  // const [documents, setDocuments] = useState([]);
  // const [previousMedicalRecords, setPreviousMedicalRecords] = useState(null);
  // const [prevDocuments, setPreviousDocuments] = useState([]);
  const [systemicIllnessList, setSystemicIllnessList] = useState([]);
  const [systemicAbuseList, setSystemicAbuseList] = useState([]);
  const [illnessName, setIllnessName] = useState([]);
  const [abuseName, setAbuseName] = useState([]);
  const [systemicIllness, setSystemicIllness] = useState("no");
  const [substanceAbuse, setSubstanceAbuse] = useState("no");

  const bmiGroupNamesMap = {
    Underweight: "underweight",
    Normal: "normal",
    "Pre-obesity": "pre_obesity",
    "Obese I": "obese_i",
    "Obese II": "obese_ii",
    "Obese III": "obese_iii",
  };

  useEffect(() => {
    setBookSteps([
      {
        target: ".docPatVideoStepTwo",
        content: "Here, you can view the patient's name, ID, gender, and age.",
        disableBeacon: true,
      },
      {
        target: ".docPatVideoStepThree",
        content: "Here, you can see the patient's blood group.",
        disableBeacon: true,
      },
      {
        target: ".docPatVideoStepFour",
        content: "Here, you can view the referring doctor or source.",
        disableBeacon: true,
      },
      {
        target: "#docPatVideoStepFive",
        content: "Here, you can see and update the patient's vitals.",
        disableBeacon: true,
      },
      {
        target: ".docPatVideoStepSix",
        content: "Here, you can view the patient's BMI.",
        disableBeacon: true,
      },
      {
        target: ".docPatVideoStepSeven",
        content:
          "Here, you can see and update the patient's systemic illnesses.",
        disableBeacon: true,
      },
      {
        target: ".docPatVideoStepEight",
        content:
          "Here, you can view and update the patient's substance abuse history.",
        disableBeacon: true,
      },
      {
        target: ".docPatVideoStepNine",
        content:
          "Here, you can access data on the patient's systemic illnesses.",
        disableBeacon: true,
      },
      {
        target: ".docPatVideoStepTen",
        content: "Here, you can access data on the patient's substance abuse.",
        disableBeacon: true,
      },
      {
        target: ".docPatVideoStepEleven",
        content: "Here, you can listen to the patient's audio file.",
        disableBeacon: true,
      },
      {
        target: ".docPatVideoStepTwelve",
        content: "Here, you can view the files uploaded by the patient.",
        disableBeacon: true,
      },
      {
        target: "#docPatVideoStepThirteen",
        content: "Here, you can see and Add the patient's symptoms.",
        disableBeacon: true,
      },
      {
        target: "#docPatVideoStepFourteen",
        content: "Here, you can view and Add the patient's drug allergies.",
        disableBeacon: true,
      },
      {
        target: "#docPatVideoStepFifteen",
        content: "Here, you can view and Add the patient's food allergies.",
        disableBeacon: true,
      },
      {
        target: "#docPatVideoStepsixteen",
        content: "Here, you can see and Add the patient's comorbidities.",
        disableBeacon: true,
      },
      {
        target: "#docPatVideoStepSeventeen",
        content: "Here, you can view the patient's physical signs.",
        disableBeacon: true,
      },
      {
        target: "#docPatVideoStepEighteen",
        content:
          "Here, you can view and Add the patient's physical examination.",
        disableBeacon: true,
      },
      {
        target: "#docPatVideoStepNineteen",
        content:
          "Here, you can see and Add the patient's history and risk factors.",
        disableBeacon: true,
      },
      {
        target: "#docPatVideoStepTwenty",
        content: "Here, you can view and Add the patient's family history.",
        disableBeacon: true,
      },
      {
        target: "#docPatVideoStepTwentyOne",
        content:
          "Here, you can see and Add possible diagnoses for the patient.",
        disableBeacon: true,
      },
      {
        target: "#docPatVideoStepTwentyTwo",
        content:
          "Here, you can view and Add investigations related to the patient.",
        disableBeacon: true,
      },
      {
        target: "#docPatVideoStepTwentyThree",
        content: "Here, you can access the patient's test results.",
        disableBeacon: true,
      },
      {
        target: "#docPatVideoStepTwentyFour",
        content: "Here, you can input the patient's test results.",
        disableBeacon: true,
      },
      {
        target: "#docPatVideoStepTwentyFive",
        content: "Here, you can see and Add the patient's final diagnosis.",
        disableBeacon: true,
      },
      {
        target: "#docPatVideoStepTwentySix",
        content:
          "Here, you can view and Add medications prescribed by the doctor.",
        disableBeacon: true,
      },
      {
        target: "#docPatVideoStepTwentySeven",
        content: "Here, you can see and Add the doctor's recommendations.",
        disableBeacon: true,
      },
      {
        target: "#docPatVideoStepTwentyEight",
        content:
          "Here, you can find other suggestions provided by the doctor Such as 'Parameter to be monitor' , 'doctor note' , 'watch out for' and more . Then Click on Prescription Button ",
        disableBeacon: true,
      },
      {
        target: "#docPatVideoStepTwentyNine",
        content: "Here, you can view patient-related messages.",
        disableBeacon: true,
      },
      {
        target: ".docPatVideoStepThirty",
        content: "Here, you can listen to audio files.",
        disableBeacon: true,
      },
      // {
      //     target: ".docPatVideoStepThirtyOne",
      //     content: "Here, you can save the data.",
      //     disableBeacon: true,
      // },
      // {
      //     target: ".docPatVideoStepThirtyTwo",
      //     content: "Here, you can edit the data.",
      //     disableBeacon: true,
      // },
    ]);
  }, []);

  const getSystemicIllness = async () => {
    const brandString = await SecureIndexedDB.getItem("substanceIllnessList");
    if (brandString) {
      setSystemicIllnessList(JSON.parse(brandString));
      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getSystemicIllness(reqDto).then((response) => {
      if (response.data) {
        setSystemicIllnessList(response.data);
        SecureIndexedDB.setItem(
          "substanceIllnessList",
          JSON.stringify(response.data)
        );
      }
    });
  };

  const getSubstanceAbuse = async () => {
    const brandString = await SecureIndexedDB.getItem("substanceAbuseList");
    if (brandString) {
      setSystemicAbuseList(JSON.parse(brandString));
      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getSubstanceAbuse(reqDto).then((response) => {
      if (response.data) {
        setSystemicAbuseList(response.data);
        SecureIndexedDB.setItem(
          "substanceAbuseList",
          JSON.stringify(response.data)
        );
      }
    });
  };

  const handleChangeAbuse = (event) => {
    const {
      target: { value },
    } = event;
    let tmp = typeof value === "string" ? value.split(",") : value;
    setAbuseName(tmp);

    updateData({ substanceAbuse: tmp });
  };

  const calculateBmi = () => {
    const heightInMeters = height / 100;
    const bmiValue = parseFloat(weight / heightInMeters ** 2).toFixed(2);
    const bmiGroupIndex = [
      [0, 18.49], //under weight
      [18.5, 24.99], //normal
      [25, 29.99], //overweight
      [30, 34.99], //
      [35, 39.99],
      [40, 600],
    ].findIndex((e) => e[0] <= bmiValue && bmiValue < e[1]);

    const bmiGroupNames = [
      "Underweight",
      "Normal",
      "Pre-obesity",
      "Obese I",
      "Obese II",
      "Obese III",
    ];

    const heightInInches = (height * 0.393700787).toFixed(0);
    const feet = Math.floor(heightInInches / 12);
    const inches = heightInInches % 12;

    setHeightOutput(`${height} cm / ${feet}' ${inches}"`);
    setWeightOutput(`${weight} kg / ${(2.2046 * weight).toFixed(2)} lb`);
    setBmi(bmiValue);
    setSelectedGroup(bmiGroupIndex);

    updateData({ bmiValue: bmiValue });
    updateData({ bmiType: bmiGroupNames[bmiGroupIndex] });

    const bmiClasses = [
      "bmi-underweight",
      "bmi-normal",
      "bmi-preobesity",
      "bmi-obese1",
      "bmi-obese2",
      "bmi-obese3",
    ];
    setBmiClass(bmiClasses[bmiGroupIndex]);
  };

  const convertHeightInCm = (value, unit) => {
    if (unit === "m") {
      return value * 100;
    } else if (unit === "cm") {
      return value;
    }
  };

  const filteredSystemicAbuses = useMemo(() => {
    const abuseIds = abuseName?.map((item) => item.id);
    const tempAbuses = systemicAbuseList?.filter((item) => {
      return !abuseIds?.includes(item.id);
    });
    return tempAbuses || [];
  }, [systemicAbuseList, abuseName]);

  const convertWeightInKg = (value, unit) => {
    if (unit === "kgs") {
      return value;
    } else if (unit === "lbs") {
      return value * 0.45359237;
    }
  };

  const getAge = () => {
    const ageComponent = extraData.queuePatientAge.split(" ");
    if (ageComponent[0].includes("Y")) {
      return parseInt(ageComponent[0].split("Y")[0]) || 0;
    } else {
      return 0;
    }
  };

  const getUnitFromVitalCode = (vitalCode) => {
    const tempValue = vitalUnitMap?.[vitalCode];
    return tempValue?.[0] || null;
  };

  const selectSystemicIllness = (event) => {
    const value = event.target.value;
    if (value === "no") {
      setIllnessName([]);
      updateData({ systemicIllnesses: [] });
    } else {
      const tempIllness = extraData?.reqValue?.systemicIllnesses
        ?.map((item) => {
          const illnessName = systemicIllnessList?.find(
            (illness) => illness.systemicIllnessName === item
          );
          return illnessName;
        })
        ?.filter((item) => (item ? true : false));

      setIllnessName(tempIllness || []);
      updateData({ systemicIllnesses: tempIllness || [] });
    }
    setSystemicIllness(event.target.value);
  };

  const selectSubstanceAbuse = (event) => {
    const value = event.target.value;
    if (value === "no") {
      setAbuseName([]);
      updateData({ substanceAbuse: [] });
    } else {
      const tempIllness = extraData?.reqValue?.substanceAbuse
        ?.map((item) => {
          const illnessName = systemicAbuseList?.find(
            (illness) => illness.substanceAbuseName === item
          );
          return illnessName;
        })
        ?.filter((item) => (item ? true : false));

      setAbuseName(tempIllness || []);
      updateData({ substanceAbuse: tempIllness || [] });
    }
    setSubstanceAbuse(value);
  };

  const handleChangeIllness = (event) => {
    // ;
    const {
      target: { value },
    } = event;
    console.log("substance vaue=", value);
    const tmp = typeof value === "string" ? value.split(",") : value;
    setIllnessName(tmp);
    updateData({ systemicIllnesses: tmp });
  };

  const getUserPreviousDocuments = () => {
    if (extraData?.queuePatientId) {
      const visitData = {
        visitId: extraData?.queueVisitId,
        patientId: extraData?.queuePatientId,
        orgId: loginUserDetails.orgId,
        doctorId: loginUserDetails.userId,
        unitId: loginUserDetails.signleUnitId,
      };

      // zhPatientVisit/getDocumentsInConsultationOnRefresh/783

      zhPatientService
        .getDocumentsInConsultationOnRefresh(extraData?.queueVisitId)
        .then((response) => {
          if (response.data) {
            // const { docs } = response.data;
            setPreviousDocuments(response.data);
          }
        });
    }
  };

  useEffect(() => {
    if (data && data.vitals) {
      setVitals(data.vitals);
    }
  }, [data]);

  useEffect(() => {
    if (systemicAbuseList) {
      const tempIllness = extraData?.reqValue?.substanceAbuse
        ?.map((item) => {
          const illnessName = systemicAbuseList?.find(
            (illness) => illness.substanceAbuseName === item
          );
          return illnessName;
        })
        ?.filter((item) => (item ? true : false));
      if (tempIllness?.length > 0) {
        setSubstanceAbuse("yes");
      }
      setAbuseName(tempIllness || []);
      // updateData({ substanceAbuse: tempIllness || [] });
    }
  }, [systemicAbuseList]);

  useEffect(() => {
    if (systemicIllnessList) {
      const tempIllness = extraData?.reqValue?.systemicIllnesses
        ?.map((item) => {
          const illnessName = systemicIllnessList?.find(
            (illness) => illness.systemicIllnessName === item
          );
          return illnessName;
        })
        ?.filter((item) => (item ? true : false));
      if (tempIllness?.length > 0) {
        setSystemicIllness("yes");
      }
      setIllnessName(tempIllness || []);
      // updateData({ systemicIllnesses: tempIllness || [] });
    }
  }, [systemicIllnessList]);

  const getDummyVital = (vitalCode, vitalUnitList) => {
    let vitalNameMap =
      vitalUnitList?.reduce((acc, curr) => {
        const temp = { ...acc };
        const { vitalCode, vitalName } = curr;

        if (temp[vitalCode]) {
          return temp;
        } else {
          temp[vitalCode] = vitalName;
          return temp;
        }
      }, {}) || {};

    // let vitalNameMap = {
    //   TEMPERATURE: "Temperature",
    //   BP: "Blood Pressure",
    //   HEART_RATE: "Heart Rate",
    //   RESPIRATION_RATE: "Respiration Rate",
    //   SPO2: "SPO2",
    //   WEIGHT: "Weight",
    //   HEIGHT: "Height",
    //   HEAD_CIRCUMFERENCE: "Head Circumference",
    //   UPPER_ARM_CIRCUMFERENCE: "Upper Arm Circumference",
    //   LMP: "LMP",
    // };

    let vitalUnitMap = vitalUnitList?.reduce((acc, curr) => {
      const temp = { ...acc };
      const { vitalCode, vitalUnit } = curr;

      if (temp[vitalCode]) {
        return temp;
      } else {
        temp[vitalCode] = vitalUnit;
        return temp;
      }
    }, {});
    console.log("vitalNameMap=", vitalNameMap);
    console.log("vitalUnitMap=", vitalUnitMap);

    // let vitalUnitMap = {
    //   TEMPERATURE: "°F",
    //   BP: "mmHg",
    //   HEART_RATE: "bpm",
    //   RESPIRATION_RATE: "breaths/min",
    //   SPO2: "%",
    //   WEIGHT: "kg",
    //   HEIGHT: "cm",
    //   HEAD_CIRCUMFERENCE: "cm",
    //   UPPER_ARM_CIRCUMFERENCE: "cm",
    //   LMP: "",
    // };

    let tempVital = {
      vitalName: vitalNameMap[vitalCode],
      unit: vitalUnitMap[vitalCode] || null,
      vitalValue: vitalCode === "BP" ? "/" : "",
      // recodedTime: "",
      isNormal: "Y",
      vitalCode,
      updatedData: {
        vitalValue: vitalCode === "BP" ? "/" : "",
        unit: vitalUnitMap[vitalCode] || null,
        foot: "",
        inch: "",
      },
    };
    return tempVital;
  };

  useEffect(() => {
    console.log("extraData=", extraData);
    getUserPreviousDocuments();
    getSystemicIllness();
    getSubstanceAbuse();

    if (extraData) {
      audioUrl.current = extraData?.reqValue?.audioNoteFileUrl;
    }

    if (extraData?.reqValue?.docs?.length > 0) {
      setPreviousDocuments(extraData?.reqValue?.docs);
    }

    if (
      extraData &&
      extraData.queueConsultationType &&
      extraData.queueConsultationType.toLowerCase().includes("opd")
    ) {
      setPatientType("statNormal");
    } else if (
      extraData &&
      extraData.queueConsultationType &&
      extraData.queueConsultationType.toLowerCase().includes("urgent")
    ) {
      setPatientType("statImportant");
    } else if (
      extraData &&
      extraData.queueConsultationType &&
      extraData.queueConsultationType.toLowerCase().includes("review")
    ) {
      setPatientType("statReview");
    } else if (
      extraData &&
      extraData.queueConsultationType &&
      extraData.queueConsultationType.toLowerCase().includes("emergency")
    ) {
      setPatientType("statUrgent");
    }

    // calculateBmi();
  }, []);

  const handleFileData = (payload) => {
    const { data } = payload;
    const { vitals, files } = data;
    if (files) {
      getUserPreviousDocuments();
    }
    if (vitals) {
      setVitals(vitals);
    }
    // setPreviousDocuments((prev) => {
    //   return [...prev, ...data];
    // });
  };

  useEffect(() => {
    doctorSocket.on("uploadFileFromCenter", handleFileData);
    return () => {
      doctorSocket.off("uploadFileFromCenter", handleFileData);
    };
  }, [doctorSocket]);

  const getVitalUnits = async () => {
    const diseasetring = await SecureIndexedDB.getItem("vitalUnitList");
    let tempVitalUnitlist = [];
    if (diseasetring) {
      tempVitalUnitlist = JSON.parse(diseasetring);
      setVitalUnitList(tempVitalUnitlist);
      return tempVitalUnitlist;
    }

    const reqDto = await getHealthDto();
    tempVitalUnitlist = await DashboardServices.getVitalUnits(reqDto).then(
      (response) => {
        if (response.data) {
          setVitalUnitList(response.data);
          SecureIndexedDB.setItem(
            "vitalUnitList",
            JSON.stringify(response.data)
          );
        }
      }
    );
    return tempVitalUnitlist;
  };

  useEffect(() => {
    const height = vitals?.find((item) => item.vitalCode === "HEIGHT");
    const weight = vitals?.find((item) => item.vitalCode === "WEIGHT");
    if (height) {
      let htValue = parseFloat(height?.vitalValue);
      let htUnit = height.unit;
      if (htUnit === "ft~in") {
        htValue = htValue * 2.54;
      }
      setHeight(htValue || null);
    }

    if (weight) {
      let wtValue = parseFloat(weight?.vitalValue);
      let wtUnit = weight.unit;
      if (wtUnit === "lb") {
        wtValue = Math.floor(wtValue * 0.453592);
      }
      setWeight(wtValue || null);
    }
  }, [vitals]);

  useEffect(() => {
    if (height && weight) {
      calculateBmi();
    }
  }, [height, weight]);

  // document start
  const elementRef = useRef(null);
  const fileElementRef = useRef(null);
  const [filearrowDisable, setFilearrowDisable] = useState(true);
  const filehandleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setFilearrowDisable(true);
      } else {
        setFilearrowDisable(false);
      }
    }, speed);
  };

  const [fileDivWidth, setFileDivWidth] = useState(0);

  useEffect(() => {
    if (fileElementRef.current) {
      setFileDivWidth(fileElementRef.current.offsetWidth);
    }
  }, [fileElementRef]);

  const [tempDivWidth, setTempDivWidth] = useState(0);

  useEffect(() => {
    if (elementRef.current) {
      setTempDivWidth(elementRef.current.offsetWidth);
    }
  }, [elementRef]);

  // const getVitalSvgAccordingToVitalType = (vitalType) => {
  //   if (vitalType === "BP") {
  //     return <BP />;
  //   } else if (vitalType === "Pulse") {
  //     return <HeartPulse />;
  //   } else if (vitalType === "SPO2") {
  //     return <SPO2 />;
  //   } else if (vitalType === "Temperature") {
  //     return <ThermometerHalf />;
  //   } else if (vitalType === "RR") {
  //     return <Lungs />;
  //   } else if (vitalType === "Weight") {
  //     return <MonitorWeightOutlined />;
  //   } else if (vitalType === "Height") {
  //     return <HeightOutlined />;
  //   }
  // };
  // document end

  // edit vitals
  const [editMode, setEditMode] = useState(false);

  const handleSaveVitals = (vitals) => {
    if (editMode) {
      const mapedVitals = vitals?.reduce((acc, curr) => {
        const { vitalCode } = curr;
        acc[vitalCode] = curr;
        return acc;
      }, {});
      if (
        !validateVitals(
          mapedVitals,
          getAge(extraData.queuePatientAge),
          extraData?.queuePatientGender
        )
      ) {
        setShowConfirmation(true);
        return;
      }

      const tempVitals = vitals.map((item) => ({
        ...item,
        vitalValue: item?.updatedData?.vitalValue,
        unit: item.updatedData.unit,
      }));
      setVitals(tempVitals);
      // updateData({ vitals: tempVitals });
    } else {
      const tempVitals = vitals.map((item) => {
        let foot = 0;
        let inch = 0;
        if (item.unit === "ft~in") {
          foot = Math.floor(parseInt(item?.vitalValue) / 12);
          inch = parseInt(item?.vitalValue) % 12;
        }
        if (isNaN(foot)) {
          foot = 0;
        }
        if (isNaN(inch)) {
          inch = 0;
        }
        return {
          ...item,
          updatedData: {
            vitalValue: item?.vitalValue,
            unit: item.unit,
            foot,
            inch,
          },
        };
      });
      setVitals(tempVitals);
      centerSocket.emit("uploadFileToDoctor", {
        doctorId: extraData?.doctorId,
        data: { vitals: tempVitals },
      });
    }
  };

  // const validateVitals = (vitals) => {
  //   const tempVitals = [...vitals];
  //   let isValid = true;
  //   const problemList = [];

  //   const bp = vitals.find((item) => item.vitalCode === "BP");
  //   const temp = vitals.find((item) => item.vitalCode === "TEMPERATURE");
  //   if (bp) {
  //     const bpList = bp?.updatedData?.vitalValue.split("/");
  //     const sistolic = parseInt(bpList[0]);
  //     const diastolic = parseInt(bpList[1]);
  //     if (sistolic || diastolic) {
  //       if (sistolic === "" || !sistolic) {
  //         problemList.push("Sistolic pressure cannot be empty");
  //         isValid = false;
  //       }

  //       if (diastolic === "" || !diastolic) {
  //         problemList.push("Diastolic pressure cannot be empty");
  //         isValid = false;
  //       }

  //       if (sistolic < diastolic) {
  //         problemList.push("Sistolic pressure must be greater than diastolic");
  //         toast.error(t("sistolic_pressure_must_be_greater_than_diastolic"));
  //         isValid = false;
  //       }

  //       if (sistolic - diastolic < 30) {
  //         problemList.push(
  //           "Sistolic and diastolic pressure difference must be greater than 30"
  //         );
  //         toast.error(
  //           t(
  //             "sistolic_and_diastolic_pressure_difference_must_be_greater_than_thirty"
  //           )
  //         );
  //         isValid = false;
  //       }
  //     }
  //   }

  //   if (temp) {
  //     const unit = temp.updatedData.unit;
  //     const value = parseFloat(temp?.updatedData?.vitalValue);
  //     if (unit.includes("C")) {
  //       if (value > 41.1 || value < 32.22) {
  //         problemList.push("Temparature must be within 32.22 to 41.10");
  //         toast.error(t("temparature_must_be_within_thirty_two_and_forty_one"));
  //         isValid = false;
  //       }
  //     } else if (unit.includes("F")) {
  //       if (value > 106 || value < 90) {
  //         problemList.push("Temparature must be within 90 to 106");
  //         toast.error(
  //           t("temparature_must_be_within_ninety_to_one_hundred_six")
  //         );
  //         isValid = false;
  //       }
  //     }
  //   }

  //   setVitalErrorList(problemList);

  //   if (!isValid) {
  //     setShowConfirmation(true);
  //   }

  //   return isValid;
  // };

  const mapUnitsToVitals = () => {
    const tempVitalUnitMap = {};
    vitalUnitList.forEach((item) => {
      if (!tempVitalUnitMap[item.vitalCode]) {
        tempVitalUnitMap[item.vitalCode] = [item.vitalUnit];
      } else {
        tempVitalUnitMap[item.vitalCode].push(item.vitalUnit);
      }
    });
    setVitalUnitMap(tempVitalUnitMap);
  };

  useEffect(() => {
    if (vitalUnitList && vitalUnitList.length > 0) {
      mapUnitsToVitals();
    }
  }, [vitalUnitList]);

  //DIFFENTIAL DIAGNOSTICS
  const [showDifferentialDiagonesis, setShowDifferentialDiagonesis] =
    useState(false);
  const closeDifferentialDiagonesis = () => {
    setShowDifferentialDiagonesis(false);
  };

  const [differentialDiagnosisData, setDifferentialDiagnosisData] =
    useState(null);

  //Drug Safety
  const [showDrugSafety, setShowDrugSafety] = useState(false);
  const closeDrugSafety = () => {
    setShowDrugSafety(false);
  };

  const [drugSafetyData, setDrugSafetyData] = useState(null);

  console.log(
    " prescription edit docVideoCallpat",
    run["docVideoCallpat"],
    run
  );

  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["docVideoCallpat"] === true}
        continuous={true}
        showSkipButton={true}
        scrollOffset={200}
        spotlightPadding={10}
        styles={{
          buttonClose: {
            display: "none",
          },
        }}
        locale={{
          last: "Next",
        }}
      />
      {/* <div className="MeetingTranscriptionContainer">
      </div> */}
      <div className="Meeting_Transcription">
        <div className="elementFormContainer">
          <div className="tskPriotGrp">
            <div class={`tskPriot ${patientType}`}>
              <div class="priStat">{extraData.queueConsultationType}</div>
            </div>
            {/* <div class="tskPriot statImportant">
                <div class="priStat">Urgent</div>
              </div>
              <div class="tskPriot statReview">
                <div class="priStat">Review</div>
              </div>
              <div class="tskPriot statUrgent">
                <div class="priStat">Emergency</div>
              </div> */}
          </div>
          <div className="pDemography docPatVideoStepTwo">
            <div className="pDemogInd">
              {/* <span>Name:</span> */}
              <span className="pDVlue">{extraData.queuePatientName}</span>
            </div>
            <div className="pDemogInd">
              {/* <span>Name:</span> */}
              <span className="pDVlue">
                {extraData.queuePatientExternalSourceId
                  ? extraData.queuePatientExternalSourceId
                  : extraData.queuePatientIdDisplay}
              </span>
            </div>
            <div className="pDemogInd">
              {/* <span>Gender:</span> */}
              {/* <span className="pDVlue">{extraData.queuePatientGender}</span> */}
              <span className="pDVlue">
                {extraData?.queuePatientGender === "Male"
                  ? t("male")
                  : extraData?.queuePatientGender === "Female"
                  ? t("female")
                  : t("other")}
              </span>
            </div>
            <div className="pDemogInd">
              {/* <span>Age:</span> */}
              <span className="pDVlue">{extraData.queuePatientAge}</span>
            </div>

            {extraData?.reqValue?.insurance && (
              <div className="pDemogInd">
                <span>{t("insurance")}:</span>
                <span className="pDVlue">{t("yes")}</span>
              </div>
            )}

            {/* <div className="pDemogInd">
                <span>Card:</span>
                <span className="pDVlue">DADS2012541LP</span>
              </div> */}
          </div>
          <div className="pDemography">
            {extraData?.bloodGroup && (
              <div className="pDemogInd docPatVideoStepThree">
                <span>{t("blood_group")}:</span>
                <span className="pDVlue">{extraData?.bloodGroup}</span>
              </div>
            )}
          </div>
          {extraData?.queueReferredBy && (
            <div className="taskElementsGrpWithTwo mt10 docPatVideoStepFour">
              <div className="taskElementGrp">
                <div className="tskElemHeding">{t("refer_by")}</div>
                <div className="tskElemntInfo">{extraData.queueReferredBy}</div>
              </div>
            </div>
          )}
          {}

          <div id="docPatVideoStepFive">
            <PatientVitalDoctorSide
              data={data}
              patientAge={getAge(extraData.queuePatientAge)}
              patientGender={extraData.queuePatientGender}
              showBloodgroup={false}
              handleDataChange={(data) => {}}
              handleChangeEditMode={(mode) => {
                setEditMode(mode);
              }}
              showSaveButton={true}
              handleSaveVitals={(data) => {
                if (data) {
                  // handleSaveVitals(data);
                  setVitals(data);
                  centerSocket.emit("uploadFileToDoctor", {
                    doctorId: extraData?.doctorId,
                    data: { vitals: data },
                  });
                }
              }}
              extraData={extraData}
              updateData={updateData}
            />
          </div>

          {height && weight && (
            <div className="bmiGroup docPatVideoStepSix">
              <div className="c-bmi__result">
                {t("bmi")}:
                <span name="r" className={` ${bmiClass}`}>
                  {bmi}
                </span>
              </div>
              <div className="c-bmi__groups" readOnly>
                {[
                  "Underweight",
                  "Normal",
                  "Pre-obesity",
                  "Obese I",
                  "Obese II",
                  "Obese III",
                ].map((label, index) => (
                  <div key={index}>
                    <input
                      type="radio"
                      className="bmiInputRadio"
                      id={`bmi-g${index}`}
                      name="g"
                      checked={selectedGroup === index}
                      readOnly
                    />
                    <label htmlFor={`bmi-g${index}`}>
                      {t(bmiGroupNamesMap[label])}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          )}
          {/* <div className="taskElementGrp mt10">
              <div className="tskElemHeding">{t("patient_complaints")}</div>
              <div className="tskElemntInfo">{extraData?.queueComplaints}</div>
            </div> */}

          {extraData?.isDoctor && (
            <div className="taskElementGrp mt10 docPatVideoStepSeven">
              <div className="tskElemHeding">{t("systemic_illness")}</div>

              <div className="formElementGrp flex-wrap">
                <div className="formElement ">
                  <FormControl className="formControl">
                    <RadioGroup
                      className="formRadioGroup"
                      labelId="setTasktype"
                      name="setTasktype"
                      value={systemicIllness}
                      onChange={selectSystemicIllness}
                    >
                      <FormControlLabel
                        className="formRadioField"
                        value="yes"
                        control={<Radio />}
                        label={t("yes")}
                      />
                      <FormControlLabel
                        className="formRadioField"
                        value="no"
                        control={<Radio />}
                        label={t("no")}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                {systemicIllness === "yes" && (
                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel id="demo-multiple-checkbox-label">
                        {t("systemic_illness")}
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        className="formInputField"
                        variant="outlined"
                        value={illnessName}
                        onChange={handleChangeIllness}
                        input={<OutlinedInput label={t("systemic_illness")} />}
                        renderValue={(selected) =>
                          selected
                            .map((item) => item.systemicIllnessName)
                            .join(", ")
                        }
                        MenuProps={MenuProps}
                      >
                        {systemicIllnessList.map((item) => (
                          <MenuItem key={item.id} value={item}>
                            <Checkbox
                              checked={
                                illnessName.findIndex(
                                  (element) => element.id === item.id
                                ) > -1
                              }
                            />
                            <ListItemText
                              primary={`${item.systemicIllnessName}`}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                )}
              </div>
            </div>
          )}

          {extraData?.isDoctor && (
            <div className="taskElementGrp mt10 docPatVideoStepEight">
              <div className="tskElemHeding">{t("substance_abuse")}</div>

              <div className="formElementGrp flex-wrap">
                <div className="formElement ">
                  <FormControl className="formControl">
                    <RadioGroup
                      className="formRadioGroup"
                      labelId="setTasktype"
                      name="setTasktype"
                      value={substanceAbuse}
                      onChange={selectSubstanceAbuse}
                    >
                      <FormControlLabel
                        className="formRadioField"
                        value="yes"
                        control={<Radio />}
                        label={t("yes")}
                      />
                      <FormControlLabel
                        className="formRadioField"
                        value="no"
                        control={<Radio />}
                        label={t("no")}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                {substanceAbuse === "yes" && (
                  // <div className="formElement">
                  //   <FormControl className="formControl">
                  //     <InputLabel id="demo-multiple-checkbox-label">
                  //       {t("substance_abuse")}
                  //     </InputLabel>
                  //     <Select
                  //       labelId="demo-multiple-checkbox-label"
                  //       id="demo-multiple-checkbox"
                  //       multiple
                  //       className="formInputField"
                  //       variant="outlined"
                  //       value={abuseName}
                  //       onChange={handleChangeAbuse}
                  //       input={<OutlinedInput label={t("substance_abuse")} />}
                  //       renderValue={(selected) =>
                  //         selected
                  //           .map((item) => item.substanceAbuseName)
                  //           .join(", ")
                  //       }
                  //       MenuProps={MenuProps}
                  //     >
                  //       {systemicAbuseList.map((item) => (
                  //         <MenuItem key={item.id} value={item}>
                  //           <Checkbox
                  //             checked={
                  //               abuseName.findIndex(
                  //                 (element) => element.id === item.id
                  //               ) > -1
                  //             }
                  //           />
                  //           <ListItemText primary={item.substanceAbuseName} />
                  //         </MenuItem>
                  //       ))}
                  //     </Select>
                  //   </FormControl>
                  // </div>
                  <>
                    <div className="addNewformGrp">
                      <div className="formElement">
                        <FormControl className="formControl">
                          <Autocomplete
                            // multiple
                            freeSolo
                            className="formAutocompleteField criticalRightStepSix"
                            variant="outlined"
                            value={selectedAbuse}
                            // options={systemicAbuseList}
                            options={filteredSystemicAbuses}
                            getOptionLabel={(option) =>
                              option.substanceAbuseName
                            }
                            inputValue={abuseInputString}
                            onInputChange={(e, value, reason) => {
                              setAbuseInputString(value);
                            }}
                            onChange={(e, newValue) => {
                              if (newValue) {
                                setSelectedAbuse(newValue);
                              }
                            }}
                            renderOption={(props, item) => {
                              return (
                                <li {...props} key={item.id}>
                                  {item.substanceAbuseName}
                                </li>
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                className="formAutoComInputField autocompFildPlaceholder"
                                // placeholder={t("select_position")}
                                label={t("substance_abuse")}
                                InputProps={{
                                  ...params.InputProps,
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </div>

                      <Button
                        className="dfultPrimaryBtn "
                        startIcon={<Add />}
                        onClick={() => {
                          let tempAllergicMedicine = selectedAbuse;
                          if (!tempAllergicMedicine) {
                            if (!abuseInputString?.trim()) {
                              setAbuseInputString("");
                              toast.error(t("Substance Name"));
                              return;
                            }
                            tempAllergicMedicine = {
                              id: uuidv4(),
                              substanceAbuseName: abuseInputString,
                            };
                          }

                          setAbuseName((prev) => {
                            const temp = [...prev, tempAllergicMedicine];
                            return temp;
                          });
                          setSelectedAbuse(null);
                          setAbuseInputString("");
                        }}
                      >
                        {t("add")}
                      </Button>
                    </div>

                    <div className="complaintList mt10">
                      <ul>
                        {abuseName &&
                          abuseName.map((item, index) => {
                            return (
                              <li>
                                <div className="complstDtlGrp ">
                                  <div className="complstDtl">
                                    <div className={`compntName `}>
                                      {item.substanceAbuseName}
                                    </div>
                                  </div>
                                  <IconButton
                                    className="removeItemBtn"
                                    onClick={() => {
                                      const temp = [...abuseName];
                                      temp.splice(index, 1);
                                      setAbuseName(temp);
                                    }}
                                  >
                                    <Trash />
                                  </IconButton>
                                </div>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}

          {!extraData?.isDoctor &&
            extraData?.reqValue?.systemicIllnesses?.length > 0 && (
              <div className="taskElementGrp mt10 docPatVideoStepNine">
                <div className="tskElemHeding">{t("systemic_illness")}</div>
                <div className="tskElemntInfo">
                  {extraData?.reqValue?.systemicIllnesses?.join(",")}
                </div>
              </div>
            )}

          {!extraData?.isDoctor &&
            extraData?.reqValue?.substanceAbuse?.length > 0 && (
              <div className="taskElementGrp mt10 docPatVideoStepTen">
                <div className="tskElemHeding">{t("substance_abuse")}</div>
                <div className="tskElemntInfo">
                  {extraData?.reqValue?.substanceAbuse?.join(",")}
                </div>
              </div>
            )}

          {extraData?.reqValue?.audioNoteFileUrl && (
            <div className="taskElementGrp docPatVideoStepEleven">
              <div className="tskElemntInfo">
                <div className="playAudioNotes">
                  <IconButton
                    variant="outlined"
                    onClick={() => {
                      setShowAudioModal(true);
                    }}
                  >
                    <Play />
                  </IconButton>
                  <span>{t("patient_audio_message")}</span>
                </div>
              </div>
            </div>
          )}

          <div className="taskElementGrp mt10 docPatVideoStepTwelve">
            <div
              className=""
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center ",
              }}
            >
              <div className="tskElemHeding">{t("files")}</div>

              <IconButton
                className="goPrevousBtn"
                onClick={() => {
                  getUserPreviousDocuments();
                }}
              >
                <Refresh />
              </IconButton>
            </div>
            <div class="elementFileListedGrp">
              <FileCursor>
                <>
                  {prevDocuments?.map((item) => {
                    return (
                      <div className="elemntFile">
                        <Tooltip
                          title={item.fileName}
                          arrow
                          className="elmFileTolTip"
                        >
                          <span className="elemntFilIoc">
                            <DescriptionOutlined />
                          </span>
                          <span className="elemntFilNm">{item.fileName}</span>
                          <span
                            className="elemntFilIoc"
                            onClick={() => {
                              downloadFileFromUrl(item.fileLink, item.fileName);
                            }}
                          >
                            <Download />
                          </span>
                        </Tooltip>
                      </div>
                    );
                  })}
                </>
              </FileCursor>
              {!extraData?.isDoctor && (
                <div class="elmntAddMrFiles">
                  <Button
                    className="elemntMrFileBtn"
                    variant="outlined"
                    onClick={(e) => {
                      setShowFileUploadDialog(true);
                    }}
                  >
                    <Plus />
                  </Button>
                  {/* <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleChangeFile}
                    style={{ display: "none" }}
                  ></input> */}
                </div>
              )}
            </div>
          </div>
          <div className="bb"></div>

          {/* start symptoms */}
          {(extraData?.isDoctor ||
            data?.symptoms?.length > 0 ||
            extraData?.symptoms?.length > 0) && (
            <div id="docPatVideoStepThirteen" className="width100p">
              <Symptoms
                updateData={updateData}
                data={data}
                extraData={extraData}
              />
            </div>
          )}
          {/* end symptoms */}

          {/* start DrugAllergy */}
          {(extraData.isDoctor || data?.allergicDrug?.length > 0) && (
            <div id="docPatVideoStepFourteen" className="width100p">
              <DrugAllergy
                updateData={updateData}
                data={data}
                extraData={extraData}
              />
            </div>
          )}
          {/* end DrugAllergy */}

          {/* start FoodAllergy */}
          {(extraData.isDoctor || data?.allergicFood?.length > 0) && (
            <div id="docPatVideoStepFifteen" className="width100p">
              <FoodAllergy
                updateData={updateData}
                data={data}
                extraData={extraData}
              />
            </div>
          )}
          {/* end FoodAllergy */}

          {/* start Comorbidity */}
          {(extraData.isDoctor || data?.comorbidity?.length > 0) && (
            <div id="docPatVideoStepsixteen" className="width100p">
              <Comorbidity
                updateData={updateData}
                data={data}
                extraData={extraData}
              />
            </div>
          )}
          {/* end Comorbidity */}

          {/* start Signs */}
          {(extraData.isDoctor || data?.singObj?.length > 0) && (
            <div id="docPatVideoStepSeventeen" className="width100p">
              <Signs
                updateData={updateData}
                data={data}
                extraData={extraData}
              />
            </div>
          )}
          {/* end Signs */}
          {/* start PhysicalExamination */}
          {(extraData.isDoctor || data?.physicalExamList?.length > 0) && (
            <div id="docPatVideoStepEighteen" className="width100p">
              <PhysicalExamination
                updateData={updateData}
                data={data}
                extraData={extraData}
              />
            </div>
          )}
          {/* end PhysicalExamination */}

          {/* start HistoryRiskFactor */}
          {(extraData.isDoctor ||
            data?.prevSurgery?.length > 0 ||
            data?.pastIllness?.length > 0 ||
            data?.riskFactor?.length > 0) && (
            <div id="docPatVideoStepNineteen" className="width100p">
              <HistoryRiskFactor
                updateData={updateData}
                data={data}
                extraData={extraData}
              />
            </div>
          )}
          {/* end HistoryRiskFactor */}

          {/* start FamilyHistory */}
          {(extraData.isDoctor || data?.familyHistory?.length > 0) && (
            <div id="docPatVideoStepTwenty" className="width100p">
              <FamilyHistory
                updateData={updateData}
                data={data}
                extraData={extraData}
              />
            </div>
          )}
          {/* end FamilyHistory */}
          {/* start possible Diagnosis */}
          {(extraData.isDoctor || data?.possibleDiagonesis?.length > 0) && (
            <div id="docPatVideoStepTwentyOne" className="width100p">
              <PossibleDiagnosis
                updateData={updateData}
                data={data}
                extraData={extraData}
                handleDiffDiagData={(diffDiagData) => {
                  setDifferentialDiagnosisData(diffDiagData);
                  setShowDifferentialDiagonesis(true);
                }}
              />
            </div>
          )}
          {/* end possible Diagnosis */}
          {/* start Investigations */}
          {(extraData.isDoctor || data?.investigation?.length > 0) && (
            <div id="docPatVideoStepTwentyTwo" className="width100p">
              <Investigations
                updateData={updateData}
                data={data}
                extraData={extraData}
              />
            </div>
          )}
          {/* end Investigations */}
          {/* start TestRsult */}
          {/* {extraData?.reqValue?.tests?.length > 0 && (
            )} */}
          {extraData?.isDoctor && (
            <div id="docPatVideoStepTwentyThree" className="width100p">
              <TestResult
                updateData={updateData}
                data={data}
                extraData={extraData}
              />
            </div>
          )}
          {/* end TestRsult */}
          {/* start test result input */}
          {!extraData?.isDoctor && (
            <div id="docPatVideoStepTwentyFour" className="width100p">
              <TestResultInput data={data} extraData={extraData} />
            </div>
          )}
          {/* end test result input */}
          {/* start Diagnosis */}
          {(extraData.isDoctor ||
            data?.possibleDiagonesis?.length > 0 ||
            data?.diagonesis?.length > 0) && (
            <div id="docPatVideoStepTwentyFive" className="width100p">
              <Diagnosis
                updateData={updateData}
                data={data}
                extraData={extraData}
              />
            </div>
          )}
          {/* end Diagnosis */}
          {/* start Medication */}

          {console.log(
            "****************** data =",
            extraData?.reqValue?.currentMedications?.length
          )}

          {(extraData.isDoctor ||
            data?.medication?.length > 0 ||
            extraData?.reqValue?.currentMedications?.length > 0) && (
            <div id="docPatVideoStepTwentySix" className="width100p">
              <Medication
                updateData={updateData}
                data={data}
                setShowDrugSafety={setShowDrugSafety}
                extraData={extraData}
              />
            </div>
          )}
          {/* end Medication */}
          {/* start Recommendations */}
          {(extraData.isDoctor ||
            data?.lifeStyle?.length > 0 ||
            data?.diet?.length > 0) && (
            <div id="docPatVideoStepTwentySeven" className="width100p">
              <Recommendations
                updateData={updateData}
                data={data}
                extraData={extraData}
              />
            </div>
          )}
          {/* end Recommendations */}
          {/* start OtherOptions */}

          {(extraData.isDoctor ||
            data?.itemToMonitor?.length > 0 ||
            data?.reviewDate ||
            data?.hospital ||
            data?.referDoctorSpeciality ||
            data?.referralNote ||
            data?.referType) && (
            <div id="docPatVideoStepTwentyEight" className="width100p">
              <OtherOptions
                updateData={updateData}
                data={data}
                extraData={extraData}
              />
            </div>
          )}

          {/* end OtherOptions */}

          {/* start message */}

          {!extraData?.isDoctor && (
            <div id="docPatVideoStepTwentyNine" className="width100p">
              <Message
                updateData={updateData}
                data={data}
                extraData={extraData}
              />
            </div>
          )}

          {/* end message */}
        </div>
      </div>
      {showAudioModal && (
        <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
          <div className="addMoreAgendaModel docPatVideoStepThirty">
            <div className="modelTitle">{t("audio_message")}</div>
            <div className="modActionBtnGrp">
              <audio controls>
                <source src={`${audioUrl.current}`} />
              </audio>
            </div>

            <div className="modActionBtnGrp">
              <Button
                className="dfultPrimaryBtn"
                onClick={() => {
                  setShowAudioModal(false);
                }}
              >
                {t("cancel")}
              </Button>
            </div>
          </div>
        </Box>
      )}

      {/* {showConfirmation && (
        <CustomModal
          modalTitle={t("do_you_want_to_continue")}
          // style={{ zIndex: 10001 }}
          closeModal={() => {
            setShowConfirmation(false);
          }}
          className={"width90"}
        >
          <div>
            <ul>
              {VitalErrorList &&
                VitalErrorList.map((item) => {
                  return <li>{item}</li>;
                })}
            </ul>
          </div>

          <div className="modActionBtnGrp actionsCreateOrg">
            <Button
              className="dfultDarkSecondaryBtn docPatVideoStepThirtyOne"
              onClick={() => {
                const tempVitals = vitals.map((item) => ({
                  ...item,
                  vitalValue: item.updatedData.vitalValue,
                  unit: item.updatedData.unit,
                }));
                setVitals(tempVitals);
                updateData({ vitals: tempVitals });
                setEditMode(!editMode);
                setShowConfirmation(false);
                centerSocket.emit("uploadFileToDoctor", {
                  doctorId: extraData?.doctorId,
                  data: { vitals: tempVitals },
                });
              }}
            >
              {t("save")}
            </Button>
            <Button
              className="dfultPrimaryBtn docPatVideoStepThirtyTwo"
              onClick={(e) => {
                setShowConfirmation(false);
              }}
            >
              {t("edit")}
            </Button>
          </div>
        </CustomModal>
      )} */}
      {showDifferentialDiagonesis && differentialDiagnosisData && (
        <DifferentialDiagnosis
          data={data}
          closeDifferentialDiagonesis={closeDifferentialDiagonesis}
          differentialDiagnosisData={differentialDiagnosisData}
          setDiffDiagnosisDisease={(diffDiagdata) => {
            if (
              "possibleDiagonesis" in data &&
              data.possibleDiagonesis.length > 0
            ) {
              const difference = diffDiagdata.filter(
                (item1) =>
                  !data.possibleDiagonesis.some(
                    (item2) => item1.id === item2.id
                  )
              );
              const temp = [...data.possibleDiagonesis, ...difference];
              updateData({ possibleDiagonesis: temp });
            } else {
              updateData({ possibleDiagonesis: diffDiagdata });
            }

            setShowDifferentialDiagonesis(false);
          }}
        />
      )}
      {showDrugSafety && (
        <DrugSafety
          data={data}
          closeDrugSafety={(drugSafetyDataTemp) => {
            closeDrugSafety();
            console.log("DrugInfo filteredData close", drugSafetyDataTemp);
            if (
              drugSafetyDataTemp.stop_use &&
              drugSafetyDataTemp.stop_use.trim() !== ""
            ) {
              updateData({
                watchoutFor: drugSafetyDataTemp.stop_use,
              });
            }
          }}
          updateData={updateData}
        />
      )}

      {/* {showFileUploadDialog && (
        <div className="RightFlotingContainerArea">
          <PatientFileUpload
            documents={documents}
            setDocuments={setDocuments}
            onClose={(e) => {
              setShowFileUploadDialog(false);
            }}
            previousMedicalRecords={previousMedicalRecords}
            setPreviousMedicalRecords={setPreviousMedicalRecords}
            onSave={(data) => {
              const reqDto = data.map((item) => {
                return {
                  fileName: item.fileName,
                  fileLink: item.fileLink,
                  fileData: item.fileData,
                  id: item.fileLink,
                };
              });
              // ;
              //
              // centerSocket.emit("uploadFileToDoctor", {
              //   doctorId: extraData?.doctorId,
              //   data: reqDto,
              // });
              zhPatientService
                .uploadDocumentsInVC(
                  extraData?.queueVisitId,
                  extraData?.queuePatientId,
                  loginUserDetails.orgId,
                  reqDto
                )
                .then((response) => {
                  if (response.data) {
                    toast.success(t("document_uploaded_successfully"));
                    setPreviousDocuments((prev) => {
                      if (!prev) {
                        return [...response.data];
                      } else {
                        return [...prev, ...response.data];
                      }
                    });

                    if (extraData?.doctorId) {
                      centerSocket.emit("uploadFileToDoctor", {
                        doctorId: extraData?.doctorId,
                        data: { files: response.data },
                      });
                    }
                  }
                })
                .catch((e) => {
                  toast.error(t("document_upload_unsuccessful"));
                });
            }}
          />
        </div>
      )} */}
    </>
  );
}
