import {
  Add,
  ArrowBackIosNew,
  ArrowForwardIos,
  Close,
  DescriptionOutlined,
  Info,
  Verified,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Checkbox,
  CircularProgress,
  FormGroup,
} from "@mui/material";
import { Microphone, Attachment } from "iconoir-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Play, Plus, Trash, XLg } from "react-bootstrap-icons";
// import ReactDatePicker from "react-datepicker";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { GetLoginUserDetails } from "../../../utils/Utils";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { convertFileToBase64_Split } from "../../../utils/FileUtils";
import {
  getAllUsersMapOfOrg,
  createMeeting,
  sendMeetingInviteToExternalUsers,
} from "../../../services/SchedulerService";

import {
  MAX_FILE_SIZE_MEETING,
  VALID_FILE_EXTENSION_MEETING,
} from "../../../constants/const_values";

import { getChatAllContacts } from "../../../services/ChatService";

import { GetLoginUserDetails, validateEmail } from "../../../utils/Utils";

import DateUtils from "../../../utils/DateUtils";
import ProfileService from "../../../services/ProfileService";
import { schedulerSocket } from "../../../socket";
import AppUtils from "../../../utils/AppUtils";
import { useTranslation } from "react-i18next";
import { FileCursor } from "../../../common/FileCursor";
import { enIN, es } from "date-fns/locale";
import SecureIndexedDB from "../../../utils/IndexedDB";
import Joyride from "react-joyride";
import { useContext } from "react";
import { TourContext } from "../../../common/TourProvider";

// Register locales with ReactDatePicker
registerLocale("en", enIN);
registerLocale("es", es);

const Meeting = ({
  handleCloseMenuButton,
  handleMeetingtype,
  setConvenientTimeData,
  selectedConvenientTime,
  closeFromDashboard,
  loading,
  setLoading,
}) => {
  const userDetails = GetLoginUserDetails();
  const { t, i18n } = useTranslation();

  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  //////////////////////////////////////////////////////////////

  const [anchorEl, setAnchorEl] = useState(null);

  const openPopover = Boolean(anchorEl);
  const popoverId = openPopover ? "simple-popover" : undefined;

  // Determine the locale based on the current language
  const currentLocale = i18n.language === "es" ? "es" : "en";

  const [meetingParticipants, setMeetingParticipants] = useState([]);
  const [newContactButton, setNewContactButton] = useState(false);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [loaderForGuest, setLoaderForGuest] = useState(false);

  const handleNewContactButton = (event) => {
    setAnchorEl(event.currentTarget);
    setNewContactButton(true);
  };

  const handleCloseNeCwontactButtonModal = () => {
    setAnchorEl(null);
    setNewContactButton(false);
    setUserName("");
    setUserEmail("");
  };

  const handleUserNameChange = (event) => {
    console.log("handleUserNameChange", event.target.value);
    setUserName(event.target.value);
  };

  const handleUserEmailChange = (event) => {
    setUserEmail(event.target.value);
  };

  const handleFormSubmit = () => {
    setLoaderForGuest(true);

    if (userName.trim() === "") {
      toast.error(t("please_enter_user_name"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoaderForGuest(false);
      return;
    }

    if (userEmail.trim() === "") {
      toast.error(t("please_enter_user_email"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoaderForGuest(false);
      return;
    }

    if (userEmail.trim() !== "" && !validateEmail(userEmail)) {
      toast.error(t("please_enter_valid_email"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoaderForGuest(false);
      return;
    }

    const isDuplicate = meetingParticipants.some(
      (participant) =>
        participant.userName === userName + " ( " + userEmail + " )" ||
        participant.userName.includes(userEmail)
    );

    if (isDuplicate) {
      toast.error(t("participant_with_this_email_already_exists"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoaderForGuest(false);
      return;
    }

    const newParticipant = {
      calledBy: "",
      chairperson: "",
      convener: "",
      id: null,
      orgId: "",
      outSide: "",
      profileImage:
        "https://ahlan-s3.s3.me-south-1.amazonaws.com/images/no-profile.png",
      userId: "",
      userName: userName + " ( " + userEmail + " )",
    };

    setMeetingParticipants([...meetingParticipants, newParticipant]);
    setNewContactButton(false);
    setAnchorEl(null);
    setUserName("");
    setUserEmail("");
    setLoaderForGuest(false);

    toast.success(t("new_guest_added_successfully"), {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  ////////////////////////////////////////////////////////////////

  const selectConvenientTimeCheckboxRef = useRef(null);
  //////////////////////////////////////////////////////preset time
  const [presetTime, setPresetTime] = useState(null);
  const [timeEndOptions, setTimeEndOptions] = useState([]);
  useEffect(() => {
    setPresetTime(selectedConvenientTime);
  }, [selectedConvenientTime]);
  useEffect(() => {
    console.log("selectedConvenientTime", selectedConvenientTime);

    // return;
    if (presetTime) {
      setStartDay(new Date(selectedConvenientTime.convStartDate));
      setEndDay(new Date(selectedConvenientTime.convStartDate));
      setEventStartdate(new Date(selectedConvenientTime.convStartDate));
      setEventEndDate(new Date(selectedConvenientTime.convStartDate));
      // getCurrentTimeOptions(new Date(selectedConvenientTime.convStartDate));
      // getCurrentTimeOptions(new Date(selectedConvenientTime.convStartDate));
      // setEventStartdate(selectedConvenientTime.convStartDate);

      if (!DateUtils.isToday(new Date(selectedConvenientTime.convStartDate))) {
        let currentDate = new Date(selectedConvenientTime.convStartDate);
        currentDate.setHours(0, 0, 0, 0);
        let allowedTime =
          DateUtils.generateTimeIntervalsRoundedTo15MinutesFromCurrentTimeAMPM(
            currentDate
          );
        setTimeOptions(allowedTime);

        if (selectedConvenientTime.convFromTime === "11:45 PM") {
          // setTimeEndOptions(["11:45 PM"]);
          let endDateNew = new Date(selectedConvenientTime.convStartDate);
          endDateNew.setDate(endDateNew.getDate() + 1);
          endDateNew.setHours(0, 0, 0, 0);
          setTimeEndOptions(
            DateUtils.generateTimeIntervalsRoundedTo15MinutesFromCurrentTimeAMPM(
              endDateNew
            )
          );
          setEndDay(new Date(endDateNew));
          setEventEndDate(new Date(endDateNew));
        } else {
          // let endTimeStart = new Date(selectedConvenientTime.convStartDate);

          // endTimeStart.setMinutes(endTimeStart.getMinutes() + 15);

          // let allowedTime =
          //   DateUtils.generateTimeIntervalsRoundedTo15MinutesFromCurrentTimeAMPM(
          //     endTimeStart
          //   );

          let indexEndTime = allowedTime.indexOf(
            selectedConvenientTime.convToTime
          );

          setTimeEndOptions(allowedTime.slice(indexEndTime));
        }
      } else {
        if (
          false &&
          new Date().getHours() === 23 &&
          new Date().getMinutes() === 45
        ) {
          let allowedTime = ["11:45 PM"];
          setTimeOptions(allowedTime);
          setTimeEndOptions(allowedTime);
        } else {
          let allowedTime =
            DateUtils.generateTimeIntervalsRoundedTo15MinutesFromCurrentTimeAMPM(
              new Date()
            );
          console.log("allowedTime", allowedTime);
          setTimeOptions(allowedTime);

          // let endTimeStart = new Date(selectedConvenientTime.convStartDate);
          // let currentDate = new Date(selectedEvent.eventStartTime);
          // endTimeStart.setHours(0, 0, 0, 0);

          // endTimeStart.setMinutes(endTimeStart.getMinutes() + 15);

          if (selectedConvenientTime.convFromTime === "11:45 PM") {
            // setTimeEndOptions(["11:45 PM"]);
            let endDateNew = new Date(selectedConvenientTime.convStartDate);
            endDateNew.setDate(endDateNew.getDate() + 1);
            endDateNew.setHours(0, 0, 0, 0);
            setTimeEndOptions(
              DateUtils.generateTimeIntervalsRoundedTo15MinutesFromCurrentTimeAMPM(
                endDateNew
              )
            );
            setEndDay(new Date(endDateNew));
            setEventEndDate(new Date(endDateNew));
          } else {
            let indexEndTime = allowedTime.indexOf(
              selectedConvenientTime.convToTime
            );
            setTimeEndOptions(allowedTime.slice(indexEndTime));
          }
        }
      }

      // setPresetTime(null);
    }
  }, [presetTime]);

  useEffect(() => {
    if (presetTime) {
      setStartTime(selectedConvenientTime.convFromTime);
      if (selectedConvenientTime.convFromTime === "11:45 PM") {
        setEndTime("00:15 AM");
        setEventStartTime(selectedConvenientTime.convFromTime);
        setEventEndTime("00:15 AM");
        setPresetTime(null);
      } else {
        setEndTime(selectedConvenientTime.convToTime);
        setEventStartTime(selectedConvenientTime.convFromTime);
        setEventEndTime(selectedConvenientTime.convToTime);
        setPresetTime(null);
      }
    }
  }, [timeEndOptions]);
  //////////////////////////////////////////////////////preset time

  console.log("userDetails", userDetails);

  const loginUser = GetLoginUserDetails();
  const [eventGuest, setEventGuest] = useState([]);

  const [anchorElRP, setAnchorElRP] = React.useState(null);
  const [allUsersList, setAllUsersList] = useState([]);
  const [selectedGuest, setSelectedGuest] = useState(null);
  const [selectedGuestInput, setSelectedGuestInput] = useState("");
  const openRP = Boolean(anchorElRP);
  const idRP = openRP ? "simple-popover" : undefined;
  const buttonRPRef = useRef(null);
  const buttonRPCancelRef = useRef(null);
  const buttonApplyForAllRec = useRef(null);
  const buttonApplyForAllRecReschedule = useRef(null);
  const buttonApplyForAllRecAddParticipant = useRef(null);
  const buttonApplyForAllRecRemoveParticipant = useRef(null);
  const [associateTaskDataList, setAssociateTaskDataList] = useState([]);

  // const [loading, setLoading] = useState(false);

  const handleClickRP = (event) => {
    setAnchorElRP(event.currentTarget);
  };

  const handleCloseRP = () => {
    setAnchorElRP(null);
  };
  // const [meetingParticipants, setMeetingParticipants] = useState([]);
  // const [selectedParticipant, setSelectedParticipant] = React.useState(null);
  ////////////////////////

  ////////////////////////////////////private notes
  //add pvt note pop over
  const [userPrivateNotes, setUserPrivateNotes] = useState([]);
  const [anchorElPN, setAnchorElPN] = React.useState(null);

  const handleClickPN = (event) => {
    setAnchorElPN(event.currentTarget);
  };

  const handleClosePN = () => {
    setAnchorElPN(null);
  };

  const openPN = Boolean(anchorElPN);
  const idPN = openPN ? "add-pvtNotePopOver" : undefined;
  // const [openAddPvtNote, setOpenAddPvtNote] = useState(false);

  // const handleOpenAddPvtNote = () => {
  //   setOpenAddPvtNote(true);
  // };

  // const handleClosePvtNote = () => {
  //   setOpenAddPvtNote(false);
  // };

  const addPNRef = useRef(null);
  const cancelPNRef = useRef(null);
  const inputPNRef = useRef(null);
  const [pvtNote, setPvtNote] = useState(null);

  const onClickAddPvtNote = () => {
    console.log(pvtNote);
    if (pvtNote === null || pvtNote.trim() === "") {
      console.log("pvtNote");
      inputPNRef.current.style.borderColor = "red";
      inputPNRef.current.placeholder = t("please_add_note");
      return;
    }
    // return;
    addPNRef.current.disabled = true;
    const userNotesDto = {
      eventNoteId: null,
      notes: pvtNote,
      notingTime: new Date().getTime(),
    };
    setUserPrivateNotes((prevList) => [...prevList, userNotesDto]);
    handleClosePN();
    setPvtNote(null);
    // setAnchorElPN(null);
    return;

    // addPrivateNotes(loginUser.userId, selectedEvent).then((response) => {
    //   console.log(response.data);
    //   getPrivateNotes(loginUser.userId, selectedEvent.eventId).then(
    //     (response) => {
    //       setUserPrivateNotes(response.data);
    //     }
    //   );
    // });
  };
  ///////////////////////////////////private notes end

  // const loginUser = GetLoginUserDetails();

  // const [startDateselect, setStartDateselect] = useState("");
  const [eventType, setEventType] = useState("Event");
  const [descriptionVisible, setDescriptionVisible] = useState(true);

  const [selectEventType, setSelectEventType] = useState("onetime");

  const handleEventType = (event) => {
    setSelectEventType(event.target.value);
  };

  const [selectEventMode, setSelectEventMode] = useState("virtual");

  const handleEventMode = (event) => {
    setSelectEventMode(event.target.value);
  };
  const elementRef = useRef(null);
  const [arrowDisable, setArrowDisable] = useState(true);
  const [tempDivWidth, setTempDivWidth] = useState(0);
  useEffect(() => {
    if (elementRef.current) {
      setTempDivWidth(elementRef.current.offsetWidth);
    }
  }, [elementRef]);

  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setArrowDisable(true);
      } else {
        setArrowDisable(false);
      }
    }, speed);
  };

  const handleEventTypeChange = (event) => {
    setEventType(event.target.value);

    // Update visibility of description based on selected event type
    setDescriptionVisible(event.target.value === "Event");
  };
  ////////////////////////////////////////////////////////////////////////////////

  const [startDate, setStartDate] = useState(new Date());
  const [startDateselect, setStartDateselect] = useState("");
  const handleSetDate = (event) => {
    setStartDateselect(event.target.value);
  };

  ////////////////////////////////date////////////////////////////////////////////////////////

  ////////////////////////////////date////////////////////////////////////////////////////////

  const [startDay, setStartDay] = useState(new Date());
  const [endDay, setEndDay] = useState(new Date());
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [timeOptions, setTimeOptions] = useState([]);
  // const [timeEndOptions, setTimeEndOptions] = useState([]);
  const [currentDay, setCurrentDay] = useState(getCurrentDay(startDay));
  const [currentEndDay, setEndCurrentDay] = useState(getCurrentDay(endDay));

  function getCurrentDay(date) {
    return date.toLocaleDateString("en-US", { weekday: "long" });
  }

  const handleStartDateChange = (date) => {
    console.log("date");
    setStartDay(date);
    setCurrentDay(getCurrentDay(date));
    if (endDay < date) {
      setEndDay(date);
      setEndCurrentDay(getCurrentDay(date));
    }
    getCurrentTimeOptions(date);
    // setEventStartdate(date);
    setEventStartdate(date !== null ? date : new Date());
    setEndDay(date);
    setEventEndDate(date);
  };

  const handleEndDateChange = (date) => {
    console.log("date", date);

    // Ensure end date is not less than start date
    if (date < startDay) {
      setStartDay(date);
      setCurrentDay(getCurrentDay(date));
    }

    setEndDay(date);
    setEndCurrentDay(getCurrentDay(date));
    setEventEndDate(date !== null ? date : eventStartDate || new Date());

    if (startDay.toDateString() !== date.toDateString()) {
      // If start date and end date are different, set end time list to start from "00:00 AM"
      const newEndTimeList =
        DateUtils.generateTimeIntervalsRoundedTo15MinutesFromCurrentTimeAMPM(
          new Date(date.setHours(0, 0, 0, 0))
        );
      setTimeEndOptions(newEndTimeList);
      // Set event end time to the first element of the new end time list
      setEventEndTime(newEndTimeList[0]);
      setEndTime(newEndTimeList[0]);
    } else {
      // Otherwise, get the next options from timeOptions
      // const endTimeIndex = timeOptions.findIndex(time => time === selectedConvenientTime.convFromTime);
      const endTimeIndex = selectedConvenientTime
        ? timeOptions.findIndex(
            (time) => time === selectedConvenientTime.convFromTime
          )
        : -1;
      const newEndTimeList = timeOptions.slice(endTimeIndex + 1);
      setTimeEndOptions(newEndTimeList);
      // Set event end time to the first element of the new end time list
      setEventEndTime(newEndTimeList[0]);
    }
  };

  // const handleEndDateChange = (date) => {
  //   console.log("date", date);
  //   // setEventEndDate(getCurrentDay(date))
  //   // Ensure end date is not less than start date
  //   if (date < startDay) {
  //     setStartDay(date);
  //     setCurrentDay(getCurrentDay(date));
  //   }
  //   setEndDay(date);
  //   setEndCurrentDay(getCurrentDay(date));
  //   // setEventEndDate(date)
  //   setEventEndDate(date !== null ? date : eventStartDate || new Date());
  // };

  const getCurrentTimeOptions = (selectedDate) => {
    const options = [];
    const currentDate = new Date();

    let startHour = 0;
    let startMinute = 0;

    if (selectedDate.toDateString() === currentDate.toDateString()) {
      // If the selected date is today, start from the current time
      if (currentDate.getHours() >= 23 && currentDate.getMinutes() >= 45) {
        // If the current time is 11:45 PM or later, start from the next day
        selectedDate.setDate(selectedDate.getDate() + 1);
        startHour = 0;
        startMinute = 0;
      } else {
        // Otherwise, start from the current time
        startHour = currentDate.getHours();
        startMinute = Math.ceil(currentDate.getMinutes() / 15) * 15;
      }
    }

    for (let hour = startHour; hour <= 23; hour++) {
      const startMin =
        hour === startHour ? Math.ceil(startMinute / 15) * 15 : 0;
      for (let minute = startMin; minute < 60; minute += 15) {
        const formattedHour = (
          hour === 0 ? "00" : hour % 12 === 0 ? 12 : hour % 12
        ).toString();
        const formattedMinute = minute < 10 ? `0${minute}` : `${minute}`;
        const period = hour < 12 ? "AM" : "PM";
        const timeString = `${formattedHour}:${formattedMinute} ${period}`;
        options.push(timeString);
      }
    }

    setTimeOptions(options);
  };

  useEffect(() => {
    if (!presetTime) {
      getCurrentTimeOptions(startDay);
    }
  }, [startDay]);

  useEffect(() => {
    console.log(" *************** time option *************");
    if (!presetTime) {
      if (timeOptions.length > 0) {
        setStartTime(timeOptions[0]);
        setEndTime(timeOptions[2] || "");
        setTimeEndOptions(timeOptions.slice(1));
      }
      setEventEndTime(timeOptions[2] || "");
      setEventStartTime(timeOptions[0]);
    }
  }, [timeOptions]);

  // useEffect(() => {
  //   if (endDay > startDay && !presetTime) {
  //     getCurrentTimeOptions(endDay);
  //     // Set default value for eventEndDate if not already set
  //   }
  // }, [endDay]);

  // const handleStartTimeChange = (event) => {
  //   console.log("event", event);
  //   const newStartTime = event.target.value;
  //   setStartTime(newStartTime);
  //   setEventStartTime(newStartTime);

  //   // Update endTime list based on the newStartTime
  //   let newEndTimeList;

  //   if (newStartTime === "11:45 PM") {
  //     // If the newStartTime is "11:45 PM", set endTime to "00:15 AM" of the next day
  //     const nextDay = new Date(startDay);
  //     nextDay.setDate(startDay.getDate() + 1);
  //     nextDay.setHours(0, 15, 0); // Set to "00:15 AM"
  //     setEndDay(nextDay);
  //     setEndCurrentDay(getCurrentDay(nextDay));

  //     newEndTimeList = ["00:15 AM"];
  //   } else {
  //     newEndTimeList = timeOptions.slice(timeOptions.indexOf(newStartTime) + 1);
  //   }

  //   setEndTime(newEndTimeList[0] || "");
  //   setTimeEndOptions(newEndTimeList);
  //   setEventEndTime(newEndTimeList[0]);
  // };

  const handleStartTimeChange = (event) => {
    const newStartTime = event.target.value;
    setStartTime(newStartTime);
    setEventStartTime(newStartTime);

    if (newStartTime === "11:45 PM") {
      console.log("Start time is 11:45 PM");
      const nextDay = new Date(startDay);
      nextDay.setDate(startDay.getDate() + 1);
      nextDay.setHours(0, 15, 0);
      console.log("Next day:", nextDay);
      setEndDay(nextDay);
      setEndCurrentDay(getCurrentDay(nextDay));
      setEventEndDate(nextDay);

      const newEndTimeList =
        DateUtils.generateTimeIntervalsRoundedTo15MinutesFromCurrentTimeAMPM(
          new Date(endDay.setHours(0, 15, 0, 0))
        );
      console.log("New end time options for different days:", newEndTimeList);
      setEndTime(newEndTimeList[0] || "");
      setTimeEndOptions(newEndTimeList);
      setEventEndTime(newEndTimeList[0]);
    } else {
      if (startDay.toDateString() === endDay.toDateString()) {
        const newEndTimeList = timeOptions.slice(
          timeOptions.indexOf(newStartTime) + 1
        );
        console.log("New end time options:", newEndTimeList);
        setEndTime(newEndTimeList[0] || "");
        setTimeEndOptions(newEndTimeList);
        setEventEndTime(newEndTimeList[0]);
      } else {
        console.log("date");
      }
    }
  };

  const handleEndTimeChange = (event) => {
    setEndTime(event.target.value);
    setEventEndTime(event.target.value);
  };

  ////////////////////////////////////////////////////////////////////////////////////////////
  const [agendaDisplayList, setAgendaDisplayList] = useState([]);
  const [agendaDataList, setAgendaDataList] = useState(new Map());
  /*
  {"1":{
    agendaTitle:"",
    agendaDesc:"",
    agendaFiles:"",
    agendaPool:"",
    agendaHands:""
    deleteFlag:"Y"
  },
  "2":{
    agendaTitle:"",
    agendaDesc:"",
    agendaFiles:"",
    agendaPool:"",
    agendaHands:""
  },

  }
  */
  // useEffect(() => {
  //   let agendaDisplayDto = (
  //     <>
  //       <div
  //         className="multiElementFrmGrp"
  //         style={{
  //           display: `${
  //             agendaDisplayList.get(agendaDisplayList.length + 1).deleteFlag ===
  //             "Y"
  //               ? "none"
  //               : "block"
  //           }`,
  //         }}
  //         key={agendaDisplayList.length + 1}
  //       >
  //         <div className="formElement">
  //           <FormControl className="formControl controlWithRemove">
  //             <TextField
  //               hiddenLabel
  //               placeholder="Enter Agenda here"
  //               variant="outlined"
  //               className="formTextFieldArea"
  //               onChange={(event) => {
  //                 if (agendaDataList.has(`${agendaDisplayList.length + 1}`)) {
  //                   let tempMap = new Map(agendaDataList);
  //                   tempMap.set("1").agendaTitle = event.target.value;
  //                   setAgendaDataList(tempMap);
  //                 } else {
  //                   let tempMap = new Map(agendaDataList);
  //                   let obj = {
  //                     agendaTitle: event.target.value,
  //                     agendaDesc: "",
  //                     agendaFiles: "",
  //                     agendaPool: "",
  //                     agendaHands: "",
  //                   };
  //                   tempMap.put(`${agendaDisplayList.length + 1}`, obj);
  //                   setAgendaDataList(tempMap);
  //                 }
  //               }}
  //             />
  //             <TextField
  //               hiddenLabel
  //               placeholder="Enter Agenda Desc here"
  //               variant="outlined"
  //               className="formTextFieldArea"
  //               onChange={(event) => {
  //                 if (agendaDataList.has("1")) {
  //                   agendaDataList.set("1").agendaDesc = event.target.value;
  //                 } else {
  //                   let obj = {
  //                     agendaTitle: "",
  //                     agendaDesc: event.target.value,
  //                     agendaFiles: [{}],
  //                     agendaPool: "",
  //                     agendaHands: "",
  //                   };
  //                   agendaDataList.put("1", obj);
  //                 }
  //               }}
  //             />
  //             <IconButton className="removeBtn">
  //               <Trash />
  //             </IconButton>
  //           </FormControl>
  //         </div>
  //         <div class="elementFileListedGrp">
  //           <div className="horaizonScroll">
  //             <IconButton
  //               className="goPrevousBtn"
  //               onClick={() => {
  //                 handleHorizantalScroll(elementRef.current, 25, 200, -10);
  //               }}
  //               disabled={arrowDisable}
  //             >
  //               <ArrowBackIosNew />
  //             </IconButton>
  //             <div>
  //               <div class="elemntFilsgrp" ref={elementRef}>
  //                 <div className="elemntFile">
  //                   <Tooltip
  //                     title="Lorem some data"
  //                     arrow
  //                     className="elmFileTolTip"
  //                   >
  //                     <span className="elemntFilIoc">
  //                       <DescriptionOutlined />
  //                     </span>
  //                     <span className="elemntFilNm">Lorem some data</span>
  //                   </Tooltip>
  //                 </div>

  //                 <div className="elemntFile">
  //                   <Tooltip
  //                     title="Lorem some data"
  //                     arrow
  //                     className="elmFileTolTip"
  //                   >
  //                     <span className="elemntFilIoc">
  //                       <DescriptionOutlined />
  //                     </span>
  //                     <span className="elemntFilNm">Lorem some data</span>
  //                     <div className="fltrdTgClose">
  //                       <Close />
  //                     </div>
  //                   </Tooltip>
  //                 </div>

  //                 <div className="elemntFile">
  //                   <Tooltip
  //                     title="Lorem some data"
  //                     arrow
  //                     className="elmFileTolTip"
  //                   >
  //                     <span className="elemntFilIoc">
  //                       <DescriptionOutlined />
  //                     </span>
  //                     <span className="elemntFilNm">Lorem some data</span>
  //                     <div className="fltrdTgClose">
  //                       <Close />
  //                     </div>
  //                   </Tooltip>
  //                 </div>

  //                 <div className="elemntFile">
  //                   <Tooltip
  //                     title="Lorem some data"
  //                     arrow
  //                     className="elmFileTolTip"
  //                   >
  //                     <span className="elemntFilIoc">
  //                       <DescriptionOutlined />
  //                     </span>
  //                     <span className="elemntFilNm">Lorem some data</span>
  //                     <div className="fltrdTgClose">
  //                       <Close />
  //                     </div>
  //                   </Tooltip>
  //                 </div>
  //               </div>
  //             </div>
  //             {tempDivWidth > 470 && (
  //               <IconButton
  //                 className="goNextBtn"
  //                 onClick={() => {
  //                   handleHorizantalScroll(elementRef.current, 25, 200, 10);
  //                 }}
  //               >
  //                 <ArrowForwardIos />
  //               </IconButton>
  //             )}
  //           </div>
  //           <div
  //             class="elmntAddMrFiles"
  //             // onClick={()=>addFile(`${agendaDisplayList.length+1}`); }
  //           >
  //             <Button className="elemntMrFileBtn" variant="outlined">
  //               <Plus />
  //             </Button>
  //           </div>
  //         </div>
  //         <div className="formElement">
  //           <FormControl className="formControl">
  //             <RadioGroup
  //               className="formRadioGroup"
  //               labelId="setTasktype"
  //               name="setTasktype"
  //             >
  //               <FormControlLabel
  //                 className="formRadioField"
  //                 value="Polling"
  //                 control={<Radio />}
  //                 label="Polling"
  //               />
  //               <FormControlLabel
  //                 className="formRadioField"
  //                 value="showHand"
  //                 control={<Radio />}
  //                 label="Show of Hand"
  //               />
  //             </RadioGroup>
  //           </FormControl>
  //         </div>
  //       </div>
  //     </>
  //   );
  //   setAgendaDisplayList([agendaDisplayList, agendaDisplayDto]);
  // }, []);

  ///////////////////////////////////////guest///////////////////////////////////////////

  // const [meetingParticipants, setMeetingParticipants] = useState([]);
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [refreshCount, setRefreshCount] = useState(1);
  const [participants, setParticipants] = useState([]);
  const [checkParticipants, setCheckparticipant] = useState({});

  // useEffect(() => {
  //   getAllUsersMapOfOrg(userDetails.orgId)
  //     .then((response) => {
  //       console.log("alluser", response.data);

  //       const inputObject = response.data;

  //       const outputList = Object.entries(inputObject).map(
  //         ([userId, userName]) => ({
  //           userId,
  //           userName,
  //         })
  //       );

  //       console.log("outputList", outputList);

  //       setAllUsersList(outputList);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching participants:", error);
  //     });
  // }, []);

  useEffect(() => {
    getChatAllContacts(loginUser.userId, loginUser.orgId).then((response) => {
      console.log("getChatAllContacts", response.data);
      // setAllUsersList(response.data);
      ProfileService.getAllUserDetailsByUserId(loginUser.userId).then(
        (response2) => {
          let userAllInfo = response2.data;
          const profileImageLink =
            userAllInfo?.userDtls?.profileImageLink || "";
          let myDetails = {
            userId: loginUser.userId,
            userName: loginUser.userName,
            userDtl: loginUser.externalCategory
              ? loginUser.externalCategory
              : "Internal",
            // profileUrl: `data:image/png;base64,${
            //   userAllInfo ? userAllInfo.userDtls.profileImageLink : ""
            // }`,
            profileUrl: profileImageLink.startsWith("http")
              ? profileImageLink
              : `data:image/png;base64,${profileImageLink}`,
            userType: loginUser.externalCategory ? "E" : "I",
            convener: "Y",
            chairperson: "Y",
          };
          setCheckparticipant(myDetails);
          setEventGuest(myDetails.userId);
          setMeetingParticipants((prevList) => [
            ...prevList,
            {
              id: null,
              userId: myDetails.userId,
              userName: myDetails.userName,
              profileImage: myDetails.profileUrl,
              convener: "Y",
              chairperson: "Y",
              outSide: myDetails.userType === "I" ? "N" : "Y",
              calledBy: "N",
              orgId: loginUser.orgId,
            },
          ]);

          setAllUsersList([...response.data, myDetails]);
        }
      );
    });
  }, []);

  const makeConveyor = (userId) => {
    setMeetingParticipants((prevList) => {
      return prevList.map((participant) => ({
        ...participant,
        convener: participant.userId === userId ? "Y" : "N",
      }));
    });
    setEventGuest(userId);
    setRefreshCount((r) => r + 1);
  };

  const makeChairperson = (userId) => {
    setMeetingParticipants((prevList) => {
      return prevList.map((participant) => ({
        ...participant,
        chairperson: participant.userId === userId ? "Y" : "N",
      }));
    });
    setEventGuest(userId);
    setRefreshCount((r) => r + 1);
  };

  // const makeConveyor = (userId) => {
  // console.log('makeConveyor',userId )
  // setMeetingParticipants((prevList) =>
  //   prevList.map((participant) => ({
  //     ...participant,
  //     convener: participant.userId === userId ? "Y" : "N",
  //   }))
  // );
  // setEventGuest((prevList) =>
  //   prevList.map((participant) => ({
  //     ...participant,
  //     convener: participant.userId === userId ? "Y" : "N",
  //   }))
  // );
  // setRefreshCount((r) => r + 1);
  // };

  // const makeChairperson = (userId) => {
  // console.log('makeChairperson', userId)
  // setMeetingParticipants((prevList) =>
  //   prevList.map((participant) => ({
  //     ...participant,
  //     chairperson: participant.userId === userId ? "Y" : "N",
  //   }))
  // );
  // setEventGuest((prevList) =>
  //   prevList.map((participant) => ({
  //     ...participant,
  //     chairperson: participant.userId === userId ? "Y" : "N",
  //   }))
  // );
  // setRefreshCount((r) => r + 1);
  // };

  const handleRemoveParticipant = (userId, userName) => {
    console.log("participantId", userId);
    // Check if the participant is a conveyor
    const isConveyor = meetingParticipants.some(
      (participant) =>
        participant.userId === userId && participant.convener === "Y"
    );

    // If the participant is a conveyor, do not allow removal
    if (isConveyor) {
      // You can handle this case (e.g., show a message)
      console.log("Cannot remove a conveyor participant");
      return;
    }

    if (userId === "") {
      const updatedParticipants = meetingParticipants.filter(
        (participant) => participant.userName !== userName
      );

      setMeetingParticipants(updatedParticipants);
      handleCloseRP();
    } else {
      // Update the state to remove the participant with the specified userId
      const updatedParticipants = meetingParticipants.filter(
        (participant) => participant.userId !== userId
      );

      setMeetingParticipants(updatedParticipants);

      handleCloseRP();
    }

    // You may also add additional logic here if needed
  };

  const [guestData, setGuestData] = useState([
    { guestName: "", guestEmail: "" },
  ]);

  useEffect(() => {
    const formatParticipants = () => {
      const formattedParticipants = [];
      const participantsWithoutUserId = [];

      meetingParticipants.forEach((participant) => {
        if (participant.userId) {
          const formattedParticipant = { userId: participant.userId };
          if (participant.convener) {
            formattedParticipant.convener = participant.convener;
          }
          if (participant.chairperson) {
            formattedParticipant.chairperson = participant.chairperson;
          }
          formattedParticipants.push(formattedParticipant);
        } else {
          participantsWithoutUserId.push(participant);
        }
      });

      setParticipants(formattedParticipants);

      // Extract guest names and emails
      const extractedGuests = participantsWithoutUserId.map((participant) => {
        const parts = participant.userName.split("(");
        const guestName = parts[0].trim();
        const guestEmail = parts[1].split(")")[0].trim();
        return { guestName, guestEmail };
      });

      setGuestData(extractedGuests);

      console.log("participantsWithoutUserId", participantsWithoutUserId);
    };

    formatParticipants();
  }, [meetingParticipants]);

  useEffect(() => {
    // Function to format and set participants state
    // const formatParticipants = () => {
    //   const formattedParticipants = meetingParticipants.map((participant) => {
    //     const formattedParticipant = { userId: participant.userId };
    //     if (participant.convener) {
    //       formattedParticipant.convener = participant.convener;
    //     }
    //     if (participant.chairperson) {
    //       formattedParticipant.chairperson = participant.chairperson;
    //     }
    //     return formattedParticipant;
    //   });
    //   setParticipants(formattedParticipants);
    // };

    // Call the function when meetingParticipants changes
    const refreshConvenientCalendar = () => {
      console.log("refreshConvenientCalendar", meetingParticipants.length);
      for (let index = 0; index < meetingParticipants.length; index++) {
        const element = meetingParticipants[index];
        console.log("refreshConvenientCalendar", element);
      }

      if (meetingParticipants.length < 2) {
        toast.error(
          t(
            "please_select_atleast_two_participants_for_viewing_convenient_time"
          )
        );
        selectConvenientTimeCheckboxRef.current.checked = false;
        setConvenientTimeData(null);
        return;
      }

      if (selectConvenientTimeCheckboxRef.current.checked) {
        let newStartTime = new Date(eventStartDate);
        let fromTimeSplice = startTime.match(/(\d+):(\d+) ([APMapm]{2})/);
        let fromHours = parseInt(fromTimeSplice[1], 10);
        var fromMinutes = parseInt(fromTimeSplice[2], 10);
        var fromPeriod = fromTimeSplice[3].toUpperCase();
        if (fromPeriod.toUpperCase() === "PM" && fromHours !== 12) {
          fromHours += 12;
        }
        newStartTime.setHours(fromHours, fromMinutes, 0, 0);

        let newEndTime = new Date(endDay);
        let toTimeSplice = endTime.match(/(\d+):(\d+) ([APMapm]{2})/);
        let toHours = parseInt(toTimeSplice[1], 10);
        var toMinutes = parseInt(toTimeSplice[2], 10);
        var toPeriod = toTimeSplice[3].toUpperCase();
        if (toPeriod.toUpperCase() === "PM" && fromHours !== 12) {
          toHours += 12;
        }
        newEndTime.setHours(toHours, toMinutes, 0, 0);

        let data = {
          meetingStartTime: newStartTime,
          meetingEndTime: newEndTime,
          usersList: meetingParticipants.map((guestDto) => guestDto.userId),
          source: "event",
        };

        setConvenientTimeData(data);
      } else {
        setConvenientTimeData(null);
      }
    };

    if (meetingParticipants.length < 2) {
      setConvenientTimeData(null);
    }

    if (
      selectConvenientTimeCheckboxRef &&
      selectConvenientTimeCheckboxRef.current &&
      selectConvenientTimeCheckboxRef?.current.checked
    ) {
      refreshConvenientCalendar();
    }

    // Call the function when meetingParticipants changes
    // formatParticipants();
  }, [meetingParticipants]);

  //////////////////////////////////////////exclude me checkbox/////////////////////////////////////////////

  const [excludeMe, setExcludeMe] = useState(false);

  // const handleCheckboxChange = () => {

  //   console.log('checkParticipants 22', checkParticipants)
  //   console.log("handleCheckboxChange");
  //   setExcludeMe((prevValue) => !prevValue);

  //   console.log("allUsersList", allUsersList);

  //   if (!excludeMe) {
  //     const updatedUsersList = allUsersList.filter(
  //       (user) => user.userId !== userDetails.userId
  //     );

  //     console.log("updatedUsersList", updatedUsersList);
  //     setAllUsersList(updatedUsersList);

  //   }

  //   else {
  //     console.log('userDetails in exclude 2', userDetails);
  //     const isUserInList = allUsersList.some(
  //       (user) => user.userId === userDetails.userId
  //     );

  //     if (!isUserInList) {
  //       // Include the current user back in the list
  //       setAllUsersList((prevParticipants) => [...prevParticipants, userDetails]);
  //     }
  //   }

  // };

  const handleCheckboxChange = () => {
    setExcludeMe((prevValue) => !prevValue);

    if (!excludeMe) {
      const updatedUsersList = allUsersList.filter(
        (user) => user.userId !== checkParticipants.userId
      );
      setAllUsersList(updatedUsersList);

      const updatedMeetingParticipants = meetingParticipants.filter(
        (user) => user.userId !== checkParticipants.userId
      );
      setMeetingParticipants(updatedMeetingParticipants);
    } else {
      const isUserInList = allUsersList.some(
        (user) => user.userId === checkParticipants.userId
      );

      if (!isUserInList) {
        const temp = {
          id: null,
          userId: checkParticipants.userId,
          userName: checkParticipants.userName,
          profileImage: checkParticipants.profileUrl,
          convener: "",
          chairperson: "",
          outSide: checkParticipants.userType === "I" ? "N" : "Y",
          calledBy: "N",
          orgId: loginUser.orgId,
        };
        setAllUsersList((prevParticipants) => [...prevParticipants, temp]);
        setMeetingParticipants((prevParticipants) => [
          ...prevParticipants,
          temp,
        ]);
      }
    }
  };

  //   console.log("handleCheckboxChange");
  //   setExcludeMe((prevValue) => !prevValue);

  //   // console.log("allUsersList", allUsersList);
  //   console.log('userDetails in exclude', userDetails);

  //   if (!excludeMe) {
  //     const updatedUsersList = meetingParticipants.filter(
  //       (user) => user.userId !== userDetails.userId
  //     );

  //     console.log("updatedUsersList", updatedUsersList);
  //     setMeetingParticipants(updatedUsersList);
  //   }
  //   else {
  //     console.log('userDetails in exclude 2', userDetails);
  //     const isUserInList = meetingParticipants.some(
  //       (user) => user.userId === userDetails.userId
  //     );

  //     if (!isUserInList) {
  //       // Include the current user back in the list
  //       setMeetingParticipants((prevParticipants) => [...prevParticipants, userDetails]);
  //     }
  //   }
  // };

  ////////////////////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////file/////////////////////////////////////////////////

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const fileInputRef = useRef(null);

  const validateFile = (file) => {
    if (file.size > MAX_FILE_SIZE_MEETING) {
      toast.error(
        t("please_select_file_less_than", {
          maxFileSize: MAX_FILE_SIZE_MEETING,
        }),
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      return false;
    }

    const extension = file.name.split(".").pop().toLowerCase();
    if (!VALID_FILE_EXTENSION_MEETING.includes(extension)) {
      toast.error(t("invalid_file_type"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }

    return true;
  };

  /////////////////////////////////////////agenda/////////////////////////////////////////////

  const [agendaElements, setAgendaElements] = useState([]);
  const meetingRef = useRef(null);

  const emptyAgendaObj = {
    noticeBlock: "Agenda",
    noticeSubject: "",
    noticeDetail: "",
    files: {},
    polling: "",
    showOfHand: "",
  };

  const [agendaListData, setAgendaListdata] = useState([emptyAgendaObj]);

  const handleAddAgenda = () => {
    const temp = [...agendaListData];
    temp.push(emptyAgendaObj);
    setAgendaListdata(temp);
  };

  const [agendaTitle, setAgendaTitle] = useState("");
  const [agendaDescription, setAgendaDescription] = useState("");
  const [agendaFiles, setAgendaFiles] = useState([]);
  const [radioCheckButton, setRadioCheckButton] = useState("");

  const handleAgendaTitle = (e, index) => {
    console.log("temp", agendaListData);

    const temp = [...agendaListData];
    temp[index].noticeSubject = e.target.value;
    setAgendaListdata(temp);
    // setAgendaTitle(e.target.value);
  };

  const handleAgendaDescription = (e, index) => {
    console.log("tempdesc", agendaListData);

    // setAgendaDescription(e.target.value);
    const tempDesc = [...agendaListData];
    tempDesc[index].noticeDetail = e.target.value;
    setAgendaListdata(tempDesc);
  };

  const handleFileUpload = async (e, index) => {
    console.log("agendaListData", agendaListData);
    const files = e.target.files;
    const tempFile = [...agendaListData];
    let filesMap = tempFile[index].files;

    let totalFiles = 0;

    for (let key in filesMap) {
      if (filesMap.hasOwnProperty(key)) {
        totalFiles++;
      }
    }

    totalFiles += files.length;

    if (totalFiles > 5) {
      toast.error(t("you_can_upload_a_maximum_of_five_files"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    for (let i = 0; i < files.length; i++) {
      if (files[i].size > 1024 * 1024 * MAX_FILE_SIZE_MEETING) {
        toast.error(
          t("please_select_file_less_than", {
            maxFileSize: MAX_FILE_SIZE_MEETING,
          }),
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      } else if (
        !VALID_FILE_EXTENSION_MEETING.includes(files[i].name.split(".").pop())
      ) {
        toast.error(t("invalid_file_type"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        const base64 = await convertFileToBase64_Split(files[i]);
        filesMap[files[i].name] = base64;
      }
    }

    // Ensure agendaFiles is initialized as an array

    console.log("handleFileUpload", filesMap);
    tempFile[index].files = filesMap;

    console.log("handleFileUpload", tempFile);

    setAgendaListdata(tempFile);

    // toast.success("File(s) Uploaded Successfully", {
    //   position: toast.POSITION.TOP_RIGHT,
    // });
  };

  const handleTaskTypeChange = (e, index) => {
    console.log("temp", agendaListData);
    // setSelectedTaskType(event.target.value);
    // console.log("selectedTaskType", event.target.value);
    // setRadioCheckButton(event.target.value);
    const tempPoll = [...agendaListData];
    if (e.target.value === "Polling") {
      tempPoll[index].polling = "Y";
      tempPoll[index].showOfHand = "N";
    } else if (e.target.value === "showHand") {
      tempPoll[index].polling = "N";
      tempPoll[index].showOfHand = "Y";
    }

    setAgendaListdata(tempPoll);
  };

  const handleRemoveAgenda = (index) => {
    const temp = [...agendaListData];
    temp.splice(index, 1);
    setAgendaListdata(temp);
  };

  /////////////////////////////////api call////////////////////////////////////////////////

  const [meetingTitle, setMeetingTitle] = useState("");
  const [eventLocation, setEventLocation] = useState(userDetails.orgAddress);

  const [eventStartTime, setEventStartTime] = useState(timeOptions.slice(1));
  const [eventEndTime, setEventEndTime] = useState(timeEndOptions.slice(1));
  const [eventStartDate, setEventStartdate] = useState(new Date());
  const [eventEndDate, setEventEndDate] = useState(new Date());

  const [selectedTaskType, setSelectedTaskType] = useState("");

  const handleMeetingTitle = (e) => {
    setMeetingTitle(e.target.value);
  };

  const handleMeetingLocation = (e) => {
    setEventLocation(e.target.value);
  };

  const calculateTime = (startTime, endTime) => {
    // Assuming startTime and endTime are Date objects
    console.log("startTime", startTime, endTime);
    const startTimeMs = startTime.getTime();
    const endTimeMs = endTime.getTime();
    const timeDifferenceMs = endTimeMs - startTimeMs;
    const timeDifferenceMinutes = timeDifferenceMs / (1000 * 60);

    return timeDifferenceMinutes;
  };

  const createFinalMeeting = () => {
    setLoading(true);

    console.log("participants", participants);

    const formattedStartDate = eventStartDate
      ? new Date(eventStartDate).toDateString()
      : null;
    const formattedEndDate = eventEndDate
      ? new Date(eventEndDate).toDateString()
      : null;

    if (eventLocation.trim() === "") {
      toast.error(t("please_enter_event_location"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (participants.length === 0) {
      toast.error(t("select_participants"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    const totalParticipants =
      participants.length + (Array.isArray(guestData) ? guestData.length : 0);

    if (totalParticipants < 2) {
      toast.error(t("this_meeting_needs_at_least_two_participants"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    // if (participants.length === 1) {
    //   toast.error("This Meeting Needs atleast 2 Participants ", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   setLoading(false);
    //   return;
    // }

    if (meetingTitle.trim() === "") {
      toast.error(t("please_enter_event_title"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (agendaListData.length === 0) {
      toast.error(t("you_have_to_add_meeting_agenda"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    const newStartDate = new Date(formattedStartDate + " " + eventStartTime);
    console.log("newStartDate", newStartDate);

    const currentDateTime = new Date();

    const oneMinuteAgo = new Date(currentDateTime.getTime() - 1 * 60 * 1000);

    if (newStartDate < oneMinuteAgo) {
      toast.error(
        t(
          "you_have_exceeded_the_current_time_please_select_the_next_time_slot"
        ),
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      setLoading(false);
      return;
    }

    // console.log("emptyobjForResolution", emptyobjForResolution.current);

    // console.log("emptyobjForOtherDocument", emptyobjForOtherDocument.current);
    console.log("alll", [
      ...agendaListData,
      ...resolutionListData,
      ...otherListData,
    ]);

    toast.info(t("please_wait"), {
      position: toast.POSITION.TOP_RIGHT,
    });

    const reqDto = {
      meetingType: handleMeetingtype,
      title: meetingTitle,
      length: calculateTime(
        new Date(formattedStartDate + " " + eventStartTime),
        new Date(formattedEndDate + " " + eventEndTime)
      ),
      startTime: new Date(formattedStartDate + " " + eventStartTime),
      endTime: new Date(formattedEndDate + " " + eventEndTime),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      location: eventLocation,
      mode: selectEventMode,
      link: "",
      createdBy: userDetails.userId,
      participants: participants,
      agendaFiles: [...agendaListData, ...resolutionListData, ...otherListData],
      scheduleEventId: 0,
    };
    console.log("handelSubmitNewMeeting", reqDto);
    console.log("guestData", guestData);

    // setLoading(false);
    // return

    finalsubmit(reqDto);
  };

  const finalsubmit = (reqDto) => {
    createMeeting(userDetails.orgId, userDetails.userId, reqDto).then(
      (response) => {
        console.log(response.data);
        setLoading(false);
        if (response.data.returnValue == "1") {
          toast.success(t("meeting_created_successfully"), {
            position: toast.POSITION.TOP_RIGHT,
          });

          if (guestData.length > 0) {
            const invitePromises = guestData.map((guest) => {
              const newGuestReqDto = {
                personName: guest.guestName,
                officialEmailId: guest.guestEmail,
              };

              return sendMeetingInviteToExternalUsers(
                response.data.status,
                userDetails.userId,
                newGuestReqDto
              ).then((response) => {
                console.log("guest response.data", response.data);
                if (response.data.returnValue !== "1") {
                  throw new Error(response.data.message);
                }
                return response.data.message;
              });
            });

            Promise.all(invitePromises)
              .then((messages) => {
                // Show a single success message
                toast.success(t("all_guests_added_successfully"), {
                  position: toast.POSITION.TOP_RIGHT,
                });
              })
              .catch((error) => {
                console.error("Error sending invites:", error);
                toast.error(t("failed_to_add_some_guests"), {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
          }

          handleCloseMenuButton();
          setMeetingTitle("");
          setEventLocation("");
          // setEventStartTime("");
          // setEventEndTime("");
          setEventStartdate(new Date());
          setEventEndDate(new Date());
          setEventGuest([]);
          setMeetingParticipants([]);
          setEventStartTime(timeOptions.slice(1));
          setEventEndTime(timeEndOptions.slice(1));
          setExcludeMe(false);
          // setResolutionSubject("");
          // setResolutionType("");
          // resolutionInputRef.current = null;
          // setPolling("");
          // setPolling("");
          // setOtherSubject("");
          // otherInputRef.current = null;
          // setAgendaListdata([emptyAgendaObj]);
          setAgendaListdata([emptyAgendaObj]);
          setOtherListdata([emptyOtherObj]);
          setResolutionListdata([emptyResolutionObj]);

          closeFromDashboard("refresh");
          try {
            schedulerSocket.emit("updateSchedulerOfEventParticipantsClient", {
              userId: response.data.message,
              eventParticipants: meetingParticipants,
            });
          } catch (err) {
            console.log(err);
          }
        } else {
          toast.error(t("fail_to_create_meeting"), {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    );
  };

  // const fetchDataTaskReviewMeeting = () => {
  //   getAssociateTasks(userDetails.userId).then((response) => {
  //     if (response.data) {
  //       setAssociateTaskDataList(response.data);
  //       if (response.data.length > 0) {
  //         let taskIdList = [];
  //         response.data.forEach((task) => {
  //           taskIdList.push(task.id);
  //         });
  //         fetchUsersTaskReviewMeeting(taskIdList);
  //       }
  //     }
  //   });
  // };

  // const fetchUsersTaskReviewMeeting = (taskIdList) => {
  //   getAssociateUserIdsOfTaskId(taskIdList).then(
  //     (response) => {
  //       console.log(response.data);
  //       // handleSelectInternalUser(response.data);
  //     }
  //   );
  // };
  /////////////////////////////////////////////////////////////////////////////////////////////////////

  // const [resolutionSubject, setResolutionSubject] = useState("");
  // const [resolutionType, setResolutionType] = useState("");
  // const [polling, setPolling] = useState("");
  // const [showOfHand, setShowOfHand] = useState("");
  // const resolutionInputRef = useRef(null);
  // // const [resolutionFile , setResolutionFile] = useState()

  // const emptyobjForResolution = useRef({
  //   noticeBlock: "Resolution",
  //   noticeSubject: resolutionSubject,
  //   noticeDetail: "",
  //   files: {},
  //   polling: polling,
  //   showOfHand: showOfHand,
  // });

  // const handleResolutionSubject = (e) => {
  //   let setvalue = e.target.value;
  //   console.log("handleResolutionSubject", e.target.value);
  //   setResolutionSubject(e.target.value);
  //   emptyobjForResolution.current.noticeSubject = setvalue;
  // };

  // const handleResolutionTypeChange = (e) => {
  //   const selectedValue = e.target.value;
  //   console.log("temp", selectedValue);

  //   setResolutionType(selectedValue);

  //   if (selectedValue === "Polling") {
  //     emptyobjForResolution.current.polling = "Y";
  //     emptyobjForResolution.current.showOfHand = "N";
  //     setPolling("Y");
  //     setShowOfHand("N");
  //   } else if (selectedValue === "showHand") {
  //     emptyobjForResolution.current.showOfHand = "Y";
  //     emptyobjForResolution.current.polling = "N";
  //     setPolling("N");
  //     setShowOfHand("Y");
  //   }
  //   console.log("polling", polling);
  //   console.log("showOfHand", showOfHand);
  // };

  // const handleResolutionFileUpload = async (e) => {
  //   // Handle the file upload logic here
  //   const selectedFiles = e.target.files;
  //   console.log("Selected Files:", selectedFiles);
  //   // You can perform further actions like uploading the files to a server.

  //   const filesObject = {};

  //   for (let i = 0; i < selectedFiles.length; i++) {
  //     const base64 = await convertFileToBase64_Split(selectedFiles[i]);

  //     //  selectedFiles[i].name = base64;

  //     filesObject[selectedFiles[i].name] = base64;

  //     console.log(`File: ${selectedFiles[i].name}, Base64: ${base64}`);
  //   }

  //   emptyobjForResolution.current.files = filesObject;

  //   console.log("Files Object:", filesObject);

  //   console.log(
  //     "Updated emptyobjForResolution:",
  //     emptyobjForResolution.current
  //   );
  // };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  // const [othersubject, setOtherSubject] = useState("");
  // const otherInputRef = useRef(null);

  // const emptyobjForOtherDocument = useRef({
  //   noticeBlock: "Other",
  //   noticeSubject: othersubject,
  //   noticeDetail: "",
  //   files: {},
  //   polling: "",
  //   showOfHand: "",
  // });

  // const handleOthersubject = (e) => {
  //   let setvalues = e.target.value;
  //   setOtherSubject(e.target.value);
  //   emptyobjForOtherDocument.current.noticeSubject = setvalues;
  // };

  // const handleOtherUploadFile = async (e) => {
  //   const selectedFiles = e.target.files;
  //   console.log("Selected Files:", selectedFiles);
  //   // You can perform further actions like uploading the files to a server.

  //   const filesObject = {};

  //   for (let i = 0; i < selectedFiles.length; i++) {
  //     const base64 = await convertFileToBase64_Split(selectedFiles[i]);

  //     //  selectedFiles[i].name = base64;

  //     filesObject[selectedFiles[i].name] = base64;

  //     console.log(`File: ${selectedFiles[i].name}, Base64: ${base64}`);
  //   }

  //   emptyobjForOtherDocument.current.files = filesObject;

  //   console.log("Files Object:", filesObject);

  //   console.log(
  //     "Updated emptyobjForOtherDocument:",
  //     emptyobjForOtherDocument.current
  //   );
  // };

  ////////////////////////////////////////////////////////////////////////////////////////////////

  const [resolutionElements, setResolutionElements] = useState([]);
  const meetingResolutionRef = useRef(null);

  const emptyResolutionObj = {
    noticeBlock: "Resolution",
    noticeSubject: "",
    noticeDetail: "",
    files: {},
    polling: "",
    showOfHand: "",
  };

  const [resolutionListData, setResolutionListdata] = useState([
    emptyResolutionObj,
  ]);

  const handleAddResolution = () => {
    const temp = [...resolutionListData];
    temp.push(emptyResolutionObj);
    setResolutionListdata(temp);
  };

  const handleResolutionTitle = (e, index) => {
    console.log("resolutionListData", resolutionListData);

    const temp = [...resolutionListData];
    temp[index].noticeSubject = e.target.value;
    setResolutionListdata(temp);
    // setAgendaTitle(e.target.value);
  };

  // const handleResolutionDescription = (e, index) => {
  //   console.log("tempdesc", agendaListData);

  //   // setAgendaDescription(e.target.value);
  //   const tempDesc = [...agendaListData];
  //   tempDesc[index].noticeDetail = e.target.value;
  //   setAgendaListdata(tempDesc);
  // };

  const handleResolutionFileUpload = async (e, index) => {
    console.log("resolutionListData", resolutionListData);
    const files = e.target.files;
    const tempFile = [...resolutionListData];
    let filesMap = tempFile[index].files;

    let totalFiles = 0;

    for (let key in filesMap) {
      if (filesMap.hasOwnProperty(key)) {
        totalFiles++;
      }
    }

    totalFiles += files.length;

    if (totalFiles > 5) {
      toast.error(t("you_can_upload_a_maximum_of_five_files"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    for (let i = 0; i < files.length; i++) {
      if (files[i].size > 1024 * 1024 * MAX_FILE_SIZE_MEETING) {
        toast.error(
          t("please_select_file_less_than", {
            maxFileSize: MAX_FILE_SIZE_MEETING,
          }),
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      } else if (
        !VALID_FILE_EXTENSION_MEETING.includes(files[i].name.split(".").pop())
      ) {
        toast.error(t("invalid_file_type"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        const base64 = await convertFileToBase64_Split(files[i]);
        filesMap[files[i].name] = base64;
      }
    }

    // Ensure agendaFiles is initialized as an array

    console.log("handleFileUpload", filesMap);
    tempFile[index].files = filesMap;

    console.log("handleFileUpload", tempFile);

    setResolutionListdata(tempFile);

    // toast.success("File(s) Uploaded Successfully", {
    //   position: toast.POSITION.TOP_RIGHT,
    // });
  };

  const handleResolutionTaskTypeChange = (e, index) => {
    console.log("temp", resolutionListData);
    // setSelectedTaskType(event.target.value);
    // console.log("selectedTaskType", event.target.value);
    // setRadioCheckButton(event.target.value);
    const tempPoll = [...resolutionListData];
    if (e.target.value === "Polling") {
      tempPoll[index].polling = "Y";
      tempPoll[index].showOfHand = "N";
    } else if (e.target.value === "showHand") {
      tempPoll[index].polling = "N";
      tempPoll[index].showOfHand = "Y";
    }

    setResolutionListdata(tempPoll);
  };

  const handleResolutionRemoveAgenda = (index) => {
    const temp = [...resolutionListData];
    temp.splice(index, 1);
    setResolutionListdata(temp);
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////////

  const [otherElements, setOtherElements] = useState([]);
  const meetingOtherRef = useRef(null);

  const emptyOtherObj = {
    noticeBlock: "Other",
    noticeSubject: "",
    noticeDetail: "",
    files: {},
    polling: "",
    showOfHand: "",
  };

  const [otherListData, setOtherListdata] = useState([emptyOtherObj]);

  const handleAddOther = () => {
    const temp = [...otherListData];
    temp.push(emptyOtherObj);
    setOtherListdata(temp);
  };

  const handleOtherTitle = (e, index) => {
    console.log("temp", otherListData);

    const temp = [...otherListData];
    temp[index].noticeSubject = e.target.value;
    setOtherListdata(temp);
    // setAgendaTitle(e.target.value);
  };

  // const handleResolutionDescription = (e, index) => {
  //   console.log("tempdesc", agendaListData);

  //   // setAgendaDescription(e.target.value);
  //   const tempDesc = [...agendaListData];
  //   tempDesc[index].noticeDetail = e.target.value;
  //   setAgendaListdata(tempDesc);
  // };

  const handleOtherFileUpload = async (e, index) => {
    console.log("agendaListData", otherListData);
    const files = e.target.files;
    const tempFile = [...otherListData];
    let filesMap = tempFile[index].files;

    let totalFiles = 0;

    for (let key in filesMap) {
      if (filesMap.hasOwnProperty(key)) {
        totalFiles++;
      }
    }

    totalFiles += files.length;

    if (totalFiles > 5) {
      toast.error(t("you_can_upload_a_maximum_of_five_files"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    for (let i = 0; i < files.length; i++) {
      if (files[i].size > 1024 * 1024 * MAX_FILE_SIZE_MEETING) {
        toast.error(
          t("please_select_file_less_than", {
            maxFileSize: MAX_FILE_SIZE_MEETING,
          }),
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      } else if (
        !VALID_FILE_EXTENSION_MEETING.includes(files[i].name.split(".").pop())
      ) {
        toast.error(t("invalid_file_type"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        const base64 = await convertFileToBase64_Split(files[i]);
        filesMap[files[i].name] = base64;
      }
    }

    // Ensure agendaFiles is initialized as an array

    console.log("handleFileUpload", filesMap);
    tempFile[index].files = filesMap;

    console.log("handleFileUpload", tempFile);

    setOtherListdata(tempFile);

    // toast.success("File(s) Uploaded Successfully", {
    //   position: toast.POSITION.TOP_RIGHT,
    // });
  };

  // const handleOtherTaskTypeChange = (e, index) => {
  //   console.log("temp", agendaListData);
  //   // setSelectedTaskType(event.target.value);
  //   // console.log("selectedTaskType", event.target.value);
  //   // setRadioCheckButton(event.target.value);
  //   const tempPoll = [...agendaListData];
  //   if (e.target.value === "Polling") {
  //     tempPoll[index].polling = "Y";
  //     tempPoll[index].showOfHand = "N";
  //   } else if (e.target.value === "showHand") {
  //     tempPoll[index].polling = "N";
  //     tempPoll[index].showOfHand = "Y";
  //   }

  //   setAgendaListdata(tempPoll);
  // };

  const handleOtherRemoveAgenda = (index) => {
    const temp = [...otherListData];
    temp.splice(index, 1);
    setOtherListdata(temp);
  };

  const searchUserFilteredList = useMemo(() => {
    let filtered = [];
    if (selectedGuestInput.length > 0 && selectedGuestInput.trim() !== "") {
      filtered = allUsersList.filter((option) =>
        option.userName.toLowerCase().includes(selectedGuestInput.toLowerCase())
      );
    }

    return filtered;
  }, [selectedGuestInput, allUsersList]);

  const handleDeleteFile = (fileKey, agendaIndex) => {
    const updatedAgendaListData = agendaListData.map((agenda, index) => {
      if (index === agendaIndex) {
        const updatedFiles = Object.entries(agenda.files)
          .filter(([key, value]) => key !== fileKey)
          .reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
          }, {});
        return {
          ...agenda,
          files: updatedFiles,
        };
      }
      return agenda;
    });
    setAgendaListdata(updatedAgendaListData);
  };

  const handleResolutionDeleteFile = (fileKey, agendaIndex) => {
    const updatedAgendaListData = resolutionListData.map((agenda, index) => {
      if (index === agendaIndex) {
        const updatedFiles = Object.entries(agenda.files)
          .filter(([key, value]) => key !== fileKey)
          .reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
          }, {});
        return {
          ...agenda,
          files: updatedFiles,
        };
      }
      return agenda;
    });
    setResolutionListdata(updatedAgendaListData);
  };

  const handleOtherDeleteFile = (fileKey, agendaIndex) => {
    const updatedAgendaListData = otherListData.map((agenda, index) => {
      if (index === agendaIndex) {
        const updatedFiles = Object.entries(agenda.files)
          .filter(([key, value]) => key !== fileKey)
          .reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
          }, {});
        return {
          ...agenda,
          files: updatedFiles,
        };
      }
      return agenda;
    });
    setOtherListdata(updatedAgendaListData);
  };

  useEffect(()=>{
    setBookSteps([
     {
       target: ".bodSpecialMeetStepOne",
       content: "Select the event mode from Virtual, Physical, or Hybrid.",
       disableBeacon: true,
     },
     {
       target: ".bodSpecialMeetStepTwo",
       content: "Enter the location for the event here.",
       disableBeacon: true,
     },
     {
       target: ".bodSpecialMeetStepFour",
       content: "Search and add participant by typing their name.",
       disableBeacon: true,
     },
     {
       target: ".bodSpecialMeetStepFive",
       content: "Click here to add a new guest to the meeting.",
       disableBeacon: true,
     },
     {
       target: ".bodSpecialMeetStepSixSeven",
       content: "Here is the list of all the participants you've added.",
       disableBeacon: true,
     },
     {
       target: ".bodSpecialMeetStepSix",
       content: "Set the start and time for your meeting here.",
       disableBeacon: true,
     },
     {
       target: ".bodSpecialMeetStepSeven",
       content: "Set the end date and time for your meeting here.",
       disableBeacon: true,
     },
     {
       target: ".bodSpecialMeetStepEight",
       content: "Check this option if you want to exclude yourself from the event.",
       disableBeacon: true,
     },
     {
       target: ".bodSpecialMeetStepNine",
       content: "Provide a title for your meeting here.",
       disableBeacon: true,
     },
     {
       target: ".bodSpecialMeetStepTen",
       content: "Add meeting agendas in this section.",
       disableBeacon: true,
     },
     {
       target: ".bodSpecialMeetStepEleven",
       content: "Click here for information about the allowed file types and sizes.",
       disableBeacon: true,
     },
     {
       target: ".bodSpecialMeetStepThirteen",
       content: "Provide meeting agendas title for your meeting here",
       disableBeacon: true,
     },
     {
       target: ".bodSpecialMeetStepFourteen",
       content: "Provide meeting agendas description for your meeting here",
       disableBeacon: true,
     },
     {
       target: ".bodSpecialMeetStepFifteen",
       content: "You can Upload Meeting agenda File",
       disableBeacon: true,
     },
     {
      target: ".bodSpecialMeetStepFifteenOneTwo",
      content: "You can between polling and show of hand",
      disableBeacon: true,
    },
     {
       target: ".bodSpecialMeetStepSixteen",
       content: "You can a new meeting agendas",
       disableBeacon: true,
     },
     {
      target: ".bodSpecialMeetStepSeventeen",
      content: "In this Section you can give Special Resolution for Meeting",
      disableBeacon: true,
    },
    {
      target: ".bodSpecialMeetStepEighteen",
      content: "Here you can give Special Resolution Name",
      disableBeacon: true,
    },
    {
      target: ".bodSpecialMeetStepNineteen",
      content: "Here you can add Special Resolution File",
      disableBeacon: true,
    },
    {
      target: ".bodSpecialMeetStepNineteenTwent",
      content: "Here you can give Polling or Show Of Hand for Special Resolution File",
      disableBeacon: true,
    },
    {
      target: ".bodSpecialMeetStepTwenty",
      content: "Here you can add a new Section for Special Resolution For Meeting",
      disableBeacon: true,
    },
     {
       target: ".bodSpecialMeetStepTwentyOne",
       content: "In this Section you can give Additional Documents for Meeting",
       disableBeacon: true,
     },
     {
       target: ".bodSpecialMeetStepTwentyTwo",
       content: "Here you can give Additional Documents Name",
       disableBeacon: true,
     },
     {
       target: ".bodSpecialMeetStepTwentyThree",
       content: "Here you can add Additional Documents File",
       disableBeacon: true,
     },
     
     {
       target: ".bodSpecialMeetStepTwentyFour",
       content: "Here you can add a new Section for Additional Documents For Meeting",
       disableBeacon: true,
     },
   ]
 )
   },[])

  return (
    <>
     <Joyride
        steps={booksteps}
        run={run["viewOneTimeMettingSC"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        styles={{
          buttonClose: {
            display: "none",
          },
        }}
      />
      {loading ? (
        <>
          <div className="meetingVEContainer">
            <div className="center-progress" style={{ height: "65vh" }}>
              <CircularProgress sx={{ marginTop: "180px" }} />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="formElement">
            <FormControl className="formControl">
              <Select
                hiddenLabel
                className="formInputField bodSpecialMeetStepOne"
                variant="outlined"
                value={selectEventMode}
                onChange={handleEventMode}
              >
                <MenuItem value={"virtual"}>{t("virtual")}</MenuItem>
                <MenuItem value={"physical"}>{t("physical")}</MenuItem>
                <MenuItem value={"hybride"}>{t("hybrid")}</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                hiddenLabel
                placeholder={t("location")}
                required
                variant="outlined"
                className="formTextFieldArea bodSpecialMeetStepTwo"
                value={eventLocation}
                onChange={handleMeetingLocation}
              />
            </FormControl>
          </div>

          {/* add guest start */}

          <div className="meetGustDtlElement">
            <div className="metGustDtlElementTitle">
              <span>{t("add_guests")}*</span>
            </div>
            <div className="meetingAddGuestGrup">
              {/* <Button>Add Guests</Button> */}
              <div className="searchGustAC bodSpecialMeetStepFour">
                <Autocomplete
                  noOptionsText={t("please_type_to_search")}
                  id="participantSelect"
                  sx={{
                    width: 300,
                    backgroundColor: "#333",
                    borderRadius: "10px",
                    color: "white",
                  }}
                  value={selectedGuest}
                  onChange={(event, selectedValue) => {
                    console.log("newValue", selectedValue);

                    // if (selectedEvent.eventType === "Recurring Event") {
                    //   setSelectedGuest(selectedValue);
                    // } else {
                    setMeetingParticipants((prevList) => [
                      ...prevList,
                      {
                        id: null,
                        userId: selectedValue.userId,
                        userName: selectedValue.userName,
                        profileImage: selectedValue.profileUrl,
                        convener: "",
                        chairperson: "",
                        outSide: selectedValue.userType === "I" ? "N" : "Y",
                        calledBy: "N",
                        orgId: loginUser.orgId,
                      },
                    ]);
                    setSelectedGuest(null);
                    setSelectedGuestInput("");
                    // }
                  }}
                  inputValue={selectedGuestInput}
                  onInputChange={(event, newInputValue) => {
                    setSelectedGuestInput(newInputValue);
                  }}
                  options={searchUserFilteredList}
                  autoHighlight
                  getOptionLabel={(option) => option.userName}
                  getOptionDisabled={(option) =>
                    meetingParticipants.some(
                      (userDto) => userDto.userId === option.userId
                    )
                  }
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        srcSet={`${option.profileUrl}`}
                        src={`${option.profileUrl}`}
                        alt=""
                      />
                      {option.userName}
                      {option.userType === "I" ? (
                        <span style={{ padding: "5px" }}>
                          <Tooltip title={t("belongs_to_your_organization")}>
                            {/* <BadgeCheck /> */}
                            <Verified />
                          </Tooltip>
                        </span>
                      ) : (
                        <></>
                      )}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      InputProps={
                        {
                          // style: { color: "white !important" },
                        }
                      }
                      {...params}

                      // label="Select a participant"

                      // inputProps={{
                      //   ...params.inputProps,
                      //   autoComplete: "new-password", // disable autocomplete and autofill
                      // }}
                    />
                  )}
                />
                {/* {selectedEvent.eventType === "Recurring Event" &&
                    selectedGuest != null && (
                      <div className="addParticipantBtn">
                        <Button
                          onClick={() => {
                            setMeetingParticipants((prevList) => [
                              ...prevList,
                              {
                                id: null,
                                userId: selectedGuest.userId,
                                userName: selectedGuest.userName,
                                profileImage: selectedGuest.profileUrl,
                                convener: "",
                                chairperson: "",
                                outSide:
                                  selectedGuest.userType === "I" ? "N" : "Y",
                                calledBy: "N",
                                orgId: loginUser.orgId,
                                applyForAllRecurringEvents:
                                  buttonApplyForAllRecAddParticipant.current
                                    ? buttonApplyForAllRecAddParticipant.current
                                        ?.checked
                                      ? "Y"
                                      : "N"
                                    : "N",
                              },
                            ]);
                            setSelectedGuest(null);
                            setSelectedGuestInput("");
                          }}
                        >
                          ADD
                        </Button>
                      </div>
                    )} */}
              </div>

              <div className="addGustNewMeetBtn bodSpecialMeetStepFive">
                <Tooltip title={t("add_a_new_guest")}>
                  <Button
                    startIcon={<Add />}
                    className="dfultPrimaryBtn "
                    onClick={handleNewContactButton}
                  >
                    {t("new_guest")}
                  </Button>
                </Tooltip>
                <Popover
                  id={popoverId}
                  open={openPopover}
                  anchorEl={anchorEl}
                  onClose={handleCloseNeCwontactButtonModal}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                >
                  <div
                    className="popOverContain"
                    style={{ padding: 20, minWidth: "280px" }}
                  >
                    <div className="modelTitle">{t("enter_user_details")}</div>
                    <div
                      className="elementFormContainer"
                      style={{ padding: "20px 0px 10px 0px" }}
                    >
                      <FormControl className="formControl">
                        <TextField
                          required
                          className="modInputFieldGuestAdd"
                          label="User Name"
                          variant="outlined"
                          value={userName}
                          onChange={handleUserNameChange}
                        />
                      </FormControl>

                      <FormControl className="formControl">
                        <TextField
                          required
                          className="modInputFieldGuestAdd"
                          label={t("user_email")}
                          variant="outlined"
                          value={userEmail}
                          onChange={handleUserEmailChange}
                        />
                      </FormControl>
                    </div>
                    <div className="modActionBtnGrp">
                      <Button
                        className="dfultPrimaryBtn"
                        onClick={handleFormSubmit}
                        disabled={loaderForGuest}
                      >
                        {loaderForGuest ? (
                          <CircularProgress size={24} color="inherit" />
                        ) : (
                          <>{t("add")}</>
                        )}
                      </Button>
                      <Button
                        className="dfultDarkSecondaryBtn"
                        onClick={handleCloseNeCwontactButtonModal}
                      >
                        {t("cancel")}
                      </Button>
                    </div>
                  </div>
                </Popover>
              </div>
            </div>
            <div className="metGustLstGrp bodSpecialMeetStepSixSeven">
              {meetingParticipants.map((participantDto) => {
                return (
                  <>
                    <div
                      className={`metGustLstInd`}
                      style={{ cursor: "unset" }}
                    >
                      <div className="metGstImg">
                        <img src={participantDto.profileImage} alt="" />
                        {/* <span className="metConVerd">
                            <CheckCircle />
                          </span> */}
                      </div>
                      <div className="metGstName">
                        {participantDto.userName}
                        {participantDto.outSide === "N" ? (
                          <span style={{ padding: "5px" }}>
                            <Tooltip title={t("belongs_to_your_organization")}>
                              {/* <BadgeCheck /> */}
                              <Verified />
                            </Tooltip>
                          </span>
                        ) : (
                          <></>
                        )}

                        {participantDto.convener === "Y" && (
                          <span className="metConvenor">({t("convener")})</span>
                        )}
                        {participantDto.chairperson === "Y" && (
                          <span className="metConvenor">
                            ({t("chairperson")})
                          </span>
                        )}
                      </div>
                      {participantDto.userId &&
                        participantDto.convener !== "Y" &&
                        refreshCount > 0 && (
                          <div className="mkAsConvenorBtnGrp">
                            <Button
                              onClick={() =>
                                makeConveyor(participantDto.userId)
                              }
                            >
                              {t("make_convener")}
                            </Button>
                          </div>
                        )}
                      {participantDto.userId &&
                        participantDto.chairperson !== "Y" &&
                        refreshCount > 0 && (
                          // selectedEvent.eventType === "Meeting" &&
                          <div className="mkAsConvenorBtnGrp">
                            <Button
                              onClick={() =>
                                makeChairperson(participantDto.userId)
                              }
                            >
                              {t("make_chairperson")}
                            </Button>
                          </div>
                        )}
                      {(participantDto.convener === "N" ||
                        participantDto.convener === "") &&
                        (participantDto.chairperson === "N" ||
                          participantDto.chairperson === "") && (
                          <div className="mkAsConvenorBtnGrp">
                            <IconButton
                              aria-describedby={idRP}
                              onClick={(event) => {
                                handleClickRP(event);
                                setSelectedParticipant(participantDto);
                              }}
                            >
                              <Trash />
                            </IconButton>
                            <Popover
                              id={idRP}
                              open={openRP}
                              anchorEl={anchorElRP}
                              onClose={handleCloseRP}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              transformOrigin={{
                                vertical: "center",
                                horizontal: "right",
                              }}
                            >
                              <div style={{ padding: "20px" }}>
                                <div className="modelTitle">
                                  {t("confirmation")}
                                </div>
                                <div
                                  className="modInputFild"
                                  style={{ padding: "20px 10px 10px 0px" }}
                                >
                                  {/* Remove {selectedParticipant?.userName} from
                                  guest list? */}
                                  {t("remove_from_guest_list", {
                                    userName: selectedParticipant?.userName,
                                  })}
                                </div>

                                {/* {selectedEvent.eventType ===
                                    "Recurring Event" && ( */}
                                {/* <div
                                      className="modActionBtnGrp"
                                      style={{ padding: "5px 0px 5px 0px" }}
                                    >
                                      <input
                                        type="checkbox"
                                        ref={
                                          buttonApplyForAllRecRemoveParticipant
                                        }
                                      />
                                      Remove for all recurring events
                                    </div> */}
                                {/* )} */}

                                <div className="modActionBtnGrp">
                                  <Button
                                    className="dfultPrimaryBtn"
                                    // onClick={() => removeParticipant()}
                                    onClick={() =>
                                      handleRemoveParticipant(
                                        selectedParticipant?.userId,
                                        selectedParticipant?.userName
                                      )
                                    }
                                    ref={buttonRPRef}
                                  >
                                    {t("remove")}
                                  </Button>
                                  <Button
                                    className="dfultDarkSecondaryBtn"
                                    onClick={handleCloseRP}
                                    ref={buttonRPCancelRef}
                                  >
                                    {t("cancel")}
                                  </Button>
                                </div>
                                {/* <div
                                  className="modActionBtnGrp"
                                  style={{ marginTop: "5px" }}
                                >
                                  <Button
                                    className="dfultPrimaryBtn"
                                    onClick={() => removeParticipant("Y")}
                                    // ref={buttonRefRP}
                                  >
                                    Remove from all Recurring Events
                                  </Button>
                                </div> */}
                              </div>
                            </Popover>
                          </div>
                        )}
                    </div>
                  </>
                );
              })}
              {/* <div className="metGustLstInd gustAsConvenor">
                <div className="metGstImg">
                  <img src={userImg1} alt="" />
                  <span className="metConVerd">
                    <CheckCircle />
                  </span>
                </div>
                <div className="metGstName">
                  Aiyasha Hasan<span className="metConvenor">(Convenor)</span>
                </div>
                <div className="mkAsConvenorBtnGrp">
                  <Button>Make Convenor</Button>
                </div>
              </div> */}
              {/*
              <div className="metGustLstInd">
                <div className="metGstImg">
                  <img src={userImg2} alt="" />
                  <span className="metConVerd">
                    <CheckCircle />
                  </span>
                </div>
                <div className="metGstName">
                  Sanket Santra<span className="metConvenor">(Convenor)</span>
                </div>
                <div className="mkAsConvenorBtnGrp">
                  <Button>Make Convenor</Button>
                </div>
              </div>

              <div className="metGustLstInd">
                <div className="metGstImg">
                  <img src={userImg3} alt="" />
                  <span className="metConVerd">
                    <CheckCircle />
                  </span>
                </div>
                <div className="metGstName">
                  Prashant Saha<span className="metConvenor">(Convenor)</span>
                </div>
                <div className="mkAsConvenorBtnGrp">
                  <Button>Make Convenor</Button>
                </div>
              </div>

              <div className="metGustLstInd">
                <div className="metGstImg">
                  <img src={userImg4} alt="" />
                  <span className="metConVerd">
                    <CheckCircle />
                  </span>
                </div>
                <div className="metGstName">
                  Sk. Aftabuddin<span className="metConvenor">(Convenor)</span>
                </div>
                <div className="mkAsConvenorBtnGrp">
                  <Button>Make Convenor</Button>
                </div>
              </div> */}
              {/* {selectedEvent.eventType === "Recurring Event" &&
                  meetingParticipants.some((obj) => obj.id === null) && (
                    <>
                      <div
                        className="modActionBtnGrp"
                        style={{ padding: "10px 0px 10px 0px" }}
                      >
                        <input
                          style={{ width: "16px", height: "16px" }}
                          type="checkbox"
                          ref={buttonApplyForAllRecAddParticipant}
                        />
                        <span style={{ fontSize: "17px", color: "white" }}>
                          Add New participants for all related recurring events
                        </span>
                      </div>
                      <div
                        className="modActionBtnGrp"
                        style={{ padding: "2px 0px 2px 0px" }}
                      >
                        <span style={{ fontSize: "10px", color: "red" }}>
                          *Convenor/Chairperson needs to be changed individually
                          for each recurring event
                        </span>
                      </div>
                    </>
                  )} */}
            </div>
            {!AppUtils.isPersonal(loginUser) &&
              meetingParticipants.length > 1 && (
                <div
                  className="convTimeCheckbix"
                  style={{ padding: "5px 0px 5px 0px" }}
                >
                  <input
                    type="checkbox"
                    ref={selectConvenientTimeCheckboxRef}
                    onChange={(event) => {
                      console.log(event.target.checked);

                      if (meetingParticipants.length < 2) {
                        toast.error(
                          t(
                            "please_select_atleast_two_participants_for_viewing_convenient_time"
                          )
                        );
                        selectConvenientTimeCheckboxRef.current.checked = false;
                        return;
                      }

                      if (event.target.checked) {
                        let newStartTime = new Date(eventStartDate);
                        let fromTimeSplice = eventStartTime.match(
                          /(\d+):(\d+) ([APMapm]{2})/
                        );
                        let fromHours = parseInt(fromTimeSplice[1], 10);
                        var fromMinutes = parseInt(fromTimeSplice[2], 10);
                        var fromPeriod = fromTimeSplice[3].toUpperCase();
                        if (
                          fromPeriod.toUpperCase() === "PM" &&
                          fromHours !== 12
                        ) {
                          fromHours += 12;
                        }
                        newStartTime.setHours(fromHours, fromMinutes, 0, 0);

                        let newEndTime = new Date(endDay);
                        let toTimeSplice = eventEndTime.match(
                          /(\d+):(\d+) ([APMapm]{2})/
                        );
                        let toHours = parseInt(toTimeSplice[1], 10);
                        var toMinutes = parseInt(toTimeSplice[2], 10);
                        var toPeriod = toTimeSplice[3].toUpperCase();
                        if (
                          toPeriod.toUpperCase() === "PM" &&
                          fromHours !== 12
                        ) {
                          toHours += 12;
                        }
                        newEndTime.setHours(toHours, toMinutes, 0, 0);

                        // console.log(
                        //   "dadddddddddddddddddddddddddddddddddddd",
                        //   newStartTime,
                        //   newEndTime,
                        //   meetingParticipants,
                        //   eventGuest
                        // );

                        let data = {
                          meetingStartTime: newStartTime,
                          meetingEndTime: newEndTime,
                          usersList: meetingParticipants.map(
                            (guestDto) => guestDto.userId
                          ),
                          source: "event",
                        };

                        setConvenientTimeData(data);
                      } else {
                        setConvenientTimeData(null);
                      }
                    }}
                  />
                  {t("select_a_convenient_time")}
                </div>
              )}
          </div>

          {/* add guest end */}

          <div className="formElement">
            <div className="medDateTime">
              <div
                className="frmDateTime bodSpecialMeetStepSix"
                style={{
                  display: "flex !important",
                  flexWrap: "wrap",
                  justifyContent: "center !important",
                }}
              >
                <div className="frmDateInput">
                  <div className="frmDaysPick">{currentDay}</div>
                  {/* START TIME */}
                  <ReactDatePicker
                    locale={currentLocale}
                    className="frmdatePick"
                    selected={startDay}
                    // onChange={(date) => setStartDate(date)}
                    onChange={handleStartDateChange}
                    minDate={new Date()}
                    maxDate={new Date().getTime() + 1051200 * 60000}
                    value={eventStartDate}
                    dateFormat="dd-MMM-yyyy"
                    // onChange={(e) => setEventStartdate(e.target.value)}
                  />
                </div>
                <div className="frmTimeInput">
                  <Select
                    className="slctFrmTime"
                    value={startTime}
                    onChange={handleStartTimeChange}
                    sx={{ maxWidth: "115px !important" }}
                  >
                    {timeOptions.map((time, index) => (
                      <MenuItem key={index} value={time}>
                        {time}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>

              <span className="spanElement">{t("to")}</span>

              <div
                className="toDateTime bodSpecialMeetStepSeven"
                style={{
                  flexWrap: "wrap",
                  justifyContent: "center !important",
                }}
              >
                <div className="frmDateInput">
                  <div className="frmDaysPick">{currentEndDay}</div>
                  {/* END TIME */}
                  <ReactDatePicker
                    locale={currentLocale}
                    className="frmdatePick"
                    selected={endDay}
                    // onChange={(date) => setStartDate(date)}
                    maxDate={DateUtils.add24HoursTDate(startDay)}
                    onChange={handleEndDateChange}
                    minDate={startDay}
                    value={eventEndDate}
                    dateFormat="dd-MMM-yyyy"
                  />
                </div>
                <div className="toTimeInput">
                  <Select
                    className="slctFrmTime"
                    value={endTime}
                    onChange={handleEndTimeChange}
                    sx={{ maxWidth: "115px !important" }}
                  >
                    {timeEndOptions.map((time, index) => (
                      <MenuItem key={index} value={time}>
                        {time}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
          </div>

          <div className="formElement">
            <FormControl className="formControl">
              <FormGroup aria-label="position" row className="feformGroup">
                <FormControlLabel
                  className="fromCheckBox bodSpecialMeetStepEight"
                  control={
                    <Checkbox
                      color="primary"
                      // style={{ color: "white" }}
                      checked={excludeMe}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label={t("exclude_me")}
                  style={{ color: "white" }}
                />
              </FormGroup>
            </FormControl>
          </div>

          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                className="MainTitleTexbox bodSpecialMeetStepNine"
                hiddenLabel
                variant="filled"
                placeholder={t("add_title")}
                required
                value={meetingTitle}
                onChange={handleMeetingTitle}
              />
            </FormControl>
          </div>

          {/* Agenda start */}
          <div className="taskElementGrp bodSpecialMeetStepTen">
            <div className="headWthInfo">
              <div className="tskElemHeding meetingHeadings">{t("agenda")}</div>
              <div className="drivInfo bodSpecialMeetStepEleven">
                <Tooltip
                  title={
                    <>
                      <b>
                        <em>{t("information")}</em>
                      </b>
                      <ul className="htmlTooltipUl">
                        <li>
                          <em>
                            <strong>
                              {t("max_files_that_can_be_uploaded")}:
                            </strong>
                          </em>{" "}
                          5
                        </li>
                        <li>
                          <em>
                            <strong>{t("max_size_per_file")}:</strong>
                          </em>{" "}
                          5 MB
                        </li>
                        <li>
                          <em>
                            <strong>{t("allowed_file_types")}:</strong>
                          </em>{" "}
                          .png, .jpg, .jpeg, .gif, .doc, .docx, .docm, .ppt,
                          .pptx, .pptm, .txt, .pdf, .xlsx, .xls
                        </li>
                      </ul>
                    </>
                  }
                >
                  <IconButton className="drivInfoBtn">
                    <Info />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            {agendaListData &&
              agendaListData.map((agenda, index) => (
                <div className="multiElementFrmGrp">
                  <div className="formElement">
                    <FormControl className="formControl controlWithRemove">
                      <TextField
                        hiddenLabel
                        placeholder={t("enter_agenda_here")}
                        variant="outlined"
                        className="formTextFieldArea bodSpecialMeetStepThirteen"
                        value={agenda.agendaTitle}
                        onChange={(e) => handleAgendaTitle(e, index)}
                      />
                      {/* <IconButton
                        className="removeBtn"
                        onClick={() => index !== 0 && handleRemoveAgenda(index)}
                        disabled={index === 0}
                      >
                        <Trash />
                      </IconButton> */}
                      {agendaListData.length > 1 && (
                        <IconButton
                          className="removeBtn"
                          onClick={() => handleRemoveAgenda(index)}
                        >
                          <Trash />
                        </IconButton>
                      )}
                    </FormControl>
                  </div>
                  <div className="formElement">
                    <FormControl className="formControl controlWithRemove">
                      <TextField
                        hiddenLabel
                        placeholder={t("enter_agenda_description")}
                        variant="outlined"
                        className="formTextFieldArea bodSpecialMeetStepFourteen"
                        value={agenda.agendaDesc}
                        onChange={(e) => handleAgendaDescription(e, index)}
                      />
                    </FormControl>
                  </div>
                  <div class="elementFileListedGrp">
                    {/* <div className="horaizonScroll"> */}
                    {/* <IconButton
                        className="goPrevousBtn"
                        onClick={() => {
                          handleHorizantalScroll(
                            elementRef.current,
                            25,
                            200,
                            -10
                          );
                        }}
                        disabled={arrowDisable}
                      >
                        <ArrowBackIosNew />
                      </IconButton> */}
                    {/* <div> */}
                    {/* <div class="elemntFilsgrp" ref={elementRef}>
                          <div className="elemntFile">
                    <Tooltip
                      title="Lorem some data"
                      arrow
                      className="elmFileTolTip"
                    >
                      <span className="elemntFilIoc">
                        <DescriptionOutlined />
                      </span>
                      <span className="elemntFilNm">Lorem some data</span>
                      <div className="fltrdTgClose">
                        <Close />
                      </div>
                    </Tooltip>
                  </div>
                        </div> */}
                    {/* <div class="elemntFilsgrp" ref={elementRef}> */}
                    {agendaListData &&
                      agendaListData.length > 0 &&
                      Object.keys(agendaListData[index].files).length > 0 && (
                        <FileCursor>
                          <>
                            {agendaListData &&
                              agendaListData.length &&
                              Object.keys(agendaListData[index].files).map(
                                (fileKey) => (
                                  <div
                                    className="elemntFile"
                                    // onClick={(e) => openFileInTab(doc)}
                                  >
                                    <Tooltip
                                      title={fileKey}
                                      arrow
                                      className="elmFileTolTip"
                                    >
                                      <span className="elemntFilIoc">
                                        <DescriptionOutlined />
                                      </span>
                                      <span className="elemntFilNm">
                                        {fileKey}
                                      </span>
                                    </Tooltip>
                                    <IconButton
                                      className="deleteFileBtn"
                                      onClick={() =>
                                        handleDeleteFile(fileKey, index)
                                      }
                                      style={{ color: "red" }}
                                    >
                                      <Close />
                                    </IconButton>
                                  </div>
                                )
                              )}
                          </>
                        </FileCursor>
                      )}
                    {/* </div> */}
                    {/* </div> */}
                    {/* {tempDivWidth > 470 && (
                        <IconButton
                          className="goNextBtn"
                          onClick={() => {
                            handleHorizantalScroll(
                              elementRef.current,
                              25,
                              200,
                              10
                            );
                          }}
                        >
                          <ArrowForwardIos />
                        </IconButton>
                      )} */}
                    {/* </div> */}

                    <div class="elmntAddMrFiles">
                      <Tooltip title={t("upload_new_file")} arrow>
                        <label>
                          <input
                            // ref={fileInputRef}
                            id={`fileupload_${index}`}
                            type="file"
                            style={{ display: "none" }}
                            onChange={(e) => handleFileUpload(e, index)}
                            multiple
                          />
                          <Button
                            className="elemntMrFileBtn bodSpecialMeetStepFifteen"
                            variant="outlined"
                            onClick={() => {
                              document
                                .getElementById(`fileupload_${index}`)
                                .click();
                            }}
                          >
                            {/* <Attachment /> */}
                            <Plus />
                          </Button>
                        </label>
                      </Tooltip>
                    </div>
                  </div>
                  <div className="formElement bodSpecialMeetStepFifteenOneTwo">
                    <FormControl className="formControl">
                      <RadioGroup
                        className="formRadioGroup"
                        labelId="setTasktype"
                        name="setTasktype"
                        value={agenda.agendaPool}
                        onChange={(e) => handleTaskTypeChange(e, index)}
                      >
                        <FormControlLabel
                          className="formRadioField"
                          value="Polling"
                          control={<Radio />}
                          label={t("polling")}
                        />
                        <FormControlLabel
                          className="formRadioField"
                          value="showHand"
                          control={<Radio />}
                          label={t("show_of_hand")}
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
              ))}

            <div className="formBtnElement">
              <Button
                variant="outlined"
                startIcon={<Plus />}
                className="comBtnOutline bodSpecialMeetStepSixteen"
                onClick={(e) => handleAddAgenda()}
              >
                {t("add_agenda")}
              </Button>
            </div>
          </div>

          {agendaElements.map((element, index) => (
            <div className="taskElementGrp">
              <div className="elemntFilsgrp" ref={meetingRef}>
                <div key={index}>{element}</div>
              </div>
            </div>
          ))}

          <br />

          <div className="taskElementGrp bodSpecialMeetStepSeventeen">
            <div className="tskElemHeding">
              {t("special_resolution_will_be_proposed")}:
            </div>

            {resolutionListData &&
              resolutionListData.map((resolution, index) => (
                <div className="multiElementFrmGrp">
                  <div className="formElement">
                    <FormControl className="formControl controlWithRemove">
                      <TextField
                        hiddenLabel
                        placeholder={t("special_resolution_name")}
                        variant="outlined"
                        className="formTextFieldArea bodSpecialMeetStepEighteen"
                        value={resolution.resolutionTitle}
                        onChange={(e) => handleResolutionTitle(e, index)}
                      />
                      {/* <IconButton
                        className="removeBtn"
                        onClick={() =>
                          index !== 0 && handleResolutionRemoveAgenda(index)
                        }
                        disabled={index === 0}
                      >
                        <Trash />
                      </IconButton> */}
                      {resolutionListData.length > 1 && (
                        <IconButton
                          className="removeBtn"
                          onClick={() => handleResolutionRemoveAgenda(index)}
                        >
                          <Trash />
                        </IconButton>
                      )}
                    </FormControl>
                  </div>
                  {/* <div className="formElement">
            <FormControl className="formControl controlWithRemove">
              <TextField
                hiddenLabel
                placeholder="Enter Description"
                variant="outlined"
                className="formTextFieldArea"
                // value={agenda.agendaDesc}
                // onChange={(e) => handleAgendaDescription(e, index)}
              />
            </FormControl>
          </div> */}
                  <div class="elementFileListedGrp">
                    {/* <div className="horaizonScroll"> */}
                    {/* <IconButton
                        className="goPrevousBtn"
                        onClick={() => {
                          handleHorizantalScroll(
                            elementRef.current,
                            25,
                            200,
                            -10
                          );
                        }}
                        disabled={arrowDisable}
                      >
                        <ArrowBackIosNew />
                      </IconButton> */}
                    {/* <div> */}
                    {/* <div class="elemntFilsgrp" ref={elementRef}> */}
                    {resolutionListData &&
                      resolutionListData.length > 0 &&
                      Object.keys(resolutionListData[index].files).length >
                        0 && (
                        <FileCursor>
                          <>
                            {resolutionListData &&
                              resolutionListData.length &&
                              Object.keys(resolutionListData[index].files).map(
                                (fileKey) => (
                                  <div
                                    className="elemntFile"
                                    // onClick={(e) => openFileInTab(doc)}
                                  >
                                    <Tooltip
                                      title={fileKey}
                                      arrow
                                      className="elmFileTolTip"
                                    >
                                      <span className="elemntFilIoc">
                                        <DescriptionOutlined />
                                      </span>
                                      <span className="elemntFilNm">
                                        {fileKey}
                                      </span>
                                    </Tooltip>
                                    <IconButton
                                      className="deleteFileBtn"
                                      onClick={() =>
                                        handleResolutionDeleteFile(
                                          fileKey,
                                          index
                                        )
                                      }
                                      style={{ color: "red" }}
                                    >
                                      <Close />
                                    </IconButton>
                                  </div>
                                )
                              )}
                          </>
                        </FileCursor>
                      )}
                    {/* </div> */}
                    {/* <div class="elemntFilsgrp" ref={elementRef}>
                          <div className="elemntFile">
                    <Tooltip
                      title="Lorem some data"
                      arrow
                      className="elmFileTolTip"
                    >
                      <span className="elemntFilIoc">
                        <DescriptionOutlined />
                      </span>
                      <span className="elemntFilNm">Lorem some data</span>
                      <div className="fltrdTgClose">
                        <Close />
                      </div>
                    </Tooltip>
                  </div>
                        </div> */}
                    {/* </div> */}
                    {/* {tempDivWidth > 470 && (
                        <IconButton
                          className="goNextBtn"
                          onClick={() => {
                            handleHorizantalScroll(
                              elementRef.current,
                              25,
                              200,
                              10
                            );
                          }}
                        >
                          <ArrowForwardIos />
                        </IconButton>
                      )} */}
                    {/* </div> */}

                    <div class="elmntAddMrFiles bodSpecialMeetStepNineteen">
                      <Tooltip title={t("upload_new_file")} arrow>
                        <label>
                          <input
                            // ref={resolutionInputRef}
                            id={`fileresolutionupload_${index}`}
                            type="file"
                            style={{ display: "none" }}
                            onChange={(e) =>
                              handleResolutionFileUpload(e, index)
                            }
                            multiple
                          />
                          <Button
                            className="elemntMrFileBtn"
                            variant="outlined"
                            onClick={() => {
                              // Trigger the file input click using the ref
                              document
                                .getElementById(`fileresolutionupload_${index}`)
                                .click();
                            }}
                          >
                            {/* <Attachment /> */}
                            <Plus />
                          </Button>
                        </label>
                      </Tooltip>
                    </div>
                  </div>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <RadioGroup
                        className="formRadioGroup bodSpecialMeetStepNineteenTwent"
                        labelId="setTasktype"
                        name="setTasktype"
                        value={resolution.resolutionPool}
                        onChange={(e) =>
                          handleResolutionTaskTypeChange(e, index)
                        }
                      >
                        <FormControlLabel
                          className="formRadioField"
                          value="Polling"
                          control={<Radio />}
                          label={t("polling")}
                        />
                        <FormControlLabel
                          className="formRadioField"
                          value="showHand"
                          control={<Radio />}
                          label={t("show_of_hand")}
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
              ))}
            <div className="formBtnElement">
              <Button
                variant="outlined"
                startIcon={<Plus />}
                className="comBtnOutline bodSpecialMeetStepTwenty"
                onClick={(e) => handleAddResolution()}
              >
                {t("add_resolution")}
              </Button>
            </div>
          </div>
          {resolutionElements.map((element, index) => (
            <div className="taskElementGrp">
              <div className="elemntFilsgrp" ref={meetingResolutionRef}>
                <div key={index}>{element}</div>
              </div>
            </div>
          ))}

          <br />
          <div className="taskElementGrp bodSpecialMeetStepTwentyOne">
            <div className="tskElemHeding">
              {t("additional_documents_for_meeting")}:
            </div>
            {otherListData &&
              otherListData.map((otherlist, index) => (
                <div className="multiElementFrmGrp">
                  <div className="formElement">
                    <FormControl className="formControl controlWithRemove">
                      <TextField
                        hiddenLabel
                        placeholder={t("additional_document_name")}
                        variant="outlined"
                        className="formTextFieldArea bodSpecialMeetStepTwentyTwo"
                        value={otherlist.othertitle}
                        onChange={(e) => handleOtherTitle(e, index)}
                      />
                      {/* <IconButton
                        className="removeBtn"
                        onClick={() =>
                          index !== 0 && handleOtherRemoveAgenda(index)
                        }
                        disabled={index === 0}
                      >
                        <Trash />
                      </IconButton> */}
                      {otherListData.length > 1 && (
                        <IconButton
                          className="removeBtn"
                          onClick={() => handleOtherRemoveAgenda(index)}
                        >
                          <Trash />
                        </IconButton>
                      )}
                    </FormControl>
                  </div>
                  {/* <div className="formElement">
            <FormControl className="formControl controlWithRemove">
              <TextField
                hiddenLabel
                placeholder="Enter Description"
                variant="outlined"
                className="formTextFieldArea"
                // value={agenda.agendaDesc}
                // onChange={(e) => handleAgendaDescription(e, index)}
              />
            </FormControl>
          </div> */}
                  <div class="elementFileListedGrp">
                    {/* <div className="horaizonScroll"> */}
                    {/* <IconButton
                        className="goPrevousBtn"
                        onClick={() => {
                          handleHorizantalScroll(
                            elementRef.current,
                            25,
                            200,
                            -10
                          );
                        }}
                        disabled={arrowDisable}
                      >
                        <ArrowBackIosNew />
                      </IconButton> */}
                    {/* <div> */}
                    {/* <div class="elemntFilsgrp" ref={elementRef}>
                          <div className="elemntFile">
                    <Tooltip
                      title="Lorem some data"
                      arrow
                      className="elmFileTolTip"
                    >
                      <span className="elemntFilIoc">
                        <DescriptionOutlined />
                      </span>
                      <span className="elemntFilNm">Lorem some data</span>
                      <div className="fltrdTgClose">
                        <Close />
                      </div>
                    </Tooltip>
                  </div>
                        </div> */}
                    {/* <div class="elemntFilsgrp" ref={elementRef}> */}
                    {otherListData &&
                      otherListData.length > 0 &&
                      Object.keys(otherListData[index].files).length > 0 && (
                        <FileCursor>
                          <>
                            {otherListData &&
                              otherListData.length &&
                              Object.keys(otherListData[index].files).map(
                                (fileKey) => (
                                  <div
                                    className="elemntFile"
                                    // onClick={(e) => openFileInTab(doc)}
                                  >
                                    <Tooltip
                                      title={fileKey}
                                      arrow
                                      className="elmFileTolTip"
                                    >
                                      <span className="elemntFilIoc">
                                        <DescriptionOutlined />
                                      </span>
                                      <span className="elemntFilNm">
                                        {fileKey}
                                      </span>
                                    </Tooltip>
                                    <IconButton
                                      className="deleteFileBtn"
                                      onClick={() =>
                                        handleOtherDeleteFile(fileKey, index)
                                      }
                                      style={{ color: "red" }}
                                    >
                                      <Close />
                                    </IconButton>
                                  </div>
                                )
                              )}
                          </>
                        </FileCursor>
                      )}
                    {/* </div> */}
                    {/* </div> */}
                    {/* {tempDivWidth > 470 && (
                        <IconButton
                          className="goNextBtn"
                          onClick={() => {
                            handleHorizantalScroll(
                              elementRef.current,
                              25,
                              200,
                              10
                            );
                          }}
                        >
                          <ArrowForwardIos />
                        </IconButton>
                      )} */}
                    {/* </div> */}

                    <div class="elmntAddMrFiles">
                      <Tooltip title={t("upload_new_file")} arrow>
                        <label>
                          <input
                            // ref={otherInputRef}
                            id={`fileotherupload_${index}`}
                            type="file"
                            style={{ display: "none" }}
                            onChange={(e) => handleOtherFileUpload(e, index)}
                            multiple
                          />
                          <Button
                            className="elemntMrFileBtn bodSpecialMeetStepTwentyThree"
                            variant="outlined"
                            onClick={() => {
                              // Trigger the file input click using the ref
                              document
                                .getElementById(`fileotherupload_${index}`)
                                .click();
                            }}
                          >
                            {/* <Attachment /> */}
                            <Plus />
                          </Button>
                        </label>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              ))}
            <div className="formBtnElement">
              <Button
                variant="outlined"
                startIcon={<Plus />}
                className="comBtnOutline bodSpecialMeetStepTwentyFour"
                onClick={(e) => handleAddOther()}
              >
                {t("add_other_document")}
              </Button>
            </div>
          </div>
          {otherElements.map((element, index) => (
            <div className="taskElementGrp">
              <div className="elemntFilsgrp" ref={meetingOtherRef}>
                <div key={index}>{element}</div>
              </div>
            </div>
          ))}

          {/* Agenda end */}
        </>
      )}
      <div className="fixedButtonsContainer">
        <div className="formBtnElement">
          {/* <Button
            className="dfultPrimaryBtn"
            onClick={() => createFinalMeeting()}
          >
            Save
          </Button> */}
          <Button
            className="dfultPrimaryBtn"
            onClick={() => createFinalMeeting()}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              <>{t("submit")}</>
            )}
          </Button>
          {!loading && (
            <Button
              className="dfultDarkSecondaryBtn"
              onClick={() => handleCloseMenuButton()}
            >
              {t("cancel")}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default Meeting;
