import { CloseFullscreen, OpenInFull } from "@mui/icons-material";
import {
  Button,
  FormControl,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useReducer } from "react";
import { XLg } from "react-bootstrap-icons";
import { useForm } from "react-hook-form";
import TaskService from "../../../../../services/TaskService";
import { GetLoginUserDetails } from "../../../../../utils/Utils";
import { useTranslation } from "react-i18next";

export const InterviewFeedbackForm = ({
  taskId,
  formDataTrans,
  taskNature,
  formData,
  handleClose,
}) => {
  const loginUserDetail = GetLoginUserDetails();
  const { t } = useTranslation();
  const fullScreenStyle = {
    width: "100%",
  };
  const initialState = { isExpanded: false };
  const reducer = (state, action) => {
    switch (action.type) {
      case "expandOrShrinkWindow":
        return { ...state, isExpanded: !state.isExpanded };
      default:
        return { ...state };
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  const [feedback, setFeedback] = useState("");
  const { register, handleSubmit } = useForm();

  const submitFeedback = (data) => {
    console.log("data =", data);
    const temp = { ...data, interviewerId: 0 };
    temp.rating = parseInt(temp.rating);

    TaskService.giveInterviewFeedback(
      formData.prospectiveEmployeeId,
      taskId,
      loginUserDetail.userId,
      temp
    );
  };

  return (
    <>
      <div
        className="RightFlotingFormContainerArea muntajForm"
        style={state.isExpanded ? fullScreenStyle : {}}
      >
        <div className="flottingContainer">
          <div className="flotHeaderPanel">
            <div class="flotHedCon">{t("interviewFeedback")}</div>
            <div class="flotActionBtnGrp">
              <div class="flotActBtn closeBtnD10">
                <Tooltip
                  arrow
                  title={
                    state.isExpanded
                      ? t("exitFullScreen")
                      : t("enterFullScreen")
                  }
                >
                  <IconButton
                    onClick={(e) => {
                      dispatch({ type: "expandOrShrinkWindow" });
                    }}
                  >
                    {state.isExpanded ? <CloseFullscreen /> : <OpenInFull />}
                    {/* <Expand /> */}
                  </IconButton>
                </Tooltip>
              </div>
              <div class="flotActBtn closeBtnD10">
                <IconButton onClick={handleClose}>
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>
          <div className="flotContain">
            <div className="elementFormContainer previewSpace ">
              <div className="indentFormtable">
                <div className="indtableResponsive">
                  <table>
                    <thead>
                      {/* <tr>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Contact No</th>
                        <th>Email Id</th>
                        <th>Current Ctc</th>
                        <th>Expected Ctc</th>
                        <th>Experience In Month</th>
                        <th>View Resume</th>
                      </tr> */}
                      <tr>
                        <th>{t("name")}</th>
                        <th>{t("status")}</th>
                        <th>{t("contactNo")}</th>
                        <th>{t("emailId")}</th>
                        <th>{t("currentCtc")}</th>
                        <th>{t("expectedCtc")}</th>
                        <th>{t("experienceInMonth")}</th>
                        <th>{t("viewResume")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="textOnly">
                            {formData.firstName +
                              " " +
                              formData.middleName +
                              " " +
                              formData.lastName}
                          </div>
                        </td>
                        <td>
                          <div className="textOnly">{formData.status}</div>
                        </td>
                        <td>
                          <div className="textOnly">{formData.contactNo}</div>
                        </td>
                        <td>
                          <div className="textOnly">{formData.emailId}</div>
                        </td>
                        <td>
                          <div className="textOnly">{formData.currentCtc}</div>
                        </td>
                        <td>
                          <div className="textOnly">{formData.expectedCtc}</div>
                        </td>
                        <td>
                          <div className="textOnly">
                            {formData.experienceInMonth}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot></tfoot>
                  </table>
                </div>

                <div className="formElementGroup">
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        label={t("strengths")}
                        variant="outlined"
                        className="descptionTextFild"
                        multiline
                        rows={3}
                        {...register("strengths")}
                      />
                    </FormControl>
                  </div>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        label={t("weaknesses")}
                        variant="outlined"
                        className="descptionTextFild"
                        multiline
                        rows={3}
                        {...register("weaknesses")}
                      />
                    </FormControl>
                  </div>
                </div>

                <div className="formElementGroup">
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        label={t("feedback")}
                        required
                        variant="outlined"
                        className="descptionTextFild"
                        multiline
                        rows={3}
                        {...register("feedbackText")}
                      />
                    </FormControl>
                  </div>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        label={t("areasForImprovement")}
                        variant="outlined"
                        className="descptionTextFild"
                        multiline
                        rows={3}
                        {...register("areasForImprovement")}
                      />
                    </FormControl>
                  </div>
                </div>

                <div className="formElement width13">
                  <FormControl className="formControl">
                    <TextField
                      label={t("rating")}
                      variant="outlined"
                      className="descptionTextFild"
                      multiline
                      {...register("rating")}
                    />
                  </FormControl>
                </div>
                <div className={`formBtnElement`}>
                  <Button
                    variant="outlined"
                    className={"comBtnOutline"}
                    onClick={handleSubmit(submitFeedback)}
                  >
                    {t("submitFeedback")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
