import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import EmailUtil from "../../features/email/view/EmailUtil";
import axios from "axios";
import { socketEndPoint } from "../../constants/url_provider";
import { jwtDecode } from "jwt-decode";
import SecureIndexedDB from "../../utils/IndexedDB";

const initialState = {
  historyId: null,
  loading: false,
  newMessages: [],
  unreadMessages: [],
  error: "",
};
const loginUser = null;

const getUnreadMessages = createAsyncThunk(
  "dashboardMail/getUnreadMessages",
  async () => {
    console.log("reading unread messages");

    try {
      if (window.gapi) {
        const response = await window.gapi.client.gmail.users.messages.list({
          userId: "me",
          labelIds: ["CATEGORY_PERSONAL", "INBOX"],
          maxResults: 50,
          q: "is:unread",
        });

        if (response.result.messages && response.result.messages.length > 0) {
          const messageList = await Promise.all(
            response.result.messages.map(
              (message) =>
                new Promise(async (resolve, reject) => {
                  const response =
                    await window.gapi.client.gmail.users.messages.get({
                      userId: "me",
                      id: message.id,
                    });
                  resolve(EmailUtil.getEmailDataFromEmailBody(response));
                })
            )
          );
          // console.log("messageList = ", messageList);
          return messageList;
        } else {
          return [];
        }
      } else {
        toast.error("gapi not initiated. Try later.");
        return [];
      }
    } catch (error) {
      console.log("Error list =", error);
      if (error.status === 401) {
        //checkAuthentication();
      }
    }
  }
);
const checkAuthentication = async () => {
  try {
    const response = await axios.get(
      `${socketEndPoint}/api/mail/checkAuthentication/${loginUser.userId}`
    );
    if (response.data.returnStatus === 0) {
      console.log("return status = 0");
      removeAccessToken();
    } else if (response.data.url) {
      // window.open(response.data.url, "_blank");
      console.log("Response url");
      removeAccessToken();
    } else {
      handleAccessTokenData(response.data);
    }
  } catch (error) {
    console.log("error happened =", error);
  }
};

const removeAccessToken = async () => {
  SecureIndexedDB.removeItem("current_mailId");
  SecureIndexedDB.removeItem("email_user_name");
  SecureIndexedDB.removeItem("access_token");
  SecureIndexedDB.removeItem("expiry_date");
};

const handleAccessTokenData = async (data) => {
  const tempData = data;
  console.log("jwt token data ", tempData.id_token);
  const decodedData = jwtDecode(tempData.id_token);
  const { email, name } = decodedData;
  // setAccessToken(tempData.access_token);
  SecureIndexedDB.setItem("current_mailId", email);
  SecureIndexedDB.setItem("email_user_name", name);
  SecureIndexedDB.setItem("access_token", tempData.access_token);
  SecureIndexedDB.setItem("expiry_date", tempData.expiry_date);
};

const modifyDashboardMsgLabels = createAsyncThunk(
  "mail/modifyMsgLabels",
  async ({ messageId, labelsToAdd, labelsToRemove }) => {
    const response = await EmailUtil.modifyMessageCall(
      messageId,
      labelsToAdd,
      labelsToRemove
    );

    console.log("modified response = ", response);
    return { ...response, hideMessage: true };
  }
);

const handleHistory = createAsyncThunk(
  "mail/handleHistory",
  async (storedHistoryId, startHistoryId) => {
    console.log("history id = ", storedHistoryId);
    const response = await window.gapi.client.gmail.users.history.list({
      userId: "me",
      startHistoryId: storedHistoryId,
      historyTypes: ["messageAdded"],
    });

    const history = response.result.history[0];
    const historyId = response.result.historyId;
    console.log("history result = ", response.result);

    if (history.messagesAdded) {
      const messages = await Promise.all(
        history.messagesAdded.map(async (message) => {
          return new Promise(async (resolve, reject) => {
            const response = await window.gapi.client.gmail.users.messages.get({
              userId: "me",
              id: message.message.id,
            });
            resolve(EmailUtil.getEmailDataFromEmailBody(response));
          });
        })
      );
      console.log("history list = ", history);
      console.log("hsdd fojsodf osjd", messages);
      return messages;
    }
  }
);

// const getUnreadMessages=createAsyncThunk("dashboardMail/")

const dashboardMailSlice = createSlice({
  name: "dashboardMail",
  initialState,
  reducers: {
    clearUnreadMessages: (state) => {
      console.log("in clear unread messages");
      state.unreadMessages = [];
    },
    updateHistory: (state, action) => {
      state.historyId = action.payload;
    },
    removeReadMessage: (state) => {
      let tempMsg = [...state.unreadMessages];
      tempMsg = tempMsg.filter((msg) => {
        return msg.labelIds.includes("UNREAD");
      });
      state.unreadMessages = tempMsg;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUnreadMessages.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUnreadMessages.fulfilled, (state, action) => {
        // console.log("action value =", action.payload);
        state.unreadMessages = action.payload;
      })
      .addCase(getUnreadMessages.rejected, (state, action) => {
        console.log("action =", action);
      });

    builder
      .addCase(modifyDashboardMsgLabels.pending, (state, action) => {
        // state.unreadMessages
        console.log(`message value`, state);
      })
      .addCase(modifyDashboardMsgLabels.fulfilled, (state, action) => {
        console.log("modified value = ", action.payload);
        const temp = state.unreadMessages;
        const index = temp.findIndex(
          (obj) => obj.messageId === action.payload.result.id
        );
        if (index >= 0) {
          temp[index].labelIds = action.payload.result.labelIds;
          state.unreadMessages = temp;
        }
      });

    builder.addCase(handleHistory.fulfilled, (state, action) => {
      console.log("fulfilled", action.payload);
      const messages = action.payload;
      messages.forEach((element) => {
        const objectExists = state.unreadMessages.some(
          (obj) => obj.messageId === element.messageId
        );
        if (!objectExists) {
          toast.success("Received New Email");
          state.newMessages = [...state.newMessages, ...action.payload];
          state.unreadMessages = [...action.payload, ...state.unreadMessages];
        }
      });
    });
  },
});

export default dashboardMailSlice;
export { getUnreadMessages, modifyDashboardMsgLabels, handleHistory };
export const { clearUnreadMessages, updateHistory, removeReadMessage } =
  dashboardMailSlice.actions;
