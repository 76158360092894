import { Add, Edit } from "@mui/icons-material";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";

export const KraHtmlView = ({ kraData }) => {
  const { t } = useTranslation();
  const [previewData, setPreviewData] = useState([]);
  const [kraDataCopy, setKraDataCopy] = useState(null);
  const [showData, setShowData] = useState(true);
  const [previewDataList, setPreviewDataList] = useState(null);

  // useEffect(() => {
  //   if (kraDataCopy) {
  //     const processedData = groupByKpiAndTitle(kraDataCopy);
  //     setPreviewData(processedData);
  //   }
  // }, [kraDataCopy]);

  useEffect(() => {
    if (kraData) {
      // setKraDataCopy([...kraData]);
      const processedData = groupByKpiAndTitle(kraData);
    }
  }, [kraData]);

  // const groupByKpiAndTitle = (data) => {
  //   const dta = data.reduce((acc, item) => {
  //     const { target, title, kpi } = item;
  //     if (!acc[title]) {
  //       acc[title] = {};
  //       acc[title]["kpi"] = kpi;
  //     }
  //     if (!acc[title][target]) {
  //       acc[title][target] = [];
  //     }
  //     acc[title][target].push(item);
  //     return acc;
  //   }, {});
  //   // console.log("data = ", dta);
  //   return dta;
  // };

  const groupByKpiAndTitle = (data) => {
    const dta = data.reduce((acc, item) => {
      const { target, title, kpi } = item;
      if (!acc[title]) {
        acc[title] = {};
        // acc[title][kpi] = kpi;
      }
      if (!acc[title][kpi]) {
        acc[title][kpi] = [];
      }
      acc[title][kpi].push(item);
      return acc;
    }, {});
    console.log("data = ", dta);
    let tempList = [];
    for (let index = 0; index < Object.keys(dta).length; index++) {
      const element = Object.keys(dta)[index];
      const kpis = Object.keys(dta[element]);
      let values = [];
      kpis.forEach((kpi) => {
        let kpiData = dta[element][kpi];
        kpiData = kpiData.map((kpiDataItem) => ({
          ...kpiDataItem,
          targetId: uuidv4(),
        }));
        values.push({ kpiId: uuidv4(), kpi: kpi, data: kpiData });
      });
      tempList.push({ titleId: uuidv4(), title: element, values: values });
    }
    console.log("tempppp", tempList);
    setPreviewDataList(tempList);
    return dta;
  };

  return (
    <>
      {kraData && (
        <div className="JDKRAContainEdit">
          <ol className="">
            {previewDataList &&
              previewDataList.map((item, index) => {
                // let { title, values } = item;
                // console.log("Values are", item);
                // console.log("Values are 1", values);
                let title = item.title;
                let values = item.values;
                return (
                  <>
                    <li className="topList">
                      <div className="jdFromElement">
                        <div className="KratitleAdd">
                          <div className="formElement">
                            <p>{title}</p>
                          </div>
                        </div>
                        <ul>
                          {values.map((kpiObj) => {
                            const kpi = kpiObj.kpi;
                            const dataObjs = kpiObj.data;

                            return (
                              <>
                                <li>
                                  <div className="subElement">
                                    <div className="subElmntHead">
                                      {t("kpi")}
                                    </div>
                                    <div className="formElement">
                                      <p>{kpi}</p>
                                    </div>

                                    <div className="subElement">
                                      <div className="subElmntHead">
                                        {t("targets")}
                                      </div>
                                      <ul>
                                        {dataObjs &&
                                          dataObjs.map((dataItem) => {
                                            return (
                                              <li>
                                                <div className="subElmntList">
                                                  <div className="formElement">
                                                    <p>{dataItem.target}</p>
                                                  </div>
                                                </div>
                                              </li>
                                            );
                                          })}
                                      </ul>
                                    </div>
                                  </div>
                                </li>
                              </>
                            );
                          })}
                        </ul>
                      </div>
                    </li>
                  </>
                );
              })}
          </ol>
        </div>
      )}
      {!kraData && <h4>{t("add_kra_data")}</h4>}
    </>
  );
};
