import { AccessTime, Add, AttachFile } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { Eye, Trash, XLg } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
// import ReactDatePicker from "react-datepicker";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateUtils from "../../../../utils/DateUtils";
import {
  addShift,
  getShifts,
  deactivateShift,
  makeDefaultShift,
} from "../../../../services/AdminService";
import { useTranslation } from "react-i18next";
import { enIN, es } from "date-fns/locale";
import { GetLoginUserDetails } from "../../../../utils/Utils";

// Register locales with ReactDatePicker
registerLocale("en", enIN);
registerLocale("es", es);

export default function AddShift({ handleCloseAddWorkingShift }) {
  const userDetails = GetLoginUserDetails();
  const { t, i18n } = useTranslation();

  const getUpcomingHour = () => {
    const now = new Date();
    now.setMinutes(0, 0, 0);
    now.setHours(now.getHours() + 1);
    return now;
  };

  const [loading, setLoading] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [shiftName, setShiftName] = useState("");
  const [defaultShift, setDefaultShift] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedShiftId, setSelectedShiftId] = useState(null);
  const [checkboxState, setCheckboxState] = useState({});

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [shiftToDelete, setShiftToDelete] = useState(null);

  // Determine the locale based on the current language
  const currentLocale = i18n.language === "es" ? "es" : "en";

  const finalSubmit = () => {
    console.log("finalSubmit");
    console.log("startTime", startTime);
    console.log("endTime", endTime);
    setLoading(true);

    if (shiftName.trim() === "") {
      toast.error(t("please_enter_shift_name"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    const reqDto = {
      shiftId: 0,
      name: shiftName,
      startTime: DateUtils.getTimeInHHMMInDoubleDigit(startTime),
      endTime: DateUtils.getTimeInHHMMInDoubleDigit(endTime),
      defaultShift: defaultShift === true ? "Y" : "N",
    };

    console.log("reqDto", reqDto);

    addShift(userDetails.orgId, userDetails.userId, reqDto).then((response) => {
      console.log("response", response.data);
      console.log("response", typeof response.data);
      if (response.data === 1) {
        toast.success(t("shift_timing_added_successFully"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        fetchDataOfShift();
        setLoading(false);
        // setStartTime(getUpcomingHour());
        // setEndTime(new Date(getUpcomingHour().getTime() + 60 * 60 * 1000));
        setStartTime(null);
        setEndTime(null);
        setShiftName("");
        setDefaultShift(false);
      } else {
        toast.error(t("something_went_wrong"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    fetchDataOfShift();
  }, []);

  const [dataOfShift, setDataOfShift] = useState([]);

  const fetchDataOfShift = () => {
    getShifts(userDetails.orgId, userDetails.userId).then((response) => {
      console.log("response", response.data);
      setDataOfShift(response.data);
    });
  };

  console.log("dataOfShift", dataOfShift);

  const handleDeleteShiftOpenModal = (shiftId) => {
    setShiftToDelete(shiftId);
    setOpenDeleteModal(true);
  };

  const handleDeleteShift = () => {
    setOpenDeleteModal(false);
  };

  const handleDelete = () => {
    if (shiftToDelete) {
      deactivateShift(shiftToDelete, userDetails.userId).then((response) => {
        console.log("response", response.data);
        if (response.data === 1) {
          toast.success(t("shift_timing_deleted_successFully"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          setOpenDeleteModal(false);
          fetchDataOfShift();
        } else {
          toast.error(t("something_went_wrong"), {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };

  const handleDefaultShiftChange = (shiftId) => {
    setSelectedShiftId(shiftId);
    setModalOpen(true);
    setCheckboxState((prevState) => ({ ...prevState, [shiftId]: true }));
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedShiftId(null);
    setCheckboxState((prevState) => ({
      ...prevState,
      [selectedShiftId]: false,
    }));
  };

  const handleConfirmDefaultShift = () => {
    makeDefaultShift(
      selectedShiftId,
      userDetails.userId,
      userDetails.orgId
    ).then((response) => {
      console.log("response", response.data);
      if (response.data === 1) {
        toast.success(t("shift_marked_as_default_successfully"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        fetchDataOfShift();
      } else {
        toast.error(t("something_went_wrong"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      handleModalClose();
    });
  };

  return (
    <>
      <div className="rightFlotingPanel">
        <div className="rightFlotingContainer">
          <div className="rfContHeader">
            <div className="rfcHeadText">{t("shift")}</div>
            <div className="rfcHActionBtnGrp">
              <div className="actionBtn closeBtnD10">
                <IconButton onClick={() => handleCloseAddWorkingShift()}>
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>

          <div className="rfContContain">
            <div className="rfContContainDetails">
              <div className="elementFormContainer">
                <div className="fromcardHighlight ">
                  <div className="formElementGroup ">
                    <div className="formElement ">
                      <FormControl className="formControl">
                        <TextField
                          label={t("shift_name")}
                          required
                          variant="outlined"
                          className="formTextFieldArea"
                          value={shiftName}
                          onChange={(e) => setShiftName(e.target.value)}
                        />
                      </FormControl>
                    </div>
                    <div className="formElement ">
                      <FormControl className="formControl">
                        <FormGroup className="formRadioGroup">
                          <FormControlLabel
                            className="formRadioField"
                            control={
                              <Checkbox
                                checked={defaultShift}
                                onChange={(e) =>
                                  setDefaultShift(e.target.checked)
                                }
                              />
                            }
                            label={t("set_as_default_shift")}
                          />
                        </FormGroup>
                      </FormControl>
                    </div>
                  </div>

                  <div className="formElementGroup ">
                    <div className="formElement width13 ">
                      <FormControl className="formControl">
                        <InputLabel
                          id={`workStartTime`}
                          className="setCompletionDate"
                        >
                          {t("start_time")}
                        </InputLabel>
                        <ReactDatePicker
                          locale={currentLocale}
                          labelId={`workStartTime`}
                          className="formDatePicker onlyTime"
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={30}
                          timeCaption="Time"
                          dateFormat="HH:mm"
                          timeFormat="HH:mm"
                          selected={startTime}
                          onChange={(date) => setStartTime(date)}
                        />
                      </FormControl>
                    </div>

                    <div className="formElement width13 ">
                      <FormControl className="formControl">
                        <InputLabel
                          id={`workEndTime`}
                          className="setCompletionDate"
                        >
                          {t("end_time")}
                        </InputLabel>
                        <ReactDatePicker
                          locale={currentLocale}
                          labelId={`workEndTime`}
                          className="formDatePicker onlyTime"
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={30}
                          timeCaption="Time"
                          dateFormat="HH:mm"
                          timeFormat="HH:mm"
                          selected={endTime}
                          onChange={(date) => setEndTime(date)}
                        />
                      </FormControl>
                    </div>
                    <Button
                      className="dfultPrimaryBtn"
                      startIcon={<Add />}
                      onClick={() => finalSubmit()}
                    >
                      {loading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        <>{t("add_shift")}</>
                      )}
                    </Button>
                  </div>
                </div>
                {/* <div style={{ color: "white" }}>Shift List</div>
                {dataOfShift.map((shift, index) => (
                  <>
                    <div
                      key={shift.shiftId}
                      style={{ display: "flex", justifyContent: "flex-start" }}
                    >
                      <div style={{ color: "white" }}>{index + 1}. </div>
                      <div style={{ color: "white" }}>{shift.name}</div>
                      <div style={{ color: "white" }}>
                        {shift.defaultShift === "Y" && (
                          <>
                            <span style={{ marginLeft: "5px" }}>
                              ( Default Shift )
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        color: "white",
                      }}
                    >
                       {shift.defaultShift !== "Y" && (
                      <IconButton
                        onClick={() => handleDelete(shift.shiftId)}
                        style={{ color: "white" }}
                      >
                        <Trash />
                      </IconButton>
                       )}
                    </div>
                    <div
                      style={{ display: "flex", justifyContent: "flex-start" }}
                    >
                      <div style={{ color: "white" }}>
                        <AccessTime />
                      </div>
                      <div style={{ color: "white" }}>
                        {shift.startTime} - {shift.endTime}
                      </div>
                      <div style={{ color: "white" }}>
                        {shift.defaultShift !== "Y" && (
                          <>
                            <Checkbox 
                             style={{ color: "white" }} 
                             checked={checkboxState[shift.shiftId] || false}
                             onChange={() => handleDefaultShiftChange(shift.shiftId)}/>
                            <span style={{ marginLeft: "5px" }}>
                              Marked as Default Shift
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                ))} */}

                <div className="shiftElemHeding ">{t("shift_list")}</div>
                {dataOfShift.map((shift, index) => (
                  <div key={shift.shiftId} className="shiftCardGrp">
                    <div className="shiftCheader">
                      <div className="shiftTitleWIndex">
                        <div className="shiftIndexNo">{index + 1}.</div>
                        <div className="shiftName">{shift.name}</div>
                      </div>

                      <div className="shiftInfoDtlGrp">
                        <div className="shiftTimerGrp">
                          <AccessTime />
                          <div className="shiftTimer">
                            {shift.startTime} - {shift.endTime}
                          </div>
                        </div>
                        {shift.defaultShift === "Y" && (
                          <span className="defultShiftPlace">
                            ( {t("default_shift")} )
                          </span>
                        )}
                        {shift.defaultShift !== "Y" && (
                          <>
                            <div className="formElement">
                              <FormControl className="formControl">
                                <FormGroup className="formRadioGroup">
                                  <FormControlLabel
                                    className="formRadioField"
                                    control={
                                      <Checkbox
                                        checked={
                                          checkboxState[shift.shiftId] || false
                                        }
                                        onChange={() =>
                                          handleDefaultShiftChange(
                                            shift.shiftId
                                          )
                                        }
                                      />
                                    }
                                    label={t("marked_as_default_shift")}
                                  />
                                </FormGroup>
                              </FormControl>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    {shift.defaultShift !== "Y" && (
                      <IconButton
                        onClick={() =>
                          handleDeleteShiftOpenModal(shift.shiftId)
                        }
                        className="removeShiftBtn"
                      >
                        <Trash />
                      </IconButton>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* <div className="rfContFooter">
            <div className="formBtnElement">
              <Button
                className="dfultPrimaryBtn"
                onClick={() => finalSubmit()}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <>Submit</>
                )}
              </Button>
              {!loading && (
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => handleCloseAddWorkingShift()}
                >
                  Cancel
                </Button>
              )}
            </div>
          </div> */}
          {modalOpen && (
            <Modal
              open={modalOpen}
              onClose={handleModalClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  // width: "250px",
                  // height: "250px",
                  maxWidth: "600px",
                  minHeight: "150px",
                  backgroundColor: "white",
                  padding: "20px",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <h2 id="modal-modal-title">
                  {t("do_you_want_to_mark_this_shift_as_the_default_shift")} ?
                </h2>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    className="dfultPrimaryBtn"
                    onClick={handleConfirmDefaultShift}
                  >
                    {t("yes")}
                  </Button>
                  <Button
                    className="dfultDarkSecondaryBtn"
                    onClick={handleModalClose}
                  >
                    {t("no")}
                  </Button>
                </div>
              </div>
            </Modal>
          )}

          {openDeleteModal && (
            <Modal
              open={openDeleteModal}
              onClose={handleDeleteShift}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  // width: "250px",
                  // height: "250px",
                  maxWidth: "600px",
                  minHeight: "150px",
                  backgroundColor: "white",
                  padding: "20px",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <h2 id="modal-modal-title">
                  {t("do_you_want_to_delete_this_shift")}
                </h2>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    // variant="contained"
                    // color="secondary"
                    className="dfultPrimaryBtn"
                    onClick={handleDelete}
                  >
                    {t("yes")}
                  </Button>
                  <Button
                    // variant="contained"
                    className="dfultDarkSecondaryBtn"
                    onClick={handleDeleteShift}
                  >
                    {t("no")}
                  </Button>
                </div>
              </div>
            </Modal>
          )}
        </div>
      </div>
    </>
  );
}
