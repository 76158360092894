import React from "react";
import { useTranslation } from "react-i18next";

export default function NoDataFoundCard() {
  const { t } = useTranslation();
  return (
    <div className="noDataCard">
      <span>{t("oops")}</span>
      {t("no_data_found")}
    </div>
  );
}
