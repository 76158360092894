import { Cancel, Edit, Save } from "@mui/icons-material";
import { FormControl, IconButton, TextField, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TaskService from "../../../../../services/TaskService";
import { toast } from "react-toastify";

export const DescriptionComponent = ({
  data,
  refreshData,
  removeFromStore,
}) => {
  const { t } = useTranslation();
  const [showEditDescription, setShowEditDescription] = useState(false);
  const [taskDescription, setTaskDescription] = useState("");

  useEffect(() => {
    setTaskDescription(data.taskDescription);
  }, [data]);
  return (
    <>
      {data && data.taskDescription && (
        <div className="taskElementGrp">
          <div className="taskContainEditBtnGrp">
            <div className="tskElemHeding">{t("description")}</div>
            {data.incoming === "N" && (
              <Tooltip arrow title={t("edit_description")}>
                <IconButton
                  className="editTimelineBtn"
                  onClick={() => {
                    setShowEditDescription((prev) => !prev);
                  }}
                >
                  {!showEditDescription ? <Edit /> : <Cancel />}
                </IconButton>
              </Tooltip>
            )}
            {data.incoming === "N" && showEditDescription && (
              <Tooltip arrow title={t("save_description")}>
                <IconButton
                  className="editTimelineBtn"
                  onClick={() => {
                    console.log("element saving");
                    TaskService.updateTaskDescription(
                      data.taskId,
                      taskDescription
                    ).then((response) => {
                      if (response.data != 0) {
                        toast.success(t("description_updated"));
                        refreshData();
                        removeFromStore();
                      } else {
                        toast.error(t("description_could_not_be_updated"));
                      }
                    });
                  }}
                >
                  <Save />
                </IconButton>
              </Tooltip>
            )}
          </div>
          {!showEditDescription && (
            <div className="tskElemntInfo">{data.taskDescription}</div>
          )}
          {showEditDescription && (
            <div className="formElement">
              <FormControl className="formControl pRelative">
                <TextField
                  label={t("description")}
                  required
                  variant="outlined"
                  className="descptionTextFild"
                  multiline
                  rows={4}
                  maxRows={7}
                  value={taskDescription}
                  onChange={(e) => setTaskDescription(e.target.value)}
                />
              </FormControl>
            </div>
          )}
        </div>
      )}
    </>
  );
};
