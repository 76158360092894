import { Add, DeviceThermostatSharp, Edit } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { NumericFormatCustom } from "./NumericFormatCustom";
import { Trash } from "react-bootstrap-icons";
import TaskService from "../../../../../services/TaskService";
import { GetLoginUserDetails } from "../../../../../utils/Utils";
import { toast } from "react-toastify";
import { t } from "i18next";

export const GoodsServicesIncomingForm = ({
  state,
  dispatch,
  tableDataList,
  budgetHeadList,
  mode,
}) => {
  const [allTimeFormData, setAllTimeFormData] = useState({
    id: new Date().valueOf(),
    itemName: "",
    itemDescription: "",
    itemId: "",
    uom: "",
    stockQty: 0,
    indentQty: 0,
    budgetHead: "",
    prePurshaseRate: 0,
    estimatedRate: 0,
    estimatedValue: 0,
    editMode: true,
  });
  const [itemUOMList, setItemUOMList] = useState([]);
  const loginUserDetail = GetLoginUserDetails();
  const [refreshCount, setRefreshCount] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [orgItemList, setOrgItemList] = useState([]);
  const [shouldUpdate, setShouldUpdate] = useState(1);

  const initializeAllTimeForeData = () => {
    setAllTimeFormData({
      id: new Date().valueOf(),
      itemName: "",
      itemDescription: "",
      itemId: "",
      uom: "",
      stockQty: 0,
      indentQty: 0,
      purpose: "",
      prePurshaseRate: 0,
      estimatedRate: 0,
      estimatedValue: 0,
      editMode: true,
    });
  };

  const getSelectedBudgetHeadObj = (budgetHeadId) => {
    const budgetHeadObj = budgetHeadList.find(
      (item) => item.id === budgetHeadId
    );
    return budgetHeadObj;
  };
  const calculateRowTotal = (rowId, qty, rate) => {
    const value = qty * rate;
    let itemIndex = tableDataList.findIndex((obj) => obj.id === rowId);
    tableDataList[itemIndex].estimatedValue = value;
    return value ? value : 0;
  };
  const calculateAllTimeTotalRow = (rowId, qty, rate) => {
    const value = qty * rate;
    allTimeFormData.estimatedValue = value;
    return value ? value : 0;
  };

  const calculateTotalValue = () => {
    let totalVal = 0;
    for (let i = 0; i < tableDataList.length; i++) {
      const { indentQty, estimatedRate } = tableDataList[i];
      totalVal += parseInt(indentQty) * parseInt(estimatedRate);
    }
    return totalVal ? totalVal : 0;
  };

  useEffect(() => {
    if (
      state.formDataCopy &&
      (state.formDataCopy.expenseType === "goods" ||
        state.formDataCopy.expenseType === "services")
    ) {
      TaskService.getItemsByType(
        loginUserDetail.orgId,
        state.formDataCopy.expenseType
      ).then((response) => {
        let tmpList = [];
        if (response.data && response.data.length > 0) {
          response.data.forEach((item) => {
            tmpList.push({
              ...item,
              ...{ label: item.itemName },
            });
          });
        }
        setOrgItemList(tmpList);
      });
    }
  }, [state.formDataCopy.expenseType, shouldUpdate]);

  useEffect(() => {
    // TaskService.getBudgetHeads(loginUserDetail.orgId).then((response) => {
    //   if (response.data) {
    //     console.log("fetchLedgerHeadData", response.data);
    //     setLedgerHeadList(response.data);
    //   }
    // });

    TaskService.getItemUOM(loginUserDetail.orgId).then((response) => {
      setItemUOMList(response.data);
    });
    setRefreshCount((prev) => prev + 1);
  }, [shouldUpdate]);

  const handleSaveNewItem = (item) => {
    if (item.itemName == null || item.itemName === "") {
      toast.error(t("please_select_item_or_add_new_item"), {
        position: toast.POSITION.TOP_RIGHT,
      });

      return false;
    } else if (item.itemDescription == null || item.itemDescription === "") {
      toast.error(t("Please_enter_description"), {
        position: toast.POSITION.TOP_RIGHT,
      });

      return false;
    } else if (item.purpose === "") {
      toast.error(t("please_select_budget_head"), {
        position: toast.POSITION.TOP_RIGHT,
      });

      return false;
    } else if (item.indentQty <= 0) {
      toast.error(t("please_enter_indent_quantity"), {
        position: toast.POSITION.TOP_RIGHT,
      });

      return false;
    } else if (item.estimatedRate <= 0) {
      toast.error(t("estimated_rate_cannot_be_less_then_or_equal_0"), {
        position: toast.POSITION.TOP_RIGHT,
      });

      return false;
    } else if (item.estimatedValue <= 0) {
      toast.error(t("estimated_value_cannot_be_less_then_or_equal_0"), {
        position: toast.POSITION.TOP_RIGHT,
      });

      return false;
    } else {
      // tableDataList[index].editMode = false;
      const temp = {
        itemId: item.itemId,
        uom: item.uom,
        indentQty: item.indentQty,
        currentStk: item.stockQty,
        prePurshaseRate: item.prePurshaseRate,
        estimatedRate: item.estimatedRate,
        estimatedValue: item.estimatedValue,
        itemDescription: item.itemDescription,
        budgetHeadId: item.budgetHead.id,
        itemName: item.itemName,
        budgetHeadName: item.budgetHead.head,
        expenseDtlId: 0,
        editMode: false,
      };
      dispatch({ type: "addItemToGoodsService", payload: temp });
      //   setTableDataList((prev) => [...prev, item]);
      return true;
    }
  };

  return (
    <>
      <div className="indentFormtable">
        <div className="indtableResponsive">
          <table>
            <thead>
              <tr>
                <th className="w200">
                  {state.formDataCopy &&
                  state.formDataCopy.expenseType === "services"
                    ? t("service_name")
                    : t("item_name")}
                </th>
                <th>
                  <Tooltip arrow title="Description / Specification mandatory">
                    {t("desc_spec")}
                  </Tooltip>
                </th>
                <th>{t("budget_head")} *</th>
                <th>{t("uom")} *</th>
                {state.formDataCopy &&
                  state.formDataCopy.expenseType === "goods" && (
                    <th className="StockQty">{t("stock_qty")}</th>
                  )}

                <th className="indntQunt">{t("indent_qty")}. *</th>
                <th className="widthMin120">{t("estimated_rate")} *</th>
                <th className="widthMin120">{t("estimated_value")}</th>
                <th className="mw80"></th>
              </tr>
            </thead>
            <tbody>
              {tableDataList.length > 0 &&
                tableDataList.map((item, index) => {
                  if (item.editMode && (!mode || mode !== "viewOnly")) {
                    return (
                      <tr>
                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <Autocomplete
                                className="formAutocompleteField"
                                variant="outlined"
                                disabled={!item.editMode}
                                disableClearable
                                options={orgItemList}
                                defaultValue={item.itemName}
                                onChange={(event, newValue) => {
                                  const temp = [...tableDataList];
                                  temp[index].uom = newValue.defaultUom;
                                  temp[index].itemName = newValue.itemName;
                                  temp[index].currentStk = newValue.currentStk;
                                  temp[index].prePurshaseRate =
                                    newValue.lastPurchaseRate;
                                  temp[index].itemDescription =
                                    newValue.itemDescription;
                                  // temp[index].
                                  console.log("newvalue=", temp);
                                  dispatch({
                                    type: "updateServiceGoods",
                                    payload: temp,
                                  });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    className="formAutoComInputField autocompFildPlaceholder"
                                    {...params}
                                    label={t("type_to_search")}
                                  />
                                )}
                                size="small"
                              />
                            </FormControl>
                            {/* <FormControl className="formControl">
                            <InputLabel id="selectItems">Items</InputLabel>
                            <Select
                              className="formInputField"
                              variant="outlined"
                              labelId="selectItems"
                              id="select-Items"
                              value={showItems}
                              label="Items"
                              onChange={handleselectItems}
                            >
                              <MenuItem value={"laptop"}>Laptop</MenuItem>
                              <MenuItem value={"keyboard"}>
                                {" "}
                                Keyboard{" "}
                              </MenuItem>
                              <MenuItem value={"mouse"}> Mouse </MenuItem>
                              <MenuItem value={"monitor"}> Monitor </MenuItem>
                              <MenuItem value={"printer"}> Printer </MenuItem>
                            </Select>
                          </FormControl> */}
                          </div>
                        </td>
                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                label={t("description_specification_mandatory")}
                                variant="outlined"
                                className="formTextFieldArea"
                                disabled={!item.editMode}
                                required
                                fullWidth
                                size="small"
                                value={item.itemDescription}
                                onChange={(e) => {
                                  const temp = [...tableDataList];
                                  temp[index].itemDescription = e.target.value;
                                  dispatch({
                                    type: "updateServiceGoods",
                                    payload: temp,
                                  });
                                }}
                              />
                            </FormControl>
                          </div>
                        </td>
                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <InputLabel id="selectLedgerHead">
                                {t("budget_head")}
                              </InputLabel>
                              <Select
                                className="formInputField"
                                variant="outlined"
                                labelId="selectLedgerHead"
                                id="select-LedgerHead"
                                label={t("budget_head")}
                                disabled={!item.editMode}
                                value={getSelectedBudgetHeadObj(
                                  item.budgetHeadId
                                )}
                                onChange={(e) => {
                                  let temp = [...tableDataList];
                                  const value = e.target.value;
                                  temp[index].budgetHeadId = value.id;
                                  temp[index].budgetHeadName = value.head;
                                  dispatch({
                                    type: "updateServiceGoods",
                                    payload: temp,
                                  });
                                }}
                              >
                                {budgetHeadList.length > 0 &&
                                  budgetHeadList.map((itemName, index) => (
                                    <MenuItem
                                      value={itemName}
                                      key={itemName.head + "_" + index}
                                    >
                                      {itemName.head}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </div>
                        </td>
                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <InputLabel id="selectUOM">{t("uom")}</InputLabel>
                              <Select
                                className="formInputField"
                                variant="outlined"
                                labelId="selectUOM"
                                id="select-UOM"
                                label={t("uom")}
                                disabled={!item.editMode}
                                value={item.uom}
                                onChange={(e) => {
                                  const temp = [...tableDataList];
                                  temp[index].uom = e.target.value;
                                  dispatch({
                                    type: "updateServiceGoods",
                                    payload: temp,
                                  });
                                }}
                              >
                                {itemUOMList.length > 0 &&
                                  itemUOMList.map((item) => (
                                    <MenuItem value={item} key={item}>
                                      {item}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </div>
                        </td>
                        <td>
                          <div className="textOnly">
                            {refreshCount >= 0 && item.currentStk}
                          </div>
                        </td>

                        {state.formDataCopy &&
                          state.formDataCopy.expenseType === "goods" && (
                            <td>
                              <div className="formElement">
                                <FormControl className="formControl">
                                  <TextField
                                    disabled={!item.editMode}
                                    label={t("indent_qty")}
                                    // value={valuesInd.indentQty}
                                    name="numberformat"
                                    id="IndentQty"
                                    InputProps={{
                                      inputComponent: NumericFormatCustom,
                                    }}
                                    defaultValue={item.indentQty}
                                    onChange={(e) => {
                                      const temp = parseInt(e.target.value);
                                      if (isNaN(temp)) {
                                        item.indentQty = "";
                                      } else {
                                        item.indentQty = temp;
                                      }
                                      setRefreshCount((r) => r + 1);
                                    }}
                                    variant="outlined"
                                    className="formTextFieldArea"
                                  />
                                </FormControl>
                              </div>
                            </td>
                          )}
                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                disabled={!item.editMode}
                                label={t("estimated_rate")}
                                required
                                name="numberformat"
                                id="EstimatedRate"
                                InputProps={{
                                  inputComponent: NumericFormatCustom,
                                }}
                                variant="outlined"
                                className="formTextFieldArea"
                                value={item.estimatedRate}
                                onChange={(e) => {
                                  const temp = [...tableDataList];
                                  temp[index].estimatedRate = e.target.value;
                                  dispatch({
                                    type: "updateServiceGoods",
                                    payload: temp,
                                  });
                                  setRefreshCount((r) => r + 1);
                                }}
                              />
                            </FormControl>
                          </div>
                        </td>
                        <td>
                          <div className="textOnly">
                            {refreshCount >= 0 &&
                              calculateRowTotal(
                                item.id,
                                item.indentQty,
                                item.estimatedRate
                              )}
                          </div>
                        </td>
                        <td>
                          <div className="iconBtnGroup">
                            {item.editMode === true && (
                              <Button
                                className="saveBtn"
                                onClick={(e) => {
                                  dispatch({
                                    type: "editGoodsServicesIndex",
                                    payload: index,
                                  });
                                }}
                              >
                                {t("save")}
                              </Button>
                            )}
                            {item.editMode === false && (
                              <Tooltip title={t("edit_item")} placement="left">
                                <IconButton
                                  className={
                                    item.editMode === false &&
                                    isEditing &&
                                    `deactivatedEditButton`
                                  }
                                  disabled={
                                    item.editMode === false && isEditing
                                  }
                                  onClick={() => {
                                    dispatch({
                                      type: "editGoodsServicesIndex",
                                      payload: index,
                                    });
                                  }}
                                >
                                  <Edit />
                                </IconButton>
                              </Tooltip>
                            )}
                            <IconButton
                              onClick={() => {
                                if (item.editMode === true) {
                                  setIsEditing(false);
                                }
                                dispatch({
                                  type: "removeRowByIndex",
                                  payload: index,
                                });
                              }}
                            >
                              <Trash />
                            </IconButton>
                          </div>
                        </td>
                      </tr>
                    );
                  } else {
                    return (
                      <tr>
                        <td>{item.itemName}</td>
                        <td>
                          <div className="textOnly">{item.itemDescription}</div>
                        </td>
                        <td>
                          <div className="textOnly">{item.budgetHeadName}</div>
                        </td>
                        <td>
                          <div className="textOnly">{item.uom}</div>
                        </td>
                        {state.formDataCopy &&
                          state.formDataCopy.expenseType === "goods" && (
                            <td>
                              <div className="textOnly">{item.currentStk}</div>
                            </td>
                          )}

                        {/* <td>
                      <div className="textOnly">{item.prePurshaseRate}</div>
                    </td> */}
                        <td>
                          <div className="textOnly">{item.indentQty}</div>
                        </td>
                        <td>
                          <div className="textOnly">{item.estimatedRate}</div>
                        </td>
                        <td>
                          <div className="textOnly">{item.estimatedValue}</div>
                        </td>
                        <td>
                          <div className="iconBtnGroup">
                            {item.editMode === false && (
                              <Tooltip title={t("edit_item")} placement="left">
                                <IconButton
                                  className={
                                    item.editMode === false &&
                                    isEditing &&
                                    `deactivatedEditButton`
                                  }
                                  disabled={
                                    item.editMode === false && isEditing
                                  }
                                  onClick={() => {
                                    console.log("Edit item =");

                                    dispatch({
                                      type: "editGoodsServicesIndex",
                                      payload: index,
                                    });
                                  }}
                                >
                                  <Edit />
                                </IconButton>
                              </Tooltip>
                            )}
                            <IconButton
                              onClick={() => {
                                if (item.editMode === true) {
                                  setIsEditing(false);
                                }
                                dispatch({
                                  type: "removeRowByIndex",
                                  payload: index,
                                });
                              }}
                            >
                              <Trash />
                            </IconButton>
                          </div>
                        </td>
                      </tr>
                    );
                  }
                })}

              {(!mode || mode !== "viewOnly") && (
                <tr>
                  <td>
                    <div className="formElement">
                      <FormControl className="formControl">
                        <Autocomplete
                          className="formAutocompleteField"
                          variant="outlined"
                          disabled={!allTimeFormData.editMode}
                          disablePortal
                          options={orgItemList}
                          defaultValue={allTimeFormData.itemName}
                          value={allTimeFormData.itemName}
                          onChange={(event, newValue) => {
                            // handelChangeItem(allTimeFormData, newValue)
                            let temp = { ...allTimeFormData };
                            if (newValue === null) {
                              temp.itemName = "";
                              temp.itemDescription = "";
                              temp.uom = "";
                              temp.itemId = 0;
                              setAllTimeFormData(temp);

                              return;
                            }
                            temp.itemName = newValue.itemName;
                            temp.itemDescription = newValue.itemDescription;
                            temp.uom = newValue.defaultUom;
                            temp.currentStk = newValue.currentStk;
                            temp.itemId = newValue.itemId;
                            setAllTimeFormData(temp);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t("type_to_search")}
                              className="formAutoComInputField autocompFildPlaceholder"
                            />
                          )}
                          size="small"
                        />
                      </FormControl>
                      {/* <FormControl className="formControl">
                            <InputLabel id="selectItems">Items</InputLabel>
                            <Select
                              className="formInputField"
                              variant="outlined"
                              labelId="selectItems"
                              id="select-Items"
                              value={showItems}
                              label="Items"
                              onChange={handleselectItems}
                            >
                              <MenuItem value={"laptop"}>Laptop</MenuItem>
                              <MenuItem value={"keyboard"}>
                                {" "}
                                Keyboard{" "}
                              </MenuItem>
                              <MenuItem value={"mouse"}> Mouse </MenuItem>
                              <MenuItem value={"monitor"}> Monitor </MenuItem>
                              <MenuItem value={"printer"}> Printer </MenuItem>
                            </Select>
                          </FormControl> */}
                    </div>
                  </td>
                  <td>
                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          label={t("description_specification_mandatory")}
                          variant="outlined"
                          className="formTextFieldArea"
                          disabled={!allTimeFormData.editMode}
                          required
                          fullWidth
                          size="small"
                          value={allTimeFormData.itemDescription}
                          onChange={(e) => {
                            allTimeFormData.itemDescription = e.target.value;
                            setRefreshCount((r) => r + 1);
                          }}
                        />
                      </FormControl>
                    </div>
                  </td>
                  <td>
                    <div className="formElement">
                      <FormControl className="formControl">
                        <InputLabel id="selectLedgerHead">
                          {t("budget_head")}
                        </InputLabel>
                        <Select
                          className="formInputField"
                          variant="outlined"
                          labelId="selectLedgerHead"
                          id="select-LedgerHead"
                          label={t("budget_head")}
                          disabled={!allTimeFormData.editMode}
                          value={allTimeFormData.budgetHead}
                          onChange={(e) => {
                            let temp = { ...allTimeFormData };
                            temp.budgetHead = e.target.value;
                            setAllTimeFormData(temp);
                          }}
                        >
                          {budgetHeadList.length > 0 &&
                            budgetHeadList.map((itemName, index) => (
                              <MenuItem
                                value={itemName}
                                key={itemName.head + "_" + index}
                              >
                                {itemName.head}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                  </td>
                  <td>
                    <div className="formElement">
                      <FormControl className="formControl">
                        <InputLabel id="selectUOM">{t("uom")}</InputLabel>
                        <Select
                          className="formInputField"
                          variant="outlined"
                          labelId="selectUOM"
                          id="select-UOM"
                          label={t("uom")}
                          disabled={!allTimeFormData.editMode}
                          value={allTimeFormData.uom}
                          onChange={(e) => {
                            allTimeFormData.uom = e.target.value;
                            setRefreshCount((r) => r + 1);
                          }}
                        >
                          {itemUOMList.length > 0 &&
                            itemUOMList.map((allTimeFormData) => (
                              <MenuItem
                                value={allTimeFormData}
                                key={allTimeFormData}
                              >
                                {allTimeFormData}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                  </td>
                  {state.formDataCopy &&
                    state.formDataCopy.expenseType === "goods" && (
                      <td>
                        <div className="textOnly">
                          {refreshCount >= 0 && allTimeFormData.currentStk
                            ? allTimeFormData.currentStk
                            : 0}
                        </div>
                      </td>
                    )}
                  {/* <td>
              <div className="textOnly">
                {refreshCount >= 0 && allTimeFormData.prePurshaseRate}
              </div>
            </td> */}
                  {
                    <td>
                      <div className="formElement">
                        <FormControl className="formControl">
                          <TextField
                            // disabled={!allTimeFormData.editMode}
                            label={t("indent_qty")}
                            value={allTimeFormData.indentQty}
                            name="numberformat"
                            id="IndentQty"
                            InputProps={{
                              inputComponent: NumericFormatCustom,
                            }}
                            // defaultValue={allTimeFormData.indentQty}
                            onChange={(e) => {
                              const temp = parseInt(e.target.value);
                              if (isNaN(temp)) {
                                allTimeFormData.indentQty = "";
                              } else {
                                allTimeFormData.indentQty = temp;
                              }
                              setRefreshCount((r) => r + 1);
                            }}
                            variant="outlined"
                            className="formTextFieldArea"
                          />
                        </FormControl>
                      </div>
                    </td>
                  }
                  <td>
                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          disabled={!allTimeFormData.editMode}
                          label="Estimated Rate*"
                          name="numberformat"
                          id="EstimatedRate"
                          InputProps={{
                            inputComponent: NumericFormatCustom,
                          }}
                          variant="outlined"
                          className="formTextFieldArea"
                          value={allTimeFormData.estimatedRate}
                          onChange={(e) => {
                            allTimeFormData.estimatedRate = e.target.value;
                            setRefreshCount((r) => r + 1);
                          }}
                        />
                      </FormControl>
                    </div>
                  </td>
                  <td>
                    <div className="textOnly">
                      {refreshCount >= 0 &&
                        allTimeFormData &&
                        calculateAllTimeTotalRow(
                          allTimeFormData.id,
                          allTimeFormData.indentQty,
                          allTimeFormData.estimatedRate
                        )}
                    </div>
                  </td>
                  <td>
                    <div className="iconBtnGroup">
                      {allTimeFormData.editMode === true && (
                        <Button
                          className="saveBtn"
                          onClick={(e) => {
                            console.log("all time form data=", allTimeFormData);
                            const temp = {
                              ...allTimeFormData,
                              editMode: false,
                            };
                            // setTableDataList((prev) => [...prev, temp]);
                            const bool = handleSaveNewItem(temp);
                            bool && initializeAllTimeForeData();
                          }}
                        >
                          {t("save")}
                        </Button>
                      )}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
            <tfoot>
              <tr>
                <td
                  colSpan={
                    state.formDataCopy &&
                    state.formDataCopy.expenseType === "goods"
                      ? "7"
                      : "6"
                  }
                >
                  <div className="textOnly tvText">{t("total_value")}</div>
                </td>
                <td>
                  <div className="textOnly fw600">
                    {refreshCount && calculateTotalValue()}
                  </div>
                </td>
                <td colSpan="1"></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </>
    // <></>
  );
};
