import { Add } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import React, { useState } from "react";
import { XLg } from "react-bootstrap-icons";
import {
  createNewDepartment,
  updateDepartment,
} from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import { GetLoginUserDetails } from "../../../../utils/Utils";

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export default function CreateDepartment({
  handelCloseCrtDeprtView,
  refresDepartmenthData,
  selectDepartment,
}) {
  const { t } = useTranslation();

  console.log("selectDepartment", selectDepartment);

  const userDetails = GetLoginUserDetails();

  const [loading, setLoading] = useState(false);

  const [personName, setPersonName] = React.useState([]);

  const handleChangeMultiselect = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////

  const [departmentName, setDepartmentName] = useState("");
  const [updatedDepartmentName, setUpdatedDepartmentName] = useState("");

  const handleDepartmentName = (e) => {
    const value = e.target.value;
    console.log("handleDepartmentName", value);
    setDepartmentName(value);
    if (selectDepartment) {
      selectDepartment.functionName = value;
      setUpdatedDepartmentName(value);
    }
  };

  const [departmentDescription, setDepartmentDescription] = useState("");
  const [updatedDepartmentDescription, setUpdatedDepartmentDescription] =
    useState("");

  const handleDepartmentDescription = (e) => {
    const value = e.target.value;
    console.log("handleDepartmentDescription", value);
    setDepartmentDescription(value);
    if (selectDepartment) {
      selectDepartment.functionDescription = value;
      setUpdatedDepartmentDescription(value);
    }
  };

  const finalSubmit = () => {
    setLoading(true);

    if (departmentName.trim() === "") {
      toast.error(t("please_enter_department_name"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (departmentDescription.trim() === "") {
      toast.error(t("please_enter_department_description"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    const reqDto = {
      functionId: "",
      functionName: departmentName,
      functionDescription: departmentDescription,
    };

    console.log("reqDto", reqDto);

    createNewDepartment(userDetails.orgId, userDetails.userId, reqDto).then(
      (response) => {
        console.log("createNewDepartment", response.data);
        setLoading(false);
        if (response.data.returnValue === "1") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          refresDepartmenthData();
          setDepartmentName("");
          setDepartmentDescription("");
        } else {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    );
  };

  const updatedFinalSubmit = () => {
    console.log("updatedFinalSubmit");
    setLoading(true);

    const newDepartmentName = updatedDepartmentName
      ? updatedDepartmentName
      : selectDepartment.functionName;

    const newDepartmentDesignation = updatedDepartmentDescription
      ? updatedDepartmentDescription
      : selectDepartment.functionDescription;

    const reqDto = {
      functionId: selectDepartment.functionId,
      functionName: newDepartmentName,
      functionDescription: newDepartmentDesignation,
    };

    console.log(" Update reqDto", reqDto);

    // return ;

    updateDepartment(userDetails.userId, reqDto).then((response) => {
      console.log("updateDepartment", response.data);
      setLoading(false);
      if (response.data.returnValue === "1") {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        refresDepartmenthData();
        setUpdatedDepartmentName("");
        setUpdatedDepartmentDescription("");
        setDepartmentName("");
        setDepartmentDescription("");
        handelCloseCrtDeprtView();
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
      }
    });
  };

  return (
    <>
      <div className="flotingAreaContainer">
        <div className="flotingAreaClose">
          <div className="facHeader">
            {selectDepartment ? t("edit_department") : t("add_department")}
          </div>
          <IconButton
            className="CloseBtn"
            onClick={() => handelCloseCrtDeprtView()}
          >
            <XLg />
          </IconButton>
        </div>

        {loading ? (
          <div className="meetingVEContainer">
            <div className="center-progress" style={{ height: "65vh" }}>
              <CircularProgress sx={{ marginTop: "180px" }} />
            </div>
          </div>
        ) : (
          <div className="elementFormContainer">
            {/* <div className="elementWithBtn">
            <div className="formElement ">
              <FormControl className="formControl">
                <InputLabel id="PreDefineDepartment">
                  Pre Define Department
                </InputLabel>
                <Select
                  className="formInputField"
                  labelId="PreDefineDepartment"
                  id="PreDefineDepartment-checkbox"
                  multiple
                  value={personName}
                  onChange={handleChangeMultiselect}
                  input={<OutlinedInput label="Pre Define Department" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {names.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={personName.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <Button startIcon={<Add />} className="dfultPrimaryBtn ">
              Add
            </Button>
          </div>

          <div className="orDiv">
            <span>OR</span>
          </div> */}

            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  label={t("department_name")}
                  required
                  variant="outlined"
                  className="formTextFieldArea"
                  value={selectDepartment?.functionName || departmentName}
                  onChange={handleDepartmentName}
                />
              </FormControl>
            </div>
            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  label={t("department_description")}
                  required
                  variant="outlined"
                  className="formTextFieldArea"
                  value={
                    selectDepartment?.functionDescription ||
                    departmentDescription
                  }
                  onChange={handleDepartmentDescription}
                />
              </FormControl>
            </div>
          </div>
        )}

        <div className="elementFooter">
          <div className="formBtnElement">
            {/* <Button className="dfultPrimaryBtn" onClick={()=>finalSubmit()}>Save</Button> */}
            <Button
              className="dfultPrimaryBtn"
              onClick={() =>
                selectDepartment ? updatedFinalSubmit() : finalSubmit()
              }
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <>{t("submit")}</>
              )}
            </Button>
            {/* <Button
              className="dfultDarkSecondaryBtn"
              onClick={() => handelCloseCrtDeprtView()}
            >
              Cancel
            </Button> */}
            {!loading && (
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={() => handelCloseCrtDeprtView()}
              >
                {t("cancel")}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
