import { Button, IconButton, TextareaAutosize } from "@mui/material";
// import { Attachment, Minus, Trash } from "iconoir-react";
import React, { useRef, useState } from "react";
import { ReactMultiEmail } from "react-multi-email";
import { toast } from "react-toastify";
import EmailUtil from "./EmailUtil";
import { Cancel } from "@mui/icons-material";
import { getValidExtentions } from "../../../utils/Utils";
import { Attachment, Minus, Trash } from "iconoir-react";
import MailRTE from "../../RTE/MailRTE";
import { generateStyleTag } from "./mailStye";
import { XLg } from "react-bootstrap-icons";
import SecureIndexedDB from "../../../utils/IndexedDB";

export default function ComposeMail({ closeComposeMail }) {
  const [toEmails, setToEmails] = useState([]);
  const [ccEmails, setCcEmails] = useState([]);
  const [bccEmails, setBccEmails] = useState([]);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [fileData, setFileData] = useState(null);
  const fileUploadRef = useRef();
  const toMailRef = useRef();

  const [rteHtml, setRteHtml] = useState("");

  // function createRawMessage(message) {
  //   const email = [
  //     'Content-Type: text/plain; charset="UTF-8"\r\n',
  //     "MIME-Version: 1.0\r\n",
  //     "Content-Transfer-Encoding: 7bit\r\n",
  //     "to: ",
  //     message.to,
  //     "\r\n",
  //     "subject: ",
  //     message.subject,
  //     "\r\n\r\n",
  //     message.body,
  //   ].join("");

  //   return btoa(email).replace(/\+/g, "-").replace(/\//g, "_");
  // }

  const createRawMessage = async (message) => {
    let htmlMsg = message.body.split("\n").join("<br>");
    htmlMsg = `<div dir="ltr">${generateStyleTag()}${rteHtml}</div><br>`;
    console.log("html messages = ", htmlMsg);

    const boundary = EmailUtil.generateRandomBoundary();
    const toHeader = message.to ? `To: ${message.to.join(",")}` : "";
    const ccHeader = message.cc ? `Cc: ${message.cc.join(",")}` : "";
    const bccHeader = message.bcc ? `Bcc: ${message.bcc.join(",")}` : "";

    let attachmentBody = [];
    fileData &&
      fileData.forEach((file) => {
        console.log("file = ", file);

        attachmentBody = [
          ...attachmentBody,
          `--${boundary}`,
          `Content-Type: ${file.mimeType}; name="${file.name}"`,
          "Content-Transfer-Encoding: base64",
          `Content-Disposition: attachment; filename="${file.name}"`,
          "",
          file.data,
        ];
      });

    const senderName = await SecureIndexedDB.getItem("email_user_name");
    const senderMailId = await SecureIndexedDB.getItem("current_mailId");
    const eml = [
      `Content-Type: multipart/mixed; boundary=${boundary}`,
      "MIME-Version: 1.0",
      senderName && senderMailId ? `From: ${senderName} <${senderMailId}>` : "",
      toHeader,
      ccHeader,
      bccHeader,
      `Subject: ${message.subject}`,
      "",
      `--${boundary}`,
      'Content-Type: text/html; charset="UTF-8"',
      "",
      htmlMsg,
      "",
      ...attachmentBody,
      `--${boundary}--`,
    ].join("\r\n");

    console.log("email body =", eml);
    // return;

    // return btoa(email2);
    const raw = btoa(unescape(encodeURIComponent(eml)));
    return raw;
  };

  async function sendEmailWithAuth() {
    // if (toEmails.length === 0) {
    //   toast.error("To Email List Cannot Be Empty", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   return;
    // }
    // const emailContent = "Test email content";

    const message = {
      to: toEmails,
      // to: ["software-engineer2@miniontek.com"],
      cc: ccEmails,
      bcc: bccEmails,
      subject: subject,
      body: body,
    };

    const rawMessage = createRawMessage(message);

    const request = window.gapi.client.gmail.users.messages.send({
      userId: "me",
      resource: {
        raw: rawMessage,
      },
    });

    request.execute(function (response) {
      if (response.error) {
        console.error("Error sending message:", response.error);
        toast.error(response.error.message);
        // Handle errors here
      } else {
        toast.success("Message sent successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        console.log("Message sent successfully:", response);
        setSubject("");
        setBody("");
        setToEmails([]);
        setCcEmails([]);
        setBccEmails([]);
        closeComposeMail();

        // Handle success, if needed
      }
    });
  }
  return (
    <>
      <div className="composeMailArea">
        <div className="composeMailContainer">
          <div className="composrMailPesition">
            <div className="composeMailStart">
              <div className="visible">
                <div className="composeMailHeader">
                  <div className="compMailHeading">New Message</div>
                  <div className="compMailControl">
                    <div className="compMailCtrl" id="compMinimise">
                      <Minus />
                    </div>
                    <div
                      className="compMailCtrl"
                      id="compClose"
                      onClick={() => {
                        closeComposeMail();
                      }}
                    >
                      <Cancel />
                    </div>
                  </div>
                </div>
                <div className="composeMailSenderInfo">
                  <ReactMultiEmail
                    placeholder="To"
                    emails={toEmails}
                    onChange={(_emails) => {
                      setToEmails(_emails);
                    }}
                    autoFocus={true}
                    getLabel={(email, index, removeEmail) => {
                      return (
                        <div data-tag key={index}>
                          <div data-tag-item>{email}</div>
                          <span
                            className="removeMailId"
                            data-tag-handle
                            onClick={() => removeEmail(index)}
                          >
                            <Cancel />
                          </span>
                        </div>
                      );
                    }}
                  />
                  <ReactMultiEmail
                    placeholder="Cc"
                    emails={ccEmails}
                    onChange={(_emails) => {
                      setCcEmails(_emails);
                    }}
                    autoFocus={false}
                    getLabel={(email, index, removeEmail) => {
                      return (
                        <div data-tag key={index}>
                          <div data-tag-item>{email}</div>
                          <span
                            className="removeMailId"
                            data-tag-handle
                            onClick={() => removeEmail(index)}
                          >
                            <Cancel />
                          </span>
                        </div>
                      );
                    }}
                  />
                  <ReactMultiEmail
                    placeholder="Bcc"
                    emails={bccEmails}
                    onChange={(_emails) => {
                      setBccEmails(_emails);
                    }}
                    autoFocus={false}
                    getLabel={(email, index, removeEmail) => {
                      return (
                        <div data-tag key={index}>
                          <div data-tag-item>{email}</div>
                          <span
                            className="removeMailId"
                            data-tag-handle
                            onClick={() => removeEmail(index)}
                          >
                            <Cancel />
                          </span>
                        </div>
                      );
                    }}
                  />
                </div>
                <div className="composeMailSubject">
                  <div className="compMailSubject">
                    <input
                      type="text"
                      placeholder="Subject"
                      id="compMailSubjectInput"
                      className="compMailSubjectInput"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                    />
                  </div>
                </div>
                {/* <div className="composeMailBody">
                  <TextareaAutosize
                    className="textAreaAutosize"
                    aria-label="minimum height"
                    minRows={12}
                    maxRows={16}
                    placeholder="Enter your text here"
                    value={body}
                    onChange={(e) => setBody(e.target.value)}
                  />
                </div> */}
                <div className="composeMailBody">
                  {<MailRTE setRteHtml={setRteHtml} />}
                </div>
                {/* list of attach file start */}
                {/* <div className="viewEmlAtchFile">
                  <div className="atfilEml">
                    <span className="atfilNm">Xyzas_kjdb.png</span>
                    <IconButton>
                      <XLg />
                    </IconButton>
                  </div>
                  <div className="atfilEml">
                    <span className="atfilNm">Xyzas_kjdb.png</span>
                    <IconButton>
                      <XLg />
                    </IconButton>
                  </div>
                  <div className="atfilEml">
                    <span className="atfilNm">Xyzas_kjdb.png</span>
                    <IconButton>
                      <XLg />
                    </IconButton>
                  </div>
                  <div className="atfilEml">
                    <span className="atfilNm">Xyzas_kjdb.png</span>
                    <IconButton>
                      <XLg />
                    </IconButton>
                  </div>
                </div> */}
                {/* list of attach file end */}

                <div className="composeMailAction">
                  <div className="composeSendMail">
                    {/* <Button
                      variant="contained"
                      className="sendeCompMailBtn"
                      onClick={(e) => {
                        console.log("open file and get base64 string");
                      }}
                    >
                      File
                    </Button> */}

                    <Button
                      variant="contained"
                      className="sendeCompMailBtn"
                      onClick={(e) => {
                        sendEmailWithAuth();
                      }}
                    >
                      Send
                    </Button>
                  </div>
                  <div className="cMailAction">
                    <div className="composeMailDelete">
                      <IconButton>
                        <Attachment
                          onClick={(e) => {
                            fileUploadRef.current.click();
                            console.log("open file and get base64 string");
                          }}
                        />
                      </IconButton>
                      <input
                        ref={fileUploadRef}
                        multiple
                        type="file"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          let files = Array.from(e.target.files);
                          console.log(files);
                          const fileList = [];

                          const promises = files.map((file) => {
                            const reader = new FileReader();

                            return new Promise((resolve) => {
                              reader.onloadend = () => {
                                const base64Data = reader.result;
                                const data = base64Data.split(",")[1];
                                const mimeType = base64Data
                                  .split(";")[0]
                                  .split(":")[1];
                                fileList.push({
                                  name: file.name,
                                  data: data,
                                  mimeType,
                                });
                                resolve();
                              };
                              reader.readAsDataURL(file);
                            });
                          });

                          Promise.all(promises).then(() => {
                            setFileData(fileList);
                          });
                        }}
                        // accept=".xlsx,.csv,.pptx,.docx,.xls,.pdf,.txt,image/*"
                        accept={getValidExtentions() + "image/*"}
                      />
                    </div>
                    <div className="composeMailDelete">
                      <IconButton>
                        <Trash
                          onClick={() => {
                            console.log("trash clicked");
                            closeComposeMail();
                          }}
                        />
                      </IconButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
