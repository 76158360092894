import { Add } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Trash, XLg } from "react-bootstrap-icons";
import {
  getRightsToSelect,
  addSalaryHead,
  getSalaryHeads,
} from "../../../../services/AdminService";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import SecureIndexedDB from "../../../../utils/IndexedDB";
import { GetLoginUserDetails } from "../../../../utils/Utils";

export default function CerateSalaryHead({
  handelCloseCreateSalaryHead,
  refreshSalaryHeadData,
}) {
  const userDetails = GetLoginUserDetails();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const [salaryHeadData, setSalaryHeadData] = useState([]);

  useEffect(() => {
    getSalaryHeads(userDetails.orgId)
      .then((response) => {
        console.log("getSalaryHeads", response.data);
        setSalaryHeadData(response.data);
        // setFilteredSalaryHeadData(response.data);
        // setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching units", error);
        // setLoading(false);
      });
  }, [userDetails.orgId]);

  const [selectSalaryGrp, setSelectSalaryGrp] = useState(null);
  const [userInput, setUserInput] = useState("");
  const [AllSalaryGroup, setAllSalaryGroup] = useState([]);

  useEffect(() => {
    getRightsToSelect("SALARY_GROUP").then((response) => {
      console.log("SALARY_GROUP", response.data);
      setAllSalaryGroup(response.data);
    });
  }, []);

  // const handleChangeSalaryGrp = (event, newValue) => {
  //   console.log("handleChangeSalaryGrp", newValue);
  //   // setSelectSalaryGrp(newValue ? newValue.name : event.target.value);
  //   // setSelectSalaryGrp(newValue.name);
  //   setSelectSalaryGrp(newValue);
  // };

  // const handleChangeSalaryGrp = (event, newValue) => {
  //   console.log('handleChangeSalaryGrp' , newValue);
  //   console.log('handleChangeSalaryGrp 333' , event.target.value);
  //   setSelectSalaryGrp(newValue)

  // };

  // const handleChangeSalaryGrp = (event, newValue) => {
  //   console.log('handleChangeSalaryGrp' , newValue);
  //   if (newValue) {
  //     // If the newValue is present, set selectSalaryGrp to newValue
  //     setSelectSalaryGrp(newValue);
  //   } else {
  //     // If newValue is null or undefined, clear selectSalaryGrp
  //     setSelectSalaryGrp(null);
  //   }
  // }

  const handleChangeAutocomplete = (event, newValue) => {
    console.log("handleChangeAutocomplete", newValue);
    setSelectSalaryGrp(newValue);
    // if (newValue && newValue.inputValue) {
    //   setSelectSalaryGrp(newValue.inputValue);
    // } else if (newValue && newValue.name) {
    //   setSelectSalaryGrp(newValue.name);
    // } else {
    //   setSelectSalaryGrp(null);
    // }
  };

  const handleInputChange = (event, newInputValue) => {
    setUserInput(newInputValue);
  };

  const [selectSalaryCalbasis, setSelectSalaryCalbasis] = useState("");
  const [allSalaryHead, setAllSalaryHead] = useState([]);

  useEffect(() => {
    getRightsToSelect("SALARY_HEAD").then((response) => {
      console.log("SALARY_HEAD", response.data);
      setAllSalaryHead(response.data);
    });
  }, []);

  // const handleChangeSalaryCalbasis = (event) => {
  //   console.log('handleChangeSalaryCalbasis', event.target.value);
  //   setSelectSalaryCalbasis(event.target.value);
  // };
  const handleChangeSalaryCalbasis = (event) => {
    const selectedId = event.target.value;
    console.log("handleChangeSalaryCalbasis", selectedId);
    setSelectSalaryCalbasis(selectedId);
  };

  console.log("salaryHeadData", salaryHeadData);
  console.log("salaryHeadData.length", salaryHeadData.length);

  const [selectSalaryOrders, setSelectSalaryOrders] = useState(0);

  useEffect(() => {
    if (salaryHeadData.length >= 0) {
      setSelectSalaryOrders(salaryHeadData.length + 1);
    }
  }, [salaryHeadData]);

  const handleChangeSalaryOrders = (event) => {
    setSelectSalaryOrders(event.target.value);
  };

  // const handleChangeSalaryOrders = (event) => {
  //   setSelectSalaryOrders(event.target.value);
  // };

  const [salaryHeadName, setSalaryHeadName] = useState("");

  const [inputSalaryHeadName, setInputSalaryHeadName] = useState("");

  const handleSalaryHeadName = (e, newValue) => {
    console.log("handleSalaryHeadName", e.target.value, newValue);
    setSalaryHeadName(newValue);
  };

  const handleInputSalaryHeadName = (e, newValue) => {
    console.log("handleInputSalaryHeadName", newValue);
    setInputSalaryHeadName(newValue);
  };

  const [PercentageValue, setPercentagevalue] = useState("");

  // const HandlePercentageValue = (event) => {
  //   const numericValue = event.target.value.replace(/[^0-9]/g, "");
  //   setPercentagevalue(numericValue);
  //   console.log("HandlePercentageValue", numericValue);
  // };
  const HandlePercentageValue = (event) => {
    const value = event.target.value;
    console.log("Input value: ", value);
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (regex.test(value)) {
      console.log("Valid value: ", value);
      setPercentagevalue(value);
    } else {
      console.log("Invalid value: ", value);
    }
  };

  const finalSubmit = () => {
    console.log("finalSubmit");
    setLoading(true);

    const lastSalaryHeadId =
      salaryHeadData.length > 0
        ? salaryHeadData[salaryHeadData.length - 1].salaryHeadId
        : 0;

    console.log("userInput", userInput);
    console.log("selectSalaryGrp", selectSalaryGrp);

    const newSalaryGroup = selectSalaryGrp?.name;

    const selectedSalaryGroup = userInput ? userInput : newSalaryGroup;

    const newSalaryHead = salaryHeadName?.name;

    const salaryhead = newSalaryHead ? newSalaryHead : inputSalaryHeadName;

    const calculationBasisHead = salaryHeadData.find(
      (head) => head.salaryHeadId === selectSalaryCalbasis
    );

    const calculationBasisSalaryHeadName = calculationBasisHead
      ? calculationBasisHead.salaryHeadName
      : "";

    if (salaryhead.trim() === "") {
      toast.error(t("please_enter_salary_head_name"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    // if (calculationBasisSalaryHeadName.trim() === "") {
    //   toast.error("Please Select Calculation Basis", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   setLoading(false);
    //   return;
    // }

    const reqDto = {
      salaryHeadId: 0,
      salaryHeadName: salaryhead,
      salaryHeadGroup: selectedSalaryGroup,
      calculationBasisHeadId: selectSalaryCalbasis,
      calculationBasisSalaryHeadName: calculationBasisSalaryHeadName,
      calculationPercentage: PercentageValue,
      slNo: selectSalaryOrders,
    };
    console.log("reqDto", reqDto);

    // return ;

    addSalaryHead(userDetails.orgId, userDetails.userId, reqDto).then(
      (response) => {
        console.log("addSalaryHead", response.data);
        setLoading(false);
        if (response.data.returnValue === "1") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          refreshSalaryHeadData();
          setSalaryHeadName("");
          setSelectSalaryGrp(null);
          setUserInput("");
          // lastSalaryHeadId = 0;
          setSelectSalaryCalbasis("");
          setPercentagevalue("");
          setSelectSalaryOrders(0);
        } else {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        }
      }
    );
  };

  const filterOptions = (options, { inputValue }) => {
    if (!inputValue) {
      return [];
    }
    return options.filter((option) =>
      option.name.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  return (
    <>
      <div className="flotingAreaContainer">
        <div className="flotingAreaClose">
          <div className="facHeader">{t("add_salary_head_group")}</div>
          <IconButton
            className="CloseBtn"
            onClick={() => handelCloseCreateSalaryHead()}
          >
            <XLg />
          </IconButton>
        </div>

        {loading ? (
          <div className="meetingVEContainer">
            <div className="center-progress" style={{ height: "65vh" }}>
              <CircularProgress sx={{ marginTop: "180px" }} />
            </div>
          </div>
        ) : (
          <div className="elementFormContainer">
            <div className="formElementGrp">
              {/* <div className="formElement mxW-150">
              <FormControl className="formControl">
                <InputLabel id="selectOrdrs">Select Orders</InputLabel>
                <Select
                  className="formInputField"
                  variant="outlined"
                  labelId="selectOrdrs"
                  id="select-saltord"
                  label="Select Orders"
                  value={selectSalaryOrders}
                  onChange={handleChangeSalaryOrders}
                >
                  <MenuItem value={"1"}>1</MenuItem>
                  <MenuItem value={"2"}>2</MenuItem>
                  <MenuItem value={"3"}>3</MenuItem>
                  <MenuItem value={"4"}>4</MenuItem>
                  <MenuItem value={"5"}>5</MenuItem>
                  <MenuItem value={"6"}>6</MenuItem>
                  <MenuItem value={"7"}>7</MenuItem>
                  <MenuItem value={"8"}>8</MenuItem>
                  <MenuItem value={"9"}>9</MenuItem>
                  <MenuItem value={"10"}>10</MenuItem>
                </Select>
              </FormControl>
            </div> */}
              <div className="formElement mxW-150 ">
                <FormControl className="formControl">
                  <TextField
                    label={t("select_orders")}
                    variant="outlined"
                    className="formTextFieldArea"
                    value={selectSalaryOrders}
                    onChange={handleChangeSalaryOrders}
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    }}
                  />
                </FormControl>
              </div>

              <div className="formElement ">
                <FormControl className="formControl">
                  <Autocomplete
                    className="formInputField"
                    options={AllSalaryGroup}
                    getOptionLabel={(option) => option.name || ""}
                    value={selectSalaryGrp}
                    inputValue={userInput}
                    onInputChange={handleInputChange}
                    onChange={handleChangeAutocomplete}
                    freeSolo
                    sx={{
                      "& .MuiAutocomplete-inputRoot": {
                        height: "40px",
                        color: "white",
                      },
                      "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input":
                        { padding: "1px" },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("select_salary_group")}
                        variant="outlined"
                        // value={selectSalaryGrptext}
                        // onChange={HandleSelectSalaryGrptext}
                        //             value={userInput}
                        // onChange={(event) => setUserInput(event.target.value)}
                      />
                    )}
                    //                   value={selectSalaryGrp}
                    //                   inputValue={userInput} // <-- Bind inputValue to userInput state
                    // onInputChange={(event, newInputValue) => {
                    //   console.log('newInputValue', newInputValue);
                    //   console.log('newInputValue', event.target.value);
                    //   setUserInput(newInputValue); // Update userInput state with new input value
                    // }}
                    //                   onChange={handleChangeSalaryGrp}
                  />

                  {/* <InputLabel id="selectsaltype">Select Salary Group</InputLabel> */}
                  {/* <Select
                  className="formInputField"
                  variant="outlined"
                  labelId="selectsaltype"
                  id="select-saltype"
                  label="Select Salary Group"
                  value={selectSalaryGrp}
                  onChange={handleChangeSalaryGrp}
                > */}
                  {/* <MenuItem value={"GPM"}>GPM</MenuItem>
                  <MenuItem value={"tap"}>Total Annual Payments</MenuItem> */}
                  {/* {AllSalaryGroup.map((salaryGroup) => (
                    <MenuItem key={salaryGroup.id} value={salaryGroup.id}>
                      {salaryGroup.name}
                    </MenuItem>
                  ))} */}
                  {/* </Select> */}
                </FormControl>
              </div>
              {/* <div className="ortxt">OR</div> */}
              {/* <div className="formElement ">
              <FormControl className="formControl">
                <TextField
                  label="Group Name*"
                  variant="outlined"
                  className="formTextFieldArea"
                />
              </FormControl>
            </div> */}
            </div>

            {/* <div className="formElement ">
              <FormControl className="formControl">
                <TextField
                  label="Salary Head Name*"
                  variant="outlined"
                  value={salaryHeadName}
                  className="formTextFieldArea"
                  onChange={handleSalaryHeadName}
                />
              </FormControl>
            </div> */}

            <div className="formElement ">
              <FormControl className="formControl">
                <Autocomplete
                  className="formInputField"
                  options={allSalaryHead}
                  getOptionLabel={(option) => option.name || ""}
                  value={salaryHeadName}
                  inputValue={inputSalaryHeadName}
                  onInputChange={handleInputSalaryHeadName}
                  onChange={handleSalaryHeadName}
                  filterOptions={filterOptions}
                  freeSolo
                  sx={{
                    "& .MuiAutocomplete-inputRoot": {
                      height: "40px",
                      color: "white",
                    },
                    "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
                      padding: "1px",
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("salary_head_name")}
                      required
                      variant="outlined"
                    />
                  )}
                />
              </FormControl>
            </div>
            <div className="formElement ">
              <FormControl className="formControl">
                <InputLabel id="selectsaltype">
                  {t("calculation_basis")}
                </InputLabel>
                <Select
                  className="formInputField"
                  variant="outlined"
                  labelId="selectsaltype"
                  id="select-saltype"
                  label={t("calculation_basis")}
                  value={selectSalaryCalbasis}
                  onChange={handleChangeSalaryCalbasis}
                >
                  {/* <MenuItem value={"basic"}>Basic</MenuItem>
                <MenuItem value={"gross"}>Gross</MenuItem> */}
                  {salaryHeadData.map((bugdetHead) => (
                    <MenuItem
                      key={bugdetHead.salaryHeadId}
                      value={bugdetHead.salaryHeadId}
                    >
                      {bugdetHead.salaryHeadName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="formElement ">
              <FormControl className="formControl">
                <TextField
                  label={t("enter_percentage")}
                  variant="outlined"
                  className="formTextFieldArea"
                  value={PercentageValue}
                  onChange={HandlePercentageValue}
                  // inputProps={{
                  //   inputMode: "numeric",
                  //   pattern: "[0-9]*",
                  // }}
                  inputProps={{
                    inputMode: "decimal",
                    pattern: "^[0-9]*\\.?[0-9]*$",
                  }}
                />
              </FormControl>
            </div>
          </div>
        )}

        <div className="elementFooter">
          <div className="formBtnElement">
            {/* <Button className="dfultPrimaryBtn" onClick={() => finalSubmit()}>
              Save
            </Button> */}
            <Button
              className="dfultPrimaryBtn"
              onClick={() => finalSubmit()}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <>{t("save")}</>
              )}
            </Button>
            {!loading && (
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={() => handelCloseCreateSalaryHead()}
              >
                {t("cancel")}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
