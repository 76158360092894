import { AttachFile } from "@mui/icons-material";
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  TextField,
} from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { Eye, Trash, XLg } from "react-bootstrap-icons";
// import ReactDatePicker from "react-datepicker";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PreviewHolidayEmailer from "./model/PreviewHolidayEmailer";
import { convertFileToBase64_Split } from "../../../../utils/FileUtils";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { enIN, es } from "date-fns/locale";

// Register locales with ReactDatePicker
registerLocale("en", enIN);
registerLocale("es", es);

export default function EditHoliday({
  handelCloseSelectedViewHoliday,
  refresHolidayData,
  selecttedViewHolidayData,
}) {
  console.log("selecttedViewHolidayData", selecttedViewHolidayData);
  const { t, i18n } = useTranslation();
  const [holidayName, SetHolidayName] = useState(
    selecttedViewHolidayData.holidayName
  );
  const [holidayDescription, setHolidayDescription] = useState(
    selecttedViewHolidayData.holidayEmailContent
  );
  //   const [selectedDate, setSelectedDate] = useState(selecttedViewHolidayData.holidayDate);
  // const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const holidayImageRef = useRef(null);
  const [fileData_Base64, setFileData_Base64] = useState(
    selecttedViewHolidayData.emailFileDataLink
  );
  const [fileName, setFileName] = useState(
    selecttedViewHolidayData.emailFileName
  );

  // Determine the locale based on the current language
  const currentLocale = i18n.language === "es" ? "es" : "en";

  const handleHolidayName = (e) => {
    console.log("handleHolidayName", e.target.value);
    SetHolidayName(e.target.value);
  };

  const handleHolidayDescription = (e) => {
    console.log("handleHolidayDescription", e.target.value);
    setHolidayDescription(e.target.value);
  };

  // useEffect(() => {
  //   const [day, month, year] = selecttedViewHolidayData.holidayDate.split("-");
  //   setSelectedDate(new Date(`${month}/${day}/${year}`));
  // }, [selecttedViewHolidayData.holidayDate]);

  useEffect(() => {
    if (selecttedViewHolidayData.holidayDate) {
      const [day, month, year] =
        selecttedViewHolidayData.holidayDate.split("-");
      const parsedDate = new Date(`${month}/${day}/${year}`);
      if (!isNaN(parsedDate)) {
        setSelectedDate(parsedDate);
      } else {
        console.error(
          "Invalid date format:",
          selecttedViewHolidayData.holidayDate
        );
      }
    }
  }, [selecttedViewHolidayData.holidayDate]);

  const changeCurrentDate = (newDate) => {
    console.log("changeCurrentDate", newDate);
    setSelectedDate(newDate);
  };

  const handleImageUpload = async (e) => {
    console.log(e);
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    console.log("handleUploadOrgLogo", file);
    // const imageValue = await convertFileToBase64_Split(file);
    // setFileData_Base64(`data:image/png;base64,${imageValue}`)
    setFileData_Base64(await convertFileToBase64_Split(file));
    setFileName(file.name);
  };

  const handleDeleteFile = () => {
    setFileName("");
    setFileData_Base64("");
  };

  const [showPreviewHolidayModel, setPreviewHolidayModel] = useState(false);
  const handelClosePreviewHolidayModel = () => {
    setPreviewHolidayModel(false);
  };

  const CheckPreviewCondition = () => {
    if (holidayName.trim() === "") {
      toast.error(t("please_enter_holiday_name"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    // if (holidayDescription.trim() === "") {
    //   toast.error("Please Enter Holiday Description for Email", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   return;
    // };

    // if (fileData_Base64.trim() === "" && fileName.trim() === "") {
    //   toast.error("Please Attach Image For Emailer", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   return;
    // };

    setPreviewHolidayModel(true);
  };

  const afterSubmission = () => {
    refresHolidayData();
    SetHolidayName("");
    setHolidayDescription("");
    setSelectedDate(new Date());
    setFileData_Base64("");
    setFileName("");
  };

  return (
    <>
      <div className="rightFlotingPanel">
        <div className="rightFlotingContainer">
          <div className="rfContHeader">
            <div className="rfcHeadText">
              {t("view")} {holidayName} {t("holiday")}
            </div>
            <div className="rfcHActionBtnGrp">
              <div className="actionBtn closeBtnD10">
                <IconButton onClick={() => handelCloseSelectedViewHoliday()}>
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>
          <div className="rfContContain">
            <div className="rfContContainDetails">
              <div className="elementFormContainer">
                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      // hiddenLabel
                      label={t("holiday_name")}
                      required
                      variant="outlined"
                      value={holidayName}
                      className="formTextFieldArea"
                      onChange={handleHolidayName}
                      disabled
                    />
                  </FormControl>
                </div>

                <div className="formElement">
                  <FormControl className="formControl">
                    <InputLabel
                      id="setHolidayDate"
                      className="setCompletionDate"
                    >
                      {t("holiday_date")}*
                    </InputLabel>
                    <ReactDatePicker
                      locale={currentLocale}
                      showIcon
                      showPopperArrow={false}
                      labelId="setHolidayDate"
                      className="formDatePicker"
                      dateFormat="dd/MM/yyyy"
                      minDate={new Date()}
                      selected={selectedDate}
                      onChange={(date) => changeCurrentDate(date)}
                      disabled
                    />
                  </FormControl>
                </div>

                <div className="formElement">
                  <FormControl className="formControl pRelative">
                    <TextField
                      required
                      label={t("holiday_description_for_email")}
                      variant="outlined"
                      className="descptionTextFild"
                      multiline
                      rows={10}
                      maxRows={10}
                      value={holidayDescription}
                      onChange={handleHolidayDescription}
                      disabled
                    ></TextField>
                  </FormControl>
                </div>

                <div className="formBtnElement">
                  <Button
                    variant="outlined"
                    startIcon={<AttachFile />}
                    className="comBtnOutline"
                    // "activeStatus" use this class for when file uploded
                    onClick={() => holidayImageRef.current.click()}
                    disabled
                  >
                    {t("attach_image_for_emailer")}
                  </Button>
                  <input
                    ref={holidayImageRef}
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      handleImageUpload(e);
                      holidayImageRef.current.value = null;
                    }}
                  />
                </div>
                {fileName && (
                  <div class="elementListGrp">
                    <ul class="elmListInfo">
                      <li>
                        <span className="rolsNm">{fileName}</span>
                        <IconButton
                          className="rmvBtn"
                          onClick={handleDeleteFile}
                          disabled
                        >
                          <Trash />
                        </IconButton>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="rfContFooter">
            <div className="formBtnElement">
              {/* <Button
                startIcon={<Eye />}
                className="dfultPrimaryBtn"
                // onClick={() => setPreviewHolidayModel(true)}
                onClick={()=> CheckPreviewCondition()}
              >
                Preview
              </Button> */}
              {/* <Button
                className="dfultDarkSecondaryBtn"
                onClick={() => handelCloseSelectedViewHoliday()}
              >
                Cancel
              </Button> */}
            </div>
          </div>
        </div>
      </div>

      {showPreviewHolidayModel && (
        <PreviewHolidayEmailer
          showPreviewHolidayModel={showPreviewHolidayModel}
          handelClosePreviewHolidayModel={handelClosePreviewHolidayModel}
          holidayName={holidayName}
          holidayDescription={holidayDescription}
          selectedDate={selectedDate}
          imageData={fileData_Base64}
          imageName={fileName}
          afterSubmission={afterSubmission}
          selecttedViewHolidayData={selecttedViewHolidayData}
          handelCloseSelectedViewHoliday={handelCloseSelectedViewHoliday}
        />
      )}
    </>
  );
}
