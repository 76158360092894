import React, { useEffect, useState } from "react";

import { GroupAdd } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { FilterList } from "iconoir-react";
import {
  getChatAllContacts,
  getMessageExchangedChats,
} from "../../../services/ChatService";
import { GetLoginUserDetails } from "../../../utils/Utils";
import ContactCard from "./component/ContactCard";
import { useTranslation } from "react-i18next";
import Joyride from "react-joyride";
import { useContext } from "react";
import { TourContext } from "../../../common/TourProvider";

export default function ChatContactList({
  selectedContact,
  setSelectedContact,
  refreshContactCount,
  onClickNewChat,
  onlineUserList,
}) {
  const loginUser = GetLoginUserDetails();
  const { t } = useTranslation();
  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  const [chatContactList, setChatContactList] = useState([]);
  const [searchInputText, setSearchInputText] = useState("");
  const [filterChatContactList, setFilterChatContactList] = useState([]);
  const [userContactList, setUserContactList] = useState([]);
  const [filterUserContactList, setFilterUserContactList] = useState([]);

  const [filterByUnread, setFilterByUnread] = useState(false);

  useEffect(() => {
    if (loginUser != null) {
      fetchData();
    }
  }, []);

  // fetch data from api start
  const fetchData = async () => {
    let contUserIdDtlMap = new Map();

    await getMessageExchangedChats(loginUser.userId).then((response) => {
      if (response.data != null) {
        if (response.data.length > 0) {
          for (let index = 0; index < response.data.length; index++) {
            const element = response.data[index];
            contUserIdDtlMap.set(element?.chatUserId, element);
          }
        }
        setChatContactList(response.data);
        setFilterChatContactList(response.data);
      }
    });

    await getChatAllContacts(loginUser.userId, loginUser.orgId).then(
      (response) => {
        if (response.data != null) {
          let tempList = [];
          if (response.data.length > 0) {
            for (let i = 0; i < response.data.length; i++) {
              const data = response.data[i];
              if (!contUserIdDtlMap.has(data.userId))
                tempList.push({
                  chatId: 0,
                  chatName: data.userName,
                  chatType: data.userType,
                  chatProfileUrl: data.profileUrl,
                  chatCreatedBy: "",
                  chatCreatedTime: "",
                  lastSeenTime: "",
                  unreadChatCount: 0,
                  lastMessageId: 0,
                  lastMessageFileName: "",
                  lastMessageSentBy: "",
                  lastMessageContent: data.userDtl,
                  lastMessageDeleted: "",
                  lastMessageTime: "",
                  lastMessageType: "info",
                  chatUserId: data.userId,
                });
            }
          }
          setUserContactList(tempList);
          setFilterUserContactList(tempList);
        }
      }
    );
  };
  // fetch data from api end

  // filter the data according search key start
  useEffect(() => {
    if (searchInputText !== "") {
      if (chatContactList && chatContactList.length > 0) {
        setFilterChatContactList(
          chatContactList.filter((x) =>
            x?.chatName?.toLowerCase().includes(searchInputText.toLowerCase())
          )
        );
      }

      if (userContactList && userContactList.length > 0) {
        setFilterUserContactList(
          userContactList.filter((x) =>
            x?.chatName?.toLowerCase().includes(searchInputText.toLowerCase())
          )
        );
      }
    } else {
      setFilterChatContactList(chatContactList);
      setFilterUserContactList(userContactList);
    }
  }, [searchInputText]);
  // filter the data according search key end

  // sort the data by unread start
  useEffect(() => {
    if (filterByUnread && chatContactList.length > 0) {
      setFilterChatContactList(
        chatContactList.filter((x) => x?.unreadChatCount > 0)
      );
      setFilterUserContactList([]);
    } else {
      setFilterChatContactList(chatContactList);
      setFilterUserContactList(userContactList);
    }
  }, [filterByUnread]);
  // sort the data by unread end

  // refresh contact list start
  useEffect(() => {
    if (refreshContactCount > 0) {
      fetchData();
    }
  }, [refreshContactCount]);
  // refresh contact list end

  useEffect(() => {
    setBookSteps([
      {
        target: ".viewChatStepOne",
        content: "Here is your profile picture. Click it to view or update your profile settings.",
        disableBeacon: true,
      },
      {
        target: ".viewChatStepTwo",
        content: "Use this search bar to find specific contacts or chats quickly.",
        disableBeacon: true,
      },
      {
        target: "#viewChatStepThree",
        content: "Click here to filter chats by unread messages.",
        disableBeacon: true,
      },
      {
        target: '#viewChatStepFour',
        content: "Use this button to create a new group chat.",
        disableBeacon: true,
      },
      {
        target: ".viewChatStepFive",
        content: "This section shows all your recent chats. Click on a contact to view the conversation.",
        disableBeacon: true,
      }
    ]);
  }, []);

  
  

  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["ChatDetailsAppCT"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        styles={{
          buttonClose: {
            display: "none",
          },
        }}
      />
      <div className="chatContactPanel">
        {/* header section with search filter start */}
        <div className="chatLftContHead">
          <div className="inchat">
            <div className="inchatImg viewChatStepOne">
              <img
                alt="userProfileImage"
                src={loginUser && loginUser?.userProfileImage}
              />
            </div>

            <div className="searchUser viewChatStepTwo">
              <div className="searchIcon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M19.6 21L13.3 14.7C12.8 15.1 12.225 15.4167 11.575 15.65C10.925 15.8833 10.2333 16 9.5 16C7.68333 16 6.146 15.3707 4.888 14.112C3.63 12.8533 3.00067 11.316 3 9.5C3 7.68333 3.62933 6.146 4.888 4.888C6.14667 3.63 7.684 3.00067 9.5 3C11.3167 3 12.8543 3.62933 14.113 4.888C15.3717 6.14667 16.0007 7.684 16 9.5C16 10.2333 15.8833 10.925 15.65 11.575C15.4167 12.225 15.1 12.8 14.7 13.3L21 19.6L19.6 21ZM9.5 14C10.75 14 11.8127 13.5627 12.688 12.688C13.5633 11.8133 14.0007 10.7507 14 9.5C14 8.25 13.5627 7.18767 12.688 6.313C11.8133 5.43833 10.7507 5.00067 9.5 5C8.25 5 7.18767 5.43767 6.313 6.313C5.43833 7.18833 5.00067 8.25067 5 9.5C5 10.75 5.43767 11.8127 6.313 12.688C7.18833 13.5633 8.25067 14.0007 9.5 14Z"
                    fill="#A8A8A8"
                  />
                </svg>
              </div>

              <input
                type="text"
                className="serchInput"
                disabled={filterByUnread}
                value={searchInputText}
                placeholder={t("search_or_find")}
                onChange={(e) => setSearchInputText(e.target.value)}
              />
            </div>
          </div>

          <div className="inChatAct ">
            <IconButton
              id="viewChatStepThree"
              aria-label="Filter"
              className={filterByUnread ? `activeBtn` : ``}
              onClick={() => {
                setSearchInputText("");
                setFilterByUnread((r) => !r);
              }}
            >
              <FilterList />
            </IconButton>

            <Tooltip title={t("create_group")}>
              <IconButton id="viewChatStepFour" aria-label="NewChat" onClick={onClickNewChat}>
                <GroupAdd />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        {/* header section with search filter end */}

        {/* chat recent and main contact section start */}
        <div className="chatCnctLstCont viewChatStepFive">
          <div className="contactListGroup">
            {filterChatContactList && filterChatContactList.length > 0 ? (
              <>
                {filterByUnread && (
                  <div className="txtSeprt">
                    <p>{t("filtered_by_unread")}</p>
                  </div>
                )}

                {filterChatContactList.map((contact) => (
                  <ContactCard
                    key={contact?.chatId}
                    contact={contact}
                    active={selectedContact?.chatId === contact?.chatId}
                    online={onlineUserList.includes(contact.chatUserId)}
                    onClick={() => setSelectedContact(contact)}
                  />
                ))}
              </>
            ) : (
              <>
                {filterByUnread && (
                  <>
                    <div className="noUnChAvaMsg">
                      <span>{t("no_unread_chat_available")}</span>
                      <span
                        className="clickFilter"
                        onClick={() => setFilterByUnread(false)}
                      >
                        {t("clear_filter")}
                      </span>
                    </div>
                  </>
                )}
              </>
            )}

            {/* user contact list start */}
            {filterUserContactList && filterUserContactList.length > 0 && (
              <>
                <div className="txtSeprt">
                  <p>{t("contacts")}</p>
                </div>

                {filterUserContactList.map((contact) => (
                  <ContactCard
                    contact={contact}
                    active={selectedContact?.chatUserId === contact?.chatUserId}
                    online={onlineUserList.includes(contact.chatUserId)}
                    onClick={() => setSelectedContact(contact)}
                  />
                ))}
                </>
            )}
            {/* user contact list end */}
          </div>
        </div>
        {/* chat recent and main contact section end */}
      </div>
    </>
  );
}
