import { CheckBox, DragIndicator } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import { XLg } from "react-bootstrap-icons";
import {
  // getPersonsOfOrg,
  getOrgEmployees,
  getMappedUnmappedMenusOfUser,
  mapMenusToUser,
  getUserListForAccessControl,
} from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { GetLoginUserDetails } from "../../../../utils/Utils";
import Joyride from "react-joyride";
import { useContext } from "react";
import { TourContext } from "../../../../common/TourProvider";

export default function CreateAccessControl({
  handelCloseAssignAccessControl,
  refreshAccessControlData,
}) {
  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);
  const userDetails = GetLoginUserDetails();
  const { t } = useTranslation();
  const [userNameData, setUserNameData] = useState([]);
  const [userData, setUserData] = useState([]);

  const [loading, setLoading] = useState(false);

  const [selectAllCheck, setSelectAllCheck] = useState(false);
  const [selectNoneCheck, setSelectNoneCheck] = useState(false);
  // const [selectedUserId, setSelectedUserId] = useState('');

  const [selectedUserName, setSelectedUserName] = useState("");

  useEffect(() => {
    getUserListForAccessControl(userDetails.orgId).then((response) => {
      console.log("response data=", response.data);
      setUserData(response.data);
      const val = response.data.map((item) => item.userName);
      console.log("val", val);
      setUserNameData(val);
    });
  }, [userDetails.orgId]);

  const handleUserNameChange = (event, value) => {
    console.log("handleUserNameChange", value);
    setSelectedUserName(value);
  };
  // const [isChecked, setIsChecked] = useState(false);

  // const handleChange = (event) => {
  //   setIsChecked(event.target.checked);
  // };

  const [mapDataOfUser, setMapDataOfUser] = useState([]);
  const [switchStates, setSwitchStates] = useState([]);

  // useEffect(() => {
  //   getMappedUnmappedMenusOfUser(userDetails.userId).then((response) => {
  //     console.log("getMappedUnmappedMenusOfUser", response.data);
  //     setMapDataOfUser(response.data);
  //     const initialSwitchStates = response.data.map(
  //       (item) => item.mapped === "Y"
  //     );
  //     setSwitchStates(initialSwitchStates);
  //   });
  // }, [userDetails.userId]);

  // useEffect(()=>{
  //   fetchMapMenuForUser();
  // },[])

  useEffect(() => {
    if (!selectedUserName) {
      setMapDataOfUser([]);
      setSwitchStates([]);
      setSelectAllCheck(false);
      setSelectNoneCheck(false);
      return;
    }
  
    fetchMapMenuForUser();
  }, [selectedUserName]);

  // useEffect(() => {
  //   if (selectedUserName) {
  //     fetchMapMenuForUser();
  //   }
  // }, [selectedUserName]);

  // const fetchMapMenuForUser = () => {
  //   getMappedUnmappedMenusOfUser(userDetails.userId).then((response) => {
  //     console.log("getMappedUnmappedMenusOfUser", response.data);
  //     setMapDataOfUser(response.data);
  //     const initialSwitchStates = response.data.map(
  //       (item) => item.mapped === "Y"
  //     );
  //     setSwitchStates(initialSwitchStates);
  //   });
  // }

  const fetchMapMenuForUser = () => {
    let selectedUserId = "";

    const selectedUser = userData.find(
      (user) => user.userName === selectedUserName
    );

    console.log("selectedUser", selectedUser);

    if (selectedUser) {
      selectedUserId = selectedUser.userId;
    }

    // const newId = selectedUserId !== "" ? selectedUserId : userDetails.userId;

    if (selectedUserId) {
      getMappedUnmappedMenusOfUser(selectedUserId)
        .then((response) => {
          console.log("getMappedUnmappedMenusOfUser", response.data);
          setMapDataOfUser(response.data);
          const initialSwitchStates = response.data.map(
            (item) => item.mapped === "Y"
          );
          // setSwitchStates(initialSwitchStates);
          const allTrue = initialSwitchStates.every((state) => state === true);

          if (allTrue) {
            setSwitchStates(initialSwitchStates);
            setSelectAllCheck(true);
          } else {
            setSwitchStates(initialSwitchStates);
          }
        })
        .catch((error) => {
          console.error("Error fetching mapped menus:", error);
          toast.error(t("failed_to_fetch_mapped_menus"), {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  // Function to handle changes in switch state
  const handleChange = (index) => (event) => {
    const newState = [...switchStates];
    newState[index] = event.target.checked;
    setSwitchStates(newState);

    const allFalse = newState.every((state) => !state);
    setSelectAllCheck(allFalse);

    const allTrue = newState.every((state) => state);
    setSelectAllCheck(allTrue);

    const selectNone = newState.every((state) => {
      console.log("select none", state);
      return state === false;
    });
    console.log("selectNone", selectNone);
    setSelectNoneCheck(selectNone);
  };

  console.log("switchStates", switchStates);

  // const handleSelectAll = () => {
  //   setSwitchStates(new Array(switchStates.length).fill(true));
  //   setSelectAllCheck(true);
  //   setSelectNoneCheck(false);
  // };

  // const handleSelectNone = () => {
  //   setSwitchStates(new Array(switchStates.length).fill(false));
  //   setSelectNoneCheck(true);
  //   setSelectAllCheck(false);
  // };

  const handleSelectAll = (event) => {
    console.log("handleSelectAll triggered", event.target.checked);
    const val = event.target.checked;
    if (val) {
      setSwitchStates(new Array(switchStates.length).fill(true));
      setSelectAllCheck(true);
    } else {
      setSwitchStates(new Array(switchStates.length).fill(false));
      setSelectAllCheck(false);
    }
    console.log("switchStates after select all:", switchStates);
  };

  // const handleSelectAll = () => {
  //   console.log("handleSelectAll triggered");
  //   setSwitchStates(new Array(switchStates.length).fill(true));
  //   setSelectAllCheck(true);
  //   setSelectNoneCheck(false);
  //   console.log("switchStates after select all:", switchStates);
  // };

  const handleSelectNone = () => {
    console.log("handleSelectNone triggered");
    setSwitchStates(new Array(switchStates.length).fill(false));
    setSelectNoneCheck(true);
    setSelectAllCheck(false);
    console.log("switchStates after select none:", switchStates);
  };

  // Data for five list items
  const listItems = [
    "External User - List Of External User",
    "Functions - Add / Delete Activity",
    "Functions - Add / Delete Routine Activity",
    "Functions - Add / Delete Subactivity",
    "Functions - Add / Edit Functions",
    "Holiday / Working Schedule - Add Holiday",
    "Holiday / Working Schedule - Holiday List",
    "Holiday / Working Schedule - Working Days List",
    "Items - Add Items",
    "Items - Non Verified Items",
    "Items - Verified Items",
    "Leave Rules - Add Leave Balance",
    "Leave Rules - Add Rule",
    "Leave Rules - List Of Leave Rules",
    "Organization - Edit",
  ];
  const userlist = [
    { label: "Sanket Santra (IT)" },
    { label: "Avijit Roy (HR)" },
    { label: "Rakesh Kumar (R&D)" },
  ];

  const handleSubmit = () => {
    console.log("handleSubmit");
    setLoading(true);

    if (selectedUserName.trim() === "") {
      toast.error(t("please_select_user"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    let selectedUserId = "";

    const selectedUser = userData.find(
      (user) => user.userName === selectedUserName
    );

    console.log("selectedUser", selectedUser);

    if (selectedUser) {
      // If user is found, set the selectedUserId
      selectedUserId = selectedUser.userId;
    }

    const reqDto = mapDataOfUser.map((item, index) => ({
      menuId: item.menuId,
      menuName: item.menuName,
      menuNameWithModule: item.menuNameWithModule,
      mapped: switchStates[index] ? "Y" : "N",
    }));

    console.log("reqDto", reqDto);

    console.log("selectedUserId", selectedUserId);

    const reqDtoFiltered = reqDto.filter((item) => item.mapped === "Y");

    console.log("reqDtoFiltered", reqDtoFiltered);
    // return ;

    mapMenusToUser(selectedUserId, userDetails.userId, reqDtoFiltered).then(
      (response) => {
        console.log("mapMenusToUser", response.data);
        if (response.data.returnValue === "1") {
          toast.success(t("user_menu_mapped_successfully"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          refreshAccessControlData();
          setSelectedUserName("");
          setMapDataOfUser([]);
          setSwitchStates([]);
          // fetchMapMenuForUser();
          // setSwitchStates([]);
          setSelectAllCheck(false);
          setSelectNoneCheck(false);
          setLoading(false);
        } else {
          toast.error(t("something_went_wrong"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        }
      }
    );
  };

  useEffect(()=>{
  setBookSteps([
    {
      target: ".addAccessControlStepOne",
      content: "First, choose the user for whom you want to manage access permissions. Then you can see the list of available menus. You can quickly select or deselect all menus by toggling The switches",
    },
    {
      target: ".addAccessControlStepTwo",
      content: "Once you've made your changes, click here to save them.",
    },
    {
      target: ".addAccessControlStepThree",
      content: "Click here if you want to discard any changes and close the form.",
    },
  ])
  },[])

  return (
    <>
          <Joyride
        steps={booksteps}
        run={run["createAccessControlAD"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        styles={{
          buttonClose: {
            display: "none",
          },
        }}
      />
      <div className="flotingAreaContainer">
        <div className="flotingAreaClose">
          <div className="facHeader">{t("add_access_permission")}</div>
          <IconButton
            className="CloseBtn"
            onClick={() => handelCloseAssignAccessControl()}
          >
            <XLg />
          </IconButton>
        </div>

        {loading ? (
          <div className="meetingVEContainer">
            <div className="center-progress" style={{ height: "65vh" }}>
              <CircularProgress sx={{ marginTop: "180px" }} />
            </div>
          </div>
        ) : (
          <div className="elementFormContainer">
            <div className="formElement">
              <FormControl className="formControl">
                <Autocomplete
                  labelId="selctUsr"
                  noOptionsText={t("please_type_to_search")}
                  //  multiple
                  className="formAutocompleteField"
                  variant="outlined"
                  disablePortal
                  // freeSolo
                  id="selectUser"
                  options={userNameData}
                  value={selectedUserName}
                  onChange={handleUserNameChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="formAutoComInputField addAccessControlStepOne"
                      label={t("select_user")}
                    />
                  )}
                />
              </FormControl>
            </div>

            {/* <div class="tskElemHeding mt10">Role based access control</div> */}

            {mapDataOfUser.length > 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  gap: "80px",
                  alignItems: "center",
                }}
              >
                <div className="tskElemHeding mt10">{t("menus")}</div>
                <div
                  className="tskElemHeding mt10"
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  {t("select_all")}{" "}
                  <input
                    type="checkbox"
                    checked={selectAllCheck}
                    onChange={handleSelectAll}
                  />
                </div>
                {/* <div className="tskElemHeding mt10" style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
            Unselect All <input type="checkbox" checked={selectNoneCheck} onChange={handleSelectNone} />
            </div> */}
              </div>
            )}

            <ul className="accessControlList">
              {mapDataOfUser.map((menuItem, index) => (
                <li key={index}>
                  <div className="acsConLstInd">
                    <div className="accessName">
                      <span className="acnIoc">
                        <DragIndicator />
                      </span>
                      <span className="acnName">{menuItem.menuName}</span>
                    </div>
                    <div className="accessControlBtn tblActionBtnGrp">
                      <FormControlLabel
                        className="couponStatusSwitch"
                        control={
                          <Switch
                            checked={switchStates[index]}
                            onChange={handleChange(index)}
                            color="primary"
                          />
                        }
                        label={switchStates[index] ? t("enable") : t("disable")}
                      />
                    </div>
                  </div>
                </li>
              ))}
              {/* <li>
                    <div className='acsConLstInd'>
                        <div className='accessName'>
                            <span className='acnIoc'><DragIndicator/></span>
                            <span className='acnName'>Accounts and Invoices - Add Accounts and Invoices</span>
                        </div>
                        <div className='accessControlBtn tblActionBtnGrp'>
                            <FormControlLabel
                                className="couponStatusSwitch"
                                control={<Switch checked={isChecked} onChange={handleChange} color="primary" />}
                                label={isChecked ? "Enable" : "Disable"}
                            />
                         </div>
                    </div>
                </li> */}
            </ul>
          </div>
        )}

        <div className="elementFooter">
          <div className="formBtnElement">
            <Button
              className="dfultPrimaryBtn addAccessControlStepTwo"
              onClick={() => handleSubmit()}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <>{t("save")}</>
              )}
            </Button>
            {!loading && (
              <Button
                className="dfultDarkSecondaryBtn addAccessControlStepThree"
                onClick={() => handelCloseAssignAccessControl()}
              >
                {t("cancel")}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
