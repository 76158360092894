import { ArrowBack, Group, PeopleRounded, Verified } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import {
  getChatAllContacts,
  getMessageExchangedChats,
} from "../../../services/ChatService";
import { GetLoginUserDetails } from "../../../utils/Utils";
import { useTranslation } from "react-i18next";

export default function CreateNewChat({
  onClickBack,
  onClickCreateGroup,
  onSelectUser,
  onlineUserList,
}) {
  const loginUser = GetLoginUserDetails();
  const { t } = useTranslation();

  const [userContactList, setUserContactList] = useState([]);
  const [filterUserContactList, setFilterUserContactList] = useState([]);
  const [searchKey, setSearchKey] = useState("");

  useEffect(() => {
    if (loginUser != null) {
      fetchData();
    }
  }, []);

  // fetch data from api start
  const fetchData = async () => {
    let userIdDetailMap = new Map();
    await getMessageExchangedChats(loginUser.userId).then((response) => {
      if (response.data != null) {
        if (response.data.length > 0) {
          for (let index = 0; index < response.data.length; index++) {
            const user = response.data[index];
            if (user.chatUserId) {
              userIdDetailMap.set(user.chatUserId, user);
            }
          }
        }
      }
    });

    await getChatAllContacts(loginUser.userId, loginUser.orgId).then(
      (response) => {
        if (response.data != null) {
          if (response.data.length > 0) {
            let tempList = [];

            for (let index = 0; index < response.data.length; index++) {
              const user = response.data[index];
              if (userIdDetailMap.has(user.userId)) {
                const chat = userIdDetailMap.get(user.userId);
                tempList.push({ ...chat, lastMessageContent: user.userDtl });
              } else {
                tempList.push({
                  chatId: 0,
                  chatName: user.userName,
                  chatType: user.userType,
                  chatProfileUrl: user.profileUrl,
                  chatCreatedBy: "",
                  chatCreatedTime: "",
                  lastSeenTime: "",
                  unreadChatCount: 0,
                  lastMessageId: 0,
                  lastMessageFileName: "",
                  lastMessageSentBy: "",
                  lastMessageContent: user.userDtl,
                  lastMessageDeleted: "",
                  lastMessageTime: "",
                  lastMessageType: "info",
                  chatUserId: user.userId,
                });
              }
            }

            if (tempList.length > 0) {
              tempList = _sort(tempList);
              setUserContactList(tempList);
              setFilterUserContactList(tempList);
            }
          }
        }
      }
    );
  };
  // fetch data from api end

  // search user list start
  useEffect(() => {
    if (searchKey !== "") {
      setFilterUserContactList(
        userContactList.filter((user) =>
          user.chatName.toLowerCase().includes(searchKey.toLowerCase())
        )
      );
    } else {
      setFilterUserContactList(userContactList);
    }
  }, [searchKey]);
  // search user list end

  // sort data by alphabetically start
  const _sort = (dataList) => {
    return dataList.sort((a, b) => {
      const x = a.chatName.toUpperCase();
      const y = b.chatName.toUpperCase();
      return x < y ? -1 : x > y ? 1 : 0;
    });
  };
  // sort data by alphabetically end

  return (
    <>
      <div className="newChatGrpPanel">
        {/* header section create new group start */}
        <div className="chatLftContHead">
          <div className="bckIoc" role="button" onClick={onClickBack}>
            <ArrowBack />
          </div>
          <div className="nchdText">{t("new_chat")}</div>
        </div>
        {/* header section create new group end */}

        <div className="chatCnctLstCont">
          {/* search input start */}
          <div className="chtsrchUsr">
            <div className="srchUsrIpG">
              <div className="searchUser">
                <div className="searchIcon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M19.6 21L13.3 14.7C12.8 15.1 12.225 15.4167 11.575 15.65C10.925 15.8833 10.2333 16 9.5 16C7.68333 16 6.146 15.3707 4.888 14.112C3.63 12.8533 3.00067 11.316 3 9.5C3 7.68333 3.62933 6.146 4.888 4.888C6.14667 3.63 7.684 3.00067 9.5 3C11.3167 3 12.8543 3.62933 14.113 4.888C15.3717 6.14667 16.0007 7.684 16 9.5C16 10.2333 15.8833 10.925 15.65 11.575C15.4167 12.225 15.1 12.8 14.7 13.3L21 19.6L19.6 21ZM9.5 14C10.75 14 11.8127 13.5627 12.688 12.688C13.5633 11.8133 14.0007 10.7507 14 9.5C14 8.25 13.5627 7.18767 12.688 6.313C11.8133 5.43833 10.7507 5.00067 9.5 5C8.25 5 7.18767 5.43767 6.313 6.313C5.43833 7.18833 5.00067 8.25067 5 9.5C5 10.75 5.43767 11.8127 6.313 12.688C7.18833 13.5633 8.25067 14.0007 9.5 14Z"
                      fill="#A8A8A8"
                    />
                  </svg>
                </div>

                <input
                  type="text"
                  className="serchInput"
                  placeholder={t("search_name")}
                  value={searchKey}
                  onChange={(e) => setSearchKey(e.target.value)}
                />
              </div>
            </div>
          </div>
          {/* search input end */}

          <div className="createGroupCont">
            {/* create  group button start */}
            <div
              className="crtBtnGrp"
              role="button"
              onClick={onClickCreateGroup}
            >
              <div className="cbtgIarea">
                <div className="crtGrpIcon">
                  <Group />
                </div>
              </div>
              <div className="crtgta">
                <div className="crtGrpTxt">{t("create_new_group")}</div>
              </div>
            </div>
            {/* create  group button end */}

            <div className="txtSeprt">
              <p>{t("contacts_on")} Zoyel</p>
            </div>

            {/* list of contacts start */}
            <div className="contactListGroup">
              {filterUserContactList && filterUserContactList.length > 0 && (
                <>
                  {filterUserContactList.map((user) => (
                    <div
                      className="chatContactList"
                      onClick={() => onSelectUser(user)}
                    >
                      <div className="contUserImg">
                        <img alt="" src={user?.chatProfileUrl} />
                        {onlineUserList &&
                          onlineUserList.includes(user.chatUserId) && (
                            <span className="usrActStatus"></span>
                          )}
                      </div>

                      <div className="userDtlWlMsg">
                        <div className="usrNmDT">
                          <div className="usrNmIn">
                            {user?.chatName}
                            {user?.chatType === "I" && <Verified />}
                            {user?.chatType === "G" && <PeopleRounded />}
                          </div>
                        </div>

                        <div className="usrLstMsgDtStus">
                          <div className="lstMsgShw">
                            {user.lastMessageContent}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
            {/* list of contacts end */}
          </div>
        </div>
      </div>
    </>
  );
}
