import { Call, Email, Language } from "@mui/icons-material";
import { Box, Button, IconButton } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { XLg } from "react-bootstrap-icons";
import DateUtils from "../../../../../utils/DateUtils";
import { GetLoginUserDetails } from "../../../../../utils/Utils";
import {
  getTempNo,
  issueAppoinment,
} from "../../../../../services/AdminService";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

export default function PreviewAppointment({
  handleClosePreviewApoinment,
  previewData,
  onSubmitSuccess,
  activity,
}) {
  const { t } = useTranslation();
  const loginUserDetails = GetLoginUserDetails();
  const [pageId, setPageId] = useState(1);
  const [tempNo, setTempNo] = useState();

  const salaryInfo = useMemo(() => {
    const temp = [];
    const tempMap = new Map();

    console.log("salaryInfoList= ", previewData);
    previewData.salaryInfoList &&
      previewData.salaryInfoList.forEach((element) => {
        const yearly = previewData.mode ? element.yearly : element.annual;
        if (element.salaryHeadGroupName) {
          const headData = tempMap.get(element.salaryHeadGroupName);
          if (headData) {
            tempMap.set(element.salaryHeadGroupName, {
              anualTotal: headData.anualTotal + yearly,
              monthlyTotal: headData.monthlyTotal + element.monthly,
              headList: [...headData.headList, element],
            });
          } else {
            tempMap.set(element.salaryHeadGroupName, {
              anualTotal: yearly,
              monthlyTotal: element.monthly,
              headList: [element],
            });
          }
        } else if (element.salaryHeadGroup) {
          const headData = tempMap.get(element.salaryHeadGroup);
          if (headData) {
            tempMap.set(element.salaryHeadGroup, {
              anualTotal: parseFloat(
                parseFloat(headData.anualTotal + yearly).toFixed(2)
              ),
              monthlyTotal: parseFloat(
                parseFloat(headData.monthlyTotal + element.amount).toFixed(2)
              ),
              headList: [...headData.headList, element],
            });
          } else {
            tempMap.set(element.salaryHeadGroup, {
              anualTotal: yearly,
              monthlyTotal: element.amount,
              headList: [element],
            });
          }
        }
      });

    //
    const salaryInfoData = Array.from(tempMap);
    console.log("salaryInfoData=", salaryInfoData);
    return salaryInfoData;
  }, [previewData]);

  const monthlyTotal = useMemo(() => {
    // setTempSelComponent()
    const value = previewData?.salaryInfoList?.reduce((acc, curr) => {
      const { monthlyAmt } = curr;
      let temp = 0;
      if (monthlyAmt) {
        temp = monthlyAmt;
      }
      return acc + temp;
    }, 0);
    return value;
  }, [previewData?.salaryInfoList]);

  const yearlyTotal = useMemo(() => {
    // setTempSelComponent()
    const value = previewData?.salaryInfoList?.reduce((acc, curr) => {
      const { anualAmt } = curr;
      let temp = 0;
      if (anualAmt) {
        temp = anualAmt;
      }
      return acc + temp;
    }, 0);
    return value;
  }, [previewData?.salaryInfoList]);

  function convertNumberToWords(num) {
    if (!num) {
      return;
    }
    let number;
    let numberArray;
    if (typeof num === "string") {
      numberArray = num.split(",");
      let str = "";
      numberArray.forEach((element) => {
        str += element;
      });
      number = parseInt(str);
    } else {
      number = num;
    }
    // console.log("nubmers =", typeof number);
    const units = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ];
    const teens = [
      "",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "Ten",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    function convertThreeDigits(num) {
      let str = "";

      if (num >= 100) {
        str += units[Math.floor(num / 100)] + " Hundred ";
        num %= 100;
      }

      if (num >= 11 && num <= 19) {
        str += teens[num - 10] + " ";
      } else if (num === 10 || num >= 20) {
        str += tens[Math.floor(num / 10)] + " ";
        num %= 10;
      }

      if (num >= 1 && num <= 9) {
        str += units[num] + " ";
      }

      return str;
    }

    if (number === 0) {
      return "Zero";
    }

    let words = "";
    let crore = Math.floor(number / 10000000);
    number %= 10000000;
    let lakh = Math.floor(number / 100000);
    number %= 100000;
    let thousand = Math.floor(number / 1000);
    let remainder = number % 1000;

    if (crore > 0) {
      words += convertThreeDigits(crore) + "Crore ";
    }

    if (lakh > 0) {
      words += convertThreeDigits(lakh) + "Lac ";
    }

    if (thousand > 0) {
      words += convertThreeDigits(thousand) + "Thousand ";
    }

    if (remainder > 0) {
      words += convertThreeDigits(remainder);
    }

    return words.trim();
  }

  useEffect(() => {
    console.log("data =", previewData);
    getTempNo("APPOINTMENT_LETTER", loginUserDetails.orgId).then((response) => {
      setTempNo(response.data);
    });
  }, []);

  const calculatePerMonthTotal = (itemList) => {
    const initialValue = 0;
    const totalValue = itemList.reduce(
      (accumulator, current) => accumulator + current[1].monthlyTotal,
      initialValue
    );
    return parseFloat(totalValue).toFixed(2);
  };

  const calculatePerYearTotal = (itemList) => {
    const initialValue = 0;
    const totalValue = itemList.reduce(
      (accumulator, current) => accumulator + current[1].anualTotal,
      initialValue
    );
    return parseFloat(totalValue).toFixed(2);
  };

  const issueNewAppoinment = () => {
    console.log("preview salaryHead name", previewData);

    const termsConditions = previewData.staticDodList.map((dod) => dod.id);

    const salaryBrkUps = [];

    // previewData.salaryInfoList.forEach((element) => {
    //   const temp = {
    //     salaryHeadId: element.salaryHeadId,
    //     salaryHeadName: element.salaryHeadName,
    //     salaryHeadGroupName: previewData.mode
    //       ? element.salaryHeadGroupName
    //       : element.calculationBasisSalaryHeadName,
    //     pc: previewData.mode ? element.pc : element.calculationPercentage,
    //     pcOnSalaryHeadId: previewData.mode
    //       ? element.pcOnSalaryHeadId
    //       : element.calculationBasisHeadId,
    //     amount: previewData.mode ? element.monthly : element.amount,
    //     monthly: previewData.mode ? element.monthly : element.amount,
    //     yearly: previewData.mode ? element.yearly : element.annual,
    //   };
    //   salaryBrkUps.push(temp);
    // });

    // const candidate = {
    //   title: previewData.employeeTitle,
    //   firstName: previewData.firstName,
    //   middleName: previewData.middleName,
    //   lastName: previewData.lastName,
    //   email: previewData.email,
    //   addressLine1: previewData.address1,
    //   addressLine2: previewData.address2,
    //   pinCode: previewData.pin,
    //   countryCode: previewData.country.countryCode,
    //   stateId: previewData?.state?.id || "",
    //   city: previewData.city,
    //   dob: DateUtils.getDateInDDMMYYYY(previewData.dateOfBirth),
    //   corrAddressLine1: previewData.corAddress.cor_address1,
    //   corrAressLine2: previewData.corAddress.cor_address2,
    //   corrPinCode: previewData.corAddress.cor_pin,
    //   corrCountryCode: previewData.corAddress.cor_country.countryCode,
    //   corrStateId: previewData.corAddress.cor_state?.id || "",
    //   corrCity: previewData.corAddress.cor_city,
    //   candidateId: previewData.candidateId || 0,
    // };
    const candidate = {
      title: previewData.employeeTitle,
      firstName: previewData.firstName,
      middleName: previewData.middleName,
      lastName: previewData.lastName,
      email: previewData.email,
      addressLine1: previewData.address1,
      addressLine2: previewData.address2,
      pinCode: previewData.pin,
      countryCode: previewData.country.countryCode,
      stateId: previewData?.state?.id || "",
      city: previewData.city,
      candidateId: previewData.candidateId || 0,
      dob: previewData.dateOfBirth
        ? dayjs(previewData.dateOfBirth).format("DD-MM-YYYY")
        : "",
      corrAddressLine1: previewData.corAddress.cor_address1,
      corrAddressLine2: previewData.corAddress.cor_address2,
      corrPinCode: previewData.corAddress.cor_pin,
      corrCountryCode: previewData.corAddress.cor_country.countryCode,
      corrStateId: previewData.corAddress.cor_state?.id || "",
      corrCity: previewData.corAddress.cor_city,
    };

    // const ctcVAlue

    // const salaryBrkUps = [];

    previewData.salaryInfoList.forEach((element) => {
      const temp = {
        componentId: element.salaryComponentId,
        monthly: element.monthlyAmt,
        yearly: element.anualAmt,
      };
      salaryBrkUps.push(temp);
    });

    const reqDto = {
      candidate: candidate,
      priorAddressChangeDays: previewData.priorAddressChangeDays,
      priorLeaveNoticeDays: previewData.priorLeaveNoticeDays,
      functionId: previewData.department.functionId || 0,
      positionId: previewData.position.positionId,
      reportingHeadUserId: previewData.reportingHead.userId,
      joiningDate: DateUtils.getDateInDDMMYYYY(previewData.joiningdate),
      employmentType: previewData.employmentTitle,
      grossPerMnth: parseInt(previewData.gpm.replace(/,/g, "")) || 0,
      ctc: parseInt(
        typeof previewData.ctc === "string"
          ? previewData.ctc.replace(/,/g, "")
          : previewData.ctc
      ),
      totalAmount:
        parseInt(
          typeof previewData.ctc === "string"
            ? previewData.ctc.replace(/,/g, "")
            : previewData.ctc
        ) || 0,
      saveTermsConditions: previewData.saveForFutureAppoint ? "Y" : "N",
      unitId: previewData.location.unitId,
      probationPeriod: parseInt(previewData.probationDay),
      terminateContinuousAbsentDays: parseInt(previewData.absentDays),
      noticePeriodDays: parseInt(previewData.noticePeriod) || 0,
      salayInLieuNoticePeriod: parseInt(previewData.noticePeriod),
      loiId:
        (previewData.loiSelectedCandidate &&
          previewData.loiSelectedCandidate.loiId) ||
        0,
      salaryBrkUps: salaryBrkUps,
      termsConditions: termsConditions,
      activityId: activity.id,
      activityName: activity.name,

      currency: previewData.currency || "",
      ccMailIds: previewData.ccLoiEmaiIds || [],

      // ccMailIds: ["hossainmuntaj@gmail.com"],
    };
    console.log("reqDto = ", reqDto);
    // return;

    issueAppoinment(loginUserDetails.userId, loginUserDetails.orgId, reqDto)
      .then((response) => {
        if (response.data.returnValue === "1") {
          toast.success(t("issue_appoinment_success"));
          onSubmitSuccess();
        } else {
          toast.error(t("something_went_wrong"));
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log("Error Status Code:", error.response.status);
          console.log("Error Data:", error.response.data);
          console.log("Error Headers:", error.response.headers);
        } else {
          console.log("No response get");
        }
      });
  };

  return (
    <>
      <div className="modelContainer previewDocument">
        <Box className="modelContain animated fullwidthModel">
          <IconButton
            className="closeModelIoc"
            onClick={() => handleClosePreviewApoinment()}
          >
            <XLg />
          </IconButton>

          <div className="fwModelContainArea">
            <div className="orgDocheaderPrv">
              <div className="orgDocHedLogo">
                <img src={previewData.orgData.orgLogo} alt="org Logo" />
                <div>{previewData.orgData.orgName}</div>
              </div>
              <div className="orgDocHedInfo">
                <div className="orgDocHedInfoElement">
                  <span>{previewData.orgData.contactNo}</span>
                  <span>
                    <Call />
                  </span>
                </div>
                <div className="orgDocHedInfoElement">
                  <span>{previewData.orgData.orgEmailId}</span>
                  <span>
                    <Email />
                  </span>
                </div>
                <div className="orgDocHedInfoElement">
                  <span>{previewData.orgData.subDomain}.zoyel.one</span>
                  <span>
                    <Language />
                  </span>
                </div>
              </div>
            </div>

            <div className="orgUsrDemogrphy">
              <div className="orgUserEmpInfo">
                <div className="loiGret">To</div>
                <div className="loiGret">{`${previewData.employeeTitle}. ${previewData.firstName} ${previewData.middleName} ${previewData.lastName}`}</div>
                <div className="loiGret adlinOne">{`${previewData.address1},`}</div>
                <div className="loiGret adlinTwo">
                  {`${previewData.address2},`}
                </div>
                <div className="loiGret adlinTwo">{`${previewData.city},${
                  previewData?.state?.name || ""
                }`}</div>
                <div className="loiGret adlinThree">{`${previewData.pin}, ${previewData.country.countryName}`}</div>
              </div>
              <div className="orgLoiDT">
                <div className="LoiMemoNo">{tempNo}</div>
                <div className="LoiDate">
                  {t("date")}:{" "}
                  <span>{DateUtils.getDateInFormalFormat(new Date())}</span>
                </div>
              </div>
            </div>

            {pageId === 1 && (
              <>
                <div className="textHeadCenter">
                  {t("subject")}: {t("appointmentAs")}{" "}
                  <span>
                    {" "}
                    {previewData.position && previewData.position.position}
                  </span>
                </div>
                <div className="loiContain">
                  <p>
                    <span>
                      {t("dear")}{" "}
                      {`${previewData.employeeTitle} ${previewData.firstName} ${previewData.middleName} ${previewData.lastName}`}
                      ,
                    </span>
                  </p>
                  <p>
                    {t("withReferenceToYourApplication")}
                    <span>
                      {previewData.position && previewData.position.position}
                    </span>
                    {t("in")}
                    <span>{previewData.orgData.orgName}</span> {t("at_our")}
                    <span>
                      {previewData.location.unitName},
                      {
                        previewData.location.organizationAddressDto
                          .addressDisplay
                      }
                    </span>
                    {"."}
                  </p>
                  <p>
                    <span>{t("dateOfJoining")}:</span>
                    {t("youHaveJoined")}{" "}
                    <span>
                      {DateUtils.getDateInFormalFormat(previewData.joiningdate)}{" "}
                      .
                    </span>
                  </p>
                  {/* <p>
                    <span>Place / Transfer:</span>
                    Though your initial place of posting will be at the above
                    address, you may be transferred to any place in the country
                    within the Company or to any of its associates, or sister
                    concerns, or its subsidiary at any place in{" "}
                    {` ${previewData.country.countryName} `} or abroad, whether
                    existing today or which may come up in future at any time at
                    the sole discretion of the Management.
                  </p> */}
                  <p>
                    <span>{t("place_transfer")}:</span>
                    {t("initial_posting_message", {
                      country: previewData.country.countryName,
                    })}
                  </p>

                  {/* <p>
                    <span> Duties</span> : A schedule of your broad duties and
                    responsibilities will be given to you in due course.
                    However, the company reserves the right to assign to you
                    such other duties and responsibilities as may be considered
                    advisable in the Company’s interests. In your capacity as
                    <span>
                      {previewData.position && previewData.position.position}
                    </span>{" "}
                    you will report to the{" "}
                    <span>{previewData.reportingHead.userName} </span>
                    or to any other officer deputed by him or her.
                  </p> */}

                  <p>
                    <span>{t("duties_title")}</span> :{" "}
                    {t("duties_message", {
                      position:
                        previewData.position && previewData.position.position,
                      reportingHead: previewData.reportingHead.userName,
                    })}
                  </p>

                  {/* <p>
                    <span>Salary structure</span>: As per the sheet enclosed at
                    a total CTC of Rs.
                    <span>
                      {`${previewData.ctc} (${convertNumberToWords(
                        previewData.ctc
                      )}) Only`}
                    </span>
                    per Year. Salary and other perquisites/benefits, which have
                    been distributed under the aforesaid heads, are subject to
                    taxes, as applicable. At its sole discretion, the Management
                    may consider either change of heads or redistribute the
                    total emoluments under various heads.
                  </p> */}
                  <p>
                    <span>{t("salary_structure")}</span>:{" "}
                    {t("salary_message", {
                      ctc: previewData.ctc,
                      ctcInWords: convertNumberToWords(previewData.ctc),
                    })}
                  </p>

                  {/* <p>
                    <span>Probation / Confirmation</span>:You will be on
                    probation for a period of {previewData.probationDay + " "}
                    days after joining work. After the expiry of the
                    probationary period it is open to the management either to
                    confirm your services or extend your probationary period.
                    The Management, however, reserves the right to terminate
                    your services without assigning any reason during the
                    probationary period, or the extended probationary period by
                    giving 24 hours’ notice. Should you decide to leave the
                    services of the Company during the period of your probation,
                    you will be required to give {previewData.noticePeriod}{" "}
                    days’ notice or {previewData.noticePeriod} days’ Gross
                    salary in lieu thereof.
                  </p> */}
                  <p>
                    <span>{t("probation_confirmation")}</span>:{" "}
                    {t("probation_message", {
                      probationDay: previewData.probationDay,
                      noticePeriod: previewData.noticePeriod,
                    })}
                  </p>

                  {/* <p>
                    <span>Absenteeism</span>: Absence for a continuous period of
                    {` ${previewData.absentDays}(${convertNumberToWords(
                      previewData.absentDays
                    )})`}{" "}
                    days without prior approval of your superior, (including
                    overstay on leave / training) or written intimation in case
                    of unforeseeable circumstances such as medical emergency by
                    itself will be proof of your voluntary abandonment of
                    services and would result in your losing your lien on the
                    service and the same (service) shall automatically come to
                    an end. Further it will be assumed that as you have left the
                    service on your own accord without any notice period served,
                    you will be liable to pay notice pay as per termination
                    clause mentioned below.
                  </p> */}
                  <p>
                    <span>{t("absenteeism")}</span>:{" "}
                    {t("absenteeism_message", {
                      absentDays: previewData.absentDays,
                      absentDaysWords: convertNumberToWords(
                        previewData.absentDays
                      ),
                    })}
                  </p>

                  {/* <p>
                    <span>
                      Code of Conduct / Standing Orders / Rules or Regulations
                    </span>
                    : You are expected to follow the HR Policies, Information
                    Security Policies, and all other company policies and abide
                    by the Company’s Code of Conduct and Disciplinary Code. You
                    will also abide by the Rules & Regulations / Standing Orders
                    of the Company in force, at present, and as varied from time
                    to time.
                  </p> */}
                  <p>
                    <span>{t("code_of_conduct")}</span>:{" "}
                    {t("code_of_conduct_message")}
                  </p>

                  {/* <p>
                    <span>Leave</span>: You will be eligible for the leaves as
                    per the Leave Rules of the company. For the number of leaves
                    please consult the Leave Policy or HR. However grant of
                    leave (except under medical emergency) will depend upon the
                    exigencies of work and shall be at the discretion of the
                    management. For availing leave, you will have to apply in
                    the prescribed form to the appropriate authority through the
                    system and seek approval at least
                    {` ${previewData.priorLeaveNoticeDays} `} days prior for
                    such leave. Similarly, for extension of leave, an
                    application will have to be made through the system before
                    the expiry of the leave originally sanctioned, which should
                    reach the company at least{" "}
                    {` ${previewData.priorLeaveNoticeDays} `} days prior to
                    expiry of leave. While making such application you will
                    state your address during the leave period. Mere submission
                    of application will not mean that the leave has been
                    sanctioned and unless leave is sanctioned or extended in
                    writing you will not be treated to be on leave.
                  </p> */}
                  <p>
                    <span>{t("leave")}</span>:{" "}
                    {t("leave_message", {
                      priorLeaveNoticeDays: previewData.priorLeaveNoticeDays,
                    })}
                  </p>

                  {/* <p>
                    <span>Traveling</span>: In the course of your employment,
                    you may be required to undertake traveling either in
                    {` ${previewData.country.countryName} `} or elsewhere in
                    connection with the Company’s business, according to the
                    directions and instructions issued by the Management, from
                    time to time, for which you will be reimbursed expenses in
                    accordance with the rules. Refusal to undertake such travel
                    may result in disciplinary action against you.
                  </p> */}
                  <p>
                    <span>{t("traveling")}</span>:{" "}
                    {t("traveling_message", {
                      countryName: previewData.country.countryName,
                    })}
                  </p>

                  {/* <p>
                    <span>Deputation</span>: You may be sent on deputation to
                    any other organization anywhere in the country which may be
                    under the same management or under different managements
                    (Existing or New). Refusal to undertake such deputation may
                    result in disciplinary action against you.
                  </p> */}
                  <p>
                    <span>{t("deputation")}</span>: {t("deputation_message")}
                  </p>

                  {/* <p>
                    <span>Medical examination & documentation:</span>
                    <ol style={{ listStyleType: "lower-alpha" }}>
                      <li>
                        Your appointment is subject to your producing a medical
                        report from a registered Medical Practitioner testifying
                        to your fitness for work.
                      </li>
                      <li>
                        Your Continuation in service will also be subject to
                        satisfactory verification of your
                        credentials/testimonials, etc. mentioned in your offer
                        letter.
                      </li>
                    </ol>
                  </p> */}
                  <p>
                    <span>{t("medical_examination")}</span>
                    <ol style={{ listStyleType: "lower-alpha" }}>
                      <li>{t("medical_report")}</li>
                      <li>{t("verification")}</li>
                    </ol>
                  </p>

                  {/* <p>
                    <span>Confidentiality</span>: You will keep all the
                    employer’s business secrets confidential at all times during
                    and after the term of your employment. The employer’s
                    business secrets include any information regarding the
                    employer’s customers, supplies, finances, research,
                    development, manufacturing processes, or any other technical
                    or business information.
                  </p> */}
                  <p>
                    <span>{t("confidentiality")}</span>:{" "}
                    {t("confidentiality_details")}
                  </p>

                  {/* <p>
                    You will not make any unauthorized copies of any of the
                    employer’s business secrets or information without the
                    employer’s consent, nor to remove any of the employer’s
                    business secrets or information from the employer’s
                    facilities.
                  </p> */}
                  <p>{t("unauthorized_copies_details")}</p>

                  {/* <p>
                    <span>
                      (The confidentiality clause is further elaborated in the
                      Non-Disclosure Agreement)
                    </span>
                  </p> */}
                  <p>
                    <span>{t("confidentiality_clause")}</span>
                  </p>

                  {/* <p>
                    <span> Termination</span>: In case your services are
                    terminated, you will be served with{" "}
                    {previewData.noticePeriod} days’ notice or given{" "}
                    {previewData.noticePeriod} days’ salary (gross salary) in
                    lieu of notice (notice pay). A similar notice or salary in
                    lieu of notice (notice pay) will have to be given by you in
                    case you wish to leave the services of the Company. In case
                    of misconduct or material breach of this agreement, the
                    Company may terminate this Agreement with immediate effect.
                    In such cases no compensation shall be payable in this
                    behalf, and any eligibility for consideration of any
                    incentives, bonus or other similar amounts shall not arise
                    and shall lapse.
                  </p> */}
                  <p>
                    <span>
                      {t("termination_clause", {
                        noticePeriod: previewData.noticePeriod,
                      })}
                    </span>
                  </p>

                  {/* <p>
                    <span>
                      Restrictive Covenants: Correspondence and address for
                      communication:
                    </span>
                  </p> */}
                  <p>
                    <span>{t("restrictive_covenants")}</span>
                  </p>

                  {/* <p>
                    <span>
                      Company will correspond with you directly or at the
                      address given below:
                    </span>
                  </p> */}
                  <p>
                    <span>{t("company_correspondence")}</span>
                  </p>

                  {/* <p>[ADDRESS FOR COMMUNICATION]</p> */}

                  <p>
                    <span>{t("address_in_cap")} </span>
                  </p>

                  <div
                    className="orgUserEmpInfo"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <div style={{ marginLeft: "50px" }}>
                      <div
                        className="loiGret adlinOne"
                        style={{
                          width: "100%",
                          fontWeight: "600",
                        }}
                      >{`${previewData.corAddress.cor_address1},`}</div>
                      <div
                        className="loiGret adlinTwo"
                        style={{
                          width: "100%",
                          fontWeight: "600",
                        }}
                      >
                        {`${previewData.corAddress.cor_address2},`}
                      </div>
                      <div
                        className="loiGret adlinThree"
                        style={{
                          width: "100%",
                          fontWeight: "600",
                        }}
                      >{`${previewData.corAddress.cor_city},${
                        previewData.corAddress.cor_state?.name || ""
                      }`}</div>
                      <div
                        className="loiGret adlinThree"
                        style={{
                          width: "100%",
                          fontWeight: "600",
                        }}
                      >{`${previewData.corAddress.cor_pin}, ${previewData.corAddress.cor_country.countryName}`}</div>
                    </div>
                  </div>

                  {/* <p>
                    In case there will be any change in your address, you will
                    intimate the Company in writing within
                    {" " + previewData.priorAddressChangeDays} days from the
                    date of such change.
                  </p> */}
                  <p>
                    {t("address_change_notification", {
                      days: previewData.priorAddressChangeDays,
                    })}
                  </p>

                  {/* <p>
                    Any letter sent by the Company by Registered A.D./Speed
                    Post/ Through Courier at the above address will be deemed as
                    proper service. Any Communication sent by e-mail or Fax at
                    the above e-mail address or fax number will be deemed as
                    proper service.
                  </p> */}
                  <p>{t("proper_service_notice")}</p>

                  <p>
                    <span>{t("otherTermsAndConditions")}:</span>
                    <ul>
                      {previewData.staticDodList &&
                        previewData.staticDodList.map((tac) => (
                          <li>{tac.name}</li>
                        ))}
                    </ul>
                  </p>
                  <p></p>
                  {/* <p>
                    Please sign and return to the undersigned the duplicate copy
                    of this letter signifying your acceptance and confirming
                    that the terms are correctly reproduced & understood.
                  </p> */}
                  <p>{t("sign_return_notice")}</p>

                  {/* <p>
                    We are happy to welcome you to our Organization and look
                    forward to a long and rewarding association. Please do not
                    hesitate to contact the Human Resources Department whenever
                    required, and the Department would be only too glad to
                    assist you.
                  </p> */}
                  <p>{t("welcome_message")}</p>
                </div>
                <div className="docReqArea"></div>

                <div className="loiFooter">
                  {/* <p>Look forward to working with you.Regards,</p> */}
                  <p>{loginUserDetails.userName}</p>
                  <p className="highText">{t("team_hr")}</p>
                  <p className="highText">{previewData.orgData.orgName}</p>
                </div>
              </>
            )}

            {pageId === 2 && (
              <>
                <table className="salaryTable">
                  <tbody>
                    <tr>
                      <th colSpan={4}>{t("remuneration_package")}</th>
                    </tr>
                    <tr>
                      <td colSpan={2}>{t("name")}</td>
                      <td
                        colSpan={2}
                      >{`${previewData.employeeTitle}. ${previewData.firstName} ${previewData.middleName} ${previewData.lastName}`}</td>
                    </tr>
                    <tr>
                      <td colSpan={2}>{t("position")}</td>
                      <td colSpan={2}>
                        {previewData.position && previewData.position.position}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>{t("department")}</td>
                      <td colSpan={2}>
                        {previewData.department &&
                          previewData.department.functionName}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>{t("company")}</td>
                      <td colSpan={2}>{previewData.orgData.orgName}</td>
                    </tr>
                    <tr>
                      <th colSpan={2}>{t("salary_head")}</th>
                      <th colSpan={1}>{t("per_month")}</th>
                      <th colSpan={1}>{t("per_annum")}</th>
                    </tr>
                    {/* {salaryInfo &&
                      salaryInfo.map((info) => {
                        return (
                          <>
                            <tr>
                              <th colSpan={2}>{info[0]}</th>
                              <th colSpan={1} className="tabAlignCent">
                                {info[1].monthlyTotal}
                              </th>
                              <th colSpan={1} className="tabAlignCent">
                                {parseFloat(info[1].anualTotal).toFixed(2)}
                              </th>
                            </tr>
                            {info[1].headList.map((head) => {
                              return (
                                <>
                                  <tr>
                                    <td colSpan={2}>{head.salaryHeadName}</td>
                                    <td colSpan={1} className="tabAlignCent">
                                      {previewData.mode
                                        ? head.monthly
                                        : head.amount}
                                    </td>
                                    <td colSpan={1} className="tabAlignCent">
                                      {previewData.mode
                                        ? head.yearly
                                        : head.annual}
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </>
                        );
                      })} */}

                    {previewData?.salaryInfoList?.map((item) => {
                      return (
                        <tr>
                          <td colSpan={2}>{item.componentName}</td>
                          <td colSpan={1} className="tabAlignCent">
                            {item.monthlyAmt}
                          </td>
                          <td colSpan={1} className="tabAlignCent">
                            {item.anualAmt}
                          </td>
                        </tr>
                      );
                    })}

                    <tr>
                      <td colSpan={4}> </td>
                    </tr>
                    <tr>
                      <th colSpan={2}>{t("total")}</th>
                      <th colSpan={1}>{monthlyTotal}</th>
                      <th colSpan={1}>{yearlyTotal}</th>
                    </tr>
                  </tbody>
                </table>
              </>
            )}

            <div className="modActionBtnGrp actionsCreateOrg">
              {pageId === 1 && (
                <>
                  <Button
                    className="dfultDarkSecondaryBtn"
                    onClick={handleClosePreviewApoinment}
                  >
                    {t("cancel")}
                  </Button>
                  <Button
                    className="dfultPrimaryBtn"
                    onClick={(e) => {
                      setPageId(2);
                    }}
                  >
                    {t("next_page")}
                  </Button>
                </>
              )}

              {pageId === 2 && (
                <>
                  <Button
                    className="dfultDarkSecondaryBtn"
                    onClick={(e) => {
                      setPageId(1);
                    }}
                  >
                    {t("prev_page")}
                  </Button>

                  <Button
                    className="dfultPrimaryBtn"
                    onClick={issueNewAppoinment}
                  >
                    {t("submit")}
                  </Button>
                </>
              )}
            </div>
          </div>
        </Box>
      </div>
    </>
  );
}
