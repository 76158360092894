import { AlarmOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  getMyEventsInDateRanges,
  getNextEvent,
} from "../services/SchedulerService";
import { GetLoginUserDetails } from "../utils/Utils";
import DateUtils from "../utils/DateUtils";
import { useTranslation } from "react-i18next";
const EventAlert = ({ viewEvent }) => {
  const loginUser = GetLoginUserDetails();
  const [eventAlertData, setEventAlertData] = useState(null);
  const [eventDto, setEventDto] = useState(null);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    try {
      const fetchData = async () => {
        await getNextEvent(loginUser.userId).then((response) => {
          console.log("EventAlert--->>>>>", response);
          if (response.data) {
            setEventDto(response.data);
            let timing = DateUtils.printEventDate(
              new Date(response.data.start),
              new Date(response.data.end)
            );
            setEventAlertData({
              header: "event_reminder",
              content: response.data.title,
              timing:
                i18n.language === "en" ? timing : convertMonthToSpanish(timing),
            });
          }
        });
      };
      fetchData();
    } catch (err) {}
  }, [i18n.language]);

  function convertMonthToSpanish(dateString) {
    const monthMap = {
      January: "enero",
      February: "febrero",
      March: "marzo",
      April: "abril",
      May: "mayo",
      June: "junio",
      July: "julio",
      August: "agosto",
      September: "septiembre",
      October: "octubre",
      November: "noviembre",
      December: "diciembre",
    };

    // Match the month in the input string and replace it with Spanish equivalent
    return dateString.replace(
      /(January|February|March|April|May|June|July|August|September|October|November|December)/g,
      (month) => monthMap[month]
    );
  }

  return (
    <>
      {null !== eventAlertData && (
        <div className="eventAlertWrapper">
          <div className="evnAltIcon">
            <AlarmOutlined />
          </div>
          <div className="evnDtlWTime">
            <span className="evdH">{t(`${eventAlertData.header}`)}, </span>
            <span className="evdT">{eventAlertData.content}, </span>
            <span className="evdTime">{eventAlertData.timing}</span>
          </div>
          <div className="eveAltBtn">
            <Button variant="outlined" onClick={() => viewEvent(eventDto)}>
              {t("view")}
            </Button>

            <Button variant="outlined" onClick={() => setEventAlertData(null)}>
              {t("dismiss")}
            </Button>
          </div>
          {/* <div className="eveAltBtn">
            <Button variant="outlined" onClick={() => setEventAlertData(null)}>
              Snooze
            </Button>
          </div> */}
        </div>
      )}
    </>
  );
};
export default EventAlert;
