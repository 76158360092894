import {
  AccessibleRounded,
  Add,
  ArrowBack,
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
  EmailRounded,
  LocalPhone,
  Reviews,
  Today,
} from "@mui/icons-material";
import { Button, CircularProgress, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Eye } from "react-bootstrap-icons";
import DashboardServices from "../../../../../services/DashboardServices";
import { jwtDecode } from "jwt-decode";
import DateUtils from "../../../../../utils/DateUtils";
import { useTranslation } from "react-i18next";
import Joyride from "react-joyride";
import { useContext } from "react";
import { TourContext } from "../../../../../common/TourProvider";
import { GetLoginUserDetails } from "../../../../../utils/Utils";

export default function ViewReviewPatient({
  handelCloseViewReviewPatient,
  closeAll,
  handleBookConsultationData,
}) {
  const userDetails = GetLoginUserDetails();
  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);
  const { t } = useTranslation();
  const [monthSelect, setMonthSelect] = useState(new Date());
  const [doctorSlotBookData, setDoctorSlotBookData] = useState([]);
  const [selectedDay, setSelectedDay] = useState(null);
  const [daySelect, setDaySelect] = useState(new Date());
  const [reviewPatientData, setReviewPatientData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setBookSteps([
      {
        target: ".reviewpatStepOne",
        content: "Go back to the previous page",
        placement: "top",
        disableBeacon: true,
      },
      {
        target: ".days-header-actionBtn > .MuiIconButton-root:first-child",
        content: "Go to the previous week",
        placement: "left",
        disableBeacon: true,
      },
      {
        target: ".days-header-actionBtn > .MuiIconButton-root:last-child",
        content: "Go to the next week",
        placement: "right",
        disableBeacon: true,
      },
      {
        target: ".reviewpatStepFour",
        content: "Select a specific day to view the data",
        placement: "bottom",
        disableBeacon: true,
      },
      {
        target: ".reviewpatStepFive",
        content: "Loading data",
        placement: "bottom",
        disableBeacon: true,
      },
      {
        target: ".reviewpatStepsix",
        content: "No data available for this day",
        placement: "bottom",
        disableBeacon: true,
      },
      {
        target: ".reviewpatStepseven",
        content: "Here is the table with patient data",
        placement: "top",
        disableBeacon: true,
      },
      {
        target: ".reviewpatStepEight",
        content: "Scroll to see more data",
        placement: "bottom",
        disableBeacon: true,
      },
      {
        target: ".reviewpatStepNine",
        content: "Process the patient data for Book consultation",
        placement: "bottom",
        disableBeacon: true,
      },
    ]);
  }, []);

  // const [selectedDate, setSelectedDate] = useState(new Date());

  // const currentDate = new Date();

  // const generateDateRange = () => {
  //   const dates = [];
  //   for (let i = -3; i <= 3; i++) {
  //     const date = new Date(currentDate);
  //     date.setDate(currentDate.getDate() + i);
  //     dates.push(date);
  //   }
  //   return dates;
  // };

  // const formatDate = (date) => {
  //   const options = { day: 'numeric', month: 'long' };
  //   const day = date.toLocaleDateString(undefined, options);
  //   const weekday = date.toLocaleDateString(undefined, { weekday: 'long' });
  //   return { day, weekday };
  // };

  // const isToday = (date) => {
  //   const today = new Date();
  //   return (
  //     date.getDate() === today.getDate() &&
  //     date.getMonth() === today.getMonth() &&
  //     date.getFullYear() === today.getFullYear()
  //   );
  // };

  // const isSelected = (date) => {
  //   return selectedDate && date.toDateString() === selectedDate.toDateString();
  // };

  // const dates = generateDateRange();

  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());

  const generateDateRange = () => {
    const dates = [];
    for (let i = -3; i <= 3; i++) {
      const date = new Date(currentDate);
      date.setDate(currentDate.getDate() + i);
      dates.push(date);
    }
    return dates;
  };

  // const formatDate = (date) => {
  //   const options = { day: "numeric", month: "long" };
  //   const day = date.toLocaleDateString(undefined, options);
  //   const weekday = date.toLocaleDateString(undefined, { weekday: "long" });
  //   return { day, weekday };
  // };

  const formatDate = (date) => {
    const options = { day: "numeric" };
    const day = date.toLocaleDateString(undefined, options);

    // Get month and weekday using translations
    const month = t(`${date.toLocaleDateString(undefined, { month: "long" })}`);
    const weekday = t(
      `${date.toLocaleDateString(undefined, { weekday: "long" })}`
    );

    return { day, month, weekday };
  };

  const isSelected = (date) => {
    return selectedDate && date.toDateString() === selectedDate.toDateString();
  };

  const handleBack = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() - 7);
    setCurrentDate(newDate);
    setSelectedDate(newDate);
  };

  const handleForward = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() + 7);
    setCurrentDate(newDate);
    setSelectedDate(newDate);
  };

  const dates = generateDateRange();

  useEffect(() => {
    fetchReviewData();
  }, [userDetails.signleUnitId, selectedDate]);

  const fetchReviewData = () => {
    setLoading(true);

    const reviewDate = DateUtils.getDateInDDMMYYYY(selectedDate);
    DashboardServices.getReviewPatients(
      userDetails.signleUnitId,
      reviewDate
    ).then((response) => {
      console.log("response getReviewPatients", response.data);
      setReviewPatientData(response.data || []);
      setLoading(false);
    });
  };

  const formatSelectedDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["viewReviewPatns"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        styles={{
          buttonClose: {
            display: "none",
          },
        }}
      />
      <div className="AnalyticsDashboardContainer nsReviewPatstepsOne">
        <div className="AnalyticsDashboardSubHeader reviewpatStepOne">
          <IconButton
            onClick={() => {
              handelCloseViewReviewPatient();
              closeAll();
            }}
          >
            <ArrowBack />
          </IconButton>
          {t("review_patients")}
        </div>
        <div className="anDashCardContainArea">
          <div className="fullContainArea">
            <div className="days-header-actionBtn">
              <IconButton onClick={handleBack}>
                <ArrowBackIosRounded />
              </IconButton>

              {/* <div className="days-header">
                {doctorSlotBookData.map((day) => {
                  let dayClass = "day-box";

                  if (day.slotDate === selectedDay) {
                    dayClass += " active";
                  }

                  return (
                    <div
                      key={day.slotDate}
                      className={dayClass}
                      // onClick={() => setSelectedDay(day.slotDate)}
                      onClick={()=>handleSlotDate(day.slotDate)}
                    >
                      <div className="daysDate">
                        <div className="ddHighTxt">{day.slotDate}</div>
                        <div className="ddSubTxt">{day.dayName}</div>
                      </div>
                    </div>
                  );
                })}
              </div> */}

              <div className="days-header reviewpatStepFour">
                {dates.map((date, index) => {
                  const { day, weekday, month } = formatDate(date);
                  const activeClass = isSelected(date) ? "active" : "";

                  return (
                    <div
                      key={index}
                      className={`day-box ${activeClass}`}
                      onClick={() => setSelectedDate(date)}
                    >
                      <div className="daysDate">
                        <div className="ddHighTxt">
                          {t(month)} {day}
                        </div>
                        <div className="ddSubTxt">{t(weekday)}</div>
                      </div>
                    </div>
                  );
                })}
              </div>

              {/* <div className="days-header">
                <div className="day-box">
                  <div className="daysDate">
                    <div className="ddHighTxt">4 September</div>
                    <div className="ddSubTxt">Wednesday</div>
                  </div>
                </div>
                <div className="day-box">
                  <div className="daysDate">
                    <div className="ddHighTxt">5 September</div>
                    <div className="ddSubTxt">Thursday</div>
                  </div>
                </div>
                <div className="day-box">
                  <div className="daysDate">
                    <div className="ddHighTxt">6 September</div>
                    <div className="ddSubTxt">Friday</div>
                  </div>
                </div>
                <div className="day-box active">
                  <div className="daysDate">
                    <div className="ddHighTxt">7 September</div>
                    <div className="ddSubTxt">Saturday</div>
                  </div>
                </div>
                <div className="day-box">
                  <div className="daysDate">
                    <div className="ddHighTxt">8 September</div>
                    <div className="ddSubTxt">Sunday</div>
                  </div>
                </div>
                <div className="day-box">
                  <div className="daysDate">
                    <div className="ddHighTxt">9 September</div>
                    <div className="ddSubTxt">Monday</div>
                  </div>
                </div>
                <div className="day-box">
                  <div className="daysDate">
                    <div className="ddHighTxt">10 September</div>
                    <div className="ddSubTxt">Tuesday</div>
                  </div>
                </div>
              </div> */}
              <IconButton onClick={handleForward}>
                <ArrowForwardIosRounded />
              </IconButton>
            </div>

            <div className="cuContainArea">
              {/* <div className="appoinmentBookingSlot">
                <div className="slots-container reviewPatList">
                  <div className="doctorListSlot">
                    <div className="doctorDetails">
                      <div className="doctorInfoWthImg">
                        <div className="docImage">
                          <img
                            src="https://plus.unsplash.com/premium_photo-1671656349322-41de944d259b?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D"
                            alt="zoyel_doctor"
                          />
                        </div>
                        <div className="doctorFullInfo">
                          <div className="doctorNmSpc">
                            <div className="docName">Sanket Santra</div>
                            <div className="docSpclty">(Male / 33Y 6M 20D)</div>
                          </div>
                          <div className="docExpFeeLan">
                            <div className="docEFL">
                              <div className="eflicon">
                                <LocalPhone />
                              </div>
                              <div className="eflText">+91 7384979442</div>
                            </div>
                            <div className="docEFL">
                              <div className="eflicon">
                                <EmailRounded />
                              </div>
                              <div className="eflText">
                                uiux-designer@miniontek.com
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="patAddQueuGrp">
                        <div className="doctorRating">
                          <div className="docRatCount">Review Date</div>
                          <div className="docRatvalue">
                            <Today />
                            <span className="ratingvalue">7 Sep 2024</span>
                          </div>
                        </div>
                        <Button
                          className="dfultPrimaryBtn  "
                          startIcon={<Add />}
                        >
                          Queue
                        </Button>
                      </div>
                    </div>
                    <div className="consultationDetails">
                      <div className="constDtlInd">
                        <div className="costDocImg">
                          <img
                            src="https://plus.unsplash.com/premium_photo-1661551577028-80cfb8e4d236?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDV8fGRvY3RvcnxlbnwwfHwwfHx8MA%3D%3D"
                            alt="doc_name"
                          />
                        </div>
                        <div className="costDocInfo">
                          <div className="costDNM">
                            Dr. Sk Aftabuddin<span>(General Medicine)</span>
                            <span>
                              <Reviews />
                            </span>
                          </div>
                          <div className="costDt">
                            Consultation Date :<span>1 Sep 2024</span>
                          </div>
                          <div className="costDNote">
                            Notes :
                            <span>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer.
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="constDtlInd">
                        <div className="costDocImg">
                          <img
                            src="https://images.unsplash.com/photo-1651008376811-b90baee60c1f?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZG9jdG9yfGVufDB8fDB8fHww"
                            alt="doc_name"
                          />
                        </div>
                        <div className="costDocInfo">
                          <div className="costDNM">
                            Dr. Aiyasha Hassan<span>(Dermatology)</span>
                          </div>
                          <div className="costDt">
                            Last Consultation Date :<span>3 Sep 2024</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="TableContainer">
                {loading ? (
                  <div
                    className="center-progress reviewpatStepFive"
                    style={{ height: "65vh" }}
                  >
                    <CircularProgress sx={{ marginTop: "180px" }} />
                  </div>
                ) : reviewPatientData.length === 0 ? (
                  <div class="noDataCard reviewpatStepsix">
                    {/* <span>{t("oops")}</span>{t("no_employee_define_yet")} */}
                    <br />
                    <span className="sallMsg">
                      {t("no_patient_review_data_found")}
                    </span>
                  </div>
                ) : (
                  <table className="taskListTable reviewpatStepseven">
                    <thead className="taskListtableHeader">
                      <tr>
                        <th>{t("patient_name")}</th>
                        <th>{t("contact_info")}</th>
                        <th>{t("review_date")}</th>
                        <th>{t("doctor_info")}</th>
                        <th>{t("consulted_on")}</th>
                        <th>{t("notes")}</th>
                        <th>{t("last_consulted_with")}</th>
                        <th>{t("last_consulted_date")}</th>

                        <th className="width100"></th>
                      </tr>
                    </thead>
                    <tbody className="scrolableTableBody reviewpatStepEight">
                      {reviewPatientData.map((patient, index) => (
                        <tr key={index}>
                          <td>
                            <div className="tskLstTaskNM fdcolTxt">
                              <span className="headTxt">
                                {patient.patientName}
                              </span>
                              <span className="subheadTxt">
                                {patient.patientExternalSourceId
                                  ? patient.patientExternalSourceId
                                  : patient.patientIdDisplay}
                              </span>
                              <span className="subheadTxt">
                                (
                                {patient?.patientGender === "Male"
                                  ? t("male")
                                  : patient?.patientGender === "Female"
                                  ? t("female")
                                  : t("other")}
                                / {patient.patientAge})
                              </span>
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM fdcolTxt">
                              <span className="subheadTxt">
                                {patient.patientContactNo}
                              </span>
                              {/* <span className="subheadTxt">
                            uiux-designer@miniontek.com
                          </span> */}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {formatSelectedDate(selectedDate)}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM fdcolTxt">
                              <span className="headTxt">
                                {patient.doctorName}
                              </span>
                              <span className="subheadTxt">
                                {patient.doctorSpecialization}
                              </span>
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {patient.consultDate}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {patient.doctorNote || t("no_notes_available")}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM fdcolTxt">
                              <span className="headTxt">
                                {patient.nextConsultDoctorName}
                              </span>
                              <span className="subheadTxt">
                                {patient.nextConsultDoctorSpecialization}
                              </span>
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {patient.nextConsultDate}
                            </div>
                          </td>
                          <td>
                            <div className="tblActionBtnGrp reviewpatStepNine">
                              <Button
                                startIcon={<Add />}
                                className="dfultPrimaryBtn"
                                onClick={() => {
                                  handleBookConsultationData(patient);
                                  if (run["viewReviewPatns"] === true) {
                                    setRun({
                                      addReviewPatns: true,
                                    });
                                  }
                                }}
                              >
                                {t("process")}
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}

                      {/* <tr>
                      <td>
                        <div className="tskLstTaskNM fdcolTxt">
                          <span className="headTxt">John Doe</span>
                          <span className="subheadTxt">Male / 45Y 2M 10D</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM fdcolTxt">
                          <span className="subheadTxt">987654XXX1</span>
                          <span className="subheadTxt">
                            john.doe@domain.com
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">8-Sep-2024</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM fdcolTxt">
                          <span className="headTxt">Dr Jane Smith</span>
                          <span className="subheadTxt">Cardiology</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">1-Sep-2024</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          Lorem ipsum dolor sit amet consectetur, adipisicing
                          elit.
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM fdcolTxt">
                          <span className="headTxt"></span>
                          <span className="subheadTxt"></span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM"></div>
                      </td>
                      <td>
                        <div className="tblActionBtnGrp">
                          <Button
                            startIcon={<Add />}
                            className="dfultPrimaryBtn"
                          >
                            Process
                          </Button>
                        </div>
                      </td>
                    </tr> */}

                      {/* <tr>
                      <td>
                        <div className="tskLstTaskNM fdcolTxt">
                          <span className="headTxt">Alice Williams</span>
                          <span className="subheadTxt">
                            Female / 29Y 11M 5D
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM fdcolTxt">
                          <span className="subheadTxt">564738XXX9</span>
                          <span className="subheadTxt">
                            alice.williams@company.com
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">7-Sep-2024</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM fdcolTxt">
                          <span className="headTxt">Dr Robert Brown</span>
                          <span className="subheadTxt">Orthopedics</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">31-Aug-2024</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          Lorem ipsum dolor sit amet consectetur, adipisicing
                          elit.
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM fdcolTxt">
                          <span className="headTxt"></span>
                          <span className="subheadTxt"></span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM"></div>
                      </td>
                      <td>
                        <div className="tblActionBtnGrp">
                          <Button
                            startIcon={<Add />}
                            className="dfultPrimaryBtn"
                          >
                            Process
                          </Button>
                        </div>
                      </td>
                    </tr> */}

                      {/* <tr>
                      <td>
                        <div className="tskLstTaskNM fdcolTxt">
                          <span className="headTxt">Michael Lee</span>
                          <span className="subheadTxt">Male / 38Y 7M 12D</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM fdcolTxt">
                          <span className="subheadTxt">675849XXX3</span>
                          <span className="subheadTxt">
                            michael.lee@webmail.com
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">6-Sep-2024</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM fdcolTxt">
                          <span className="headTxt">Dr Sarah Johnson</span>
                          <span className="subheadTxt">Dermatology</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">30-Aug-2024</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          Lorem ipsum dolor sit amet consectetur, adipisicing
                          elit.
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM fdcolTxt">
                          <span className="headTxt"></span>
                          <span className="subheadTxt"></span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM"></div>
                      </td>
                      <td>
                        <div className="tblActionBtnGrp">
                          <Button
                            startIcon={<Add />}
                            className="dfultPrimaryBtn"
                          >
                            Process
                          </Button>
                        </div>
                      </td>
                    </tr> */}

                      {/* <tr>
                      <td>
                        <div className="tskLstTaskNM fdcolTxt">
                          <span className="headTxt">Emily Taylor</span>
                          <span className="subheadTxt">Female / 40Y 3M 9D</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM fdcolTxt">
                          <span className="subheadTxt">948573XXX8</span>
                          <span className="subheadTxt">
                            emily.taylor@service.org
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">5-Sep-2024</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM fdcolTxt">
                          <span className="headTxt">Dr Mark Davis</span>
                          <span className="subheadTxt">Pediatrics</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">29-Aug-2024</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          Lorem ipsum dolor sit amet consectetur, adipisicing
                          elit.
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM fdcolTxt">
                          <span className="headTxt"></span>
                          <span className="subheadTxt"></span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM"></div>
                      </td>
                      <td>
                        <div className="tblActionBtnGrp">
                          <Button
                            startIcon={<Add />}
                            className="dfultPrimaryBtn"
                          >
                            Process
                          </Button>
                        </div>
                      </td>
                    </tr> */}

                      {/* <tr>
                      <td>
                        <div className="tskLstTaskNM fdcolTxt">
                          <span className="headTxt">David Wilson</span>
                          <span className="subheadTxt">Male / 55Y 1M 17D</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM fdcolTxt">
                          <span className="subheadTxt">183948XXX5</span>
                          <span className="subheadTxt">
                            david.wilson@techworld.com
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">4-Sep-2024</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM fdcolTxt">
                          <span className="headTxt">Dr Nancy Thomas</span>
                          <span className="subheadTxt">Oncology</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">28-Aug-2024</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          Lorem ipsum dolor sit amet consectetur, adipisicing
                          elit.
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM fdcolTxt">
                          <span className="headTxt"></span>
                          <span className="subheadTxt"></span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM"></div>
                      </td>
                      <td>
                        <div className="tblActionBtnGrp">
                          <Button
                            startIcon={<Add />}
                            className="dfultPrimaryBtn"
                          >
                            Process
                          </Button>
                        </div>
                      </td>
                    </tr> */}

                      {/* <tr>
                      <td>
                        <div className="tskLstTaskNM fdcolTxt">
                          <span className="headTxt">Sophia Hernandez</span>
                          <span className="subheadTxt">
                            Female / 60Y 5M 22D
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM fdcolTxt">
                          <span className="subheadTxt">172839XXX6</span>
                          <span className="subheadTxt">
                            sophia.hernandez@corporate.com
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">3-Sep-2024</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM fdcolTxt">
                          <span className="headTxt">Dr Karen Miller</span>
                          <span className="subheadTxt">Neurology</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">27-Aug-2024</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          Lorem ipsum dolor sit amet consectetur, adipisicing
                          elit.
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM fdcolTxt">
                          <span className="headTxt"></span>
                          <span className="subheadTxt"></span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM"></div>
                      </td>
                      <td>
                        <div className="tblActionBtnGrp">
                          <Button
                            startIcon={<Add />}
                            className="dfultPrimaryBtn"
                          >
                            Process
                          </Button>
                        </div>
                      </td>
                    </tr> */}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
