import { Add, ExpandMoreRounded } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Trash } from "react-bootstrap-icons";
// import ReactDatePicker from "react-datepicker";
import { enIN, es } from "date-fns/locale";
import dayjs from "dayjs";
import { Microphone } from "iconoir-react";
import { ConfirmProvider } from "material-ui-confirm";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import RecordAudio from "../../../../../../../common/RecordAudio";
import DashboardServices from "../../../../../../../services/DashboardServices";
import AppUtils from "../../../../../../../utils/AppUtils";
import DateUtils from "../../../../../../../utils/DateUtils";
import SecureIndexedDB from "../../../../../../../utils/IndexedDB";
import useAudio from "../../../../../../../utils/useAudio";
import {
  getFilteredList,
  getHealthDto,
  MAX_SUGGESTED_RESULT,
} from "./consultationUtil";

// Register locales with ReactDatePicker
registerLocale("en", enIN);
registerLocale("es", es);

const ITEM_HEIGHT = 38;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function OtherOptions({ updateData, data, extraData }) {
  const { t, i18n } = useTranslation();
  const [accordionExpanded, setAccordionExpanded] = useState(true);
  const { tempAudioChunk, getAudioBase64, setAudioChunk } = useAudio();
  // Determine the locale based on the current language
  const currentLocale = i18n.language === "es" ? "es" : "en";

  const [parameterMonitor, setParameterMonitor] = useState([]);
  const [hospitalization, setHospitalization] = useState("NO");
  const [surgeryDate, setSurgeryDate] = useState(null);
  // const [reviewDate, setReviewDate] = useState(new Date(new Date().getDate() + 7));
  const [reviewDate, setReviewDate] = useState(
    new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
  );
  const [nextReview, setNextReview] = useState(true);
  const [referPatient, setReferPatient] = useState("");
  const [referSpeciality, setReferSpeciality] = useState(null);

  const [procedureList, setProcedureList] = useState([]);
  const [selectedProcedure, setSelectedProcedure] = useState(null);
  const [userProcedureList, setUserProcedureList] = useState([]);
  const [procedureString, setProcedureString] = useState("");
  const tempAudioChunkRef = useRef([]);
  const [recordingLength, setRecordingLength] = useState(0);
  const [recordingLink, setRecordingLink] = useState();
  const [showRecAudio, setShowRecAudio] = useState(false);
  const [vitalUnitList, setVitalUnitList] = useState([]);

  // const getAudioValue = async () => {
  //   const tmp = await getAudioBase64();
  //   return tmp;
  // };

  useEffect(() => {
    // if (recordingLink) {
    // }
    if (tempAudioChunk?.current?.length > 0) {
      updateData({ audioData: recordingLink });
    } else {
      updateData({ audioData: null });
    }
  }, [recordingLink, tempAudioChunk.current]);

  const handleShowRecAudioClose = () => {
    if (tempAudioChunkRef.current && tempAudioChunkRef.current.length > 0) {
      getSingleBlob();
    }
    setShowRecAudio(false);
  };

  const getSingleBlob = () => {
    // const promises = tempAudioChunkRef.current.map((blob) =>
    //   blob.arrayBuffer()
    // );
    const promises = [];

    for (const blob of tempAudioChunkRef.current) {
      const promise = blob.arrayBuffer();
      promises.push(promise);
    }

    Promise.all(promises)
      .then((arrayBuffers) => {
        const concatenatedArrayBuffer = arrayBuffers.reduce((acc, buffer) => {
          const totalLength = acc.byteLength + buffer.byteLength;
          const combined = new Uint8Array(totalLength);
          combined.set(new Uint8Array(acc), 0);
          combined.set(new Uint8Array(buffer), acc.byteLength);
          return combined.buffer;
        }, new ArrayBuffer(0));

        const combinedBlob = new Blob([concatenatedArrayBuffer], {
          type: "audio/wav",
        });
        tempAudioChunkRef.current = [combinedBlob];
      })
      .catch((error) => {
        console.error("Error occurred while combining Blobs:", error);
      });
  };

  // const [specializationString, setSpecializationString] = useState("");
  const [hospitalString, setHospitalString] = useState("");

  const [monitorNames, setMonitorNames] = useState([]);
  const [doctorName, setDoctorName] = useState("");
  const [doctorNote, setDoctorNote] = useState("");
  const [selectedHospital, setSelectedHospital] = useState(null);

  const [specilizationList, setSpecializationList] = useState([]);
  const [hospitalList, setHospitalizationList] = useState();
  const [referralNote, setReferralNote] = useState(null);
  const [watchoutFor, setWatchoutFor] = useState("");

  const initializeForm = () => {
    setSelectedProcedure(null);
    setProcedureString("");
    setSurgeryDate(null);
  };

  const handleChangeReferPatient = (event) => {
    const selectedValue = event.target.value;
    let tempHospital = selectedHospital;
    let tempSpeciality = referSpeciality;
    let tempReferral = referralNote;
    if (selectedValue) {
      setNextReview(false);
      setReviewDate(null);
    }
    if (
      selectedValue === "higherFacility" ||
      selectedValue === "immediateHospitalization"
    ) {
      if (
        referPatient !== "higherFacility" ||
        referPatient !== "immediateHospitalization"
      ) {
        setSelectedHospital(null);
        setHospitalString("");

        tempHospital = null;
      }
      setReferSpeciality(null);
      tempSpeciality = null;
      setReferralNote(selectedValue);
      tempReferral = selectedValue;
    } else if (selectedValue === "otherSpeciality") {
      setSelectedHospital(null);
      setHospitalString("");
      tempHospital = null;
      setReferSpeciality(null);
      tempSpeciality = null;
      setReferralNote(selectedValue);
      tempReferral = selectedValue;
    } else {
      setSelectedHospital("");
      setHospitalString("");
      tempHospital = null;
      setReferSpeciality("");
      tempSpeciality = null;
      setReferralNote(selectedValue);
      tempReferral = selectedValue;
    }
    setReferPatient(selectedValue);
    updateData({
      referType: selectedValue,
      reviewDate: null,
      hospital: tempHospital,
      referDoctorSpeciality: tempSpeciality,
      referralNote: tempReferral,
    });
  };

  //
  //will be used later
  const getReferStringFromType = (refer) => {
    switch (refer) {
      case "higherFacility":
        return t("advise_higher_facility");

      case "immediateHospitalization":
        return t("advise_immediate_hospitalization");

      case "otherSpeciality":
        return t("refer_to_other_speciality");

      case "medicoLegal":
        return t("refer_to_medico_legal");

      default:
        return "";
    }
  };

  const suggestedProcedure = useMemo(() => {
    console.log("procedurelist =", procedureList);
    const filteredList = getFilteredList(
      procedureList,
      procedureString,
      "procedureName"
    );
    const { queuePatientGender } = extraData;
    const filteredMaleFemale = filteredList?.filter((procedure) => {
      if (queuePatientGender === "Male") {
        return procedure.gender === "M" || procedure.gender === "C";
      } else if (queuePatientGender === "Female") {
        return procedure.gender === "F" || procedure.gender === "C";
      } else {
        return procedure.gender === "C";
      }
    });

    return filteredMaleFemale.slice(0, MAX_SUGGESTED_RESULT);
  }, [procedureList, procedureString]);

  const getProcedures = async () => {
    const lifeStyleString = await SecureIndexedDB.getItem("procedureList");
    if (lifeStyleString) {
      setProcedureList(JSON.parse(lifeStyleString));
      return;
    }
    const reqDto = await getHealthDto();
    DashboardServices.getProcedures(reqDto).then((response) => {
      if (response.data) {
        setProcedureList(response.data);
        SecureIndexedDB.setItem("procedureList", JSON.stringify(response.data));
      }
    });
  };

  const getSpecializations = async () => {
    const lifeStyleString = await SecureIndexedDB.getItem("specializationList");
    if (lifeStyleString) {
      setSpecializationList(JSON.parse(lifeStyleString));
      return;
    }
    const reqDto = await getHealthDto();
    DashboardServices.getSpecializations(reqDto).then((response) => {
      if (response.data) {
        setSpecializationList(response.data || []);
        SecureIndexedDB.setItem(
          "specializationList",
          JSON.stringify(response.data)
        );
      }
    });
  };

  const getHospitalsOfOrg = async () => {
    const lifeStyleString = await SecureIndexedDB.getItem("hospitalList");
    if (lifeStyleString) {
      setHospitalizationList(JSON.parse(lifeStyleString));
      return;
    }
    const reqDto = await getHealthDto();
    DashboardServices.getHospitalsOfOrg(reqDto.orgId).then((response) => {
      if (response.data) {
        setHospitalizationList(response.data);
        SecureIndexedDB.setItem("hospitalList", JSON.stringify(response.data));
      }
    });
  };

  const getVitalUnits = async () => {
    const diseasetring = await SecureIndexedDB.getItem("vitalUnitList");
    let tempVitalUnitlist = [];
    if (diseasetring) {
      tempVitalUnitlist = JSON.parse(diseasetring);
      setVitalUnitList(tempVitalUnitlist);
      return tempVitalUnitlist;
    }

    const reqDto = await getHealthDto();
    tempVitalUnitlist = await DashboardServices.getVitalUnits(reqDto).then(
      (response) => {
        if (response.data) {
          setVitalUnitList(response.data);
          SecureIndexedDB.setItem(
            "vitalUnitList",
            JSON.stringify(response.data)
          );
        }
      }
    );
    return tempVitalUnitlist;
  };

  const computeMonitors = async () => {
    let investigationList = [];
    let vitalsList = [];
    if (data.investigation) {
      investigationList = data.investigation.map((item) => item.testName);
    }
    let unitVitalList = [];
    if (vitalUnitList?.length === 0) {
      unitVitalList = await getVitalUnits();
    } else {
      unitVitalList = vitalUnitList;
    }

    const vitalNameMaps =
      unitVitalList?.reduce((acc, curr) => {
        const temp = { ...acc };
        const { vitalCode, vitalName } = curr;

        if (temp[vitalCode]) {
          return temp;
        } else {
          temp[vitalCode] = vitalName;
          return temp;
        }
      }, {}) || {};

    // const vitalNameMaps = {
    //   TEMPERATURE: t("temperature"),
    //   BP: t("blood_pressure"),
    //   HEART_RATE: t("heart_rate"),
    //   RESPIRATION_RATE: t("respiration_rate"),
    //   SPO2: t("spo2"),
    // };
    const vitalNames = [
      "TEMPERATURE",
      "BP",
      "HEART_RATE",
      "RESPIRATION_RATE",
      "SPO2",
    ];

    vitalsList = vitalNames.map((item) => vitalNameMaps[item]);

    setMonitorNames([...vitalsList, ...investigationList]);
  };

  useEffect(() => {
    computeMonitors();
  }, [data.investigation, extraData]);

  useEffect(() => {
    computeMonitors();

    getProcedures();
    getSpecializations();
    getHospitalsOfOrg();
  }, []);

  useEffect(() => {
    if (data && data.itemToMonitor) {
      setParameterMonitor(data.itemToMonitor);
    }

    //////////////////////////////////////
    if (data && data.procedure) {
      setUserProcedureList(data.procedure || []);
    }
    if (data) {
      setReferPatient(data.referType || null);
      setNextReview(false);
      // initializeRefererType();
    }

    if (data) {
      setReferSpeciality(data.referDoctorSpeciality);
      setDoctorNote(data.doctorNote || "");
      setSelectedHospital(data?.hospital);
      setDoctorName(data?.doctorName);
      setReferralNote(data?.referralNote);
    }

    if (data.watchoutFor) {
      setWatchoutFor(data.watchoutFor);
    }

    // if (data.audioRaw) {
    //   setAudioChunk(data.audioRaw);
    // }

    if (data?.reviewDate) {
      setNextReview(true);
      setReviewDate(DateUtils.convertToDate(data?.reviewDate));
    } else {
      setNextReview(false);
      setReviewDate(null);
    }
  }, [data]);

  const handleChangeparameterMonitor = (event) => {
    const {
      target: { value },
    } = event;
    setParameterMonitor(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    updateData({
      itemToMonitor: typeof value === "string" ? value.split(",") : value,
    });
  };

  return (
    <>
      <Accordion
        className="docInputElmntAcod"
        expanded={accordionExpanded}
        onChange={() => {
          setAccordionExpanded((prev) => !prev);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreRounded />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="acodHeader"
        >
          <div className="tskElemHeding">
            {t("others_options")}:
            <span>{/*symptoms gose here when acordion not expend*/}</span>
          </div>
        </AccordionSummary>
        <AccordionDetails className="acodDetails">
          {(extraData?.isDoctor || parameterMonitor?.length > 0) && (
            <>
              <div className="tskElemHeding ">
                {t("parameter_to_be_monitored")}
              </div>
              {extraData?.isDoctor && (
                <div className="formElement ">
                  <FormControl className="formControl">
                    <InputLabel id="ParameterMonitor">
                      {t("select_parameters")}
                    </InputLabel>
                    <Select
                      labelId="ParameterMonitor"
                      id="Parameter-Monitor"
                      multiple
                      value={parameterMonitor}
                      onChange={handleChangeparameterMonitor}
                      input={<OutlinedInput label={t("select_parameters")} />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                      className="formInputField"
                      variant="outlined"
                      disabled={!extraData.isDoctor}
                    >
                      {monitorNames.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox
                            checked={parameterMonitor.indexOf(name) > -1}
                          />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )}
              {!extraData?.isDoctor && (
                <div className="tskElemntInfo">
                  {parameterMonitor?.join(",")}
                </div>
              )}

              <div className="bb"></div>
            </>
          )}

          {(extraData?.isDoctor || doctorNote) && (
            <>
              <div className="tskElemHeding ">{t("doctor_note")}</div>

              {extraData?.isDoctor && (
                <div className="formElement mt10">
                  <FormControl className="formControl">
                    <TextField
                      label={t("doctor_note")}
                      variant="outlined"
                      className="descptionTextFild"
                      multiline
                      rows={4}
                      maxRows={6}
                      value={doctorNote}
                      onChange={(e) => {
                        setDoctorNote(e.target.value);
                        updateData({ doctorNote: e.target.value });
                      }}
                      disabled={!extraData?.isDoctor}
                    ></TextField>
                    {/* <Tooltip
                      className="audioNoteTooltip"
                      title={t("record_audio_note")}
                      arrow
                      placement="bottom"
                      style={{ right: "-15px" }}
                    >
                      <IconButton
                        onClick={() => setShowRecAudio(true)}
                        className={
                          tempAudioChunk.current.length > 0
                            ? `audioNote activeStatus`
                            : "audioNote"
                        }
                      >
                        <Microphone />
                      </IconButton>
                            
                    </Tooltip> */}
                    <Tooltip
                      className="audioNoteTooltip"
                      title={
                        recordingLength > 0
                          ? t("audio_note_recorded")
                          : t("record_audio_note")
                      }
                      arrow
                      placement="bottom"
                    >
                      <IconButton
                        onClick={() => setShowRecAudio(true)}
                        className={
                          recordingLength > 0
                            ? `audioNote activeStatus`
                            : "audioNote"
                        }
                      >
                        <Microphone />
                      </IconButton>
                    </Tooltip>
                  </FormControl>
                </div>
              )}

              {showRecAudio && (
                <>
                  <Modal
                    open={showRecAudio}
                    onClose={() => handleShowRecAudioClose()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="coustomModel"
                  >
                    <Box
                      className="createEvent responsiveModelAdmin"
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        maxHeight: "70vh",
                        width: "400px",
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 4,
                      }}
                    >
                      <ConfirmProvider>
                        <RecordAudio
                          closeDialog={async () => {
                            handleShowRecAudioClose();
                            updateData({ audioRaw: tempAudioChunk });
                          }}
                          // tempAudioChunk={tempAudioChunkRef}
                          tempAudioChunk={tempAudioChunk}
                          recordingLength={recordingLength}
                          setRecordingLength={setRecordingLength}
                          recordingLink={recordingLink}
                          setRecordingLink={setRecordingLink}
                        />
                      </ConfirmProvider>
                    </Box>
                  </Modal>
                </>
              )}

              {!extraData?.isDoctor && (
                <div className="tskElemntInfo">{doctorNote}</div>
              )}
              <div className="bb"></div>
            </>
          )}

          {(extraData?.isDoctor || doctorNote) && (
            <>
              <div className="tskElemHeding ">{t("watch_out_for")}</div>

              {extraData?.isDoctor && (
                <div className="formElement mt10">
                  <FormControl className="formControl">
                    <TextField
                      label={t("add_watch_out_for")}
                      variant="outlined"
                      className="descptionTextFild"
                      multiline
                      rows={4}
                      maxRows={6}
                      value={watchoutFor}
                      onChange={(e) => {
                        setWatchoutFor(e.target.value);
                        updateData({ watchoutFor: e.target.value });
                      }}
                      disabled={!extraData?.isDoctor}
                    ></TextField>
                  </FormControl>
                </div>
              )}

              {!extraData?.isDoctor && watchoutFor && (
                <div className="tskElemntInfo">{watchoutFor}</div>
              )}
              <div className="bb"></div>
            </>
          )}

          {(extraData?.isDoctor || userProcedureList?.length > 0) && (
            <>
              <div className="tskElemHeding mt10 ">
                {t("procedure_surgery")}
              </div>
              <div className="complaintList  ">
                <ul className=" pastIllness">
                  {userProcedureList &&
                    userProcedureList.map((item, index) => {
                      return (
                        <li>
                          <div className="valuDateRow complstDtlGrp">
                            <div className="piValu">
                              {item.procedure.procedureName}
                            </div>
                            <div className="d-flex">
                              <div className="piDate">
                                {dayjs(item.date).format("DD-MMM-YYYY")}
                              </div>
                              {extraData?.isDoctor && (
                                <IconButton
                                  className="removeItemBtn"
                                  onClick={() => {
                                    const temp = [...userProcedureList];
                                    temp.splice(index, 1);
                                    setUserProcedureList(temp);
                                    updateData({ procedure: temp });
                                  }}
                                >
                                  <Trash />
                                </IconButton>
                              )}
                            </div>
                          </div>
                        </li>
                      );
                    })}

                  {extraData?.isDoctor && (
                    <li className="naLstTyp">
                      <div className="addNewformGrp">
                        <div className="formElement">
                          <FormControl className="formControl">
                            <Autocomplete
                              freeSolo
                              className="formAutocompleteField"
                              variant="outlined"
                              value={selectedProcedure}
                              options={suggestedProcedure}
                              inputValue={procedureString}
                              onChange={(e, newValue) => {
                                setSelectedProcedure(newValue);
                              }}
                              onInputChange={(e, value) => {
                                setProcedureString(value);
                              }}
                              getOptionLabel={(option) => option.procedureName}
                              renderOption={(props, item) => {
                                return (
                                  <li {...props} key={item.id}>
                                    {item.procedureName}
                                  </li>
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  className="formAutoComInputField autocompFildPlaceholder"
                                  placeholder={t("add_procedure")}
                                  label={t("procedure")}
                                  required
                                  InputProps={{
                                    ...params.InputProps,
                                  }}
                                />
                              )}
                            />
                          </FormControl>
                        </div>
                        <div className="formElement mxW-150">
                          <FormControl className="formControl">
                            <InputLabel
                              id="setsurgeryDate"
                              className="setCompletionDate"
                            >
                              {t("select_date")}*
                            </InputLabel>
                            <ReactDatePicker
                              locale={currentLocale}
                              showIcon
                              toggleCalendarOnIconClick
                              showYearDropdown
                              yearDropdownItemNumber={100}
                              scrollableYearDropdown
                              showMonthDropdown
                              labelId="setsurgeryDate"
                              className="formDatePicker"
                              selected={surgeryDate}
                              minDate={new Date()}
                              dateFormat="dd-MMM-yyyy"
                              onChange={(date) => {
                                setSurgeryDate(date);
                              }}
                            />
                          </FormControl>
                        </div>

                        <Button
                          className="dfultPrimaryBtn "
                          startIcon={<Add />}
                          onClick={() => {
                            let tempProcedure = selectedProcedure;
                            if (!tempProcedure) {
                              if (!procedureString?.trim()) {
                                setProcedureString("");
                                setSurgeryDate(null);
                                toast.error(t("please_provide_procedure_name"));
                                return;
                              }
                              tempProcedure = {
                                id: uuidv4(),
                                procedureName: procedureString,
                                gender: "",
                                locallyAdded: true,
                              };
                            }
                            if (!surgeryDate) {
                              toast.error(t("please_select_surgery_date"));
                              return;
                            }
                            setUserProcedureList((prev) => {
                              const temp = [
                                ...prev,
                                {
                                  procedure: tempProcedure,
                                  date: surgeryDate,
                                },
                              ];
                              updateData({ procedure: temp });
                              return temp;
                            });
                            initializeForm();
                          }}
                        >
                          {t("add")}
                        </Button>
                      </div>
                    </li>
                  )}
                </ul>
              </div>
              <div className="bb"></div>
            </>
          )}

          {(extraData?.isDoctor || referPatient) && (
            <>
              <div className="tskElemHeding mt10 ">{t("refer")}</div>

              {extraData?.isDoctor && (
                <>
                  <div className="formElement ">
                    <FormControl className="formControl">
                      <InputLabel id="Refer-select-label">
                        {t("refer_type")}
                      </InputLabel>
                      <Select
                        labelId="Refer-select-label"
                        id="Refer-select"
                        value={referPatient}
                        label={t("refer_patient")}
                        onChange={handleChangeReferPatient}
                        className="formInputField"
                        variant="outlined"
                        disabled={!extraData?.isDoctor}
                      >
                        <MenuItem value={null}>
                          --{t("select_refer_type")}--
                        </MenuItem>
                        <MenuItem value="higherFacility">
                          {t("advise_higher_facility")}
                        </MenuItem>
                        <MenuItem value="immediateHospitalization">
                          {t("advise_immediate_hospitalization")}
                        </MenuItem>

                        <MenuItem value="otherSpeciality">
                          {t("refer_to_other_speciality")}
                        </MenuItem>
                        <MenuItem value="medicoLegal">
                          {t("refer_to_medico_legal")}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  {(referPatient === "immediateHospitalization" ||
                    referPatient === "higherFacility") && (
                    <>
                      <div className="formElement">
                        <FormControl className="formControl pRelative">
                          <Autocomplete
                            freeSolo
                            className="formAutocompleteField"
                            variant="outlined"
                            value={selectedHospital}
                            options={hospitalList}
                            inputValue={hospitalString}
                            onChange={(e, newValue) => {
                              setSelectedHospital(newValue);
                              updateData({ hospital: newValue });
                            }}
                            onInputChange={(e, value) => {
                              setHospitalString(value);
                              const temp = {
                                hospitalName: value,
                                hospitalId: 0,
                              };
                              updateData({ hospital: temp });
                            }}
                            getOptionLabel={(option) => option.hospitalName}
                            renderOption={(props, item) => {
                              return (
                                <li {...props} key={item.id}>
                                  {item.hospitalName}
                                </li>
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                className="formAutoComInputField autocompFildPlaceholder"
                                // placeholder={t("add_previous_procedure")}
                                placeholder={t("select_hospital")}
                                InputProps={{
                                  ...params.InputProps,
                                }}
                              />
                            )}
                            disabled={!extraData?.isDoctor}
                          />
                        </FormControl>
                      </div>
                    </>
                  )}
                  {referPatient === "otherSpeciality" && (
                    <>
                      <div className="addNewformGrp mt10">
                        <div className="formElement ">
                          <FormControl className="formControl">
                            {/* <InputLabel id="speciality-select-label">
        {t("select_speciality")}
      </InputLabel> */}

                            <Autocomplete
                              freeSolo
                              className="formAutocompleteField"
                              variant="outlined"
                              value={referSpeciality}
                              options={specilizationList}
                              // inputValue={specializationString}
                              onChange={(e, newValue) => {
                                setReferSpeciality(newValue);
                                updateData({ referDoctorSpeciality: newValue });
                              }}
                              // onInputChange={(e, value) => {
                              //   setSpecializationString(value);
                              // }}
                              getOptionLabel={(option) =>
                                option.specializationName
                              }
                              renderOption={(props, item) => {
                                return (
                                  <li {...props} key={item.id}>
                                    {item.specializationName}
                                  </li>
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  className="formAutoComInputField autocompFildPlaceholder"
                                  placeholder={t("select_speciality")}
                                  InputProps={{
                                    ...params.InputProps,
                                  }}
                                />
                              )}
                              disabled={!extraData?.isDoctor}
                            />
                          </FormControl>
                        </div>
                        <div className="formElement   ">
                          <FormControl className="formControl">
                            <TextField
                              // hiddenLabel
                              label={t("doctor_name_optional")}
                              autoComplete="off"
                              placeholder={t("enter_doctor_name")}
                              variant="outlined"
                              className="formTextFieldArea"
                              value={doctorName}
                              onChange={(e) => {
                                setDoctorName(e.target.value);
                                updateData({ doctorName: e.target.value });
                              }}
                              disabled={!extraData?.isDoctor}
                            />
                          </FormControl>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
              {!extraData?.isDoctor && referPatient && (
                <>
                  <div className="tskElemntInfo">
                    {`${getReferStringFromType(referPatient)} 

                    
                    ${
                      (referPatient === "higherFacility" ||
                        referPatient === "immediateHospitalization") &&
                      selectedHospital?.hospitalName
                        ? `( ${selectedHospital?.hospitalName} )`
                        : ""
                    } 

                    ${`${
                      referPatient === "otherSpeciality" &&
                      referSpeciality?.specializationName
                        ? `( ${referSpeciality.specializationName} )`
                        : ""
                    }`}
                    `}
                  </div>

                  {doctorName && (
                    <>
                      <div className="tskElemHeding mt10 ">
                        {t("refer_patient_to_doctor")}
                      </div>
                      <div className="tskElemntInfo">{doctorName}</div>
                    </>
                  )}
                </>
              )}

              <div className="bb"></div>
            </>
          )}

          {!referPatient && (
            <>
              {(extraData?.isDoctor || reviewDate) && (
                <>
                  <div className="tskElemHeding mt10 ">{t("review")}</div>
                  {extraData?.isDoctor && (
                    <>
                      <div className="d-flex">
                        <div className="formElement wAuto">
                          <FormControl className="formControl">
                            <FormGroup className="formRadioGroup">
                              <FormControlLabel
                                className="formRadioField"
                                control={
                                  <Checkbox
                                    checked={nextReview}
                                    onChange={(e) => {
                                      setNextReview(e.target.checked);
                                      if (e.target.checked) {
                                        // if (reviewDate) {
                                        //   reviewDate.setDate(new Date().getDate() + 7);
                                        // } else {
                                        // }
                                        const tempReviewDate = new Date(
                                          new Date().setDate(
                                            new Date().getDate() + 7
                                          )
                                        );
                                        setReviewDate(tempReviewDate);
                                        updateData({
                                          reviewDate:
                                            dayjs(tempReviewDate).format(
                                              "DD-MM-YYYY"
                                            ),
                                        });
                                      } else {
                                        updateData({
                                          reviewDate: null,
                                        });
                                      }
                                    }}
                                    disabled={
                                      !extraData?.isDoctor ? true : false
                                    }
                                  />
                                }
                                label={t("next_review")}
                              />
                            </FormGroup>
                          </FormControl>
                        </div>
                        {nextReview === true && (
                          <>
                            <div className="formElement mxW-160">
                              <FormControl className="formControl">
                                <InputLabel
                                  id="setreviewDate"
                                  className="setCompletionDate"
                                >
                                  {t("review_date")}*
                                </InputLabel>
                                <ReactDatePicker
                                  locale={currentLocale}
                                  showIcon
                                  toggleCalendarOnIconClick
                                  showYearDropdown
                                  yearDropdownItemNumber={100}
                                  scrollableYearDropdown
                                  showMonthDropdown
                                  labelId="setreviewDate"
                                  className="formDatePicker"
                                  selected={reviewDate}
                                  minDate={new Date()}
                                  dateFormat="dd-MMM-yyyy"
                                  onChange={(date) => {
                                    setReviewDate(date);
                                    updateData({
                                      reviewDate: date
                                        ? dayjs(date).format("DD-MM-YYYY")
                                        : null,
                                    });
                                  }}
                                  disabled={!extraData.isDoctor}
                                />
                              </FormControl>
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  )}
                  {!extraData?.isDoctor && (
                    <div className="tskElemntInfo">
                      {dayjs(reviewDate).format("DD-MMM-YYYY")}
                    </div>
                  )}
                  <div className="bb"></div>
                </>
              )}
            </>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
}
