import React, { useState } from "react";
import FemaleBody from "./FemaleBody";
import Icon1 from "../../../../images/symptoms/SMCTL1_00001.svg";
import Icon2 from "../../../../images/symptoms/SMCTL1_00002.svg";
import Icon3 from "../../../../images/symptoms/SMCTL1_00003.svg";
import Icon4 from "../../../../images/symptoms/SMCTL1_00004.svg";
import Icon5 from "../../../../images/symptoms/SMCTL1_00005.svg";
import Icon6 from "../../../../images/symptoms/SMCTL1_00006.svg";
import Icon7 from "../../../../images/symptoms/SMCTL1_00007.svg";
import Icon8 from "../../../../images/symptoms/SMCTL1_00008.svg";
import Icon9 from "../../../../images/symptoms/SMCTL1_00009.svg";
import Icon10 from "../../../../images/symptoms/SMCTL1_00010.svg";

export default function VisualSelection() {
  const [activeIds, setActiveIds] = useState([]);
  const [selectedPart, setSelectedPart] = useState(null);
  const [activeLabels, setActiveLabels] = useState([]); // Store active label IDs

  // Mapping of IDs to arrays of SVGs or a single SVG
  const iconMapping = {
    item1: [Icon1, Icon2],
    item2: Icon10,
    item3: [Icon1, Icon2, Icon3, Icon4, Icon5, Icon6],
    item4: Icon4,
    item5: Icon5,
    item6: Icon6,
    item7: [Icon9, Icon2],
    item8: Icon7,
    item9: Icon8,
  };

  // Handle body part clicks
  const handleBodyPartClick = (id) => {
    if (!activeIds.includes(id)) {
      setActiveIds([...activeIds, id]);
    }
    setSelectedPart([...activeIds, id]);
  };

  // Handle label click to toggle "active" class
  const handleLabelClick = (id) => {
    setActiveLabels((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  return (
    <>
      <div className="visualSlectionContainer">
        <div className="personBody">
          {/* Pass the click handler to the FemaleBody component */}
          <FemaleBody
            onBodyPartClick={handleBodyPartClick}
            selectedPart={selectedPart}
          />
        </div>
        <div className="levelSymptGrp">
          <div className="lvlSyHeading">Level one</div>
          <div className="labelSymptoms">
            {!selectedPart && (
              <div className="noDatagrp">
                <div className="btext">Oops!</div>
                <div className="subtext">
                  Please click on the body part where you are experiencing
                  problems.
                </div>
              </div>
            )}
            {activeIds.map((id) => {
              const icons = Array.isArray(iconMapping[id])
                ? iconMapping[id]
                : [iconMapping[id]]; // Convert to array if single item
              return icons.map((icon, index) => (
                <div
                  key={`${id}-${index}`}
                  className={`labelSympInd ${
                    activeLabels.includes(`${id}-${index}`) ? "active" : ""
                  }`}
                  onClick={() => handleLabelClick(`${id}-${index}`)}
                >
                  <img src={icon} alt={id} />
                </div>
              ));
            })}
          </div>
        </div>
        <div className="levelSymptGrp">
          <div className="lvlSyHeading">Level two</div>
          <div className="labelSymptoms"></div>
        </div>
        <div className="levelSymptGrp">
          <div className="lvlSyHeading">Level three</div>
          <div className="finalSymptoms"></div>
        </div>
      </div>
    </>
  );
}
