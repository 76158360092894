import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { GetLoginUserDetails } from "../../../../utils/Utils";
import ReactDatePicker from "react-datepicker";
import { NumericFormatCustom } from "../../../task/view/component/Forms/NumericFormatCustom";
import { SalaryTable } from "./SalaryTable";
import { Bank, Trash } from "react-bootstrap-icons";
import { Add, Edit, Gavel } from "@mui/icons-material";
import {
  getAllDepartment,
  getContractList,
  getCountryList,
  getCurrencyList,
  getEmployees,
  getItems,
  getOrgDetailsByOrgId,
  getPendingAppointmentCandidat,
  getPositionsOfOrganization,
  getSalaryBreakUpOfLoi,
  getStateList,
  getStaticDDOfOrg,
  getUnits,
  unitDetilsForProforma,
  getEmpForRelease,
  issueReleaseLetter,
  getEmpForServiceCertificate,
  getAllPositionOfEmployee,
  issueServiceCertificate,
} from "../../../../services/AdminService";
import DashboardServices from "../../../../services/DashboardServices";
import IntegerTextField from "../../../../common/IntegerTextField";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { VendorPaymentMethods } from "../../../task/view/component/Forms/VendorPaymentMethods";
import TaskService from "../../../../services/TaskService";
import { TermsAndConditions } from "../../../task/view/component/Forms/TermsAndConditions";
import { FloatTextField } from "../../../../common/FloatTextField";
import dayjs from "dayjs";
import DateUtils from "../../../../utils/DateUtils";

export const ExperienceLetter = forwardRef(
  (
    { formData, setFormData, handleShowPreview, setSelectActivity },
    experienceLetterRef
  ) => {
    const loginUserDetails = GetLoginUserDetails();
    const loginUserDetail = GetLoginUserDetails();

    const [lastWorkingdate, setLastWorkingdate] = useState(new Date());
    const [dateOfResignation, setDateOfResignation] = useState(new Date());
    const [dateOfBirth, setDateOfBirth] = useState(new Date());
    const [loiCandidateList, setLoiCandidateList] = useState([]);
    const [fromIssuedLoi, setFromIssuedLoi] = useState(false);
    const { t } = useTranslation();
    const [unitsData, setUnitData] = useState([]);
    const [emplyeeUnits, setEmplyeeUnits] = useState("");
    const [tableDataList, setTableDataList] = useState([]);
    const [showBankDetails, setShowBankDetails] = useState(false);
    const [selectedMethodId, setSelectedMethodId] = useState(0);

    const [selectedDepartment, setSelectedDepartment] = useState("");
    const [selectedPosition, setSelectedPosition] = useState("");
    const [selectedCountry, setSelectedCountry] = useState("");
    const [selectedState, setSelectedState] = useState("");
    const [salaryBrkUps, setSalaryBrkUps] = useState([]);
    const [orgData, setOrgData] = useState(null);
    const [unitList, setUnitList] = useState([]);
    const [corCountry, setCorCountry] = useState("");
    const [corState, setCorState] = useState("");
    const tacRef = useRef();
    const [saveForFutureAppoint, setSaveForFutureAppoint] = useState(false);

    const [selectedReportingHead, setSelectedReportingHead] = useState(null);
    const [userList, setUserList] = useState([]);

    const [staticDodList, setStaticDodList] = useState([]);
    const [staticDodListCopy, setStaticDodListCopy] = useState([]);
    const [selectedContractId, setSelectedContractId] = useState("");
    const [shipToData, setShipToData] = useState("");
    const [itemData, setItemData] = useState([]);
    const [defultCurrency, setdefultCurrency] = useState("");
    const [sellerTaxId, setsellerTaxId] = useState("");
    const [sellerEmailValue, setsellerEmailValue] = useState([]);
    const [sellerEmailId, setsellerEmailId] = useState("");
    const [sellerEmailInputId, setsellerEmailInputId] = useState("");

    const [contractData, setContractData] = useState([]);
    const [buyerTaxId, setBuyerTaxId] = useState("");
    const [buyerEmailId, setBuyerEmailId] = useState("");
    const [notesSpecialInstruction, setNotesSpecialInstruction] = useState("");
    const [buyerCountryCode, setBuyerCountryCode] = useState("");
    const [selectSellerState, setSelectSellerState] = useState("");
    const [selectBuyerState, setSelectBuyerState] = useState("");
    const [roundOffAmount, setRoundOffAmount] = useState();
    const [totalAmount, setTotalAmount] = useState();
    const [letterContent, setLetterContent] = useState("");
    const [Signature, setSignature] = useState("");
    const [emplyuserId, setEmplyUserId] = useState("");
    const [positiveQualtiesValue, setPositiveQualtiesValue] = useState("");
    const [legalClauseValue, setLegalClauseValue] = useState("");

    const [addTableData, setAddTableData] = useState({
      proformaInvoId: 1,
      itemId: 0,
      itemName: "",
      uom: "",
      quantity: "",
      rate: 0,
      disePc: 0,
      sgstPc: 0,
      cgstPc: 0,
      igstPc: 0,
      taxPc: 0,
      diseAmt: 0,
      sgstAmt: 0,
      amount: 0,
      unitDetails: "",
    });

    console.log("addTableData", addTableData);

    const [employName, setEmployName] = useState("");
    const [employId, setEmployId] = useState("");
    const [employPosition, setEmployPosition] = useState([]);
    const [employDepartment, setEmployDepartment] = useState("");

    const [emplyStartDate, setEmplyStartDate] = useState("");
    const [emplyEndDate, setEmplyEndDate] = useState("");
    const [emplyGender, setEmplyGender] = useState("");

    const [designationData, setDesignationData] = useState([]);
    const [designationDetailValue, setDesignationDetailValue] = useState("");
    const [listOfResponsibility, setListOfResponsibility] = useState([]);
    const [responsibilityDataOfReqDto, setResponsibiltyDataOfReqDto] = useState(
      {}
    );

    //////////////////////////////////////

    const [employsData, setEmploysData] = useState([]);
    const [currentDate, setCurrentDate] = useState(new Date());

    const [selectedEmploy, setSelectedEmploy] = useState(null);

    const [employInputString, setEmployInputString] = useState("");

    const [orgPersonPosition, setOrgPersonPosition] = useState("");

    const [positionDesignationData, setPositionDesignationData] = useState([]);

    const [selectedPositionId, setSelectedPositionId] = useState("");
    const [responsibilities, setResponsibilities] = useState([]);
    const [selectedPositionValue, setSelectedPositionValue] = useState(null);
    const [responsibilitiesValue, setResponsibilitiesValue] = useState("");

    /////////////////////////////////////////

    useEffect(() => {
      fetchEmployData();
    }, []);

    const fetchEmployData = () => {
      getEmpForServiceCertificate(loginUserDetail.orgId)
        .then((response) => {
          console.log("getEmployees", response.data);
          setEmploysData(response.data);
        })
        .catch((error) => {
          console.error("Error fetching units", error);
        });
    };

    console.log("selectedEmploy", selectedEmploy);
    console.log("employInputString", employInputString);

    useEffect(() => {
      if (selectedEmploy && employsData) {
        const result = employsData.find(
          (val) => val.employeeUserId === selectedEmploy.employeeUserId
        );

        console.log("buyer", result);

        if (result) {
          setEmployName(result.employeeName || "");
          setEmployId(result.employeeId || "");
          setEmployDepartment(result.functionName || "");
          setEmployPosition(result.positions || []);
          setEmplyUserId(result.employeeUserId || "");
          setEmplyStartDate(result.joiningDate || "");
          setEmplyEndDate(result.lastWorkingDate || "");
          setEmplyGender(result.gender || "");
        } else {
          console.log("No matching unit found");
          setEmployName("");
          setEmployId("");
          setEmployDepartment("");
          setEmployPosition([]);
          setEmplyUserId("");
          setEmplyStartDate();
          setEmplyEndDate();
          setEmplyGender();
        }
      }
    }, [selectedEmploy, employsData]);

    useEffect(() => {
      if (loginUserDetail && employsData) {
        const result = employsData.find(
          (val) => val.employeeUserId === loginUserDetail.userId
        );

        console.log("buyer", result);

        if (result) {
          setOrgPersonPosition(result.positions.join("") || "");
        } else {
          console.log("No matching unit found");
          setOrgPersonPosition("");
        }
      }
    }, [selectedEmploy, employsData]);

    useEffect(() => {
      if (selectedEmploy) {
        getAllPositionOfEmployee(
          loginUserDetail.userId,
          selectedEmploy.lastWorkingDate
        ).then((response) => {
          console.log("position of emply", response.data);
          setPositionDesignationData(response.data);
          // setDesignationData(response.data);
        });
      }
    }, [selectedEmploy]);

    console.log("positionDesignationData", positionDesignationData);

    useEffect(() => {
      setDateOfResignation(lastWorkingdate);
    }, [lastWorkingdate]);

    useEffect(() => {
      fetchUserProfileDetails();
    }, []);

    const fetchUserProfileDetails = () => {
      DashboardServices.getUserProfile(loginUserDetails.userId).then(
        (response) => {
          if (!response.data) {
            return;
          }

          setSignature(response.data.signFileLink);
        }
      );
    };

    const handlePositionChange = (event) => {
      const positionId = event.target.value;
      setSelectedPositionId(positionId);

      const selectedPosition = positionDesignationData.find(
        (designation) => designation.positionId === positionId
      );

      setSelectedPositionValue(selectedPosition);

      if (selectedPosition && selectedPosition.responsibilities) {
        setResponsibilities(
          selectedPosition.responsibilities.map((responsibility) => ({
            title: responsibility.title,
            heading: responsibility.heading,
          }))
        );
      } else {
        setResponsibilities([]);
      }
    };

    // const [listOfResponsibilty , setListOfResponsibilty] = useState([]);

    // const addResponsibiltyData = () =>{
    //   console.log('selectedPositionId' , selectedPositionId);
    //   console.log("responsibilitiesValue" , responsibilitiesValue);
    //   const selectedPosition = positionDesignationData.find(
    //     (designation) => designation.positionId === selectedPositionId
    //   );
    //   console.log("selectedPosition" , selectedPosition);

    //   const val = {
    //     positionId : selectedPosition.positionId,
    //     position : selectedPosition.position ,
    //     startDate : selectedPosition.startDate ,
    //     endDate : selectedPosition.endDate || "14-11-2024" ,
    //     keyResponsibilities : [responsibilitiesValue]
    //   }

    //   setListOfResponsibilty(val)

    // }
    // const [listOfResponsibility, setListOfResponsibility] = useState([]);
    // const [responsibilityDataOfReqDto, setResponsibiltyDataOfReqDto] = useState({});

    // const addResponsibiltyData = () => {
    //   console.log("selectedPositionId", selectedPositionId);
    //   console.log("responsibilitiesValue", responsibilitiesValue);

    //   const selectedPosition = positionDesignationData.find(
    //     (designation) => designation.positionId === selectedPositionId
    //   );

    //   if (selectedPosition) {
    //     console.log("selectedPosition", selectedPosition);

    //     const val = {
    //       positionId: selectedPosition.positionId,
    //       position: selectedPosition.position,
    //       startDate: selectedPosition.startDate,
    //       endDate: selectedPosition.endDate || selectedEmploy?.lastWorkingDate || "",
    //       keyResponsibilities: [responsibilitiesValue],
    //     };

    //     setListOfResponsibility((prevList) => {
    //       const existingIndex = prevList.findIndex(
    //         (item) => item.positionId === selectedPosition.positionId
    //       );

    //       if (existingIndex !== -1) {
    //         const updatedList = [...prevList];
    //         updatedList[existingIndex].keyResponsibilities.push(
    //           responsibilitiesValue
    //         );
    //         return updatedList;
    //       } else {
    //         return [...prevList, val];
    //       }
    //     });
    //     setResponsibilitiesValue("");
    //   }
    // };

    // const handleSubmit = () => {
    //   const reqDto = {
    //     employeeUserId: emplyuserId,
    //     responsibilities: {
    //       additionalProp1: ["string"],
    //       additionalProp2: ["string"],
    //       additionalProp3: ["string"],
    //     },
    //     positiveQualities: positiveQualtiesValue,
    //     legalClause: legalClauseValue,
    //   };

    //   console.log("reqDto", reqDto);
    //   return;
    //   issueServiceCertificate(
    //     loginUserDetail.orgId,
    //     loginUserDetail.userId,
    //     reqDto
    //   ).then((response) => {
    //     console.log("response", response.data);
    //     if (response.data == "1") {
    //       toast.success("Issue Service Certificate Successfully", {
    //         position: toast.POSITION.TOP_RIGHT,
    //       });
    //       setSelectActivity("");
    //     } else {
    //       toast.error("Something Went Wrong", {
    //         position: toast.POSITION.TOP_RIGHT,
    //       });
    //     }
    //   });
    // };

    // console.log("designationData", designationData);

    // const addResponsibiltyData = () => {
    //   console.log("selectedPositionId", selectedPositionId);
    //   console.log("responsibilitiesValue", responsibilitiesValue);

    //   const selectedPosition = positionDesignationData.find(
    //     (designation) => designation.positionId === selectedPositionId
    //   );

    //   if (selectedPosition) {
    //     console.log("selectedPosition", selectedPosition);

    //     const updatedResponsibilities = {
    //       [selectedPosition.userPositionId]: responsibilitiesValue ? [responsibilitiesValue] : [],
    //     };

    //     console.log("updatedResponsibilities" , updatedResponsibilities);

    //     setResponsibiltyDataOfReqDto((prevData) => ({
    //       ...prevData,
    //       ...updatedResponsibilities,
    //     }));

    //     const val = {
    //       positionId: selectedPosition.positionId,
    //       position: selectedPosition.position,
    //       startDate: selectedPosition.startDate,
    //       endDate:
    //         selectedPosition.endDate || selectedEmploy?.lastWorkingDate || "",
    //       keyResponsibilities: [responsibilitiesValue],
    //     };

    //     setListOfResponsibility((prevList) => {
    //       const existingIndex = prevList.findIndex(
    //         (item) => item.positionId === selectedPosition.positionId
    //       );

    //       if (existingIndex !== -1) {
    //         const updatedList = [...prevList];
    //         updatedList[existingIndex].keyResponsibilities.push(
    //           responsibilitiesValue
    //         );
    //         return updatedList;
    //       } else {
    //         return [...prevList, val];
    //       }
    //     });

    //   }
    //   setResponsibilitiesValue("");
    // };

    const addResponsibiltyData = () => {
      console.log("selectedPositionId", selectedPositionId);
      console.log("responsibilitiesValue", responsibilitiesValue);

      const selectedPosition = positionDesignationData.find(
        (designation) => designation.positionId === selectedPositionId
      );

      if (selectedPosition) {
        console.log("selectedPosition", selectedPosition);

        const userPositionId = selectedPosition.userPositionId;

        setResponsibiltyDataOfReqDto((prevData) => {
          const existingResponsibilities = prevData[userPositionId] || [];

          const updatedResponsibilities = responsibilitiesValue
            ? [...existingResponsibilities, responsibilitiesValue]
            : existingResponsibilities;

          return {
            ...prevData,
            [userPositionId]: updatedResponsibilities,
          };
        });

        const val = {
          positionId: selectedPosition.positionId,
          position: selectedPosition.position,
          startDate: selectedPosition.startDate,
          endDate:
            selectedPosition.endDate || selectedEmploy?.lastWorkingDate || "",
          keyResponsibilities: [responsibilitiesValue],
        };

        setListOfResponsibility((prevList) => {
          const existingIndex = prevList.findIndex(
            (item) => item.positionId === selectedPosition.positionId
          );

          if (existingIndex !== -1) {
            const updatedList = [...prevList];
            updatedList[existingIndex].keyResponsibilities.push(
              responsibilitiesValue
            );
            return updatedList;
          } else {
            return [...prevList, val];
          }
        });
      }

      setResponsibilitiesValue("");
    };

    // const addResponsibiltyData = () => {
    //   console.log("selectedPositionId", selectedPositionId);
    //   console.log("responsibilitiesValue", responsibilitiesValue);

    //   const selectedPosition = positionDesignationData.find(
    //     (designation) => designation.positionId === selectedPositionId
    //   );

    //   if (selectedPosition) {
    //     console.log("selectedPosition", selectedPosition);

    //     // Construct the responsibility data for reqDto
    //     const updatedResponsibilities = {
    //       [selectedPositionId]: responsibilitiesValue ? [responsibilitiesValue] : [],
    //     };

    //     // Merge with existing data
    //     setResponsibiltyDataOfReqDto((prevData) => ({
    //       ...prevData,
    //       ...updatedResponsibilities,
    //     }));

    //     // Construct the value to add to listOfResponsibility
    //     const val = {
    //       positionId: selectedPosition.positionId,
    //       position: selectedPosition.position,
    //       startDate: selectedPosition.startDate,
    //       endDate: selectedPosition.endDate || selectedEmploy?.lastWorkingDate || "",
    //       keyResponsibilities: [responsibilitiesValue],
    //     };

    //     // Update listOfResponsibility
    //     setListOfResponsibility((prevList) => {
    //       const existingIndex = prevList.findIndex(
    //         (item) => item.positionId === selectedPosition.positionId
    //       );

    //       if (existingIndex !== -1) {
    //         const updatedList = [...prevList];
    //         updatedList[existingIndex].keyResponsibilities.push(responsibilitiesValue);
    //         return updatedList;
    //       } else {
    //         return [...prevList, val];
    //       }
    //     });

    //     // Reset responsibilitiesValue
    //     setResponsibilitiesValue("");
    //   }
    // };

    const handleSubmit = () => {
      console.log("handle Submit");
      const reqDto = {
        employeeUserId: emplyuserId,
        responsibilities: responsibilityDataOfReqDto,
        positiveQualities: positiveQualtiesValue,
        legalClause: legalClauseValue,
      };

      console.log("reqDto", reqDto);

      // return

      issueServiceCertificate(
        loginUserDetail.orgId,
        loginUserDetail.userId,
        reqDto
      )
        .then((response) => {
          console.log("response", response.data);
          if (response.data === "1") {
            toast.success(t("issue_service_certificate_successfully"), {
              position: toast.POSITION.TOP_RIGHT,
            });
            setSelectActivity("");
            setListOfResponsibility([]);
            setResponsibiltyDataOfReqDto({});
            setSelectedEmploy(null);
            setEmployInputString("");
            setPositiveQualtiesValue("");
            setLegalClauseValue("");
            setSelectedPositionId("");
            setResponsibilitiesValue("");
          } else {
            toast.error(t("something_went_wrong"), {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          console.error("API call error:", error);
          toast.error(t("something_went_wrong"), {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    };

    console.log("designationData", designationData);

    console.log("designationData", designationData);

    return (
      <>
        <div className="formElementGrp">
          <div className="formElement">
            <FormControl className="formControl">
              <Autocomplete
                freeSolo
                className="formAutocompleteField"
                variant="outlined"
                options={employsData || []}
                value={selectedEmploy}
                onChange={(e, newValue) => {
                  setSelectedEmploy(newValue);
                }}
                inputValue={employInputString}
                onInputChange={(e, value) => {
                  setEmployInputString(value);
                }}
                getOptionLabel={(option) => option.employeeName || ""}
                renderOption={(props, option) => {
                  return (
                    <li
                      {...props}
                      style={{ padding: "4px 12px" }}
                      className="sDocOptList"
                    >
                      <div className="sDname">{option.employeeName}</div>
                      <div className="sDElm">{option.employeeId}</div>
                      <div className="sDElmGrp">
                        {option.positions.length > 0 && (
                          <div className="sDElm">
                            {t("designation")}:{" "}
                            <span>
                              {option.positions.map((name, index) => (
                                <span key={index}>
                                  {name}
                                  {index < option.positions.length - 1
                                    ? ", "
                                    : ""}
                                </span>
                              ))}
                            </span>
                          </div>
                        )}
                        <div className="sDElm">
                          {t("department")}:<span>{option.functionName}</span>
                        </div>
                      </div>
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("select_employee")}
                    className="formAutoComInputField autocompFildPlaceholder"
                  />
                )}
              />
            </FormControl>
          </div>

          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label={t("positive_qualities")}
                required
                placeholder={t("enter_positive_qualities")}
                variant="outlined"
                className="formTextFieldArea"
                autoComplete="off"
                value={positiveQualtiesValue}
                onChange={(e) => setPositiveQualtiesValue(e.target.value)}
              />
            </FormControl>
          </div>

          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label={t("legal_clause_text")}
                // required
                placeholder={t("enter_legal_clause")}
                variant="outlined"
                className="formTextFieldArea"
                autoComplete="off"
                value={legalClauseValue}
                onChange={(e) => setLegalClauseValue(e.target.value)}
              />
            </FormControl>
          </div>
        </div>

        <div className="formElementGrp">
          <div className="formElement">
            <FormControl className="formControl">
              <InputLabel id="selectUnits">
                {t("designation_details")}:
              </InputLabel>
              <Select
                required
                labelId="selectUnits"
                id="employee-units"
                value={selectedPositionId}
                label={t("designation_details")}
                onChange={handlePositionChange}
                className="formInputField"
                variant="outlined"
              >
                {positionDesignationData.map((designation) => (
                  <MenuItem
                    key={designation.positionId}
                    value={designation.positionId}
                  >
                    {designation.position}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="formElement">
            <FormControl className="formControl">
              <InputLabel id="selectUnits">{t("responsibilities")}:</InputLabel>
              <Select
                required
                labelId="selectUnits"
                id="employee-units"
                value={responsibilitiesValue}
                label={t("responsibilities")}
                onChange={(e) => setResponsibilitiesValue(e.target.value)}
                className="formInputField"
                variant="outlined"
              >
                {responsibilities.map((responsibility, index) => (
                  <MenuItem key={index} value={responsibility.heading}>
                    {responsibility.heading}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <Button
            className="dfultPrimaryBtn"
            startIcon={<Add />}
            onClick={() => addResponsibiltyData()}
          >
            {t("add")}
          </Button>
        </div>

        <div className="bb"></div>

        {employName && (
          <div className="elementFormContainer" style={{ color: "#fff" }}>
            <div style={{ marginLeft: "280px" }}>
              <h4 style={{ color: "#fff" }}>
                {t("service_certificate_experience_letter")}
              </h4>
            </div>

            <div style={{ marginBottom: "30px" }}>
              <p style={{ margin: 0, padding: 0, color: "#fff" }}></p>
              <p style={{ margin: 0, padding: 0, color: "#fff" }}>
                {employName}
              </p>
              <p style={{ margin: 0, padding: 0, color: "#fff" }}>
                {t("employ_id")}: {employId}
              </p>
              <p style={{ margin: 0, padding: 0, color: "#fff" }}>
                {t("designation")}:{" "}
                {employPosition.map((position, index) => (
                  <span key={index}>{position}</span>
                ))}
              </p>
              <p style={{ margin: 0, padding: 0, color: "#fff" }}>
                {t("department")}:{employDepartment}
              </p>
              <p style={{ margin: 0, padding: 0, color: "#fff" }}>
                {t("date")}: {dayjs(currentDate).format("DD-MMM-YYYY")}
              </p>

              <div style={{ margin: 0, padding: 0, color: "#fff" }}>
                {/* <p>
                  This is to certify that <strong>{employName}</strong> was
                  employed with {loginUserDetail.orgName} from {emplyStartDate}{" "}
                  to {emplyEndDate}. During this period,{" "}
                  {emplyGender.toLocaleLowerCase() === "male"
                    ? "he"
                    : emplyGender.toLocaleLowerCase() === "female"
                    ? "she"
                    : "they"}{" "}
                  served in various capacities, including:
                </p> */}
                <p>
                  {t("employment_certification", {
                    name: employName,
                    orgName: loginUserDetail.orgName,
                    startDate: emplyStartDate,
                    endDate: emplyEndDate,
                    genderPronoun:
                      emplyGender.toLocaleLowerCase() === "male"
                        ? t("he")
                        : emplyGender.toLocaleLowerCase() === "female"
                        ? t("she")
                        : t("they"),
                  })}
                </p>
                {/* <ul style={{ paddingLeft: "20px", listStyleType: "disc" }}> */}
                {/* <li>[Designation 1] from [Start Date 1] to [End Date 1]</li> */}
                {/* <p style={{ margin: "5px 0", paddingLeft: "20px" }}>
                    Key Responsibilities:
                  </p> */}
                {/* <ul style={{ paddingLeft: "40px", listStyleType: "circle" }}>
                    <li>[List of key responsibilities]</li>
                  </ul> */}
                {/* <li>[Designation 2] from [Start Date 2] to [End Date 2]</li> */}
                {/* <p style={{ margin: "5px 0", paddingLeft: "20px" }}>
                    Key Responsibilities:
                  </p> */}
                {/* <ul style={{ paddingLeft: "40px", listStyleType: "circle" }}>
                    <li>[List of key responsibilities]</li>
                  </ul> */}
                {/* </ul> */}
                <ul style={{ paddingLeft: "20px", listStyleType: "disc" }}>
                  {listOfResponsibility.map((item, index) => (
                    <li key={index}>
                      {item.position} {t("from")} {item.startDate} {t("to")}{" "}
                      {item.endDate}
                      {item.keyResponsibilities.filter(
                        (res) => res.trim() !== ""
                      ).length > 0 && (
                        <>
                          <p style={{ margin: "5px 0", paddingLeft: "20px" }}>
                            {t("key_responsibilities")}:
                          </p>
                          <ul
                            style={{
                              paddingLeft: "40px",
                              listStyleType: "circle",
                            }}
                          >
                            {item.keyResponsibilities
                              .filter(
                                (responsibility) => responsibility.trim() !== ""
                              )
                              .map((responsibility, idx) => (
                                <li key={idx}>{responsibility}</li>
                              ))}
                          </ul>
                        </>
                      )}
                    </li>
                  ))}
                </ul>
              </div>

              <div style={{ margin: "20px 0" }}>
                {/* <p>
                  {employName} consistently demonstrated {positiveQualtiesValue}{" "}
                  and made significant contributions to{" "}
                  {loginUserDetail.orgName}.
                </p> */}
                <p>
                  {t("contributions_and_qualities", {
                    name: employName,
                    qualities: positiveQualtiesValue,
                    orgName: loginUserDetail.orgName,
                  })}
                </p>
                <p>{legalClauseValue}</p>
              </div>

              {/* <p>
                We wish <strong>{employName}</strong> all the best in{" "}
                {emplyGender.toLocaleLowerCase() === "male"
                  ? "his"
                  : emplyGender.toLocaleLowerCase() === "female"
                  ? "her"
                  : "their"}{" "}
                future endeavours.
              </p> */}
              <p>
                {t("future_wishes", {
                  name: employName,
                  genderPossessive:
                    emplyGender.toLocaleLowerCase() === "male"
                      ? t("his")
                      : emplyGender.toLocaleLowerCase() === "female"
                      ? t("her")
                      : t("their"),
                })}
              </p>

              <div
                style={{ textAlign: "left", marginTop: "30px", color: "#fff" }}
              >
                <p style={{ margin: 0, padding: 0, color: "#fff" }}>
                  {t("sincerely")},
                </p>
                <p style={{ margin: 0, padding: 0, color: "#fff" }}>
                  {loginUserDetail.userName}
                </p>
                <p style={{ margin: 0, padding: 0, color: "#fff" }}>
                  {orgPersonPosition}
                </p>
                <p style={{ margin: 0, padding: 0, color: "#fff" }}>
                  {loginUserDetail.orgName}
                </p>
                <p style={{ margin: 0, padding: 0, color: "#fff" }}>
                  {loginUserDetail.orgAddress}
                </p>
                {/* <p>[Company Contact Information]</p> */}
                {Signature && (
                  <div className="signAvl">
                    <img
                      src={Signature}
                      alt={t("signature")}
                      onError={(e) => {
                        console.error("Error loading image:", e.target.src);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        <Button
          ref={experienceLetterRef}
          style={{ display: "none" }}
          //   onClick={(e) => {
          //     const selectedBankingMethod = paymentMethods?.find(
          //       (item) => item.id === selectedMethodId
          //     );
          //     const SellerUnitName = unitsData?.find(
          //       (item) => item.unitId === emplyeeUnits
          //     );
          //     const BuyerName = contractData?.find(
          //       (item) => item.id === selectedContractId
          //     );
          //     const data = {
          //       sellerUnitId: emplyeeUnits,
          //       sellerName: SellerUnitName.unitName,
          //       sellerAdress: SellerUnitName.address,
          //       sellerTaxId: sellerTaxId,
          //       sellerEmailId:
          //         sellerEmailInputId !== "" ? sellerEmailInputId : sellerEmailId,
          //       buyerId: selectedContractId,
          //       buyerName: BuyerName ? BuyerName.contractName : "",
          //       buyerAddress: BuyerName ? BuyerName.address : "",
          //       buyerTaxId: buyerTaxId,
          //       buyerEmailId: buyerEmailId,
          //       placeOfSupply: shipToData,
          //       // invoiceDate : formatDateWithSuffix(new Date(joiningdate)) ,
          //       currency: defultCurrency,
          //       bankingMethod: selectedBankingMethod,
          //       allTermsAndConditions: allTermsAndConditions,
          //       tableData: tableData,
          //       subTotalAmount,
          //       totalAmount,
          //       roundOffAmount,
          //     };
          //     handleShowPreview(data);
          //   }}
          onClick={() => handleSubmit()}
        >
          {t("submit")}
        </Button>

        {/* {showTaCDialog && (
            <TermsAndConditions
              showTermsAndConditions={showTaCDialog}
              handelCloseLeaveBalanceModel={(e) => {
                setShowTaCDialog(false);
              }}
              conditionList={orgTacList}
              getTermsAndCondition={(data) => {
                setAllTermsAndConditions(data);
              }}
              selectedConditionList={selectedConditionList}
              setSelectedConditionList={setSelectedConditionList}
            />
          )} */}
      </>
    );
  }
);
