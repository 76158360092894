import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import zhPatientService from "../../../../../services/ZhPatientService";
import { getHealthDto } from "../../../../scheduler/view/component/meetings/view/consultation/consultationUtil";
import { Add, Cancel, Edit } from "@mui/icons-material";
import {
  ButtonGroup,
  FormControl,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import RightFlotingAdmin from "../RightFlotingAdmin";
import Joyride from "react-joyride";
import { AddParameterAdmin } from "./AddParameterAdmin";
import { Search, Trash } from "react-bootstrap-icons";
import { TourContext } from "../../../../../common/TourProvider";

export const HealthCareParameter = () => {
  const { t } = useTranslation();

  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  const [parameterData, setParameterData] = useState([]);
  const [showAddParameter, setShowAddParameter] = useState(false);
  const [editData, setEditData] = useState(null);

  const [searchTestString, setSearchTestString] = useState("");
  const [showTestString, setShowTestString] = useState(false);

  const fetchParameters = async () => {
    const reqDto = await getHealthDto();
    zhPatientService.getParameterMaster(reqDto).then((response) => {
      if (response.data) {
        setParameterData(response.data);
      }
    });
  };

  function convertDaysToYMD(totalDays) {
    const daysInYear = 365;
    const daysInMonth = 30; // Approximation for a month

    const years = Math.floor(totalDays / daysInYear);
    totalDays %= daysInYear; // Remaining days after calculating years

    const months = Math.floor(totalDays / daysInMonth);
    const days = totalDays % daysInMonth; // Remaining days after calculating months

    return { years, months, days };
  }

  useEffect(()=>{
  setBookSteps([
    {
      target: ".viewParamterConfigStepOne",
      content: "This is the table header showing parameter details.",
      disableBeacon: true,
    },
    {
      target: ".viewParamterConfigStepTwo",
      content: "Here is the list of parameters with their details.",
      disableBeacon: true,
    },
    {
      target: ".viewParamterConfigStepThree",
      content: "Click here to add a new healthcare parameter.",
      disableBeacon: true,
    },
   
    {
      target: ".viewParamterConfigStepFour",
      content: "Click here to edit an existing parameter.",
      disableBeacon: true,
    }
  ]
  )
  },[])

  // Example usage

  const calculateAgeLimit = (startAge, endAge) => {
    // let endString = endAge;

    // if (endAge?.includes("<")) {
    //   const endDate = endAge.split("<");
    //   endString = endDate[endDate.length - 1];
    // }

    const startAgeYMD = convertDaysToYMD(parseInt(startAge));
    // const endAgeYMD = convertDaysToYMD(parseInt(endString));
    const endAgeYMD = convertDaysToYMD(parseInt(endAge));

    return `${startAgeYMD.years}Y ${startAgeYMD.months}M ${startAgeYMD.days}D -  ${endAgeYMD.years}Y ${endAgeYMD.months}M ${endAgeYMD.days}D`;
  };

  const calculateValueRange = (ll, ul) => {
    if (ul?.includes("<")) {
      return `${ll}${ul}`;
    } else {
      return `${ll}-${ul}`;
    }
  };

  useEffect(() => {
    fetchParameters();
  }, []);

  const filteredParameters = useMemo(() => {
    if (!searchTestString) {
      return parameterData;
    } else {
      const temp = parameterData?.filter((item) =>
        item.parameterName
          .toLowerCase()
          .includes(searchTestString.toLowerCase())
      );
      return temp;
    }
  }, [parameterData, searchTestString]);

  return (
    <>
     <Joyride
        steps={booksteps}
        run={run["viewParameterConfigAD"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        styles={{
          buttonClose: {
            display: "none",
          },
        }}
      />
      <div className="TableContainer organizationPosition responsiveTable">
        <table className="taskListTable">
          <thead className="taskListtableHeader viewParamterConfigStepOne">
            <tr>
              <th className=" w25percent">
                {/* <div className="txtWithBtn">{t("parameter_name")}</div> */}
                {!showTestString && (
                  <div className="orgPText">
                    <div className="txtWithBtn">{t("parameter_name")}</div>

                    <ButtonGroup
                      variant="contained"
                      className="buttonContainerGrp"
                    >
                      <Tooltip
                        arrow
                        title={t("search")}
                        onClick={() => {
                          setShowTestString(true);
                        }}
                      >
                        <IconButton>
                          <Search />
                        </IconButton>
                      </Tooltip>
                    </ButtonGroup>
                  </div>
                )}
                {showTestString && (
                  <div className="orgPText">
                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          // hiddenLabel
                          label={t("parameter_name")}
                          required
                          autoComplete="off"
                          variant="outlined"
                          className="formTextFieldArea"
                          value={searchTestString}
                          onChange={(e) => {
                            setSearchTestString(e.target.value);
                          }}
                          //   disabled
                        />
                      </FormControl>
                    </div>
                    <ButtonGroup
                      variant="contained"
                      className="buttonContainerGrp"
                    >
                      <Tooltip arrow title={t("save")}>
                        <IconButton
                          onClick={() => {
                            setShowTestString(false);
                            setSearchTestString("");
                          }}
                        >
                          <Cancel />
                        </IconButton>
                      </Tooltip>
                    </ButtonGroup>
                  </div>
                )}
              </th>
              <th>
                <div className="txtWithBtn ">{t("reference_range")}</div>
              </th>
              <th className="w50px">
                <Tooltip arrow title={t("add_parameter")}>
                  <IconButton
                    className="editTimelineBtn viewParamterConfigStepThree"
                    onClick={() => {
                      setShowAddParameter(true);
                    }}
                  >
                    <Add />
                  </IconButton>
                </Tooltip>
              </th>
            </tr>
          </thead>
          <tbody className="scrolableTableBody">
            {filteredParameters?.map((parameter, index) => (
              <tr key={index}>
                <td>
                  <div className="orgPText">
                    {parameter?.parameterName || ""}
                  </div>
                </td>
                <td>
                  <ul className="parameterTdUl">
                    {parameter?.parameterReferances?.map((reference) => {
                      return (
                        <li className="parameterTdLi">
                          <div className="orgPText">
                            <span>{`Gender: ${
                              reference.gender === "C"
                                ? "Both"
                                : reference.gender === "M"
                                ? "Male"
                                : "Female"
                            }, ${
                              reference.pregnantFlag === "Y"
                                ? `Pregnant: ${reference.pregnantFlag}`
                                : ""
                            }`}</span>
                            {/* <span>
                              {reference.gender === "C"
                                ? "Both"
                                : reference.gender === "M"
                                ? "Male"
                                : "Female"}
                            </span> */}
                          </div>
                          {/* {reference.pregnantFlag === "Y" && (
                            <div className="orgPText">
                              <span>Pregnant: </span>
                              <span>{reference.pregnantFlag}</span>
                            </div>
                          )} */}
                          <div className="orgPText">
                            <span>
                              {`Range Type: ${reference.rangeType}, Unit: ${reference.paramUnit}`}{" "}
                            </span>
                            {/* <span>{reference.rangeType}</span> */}
                            {/* <span>Unit: </span>
                            <span>{reference.paramUnit}</span> */}
                          </div>
                          {/* <div className="orgPText">
                            <span>Unit: </span>
                            <span>{reference.paramUnit}</span>
                          </div> */}
                          <div className="orgPText">
                            <span>
                              {`Value: ${calculateValueRange(
                                reference.valLl,
                                reference.valUl
                              )}, Age Limit: ${calculateAgeLimit(
                                reference.ageLl,
                                reference.ageUl
                              )}`}{" "}
                            </span>
                            {/* <span>
                              {calculateValueRange(
                                reference.valLl,
                                reference.valUl
                              )}
                            </span> */}
                          </div>
                          {/* <div className="orgPText">
                            <span>Age Limit: </span>
                            <span>
                              {calculateAgeLimit(
                                reference.ageLl,
                                reference.ageUl
                              )}
                            </span>
                          </div> */}
                        </li>
                      );
                    })}
                  </ul>
                </td>
                <td>
                  <Tooltip arrow title={t("edit")}>
                    <IconButton
                      className="editTimelineBtn viewParamterConfigStepFour"
                      onClick={() => {
                        setEditData(parameter);
                        setShowAddParameter(true);
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>

                  {/* <Tooltip arrow title={t("delete")}>
                        <IconButton className="editTimelineBtn">
                          <Trash />
                        </IconButton>
                      </Tooltip> */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showAddParameter && (
        <RightFlotingAdmin>
          <AddParameterAdmin
            onClose={(shouldUpdateData = false) => {
              setShowAddParameter(false);
              setEditData(null);
              if (shouldUpdateData) {
                fetchParameters();
              }
            }}
            editData={editData}
            mode={editData ? "edit" : "add"}
          />
        </RightFlotingAdmin>
      )}
    </>
  );
};
