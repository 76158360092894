import { Add, ExpandMoreRounded } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { Trash } from "react-bootstrap-icons";
import DashboardServices from "../../../../../../../services/DashboardServices";
import { getHealthDto } from "./consultationUtil";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import SecureIndexedDB from "../../../../../../../utils/IndexedDB";

export default function PhysicalExamination({ updateData, data, extraData }) {
  const { t } = useTranslation();
  const [physicalExamList, setPhysicalExamList] = useState([]);
  const [accordionExpanded, setAccordionExpanded] = useState(true);
  const [examination, setExamination] = useState("");

  const [subSystemList, setSubsystemList] = useState(null);
  const [parameterList, setParameterList] = useState(null);
  const [issueList, setIssuelist] = useState(null);

  const [rootElementList, setRootElementList] = useState([]);

  const [buildMap, setBuildMap] = useState(null);
  const [subSystemMap, setSubSystemMap] = useState(null);
  const [parameterMap, setParameterMap] = useState(null);
  const [issueMap, setIssueMap] = useState(null);

  const [userPhysicalExamList, setUserPhysicalExamList] = useState([]);
  const [subsystem, setSubsystem] = useState(null);
  const [parameter, setParameter] = useState(null);
  const [eIssue, setEIssue] = useState("");
  const [otherObservationValue, setOtherObservationValue] = useState("");

  const handleChangeExamination = (event) => {
    setExamination(event.target.value);
    const value = event.target.value;
    const { children } = buildMap[value.phyExamId];
    console.log("Children =", children);
    setSubSystemMap(children);
    const tempChildren = Object.values(children).map((item) => {
      const { children, ...objValues } = item;
      return objValues;
    });
    setSubsystem("");
    setParameter("");
    setEIssue("");

    setSubsystemList(tempChildren);
    setParameterList(null);
    setIssuelist(null);
  };

  const handleChangeSubsystem = (event) => {
    setSubsystem(event.target.value);
    const value = event.target.value;
    const { children } = subSystemMap[value.phyExamId];
    setParameterMap(children);

    const tempChildren = Object.values(children).map((item) => {
      const { children, ...objValues } = item;
      return objValues;
    });
    setParameter(null);
    setEIssue("");

    setParameterList(tempChildren);
    setIssuelist(null);
  };

  const handleChangeParameter = (event) => {
    setParameter(event.target.value);
    const value = event.target.value;
    const { children } = parameterMap[value.phyExamId];

    setIssueMap(children);

    const tempChildren = Object.values(children).map((item) => {
      const { children, ...objValues } = item;
      return objValues;
    });
    setIssuelist(tempChildren);
    setEIssue("");
  };

  const handleChangeEIssue = (value) => {
    setEIssue(value);
    if (value !== "others") {
      const temp = { system: examination, subsystem, parameter, issue: value };
      console.log("temp =", temp);
      //
      const physicalList = [...userPhysicalExamList, temp];
      setUserPhysicalExamList(physicalList);
      updateData({ physicalExamList: physicalList });
      setEIssue("");
      setParameter("");
      setSubsystem("");
    }
  };

  useEffect(() => {
    getPhyExams();
  }, []);

  useEffect(() => {
    if (data.physicalExamList) {
      setUserPhysicalExamList(data.physicalExamList);
    }
  }, [data.physicalExamList]);

  function buildHierarchy(data) {
    const map = {};
    const roots = {};

    // Create a map of each item by its phyExamId
    data.forEach((item) => {
      map[item.phyExamId] = { ...item, children: {} };
      if (!item.phyExamParId) {
        rootElementList.push(item);
      }
    });

    data.forEach((item) => {
      const parent = map[item.phyExamParId];
      if (parent) {
        // Add the item to its parent's children object
        parent.children[item.phyExamId] = map[item.phyExamId];
      } else {
        // If there's no parent, this item is a root
        roots[item.phyExamId] = map[item.phyExamId];
      }
    });

    return roots;
  }

  useEffect(() => {
    if (physicalExamList) {
      const userSelectedPhysicalExamListIds = userPhysicalExamList?.map(
        (item) => item?.issue?.phyExamId
      );

      const filteredPhysicalExam = physicalExamList?.filter((item) => {
        return !userSelectedPhysicalExamListIds?.includes(item.phyExamId);
      });
      console.log(
        "userselected Physical exams=",
        userSelectedPhysicalExamListIds
      );
      console.log("user data length =", physicalExamList.length);
      console.log("filtered data lengh", filteredPhysicalExam.length);

      const buildMap = buildHierarchy(filteredPhysicalExam);
      console.log("build map =", buildMap);
      setBuildMap(buildMap);

      //-------------------------------------------------------------------//

      if (examination) {
        const value = examination;
        const { children } = buildMap[value.phyExamId];

        setSubSystemMap(children);
        const tempChildren = Object.values(children).map((item) => {
          const { children, ...objValues } = item;
          return objValues;
        });
        setSubsystem("");
        setParameter("");
        setEIssue("");

        setSubsystemList(tempChildren);
        setParameterList(null);
        setIssuelist(null);
      }
    }
    // if (physicalExamList) {
    //   const buildMap = buildHierarchy(physicalExamList);
    //   setBuildMap(buildMap);
    //   console.log("buildMap", buildMap);
    // }
  }, [physicalExamList, userPhysicalExamList]);

  const getPhyExams = async () => {
    const symptomString = await SecureIndexedDB.getItem("physicalExamList");
    if (symptomString) {
      setPhysicalExamList(JSON.parse(symptomString));
      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getPhyExams(reqDto).then((response) => {
      if (response.data) {
        setPhysicalExamList(response.data);
        SecureIndexedDB.setItem(
          "physicalExamList",
          JSON.stringify(response.data)
        );
      }
    });
  };

  return (
    <>
      <Accordion
        className="docInputElmntAcod"
        expanded={accordionExpanded}
        onChange={() => {
          setAccordionExpanded((prev) => !prev);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreRounded />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="acodHeader"
        >
          <div className="tskElemHeding">
            {t("physical_examination")}:{!accordionExpanded && <span></span>}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {extraData?.isDoctor && (
            <>
              <div className="formElementGroup ">
                <div className="formElement  ">
                  <FormControl className="formControl">
                    <InputLabel id="examination-select-label">
                      {t("select_system")}*
                    </InputLabel>
                    <Select
                      labelId="examination-select-label"
                      id="examination-select"
                      required
                      value={examination}
                      label={t("select_system")}
                      onChange={handleChangeExamination}
                      className="formInputField"
                      variant="outlined"
                    >
                      {rootElementList?.map((item) => {
                        return (
                          <MenuItem value={item}>{item.phyExamName}</MenuItem>
                        );
                      })}
                      {/* <MenuItem value="cns"> CNS </MenuItem>
        <MenuItem value="respiratory"> Respiratory </MenuItem>
        <MenuItem value="male_reproductive">
          Male Reproductive
        </MenuItem>
        <MenuItem value="general"> General </MenuItem> */}
                    </Select>
                  </FormControl>
                </div>
              </div>

              {examination && (
                <div className="formElementGroup ">
                  <div className="formElement  ">
                    <FormControl className="formControl">
                      <InputLabel id="subsystem-select-label">
                        {t("select_sub_system")}*
                      </InputLabel>
                      <Select
                        labelId="subsystem-select-label"
                        id="subsystem-select"
                        required
                        value={subsystem}
                        label={t("select_examination")}
                        onChange={handleChangeSubsystem}
                        className="formInputField"
                        variant="outlined"
                      >
                        {subSystemList?.map((item) => {
                          return (
                            <MenuItem value={item}>{item.phyExamName}</MenuItem>
                          );
                        })}
                        {/* <MenuItem value="Palpation"> Palpation </MenuItem>
          <MenuItem value="Percussion"> Percussion </MenuItem>
          <MenuItem value="Percussion">Percussion</MenuItem>
          <MenuItem value="Auscultation"> Auscultation </MenuItem> */}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="formElement  ">
                    <FormControl className="formControl">
                      <InputLabel id="parameter-select-label">
                        {t("select_parameter")}*
                      </InputLabel>
                      <Select
                        labelId="parameter-select-label"
                        id="parameter-select"
                        required
                        disabled={subsystem && subsystem !== "" ? false : true}
                        value={parameter}
                        label={t("select_parameter")}
                        onChange={handleChangeParameter}
                        className="formInputField"
                        variant="outlined"
                      >
                        {parameterList?.map((item) => {
                          return (
                            <MenuItem value={item}>{item.phyExamName}</MenuItem>
                          );
                        })}
                        {/* <MenuItem value="mur"> Muscle Use in Respiration </MenuItem>
          <MenuItem value="rd">Respiratory Distress</MenuItem>
          <MenuItem value="cwa">Chest Wall Anomalies</MenuItem>
          <MenuItem value="asb">Anomalies of Spine & back</MenuItem> */}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="formElement  ">
                    <FormControl className="formControl">
                      <InputLabel
                        id="severity-select-label"
                        // shrink={eIssue && eIssue !== ""}
                      >
                        {t("select_issue")}*
                      </InputLabel>
                      <Select
                        labelId="severity-select-label"
                        id="severity-select"
                        required
                        disabled={parameter && parameter !== "" ? false : true}
                        value={eIssue}
                        label={t("select_issue")}
                        // placeholder={"Select Issue"}
                        onChange={(e) => handleChangeEIssue(e.target.value)}
                        className="formInputField"
                        variant="outlined"
                      >
                        {issueList?.map((item) => {
                          return (
                            <MenuItem value={item}>{item.phyExamName}</MenuItem>
                          );
                        })}
                        {/* <MenuItem value="Kyphosis"> Kyphosis </MenuItem>
          <MenuItem value="Scoliosis"> Scoliosis </MenuItem> */}
                        <MenuItem value="others"> {t("others")} </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              )}
              {eIssue === "others" && (
                <div className="formElementGroup">
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        // hiddenLabel
                        label={t("other_observations")}
                        required
                        autoComplete="off"
                        variant="outlined"
                        className="formTextFieldArea"
                        value={otherObservationValue}
                        onChange={(e) => {
                          setOtherObservationValue(e.target.value);
                        }}
                      />
                    </FormControl>
                  </div>

                  <Button
                    className="dfultPrimaryBtn "
                    startIcon={<Add />}
                    onClick={() => {
                      if (otherObservationValue?.trim() === "") {
                        toast.error(
                          t("other_observation_field_cannot_be_empty")
                        );
                        return;
                      } else {
                        const temp = {
                          phyExamId: "0",
                          phyExamName: otherObservationValue,
                          phyExamParId: parameter.phyExamId,
                          phyExamTopParId: examination.phyExamId,
                          hasChild: null,
                        };

                        handleChangeEIssue(temp);
                        setOtherObservationValue("");
                      }
                    }}
                  >
                    {t("add")}
                  </Button>
                </div>
              )}

              <div className="bb"></div>
            </>
          )}

          <div className="phyExamList">
            <table className="phyExamTable">
              <thead>
                <tr>
                  <th>{t("examination_name")}</th>
                  <th>{t("value")}</th>
                  {extraData?.isDoctor && <th></th>}
                </tr>
              </thead>
              <tbody>
                {userPhysicalExamList?.map((item, index) => {
                  return (
                    <tr>
                      <td> {item.system.phyExamName}</td>
                      <td>{item.issue.phyExamName}</td>
                      {extraData?.isDoctor && (
                        <td>
                          <div className="complstDtlGrp ">
                            <IconButton
                              className="removeItemBtn"
                              onClick={() => {
                                const temp = [...userPhysicalExamList];
                                temp.splice(index, 1);
                                setUserPhysicalExamList(temp);
                                updateData({ physicalExamList: temp });
                              }}
                            >
                              <Trash />
                            </IconButton>
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })}
                {/* <tr>
                  <td> HEENT</td>
                  <td>Pupillary Light Reflex Absent</td>
                  <td>
                    <div className="complstDtlGrp ">
                      <IconButton className="removeItemBtn">
                        <Trash />
                      </IconButton>
                    </div>
                  </td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
