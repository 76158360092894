import React, { useEffect, useRef } from "react";
// import * as d3 from "d3";
import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { StarRounded } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController
);

function UnitTooltip({ data, position, handleClose }) {
  const { t } = useTranslation();
  //   const chartRef = useRef(null);

  //   console.log('data' , data)

  //   // Create the D3 chart inside the tooltip
  //   useEffect(() => {
  //     // if (!data) return;
  //     if (!data || !data.dailyConsultation) return;

  //     // Clear any previous chart
  //     d3.select(chartRef.current).selectAll("*").remove();

  //     const width = 300;
  //     const height = 150;

  //     const chartData = data.dailyConsultation;
  //     const labels = chartData.map(d => d.label);
  //     const counts = chartData.map(d => d.count);

  //     const svg = d3
  //       .select(chartRef.current)
  //       .attr("width", width)
  //       .attr("height", height);

  //     // const xScale = d3.scaleLinear().domain([0, chartData.length - 1]).range([0, width]);
  //     // const yScale = d3.scaleLinear().domain([0, d3.max(chartData)]).range([height, 0]);

  //     const xScale = d3.scaleBand()
  //       .domain(labels)
  //       .range([0, width])
  //       .padding(0.1);

  //     const yScale = d3.scaleLinear()
  //       .domain([0, d3.max(counts)])
  //       .range([height, 0]);

  //     // const line = d3
  //     //   .line()
  //     //   .x((d, i) => xScale(i))
  //     //   .y(d => yScale(d))
  //     //   .curve(d3.curveMonotoneX);

  //     // svg
  //     //   .append("path")
  //     //   .datum(chartData)
  //     //   .attr("fill", "none")
  //     //   .attr("stroke", "#0072BC")
  //     //   .attr("stroke-width", 2)
  //     //   .attr("d", line);

  //     const line = d3.line()
  //     .x((d, i) => xScale(labels[i]) + xScale.bandwidth() / 2) // Center the line on the bands
  //     .y(d => yScale(d));

  //   // Draw the line
  //   svg.append("path")
  //     .datum(counts)
  //     .attr("fill", "none")
  //     .attr("stroke", "#0072BC")
  //     .attr("stroke-width", 2)
  //     .attr("d", line);

  //   // Draw the x-axis
  //   svg.append("g")
  //     .attr("transform", `translate(0, ${height})`)
  //     .call(d3.axisBottom(xScale));

  //   // Draw the y-axis
  //   svg.append("g")
  //     .call(d3.axisLeft(yScale));

  //   }, [data]);

  const chartData = data.dailyConsultation;
  const labels = chartData.map((d) => d.label);
  const counts = chartData.map((d) => d.count);

  const barLineData = {
    labels: labels,
    datasets: [
      {
        label: t("daily_consultation_trend"),
        type: "line",
        data: counts,
        borderColor: "rgba(255, 0, 0, 1)",
        backgroundColor: "rgba(255, 0, 0, .5)",
        fill: false,
        borderWidth: 1,
        tension: 0.3,
      },
    ],
  };

  const barLineOptions = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 16,
          padding: 10,
        },
      },
      title: {
        display: false,
        text: "",
      },
    },
    barThickness: 10,
    maxBarThickness: 20,
    minBarLength: 2,
  };

  return (
    <div
      className="tooltip"
      //   style={{
      //     position: "absolute",
      //     top: `${position.y}px`,
      //     left: `${position.x}px`,
      //     backgroundColor: "white",
      //     padding: "10px",
      //     borderRadius: "5px",
      //     boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      //     pointerEvents: "none",
      //   }}
      style={{
        position: "absolute",
        // top: `${y}px`,
        // left: `${x}px`,
        bottom: "10%",
        left: "20%",
        transform: "translate(-40% ,-10%)",
        backgroundColor: "white",
        border: "1px solid #ccc",
        padding: "10px",
        minWidth: "420px",
        borderRadius: "6px",
        pointerEvents: "none",
        boxShadow: "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)",
        zIndex: 10,
      }}
    >
      {data ? (
        <>
          <div className="cnameWithRat">
            <div className="cenName">
              <strong>{data.name}</strong>
            </div>
            <div className="cenRate">
              {data.rating}
              <StarRounded />
            </div>
          </div>

          <div className="centerHilights">
            <div className="cenCardInd">
              <span className="smlTxt">{t("till_date_consultation")}:</span>{" "}
              <span className="keyTxt">{data.tillDateConsultation}</span>
            </div>
            <div className="cenCardInd">
              <span className="smlTxt">{t("consultation_sharing")}:</span>
              <span className="keyTxt">{data.consultationShare}%</span>
            </div>

            <div className="cenCardInd">
              <span className="smlTxt">{t("daily_average")}:</span>
              <span className="keyTxt">{data.ref}</span>
            </div>
          </div>

          <div className="bestOneList">
            <span>
              <strong>{t("top_3_diseases")}:</strong>
            </span>
            {data.diseases ? data.diseases.join(", ") : ""}
          </div>
          <div>
            <strong>{t("daily_consultation_trend")}:</strong>

            {/* <svg ref={chartRef}></svg> */}
            <Line
              className="hw100 maxHeight150"
              type="line"
              data={barLineData}
              options={barLineOptions}
            />
          </div>
          {/* <div className="floatRight">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={() => handleClose()}
                >
                  Close
                </Button></div> */}
        </>
      ) : null}
    </div>
  );
}

export default UnitTooltip;
