import { ArrowBack } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
// import ReactDatePicker from "react-datepicker";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateUtils from "../../../../../utils/DateUtils";
import { jwtDecode } from "jwt-decode";
import { Search } from "react-bootstrap-icons";
import DashboardServices from "../../../../../services/DashboardServices";
import { useTranslation } from "react-i18next";
import { getOrgByOrgId } from "../../../../../services/AdminService";
import { enIN, es } from "date-fns/locale";
import Joyride from "react-joyride";
import { useContext } from "react";
import { TourContext } from "../../../../../common/TourProvider";
import { GetLoginUserDetails } from "../../../../../utils/Utils";

// Register locales with ReactDatePicker
registerLocale("en", enIN);
registerLocale("es", es);

export default function ViewReferredPatient({
  handleCloseViewReferredPatient,
  closeAll,
}) {
  const userDetails = GetLoginUserDetails();
  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [primaryPosdate, setPrimaryPosdate] = useState(new Date());
  const [referredPatientData, setReferredPatientData] = useState([]);
  const [orgDetailsDoiData, setorgDetailsDoiData] = useState("");
  const handleJoiningprimaryPosdate = (newDate) => {
    setPrimaryPosdate(newDate);
  };

  useEffect(() => {
    fetchReferredPatientData();
  }, []);

  useEffect(() => {
    setBookSteps([
      {
        target: ".referpatStepOne",
        content: "Click here to close the tour",
        placement: "top",
        disableBeacon: true,
      },
      {
        target: ".referpatStepTwo",
        content: "Select a date for the referral",
        placement: "right",
        disableBeacon: true,
      },
      {
        target: ".referpatStepThree",
        content: "Click the search button to get data",
        placement: "top",
        disableBeacon: true,
      },
      {
        target: ".referpatStepFour",
        content: "Loading patient data...",
        placement: "bottom",
        disableBeacon: true,
      },
      {
        target: ".referpatStepFive",
        content: "No patient data found",
        placement: "bottom",
        disableBeacon: true,
      },
      {
        target: ".referpatStepSix",
        content: "Here is the patient data",
        placement: "top",
        disableBeacon: true,
      },
      {
        target: ".referpatStepSeven",
        content: "Scroll through the table for more patients",
        placement: "bottom",
        disableBeacon: true,
      },
      {
        target: ".referpatStepEight",
        content: "Each row shows patient details",
        placement: "bottom",
        disableBeacon: true,
      },
    ]);
  }, []);

  // Determine the locale based on the current language
  const currentLocale = i18n.language === "es" ? "es" : "en";

  const fetchReferredPatientData = () => {
    DashboardServices.getReferredPatients(
      userDetails.signleUnitId,
      DateUtils.getDateInDDMMYYYY(primaryPosdate)
    ).then((response) => {
      console.log("response of consul List", response.data);
      setReferredPatientData(response.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchgetOrgDetails();
  }, []);

  const fetchgetOrgDetails = () => {
    getOrgByOrgId(userDetails.orgId)
      .then((response) => {
        const DateOfIncorp = response.data.doi;
        if (DateOfIncorp !== null || DateOfIncorp !== "") {
          setorgDetailsDoiData(response.data.doi);
        }
      })
      .catch((error) => {
        console.error("Error fetching getOrgDetails", error);
      });
  };

  const parseDateString = (dateString) => {
    if (!dateString) {
      return null;
    }
    const [day, month, year] = dateString.split("-").map(Number);
    return new Date(year, month - 1, day);
  };

  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["viewReferPatns"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        styles={{
          buttonClose: {
            display: "none",
          },
        }}
      />
      <div className="AnalyticsDashboardContainer nsReferPatienttepsOne">
        <div className="AnalyticsDashboardSubHeader referpatStepOne">
          <IconButton
            onClick={() => {
              console.log("closing");
              handleCloseViewReferredPatient();
              closeAll();
            }}
          >
            <ArrowBack />
          </IconButton>
          {t("referred_patient")}
        </div>
        <div className="anDashCardContainArea">
          <div className="fullContainArea">
            <div className="cuHeaderGrp">
              <div className="formSearchGrp">
                <div className="formElement width180">
                  <FormControl className="formControl">
                    <InputLabel id="primPosition" className="setCompletionDate">
                      {t("select_date")}
                    </InputLabel>
                    <ReactDatePicker
                      locale={currentLocale}
                      toggleCalendarOnIconClick
                      showYearDropdown
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                      showMonthDropdown
                      showIcon
                      labelId="primPosition"
                      className="formDatePicker referpatStepTwo"
                      selected={primaryPosdate}
                      // minDate={new Date()}
                      minDate={parseDateString(orgDetailsDoiData)}
                      maxDate={new Date()}
                      dateFormat="dd/MM/yyyy"
                      onChange={(date) => {
                        handleJoiningprimaryPosdate(date);
                      }}
                    />
                  </FormControl>
                </div>
                <Button
                  className="dfultPrimaryBtn referpatStepThree"
                  onClick={fetchReferredPatientData}
                  startIcon={<Search />}
                >
                  {t("search")}
                </Button>
              </div>
            </div>

            <div className="cuContainArea">
              {loading ? (
                <div
                  className="center-progress referpatStepFour"
                  style={{ height: "65vh" }}
                >
                  <CircularProgress sx={{ marginTop: "180px" }} />
                </div>
              ) : referredPatientData.length === 0 ? (
                <div class="noDataCard referpatStepFive">
                  {/* <span>oops</span>No data */}
                  <br />
                  <span className="sallMsg">
                    {t("no_refer_patient_data_is_found")}
                  </span>
                </div>
              ) : (
                <div className="TableContainer">
                  <table className="taskListTable referpatStepSix">
                    <thead className="taskListtableHeader">
                      <tr>
                        <th>
                          <div className="textWsearch">
                            <span>{t("patient_name")}</span>
                          </div>
                        </th>
                        <th>{t("doctor_name")}</th>
                        <th>{t("doctor_note")}</th>
                        <th>{t("referral_note")}</th>
                      </tr>
                    </thead>

                    <tbody className="scrolableTableBody referpatStepSeven">
                      {referredPatientData.map((refer, index) => (
                        <tr key={index}>
                          <td>
                            <div className="tskLstTaskNM tdFdC patientIdNameReview">
                              <span className="tdHtext">
                                {refer.patientName}
                              </span>
                              <span className="tdStext ">
                                {refer.patientExternalSourceId
                                  ? refer.patientExternalSourceId
                                  : refer.patientIdDisplay
                                  ? refer.patientIdDisplay
                                  : ""}
                              </span>
                              <span className="tdStext">
                                (
                                {refer?.patientGender === "Male"
                                  ? t("male")
                                  : refer?.patientGender === "Female"
                                  ? t("female")
                                  : t("other")}
                                , {refer.patientAge})
                              </span>
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM tdFdC referpatStepEight">
                              <span className="tdHtext">
                                {refer.doctorName}
                              </span>
                              <span className="tdStext">
                                ({refer.doctorSpecialization})
                              </span>
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM referpatStepEight">
                              {refer.doctorNote
                                ? refer.doctorNote
                                : t("no_doctor_notes")}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM referpatStepEight">
                              {refer.refferalNote
                                ? refer.refferalNote
                                : t("no_referral_notes")}
                            </div>
                          </td>
                        </tr>
                      ))}
                      {/* <tr>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">Vikash Kumar</span>
                          <span className="tdStext">(Male, 25/Aug/1993)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM tdFdC">
                          <span className="tdHtext">Dr. Prashnat Shah</span>
                          <span className="tdStext">(Cardiologists)</span>
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          <span className="constStatus statCancel">Cancel</span>
                        </div>
                      </td>

                      <td>
                      </td>
                    </tr> */}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
