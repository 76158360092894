import {
  Button,
  FormControl,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { XLg } from "react-bootstrap-icons";
import isAssignedByMe from "../../../taskUtil";
import { GetLoginUserDetails } from "../../../../../utils/Utils";
import DashboardServices from "../../../../../services/DashboardServices";

export default function RightSideDisagree({
  selectedTasks,
  handleClose,
  updateTaskList,
}) {
  const [disagreeData, setDisagreeData] = useState([]);
  const [updatePercentList, setUpdatePercentList] = useState([]);
  const [currentPercentList, setCurrentPercentList] = useState([]);
  const numberList = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  const loginUserDetail = GetLoginUserDetails();

  useEffect(() => {
    setDisagreeData(
      selectedTasks.map((task) => {
        return { remark: "" };
      })
    );
    const currentPercents = selectedTasks.map((task) => task.progress);
    setUpdatePercentList(currentPercents);
    setCurrentPercentList(currentPercents);
  }, []);

  const handleDisagree = async () => {
    const result = await Promise.all(
      selectedTasks.map((task, index) => {
        return new Promise((resolve, reject) => {
          DashboardServices.disagreementTask(
            task.taskId,
            loginUserDetail.userId,
            updatePercentList[index],
            disagreeData[index].remark
          ).then((response) => {
            if (response.data !== "0") {
              const value = { ...selectedTasks[index] };
              value.progress = updatePercentList[index];
              resolve(value);
            } else {
              resolve({ ...selectedTasks[index] });
            }
          });
        });
      })
    );
  };

  return (
    <div className="RightFlotingContainerArea">
      <div className="taskViewPannel">
        <div className="taskViewContainer">
          <div className="taskHeaderPanel">
            <div className="taskTitleContrct">
              <div className="taskTitleNM">{`Disagree ${selectedTasks.length} Tasks `}</div>
            </div>
            <div className="taskControlBtnGrp"></div>

            <div className="taskActionBtnGrp">
              <div className="taskActBtn closeBtnD10">
                <IconButton aria-label="close" onClick={handleClose}>
                  <Tooltip title="Close" arrow>
                    <XLg />
                  </Tooltip>
                </IconButton>
              </div>
            </div>
          </div>

          <div className="taskContain">
            <div className="taskContainDetail">
              <div className="taskContDtlContainer">
                {selectedTasks &&
                  selectedTasks.length > 0 &&
                  selectedTasks.map((task, index) => {
                    return (
                      <>
                        <div className="multipleUpdateTaskCard">
                          <div className="taskTitleContrct">
                            <div className="taskHeadingNM">{task.taskName}</div>
                          </div>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                // sx={{ color: "white" }}
                                label="Disagreement Remark*"
                                variant="outlined"
                                className="descptionTextFild"
                                multiline
                                rows={3}
                                maxRows={7}
                                value={
                                  disagreeData &&
                                  disagreeData.length > 0 &&
                                  disagreeData[index].remark
                                }
                                // value={reason}
                                onChange={(e) => {
                                  const temp = [...disagreeData];
                                  temp[index].remark = e.target.value;
                                  setDisagreeData(temp);
                                }}
                              />
                            </FormControl>
                          </div>
                          <div className="commonUpdate">
                            <div className="workUpdateBtnGrp">
                              {numberList &&
                                numberList.map((number) => (
                                  <Button
                                    className={`${
                                      updatePercentList[index] === number
                                        ? "activeStatus"
                                        : ""
                                    }`}
                                    variant="contained"
                                    disabled={
                                      number <= currentPercentList[index]
                                    }
                                    onClick={(e) => {
                                      const temp = [...updatePercentList];
                                      temp[index] = number;
                                      setUpdatePercentList(temp);
                                      console.log("temp value =", temp);
                                    }}
                                  >
                                    {number}
                                  </Button>
                                ))}
                            </div>

                            <div className="tskCretdByElmnt">
                              <span className="assuTag">Created By :</span>
                              <span className="assuName">
                                {task && isAssignedByMe(task.createdByUserId)
                                  ? "Me"
                                  : task.createdByUserName}
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="taskFooterPanel">
            <div className="formBtnElement">
              <Button
                className="dfultPrimaryBtn"
                onClick={(e) => {
                  handleDisagree();
                  handleClose();
                }}
              >
                Submit
              </Button>
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={(e) => handleClose()}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
