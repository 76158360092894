import React, { useEffect, useMemo, useRef, useState } from "react";
import "../../../css/task.css";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  Modal,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  ArrowBackIosNew,
  ArrowForwardIos,
  AssignmentLateOutlined,
  AttachFile,
  Audiotrack,
  CancelOutlined,
  CheckCircle,
  DescriptionOutlined,
  Edit,
  ListAlt,
  Send,
  ShortcutOutlined,
  VideoCall,
} from "@mui/icons-material";
import {
  ArrowLeft,
  Back,
  Diagram3,
  People,
  Play,
  Plus,
  XLg,
} from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  getFormattedDate,
  GetLoginUserDetails,
} from "../../../../../utils/Utils";
import DashboardServices from "../../../../../services/DashboardServices";
import { convertFileToBase64_Split } from "../../../../../utils/FileUtils";
import {
  clearTaskModalData,
  moveToPreviousTask,
  pushLinkTask,
  setTaskModalData,
} from "../../../../../redux/reducers/taskTab";
import TemplateList from "../TemplateList";
import FileList from "../FileList";
import ForwardTaskModal from "../ForwardTaskModal";
import LinkTaskModal from "../LinkTaskModal";
import ConcernTaskModal from "../ConcernTaskModal";
import AssigneeModal from "../AssigneeModal";
import RightFlotingContainer from "../RightFlotingContainer";
import { clearSelectedTask } from "../../../../../redux/reducers/rightSideTaskPanelSlice";
import { Refresh } from "iconoir-react";
import ReassignTaskModal from "../Modals/ReassignTaskModal";
import RightTaskFileUpload from "../RightTaskFileUpload";
import { LinkedTaskModule } from "../LinkedTaskModule";
import isAssignedByMe from "../../../taskUtil";
import { useContext } from "react";
import { taskTabContext } from "../../TaskTabContainer";
import TaskService from "../../../../../services/TaskService";
import MeetingModal from "../Modals/MeetingModal";
import { PurchaseFormRenderer } from "../Forms/PurchaseFormRenderer";
import SelfEvaluationPmsForm from "../Forms/SelfEvaluationPmsForm";
import PmsManagerReviewForm from "../Forms/PmsManagerReviewForm";
import PmsFinalReviewForm from "../Forms/PmsFinalReviewForm";
import { IncomingFormPreview } from "../Forms/IncomingFormPreview";
import PmsSalaryAdjustmentForm from "../Forms/PmsSalaryAdjustmentForm";
import { useTranslation } from "react-i18next";
import { EditDeadLineModal } from "../Modals/EditDeadLineModal";
import { PriorityComponent } from "../TaskComponent/PriorityComponent";
import { TaskNameModal } from "../Modals/TaskNameModal";
import { DescriptionComponent } from "../Modals/DescriptionComponent";
import { TaskContractModal } from "../Modals/TaskContractModal";

export default function TaskTabIncomingOngoing({
  data,
  refreshData,
  handleClose,
  removeFromStore,
}) {
  const { t } = useTranslation();
  const context = useContext(taskTabContext);
  const { setShowConvenientMeetingView } = context;
  const convinientSelecteduser = context.setSelectedUsers;
  const setMeetingList = useRef();
  const [showMeetingModal, setShowMeetingsModal] = useState(false);

  const [showFileModal, setShowFileUploadModal] = useState(false);

  const [formData, setFormData] = useState(null);
  const [showForm, setShowForm] = useState(false);

  const scrollRef = useRef();

  const audioUrl = useRef();
  // const [audioUrl, setAudioUrl] = useState();
  const numberList = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  const [tempUpdatePercent, setTempUpdatePercent] = useState(0);
  const loginUserDetail = GetLoginUserDetails();
  const [progressWidth, setProgressWidth] = useState(0);
  const progress = useRef(0);

  const [dateRemaning, setDateRemaning] = useState(0);
  const fileElementRef = useRef(null);
  const [filearrowDisable, setFilearrowDisable] = useState(true);
  // left right tags scroll
  const elementRef = useRef(null);
  const [arrowDisable, setArrowDisable] = useState(true);
  const [fileDivWidth, setFileDivWidth] = useState(0);
  const [openUserList, setOpenUserList] = useState(false);
  const [assigneeList, setAssigneeList] = useState([]);
  const commentInputRef = useRef();
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const fileDocuments = useRef(null);
  const [showForwardTask, setShowForwardTask] = useState(false);
  const [userList, setUserList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [forwardReason, setForwardReason] = useState("");
  const [showCreateLinkTask, setShowCreateLinkTask] = useState(false);
  const [linkTask, setLinkTask] = useState("");
  const [linkTaskDescription, setLinkTaskDescription] = useState("");

  const [showLinkFileUpload, setShowFileUploadDialog] = useState(false);
  const [linkDocuments, setLinkDocuments] = useState([]);

  const [showAddTemplate, setShowAddTemplate] = useState(false);
  const [recommendedTemplateList, setRecommendedTemplateList] = useState([]);
  const [addedTemplateList, setAddedTemplateList] = useState([]);
  const [tempOfOrgList, setTemplateOfOrgList] = useState([]);
  const [showConcernModal, setShowConcernModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [concernTitle, setConcernTitle] = useState("");
  const [showEditDeadline, setShowEditDeadLine] = useState(false);

  const [updatePercentValue, setUpdatePercentValue] = useState(0);

  const [approvalConPop, setApprovalConPop] = React.useState(null);
  const open = Boolean(approvalConPop);
  const id = open ? "simple-popover" : undefined;

  const [anchorUl, setAnchorUl] = React.useState(null);
  const openUl = Boolean(anchorUl);
  const ulId = openUl ? "simple-popover" : undefined;

  const [showAudioModal, setShowAudioModal] = useState(false);
  const [showAssigneeList, setShowAssigneeList] = useState(false);

  const [linkTaskTree, setLinkTaskTree] = useState(null);
  const links = useSelector((state) => state.taskList.taskLinks);
  // const linkTaskHtml=useMemo(()=>{

  // },data)

  const handleTaskSubmission = (response) => {
    // Failure message start
    if (response.data.returnValue === "0") {
      toast.error(t("something_went_wrong"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (response.data.returnValue === "-1") {
      toast.error(t("link_task_not_completed_against_this_task"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (response.data.returnValue === "-2") {
      toast.error(t("task_has_been_cancelled"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (response.data.returnValue === "-3") {
      toast.error("task_has_been_closed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (response.data.returnValue === "-4") {
      toast.error(t("task_has_been_completed"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    // Failure message end

    // Success message start
    else if (response.data.returnValue === "1") {
      toast.success(t("task_completed_successfully"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (response.data.returnValue === "2") {
      toast.success(t("concern_raised_successfully"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (response.data.returnValue === "3") {
      toast.success(t("approved_successfully"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (response.data.returnValue === "4") {
      toast.success(t("task_progress_report_submitted_successfully"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    const temp = response.data.returnValue;
    if (temp === "1" || temp === "2" || temp === "3" || temp === "4") {
      setShowConvenientMeetingView(false);
      convinientSelecteduser([]);
      dispatch(clearSelectedTask());
      refreshData();
    }
    // Success message end
  };

  const fetchExpenseApprovalRequest = () => {
    // TaskService.getIndentItemsByIndentId(data.formDataTransId).then(
    //   (response) => {
    //     if (response.data) {
    //       setFormData(response.data);
    //       setShowForm(true);
    //     }
    //   }
    // );

    if (
      data.taskNature === "PMS_SELF_EVALUATION" ||
      data.taskNature === "PMS_MANAGER_REVIEW" ||
      data.taskNature === "PMS_FINAL_REVIEW" ||
      data.taskNature === "PMS_SALARY_ADJUSTMENT"
    ) {
      setShowForm(true);
      return;
    }

    if (data.taskNature === "INTERVIEW_FEEDBACK") {
      TaskService.getProspectiveEmployeeById(data.formDataTransId).then(
        (response) => {
          if (response.data) {
            setFormData(response.data);
            setShowForm(true);
          }
        }
      );
    } else {
      TaskService.getExpenseApprovalRequest(data.formDataTransId).then(
        (response) => {
          if (response.data) {
            setFormData(response.data);
            setShowForm(true);
          }
        }
      );
    }

    // TaskService.getExpenseApprovalRequest(data.formDataTransId).then(
    //   (response) => {
    //     if (response.data) {
    //       setFormData(response.data);
    //       setShowForm(true);
    //     }
    //   }
    // );
  };

  const handleClickFormButton = () => {
    fetchExpenseApprovalRequest();
  };

  const renderLinkTask = (taskObj) => {
    return (
      <>
        <ol className="listOfLinkTask">
          {taskObj &&
            taskObj.length > 0 &&
            taskObj.map((task) => (
              <>
                <li
                  className="linkTskInd"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleLinkTaskClick(task);
                  }}
                >
                  <span className="linkTskNm">{task.taskName}</span>
                  {task.children &&
                    task.children.length > 0 &&
                    renderLinkTask(task.children)}
                </li>
              </>
            ))}
        </ol>
      </>
    );
  };

  const gotoPreviousTAsk = () => {
    dispatch(moveToPreviousTask());
  };

  const handleCloseUl = () => {
    setAnchorUl(null);
  };

  const clearStoreData = () => {
    dispatch(clearTaskModalData());
  };

  const handleClickUl = (event) => {
    setAnchorUl(event.currentTarget);
  };

  const closeApprovalConPop = () => {
    setApprovalConPop(null);
  };
  const openApprovalConPop = (event) => {
    setApprovalConPop(event.currentTarget);
    setUpdatePercentValue(data.progress);
  };

  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setArrowDisable(true);
      } else {
        setArrowDisable(false);
      }
    }, speed);
  };

  const intervalProgressor = (timeDelay) => {
    const intervalId = setInterval(() => {
      setDateRemaning((prev) => {
        console.log("prev =", prev);
        if (prev < 100) {
          return prev + 1;
        }
        clearInterval(intervalId);
        return prev;
      });
      // if (progress.current < 100) {
      //   setDateRemaning((prev) => prev + 1);
      //   // setProgressWidth((prev) => prev + 1);
      //   progress.current = progress.current + 1;
      // } else {
      //   clearInterval(intervalId);
      // }
    }, timeDelay);
  };

  const handleAddTemplate = async () => {
    const value = await DashboardServices.getAllTemplatesOfOrganization(
      loginUserDetail.orgId
    );
    setTemplateOfOrgList(value.data);

    const response = await DashboardServices.getTemplatesOfSubActivity(
      data.activityId,
      0
    );
    let notaddedTemplateList = [];
    if (response.data != null && response.data.length > 0) {
      response.data.forEach((template) => {
        let isTemplateAdded = false;
        addedTemplateList.forEach((addedTemplate) => {
          if (template.templateId === addedTemplate.templateId) {
            isTemplateAdded = true;
          }
        });
        if (!isTemplateAdded) {
          notaddedTemplateList.push(template);
        }
      });
      setRecommendedTemplateList(notaddedTemplateList);
    }
    console.log("hello");
    setShowAddTemplate(true);
  };

  const handleSubmitTransferTask = () => {
    if (forwardReason === "") {
      toast.error("Please Enter Transfer Reason", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (setSelectedUsers.length === 0) {
      toast.error("Please Select User", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      const reqDto = {
        taskDetailId: data.taskId,
        fromUserId: loginUserDetail.userId,
        toUserId: selectedUsers.userId,
        transferReason: forwardReason,
      };
      console.log("submit ", reqDto);
      // setLoading(true);
      DashboardServices.transferTask(reqDto).then((response) => {
        if (response.data) {
          if (response.data.returnValue === "1") {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setForwardReason("");
            setUserList(null);
            setShowForwardTask(false);
          } else {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      });
    }
  };

  const handleAddnewFile = () => {
    fileInputRef.current.click();
  };

  const getFilteredData = () => {
    const taskCreator = data.assignByUserId;

    const filteredData = userList.filter((item) => {
      return (
        item.userId !== loginUserDetail.userId || item.userId !== taskCreator
      );
    });
    return filteredData;
  };

  const clearAllModals = () => {
    setShowForwardTask(false);
    setShowConcernModal(false);
    setShowCreateLinkTask(false);
    setShowAssigneeList(false);
    closeApprovalConPop();
  };

  const handleChangeFile = async (fileList) => {
    const reqDto = fileList.map((file) => ({
      id: 0,
      taskDtlId: data.taskId,
      fileName: file.fileName,
      fileDataLink: file.fileDataLink,
      uploadedBy: loginUserDetail.userId,
      fileDisplayName: file.fileName,
    }));
    console.log("reqDto = ", reqDto);

    DashboardServices.uploadDocuments(
      loginUserDetail.orgId,
      loginUserDetail.userId,
      data.taskId,
      reqDto
    ).then((response) => {
      console.log("document sent is ", response.data);
      if (response.data) {
        const tempData = { ...data };
        if (tempData.documents === null) {
          tempData.documents = [...response.data];
        } else {
          tempData.documents = [...data.documents, ...response.data];
        }
        dispatch(setTaskModalData(tempData));
      }
    });
    console.log("file document = ", fileDocuments.current);
  };

  const handleSubmitCreateLinkTask = () => {
    console.log("link value = ", data);

    const submitDto = {
      taskMasterId: parseInt(data.activityId),
      subTask: data.activityId,
      scheduleCompletionTime: "2024-01-30T14:30:00.136Z",
      taskDescription: linkTaskDescription,
      priority: data.priority,
      assigness: selectedUsers && selectedUsers.map((user) => user.userId),
      documents: linkDocuments,
      previousTaskId: parseInt(data.taskId),
      contractId: null,
      formId: "",
      formDataTransId: "",
    };

    try {
      DashboardServices.createLinkTask(
        loginUserDetail.userId,
        loginUserDetail.orgId,
        submitDto
      ).then((response) => {
        // console.log(response.data);
        if (response.data) {
          if (response.data.returnValue == "1") {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLinkTask("");
            setLinkTaskDescription("");
            setLinkDocuments([]);
          } else {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      });
    } catch (error) {
      toast.error(t("something_went_wrong"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      // handelCloseOpenCreateSubTask();
    }
  };
  const getTaskDifference = () => {
    // const time1=task.taskCreationTime
    // const time2 =task.taskCompletionTime
    const creationTime = new Date(data && data.createdAt);
    const completionTime = new Date(data && data.completionTime);
    const currentTime = new Date();

    //check if already time passed

    const diffFromCurrent = currentTime - completionTime;
    if (diffFromCurrent > 0) {
      setDateRemaning(100);
      return;
    }

    // Calculate the time difference in milliseconds
    const totalTimeDifference = completionTime - creationTime;
    const timeDifferenceFromNow = currentTime - creationTime;
    const milisecondPerPercentIncrease = totalTimeDifference / 100;
    const currentPercent = (timeDifferenceFromNow * 100) / totalTimeDifference;
    setDateRemaning(
      Math.floor(currentPercent) < 100 ? Math.floor(currentPercent) : 100
    );
    progress.current = Math.floor(currentPercent);
    const nextPercent = Math.ceil(currentPercent);
    const difference = nextPercent - currentPercent;
    const diffInMilisecond = milisecondPerPercentIncrease * difference;
    const clearTimeoutVal = setTimeout(() => {
      if (currentPercent < 100) {
        console.log("before interval progress", milisecondPerPercentIncrease);
        clearTimeout(clearTimeoutVal);
        setDateRemaning((prev) => prev + 1);
        progress.current = progress.current + 1;
        if (milisecondPerPercentIncrease > 0) {
          intervalProgressor(milisecondPerPercentIncrease);
        }
      }
    }, diffInMilisecond);
    console.log("currentPercent", currentPercent);
    console.log(
      "milisecondPerPercentIncrease = ",
      milisecondPerPercentIncrease / 1000
    );
  };

  const sendComment = (e) => {
    if (!data) {
      toast.error(t("something_went_wrong"));
      return;
    }
    const comment = commentInputRef.current.value;
    const reqDto = {
      taskDetailId: data.taskId,
      progress: data.progress,
      comments: comment,
      referenceId: 0,
      commentsType: null,
    };
    console.log("reqDto = ", reqDto);
    const commentsAt = new Date().toISOString();
    // return;
    if (comment.trim() !== "") {
      DashboardServices.sendTaskComments(loginUserDetail.userId, reqDto).then(
        (response) => {
          console.log("out response data= ", response);
          if (response.data == 1) {
            const tempData = { ...data };
            tempData.comments = [
              ...tempData.comments,
              {
                comments: comment,
                commentsType: null,
                commentsByUserName: loginUserDetail.userName,
                commentsByUserProfileUrl: loginUserDetail.userProfileImage,
                progress: data.progress,
                commentsAt: commentsAt,
              },
            ];
            dispatch(setTaskModalData(tempData));
            commentInputRef.current.value = "";

            const timeoutValue = setTimeout(() => {
              scrollRef.current.scrollIntoView({ behavior: "smooth" });
              clearTimeout(timeoutValue);
            }, 500);
          } else {
            toast.error(t("something_went_wrong"));
          }
        }
      );
    }
  };

  const filehandleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setFilearrowDisable(true);
      } else {
        setFilearrowDisable(false);
      }
    }, speed);
  };

  const calculateDateProgress = () => {};
  const handleLinkTaskClick = async (task) => {
    // console.log("task id =", taskId);
    const values = await DashboardServices.getTaskById(task.taskId);
    dispatch(pushLinkTask(data));
    dispatch(setTaskModalData({ ...task, ...values.data }));
  };

  const listToTree = (arr) => {
    let map = {},
      node,
      res = [],
      i;

    for (i = 0; i < arr.length; i += 1) {
      map[arr[i].taskId] = i;
    }

    for (i = 0; i < arr.length; i += 1) {
      node = arr[i];
      if (node && node.parTaskId && node.parTaskId !== "") {
        const mapIndex = map[node.parTaskId];
        if (mapIndex) {
          if (arr[mapIndex].children) {
            arr[mapIndex].children.push(node);
          } else {
            arr[mapIndex].children = [node];
          }
        } else {
          res.push(node);
        }
      } else {
        res.push(node);
      }
    }

    const compareById = (a, b) => a.taskId - b.taskId;

    return res.sort(compareById);
  };

  useEffect(() => {
    if (fileElementRef.current) {
      setFileDivWidth(fileElementRef.current.offsetWidth);
    }
  }, [fileElementRef]);

  const [tempDivWidth, setTempDivWidth] = useState(0);

  useEffect(() => {
    if (elementRef.current) {
      setTempDivWidth(elementRef.current.offsetWidth);
    }
  }, [elementRef]);

  useEffect(() => {
    console.log("in task Container");
    setProgressWidth(data.progress);
    calculateDateProgress();
    DashboardServices.getAssigneesOfTask(data.taskId).then((response) => {
      setAssigneeList(response.data);
    });

    DashboardServices.getOrgEmployees(loginUserDetail.orgId).then(
      (response) => {
        console.log("all contract  = ", response.data);
        setUserList(response.data);
      }
    );

    getTaskDifference();
  }, [data]);

  return (
    <>
      <div className="taskViewPannel">
        <div className="taskViewContainer">
          <div className="taskHeaderPanel">
            <div className="taskControlBtnGrp">
              <div className="taskVDContBtn">
                <Button
                  variant="contained"
                  startIcon={<CheckCircle />}
                  onClick={(e) => {
                    clearAllModals();
                    openApprovalConPop(e);
                  }}
                  // onClick={(e) => {
                  //   console.log("update clicked");
                  //   setShowUpdateModal(true);
                  //   setUpdatePercentValue(data.progress);
                  // }}
                >
                  {t("update")}
                </Button>

                <Popover
                  id={id}
                  open={open}
                  anchorEl={approvalConPop}
                  onClose={closeApprovalConPop}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <div style={{ padding: "20px" }}>
                    <div className="modelTitle">{t("confirmation")}</div>
                    <div
                      className="modInputFild"
                      style={{ padding: "20px 10px 10px 0px" }}
                    >
                      {t("select_your_progress_of_task")}
                    </div>
                    <div className="workUpdateBtnGrp">
                      {}
                      {numberList &&
                        numberList.map((number) => (
                          <Button
                            className={`${
                              tempUpdatePercent === number ? "activeStatus" : ""
                            }`}
                            variant="contained"
                            disabled={number <= updatePercentValue}
                            onClick={(e) => {
                              setTempUpdatePercent(number);
                              console.log("");
                            }}
                          >
                            {number}
                          </Button>
                        ))}
                    </div>

                    <div className="modActionBtnGrp">
                      <Button
                        className="dfultPrimaryBtn"
                        onClick={(e) => {
                          if (tempUpdatePercent === 0) {
                            toast.error(t("please_select_percentage_of_work"));
                            return;
                          }
                          DashboardServices.updateProgressOfTask(
                            data.taskId,
                            loginUserDetail.userId,
                            tempUpdatePercent
                          ).then((response) => {
                            if (response.data.returnValue !== "0") {
                              toast.success(t("task_progress_updated"));
                              const temp = { ...data };
                              temp.progress = tempUpdatePercent;
                              dispatch(setTaskModalData(temp));
                              setProgressWidth(tempUpdatePercent);
                              setUpdatePercentValue(tempUpdatePercent);
                              closeApprovalConPop();
                              if (tempUpdatePercent === 100) {
                                dispatch(clearTaskModalData());
                              }
                              refreshData();
                            } else {
                              toast.error(t("something_went_wrong"));
                            }
                          });
                        }}
                      >
                        {tempUpdatePercent === 100
                          ? t("mark_as_completed")
                          : t("update")}
                      </Button>
                      <Button
                        className="dfultDarkSecondaryBtn"
                        onClick={closeApprovalConPop}
                      >
                        {t("cancel")}
                      </Button>
                    </div>
                  </div>
                </Popover>
              </div>
              {/* <div className="taskVDContBtn">
                <Button variant="outlined">Approve</Button>
              </div>
              <div className="taskVDContBtn">
                <Button variant="outlined">Reject</Button>
              </div> */}
            </div>

            <div className="taskActionBtnGrp">
              <div className="taskActBtn">
                <IconButton
                  aria-label="Forward"
                  onClick={() => {
                    clearAllModals();
                    setShowForwardTask(true);
                  }}
                >
                  <Tooltip title={t("delegate")} arrow>
                    <ShortcutOutlined />
                  </Tooltip>
                </IconButton>
              </div>
              <div className="taskActBtn">
                <IconButton
                  aria-label="Concern"
                  onClick={(e) => {
                    clearAllModals();
                    setShowConcernModal(true);
                  }}
                >
                  <Tooltip title={t("concern")} arrow>
                    <AssignmentLateOutlined />
                  </Tooltip>
                </IconButton>
              </div>
              <div className="taskActBtn">
                <IconButton
                  aria-label="Link Task"
                  onClick={(e) => {
                    clearAllModals();
                    setShowCreateLinkTask(true);
                  }}
                >
                  <Tooltip title={t("link_task")} arrow>
                    <Diagram3 />
                  </Tooltip>
                </IconButton>
              </div>
              <div className="taskActBtn">
                <IconButton
                  aria-label="Meeting"
                  onClick={(e) => {
                    TaskService.getEventsofTask(data.taskId).then(
                      (response) => {
                        console.log("event task =", response.data);
                        setMeetingList.current = response.data;
                        if (response.data && response.data.length > 0) {
                          setShowMeetingsModal(true);
                        } else {
                          setShowConvenientMeetingView(true);
                          convinientSelecteduser(assigneeList);
                        }
                      }
                    );
                  }}
                >
                  <Tooltip title={t("meeting")} arrow>
                    <VideoCall />
                  </Tooltip>
                </IconButton>
              </div>
              <div className="taskActBtn">
                <IconButton
                  aria-label="User"
                  onClick={(e) => {
                    // setOpenUserList(true);
                    // handleClickUl(e);
                    clearAllModals();
                    setShowAssigneeList(true);
                  }}
                >
                  <Tooltip title={t("user")} arrow>
                    <People />
                  </Tooltip>
                </IconButton>
              </div>

              <div className="taskActBtn closeBtnD10">
                <IconButton
                  aria-label="close"
                  onClick={(e) => {
                    setShowConvenientMeetingView(false);
                    convinientSelecteduser([]);
                    dispatch(clearTaskModalData());
                  }}
                >
                  <Tooltip title={t("close")} arrow>
                    <XLg />
                  </Tooltip>
                </IconButton>
              </div>
            </div>
          </div>
          <div className="taskContain">
            <div className="taskContainDetail">
              <div className="taskContDtlContainer">
                {links && links.length > 0 && (
                  <div className="linktaskBreadCrumb">
                    <IconButton onClick={gotoPreviousTAsk}>
                      <ArrowLeft />
                    </IconButton>
                    <div className="prentTaskNM">
                      {links &&
                        links.length > 0 &&
                        links[links.length - 1].taskName}
                    </div>
                  </div>
                )}
                {/* <div className="taskTitleContrct">
                  <div className="taskTitleNM">{data.taskName}</div>
                </div>

                {data && data.taskDescription && (
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">{t("description")}</div>
                    <div className="tskElemntInfo">{data.taskDescription}</div>
                  </div>
                )} */}

                <TaskNameModal
                  data={data}
                  refreshData={refreshData}
                  removeFromStore={removeFromStore}
                />

                <DescriptionComponent
                  data={data}
                  refreshData={refreshData}
                  removeFromStore={removeFromStore}
                />

                {data && data.audioNoteFileName && (
                  <div className="taskElementGrp">
                    <div className="tskElemntInfo">
                      <div className="playAudioNotes">
                        <IconButton
                          variant="outlined"
                          onClick={(e) => {
                            DashboardServices.generatePreSignedUrlS3Object(
                              data.audioNoteFileName,
                              loginUserDetail.orgId
                            ).then((response) => {
                              audioUrl.current = response.data;
                              setShowAudioModal(true);
                            });
                          }}
                        >
                          <Play />
                        </IconButton>
                        <span>{t("play_audio_message")}</span>
                      </div>
                    </div>
                  </div>
                )}

                <TaskContractModal
                  data={data}
                  refreshData={refreshData}
                  removeFromStore={removeFromStore}
                />

                {/* {data && data.contractName && (
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">{t("contract")}</div>
                    <div className="taskContractNm">{data.contractName}</div>
                  </div>
                )} */}

                <div className="taskElementGrp">
                  <div className="tskElemHeding">{t("status")}</div>
                  <div className="tskTimeline">
                    <div className="tskTimBar">
                      <div className="progress">
                        <div
                          // ps50 ps80 ps100
                          className={`progress-done ${
                            progressWidth < 50
                              ? "ps50"
                              : progressWidth < 80
                              ? "ps80"
                              : "ps100"
                          }`}
                          style={{ width: `${progressWidth}%`, opacity: 1 }}
                        >
                          <span className="pwCount">{progressWidth}%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="taskElementGrp">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div className="tskElemHeding">{t("timeline")}</div>
                    {data.incoming === "N" && (
                      <Tooltip arrow title={t("edit_timeline")}>
                        <IconButton
                          className="editTimelineBtn"
                          onClick={() => {
                            setShowEditDeadLine(true);
                          }}
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                  <div className="tskTimeline">
                    <div className="tskTimBar">
                      <div className="progress">
                        <div
                          // up50 up80 up100
                          className={`progress-done ${
                            dateRemaning < 50
                              ? "up50"
                              : dateRemaning < 80
                              ? "up80"
                              : "up100"
                          }`}
                          style={{ width: `${dateRemaning}%`, opacity: 1 }}
                        ></div>
                      </div>
                    </div>
                    <div className="tskDtSE">
                      <div className="tskDTStart">
                        {getFormattedDate(data.createdAt)}
                      </div>
                      <div className="tskDTEnd">
                        {getFormattedDate(data.completionTime)}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Templates start */}
                {/* <div className="taskElementGrp">
                  <div className="tskElemHeding">Template</div>

                  <div class="elementFileListedGrp">
                    <div className="horaizonScroll">
                      <IconButton
                        className="goPrevousBtn"
                        onClick={() => {
                          handleHorizantalScroll(
                            elementRef.current,
                            25,
                            200,
                            -10
                          );
                        }}
                        disabled={arrowDisable}
                      >
                        <ArrowBackIosNew />
                      </IconButton>
                      <div>
                        <div class="elemntFilsgrp" ref={elementRef}>
                          {data &&
                            data.templates.map((template) => (
                              <div className="elemntFile">
                                <Tooltip
                                  title={template.fileDisplayName}
                                  arrow
                                  className="elmFileTolTip"
                                >
                                  <span className="elemntFilIoc">
                                    <DescriptionOutlined />
                                  </span>
                                  <span className="elemntFilNm">
                                    {template.fileDisplayName}
                                  </span>
                                </Tooltip>
                              </div>
                            ))}
                        </div>
                      </div>
                      {tempDivWidth > 470 && (
                        <IconButton
                          className="goNextBtn"
                          onClick={() => {
                            handleHorizantalScroll(
                              elementRef.current,
                              25,
                              200,
                              10
                            );
                          }}
                        >
                          <ArrowForwardIos />
                        </IconButton>
                      )}
                    </div>
                    <div class="elmntAddMrFiles">
                      <Button
                        className="elemntMrFileBtn"
                        variant="outlined"
                        onClick={handleAddTemplate}
                      >
                        <Plus />
                      </Button>
                    </div>
                  </div>
                </div> */}

                <TemplateList
                  data={data}
                  tempDivWidth={tempDivWidth}
                  handleAddTemplate={handleAddTemplate}
                />
                {/* Templates end */}

                {/* files start */}
                {/* <div className="taskElementGrp">
                  <div className="tskElemHeding">Files</div>

                  <div class="elementFileListedGrp">
                    <div className="horaizonScroll">
                      <IconButton
                        className="goPrevousBtn"
                        onClick={() => {
                          filehandleHorizantalScroll(
                            fileElementRef.current,
                            25,
                            200,
                            -10
                          );
                        }}
                        disabled={filearrowDisable}
                      >
                        <ArrowBackIosNew />
                      </IconButton>

                      <div class="elemntFilsgrp" ref={fileElementRef}>
                        {data &&
                          data.documents &&
                          data.documents.map((doc) => (
                            <div className="elemntFile">
                              <Tooltip
                                title={doc.fileDisplayName}
                                arrow
                                className="elmFileTolTip"
                              >
                                <span className="elemntFilIoc">
                                  <DescriptionOutlined />
                                </span>
                                <span className="elemntFilNm">
                                  {doc.fileDisplayName}
                                </span>
                              </Tooltip>
                            </div>
                          ))}
                      </div>
                      {fileDivWidth > 470 && (
                        <IconButton
                          className="goNextBtn"
                          onClick={() => {
                            filehandleHorizantalScroll(
                              fileElementRef.current,
                              25,
                              200,
                              10
                            );
                          }}
                        >
                          <ArrowForwardIos />
                        </IconButton>
                      )}
                    </div>
                    <div class="elmntAddMrFiles">
                      <Button
                        className="elemntMrFileBtn"
                        variant="outlined"
                        onClick={handleAddnewFile}
                      >
                        <Plus />
                      </Button>
                      <input
                        type="file"
                        ref={fileInputRef}
                        onChange={handleChangeFile}
                        style={{ display: "none" }}
                      ></input>
                    </div>
                  </div>
                </div> */}

                <FileList
                  data={data}
                  tempDivWidth={tempDivWidth}
                  handleChangeFile={handleChangeFile}
                  setShowFileUploadModal={setShowFileUploadModal}
                />

                {data &&
                  data.formDataTransId &&
                  data.formDataTransId !== "" &&
                  data.formDataTransId !== "0" && (
                    <div className="taskElementGrp">
                      <div className="tskElemHeding">{t("form_data")}</div>
                      <IconButton
                        className="elemntMrFileBtn"
                        variant="outlined"
                        onClick={handleClickFormButton}
                      >
                        <ListAlt />
                      </IconButton>
                    </div>
                  )}

                {/* files end */}
                {/* {data && data.audioNoteFileName && (
                  <div className="taskElementGrp">
                    <IconButton
                      className="elemntMrFileBtn"
                      variant="outlined"
                      onClick={(e) => {
                        DashboardServices.generatePreSignedUrlS3Object(
                          data.audioNoteFileName,
                          loginUserDetail.orgId
                        ).then((response) => {
                          audioUrl.current = response.data;
                          setShowAudioModal(true);
                        });
                      }}
                    >
                      <Audiotrack />
                    </IconButton>
                  </div>
                )} */}

                {/* <div className="taskElementGrp">
                  <div className="tskElemHeding">{t("priority")}</div>
                  <div className="tskPrioArea">
                    <div
                      class={`tskPriot  ${
                        data.priority === 0
                          ? "statNormal"
                          : data.priority === 1
                          ? "statImportant"
                          : "statUrgent"
                      }`}
                    >
                      <div class={`priStat `}>
                        {data.priority === 0
                          ? t("regular")
                          : data.priority === 1
                          ? t("important")
                          : t("critical")}
                      </div>
                    </div>
                  </div>
                </div> */}

                <PriorityComponent
                  data={data}
                  refreshData={refreshData}
                  removeFromStore={removeFromStore}
                />

                <div className="taskElementsGrpWithTwo">
                  {/* <div className="taskElementGrp">
                    <div className="tskElemHeding">Department</div>
                    <div className="tskElemntInfo">Administration</div>
                  </div> */}
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">{t("activity")}</div>
                    <div className="tskElemntInfo">{data.activityName}</div>
                  </div>
                </div>

                <div className="taskElementsGrpWithTwo">
                  {/* <div className="taskElementGrp">
                    <div className="tskElemHeding">Assigned to</div>
                    <div className="tskElemntInfo">Me</div>
                  </div> */}
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">{t("assigned_by")}</div>
                    <div className="tskElemntInfo">
                      {data && isAssignedByMe(data.assignByUserId)
                        ? t("me")
                        : data.assignByUserName}
                    </div>
                  </div>
                </div>

                <LinkedTaskModule
                  data={data}
                  handleLinkTaskClick={(data) => handleLinkTaskClick(data)}
                />

                {/* <div className="taskElementGrp">
                  <div className="tskElemHeding">Link Task</div>
                  <div className="tskElemntInfo"> */}
                {/* <ul className="listOfLinkTask">
                        <li className="linkTskInd">
                          <div className="linkTskNm">
                            Create Prototype of App design
                          </div>
                        </li>
                        <li className="linkTskInd">
                          <div className="linkTskInd">
                            Approval Request for buy new Laptop
                          </div>
                        </li>
                      </ul> */}

                {/* {linkTaskTree && renderLinkTask(linkTaskTree)} */}

                {/* <ol className="listOfLinkTask">
                      <li className="linkTskInd">
                        <span className="linkTskNm">one</span>
                      </li>
                      <li className="linkTskInd">
                        <span className="linkTskNm">Two</span>
                        <ol className="listOfLinkTask">
                          <li className="linkTskInd">
                            <span className="linkTskNm">Two.One</span>
                          </li>
                          <li className="linkTskInd">
                            <span className="linkTskNm">Two.Two</span>
                            <ol className="listOfLinkTask">
                              <li className="linkTskInd">
                                <span className="linkTskNm">Two.Two.One</span>
                              </li>
                              <li className="linkTskInd">
                                <span className="linkTskNm">Two.Two.Two</span>
                              </li>
                              <li className="linkTskInd">
                                <span className="linkTskNm">Two.Two.Three</span>
                              </li>
                              <li className="linkTskInd">
                                <span className="linkTskNm">Two.Two.Four</span>
                              </li>
                            </ol>
                          </li>
                          <li className="linkTskInd">
                            <span className="linkTskNm">Two.Three</span>
                          </li>
                        </ol>
                      </li>
                      <li className="linkTskInd">
                        <span className="linkTskNm">Three</span>
                        <ol className="listOfLinkTask">
                          <li className="linkTskInd">
                            <span className="linkTskNm">Three.One</span>
                          </li>
                          <li className="linkTskInd">
                            <span className="linkTskNm">three.two</span>
                            <ol className="listOfLinkTask">
                              <li className="linkTskInd">
                                <span className="linkTskNm">three.two.one</span>
                              </li>
                              <li className="linkTskInd">
                                <span className="linkTskNm">three.two.two</span>
                              </li>
                            </ol>
                          </li>
                        </ol>
                      </li>
                      <li className="linkTskInd">
                        <span className="linkTskNm">four</span>
                      </li>
                    </ol> */}
                {/* </div>
                </div> */}

                {data && data.comments && data.comments.length && (
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">{t("last_updated")}</div>
                    <div className="tskElemntInfo">
                      {data &&
                        data.comments &&
                        data.comments.length > 0 &&
                        `${
                          data.comments[data.comments.length - 1]
                            .commentsByUserName
                        } - ${getFormattedDate(
                          data.comments[data.comments.length - 1].commentsAt
                        )}`}
                      {/* Sandeep Mohapatra - 2:15PM, 06-12-2023 */}
                    </div>
                  </div>
                )}

                <div className="taskUpCommentGrp">
                  {data &&
                    data.comments.length > 0 &&
                    data.comments.map((comment) => (
                      <div className="taskUserComment">
                        <div className="tskUderImgDT">
                          <div className="tskUseComImg">
                            <img
                              src={comment.commentsByUserProfileUrl}
                              alt=""
                            />
                          </div>
                          <div className="tskUsrCNM">
                            <div className="tskUCNM">
                              {comment.commentsByUserName}
                            </div>
                            <div className="tskUCDT">
                              {comment && getFormattedDate(comment.commentsAt)}
                            </div>
                          </div>
                        </div>
                        <div className="taskUPComnt">{comment.comments}</div>
                      </div>
                    ))}
                </div>
                <div ref={scrollRef}></div>
              </div>
            </div>
          </div>
          <div className="taskFooterPanel">
            <div className="tskInputGrp">
              <input
                ref={commentInputRef}
                type="text"
                className="tskComntInput"
                placeholder={t("update_comment_here")}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    console.log("Enter key pressed");
                    sendComment(event);
                  }
                }}
              />
              <IconButton className="tskComtSndBtn" onClick={sendComment}>
                <Send />
              </IconButton>
            </div>
          </div>
        </div>

        {showMeetingModal && (
          <MeetingModal
            handleClose={(e) => setShowMeetingsModal(false)}
            assigneeList={assigneeList}
            taskId={data.taskId}
          />
        )}

        {showForwardTask && (
          <ForwardTaskModal
            data={data}
            userList={userList}
            refreshData={refreshData}
            handleClose={() => {
              setShowForwardTask(false);
            }}
            clearStoreData={clearStoreData}
          />
        )}

        {/* {showCreateLinkTask && (
          <>
            <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
              <div className="addMoreAgendaModel">
                <div className="modelTitle">Create Link Task</div>
                <div
                  className="modInputFild"
                  style={{ border: "1px solid black" }}
                >
                  <TextField
                    sx={{ color: "balck !important" }}
                    label="Description"
                    variant="outlined"
                    multiline
                    rows={4}
                    maxRows={7}
                    value={linkTaskDescription}
                    onChange={(e) => setLinkTaskDescription(e.target.value)}
                  />
                </div>

                <div className="modInputFild">
                  <Autocomplete
                    multiple
                    id="adduser-select"
                    disableClearable
                    className="formAutocompleteField"
                    variant="outlined"
                    options={userList}
                    getOptionLabel={(option) => option.userName}
                    getOptionSelected={(option) => {
                      console.log("option is ", option);
                      return option.userId;
                    }}
                    onChange={(e, newValue) => {
                      console.log("new value = ", newValue);
                      setSelectedUsers(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        sx={{ color: "black" }}
                        {...params}
                        placeholder="Search User"
                        InputProps={{
                          ...params.InputProps,
                          type: "search",
                        }}
                      />
                    )}
                  />
                </div>
                <div className="modInputFild">
                  <Button
                    variant="outlined"
                    startIcon={<AttachFile />}
                    className="comBtnOutline"
                    onClick={(e) => {
                      setShowFileUploadDialog(true);
                    }}
                  >
                    Attach File
                  </Button>
                </div>

                <div className="modActionBtnGrp">
                  <Button
                    className="dfultPrimaryBtn"
                    onClick={(e) => {
                      handleSubmitCreateLinkTask();
                    }}
                  >
                    Submit
                  </Button>
                  <Button
                    className="dfultDarkSecondaryBtn"
                    onClick={() => {
                      setShowCreateLinkTask(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Box>
            {showLinkFileUpload && (
              <FileUpload
                openFileUploadDialog={showLinkFileUpload}
                showUploadSection={true}
                uploadedFiles={linkDocuments}
                downloadFileList={[]}
                submitFileUpload={(file) => setLinkDocuments(file)}
                handelCloseFileUploadDialog={() =>
                  setShowFileUploadDialog(false)
                }
              />
            )}
          </>
        )} */}

        {showCreateLinkTask && (
          <LinkTaskModal
            userList={userList}
            data={data}
            handleClose={(e) => setShowCreateLinkTask(false)}
            refreshData={refreshData}
            removeFromStore={removeFromStore}
          />
        )}

        {showConcernModal && (
          <ConcernTaskModal
            data={data}
            refreshData={refreshData}
            handleClose={(e) => setShowConcernModal(false)}
            removeFromStore={removeFromStore}
          />
        )}

        {showAssigneeList && (
          <AssigneeModal
            assigneeList={assigneeList}
            handleClose={(e) => setShowAssigneeList(false)}
          />
        )}

        {showAudioModal && (
          <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
            <div className="addMoreAgendaModel">
              <div className="modelTitle">{t("audio_message")}</div>
              <div className="modActionBtnGrp">
                <audio controls>
                  <source src={`${audioUrl.current}`} />
                </audio>
              </div>

              <div className="modActionBtnGrp">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={() => {
                    setShowAudioModal(false);
                  }}
                >
                  {t("cancel")}
                </Button>
              </div>
            </div>
          </Box>
        )}

        {showEditDeadline && (
          <EditDeadLineModal
            createdAt={data.createdAt}
            completionTime={data.completionTime}
            closeModal={() => {
              setShowEditDeadLine(false);
            }}
            onClose={(e) => setShowEditDeadLine(false)}
            userList={userList}
            handleClose={(e) => setShowEditDeadLine(false)}
            previousAssigness={assigneeList}
            data={data}
            refreshData={refreshData}
            removeFromStore={removeFromStore}
          />
        )}
      </div>
      {showAddTemplate && (
        <RightFlotingContainer
          recommendedTemplateList={recommendedTemplateList}
          setRecommendedTemplateList={setRecommendedTemplateList}
          tempOfOrgList={tempOfOrgList}
          addedTemplateList={addedTemplateList}
          setAddedTemplateList={setAddedTemplateList}
          closeTemplate={() => {
            setShowAddTemplate(false);
            if (addedTemplateList && addedTemplateList.length > 0) {
              const temp = [...addedTemplateList];
              temp.forEach((template) => {
                template.taskDetailId = data.taskId;
              });
              DashboardServices.addTaskTemplate(
                loginUserDetail.userId,
                addedTemplateList,
                "OLD"
              ).then((response) => {
                console.log("addTaskTemplate", response.data);
                const tempData = { ...data };
                if (tempData.templates) {
                  tempData.templates = [
                    ...tempData.templates,
                    ...addedTemplateList,
                  ];
                } else {
                  tempData.templates = [...addedTemplateList];
                }
                dispatch(setTaskModalData(tempData));
              });
            }
          }}
          mode={"old"}
        />
      )}
      {showFileModal && (
        <div className="RightFlotingContainerArea">
          <RightTaskFileUpload
            documents={data.documents}
            onClose={(e) => setShowFileUploadModal(false)}
            uploadFile={(data) => {
              handleChangeFile(data);
            }}
          />
        </div>
      )}

      {/* {showForm && (
        <PurchaseFormRenderer
          data={data}
          taskId={data.taskId}
          taskNature={data.taskNature}
          formData={formData}
          handleClose={() => {
            setShowForm(false);
          }}
          handleSubmitPO={(date) => {
            if (date) {
              let reqDto = {
                taskDetailId: data.taskId,
                orgId: loginUserDetail.orgId,
                documents: [],

                taskCreator: "N",
                taskForwordToUserIdAfterApproval: "",
                additionalInfo: {
                  "Effective Date": date,
                },
                progress: data.progress,
                concern: data.concern,
                comments: data.comments,
                submitButtonLabel: data.submitButtonLabel,
                partialSubmitChecked: data.progress === 100 ? "N" : "Y",
                previousAssigness: [],
                currentAssigness: [],
              };
              console.log("reqdto =", reqDto);
              return;
              DashboardServices.updateProgressOfTask(
                loginUserDetail.userId,
                reqDto
              ).then((response) => {
                handleTaskSubmission(response);
              });
            }
          }}
        />
      )} */}

      {showForm &&
        (data.taskNature === "SUBMIT_RFP" ||
          data.taskNature === "PO_APPROVAL_REQUEST" ||
          data.taskNature === "GRN_ENTRY" ||
          data.taskNature === "PAYMENT_AGAINST_REIMBURSEMENT_REQUEST" ||
          data.taskNature === "PAYMENT_AGAINST_ADVANCE_REQUEST" ||
          data.taskNature === "PAYMENT_AGAINST_SIP_REQUEST" ||
          data.taskNature === "SEARCH_MANPOWER" ||
          data.taskNature === "SHORTLIST_RESUMES" ||
          data.taskNature === "INTERVIEW_FEEDBACK") && (
          <PurchaseFormRenderer
            data={data}
            taskId={data.taskId}
            taskNature={data.taskNature}
            formData={formData}
            handleClose={() => {
              setShowForm(false);
            }}
            handleSubmitPO={(date) => {
              if (date) {
                let reqDto = {
                  taskDetailId: data.taskId,
                  orgId: loginUserDetail.orgId,
                  documents: [],

                  taskCreator: "N",
                  taskForwordToUserIdAfterApproval: "",
                  additionalInfo: {
                    "Effective Date": date,
                  },
                  progress: data.progress,
                  concern: data.concern,
                  comments: data.comments,
                  submitButtonLabel: data.submitButtonLabel,
                  partialSubmitChecked: data.progress === 100 ? "N" : "Y",
                  previousAssigness: [],
                  currentAssigness: [],
                };
                console.log("reqdto =", reqDto);
                // return;
                DashboardServices.updateProgressOfTask(
                  loginUserDetail.userId,
                  reqDto
                ).then((response) => {
                  handleTaskSubmission(response);
                });
              }
            }}
          />
        )}

      {showForm && data.taskNature === "PMS_SELF_EVALUATION" && (
        <SelfEvaluationPmsForm
          data={data}
          handleClose={() => setShowForm(false)}
          refreshData={refreshData}
          removeFromStore={removeFromStore}

        />
      )}

      {showForm && data.taskNature === "PMS_MANAGER_REVIEW" && (
        <PmsManagerReviewForm
          data={data}
          handleClose={() => setShowForm(false)}
          refreshData={refreshData}
          removeFromStore={removeFromStore}
        />
      )}

      {showForm && data.taskNature === "PMS_FINAL_REVIEW" && (
        <PmsFinalReviewForm
          data={data}
          handleClose={() => setShowForm(false)}
          refreshData={refreshData}
          removeFromStore={removeFromStore}

        />
      )}

      {showForm && data.taskNature === "PMS_SALARY_ADJUSTMENT" && (
        <PmsSalaryAdjustmentForm
          data={data}
          handleClose={() => setShowForm(false)}
          refreshData={refreshData}
          removeFromStore={removeFromStore}

        />
      )}

      {showForm && data.taskNature === "EXPENSE_APPROVAL_REQUEST" && (
        <IncomingFormPreview
          taskId={data.taskId}
          taskNature={data.taskNature}
          taskCreatedBy={data.assignByUserId}
          formData={formData}
          handleClose={() => {
            setShowForm(false);
          }}
          handleSubmitValue={() => {
            setShowForm();
            dispatch(clearSelectedTask());
            refreshData();
          }}
        />
      )}
    </>
  );
}
