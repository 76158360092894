import React, { useEffect, useState } from "react";
import brandLogo from "../../images/BrandLogo.svg";
import { Box, Button, MenuItem, Select } from "@mui/material";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

export default function NavBarLP({
  handelOpenLogRegContain,
  scrollableNavRef,
  handelOpenFeatures,
  handelOpenAboutUs,
}) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false); // State to track whether the menu is open or closed
  const [isScrolled, setIsScrolled] = useState(false); // State to track whether the user has scrolled
  const [selectedLanguage, setSelectedLanguage] = useState("en");

  // Function to toggle the menu state
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Function to handle scroll events to add class in navbar
  const handleScroll = () => {
    if (scrollableNavRef.current.scrollTop > 300) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    const scrollableDiv = scrollableNavRef.current;

    if (scrollableDiv) {
      scrollableDiv.addEventListener("scroll", handleScroll);
      return () => {
        scrollableDiv.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const handleSelectedLanguageChange = (event) => {
    console.log("handgleChangeSelectedLanguage", event.target.value);
    i18n.changeLanguage(event.target.value);
    setSelectedLanguage(event.target.value);
  };

  return (
    <>
      <header className={`top-navbar ${isScrolled ? "top-navbar-fixed" : ""}`}>
        <nav className="navbar-container">
          <div className="navbar-logoLP">
            <a href="https://zoyel.one/">
              <img src={brandLogo} alt="Zoyel" height="50" width="50" />
            </a>
          </div>
          <div>
            <div className="mobNavBtnGrp">
              <div className="signInBtn signBtnMobile">
                <span className="loginBtn" onClick={handelOpenLogRegContain}>
                  {t("sign_in")}
                </span>
              </div>
              <button
                className={`menu-toggle ${isOpen ? "open" : ""}`}
                onClick={toggleMenu}
              >
                <span></span>
                <span></span>
                <span></span>
              </button>
            </div>
            <ul className={`navbar-links ${isOpen ? "open" : ""}`}>
              <li className="lpItems">
                <span className="ipmenu" onClick={handelOpenFeatures}>
                  {t("features")}
                </span>
              </li>

              <li className="lpItems">
                <a href="#">{t("how_it_works")}</a>
              </li>
              <li className="lpItems centerLogo">
                <a href="#">
                  <img src={brandLogo} alt="Zoyel" height="111" width="100" />
                </a>
              </li>
              <li className="lpItems">
                <a href="#">{t("safety")}</a>
              </li>
              <li className="lpItems">
                <span className="ipmenu" onClick={handelOpenAboutUs}>
                  {t("about_us")}
                </span>
              </li>
              <li className="lpItems signInBtn signBtnDesktop">
                <span className="loginBtn" onClick={handelOpenLogRegContain}>
                  {t("sign_in")}
                </span>
              </li>
            </ul>
          </div>
          <div className="navChangeLang">
            <Select
              className="langChangeSelect"
              value={selectedLanguage}
              onChange={handleSelectedLanguageChange}
              inputProps={{ "aria-label": "Select Language" }}
            >
              <MenuItem value="en">English</MenuItem>
              <MenuItem value="es">Spanish</MenuItem>
            </Select>
          </div>
        </nav>
      </header>
    </>
  );
}
