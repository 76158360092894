import React from "react";
import "../../css/aboutUs.css";
import { Grid } from "@mui/material";
import zoyelLogo from "../../images/BrandLogo.svg";

export default function AboutUs() {
  return (
    <>
      <section className="aboutUsContainerWthImg">
        <div className="headingText">About Us</div>
      </section>
      <section className="aboutContain">
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <div className="zoyelImg">
              <img src={zoyelLogo} alt="aboutZoyel" />
            </div>
          </Grid>
          <Grid item xs={12} md={8}>
            <div className="aboutText">
              Zoya Technologies LLC is a pioneering technology provider
              focussing on building seamless platform for making business easier
              and ensuring that the power of Artificial Intelligence, IoT, Cloud
              computing, web3 and other software and hardware technologies are
              available for growth and scaling of businesses, irrespective of
              their sizes. Its main licensed activities are Computer systems and
              Communication equipment, Software design, Data classification and
              Analysis services, Information Technology Network Services, IT
              Infrastructure, Portal, Internet Content Services, Portal, Data
              Entry Services, Web Design, and Social Media Applications
              Development & Services. Established in April 2022, under the
              Department of Economic Development, Emirate of Dubai, it is a
              Dubai Mainland Company with registered office at Bena Complex C,
              Oud Metha, Dubai, United Arab Emirates.
            </div>
          </Grid>
        </Grid>
      </section>
    </>
  );
}
