import { Box, Button, IconButton, Tooltip } from "@mui/material";
import React, { useState, useRef } from "react";
import "../../css/flotingContainer.css";
import { Drag } from "iconoir-react";
import { useDrag } from "react-dnd";
import HomeIcon from "@mui/icons-material/Home";
import {
  AdminPanelSettings,
  BlurOn,
  CalendarMonth,
  ChatBubble,
  CorporateFare,
  Dashboard,
  DesignServices,
  Email,
  FilePresent,
  PlaylistAdd,
  Storage,
  Videocam,
  Web,
  Tab,
  Close,
  MinimizeOutlined,
  RemoveOutlined,
} from "@mui/icons-material";
import { ListTask } from "react-bootstrap-icons";

export default function FlotingContainer({
  children,
  shouldComputeDragAfter,
  setCurrentDragging,
  floatingComponent,
  minimize,
}) {
  console.log("children", children);
  console.log("children name ", children.type.name);
  console.log("floatingComponent", floatingComponent);
  const [isClosed, setIsClosed] = useState(false);

  // const initialWidth = window.innerWidth * 0.5;
  // const initialHeight = window.innerHeight * 0.5;

  // console.log('initialWidth', initialWidth);
  // console.log('initialHeight' , initialHeight)

  const [position, setPosition] = useState({ top: 50, left: 50 });
  const [size, setSize] = useState({ width: 1200, height: 400 });
  const containerRef = useRef(null);

  const [{ isDragging }, drag] = useDrag({
    type: "FLOATING_DIV",
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    end: (item, monitor) => {
      const delta = monitor.getDifferenceFromInitialOffset();
      if (delta) {
        const left = Math.round(position.left + delta.x);
        const top = Math.round(position.top + delta.y);
        setPosition({ top, left });
      }
    },
    // end: (item, monitor) => {
    //   const dropResult = monitor.getDropResult();
    //   if (dropResult) {
    //     // Handle drop result if needed
    //   }
    //   if (shouldComputeDragAfter.current) {
    //     // Update the position state based on the final drag position
    //     const delta = monitor.getDifferenceFromInitialOffset();
    //     console.log("delta = ", delta);
    //     const left = Math.round(position.left + delta.x);
    //     const top = Math.round(position.top + delta.y);
    //     setPosition({ top, left });
    //   }
    // },
  });

  const handleDragStart = () => {
    setCurrentDragging();
  };

  // const handleDrag = (event) => {
  //   const delta = {
  //     x: event.clientX - position.current.x,
  //     y: event.clientY - position.current.y,
  //   };

  //   // Update the position state based on the drag delta
  //   setPosition((prevPosition) => ({
  //     left: prevPosition.left + delta.x,
  //     top: prevPosition.top + delta.y,
  //   }));
  // };

  const handleMouseDown = (e, direction) => {
    e.preventDefault();
    const startX = e.clientX;
    const startY = e.clientY;
    const startWidth = size.width;
    const startHeight = size.height;
    const startLeft = position.left;
    const startTop = position.top;

    const doDrag = (dragEvent) => {
      let newWidth = startWidth;
      let newHeight = startHeight;
      let newLeft = startLeft;
      let newTop = startTop;

      if (direction.includes("right")) {
        console.log("startWidth right", startWidth);
        console.log("dragEvent.clientX right ", dragEvent.clientX);
        console.log("startX right", startX);
        newWidth = startWidth + dragEvent.clientX - startX;
        console.log("newWidth right", newWidth);
      }
      if (direction.includes("bottom")) {
        console.log("startHeight bottom", startHeight);
        console.log("dragEvent.clientY bottom ", dragEvent.clientY);
        console.log("startY bottom", startY);
        newHeight = startHeight + dragEvent.clientY - startY;
        console.log("newHeight bottom", newHeight);
      }
      if (direction.includes("left")) {
        console.log("startWidth left", startWidth);
        console.log("dragEvent.clientX left ", dragEvent.clientX);
        console.log("startX left", startX);
        console.log("startLeft left", startLeft);
        newWidth = startWidth - (dragEvent.clientX - startX);
        newLeft = startLeft + (dragEvent.clientX - startX);
        console.log("newWidth left", newWidth);
        console.log("newLeft left", newLeft);
      }
      if (direction.includes("top")) {
        console.log("startHeight top", startHeight);
        console.log("dragEvent.clientY top ", dragEvent.clientY);
        console.log("startY top", startY);
        console.log("startTop top", startTop);
        newHeight = startHeight - (dragEvent.clientY - startY);
        newTop = startTop + (dragEvent.clientY - startY);
        console.log(" newHeight top", newHeight);
        console.log("newTop top", newTop);
      }

      setSize({ width: newWidth, height: newHeight });
      setPosition({ top: newTop, left: newLeft });
    };

    const stopDrag = () => {
      document.documentElement.removeEventListener("mousemove", doDrag, false);
      document.documentElement.removeEventListener("mouseup", stopDrag, false);
    };

    document.documentElement.addEventListener("mousemove", doDrag, false);
    document.documentElement.addEventListener("mouseup", stopDrag, false);
  };

  const handleClose = () => {
    setIsClosed(true);
    children = {};
    floatingComponent = {};
  };

  const closeButtonStyle = {
    position: "absolute",
    top: `${(5 / size.height) * 100}%`,
    right: `${(5 / size.width) * 100}%`,
    padding: "2px",
    cursor: "pointer",
    color: "white",
    border: "none",
    zIndex: "1",
  };

  const MinimizeButtonStyle = {
    position: "absolute",
    top: `${(5 / size.height) * 100}%`,
    right: `${(5 / size.width) * 100}%`,
    paddingRight: "40px",
    paddingTop: "3px",
    paddingBottom: "5px",
    paddingLeft: "5px",
    cursor: "pointer",
    color: "white",
    border: "none",
    zIndex: 1,
  };

  const handleMinimize = () => {
    minimize();
  };

  return (
    <>
      {!isClosed && (
        <Box
          sx={{
            bgcolor: "dark.navTab",
            top: position.top,
            left: position.left,
            width: size.width,
            height: size.height,
            position: "absolute",
            resize: "both",
            overflow: "auto",
            border: "1px solid #606060",
          }}
          className="flotingDragAndDropContainer"
          ref={drag}
          onDragStart={handleDragStart}
          // onDrag={handleDrag}
        >
          {/* <Button startIcon={<Drag />}>Drag Here</Button> */}
          {/* <Button style={{color : 'white'}}>{floatingComponent.title}</Button> */}
          <div className="floatingDNDHeader">
            <div
              style={{ color: "white" }}
              startIcon={
                (floatingComponent.title === "Home" && <HomeIcon />) ||
                (floatingComponent.title === "Dashboard" && <Dashboard />) ||
                (floatingComponent.title === "Chat" && <ChatBubble />) ||
                (floatingComponent.title === "Email" && <Email />) ||
                ((floatingComponent.title === "Meeting Room" ||
                  floatingComponent.type === "Meeting") && <Videocam />) ||
                (floatingComponent.title === "Task" && <ListTask />) ||
                (floatingComponent.title === "Drive" && <Storage />) ||
                (floatingComponent.title === "Create Task" && (
                  <PlaylistAdd />
                )) ||
                (floatingComponent.title === "Scheduler" && (
                  <CalendarMonth />
                )) ||
                (floatingComponent.title === "Admin" && (
                  <AdminPanelSettings />
                )) ||
                (floatingComponent.title === "Create Organization" && (
                  <CorporateFare />
                )) ||
                (floatingComponent.title === "Onboarding" && <BlurOn />) ||
                (floatingComponent.title === "Select Template" && <Web />) ||
                (floatingComponent.type === "Whiteboard" && (
                  <DesignServices />
                )) ||
                (floatingComponent.type === "PlusAdd" && <Tab />) ||
                (floatingComponent.type === "File Viewer" && <FilePresent />)
              }
            >
              {floatingComponent.title}
            </div>
            <div className="fdadActionBtnGrp">
              <Tooltip title="Minimize">
                <IconButton
                  style={MinimizeButtonStyle}
                  onClick={handleMinimize}
                >
                  <RemoveOutlined />
                </IconButton>
              </Tooltip>

              <Tooltip title="Close">
                <IconButton style={closeButtonStyle} onClick={handleClose}>
                  <Close />
                </IconButton>
              </Tooltip>
            </div>
          </div>

          <div
            className="dragableContainDragAndDropArea"
            style={{
              color: "dark.contrastText",
              height: "100%",
              overflow: "auto",
            }}
          >
            {children}
          </div>
          <div
            className="resize-handle resize-handle-top-left"
            onMouseDown={(e) => handleMouseDown(e, "top left")}
          />
          <div
            className="resize-handle resize-handle-top-right"
            onMouseDown={(e) => handleMouseDown(e, "top right")}
          />
          <div
            className="resize-handle resize-handle-bottom-left"
            onMouseDown={(e) => handleMouseDown(e, "bottom left")}
          />
          <div
            className="resize-handle resize-handle-bottom-right"
            onMouseDown={(e) => handleMouseDown(e, "bottom right")}
          />
          <div
            className="resize-handle resize-handle-left"
            onMouseDown={(e) => handleMouseDown(e, "left")}
          />
          <div
            className="resize-handle resize-handle-right"
            onMouseDown={(e) => handleMouseDown(e, "right")}
          />
          <div
            className="resize-handle resize-handle-top"
            onMouseDown={(e) => handleMouseDown(e, "top")}
          />
          <div
            className="resize-handle resize-handle-bottom"
            onMouseDown={(e) => handleMouseDown(e, "bottom")}
          />
        </Box>
      )}
    </>
  );
}
