import React, { useEffect, useRef, useState } from "react";
import "../../../css/task.css";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Popover,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  ArrowBackIosNew,
  ArrowForwardIos,
  AssignmentLateOutlined,
  Audiotrack,
  Cancel,
  CancelOutlined,
  CheckCircle,
  DescriptionOutlined,
  Edit,
  ListAlt,
  Send,
  ShortcutOutlined,
  Task,
  VideoCall,
} from "@mui/icons-material";
import { useContext } from "react";
import {
  getFormattedDate,
  GetLoginUserDetails,
} from "../../../../../utils/Utils";
import { useDispatch, useSelector } from "react-redux";
import DashboardServices from "../../../../../services/DashboardServices";
import { toast } from "react-toastify";
import {
  clearTaskModalData,
  moveToPreviousTask,
  pushLinkTask,
  setTaskModalData,
} from "../../../../../redux/reducers/taskTab";
import DocumentTab from "../../../../../common/DocumentTab";
import { convertFileToBase64_Split } from "../../../../../utils/FileUtils";
import { ArrowLeft, Diagram3, People, Play, XLg } from "react-bootstrap-icons";
import TemplateList from "../TemplateList";
// import ReactDatePicker from "react-datepicker";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import ForwardTaskModal from "../ForwardTaskModal";
import LinkTaskModal from "../LinkTaskModal";
import ConcernTaskModal from "../ConcernTaskModal";
import AssigneeModal from "../AssigneeModal";
import RightFlotingContainer from "../RightFlotingContainer";
import CloseTask from "../CloseTask";
import FileList from "../FileList";
import ReassignTaskModal from "../Modals/ReassignTaskModal";
import RightTaskFileUpload from "../RightTaskFileUpload";
import { LinkedTaskModule } from "../LinkedTaskModule";
import { taskTabContext } from "../../TaskTabContainer";
import TaskService from "../../../../../services/TaskService";
import MeetingModal from "../Modals/MeetingModal";
import { AddTabContext } from "../../../../dashboard/view/DashboardPage";
import { useTimeProgressor } from "../hooks/useTimerProgressor";
import PmsApprovalForm from "../Forms/PmsApprovalForm";
import { enIN, es } from "date-fns/locale";
import { EditDeadLineModal } from "../Modals/EditDeadLineModal";
import { PriorityComponent } from "../TaskComponent/PriorityComponent";
import { TaskNameModal } from "../Modals/TaskNameModal";
import { DescriptionComponent } from "../Modals/DescriptionComponent";
import { TaskContractModal } from "../Modals/TaskContractModal";

// Register locales with ReactDatePicker
registerLocale("en", enIN);
registerLocale("es", es);

export default function TaskTabPmsCycleApprovalRequest({
  data,
  refreshData,
  removeFromStore,
  setShowPmsForm,
}) {
  const { t, i18n } = useTranslation();
  const { setShowConvenientMeetingView, setSelectedUsers } =
    useContext(taskTabContext);
  const setMeetingList = useRef();
  const [showMeetingModal, setShowMeetingsModal] = useState(false);

  // Determine the locale based on the current language
  const currentLocale = i18n.language === "es" ? "es" : "en";

  const links = useSelector((state) => state.taskList.taskLinks);

  const [showFileModal, setShowFileUploadModal] = useState(false);

  const { addTab } = useContext(AddTabContext);
  const loginUserDetail = GetLoginUserDetails();
  const [progressWidth, setProgressWidth] = useState(0);
  const progress = useRef(0);
  const [showAssigneeList, setShowAssigneeList] = useState(false);

  // const [dateRemaning, setDateRemaning] = useState(0);
  const dateRemaning = useTimeProgressor(data.createdAt, data.completionTime);
  // left right tags scroll
  const [openUserList, setOpenUserList] = useState(false);
  const [assigneeList, setAssigneeList] = useState([]);
  const commentInputRef = useRef();
  const dispatch = useDispatch();
  const fileDocuments = useRef(null);
  const [showForwardTask, setShowForwardTask] = useState(false);
  const [userList, setUserList] = useState([]);
  const [showCreateLinkTask, setShowCreateLinkTask] = useState(false);

  const [showAddTemplate, setShowAddTemplate] = useState(false);
  const [recommendedTemplateList, setRecommendedTemplateList] = useState([]);
  const [addedTemplateList, setAddedTemplateList] = useState([]);
  const [tempOfOrgList, setTemplateOfOrgList] = useState([]);
  const [showConcernModal, setShowConcernModal] = useState(false);

  const [showAudioModal, setShowAudioModal] = useState(false);
  const audioUrl = useRef();
  const scrollRef = useRef();

  const [showApproveModal, setShowApproveModal] = useState(false);

  const [approvalRemark, setApprovalRemark] = useState("");

  const [showReject, setShowReject] = useState(false);
  const [rejectRemark, setRejectRemark] = useState("");

  const [showPostpone, setShowPostpone] = useState(false);
  const [postponeRemark, setPostponeRemark] = useState("");
  const [postponeTill, setPostponeTill] = useState(
    new Date(new Date().setDate(new Date().getDate() + 1))
  );

  const [shouldForward, setShouldForward] = useState(false);
  const [forwardTaskUser, setForwardTaskUser] = useState("");
  const [showEditDeadline, setShowEditDeadLine] = useState(false);

  const [formData, setFormData] = useState(null);
  const [showForm, setShowForm] = useState(false);

  const gotoPreviousTAsk = () => {
    dispatch(moveToPreviousTask());
  };

  const intervalProgressor = (timeDelay) => {
    const intervalId = setInterval(() => {
      if (progress.current < 100) {
        setProgressWidth((prev) => prev + 1);
        progress.current = progress.current + 1;
      } else {
        clearInterval(intervalId);
      }
    }, timeDelay);
  };

  const handleClickFormButton = () => {
    if (data.taskNature === "PMS_APPROVAL_REQUEST") {
      setShowPmsForm(true);
      return;
    }

    if (data.taskNature === "INTERVIEW_FEEDBACK") {
      TaskService.getProspectiveEmployeeById(data.formDataTransId).then(
        (response) => {
          if (response.data) {
            setFormData(response.data);
            setShowForm(true);
          }
        }
      );
    } else {
      TaskService.getExpenseApprovalRequest(data.formDataTransId).then(
        (response) => {
          if (response.data) {
            setFormData(response.data);
            setShowForm(true);
          }
        }
      );
    }
  };

  const handleAddTemplate = async () => {
    const value = await DashboardServices.getAllTemplatesOfOrganization(
      loginUserDetail.orgId
    );
    setTemplateOfOrgList(value.data);

    const response = await DashboardServices.getTemplatesOfSubActivity(
      data.activityId,
      0
    );
    let notaddedTemplateList = [];
    if (response.data != null && response.data.length > 0) {
      response.data.forEach((template) => {
        let isTemplateAdded = false;
        addedTemplateList.forEach((addedTemplate) => {
          if (template.templateId === addedTemplate.templateId) {
            isTemplateAdded = true;
          }
        });
        if (!isTemplateAdded) {
          notaddedTemplateList.push(template);
        }
      });
      setRecommendedTemplateList(notaddedTemplateList);
    }

    setShowAddTemplate(true);
  };

  const clearAllModals = () => {
    setShowForwardTask(false);
    setShowConcernModal(false);
    setShowCreateLinkTask(false);
    setShowPostpone(false);
    setShowReject(false);
  };

  const getFilteredData = () => {
    const taskCreator = data.assignByUserId;

    const filteredData = userList.filter((item) => {
      return (
        item.userId !== loginUserDetail.userId || item.userId !== taskCreator
      );
    });
    return filteredData;
  };

  const handleLinkTaskClick = async (task) => {
    //
    const values = await DashboardServices.getTaskById(task.taskId);
    dispatch(pushLinkTask(data));
    dispatch(setTaskModalData({ ...task, ...values.data }));
  };

  const handleChangeFile = async (e) => {
    const files = e.target.files;

    // if (file.length == 0)
    fileDocuments.current = {
      fileName: files[0].name,
      fileDataLink: await convertFileToBase64_Split(files[0]),
    };
    // const uploadTime = new Date().toISOString();

    const reqDto = [
      {
        id: 0,
        taskDtlId: data.taskId,
        fileName: fileDocuments.current.fileName,
        fileDataLink: fileDocuments.current.fileDataLink,
        uploadedBy: loginUserDetail.userId,
        fileDisplayName: fileDocuments.current.fileName,
      },
    ];

    DashboardServices.uploadDocuments(
      loginUserDetail.orgId,
      loginUserDetail.userId,
      data.taskId,
      reqDto
    ).then((response) => {
      if (response.data) {
        const tempData = { ...data };

        if (tempData.documents === null) {
          tempData.documents = [...response.data];
        } else {
          tempData.documents = [...data.documents, ...response.data];
        }
        dispatch(setTaskModalData(tempData));
      }
    });
  };

  // const getTaskDifference = () => {
  //   // const time1=task.taskCreationTime
  //   // const time2 =task.taskCompletionTime
  //   const creationTime = new Date(data && data.createdAt);
  //   const completionTime = new Date(data && data.completionTime);
  //   const currentTime = new Date();

  //   //check if already time passed

  //   const diffFromCurrent = currentTime - completionTime;
  //   if (diffFromCurrent > 0) {
  //     setDateRemaning(100);
  //     return;
  //   }

  //   // Calculate the time difference in milliseconds
  //   const totalTimeDifference = completionTime - creationTime;
  //   const timeDifferenceFromNow = currentTime - creationTime;
  //   const milisecondPerPercentIncrease = totalTimeDifference / 100;
  //   const currentPercent = (timeDifferenceFromNow * 100) / totalTimeDifference;
  //   setDateRemaning(
  //     Math.floor(currentPercent) < 100 ? Math.floor(currentPercent) : 100
  //   );
  //   progress.current = Math.floor(currentPercent);
  //   const nextPercent = Math.ceil(currentPercent);
  //   const difference = nextPercent - currentPercent;
  //   const diffInMilisecond = milisecondPerPercentIncrease * difference;
  //   const clearTimeoutVal = setTimeout(() => {
  //     if (currentPercent < 100) {
  //
  //       clearTimeout(clearTimeoutVal);
  //       setDateRemaning((prev) => prev + 1);
  //       progress.current = progress.current + 1;
  //       if (milisecondPerPercentIncrease > 0) {
  //         intervalProgressor(milisecondPerPercentIncrease);
  //       }
  //     }
  //   }, diffInMilisecond);
  //
  //   console.log(
  //     "milisecondPerPercentIncrease = ",
  //     milisecondPerPercentIncrease / 1000
  //   );
  // };

  const sendComment = (e) => {
    if (!data) {
      toast.error(t("something_went_wrong"));
      return;
    }
    const comment = commentInputRef.current.value;
    const reqDto = {
      taskDetailId: data.taskId,
      progress: data.progress,
      comments: comment,
      referenceId: 0,
      commentsType: null,
    };

    const commentsAt = new Date().toISOString();
    // return;
    if (comment.trim() !== "") {
      DashboardServices.sendTaskComments(loginUserDetail.userId, reqDto).then(
        (response) => {
          if (response.data == 1) {
            const tempData = { ...data };
            tempData.comments = [
              ...tempData.comments,
              {
                comments: comment,
                commentsType: null,
                commentsByUserName: loginUserDetail.userName,
                commentsByUserProfileUrl: loginUserDetail.userProfileImage,
                progress: data.progress,
                commentsAt: commentsAt,
              },
            ];
            dispatch(setTaskModalData(tempData));
            commentInputRef.current.value = "";

            const timeoutValue = setTimeout(() => {
              scrollRef.current.scrollIntoView({ behavior: "smooth" });
              clearTimeout(timeoutValue);
            }, 500);
          } else {
            toast.error(t("something_went_wrong"));
          }
        }
      );
    }
  };

  useEffect(() => {
    setProgressWidth(data.progress);
    DashboardServices.getAssigneesOfTask(data.taskId).then((response) => {
      setAssigneeList(response.data);
    });

    DashboardServices.getOrgEmployees(loginUserDetail.orgId).then(
      (response) => {
        setUserList(response.data);
      }
    );
    // getTaskDifference();
  }, []);

  const [approvalConPop, setApprovalConPop] = React.useState(null);

  const closeApprovalConPop = () => {
    setApprovalConPop(null);
  };

  const open = Boolean(approvalConPop);
  const id = open ? "simple-popover" : undefined;

  const [anchorUl, setAnchorUl] = React.useState(null);
  const openUl = Boolean(anchorUl);
  const ulId = openUl ? "simple-popover" : undefined;

  const handleCloseUl = () => {
    setAnchorUl(null);
  };

  return (
    <>
      <div className="taskViewPannel">
        <div className="taskViewContainer">
          <div className="taskHeaderPanel">
            <div className="taskControlBtnGrp"></div>

            <div className="taskActionBtnGrp">
              <div className="taskActBtn closeBtnD10">
                <IconButton
                  aria-label="close"
                  onClick={(e) => {
                    setShowConvenientMeetingView(false);
                    setSelectedUsers([]);
                    dispatch(clearTaskModalData());
                  }}
                >
                  <Tooltip title={t("close")} arrow>
                    <XLg />
                  </Tooltip>
                </IconButton>
              </div>
            </div>
          </div>
          <div className="taskContain">
            <div className="taskContainDetail">
              <div className="taskContDtlContainer">
                {links && links.length > 0 && (
                  <div className="linktaskBreadCrumb">
                    <IconButton onClick={gotoPreviousTAsk}>
                      <ArrowLeft />
                    </IconButton>
                    <div className="prentTaskNM">
                      {links &&
                        links.length > 0 &&
                        links[links.length - 1].taskName}
                    </div>
                  </div>
                )}

                {/* <div className="taskTitleContrct">
                  <div className="taskTitleNM">{data && data.taskName}</div>
                </div>

                {data && data.taskDescription && (
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">{t("description")}</div>
                    <div className="tskElemntInfo">
                      {data && data.taskDescription}
                    </div>
                  </div>
                )} */}

                <TaskNameModal
                  data={data}
                  refreshData={refreshData}
                  removeFromStore={removeFromStore}
                />

                <DescriptionComponent
                  data={data}
                  refreshData={refreshData}
                  removeFromStore={removeFromStore}
                />

                {data && data.audioNoteFileName && (
                  <div className="taskElementGrp">
                    <div className="tskElemntInfo">
                      <div className="playAudioNotes">
                        <IconButton
                          variant="outlined"
                          onClick={(e) => {
                            DashboardServices.generatePreSignedUrlS3Object(
                              data.audioNoteFileName,
                              loginUserDetail.orgId
                            ).then((response) => {
                              audioUrl.current = response.data;
                              setShowAudioModal(true);
                            });
                          }}
                        >
                          <Play />
                        </IconButton>
                        <span>{t("play_audio_message")}</span>
                      </div>
                    </div>
                  </div>
                )}
                <TaskContractModal
                  data={data}
                  refreshData={refreshData}
                  removeFromStore={removeFromStore}
                />

                {/* {data && data.contractName && (
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">{t("contract")}</div>
                    <div className="taskContractNm">
                      {data && data.contractName}
                    </div>
                  </div>
                )} */}

                <div className="taskElementGrp">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div className="tskElemHeding"> {t("timeline")}</div>
                    {data.incoming === "N" && (
                      <Tooltip arrow title={t("edit_timeline")}>
                        <IconButton
                          className="editTimelineBtn"
                          onClick={() => {
                            setShowEditDeadLine(true);
                          }}
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                  <div className="tskTimeline">
                    <div className="tskTimBar">
                      <div className="progress">
                        {console.log(" progress width = ", progressWidth)}
                        <div
                          // up50 up80 up100
                          className={`progress-done ${
                            data && dateRemaning < 50
                              ? "up50"
                              : dateRemaning < 80
                              ? "up80"
                              : "up100"
                          }`}
                          style={{ width: `${dateRemaning}%`, opacity: 1 }}
                        ></div>
                      </div>
                    </div>
                    <div className="tskDtSE">
                      <div className="tskDTStart">
                        {getFormattedDate(data.createdAt)}
                      </div>
                      <div className="tskDTEnd">
                        {getFormattedDate(data.completionTime)}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Templates start */}

                <TemplateList
                  data={data}
                  // tempDivWidth={tempDivWidth}
                  handleAddTemplate={handleAddTemplate}
                />

                {/* Templates end */}

                {/* files start */}

                <FileList
                  data={data}
                  // tempDivWidth={tempDivWidth}
                  handleChangeFile={handleChangeFile}
                  setShowFileUploadModal={setShowFileUploadModal}
                />
                {/* files end */}

                {data &&
                  data.formDataTransId &&
                  data.formDataTransId !== "" &&
                  data.formDataTransId !== "0" && (
                    <div className="taskElementGrp">
                      <Tooltip title="Form Data">
                        <IconButton
                          className="elemntMrFileBtn"
                          variant="outlined"
                          onClick={handleClickFormButton}
                        >
                          <ListAlt />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}

                {/* <div className="taskElementGrp">
                  <div className="tskElemHeding">{t("priority")}</div>
                  <div className="tskPrioArea">
                    <div
                      class={`tskPriot  ${
                        data.priority === 0
                          ? "statNormal"
                          : data.priority === 1
                          ? "statImportant"
                          : "statUrgent"
                      }`}
                    >
                      <div class={`priStat `}>
                        {data.priority === 0
                          ? t("regular")
                          : data.priority === 1
                          ? t("important")
                          : t("critical")}
                      </div>
                    </div>
                  </div>
                </div> */}

                <PriorityComponent
                  data={data}
                  refreshData={refreshData}
                  removeFromStore={removeFromStore}
                />

                <div className="taskElementsGrpWithTwo">
                  {/* <div className="taskElementGrp">
                    <div className="tskElemHeding">Department</div>
                    <div className="tskElemntInfo">Administration</div>
                  </div> */}
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">{t("activity")}</div>
                    <div className="tskElemntInfo">{data.activityName}</div>
                  </div>
                </div>

                <div className="taskElementsGrpWithTwo">
                  {/* <div className="taskElementGrp">
                    <div className="tskElemHeding">Assigned to</div>
                    <div className="tskElemntInfo">Me</div>
                  </div> */}
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">{t("requested_by")}</div>
                    <div className="tskElemntInfo">
                      {data && data.assignByUserName && data.assignByUserName}
                    </div>
                  </div>
                </div>

                <LinkedTaskModule
                  data={data}
                  handleLinkTaskClick={(data) => handleLinkTaskClick(data)}
                />

                <div className="taskElementGrp">
                  <div className="tskElemHeding">{t("last_updated")}</div>
                  <div className="tskElemntInfo">
                    {data && data.comments && data.comments.length > 0
                      ? `${
                          data.comments[data.comments.length - 1]
                            .commentsByUserName
                        } - ${getFormattedDate(
                          data.comments[data.comments.length - 1].commentsAt
                        )}`
                      : t("no_update_found")}
                    {/* Sandeep Mohapatra - 2:15PM, 06-12-2023 */}
                  </div>
                </div>

                {data &&
                  data.comments.length > 0 &&
                  data.comments.map((comment) => (
                    <div className="taskUpCommentGrp">
                      <div className="taskUserComment">
                        <div className="tskUderImgDT">
                          <div className="tskUseComImg">
                            <img
                              src={comment.commentsByUserProfileUrl}
                              alt=""
                            />
                          </div>
                          <div className="tskUsrCNM">
                            <div className="tskUCNM">
                              {comment.commentsByUserName}
                            </div>
                            <div className="tskUCDT">
                              {comment && getFormattedDate(comment.commentsAt)}
                            </div>
                          </div>
                        </div>
                        <div className="taskUPComnt">{comment.comments}</div>
                      </div>
                    </div>
                  ))}
                <div ref={scrollRef}></div>
              </div>
            </div>
          </div>
          <div className="taskFooterPanel">
            <div className="tskInputGrp">
              <input
                ref={commentInputRef}
                type="text"
                className="tskComntInput"
                placeholder={t("update_comment_here")}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    sendComment(event);
                  }
                }}
              />
              <IconButton className="tskComtSndBtn" onClick={sendComment}>
                <Send />
              </IconButton>
            </div>
          </div>
        </div>

        {showReject && (
          <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
            <div className="addMoreAgendaModel">
              <div className="modelTitle">{t("reject_request")}</div>

              <div className="elementFormContainer">
                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      className="modelTextFild"
                      id="outlined-basic"
                      required
                      label={t("rejection_reason")}
                      variant="outlined"
                      multiline
                      rows={4}
                      maxRows={7}
                      value={rejectRemark}
                      onChange={(e) => setRejectRemark(e.target.value)}
                    />
                  </FormControl>
                </div>
              </div>

              <div className="modActionBtnGrp">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={(e) => {
                    if (rejectRemark.trim() === "") {
                      toast.error(t("please_provide_rejection_reason"));
                      return;
                    }
                    DashboardServices.rejectTask(
                      data.taskId,
                      loginUserDetail.userId,
                      rejectRemark
                    ).then((response) => {
                      if (response.data.returnValue === "1") {
                        toast.success(response.data.message);
                        dispatch(clearTaskModalData());
                        refreshData();
                      } else {
                        toast.error(response.data.message);
                      }
                    });
                  }}
                >
                  {t("submit")}
                </Button>
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => {
                    setShowReject(false);
                  }}
                >
                  {t("cancel")}
                </Button>
              </div>
            </div>
          </Box>
        )}

        {showPostpone && (
          <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
            <div className="addMoreAgendaModel">
              <div className="modelTitle">{t("pospone_approval_request")}</div>
              <div className="elementFormContainer">
                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      className="modelTextFild"
                      label={t("postpone_reason")}
                      required
                      variant="outlined"
                      multiline
                      rows={4}
                      maxRows={7}
                      value={postponeRemark}
                      onChange={(e) => setPostponeRemark(e.target.value)}
                    />
                  </FormControl>
                </div>

                <div className="formElement">
                  <FormControl className="formControl">
                    <InputLabel
                      id="setCompletionDate"
                      className="setCompletionDate"
                    >
                      {t("postpone_till")}*
                    </InputLabel>
                    <ReactDatePicker
                      locale={currentLocale}
                      showIcon
                      labelId="setCompletionDate"
                      className="formDatePicker"
                      selected={postponeTill}
                      minDate={
                        new Date(new Date().setDate(new Date().getDate() + 1))
                      }
                      dateFormat="dd/MM/yyyy"
                      onChange={(date) => {
                        setPostponeTill(date);
                      }}
                    />
                  </FormControl>
                </div>
              </div>

              {/* <div className="modInputFild">
                <TextField
                  id="outlined-basic"
                  label="Postpone Reason"
                  variant="outlined"
                  value={postponeRemark}
                  onChange={(e) => setPostponeRemark(e.target.value)}
                />
              </div> */}

              {/* <div className="formElement">
                <FormControl className="formControl">
                  <InputLabel id="compTime">Completion Time</InputLabel>
                  <Select
                    className="formInputField"
                    variant="outlined"
                    labelId="compTime"
                    id="compTime-select"
                    value={postponeTime}
                    label="Completion Time"
                    onChange={(e) => {
                      setPostPoneTime(e.target.value);
                    }}
                  >
                    {timeSlots &&
                      timeSlots.map((time) => (
                        <MenuItem value={time}> {time} </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div> */}
              {/* <div className="modAtchFileBtn">
            <label class="btn-up">
              <input type="file" hidden />
              Attach File
            </label>
          </div> */}
              <div className="modActionBtnGrp">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={(e) => {
                    //
                    //
                    // let tempPostPoneTill = new Date(postponeTill);
                    // const [hours, minutes] = postponeTime
                    //   .split(":")
                    //   .map(Number);
                    // tempPostPoneTill.setHours(hours, minutes, 0, 0);
                    //

                    let validForm = true;
                    if (postponeRemark.trim() === "") {
                      toast.error(t("please_provide_postpone_reason"));
                      validForm = false;
                    }

                    if (!validForm) {
                      return;
                    }

                    const day = postponeTill.getDate();
                    const month = postponeTill.getMonth() + 1; // Months are zero-based
                    const year = postponeTill.getFullYear();

                    // Pad single-digit day and month with leading zeros
                    const formattedDay = String(day).padStart(2, "0");
                    const formattedMonth = String(month).padStart(2, "0");

                    // Create the formatted date string in "dd-mm-yyyy" format
                    const formattedDate = `${formattedDay}-${formattedMonth}-${year}`;

                    const reqDto = {
                      postponedReason: postponeRemark,
                      postponedTill: formattedDate,
                    };

                    DashboardServices.postponedTask(
                      data.taskId,
                      loginUserDetail.userId,
                      reqDto
                    ).then((response) => {
                      if (response.data && response.data.returnValue === "1") {
                        toast.success(response.data.message);
                        dispatch(clearTaskModalData());
                        refreshData();
                      } else {
                        toast.error(response.data.message);
                      }
                    });
                  }}
                >
                  {t("submit")}
                </Button>
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => {
                    setShowPostpone(false);
                  }}
                >
                  {t("cancel")}
                </Button>
              </div>
            </div>
          </Box>
        )}

        {/* {showForwardTask && (
          <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
            <div className="addMoreAgendaModel">
              <div className="modelTitle">Forward Task</div>
              <div
                className="modInputFild"
                style={{ border: "1px solid black" }}
              >
                <TextField
                  sx={{ color: "balck !important" }}
                  label="Forward Remark"
                  variant="outlined"
                  multiline
                  rows={4}
                  maxRows={7}
                  value={forwardReason}
                  onChange={(e) => {
                    setForwardReason(e.target.value);
                  }}
                />
              </div>

              <div className="formElement">
                <FormControl className="formControl">
                  <Autocomplete
                    id="adduser-select"
                    disableClearable
                    className="formAutocompleteField"
                    variant="outlined"
                    options={getFilteredData()}
                    getOptionLabel={(option) => option.userName}
                    getOptionSelected={(option) => {
                      
                      return option.userId;
                    }}
                    onChange={(e, newValue) => {
                      
                      setSelectedUsers(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="formAutoComInputField"
                        placeholder="Search User"
                        InputProps={{
                          ...params.InputProps,
                          type: "search",
                        }}
                      />
                    )}
                  />
                </FormControl>
              </div>
              <div className="modActionBtnGrp">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={(e) => {
                    handleSubmitTransferTask();
                  }}
                >
                  Submit
                </Button>
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => {
                    setShowForwardTask(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Box>
        )} */}
        {showForwardTask && (
          <ForwardTaskModal
            data={data}
            userList={userList}
            refreshData={refreshData}
            handleClose={() => {
              setShowForwardTask(false);
            }}
          />
        )}

        {openUserList && (
          <Modal
            open={openUserList}
            onClose={() => {
              setOpenUserList(false);
            }}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box
              sx={{ backgroundColor: "white", width: "50vw", height: "50vh" }}
            >
              <ul>
                {assigneeList &&
                  assigneeList.map((assignee) => {
                    return <li>{assignee.name}</li>;
                  })}
              </ul>
            </Box>
          </Modal>
        )}

        {showCreateLinkTask && (
          <LinkTaskModal
            userList={userList}
            data={data}
            handleClose={(e) => setShowCreateLinkTask(false)}
            refreshData={refreshData}
            removeFromStore={removeFromStore}
          />
        )}

        {showConcernModal && (
          <ConcernTaskModal
            data={data}
            refreshData={refreshData}
            handleClose={(e) => setShowConcernModal(false)}
            removeFromStore={removeFromStore}
          />
        )}

        {/* {showApproveModal && (
          <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
            <div className="addMoreAgendaModel">
              <div className="modelTitle">Approve Task</div>
              <div className="modInputFild">
                <TextField
                  id="outlined-basic"
                  label="Approval Remark"
                  variant="outlined"
                  value={approvalRemark}
                  onChange={(e) => setApprovalRemark(e.target.value)}
                />
              </div>
              <div className="modInputFild">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={shouldForward}
                        onChange={(e) => {
                          
                          setShouldForward(e.target.checked);
                        }}
                      />
                    }
                    label="Forward Task After Approval?"
                  />
                </FormGroup>
              </div>
              {shouldForward && (
                <div className="modInputFild">
                  <FormControl className="formControl">
                    <Autocomplete
                      id="adduser-select"
                      disableClearable
                      className="formAutocompleteField"
                      variant="outlined"
                      options={getFilteredData()}
                      getOptionLabel={(option) => option.userName}
                      getOptionSelected={(option) => {
                        
                        return option.userId;
                      }}
                      onChange={(e, newValue) => {
                        
                        setForwardTaskUser(newValue);
                      }}
                      // renderOption={(props, option) => {
                      //   <Box
                      //     component="li"
                      //     sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      //     {...props}
                      //   >
                      //     <img loading="lazy" src={option.profileUrl} />
                      //     {option.userName}
                      //   </Box>;
                      // }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="formAutoComInputField"
                          placeholder="Search User"
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </div>
              )}

              <div className="modActionBtnGrp">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={(e) => {
                    
                    
                    

                    const reqDto = {
                      taskDetailId: data.taskId,
                      taskForwordToUserIdAfterApproval: forwardTaskUser.userId,
                      approvalRmks: approvalRemark,
                    };

                    DashboardServices.approveTask(
                      loginUserDetail.userId,
                      reqDto
                    ).then((response) => {
                      if (response && response.data) {
                        toast.success(response.data.message);
                        dispatch(clearTaskModalData());
                      } else {
                        toast.error(response.data.message);
                      }
                    });
                    
                  }}
                >
                  Submit
                </Button>
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => {
                    setShowApproveModal(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Box>
        )} */}

        {showMeetingModal && (
          <MeetingModal
            handleClose={(e) => setShowMeetingsModal(false)}
            assigneeList={assigneeList}
            taskId={data.taskId}
          />
        )}

        {showApproveModal && (
          <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
            <div className="addMoreAgendaModel">
              <div className="modelTitle">{t("approve_task")}</div>

              <div className="elementFormContainer">
                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      className="modelTextFild"
                      required
                      label={t("approval_remark")}
                      variant="outlined"
                      multiline
                      rows={4}
                      maxRows={7}
                      value={approvalRemark}
                      onChange={(e) => setApprovalRemark(e.target.value)}
                    />
                  </FormControl>
                </div>

                {/* <div className="modInputFild">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={shouldForward}
                          onChange={(e) => {
                            setShouldForward(e.target.checked);
                          }}
                        />
                      }
                      label="Forward Task After Approval?"
                    />
                  </FormGroup>
                </div> */}

                {shouldForward && (
                  <div className="formElement">
                    <FormControl className="formControl">
                      <Autocomplete
                        id="adduser-select"
                        disableClearable
                        className="modelformAutocompleteField"
                        variant="outlined"
                        options={getFilteredData()}
                        getOptionLabel={(option) => option.userName}
                        getOptionSelected={(option) => {
                          return option.userId;
                        }}
                        onChange={(e, newValue) => {
                          setForwardTaskUser(newValue);
                        }}
                        // renderOption={(props, option) => {
                        //   <Box
                        //     component="li"
                        //     sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        //     {...props}
                        //   >
                        //     <img loading="lazy" src={option.profileUrl} />
                        //     {option.userName}
                        //   </Box>;
                        // }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="formAutoComInputField"
                            placeholder={t("search_user")}
                            InputProps={{
                              ...params.InputProps,
                              type: "search",
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </div>
                )}
              </div>

              <div className="modActionBtnGrp">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={(e) => {
                    const reqDto = {
                      taskDetailId: data.taskId,
                      taskForwordToUserIdAfterApproval: forwardTaskUser.userId,
                      approvalRmks: approvalRemark,
                    };

                    DashboardServices.approveTask(
                      loginUserDetail.userId,
                      reqDto
                    ).then((response) => {
                      if (response && response.data) {
                        toast.success(response.data.message);
                        dispatch(clearTaskModalData());
                        refreshData();
                      } else {
                        toast.error(response.data.message);
                      }
                    });
                  }}
                >
                  {t("submit")}
                </Button>
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => {
                    setShowApproveModal(false);
                  }}
                >
                  {t("cancel")}
                </Button>
              </div>
            </div>
          </Box>
        )}

        {showAudioModal && (
          <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
            <div className="addMoreAgendaModel">
              <div className="modelTitle">{t("audio_message")}</div>
              <div className="modActionBtnGrp">
                <audio controls>
                  <source src={`${audioUrl.current}`} />
                </audio>
              </div>

              <div className="modActionBtnGrp">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={() => {
                    setShowAudioModal(false);
                  }}
                >
                  {t("ok")}
                </Button>
                {/* <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => {
                    setShowAudioModal(false);
                  }}
                >
                  Ok
                </Button> */}
              </div>
            </div>
          </Box>
        )}

        {showAssigneeList && (
          <AssigneeModal
            assigneeList={assigneeList}
            handleClose={(e) => setShowAssigneeList(false)}
          />
        )}

        {showAudioModal && (
          <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
            <div className="addMoreAgendaModel">
              <div className="modelTitle">{t("audio_message")}</div>
              <div className="modActionBtnGrp">
                <audio controls>
                  <source src={`${audioUrl.current}`} />
                </audio>
              </div>

              <div className="modActionBtnGrp">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={() => {
                    setShowAudioModal(false);
                  }}
                >
                  {t("ok")}
                </Button>
                {/* <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => {
                    setShowAudioModal(false);
                  }}
                >
                  Ok
                </Button> */}
              </div>
            </div>
          </Box>
        )}

        {showEditDeadline && (
          <EditDeadLineModal
            createdAt={data.createdAt}
            completionTime={data.completionTime}
            closeModal={() => {
              setShowEditDeadLine(false);
            }}
            onClose={(e) => setShowEditDeadLine(false)}
            userList={userList}
            handleClose={(e) => setShowEditDeadLine(false)}
            previousAssigness={assigneeList}
            data={data}
            refreshData={refreshData}
            removeFromStore={removeFromStore}
          />
        )}
      </div>

      {showAddTemplate && (
        <RightFlotingContainer
          recommendedTemplateList={recommendedTemplateList}
          setRecommendedTemplateList={setRecommendedTemplateList}
          tempOfOrgList={tempOfOrgList}
          addedTemplateList={addedTemplateList}
          setAddedTemplateList={setAddedTemplateList}
          closeTemplate={() => {
            setShowAddTemplate(false);
            if (addedTemplateList && addedTemplateList.length > 0) {
              const temp = [...addedTemplateList];
              temp.forEach((template) => {
                template.taskDetailId = data.taskId;
              });
              DashboardServices.addTaskTemplate(
                loginUserDetail.userId,
                addedTemplateList,
                "OLD"
              ).then((response) => {
                dispatch(clearTaskModalData());
              });
            }
          }}
          mode={"old"}
        />
      )}

      {showFileModal && (
        <div className="RightFlotingContainerArea">
          <RightTaskFileUpload
            documents={data.documents}
            onClose={(e) => setShowFileUploadModal(false)}
            uploadFile={(data) => {
              handleChangeFile(data);
            }}
          />
        </div>
      )}

      {/* {showForm && data.taskNature === "PMS_APPROVAL_REQUEST" && (
        <PmsApprovalForm
          data={data}
          handleClose={() => setShowForm(false)}
          refreshData={() => {
            refreshData();
            // dispatch(clearSelectedTask());
          }}
        />
      )} */}
    </>
  );
}
