import { AttachFile } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  TextField,
} from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { Eye, Trash, XLg } from "react-bootstrap-icons";
// import ReactDatePicker from "react-datepicker";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PreviewHolidayEmailer from "./model/PreviewHolidayEmailer";
import { convertFileToBase64_Split } from "../../../../utils/FileUtils";
import { toast } from "react-toastify";
import AppUtils from "../../../../utils/AppUtils";
import { addHoliday, updateHoliday } from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import DateUtils from "../../../../utils/DateUtils";
import { useTranslation } from "react-i18next";
import { enIN, es } from "date-fns/locale";
import { GetLoginUserDetails } from "../../../../utils/Utils";

// Register locales with ReactDatePicker
registerLocale("en", enIN);
registerLocale("es", es);

export default function EditHoliday({
  handelCloseEditHoliday,
  refresHolidayData,
  selecttedHolidayData,
}) {
  console.log("selecttedHolidayData", selecttedHolidayData);
  const userDetails = GetLoginUserDetails();
  const { t, i18n } = useTranslation();

  const [loadingData, setLoadingData] = useState(false);

  const [holidayName, SetHolidayName] = useState(
    selecttedHolidayData.holidayName
  );
  const [holidayDescription, setHolidayDescription] = useState(
    selecttedHolidayData.holidayEmailContent
  );
  //   const [selectedDate, setSelectedDate] = useState(selecttedHolidayData.holidayDate);
  // const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const holidayImageRef = useRef(null);
  const [fileData_Base64, setFileData_Base64] = useState("");
  const [fileName, setFileName] = useState("");

  useEffect(() => {
    if (selecttedHolidayData) {
      init();
    }
  }, [selecttedHolidayData]);

  // Determine the locale based on the current language
  const currentLocale = i18n.language === "es" ? "es" : "en";

  const init = async () => {
    if (
      selecttedHolidayData.emailFileDataLink &&
      selecttedHolidayData.emailFileDataLink !== ""
    ) {
      const base64 = await AppUtils.imageUrlToBase64(
        selecttedHolidayData.emailFileDataLink
      );
      const result = base64.slice(23);
      setFileData_Base64(result);
      setFileName(selecttedHolidayData.emailFileName);
    }
  };

  const handleHolidayName = (e) => {
    console.log("handleHolidayName", e.target.value);
    SetHolidayName(e.target.value);
  };

  const handleHolidayDescription = (e) => {
    console.log("handleHolidayDescription", e.target.value);
    setHolidayDescription(e.target.value);
  };

  // useEffect(() => {
  //   const [day, month, year] = selecttedHolidayData.holidayDate.split("-");
  //   setSelectedDate(new Date(`${month}/${day}/${year}`));
  // }, [selecttedHolidayData.holidayDate]);

  useEffect(() => {
    if (selecttedHolidayData.holidayDate) {
      const [day, month, year] = selecttedHolidayData.holidayDate.split("-");
      const parsedDate = new Date(`${month}/${day}/${year}`);
      if (!isNaN(parsedDate)) {
        setSelectedDate(parsedDate);
      } else {
        console.error("Invalid date format:", selecttedHolidayData.holidayDate);
      }
    }
  }, [selecttedHolidayData.holidayDate]);

  const changeCurrentDate = (newDate) => {
    console.log("changeCurrentDate", newDate);
    setSelectedDate(newDate);
  };

  const handleImageUpload = async (e) => {
    console.log(e);
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    console.log("handleUploadOrgLogo", file);
    setFileData_Base64(await convertFileToBase64_Split(file));
    setFileName(file.name);
  };

  console.log("fileData_Base64", fileData_Base64);

  const handleDeleteFile = () => {
    setFileName("");
    setFileData_Base64("");
  };

  const [showPreviewHolidayModel, setPreviewHolidayModel] = useState(false);
  const handelClosePreviewHolidayModel = () => {
    setPreviewHolidayModel(false);
  };

  const CheckPreviewCondition = () => {
    if (holidayName.trim() === "") {
      toast.error(t("please_enter_holiday_name"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    // if (holidayDescription.trim() === "") {
    //   toast.error("Please Enter Holiday Description for Email", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   return;
    // };

    // if (fileData_Base64.trim() === "" && fileName.trim() === "") {
    //   toast.error("Please Attach Image For Emailer", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   return;
    // };

    setPreviewHolidayModel(true);
  };

  const afterSubmission = () => {
    refresHolidayData();
    SetHolidayName("");
    setHolidayDescription("");
    setSelectedDate(new Date());
    setFileData_Base64("");
    setFileName("");
  };

  const isPreviewAvailable =
    holidayDescription || (fileName && fileData_Base64);

  const updateFinalSubmit = () => {
    setLoadingData(true);
    console.log("finalSubmit");

    const holidayUpdatedDate = DateUtils.getDateInDDMMYYYY(selectedDate);
    console.log("holidayUpdatedDate", holidayUpdatedDate);

    const reqDto = {
      holidayDate: DateUtils.getDateInDDMMYYYY(selectedDate),
      holidayName: holidayName,
      holidayEmailContent: holidayDescription,
      optional: "",
      emailFileName: fileName,
      emailFileDataLink: fileData_Base64,
    };

    console.log("reqDto", reqDto);

    updateHoliday(
      userDetails.orgId,
      userDetails.userId,
      holidayUpdatedDate,
      reqDto
    ).then((response) => {
      if (response.data) {
        if (response.data.returnValue === "1") {
          toast.success(t("holiday_update_successfully"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoadingData(false);
          afterSubmission();
          handelClosePreviewHolidayModel();
          handelCloseEditHoliday();
        }

        if (response.data.returnValue === "0") {
          toast.error(t("failed_to_update_holiday"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoadingData(false);
        }

        if (response.data.returnValue === "-1") {
          toast.error(t("holiday_update_already_exists"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoadingData(false);
        }
      }
    });
  };

  return (
    <>
      <div className="rightFlotingPanel">
        <div className="rightFlotingContainer">
          <div className="rfContHeader">
            <div className="rfcHeadText">{t("edit_holiday")}</div>
            <div className="rfcHActionBtnGrp">
              <div className="actionBtn closeBtnD10">
                <IconButton onClick={() => handelCloseEditHoliday()}>
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>
          <div className="rfContContain">
            <div className="rfContContainDetails">
              <div className="elementFormContainer">
                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      // hiddenLabel
                      label={t("holiday_name")}
                      required
                      variant="outlined"
                      value={holidayName}
                      className="formTextFieldArea"
                      onChange={handleHolidayName}
                    />
                  </FormControl>
                </div>

                <div className="formElement">
                  <FormControl className="formControl">
                    <InputLabel
                      id="setHolidayDate"
                      className="setCompletionDate"
                    >
                      {t("holiday_date")}
                    </InputLabel>
                    <ReactDatePicker
                      locale={currentLocale}
                      showIcon
                      showPopperArrow={false}
                      labelId="setHolidayDate"
                      className="formDatePicker"
                      dateFormat="dd/MM/yyyy"
                      minDate={new Date()}
                      selected={selectedDate}
                      onChange={(date) => changeCurrentDate(date)}
                      disabled
                    />
                  </FormControl>
                </div>

                <div className="formElement">
                  <FormControl className="formControl pRelative">
                    <TextField
                      label={t("holiday_description_for_email")}
                      variant="outlined"
                      className="descptionTextFild"
                      multiline
                      rows={10}
                      maxRows={10}
                      value={holidayDescription}
                      onChange={handleHolidayDescription}
                    ></TextField>
                  </FormControl>
                </div>

                <div className="formBtnElement">
                  <Button
                    variant="outlined"
                    startIcon={<AttachFile />}
                    className="comBtnOutline"
                    // "activeStatus" use this class for when file uploded
                    onClick={() => holidayImageRef.current.click()}
                  >
                    {t("attach_image_for_emailer")}
                  </Button>
                  <input
                    ref={holidayImageRef}
                    type="file"
                    // accept="image/*"
                    accept="image/jpeg, image/jpg"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      handleImageUpload(e);
                      holidayImageRef.current.value = null;
                    }}
                  />
                </div>
                {fileName && (
                  <div class="elementListGrp">
                    <ul class="elmListInfo">
                      <li>
                        <span className="rolsNm">{fileName}</span>
                        <IconButton
                          className="rmvBtn"
                          onClick={handleDeleteFile}
                        >
                          <Trash />
                        </IconButton>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="rfContFooter">
            <div className="formBtnElement">
              {isPreviewAvailable ? (
                <Button
                  startIcon={<Eye />}
                  className="dfultPrimaryBtn"
                  // onClick={() => setPreviewHolidayModel(true)}
                  onClick={() => CheckPreviewCondition()}
                >
                  {t("preview")}
                </Button>
              ) : (
                <Button
                  className="dfultPrimaryBtn"
                  onClick={() => updateFinalSubmit()}
                >
                  {loadingData ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    <>{t("submit")}</>
                  )}
                </Button>
              )}
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={() => handelCloseEditHoliday()}
              >
                {t("cancel")}
              </Button>
            </div>
          </div>
        </div>
      </div>

      {showPreviewHolidayModel && (
        <PreviewHolidayEmailer
          showPreviewHolidayModel={showPreviewHolidayModel}
          handelClosePreviewHolidayModel={handelClosePreviewHolidayModel}
          holidayName={holidayName}
          holidayDescription={holidayDescription}
          selectedDate={selectedDate}
          imageData={fileData_Base64}
          imageName={fileName}
          afterSubmission={afterSubmission}
          selecttedHolidayData={selecttedHolidayData}
          handelCloseEditHoliday={handelCloseEditHoliday}
        />
      )}
    </>
  );
}
