import React from "react";
import CardManagementDoctor from "../../CardManagementDoctor";
import DetailsAnalyticTable from "./DetailsAnalyticTable";

export default function DoctorAnalyticDetails({ selectedMenu }) {
  return (
    <>
      <div className="overViewConatiner">
        {selectedMenu === "_doc_graph_" && (
          <CardManagementDoctor showMoreBtn={false} />
        )}
        {selectedMenu === "_doctors_details_" && <DetailsAnalyticTable />}
      </div>
    </>
  );
}
