import {
  Add,
  ArrowBackIosNew,
  ArrowForwardIos,
  Close,
  DescriptionOutlined,
  Verified,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Modal,
  CircularProgress,
} from "@mui/material";
import { Microphone, MicrophoneCheckSolid } from "iconoir-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Play, Plus, Trash, XLg } from "react-bootstrap-icons";
// import ReactDatePicker from "react-datepicker";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { GetLoginUserDetails, validateEmail } from "../../../utils/Utils";
import RecordAudio from "../../../common/RecordAudio";
// import SchedulerRecordAudio from "./component/SchedulerRecordAudio";
import AppUtils from "../../../utils/AppUtils";
import { ConfirmProvider, useConfirm } from "material-ui-confirm";
import { toast } from "react-toastify";
import Joyride from "react-joyride";

import {
  getAllUsersMapOfOrg,
  createWebinar,
  getContacts,
  empOnLeaveList,
  sendCallInvitePersonal,
  sendMeetingInviteToExternalUsers,
} from "../../../services/SchedulerService";
import { getChatAllContacts } from "../../../services/ChatService";

import ProfileService from "../../../services/ProfileService";

import { jwtDecode } from "jwt-decode";
import { async } from "q";
import DateUtils from "../../../utils/DateUtils";
import { schedulerSocket } from "../../../socket";
import { useTranslation } from "react-i18next";
import { enIN, es } from "date-fns/locale";
import { useContext } from "react";
import { TourContext } from "../../../common/TourProvider";

// Register locales with ReactDatePicker
registerLocale("en", enIN);
registerLocale("es", es);

const Webinar = ({
  handleCloseMenuButton,
  loading,
  setLoading,
  closeFromDashboard,
}) => {
  const userDetails = GetLoginUserDetails();
  const { t, i18n } = useTranslation();

  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  console.log("userDetails", userDetails);

  const loginUser = GetLoginUserDetails();

  //////////////////////////////////////////////////////////////

  const [anchorEl, setAnchorEl] = useState(null);

  // Determine the locale based on the current language
  const currentLocale = i18n.language === "es" ? "es" : "en";

  const openPopover = Boolean(anchorEl);
  const popoverId = openPopover ? "simple-popover" : undefined;

  const [meetingParticipants, setMeetingParticipants] = useState([]);
  const [newContactButton, setNewContactButton] = useState(false);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [loaderForGuest, setLoaderForGuest] = useState(false);

  const handleNewContactButton = (event) => {
    setAnchorEl(event.currentTarget);
    setNewContactButton(true);
  };

  const handleCloseNeCwontactButtonModal = () => {
    setAnchorEl(null);
    setNewContactButton(false);
    setUserName("");
    setUserEmail("");
  };

  const handleUserNameChange = (event) => {
    console.log("handleUserNameChange", event.target.value);
    setUserName(event.target.value);
  };

  const handleUserEmailChange = (event) => {
    setUserEmail(event.target.value);
  };

  const handleFormSubmit = () => {
    setLoaderForGuest(true);

    if (userName.trim() === "") {
      toast.error(t("please_enter_user_name"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoaderForGuest(false);
      return;
    }

    if (userEmail.trim() === "") {
      toast.error(t("please_enter_user_email"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoaderForGuest(false);
      return;
    }

    if (userEmail.trim() !== "" && !validateEmail(userEmail)) {
      toast.error(t("please_enter_valid_email"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoaderForGuest(false);
      return;
    }

    const isDuplicate = meetingParticipants.some(
      (participant) =>
        participant.userName === userName + " ( " + userEmail + " )" ||
        participant.userName.includes(userEmail)
    );

    if (isDuplicate) {
      toast.error(t("participant_with_this_email_already_exists"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoaderForGuest(false);
      return;
    }

    const newParticipant = {
      calledBy: "",
      chairperson: "",
      convener: "",
      id: null,
      orgId: "",
      outSide: "",
      profileImage:
        "https://ahlan-s3.s3.me-south-1.amazonaws.com/images/no-profile.png",
      userId: "",
      userName: userName + " ( " + userEmail + " )",
    };

    setMeetingParticipants([...meetingParticipants, newParticipant]);
    setNewContactButton(false);
    setAnchorEl(null);
    setUserName("");
    setUserEmail("");
    setLoaderForGuest(false);

    toast.success(t("new_guest_added_successfully"), {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  ////////////////////////////////////////////////////////////////

  // const [loading, setLoading] = useState(false);

  const [showEmpOnLeaveModal, setShowEmpOnLeaveModal] = useState(false);

  const [anchorElRP, setAnchorElRP] = React.useState(null);
  const [allUsersList, setAllUsersList] = useState([]);
  // const [selectedGuest, setSelectedGuest] = useState(null);
  // const [selectedGuestInput, setSelectedGuestInput] = useState("");
  const openRP = Boolean(anchorElRP);
  const idRP = openRP ? "simple-popover" : undefined;
  const buttonRPRef = useRef(null);
  const buttonRPCancelRef = useRef(null);
  const buttonApplyForAllRec = useRef(null);
  const buttonApplyForAllRecReschedule = useRef(null);
  const buttonApplyForAllRecAddParticipant = useRef(null);
  const buttonApplyForAllRecRemoveParticipant = useRef(null);
  const [lempOnLeaveList, setLEmpOnLeaveList] = useState([]);

  const handleClickRP = (event) => {
    setAnchorElRP(event.currentTarget);
  };

  const handleCloseRP = () => {
    setAnchorElRP(null);
  };
  // const [meetingParticipants, setMeetingParticipants] = useState([]);
  // const [selectedParticipant, setSelectedParticipant] = React.useState(null);
  ////////////////////////

  ////////////////////////////////////private notes
  //add pvt note pop over
  const [userPrivateNotes, setUserPrivateNotes] = useState([]);
  const [anchorElPN, setAnchorElPN] = React.useState(null);

  const handleClickPN = (event) => {
    setAnchorElPN(event.currentTarget);
  };

  const handleClosePN = () => {
    setAnchorElPN(null);
  };

  const openPN = Boolean(anchorElPN);
  const idPN = openPN ? "add-pvtNotePopOver" : undefined;
  // const [openAddPvtNote, setOpenAddPvtNote] = useState(false);

  // const handleOpenAddPvtNote = () => {
  //   setOpenAddPvtNote(true);
  // };

  // const handleClosePvtNote = () => {
  //   setOpenAddPvtNote(false);
  // };

  const addPNRef = useRef(null);
  const cancelPNRef = useRef(null);
  const inputPNRef = useRef(null);
  const [pvtNote, setPvtNote] = useState(null);

  const onClickAddPvtNote = () => {
    console.log(pvtNote);
    if (pvtNote === null || pvtNote.trim() === "") {
      console.log("pvtNote");
      inputPNRef.current.style.borderColor = "red";
      inputPNRef.current.placeholder = "Please add note...";
      return;
    }
    // return;
    addPNRef.current.disabled = true;
    const userNotesDto = {
      eventNoteId: null,
      notes: pvtNote,
      notingTime: new Date().getTime(),
    };
    setUserPrivateNotes((prevList) => [...prevList, userNotesDto]);
    handleClosePN();
    setPvtNote(null);
    // setAnchorElPN(null);
    return;

    // addPrivateNotes(loginUser.userId, selectedEvent).then((response) => {
    //   console.log(response.data);
    //   getPrivateNotes(loginUser.userId, selectedEvent.eventId).then(
    //     (response) => {
    //       setUserPrivateNotes(response.data);
    //     }
    //   );
    // });
  };
  ///////////////////////////////////private notes end

  // const loginUser = GetLoginUserDetails();

  // const [startDateselect, setStartDateselect] = useState("");
  const [eventType, setEventType] = useState("Event");
  const [descriptionVisible, setDescriptionVisible] = useState(true);

  const [selectEventType, setSelectEventType] = useState("onetime");

  const handleEventType = (event) => {
    setSelectEventType(event.target.value);
  };

  const [selectEventMode, setSelectEventMode] = useState("virtual");

  const handleEventMode = (event) => {
    setSelectEventMode(event.target.value);
  };
  const elementRef = useRef(null);
  const [arrowDisable, setArrowDisable] = useState(true);
  const [tempDivWidth, setTempDivWidth] = useState(0);
  useEffect(() => {
    if (elementRef.current) {
      setTempDivWidth(elementRef.current.offsetWidth);
    }
  }, [elementRef]);

  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setArrowDisable(true);
      } else {
        setArrowDisable(false);
      }
    }, speed);
  };

  const handleEventTypeChange = (event) => {
    setEventType(event.target.value);

    // Update visibility of description based on selected event type
    setDescriptionVisible(event.target.value === "Event");
  };
  ////////////////////////////////////////////////////////////////////////////////

  const [startDate, setStartDate] = useState(new Date());
  const [startDateselect, setStartDateselect] = useState("");
  const handleSetDate = (event) => {
    setStartDateselect(event.target.value);
  };

  ////////////////////////////////date////////////////////////////////////////////////////////

  const [startDay, setStartDay] = useState(new Date());
  const [endDay, setEndDay] = useState(new Date());
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [timeOptions, setTimeOptions] = useState([]);
  const [timeEndOptions, setTimeEndOptions] = useState([]);
  const [currentDay, setCurrentDay] = useState(getCurrentDay(startDay));
  const [currentEndDay, setEndCurrentDay] = useState(getCurrentDay(endDay));

  function getCurrentDay(date) {
    return date.toLocaleDateString("en-US", { weekday: "long" });
  }

  // const extractDayFromDate = (date) => {
  //   return new Intl.DateTimeFormat('en-US', { weekday: 'short' }).format(date);
  // };

  const handleStartDateChange = (date) => {
    console.log("date", date);
    setStartDay(date);
    setCurrentDay(getCurrentDay(date));
    // Ensure end date is not less than start date
    if (endDay < date) {
      setEndDay(date);
      setEndCurrentDay(getCurrentDay(date));
    }
    getCurrentTimeOptions(date);
    setEventStartdate(date !== null ? date : new Date());
    setEndDay(date);
    setEventEndDate(date);
  };

  const handleEndDateChange = (date) => {
    console.log("date", date);
    // Ensure end date is not less than start date
    if (date < startDay) {
      setStartDay(date);
      setCurrentDay(getCurrentDay(date));
    }
    setEndDay(date);
    setEndCurrentDay(getCurrentDay(date));
    setEventEndDate(date !== null ? date : eventStartDate || new Date());

    if (startDay.toDateString() !== date.toDateString()) {
      // If start date and end date are different, set end time list to start from "00:00 AM"
      const newEndTimeList = generateTimeIntervals("00:00 AM", "11:45 PM");
      setTimeEndOptions(newEndTimeList);
      // Set event end time to the first element of the new end time list
      setEventEndTime(newEndTimeList[0]);
      setEndTime(newEndTimeList[0]);
    } else {
      // Otherwise, get the next options from timeOptions
      const newEndTimeList = timeOptions.slice(
        timeOptions.indexOf(date.toLocaleTimeString()) + 1
      );
      setTimeEndOptions(newEndTimeList);
      // Set event end time to the first element of the new end time list
      setEventEndTime(newEndTimeList[0]);
    }
  };

  // const handleEndDateChange = (date) => {
  //   console.log("date", date);
  //   // setEventEndDate(getCurrentDay(date))
  //   // Ensure end date is not less than start date
  //   if (date < startDay) {
  //     setStartDay(date);
  //     setCurrentDay(getCurrentDay(date));
  //   }
  //   setEndDay(date);
  //   setEndCurrentDay(getCurrentDay(date));
  //   setEventEndDate(date !== null ? date : eventStartDate || new Date());

  //   // Check if start date and end date are different
  // if (startDay.toDateString() !== endDay.toDateString()) {
  //   // If start date and end date are different, set end time list to start from "00:00 AM"
  //   const newEndTimeList = generateTimeIntervals("00:00 AM", "11:45 PM");
  //   setTimeEndOptions(newEndTimeList);
  //   // Set event end time to the first element of the new end time list
  //   setEventEndTime(newEndTimeList[0]);
  // } else {
  //   // Otherwise, get the next options from timeOptions
  //   const newEndTimeList = timeOptions.slice(timeOptions.indexOf(newEndTime) + 1);
  //   setTimeEndOptions(newEndTimeList);
  //   // Set event end time to the first element of the new end time list
  //   setEventEndTime(newEndTimeList[0]);
  // }
  // };

  const getCurrentTimeOptions = (selectedDate) => {
    const options = [];
    const currentDate = new Date();

    let startHour = 0;
    let startMinute = 0;

    if (selectedDate.toDateString() === currentDate.toDateString()) {
      // If the selected date is today, start from the current time
      if (currentDate.getHours() >= 23 && currentDate.getMinutes() >= 45) {
        // If the current time is 11:45 PM or later, start from the next day
        selectedDate.setDate(selectedDate.getDate() + 1);
        startHour = 0;
        startMinute = 0;
      } else {
        // Otherwise, start from the current time
        startHour = currentDate.getHours();
        startMinute = Math.ceil(currentDate.getMinutes() / 15) * 15;
      }
    }

    for (let hour = startHour; hour <= 23; hour++) {
      const startMin =
        hour === startHour ? Math.ceil(startMinute / 15) * 15 : 0;
      for (let minute = startMin; minute < 60; minute += 15) {
        const formattedHour = (
          hour === 0 ? "00" : hour % 12 === 0 ? 12 : hour % 12
        ).toString();
        const formattedMinute = minute < 10 ? `0${minute}` : `${minute}`;
        const period = hour < 12 ? "AM" : "PM";
        const timeString = `${formattedHour}:${formattedMinute} ${period}`;
        options.push(timeString);
      }
    }

    setTimeOptions(options);
  };

  useEffect(() => {
    getCurrentTimeOptions(startDay);
  }, [startDay]);

  useEffect(() => {
    if (timeOptions.length > 0) {
      setStartTime(timeOptions[0]);
      setEndTime(timeOptions[2] || "");
      setTimeEndOptions(timeOptions.slice(1));
    }

    setEventEndTime(timeOptions[2] || "");
    setEventStartTime(timeOptions[0]);
  }, [timeOptions]);

  // const handleStartTimeChange = (event) => {
  //   const newStartTime = event.target.value;
  //   setStartTime(newStartTime);
  //   const newEndTimeList = timeOptions.slice(timeOptions.indexOf(newStartTime) + 1);
  //   setEndTime(newEndTimeList[0] || '');
  //   setTimeEndOptions(newEndTimeList);

  // };

  // const handleStartTimeChange = (event) => {
  //   const newStartTime = event.target.value;
  //   setStartTime(newStartTime);
  //   setEventStartTime(newStartTime);

  //   // Update endTime list based on the newStartTime
  //   let newEndTimeList;

  //   if (newStartTime === "11:45 PM") {
  //     // If the newStartTime is "11:45 PM", set endTime to "00:15 AM" of the next day
  //     const nextDay = new Date(startDay);
  //     nextDay.setDate(startDay.getDate() + 1);
  //     nextDay.setHours(0, 15, 0); // Set to "00:15 AM"
  //     setEndDay(nextDay);
  //     setEndCurrentDay(getCurrentDay(nextDay));
  //     setEventEndDate(nextDay)

  //     // newEndTimeList = ["00:15 AM"];
  //     newEndTimeList = generateTimeIntervals("00:15 AM", "11:45 PM");
  //   } else {
  //     // Otherwise, get the next options from timeOptions
  //     newEndTimeList = timeOptions.slice(timeOptions.indexOf(newStartTime) + 1);
  //   }

  //   setEndTime(newEndTimeList[0] || "");
  //   setTimeEndOptions(newEndTimeList);
  //   setEventEndTime(newEndTimeList[0]);
  // };

  const handleStartTimeChange = (event) => {
    const newStartTime = event.target.value;
    setStartTime(newStartTime);
    setEventStartTime(newStartTime);

    if (newStartTime === "11:45 PM") {
      console.log("Start time is 11:45 PM");
      const nextDay = new Date(startDay);
      nextDay.setDate(startDay.getDate() + 1);
      nextDay.setHours(0, 15, 0);
      console.log("Next day:", nextDay);
      setEndDay(nextDay);
      setEndCurrentDay(getCurrentDay(nextDay));
      setEventEndDate(nextDay);

      const newEndTimeList = generateTimeIntervals("00:15 AM", "11:45 PM");
      console.log("New end time options for different days:", newEndTimeList);
      setEndTime(newEndTimeList[0] || "");
      setTimeEndOptions(newEndTimeList);
      setEventEndTime(newEndTimeList[0]);
    } else {
      if (startDay.toDateString() === endDay.toDateString()) {
        const newEndTimeList = timeOptions.slice(
          timeOptions.indexOf(newStartTime) + 1
        );
        console.log("New end time options:", newEndTimeList);
        setEndTime(newEndTimeList[0] || "");
        setTimeEndOptions(newEndTimeList);
        setEventEndTime(newEndTimeList[0]);
      } else {
        console.log("date");
      }
    }
  };

  const generateTimeIntervals = (startTime, endTime) => {
    const intervals = [];
    let current = startTime;
    let isAM = startTime.includes("AM");

    while (current !== endTime) {
      intervals.push(current);
      const [hours, minutes] = current.split(/:| /);
      let newMinutes = parseInt(minutes, 10) + 15;
      let newHours = parseInt(hours, 10);

      if (newMinutes >= 60) {
        newMinutes -= 60;
        newHours += 1;
        if (newHours === 12) {
          isAM = !isAM; // Toggle AM/PM when reaching 12:00
        }
        if (newHours > 12) {
          newHours -= 12;
        }
      }

      current = `${newHours.toString().padStart(2, "0")}:${newMinutes
        .toString()
        .padStart(2, "0")} ${isAM ? "AM" : "PM"}`;
    }

    intervals.push(endTime); // Add endTime to the list
    return intervals;
  };

  // const handleStartTimeChange = (event) => {
  //   const newStartTime = event.target.value;
  //   setStartTime(newStartTime);
  //   setEventStartTime(newStartTime);

  //   let newEndTimeList;

  //   if (newStartTime === "11:45 PM") {
  //     // If the newStartTime is "11:45 PM", set endTime to "00:15 AM" of the next day
  //     const nextDay = new Date(startDay);
  //     nextDay.setDate(startDay.getDate() + 1);
  //     nextDay.setHours(0, 15, 0); // Set to "00:15 AM"
  //     setEndDay(nextDay);
  //     setEndCurrentDay(getCurrentDay(nextDay));

  //     newEndTimeList = ["00:15 AM"];
  //   } else {
  //     // Generate end time options for the whole day with 15-minute intervals
  //     newEndTimeList = [];
  //     for (let hour = 0; hour < 24; hour++) {
  //       for (let minute = 0; minute < 60; minute += 15) {
  //         const formattedHour = (hour === 0 ? "00" : hour % 12 === 0 ? 12 : hour % 12).toString();
  //         const formattedMinute = minute < 10 ? `0${minute}` : `${minute}`;
  //         const period = hour < 12 ? "AM" : "PM";
  //         const timeString = `${formattedHour}:${formattedMinute} ${period}`;
  //         newEndTimeList.push(timeString);
  //       }
  //     }
  //   }

  //   setTimeEndOptions(newEndTimeList);
  //   setEndTime(newEndTimeList[0] || "");
  // };

  // ... (rest of your code)

  const handleEndTimeChange = (event) => {
    setEndTime(event.target.value);
    setEventEndTime(event.target.value);
  };

  ////////////////////////////////////////// audio ////////////////////////////////////

  const tempAudioChunkRef = useRef([]);

  const [recordingLink, setRecordingLink] = useState();
  const [recordingLength, setRecordingLength] = useState(0);
  const [showRecAudio, setShowRecAudio] = useState(false);

  const handleShowRecAudioClose = () => {
    console.log(tempAudioChunkRef.current);
    if (tempAudioChunkRef.current && tempAudioChunkRef.current.length > 0) {
      getSingleBlob();
    }
    setShowRecAudio(false);
  };

  const getSingleBlob = () => {
    // const promises = tempAudioChunkRef.current.map((blob) =>
    //   blob.arrayBuffer()
    // );

    const promises = [];

    for (const blob of tempAudioChunkRef.current) {
      const promise = blob.arrayBuffer();
      promises.push(promise);
    }

    Promise.all(promises)
      .then((arrayBuffers) => {
        const concatenatedArrayBuffer = arrayBuffers.reduce((acc, buffer) => {
          const totalLength = acc.byteLength + buffer.byteLength;
          const combined = new Uint8Array(totalLength);
          combined.set(new Uint8Array(acc), 0);
          combined.set(new Uint8Array(buffer), acc.byteLength);
          return combined.buffer;
        }, new ArrayBuffer(0));

        const combinedBlob = new Blob([concatenatedArrayBuffer], {
          type: "audio/wav",
        });
        tempAudioChunkRef.current = [combinedBlob];
        console.log(tempAudioChunkRef.current);
      })
      .catch((error) => {
        console.error("Error occurred while combining Blobs:", error);
      });
  };

  const handleAudio = () => {
    return new Promise((resolve, reject) => {
      let audioFileDataBase64;
      if (tempAudioChunkRef.current.length > 0) {
        const audioBlob = new Blob(tempAudioChunkRef.current, {
          type: "audio/wav",
        });

        if (audioBlob) {
          AppUtils.blobToBase64(audioBlob)
            .then((base64Data) => {
              audioFileDataBase64 = base64Data;
              resolve(audioFileDataBase64);
            })
            .catch((error) => {
              console.error("Error converting audioBlob to base64:", error);
              reject(error);
            });
        } else {
          resolve(audioFileDataBase64);
        }
      } else {
        resolve(audioFileDataBase64);
      }
    });
  };

  ///////////////////////////////////////guest///////////////////////////////////////////
  const [selectedGuest, setSelectedGuest] = useState(null);
  const [selectedGuestInput, setSelectedGuestInput] = useState("");

  // const [meetingParticipants, setMeetingParticipants] = useState([]);
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [refreshCount, setRefreshCount] = useState(1);
  const [participants, setParticipants] = useState([]);

  // useEffect(() => {
  //   getAllUsersMapOfOrg(userDetails.orgId)
  //     .then((response) => {
  //       console.log("alluser", response.data);

  //       const inputObject = response.data;

  //       const outputList = Object.entries(inputObject).map(
  //         ([userId, userName]) => ({
  //           userId,
  //           userName,
  //         })
  //       );

  //       console.log("outputList", outputList);

  //       setAllUsersList(outputList);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching participants:", error);
  //     });
  // }, []);

  // useEffect(() => {
  //   getChatAllContacts(loginUser.userId, loginUser.orgId).then((response) => {
  //     console.log("getChatAllContacts", response.data);
  //     setAllUsersList(response.data);
  //   });
  // }, []);

  useEffect(() => {
    getChatAllContacts(loginUser.userId, loginUser.orgId).then((response) => {
      console.log("getChatAllContacts", response.data);

      ProfileService.getAllUserDetailsByUserId(loginUser.userId).then(
        (response2) => {
          let userAllInfo = response2.data;
          const profileImageLink =
            userAllInfo?.userDtls?.profileImageLink || "";
          let myDetails = {
            userId: loginUser.userId,
            userName: loginUser.userName,
            userDtl: loginUser.externalCategory
              ? loginUser.externalCategory
              : "Internal",
            // profileUrl: `data:image/png;base64,${
            //   userAllInfo ? userAllInfo.userDtls.profileImageLink : ""
            // }`,
            profileUrl: profileImageLink.startsWith("http")
              ? profileImageLink
              : `data:image/png;base64,${profileImageLink}`,
            userType: loginUser.externalCategory ? "E" : "I",
            convener: "Y",
          };

          setEventGuest(myDetails.userId);
          setMeetingParticipants((prevList) => [
            ...prevList,
            {
              id: null,
              userId: myDetails.userId,
              userName: myDetails.userName,
              profileImage: myDetails.profileUrl,
              convener: "Y",
              chairperson: "",
              outSide: myDetails.userType === "I" ? "N" : "Y",
              calledBy: "N",
              orgId: loginUser.orgId,
            },
          ]);

          setAllUsersList([...response.data, myDetails]);
        }
      );
    });
  }, []);

  const makeConveyor = (userId) => {
    const updatedParticipants = meetingParticipants.map((participant) => ({
      ...participant,
      convener: participant.userId === userId ? "Y" : "N",
    }));

    setMeetingParticipants(updatedParticipants);
    setEventGuest(updatedParticipants);
    setRefreshCount((r) => r + 1);
  };

  const handleRemoveParticipant = (userId, userName) => {
    // Check if the participant is a conveyor
    const isConveyor = meetingParticipants.some(
      (participant) =>
        participant.userId === userId && participant.convener === "Y"
    );

    // If the participant is a conveyor, do not allow removal
    if (isConveyor) {
      // You can handle this case (e.g., show a message)
      console.log("Cannot remove a conveyor participant");
      return;
    }

    if (userId === "") {
      const updatedParticipants = meetingParticipants.filter(
        (participant) => participant.userName !== userName
      );

      setMeetingParticipants(updatedParticipants);
      handleCloseRP();
    } else {
      // Update the state to remove the participant with the specified userId
      const updatedParticipants = meetingParticipants.filter(
        (participant) => participant.userId !== userId
      );

      setMeetingParticipants(updatedParticipants);

      // You may also add additional logic here if needed
      handleCloseRP();
    }
  };

  // useEffect(() => {
  //   // Function to format and set participants state
  //   const formatParticipants = () => {
  //     const formattedParticipants = meetingParticipants.map((participant) => {
  //       const formattedParticipant = { userId: participant.userId };
  //       if (participant.convener) {
  //         formattedParticipant.convener = participant.convener;
  //       }
  //       return formattedParticipant;
  //     });
  //     setParticipants(formattedParticipants);
  //   };

  //   formatParticipants();
  // }, [meetingParticipants]);

  const [guestData, setGuestData] = useState([
    { guestName: "", guestEmail: "" },
  ]);

  useEffect(() => {
    const formatParticipants = () => {
      const formattedParticipants = [];
      const participantsWithoutUserId = [];

      meetingParticipants.forEach((participant) => {
        if (participant.userId) {
          const formattedParticipant = { userId: participant.userId };
          if (participant.convener) {
            formattedParticipant.convener = participant.convener;
          }
          formattedParticipants.push(formattedParticipant);
        } else {
          participantsWithoutUserId.push(participant);
        }
      });

      setParticipants(formattedParticipants);

      // Extract guest names and emails
      const extractedGuests = participantsWithoutUserId.map((participant) => {
        const parts = participant.userName.split("(");
        const guestName = parts[0].trim();
        const guestEmail = parts[1].split(")")[0].trim();
        return { guestName, guestEmail };
      });

      setGuestData(extractedGuests);

      console.log("participantsWithoutUserId", participantsWithoutUserId);
    };

    formatParticipants();
  }, [meetingParticipants]);

  ///////////////////////////////////////////finalapi/////////////////////////////////////

  const [eventTitle, setEventTitle] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [eventLocation, setEventLocation] = useState(userDetails.orgAddress);
  const [eventStartTime, setEventStartTime] = useState(timeOptions.slice(1));
  const [eventEndTime, setEventEndTime] = useState(timeEndOptions.slice(1));
  const [eventStartDate, setEventStartdate] = useState(new Date());
  const [eventEndDate, setEventEndDate] = useState(new Date());
  const [eventGuest, setEventGuest] = useState([]);
  const [blogSlug, setBlogSlug] = useState("");

  const slugPattern = /^[a-zA-Z0-9\-_]*$/;

  function isValidSlug(slug) {
    return slugPattern.test(slug);
  }

  const [selContacts, setSelContacts] = useState([]);

  useEffect(() => {
    getContacts(userDetails.userId).then((response) => {
      console.log("getContacts", response.data);
      // toast.success("Contact added Successfully", {
      //   position: toast.POSITION.TOP_RIGHT,
      // });

      if (response.data) {
        const newSelContacts = response.data.map((contact) => {
          if (contact.userId === userDetails.userId) {
            return {
              accountExists: null,
              contactEmailId: "",
              contactName: userDetails.userName,
              contactUserId: userDetails.userId,
              id: "",
              inviteDate: "",
              inviteLink: "",
              inviteStatus: "",
              meetingId: null,
              userId: null,
            };
          } else {
            return {
              accountExists: null,
              contactEmailId: contact.contactEmailId,
              contactName: contact.contactName,
              contactUserId: contact.contactUserId,
              id: contact.id,
              inviteDate: contact.inviteDate,
              inviteLink: contact.inviteLink,
              inviteStatus: contact.inviteStatus,
              meetingId: null,
              userId: null,
            };
          }
        });
        setSelContacts((prevSelContacts) => [
          ...prevSelContacts,
          ...newSelContacts,
        ]);
      }
    });

    setSelContacts([
      {
        accountExists: null,
        contactEmailId: "",
        contactName: userDetails.userName,
        contactUserId: userDetails.userId,
        id: "",
        inviteDate: "",
        inviteLink: "",
        inviteStatus: "",
        meetingId: null,
        userId: null,
      },
    ]);
  }, []);

  const calculateTime = (startTime, endTime) => {
    // Assuming startTime and endTime are Date objects
    console.log("startTime", startTime, endTime);
    const startTimeMs = startTime.getTime();
    const endTimeMs = endTime.getTime();
    const timeDifferenceMs = endTimeMs - startTimeMs;
    const timeDifferenceMinutes = timeDifferenceMs / (1000 * 60);

    return timeDifferenceMinutes;
  };

  const finalWebinarsubmit = async () => {
    setLoading(true);

    const formattedStartDate = eventStartDate
      ? new Date(eventStartDate).toDateString()
      : null;
    const formattedEndDate = eventEndDate
      ? new Date(eventEndDate).toDateString()
      : null;

    console.log("eventTitle", eventTitle);
    console.log("eventDescription", eventDescription);
    console.log("eventLocation", eventLocation);
    console.log("eventStartTime", eventStartTime);
    console.log("eventEndTime", eventEndTime);
    console.log("eventStartDate", eventStartDate);
    console.log("eventEndDate", eventEndDate);
    console.log("participants", participants);
    console.log("blogSlug", blogSlug);

    if (eventTitle.trim() === "") {
      toast.error(t("please_enter_webinar_title"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (eventDescription.trim() === "") {
      toast.error(t("please_enter_webinar_description"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (eventLocation.trim() === "") {
      toast.error(t("please_enter_event_location"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (blogSlug.trim() === "") {
      toast.error(t("Please_fill_blogSlug"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (participants.length === 0) {
      toast.error(t("select_participants"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    const newStartDate = new Date(formattedStartDate + " " + eventStartTime);
    console.log("newStartDate", newStartDate);

    const currentDateTime = new Date();

    const oneMinuteAgo = new Date(currentDateTime.getTime() - 1 * 60 * 1000);

    if (newStartDate < oneMinuteAgo) {
      toast.error(
        t(
          "you_have_exceeded_the_current_time_please_select_the_next_time_slot"
        ),
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      setLoading(false);
      return;
    }

    toast.info(t("please_wait"), {
      position: toast.POSITION.TOP_RIGHT,
    });

    let reqDto = {
      title: eventTitle,
      description: eventDescription,
      length: calculateTime(
        new Date(formattedStartDate + " " + eventStartTime),
        new Date(formattedEndDate + " " + eventEndTime)
      ),
      startTime: new Date(formattedStartDate + " " + eventStartTime),
      endTime: new Date(formattedEndDate + " " + eventEndTime),
      location: eventLocation,
      participants: participants,
    };

    console.log("reqDto", reqDto);

    await handleSelectUser(reqDto);

    // finalCreateWebinar(reqDto);
  };

  const finalCreateWebinar = async (reqDto) => {
    console.log("Inside finalCreateEvent:", reqDto);

    try {
      const audioFileDataBase64 = await handleAudio();
      console.log("audioFileDataBase64", typeof audioFileDataBase64);
      console.log("audioFileDataBase64", audioFileDataBase64);

      if (audioFileDataBase64) {
        reqDto.audioFileDataBase64 = audioFileDataBase64;
      }

      reqDto.webinarSlugUrl = blogSlug + ".html";

      console.log("createWebinar reqDto", reqDto);
      console.log("guestData", guestData);
      // setLoading(false);
      // return
      createWebinar(userDetails.orgId, userDetails.userId, reqDto).then(
        (response) => {
          console.log(response.data);
          setLoading(false);
          if (response.data.returnValue === "1") {
            toast.success(t("webinar_created_successfully"), {
              position: toast.POSITION.TOP_RIGHT,
            });
            handleCloseMenuButton();

            if (guestData.length > 0) {
              const invitePromises = guestData.map((guest) => {
                const newGuestReqDto = {
                  personName: guest.guestName,
                  officialEmailId: guest.guestEmail,
                };

                return sendMeetingInviteToExternalUsers(
                  response.data.message,
                  userDetails.userId,
                  newGuestReqDto
                ).then((response) => {
                  console.log("guest response.data", response.data);
                  if (response.data.returnValue !== "1") {
                    throw new Error(response.data.message);
                  }
                  return response.data.message;
                });
              });

              Promise.all(invitePromises)
                .then((messages) => {
                  // Show a single success message
                  toast.success(t("all_guests_added_successfully"), {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                })
                .catch((error) => {
                  console.error("Error sending invites:", error);
                  toast.error(t("failed_to_add_some_guests"), {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                });
            }

            // if (userDetails.orgId === "ORG000000000000") {
            //   if (
            //     selContacts.length === 1 &&
            //     userDetails.userId === selContacts[0].contactUserId
            //   ) {
            //     // setInvitePerModal(false);
            //     // props.onSuccess();
            //     return;
            //   }
            //   if (selContacts && selContacts.length > 0) {
            //     sendCallInvitePersonal(
            //       selContacts,
            //       response.data.message,
            //       userDetails.userId,
            //       "EVENT"
            //     ).then((response) => {
            //       console.log("sendCallInvite", response.data);
            //       if (response.data.returnValue === "1") {
            //         console.log("sendCallInvite", response.data);
            //         // toast.success("Event Invite Sent to all selected contacts", {
            //         //   position: toast.POSITION.TOP_RIGHT,
            //         // });
            //         // setInvitePerModal(false);
            //       } else {
            //         // toast.error("Something went wrong", {
            //         //   position: toast.POSITION.TOP_RIGHT,
            //         // });
            //       }
            //     });
            //   }
            // }

            setEventTitle("");
            setEventDescription("");
            setEventLocation("");
            // setEventStartTime("");
            setEventStartdate(new Date());
            setEventEndDate(new Date());
            setEventGuest([]);
            setMeetingParticipants([]);
            tempAudioChunkRef.current = [];
            setRecordingLink();
            setRecordingLength(0);
            setBlogSlug("");
            closeFromDashboard("refresh");
            try {
              schedulerSocket.emit("updateSchedulerOfEventParticipantsClient", {
                userId: response.data.message,
                eventParticipants: meetingParticipants,
              });
            } catch (err) {
              console.log(err);
            }
          } else {
            toast.error(t("something_went_wrong_please_try_again"), {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      );
    } catch (error) {
      console.error("Error in finalCreateEvent:", error);
      toast.error(t("something_went_wrong_please_try_again"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    // handleSelectUser();
  };

  const handleSelectUser = (finalSubmitDto) => {
    const startDate = new Date(eventStartDate);
    const formattedStartDate = `${startDate
      .getDate()
      .toString()
      .padStart(2, "0")}-${(startDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${startDate.getFullYear()}`;
    console.log("startdates", formattedStartDate);

    const endDate = new Date(eventEndDate);
    const formattedEndDate = `${endDate
      .getDate()
      .toString()
      .padStart(2, "0")}-${(endDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${endDate.getFullYear()}`;
    console.log("enddates", formattedEndDate);

    const data = participants.map((value) => value.userId);
    console.log("User IDs:", data);

    if (data.length > 0) {
      const reqDto = {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        userIds: data,
      };
      console.log("reqDto", reqDto);

      empOnLeaveList(reqDto).then((response) => {
        console.log("handleSelectUser", response.data);
        if (response.data && response.data.length > 0) {
          // toast.success("Event empOnLeaveList Successfully", {
          //   position: toast.POSITION.TOP_RIGHT,
          // });

          setShowEmpOnLeaveModal(true);
          console.log("empleavelistresponse", response.data);
          setLEmpOnLeaveList({
            reqDto: finalSubmitDto,
            leaveList: response.data,
          });
          console.log(
            "isAParticipantOnLeave",
            new Date().getTime(),
            response.data
          );
        } else {
          finalCreateWebinar(finalSubmitDto);
          // return false;
        }
      });
    } else {
      finalCreateWebinar(finalSubmitDto);
      // return false;
    }
  };

  const searchUserFilteredList = useMemo(() => {
    let filtered = [];
    if (selectedGuestInput.length > 0 && selectedGuestInput.trim() !== "") {
      filtered = allUsersList.filter((option) =>
        option.userName.toLowerCase().includes(selectedGuestInput.toLowerCase())
      );
    }

    return filtered;
  }, [selectedGuestInput, allUsersList]);

  useEffect(()=>{
    setBookSteps([
      {
        target: ".scheWebinarStepOne",
        content: "This is where you can set the Webinar title.",
        disableBeacon: true,
      },
      {
        target: ".scheWebinarStepTwo",
        content: "Select the Webinar's start date and time here.",
        disableBeacon: true,
      },
      {
        target: ".scheWebinarStepThree",
        content: "Now, select the Webinar's end date and time here.",
        disableBeacon: true,
      },
      {
        target: ".scheWebinarStepFour",
        content: "Add a description for the Webinar here.",
        disableBeacon: true,
      },
      {
        target: ".scheWebinarStepFive",
        content: "Provide the Webinar's location in this field.",
        disableBeacon: true,
      },
      {
        target: ".scheWebinarStepFiveSix",
        content: "Provide the Webinar's Slug in this field.",
        disableBeacon: true,
      },
      {
        target: ".scheWebinarStepSix",
        content: "Search and add participants for your event here.",
        disableBeacon: true,
      },
      {
        target: ".scheWebinarStepSeven",
        content: "Click here to add a new guest to your event.",
        disableBeacon: true,
      },
      {
        target: ".scheWebinarStepEight",
        content: "Here is the list of all the participants you've added.",
        disableBeacon: true,
      },
    ])
    },[])

  return (
    <>
        <Joyride
        steps={booksteps}
        run={run["viewWebinarMettingSC"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        styles={{
          buttonClose: {
            display: "none",
          },
        }}
      />
      {loading ? (
        // <CircularProgress
        //   size={150}
        //   color="success"
        //   top="60"
        //   left="160"
        //   position="relative"
        // />
        <div className="meetingVEContainer">
          <div className="center-progress" style={{ height: "65vh" }}>
            <CircularProgress sx={{ marginTop: "180px" }} />
          </div>
        </div>
      ) : (
        <>
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                className="MainTitleTexbox scheWebinarStepOne"
                hiddenLabel
                variant="filled"
                placeholder={t("Webinar_title")}
                required
                value={eventTitle}
                onChange={(e) => setEventTitle(e.target.value)}
                Autocomplete="off"
              />
            </FormControl>
          </div>

          <div className="formElement">
            <div className="medDateTime">
              <div
                className="frmDateTime scheWebinarStepTwo"
                style={{
                  display: "flex !important",
                  flexWrap: "wrap",
                  justifyContent: "center !important",
                }}
              >
                <div className="frmDateInput">
                  <div className="frmDaysPick">{currentDay}</div>
                  {/* START TIME */}
                  <ReactDatePicker
                    locale={currentLocale}
                    className="frmdatePick"
                    selected={startDay}
                    // onChange={(date) => setStartDate(date)}
                    onChange={handleStartDateChange}
                    minDate={new Date()}
                    maxDate={new Date().getTime() + 1051200 * 60000}
                    value={eventStartDate}
                    dateFormat="dd-MMM-yyyy"
                    // onChange={(e) => setEventStartdate(e.target.value)}
                  />
                </div>
                <div className="frmTimeInput">
                  <Select
                    className="slctFrmTime"
                    value={startTime}
                    onChange={handleStartTimeChange}
                    sx={{ maxWidth: "115px !important" }}
                  >
                    {timeOptions.map((time, index) => (
                      <MenuItem key={index} value={time}>
                        {time}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>

              <span className="spanElement">{t("to")}</span>

              <div
                className="toDateTime scheWebinarStepThree"
                style={{
                  flexWrap: "wrap",
                  justifyContent: "center !important",
                }}
              >
                <div className="frmDateInput">
                  <div className="frmDaysPick">{currentEndDay}</div>
                  {/* END TIME */}
                  <ReactDatePicker
                    locale={currentLocale}
                    className="frmdatePick"
                    selected={endDay}
                    // onChange={(date) => setStartDate(date)}
                    onChange={handleEndDateChange}
                    minDate={startDay}
                    maxDate={DateUtils.add24HoursTDate(startDay)}
                    value={eventEndDate}
                    dateFormat="dd-MMM-yyyy"
                  />
                </div>
                <div className="toTimeInput">
                  <Select
                    className="slctFrmTime"
                    value={endTime}
                    onChange={handleEndTimeChange}
                    sx={{ maxWidth: "115px !important" }}
                  >
                    {timeEndOptions.map((time, index) => (
                      <MenuItem key={index} value={time}>
                        {time}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
          </div>

          <div className="formElement">
            <FormControl className="formControl pRelative">
              <TextField
                hiddenLabel
                variant="outlined"
                className="descptionTextFild scheWebinarStepFour"
                multiline
                rows={4}
                maxRows={7}
                placeholder={t("webinar_description")}
                required
                value={eventDescription}
                onChange={(e) => setEventDescription(e.target.value)}
              ></TextField>
              {/* <Tooltip
                className="audioNoteTooltip"
                title={
                  recordingLength > 0
                    ? "Audio Note Recorded"
                    : "Record Audio Note"
                }
                arrow
                placement="bottom"
              >
                <IconButton
                  className="audioNote"
                  onClick={() => {
                    setShowRecAudio(true);
                  }}
                >
                  {recordingLength > 0 ? (
                    <MicrophoneCheckSolid />
                  ) : (
                    <Microphone />
                  )}
                </IconButton>
              </Tooltip> */}
              <Tooltip
                className="audioNoteTooltip"
                title={
                  recordingLength > 0
                    ? t("audio_note_recorded")
                    : t("record_audio_note")
                }
                arrow
                placement="bottom"
              >
                <IconButton
                  onClick={() => setShowRecAudio(true)}
                  className={
                    recordingLength > 0 ? `audioNote activeStatus` : "audioNote"
                  }
                >
                  <Microphone />
                </IconButton>
              </Tooltip>
            </FormControl>
            {/* If audio notes available then show  playAudioNotes start */}
            {/* <div className="playAudioNotes">
          <IconButton>
            <Play />
          </IconButton>
          <span>Play audio notes name</span>
        </div> */}
            {/* If audio notes available then show  end */}
          </div>

          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                hiddenLabel
                placeholder={t("location")}
                required
                variant="outlined"
                className="formTextFieldArea scheWebinarStepFive"
                value={eventLocation}
                onChange={(e) => setEventLocation(e.target.value)}
              />
            </FormControl>
          </div>

          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                hiddenLabel
                placeholder={t("webinar_slug")}
                required
                variant="outlined"
                className="formTextFieldArea scheWebinarStepFiveSix"
                value={blogSlug}
                // onChange={(e) => setBlogSlug(e.target.value)}
                onChange={(e) => {
                  if (isValidSlug(e.target.value)) {
                    setBlogSlug(e.target.value);
                  }
                }}
              />
            </FormControl>
          </div>

          {/* add guest start */}

          <div className="meetGustDtlElement">
            <div className="metGustDtlElementTitle">
              <span>{t("add_guests")}*</span>
            </div>
            <div className="meetingAddGuestGrup">
              {/* <Button>Add Guests</Button> */}
              <div className="searchGustAC scheWebinarStepSix">
                <Autocomplete
                  noOptionsText={t("please_type_to_search")}
                  id="participantSelect"
                  sx={{
                    width: 300,
                    backgroundColor: "#333",
                    borderRadius: "10px",
                    color: "white",
                  }}
                  value={selectedGuest}
                  onChange={(event, selectedValue) => {
                    console.log("newValue", selectedValue);
                    setEventGuest(selectedValue.userId);
                    setMeetingParticipants((prevList) => {
                      if (
                        !prevList.some(
                          (participant) =>
                            participant.userId === selectedValue.userId
                        )
                      ) {
                        return [
                          ...prevList,
                          {
                            id: null,
                            userId: selectedValue.userId,
                            userName: selectedValue.userName,
                            profileImage: selectedValue.profileUrl,
                            convener: "",
                            chairperson: "",
                            outSide: selectedValue.userType === "I" ? "N" : "Y",
                            calledBy: "N",
                            orgId: loginUser.orgId,
                          },
                        ];
                      }
                      return prevList;
                    });

                    setSelectedGuest(null);
                    setSelectedGuestInput("");
                    // }
                  }}
                  inputValue={selectedGuestInput}
                  onInputChange={(event, newInputValue) => {
                    setSelectedGuestInput(newInputValue);
                  }}
                  options={searchUserFilteredList}
                  autoHighlight
                  getOptionLabel={(option) => option.userName}
                  getOptionDisabled={(option) =>
                    meetingParticipants.some(
                      (userDto) => userDto.userId === option.userId
                    )
                  }
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        srcSet={`${option.profileUrl}`}
                        src={`${option.profileUrl}`}
                        alt=""
                      />
                      {option.userName}
                      {option.userType === "I" ? (
                        <span style={{ padding: "5px" }}>
                          <Tooltip title={t("belongs_to_your_organization")}>
                            {/* <BadgeCheck /> */}
                            <Verified />
                          </Tooltip>
                        </span>
                      ) : (
                        <></>
                      )}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      InputProps={
                        {
                          // style: { color: "white !important" },
                        }
                      }
                      {...params}

                      // label="Select a participant"

                      // inputProps={{
                      //   ...params.inputProps,
                      //   autoComplete: "new-password", // disable autocomplete and autofill
                      // }}
                    />
                  )}
                />
                {/* {selectedEvent.eventType === "Recurring Event" &&
                    selectedGuest != null && (
                      <div className="addParticipantBtn">
                        <Button
                          onClick={() => {
                            setMeetingParticipants((prevList) => [
                              ...prevList,
                              {
                                id: null,
                                userId: selectedGuest.userId,
                                userName: selectedGuest.userName,
                                profileImage: selectedGuest.profileUrl,
                                convener: "",
                                chairperson: "",
                                outSide:
                                  selectedGuest.userType === "I" ? "N" : "Y",
                                calledBy: "N",
                                orgId: loginUser.orgId,
                                applyForAllRecurringEvents:
                                  buttonApplyForAllRecAddParticipant.current
                                    ? buttonApplyForAllRecAddParticipant.current
                                        ?.checked
                                      ? "Y"
                                      : "N"
                                    : "N",
                              },
                            ]);
                            setSelectedGuest(null);
                            setSelectedGuestInput("");
                          }}
                        >
                          ADD
                        </Button>
                      </div>
                    )} */}
              </div>

              <div className="addGustNewMeetBtn scheWebinarStepSeven">
                <Tooltip title={t("add_a_new_guest")}>
                  <Button
                    startIcon={<Add />}
                    className="dfultPrimaryBtn"
                    onClick={handleNewContactButton}
                  >
                    {t("new_guest")}
                  </Button>
                </Tooltip>
                <Popover
                  id={popoverId}
                  open={openPopover}
                  anchorEl={anchorEl}
                  onClose={handleCloseNeCwontactButtonModal}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                >
                  <div
                    className="popOverContain"
                    style={{ padding: 20, minWidth: "280px" }}
                  >
                    <div className="modelTitle">{t("enter_user_details")}</div>
                    <div
                      className="elementFormContainer"
                      style={{ padding: "20px 0px 10px 0px" }}
                    >
                      <FormControl className="formControl">
                        <TextField
                          required
                          className="modInputFieldGuestAdd"
                          label={t("user_name")}
                          variant="outlined"
                          value={userName}
                          onChange={handleUserNameChange}
                        />
                      </FormControl>

                      <FormControl className="formControl">
                        <TextField
                          required
                          className="modInputFieldGuestAdd"
                          label={t("user_email")}
                          variant="outlined"
                          value={userEmail}
                          onChange={handleUserEmailChange}
                        />
                      </FormControl>
                    </div>
                    <div className="modActionBtnGrp">
                      <Button
                        className="dfultPrimaryBtn"
                        onClick={handleFormSubmit}
                        disabled={loaderForGuest}
                      >
                        {loaderForGuest ? (
                          <CircularProgress size={24} color="inherit" />
                        ) : (
                          <>{t("add")}</>
                        )}
                      </Button>
                      <Button
                        className="dfultDarkSecondaryBtn"
                        onClick={handleCloseNeCwontactButtonModal}
                      >
                        {t("cancel")}
                      </Button>
                    </div>
                  </div>
                </Popover>
              </div>
            </div>
            <div className="metGustLstGrp">
              {meetingParticipants.map((participantDto) => {
                return (
                  <>
                    <div
                      className={`metGustLstInd scheWebinarStepEight`}
                      style={{ cursor: "unset" }}
                    >
                      <div className="metGstImg">
                        <img src={participantDto.profileImage} alt="" />
                        {/* <span className="metConVerd">
                            <CheckCircle />
                          </span> */}
                      </div>
                      <div className="metGstName">
                        {participantDto.userName}
                        {participantDto.outSide === "N" ? (
                          <span style={{ padding: "5px" }}>
                            <Tooltip title={t("belongs_to_your_organization")}>
                              {/* <BadgeCheck /> */}
                              <Verified />
                            </Tooltip>
                          </span>
                        ) : (
                          <></>
                        )}

                        {participantDto.convener === "Y" && (
                          <span className="metConvenor">({t("convener")})</span>
                        )}
                        {/* {participantDto.chairperson === "Y" && (
                            <span className="metConvenor">(Chairperson)</span>
                          )} */}
                      </div>
                      {participantDto.userId &&
                        participantDto.convener !== "Y" &&
                        refreshCount > 0 && (
                          <div className="mkAsConvenorBtnGrp">
                            <Button
                              onClick={() =>
                                makeConveyor(participantDto.userId)
                              }
                            >
                              {t("make_convener")}
                            </Button>
                          </div>
                        )}
                      {/* {participantDto.chairperson !== "Y" &&
                          // selectedEvent.eventType === "Meeting" && 
                          (
                            <div className="mkAsConvenorBtnGrp">
                              <Button
                                onClick={() => makeChairperson(participantDto)}
                              >
                                Make Chairperson
                              </Button>
                            </div>
                          )} */}
                      {(participantDto.convener === "N" ||
                        participantDto.convener === "") &&
                        (participantDto.chairperson === "N" ||
                          participantDto.chairperson === "") && (
                          <div className="mkAsConvenorBtnGrp">
                            <IconButton
                              aria-describedby={idRP}
                              onClick={(event) => {
                                handleClickRP(event);
                                setSelectedParticipant(participantDto);
                              }}
                            >
                              <Trash />
                            </IconButton>
                            <Popover
                              id={idRP}
                              open={openRP}
                              anchorEl={anchorElRP}
                              onClose={handleCloseRP}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              transformOrigin={{
                                vertical: "center",
                                horizontal: "right",
                              }}
                            >
                              <div style={{ padding: "20px" }}>
                                <div className="modelTitle">
                                  {t("confirmation")}
                                </div>
                                <div
                                  className="modInputFild"
                                  style={{ padding: "20px 10px 10px 0px" }}
                                >
                                  {/* Remove {selectedParticipant?.userName} from
                                  guest list? */}
                                  {t("remove_from_guest_list", {
                                    userName: selectedParticipant?.userName,
                                  })}
                                </div>

                                {/* {selectedEvent.eventType ===
                                    "Recurring Event" && ( */}
                                {/* <div
                                      className="modActionBtnGrp"
                                      style={{ padding: "5px 0px 5px 0px" }}
                                    >
                                      <input
                                        type="checkbox"
                                        ref={
                                          buttonApplyForAllRecRemoveParticipant
                                        }
                                      />
                                      Remove for all recurring events
                                    </div> */}
                                {/* )} */}

                                <div className="modActionBtnGrp">
                                  <Button
                                    className="dfultPrimaryBtn"
                                    // onClick={() => removeParticipant()}
                                    onClick={() =>
                                      handleRemoveParticipant(
                                        selectedParticipant?.userId,
                                        selectedParticipant?.userName
                                      )
                                    }
                                    ref={buttonRPRef}
                                  >
                                    {t("remove")}
                                  </Button>
                                  <Button
                                    className="dfultDarkSecondaryBtn"
                                    onClick={handleCloseRP}
                                    ref={buttonRPCancelRef}
                                  >
                                    {t("cancel")}
                                  </Button>
                                </div>
                                {/* <div
                                  className="modActionBtnGrp"
                                  style={{ marginTop: "5px" }}
                                >
                                  <Button
                                    className="dfultPrimaryBtn"
                                    onClick={() => removeParticipant("Y")}
                                    // ref={buttonRefRP}
                                  >
                                    Remove from all Recurring Events
                                  </Button>
                                </div> */}
                              </div>
                            </Popover>
                          </div>
                        )}
                    </div>
                  </>
                );
              })}
              {/* <div className="metGustLstInd gustAsConvenor">
                <div className="metGstImg">
                  <img src={userImg1} alt="" />
                  <span className="metConVerd">
                    <CheckCircle />
                  </span>
                </div>
                <div className="metGstName">
                  Aiyasha Hasan<span className="metConvenor">(Convenor)</span>
                </div>
                <div className="mkAsConvenorBtnGrp">
                  <Button>Make Convenor</Button>
                </div>
              </div> */}
              {/*
              <div className="metGustLstInd">
                <div className="metGstImg">
                  <img src={userImg2} alt="" />
                  <span className="metConVerd">
                    <CheckCircle />
                  </span>
                </div>
                <div className="metGstName">
                  Sanket Santra<span className="metConvenor">(Convenor)</span>
                </div>
                <div className="mkAsConvenorBtnGrp">
                  <Button>Make Convenor</Button>
                </div>
              </div>

              <div className="metGustLstInd">
                <div className="metGstImg">
                  <img src={userImg3} alt="" />
                  <span className="metConVerd">
                    <CheckCircle />
                  </span>
                </div>
                <div className="metGstName">
                  Prashant Saha<span className="metConvenor">(Convenor)</span>
                </div>
                <div className="mkAsConvenorBtnGrp">
                  <Button>Make Convenor</Button>
                </div>
              </div>

              <div className="metGustLstInd">
                <div className="metGstImg">
                  <img src={userImg4} alt="" />
                  <span className="metConVerd">
                    <CheckCircle />
                  </span>
                </div>
                <div className="metGstName">
                  Sk. Aftabuddin<span className="metConvenor">(Convenor)</span>
                </div>
                <div className="mkAsConvenorBtnGrp">
                  <Button>Make Convenor</Button>
                </div>
              </div> */}
              {/* {selectedEvent.eventType === "Recurring Event" &&
                  meetingParticipants.some((obj) => obj.id === null) && (
                    <>
                      <div
                        className="modActionBtnGrp"
                        style={{ padding: "10px 0px 10px 0px" }}
                      >
                        <input
                          style={{ width: "16px", height: "16px" }}
                          type="checkbox"
                          ref={buttonApplyForAllRecAddParticipant}
                        />
                        <span style={{ fontSize: "17px", color: "white" }}>
                          Add New participants for all related recurring events
                        </span>
                      </div>
                      <div
                        className="modActionBtnGrp"
                        style={{ padding: "2px 0px 2px 0px" }}
                      >
                        <span style={{ fontSize: "10px", color: "red" }}>
                          *Convenor/Chairperson needs to be changed individually
                          for each recurring event
                        </span>
                      </div>
                    </>
                  )} */}
            </div>
          </div>

          {/* add guest end */}
        </>
      )}

      <div className="fixedButtonsContainer">
        <div className="formBtnElement">
          {/* <Button
            className="dfultPrimaryBtn"
            onClick={() => finalWebinarsubmit()}
            disabled = {loading}
          >
            Save
          </Button> */}
          <Button
            className="dfultPrimaryBtn"
            onClick={() => finalWebinarsubmit()}
            // disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              <>{t("submit")}</>
            )}
            {/* Submit */}
          </Button>
          {!loading && (
            <Button
              className="dfultDarkSecondaryBtn"
              onClick={() => handleCloseMenuButton()}
            >
              {t("cancel")}
            </Button>
          )}
        </div>
      </div>

      {showEmpOnLeaveModal && (
        <>
          <Modal
            open={showEmpOnLeaveModal}
            onClose={() => setShowEmpOnLeaveModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="coustomModel"
          >
            <Box
              className="createEvent responsiveModelAdmin"
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                maxHeight: "70vh",
                width: "400px",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
              }}
            >
              <div>
                <ul>
                  {lempOnLeaveList.leaveList.map((item) => {
                    return <li>{item}</li>;
                  })}
                </ul>
              </div>
              <div>
                <p>{t("do_you_want_to_continue")} ?</p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 2,
                  }}
                >
                  <Button
                    // variant="contained"
                    // color="primary"
                    className="dfultPrimaryBtn"
                    onClick={() => {
                      setShowEmpOnLeaveModal(false);
                      finalCreateWebinar(lempOnLeaveList.reqDto);
                    }}
                  >
                    {t("yes")}
                  </Button>
                  <Button
                    // variant="contained"
                    // color="secondary"
                    className="dfultDarkSecondaryBtn"
                    onClick={() => {
                      setShowEmpOnLeaveModal(false);
                      setLoading(false);
                    }}
                  >
                    {t("no")}
                  </Button>
                </div>
              </div>
            </Box>
          </Modal>
        </>
      )}

      {showRecAudio && (
        <>
          <Modal
            open={showRecAudio}
            onClose={() => handleShowRecAudioClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="coustomModel"
          >
            <Box
              className="createEvent responsiveModelAdmin"
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                maxHeight: "70vh",
                width: "400px",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
              }}
            >
              <ConfirmProvider>
                <RecordAudio
                  closeDialog={() => handleShowRecAudioClose()}
                  tempAudioChunk={tempAudioChunkRef}
                  recordingLength={recordingLength}
                  setRecordingLength={setRecordingLength}
                  recordingLink={recordingLink}
                  setRecordingLink={setRecordingLink}
                />
              </ConfirmProvider>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
};

export default Webinar;
