import {
  AccessibleRounded,
  Add,
  ArrowBack,
  ArrowBackIosNewRounded,
  ArrowForwardIosRounded,
  FemaleRounded,
  MaleRounded,
  Search,
} from "@mui/icons-material";
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
// import ReactDatePicker from "react-datepicker";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DashboardServices from "../../../../../services/DashboardServices";
import { jwtDecode } from "jwt-decode";
import DateUtils from "../../../../../utils/DateUtils";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { enIN, es } from "date-fns/locale";
import Joyride from "react-joyride";
import { TourContext } from "../../../../../common/TourProvider";
import { GetLoginUserDetails } from "../../../../../utils/Utils";

// Register locales with ReactDatePicker
registerLocale("en", enIN);
registerLocale("es", es);

export default function ViewBookAppointmentMoreSlots({
  handelCloseViewBookAppointment,
  closeAll,
  handelOpenAppointmentBooking,
  handelCloseAppointBooking,
  selectedDocId,
  refreshbookAppointMent,
  bookAppointClass,
  registeredPatient,
}) {
  console.log("selectedDocId", selectedDocId);
  const { t, i18n } = useTranslation();
  const userDetails = GetLoginUserDetails();
  const [monthSelect, setMonthSelect] = useState(new Date());
  const [selectDoctor, setSelectDoctor] = useState("");
  const [doctorSlotBookData, setDoctorSlotBookData] = useState([]);
  const [extractedData, setExtractedData] = useState([]);
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedSlotId, setSelectedSlotId] = useState(null);
  const [submitData, setSubmitData] = useState({});

  // Determine the locale based on the current language
  const currentLocale = i18n.language === "es" ? "es" : "en";

  const [booksteps, setBookSteps] = useState([]);
  const { steps, run, setRun } = useContext(TourContext);

  useEffect(() => {
    setBookSteps([
      {
        target: ".bookSlotOne",
        content: "Click on the arrow icon to return to the previous screen.",
        placement: "bottom",
        disableBeacon: true,
      },
      {
        target: ".bookSlotTwo",
        content: "Choose the doctor you want to book an appointment with.",
        placement: "bottom",
        disableBeacon: true,
      },
      {
        target: ".bookSlotThree",
        content: "Pick a date to see available slots for the selected doctor.",
        placement: "bottom",
        disableBeacon: true,
      },
      {
        target: ".bookSlotFour",
        content:
          "Here are the available slots and the Booked Slot for the selected date.",
        placement: "top",
        disableBeacon: true,
      },
      {
        target: ".bookSlotFive",
        content: "Select a time slot to book an appointment.",
        placement: "top",
        disableBeacon: true,
      },
    ]);
  }, []);

  useEffect(() => {
    if (selectedDocId) {
      setSelectDoctor(selectedDocId);
    } else {
      setSelectDoctor("");
    }
  }, [selectedDocId]);

  const [listOfDoctorForAppointment, setListOfDoctorForAppointment] = useState(
    []
  );

  // useEffect(() => {
  //   DashboardServices.getDoctorsofUnitForAppointment(
  //     userDetails.signleUnitId
  //   ).then((response) => {
  //     console.log("response", response.data);
  //     setListOfDoctorForAppointment(response.data);
  //   });
  // }, []);

  useEffect(() => {
    DashboardServices.getDoctorsofUnitForAppointment(userDetails.signleUnitId)
      .then((response) => {
        console.log("response", response.data);
        const doctors = response.data || [];

        const selectedDoctor = doctors.find(
          (doctor) => doctor.doctorId === selectedDocId
        );

        if (selectedDoctor) {
          setListOfDoctorForAppointment(doctors);
        } else {
          DashboardServices.getDoctorListWithAvailableSlot(
            userDetails.signleUnitId
          ).then((response) => {
            console.log("response", response.data);
            const availableDoctors = response.data || [];

            const availableDoctor = availableDoctors.find(
              (doc) => doc.doctorId === selectedDocId
            );

            if (availableDoctor) {
              setListOfDoctorForAppointment([
                ...doctors,
                {
                  doctorId: availableDoctor.doctorId,
                  doctorName: availableDoctor.doctorName,
                  specializationId: null,
                  fees: availableDoctor.fees,
                  feesCurrency: availableDoctor.feesCurrency,
                  specializationName: availableDoctor.specialization,
                  queueCount: 0,
                  languages: availableDoctor.language,
                  experience: availableDoctor.experience,
                  avgConsultTime: 0,
                  licenseIssuingAuth: null,
                  salutation: null,
                  qualification: null,
                  practiceStartDate: null,
                  doctorSignImageUrl: null,
                  medicalLicenseNo: null,
                  medicalLicenseCertificate: null,
                  medicalLicenseCertificateUrl: null,
                  medicalLicenseCertificateBase64Data: null,
                  doctorGender: null,
                  doctorDob: null,
                  unitIds: null,
                  doctorProfileImageUrl: null,
                  orgId: null,
                  activeFlag: null,
                },
              ]);
            }
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching doctors:", error);
      });
  }, [userDetails.signleUnitId, selectedDocId]);

  console.log("listOfDoctorForAppointment", listOfDoctorForAppointment);

  useEffect(() => {
    fetchDoctorSlot();
  }, [monthSelect, selectDoctor, refreshbookAppointMent]);

  const fetchDoctorSlot = () => {
    if (monthSelect && selectDoctor) {
      const reqDto = {
        fromDate: DateUtils.getDateInDDMMYYYY(monthSelect),
        doctorId: selectDoctor,
        unitId: userDetails.signleUnitId,
      };
      console.log("reqDto", reqDto);

      DashboardServices.getDoctorSlots(reqDto).then((response) => {
        console.log("response", response.data);
        setDoctorSlotBookData(response.data);
      });
    }
  };

  const handleChangselectDoctor = (event) => {
    setSelectDoctor(event.target.value);
  };

  const handleMonthSelect = (newDate) => {
    setMonthSelect(newDate);
  };

  useEffect(() => {
    return () => {
      handelCloseAppointBooking();
    };
  }, []);

  useEffect(() => {
    if (doctorSlotBookData.length > 0) {
      const extracted = extractSlotData(doctorSlotBookData);
      setExtractedData(extracted);
      setSelectedDay(extracted[0].slotDate);
      console.log("extractedData", extracted);
    }
  }, [doctorSlotBookData]);

  function extractSlotData(data) {
    return data.map((day) => ({
      slotDate: day.slotDate,
      availableCount: day.availableCount,
      bookedCount: day.bookedCount,
      dayName: day.dayName,
      dayMonth: day.dayMonth,
      slots: day.slots.map((slot) => ({
        slotId: slot.slotId,
        fromTime: slot.fromTime,
        toTime: slot.toTime,
        patientId: slot.patientId,
        patientAge: slot.patientAge,
        patientGender: slot.patientGender,
        patientName: slot.patientName,
        patientUnitId: slot.patientUnitId,
        status: slot.status,
      })),
    }));
  }

  const handlePastSevenDaysDate = () => {
    const res = extractedData[0];
    const [day, month, year] = res.slotDate.split("-");
    const parsedDate = new Date(`${year}-${month}-${day}`);
    parsedDate.setDate(parsedDate.getDate() - 7);
    if (new Date() < parsedDate) {
      setMonthSelect(parsedDate);
    } else {
      setMonthSelect(new Date());
    }
  };

  // const handlePastSevenDaysDate = () => {
  //   const val = monthSelect;

  //   const getLastSevenDays = (date) => {
  //     let dates = [];
  //     for (let i = 7; i >= 0; i--) {
  //       let newDate = new Date(date);
  //       newDate.setDate(newDate.getDate() - i);
  //       dates.push(newDate);
  //     }
  //     return dates;
  //   };

  //   const lastSevenDays = getLastSevenDays(val);

  //   console.log("Last 7 days:", lastSevenDays);

  //   if(new Date() < lastSevenDays[0]){
  //     setMonthSelect(lastSevenDays[0]);
  //   }else{
  //   setMonthSelect(new Date());
  //   }
  // };

  // const handleFutureSevenDaysDate = () => {
  //   const val = monthSelect;

  //   const getFutureSevenDays = (date) => {
  //     let dates = [];
  //     for (let i = 7; i >= 0; i--) {
  //       let newDate = new Date(date);
  //       newDate.setDate(newDate.getDate() + i);
  //       dates.push(newDate);
  //     }
  //     return dates;
  //   };

  //   const futureSevenDays = getFutureSevenDays(val);

  //   console.log("Future 7 days:", futureSevenDays);

  //   setMonthSelect(futureSevenDays[0]);
  // };

  const handleFutureSevenDaysDate = () => {
    const res = extractedData[6];
    const [day, month, year] = res.slotDate.split("-");
    const parsedDate = new Date(`${year}-${month}-${day}`);
    parsedDate.setDate(parsedDate.getDate() + 1);
    setMonthSelect(parsedDate);
  };

  const handleSubmitData = (slot) => {
    console.log("slot", slot);
    console.log("listOfDoctorForAppointment", listOfDoctorForAppointment);
    console.log("doctorSlotBookData", doctorSlotBookData);

    const delDoctor = listOfDoctorForAppointment.find(
      (val) => val.doctorId === selectDoctor
    );

    const delDate = doctorSlotBookData.find((val) =>
      val.slots.some((slotItem) => slotItem.slotId === slot.slotId)
    );

    // val.slots.slotId === slot.slotId
    // console.log('delDate', delDate);

    const [day, month, year] = delDate.slotDate.split("-");
    const parsedDate = new Date(`${year}-${month}-${day}`);

    const obj = {
      doctorName: delDoctor.doctorName,
      specialization: delDoctor.specializationName,
      experience: delDoctor.experience,
      language: delDoctor.languages,
      fees: delDoctor.fees,
      feesCurrency: delDoctor.feesCurrency,
      rating: null,
      numberOfUserRated: 0,
      imageLink: delDoctor.doctorProfileImageUrl,
      timezone: "",
      doctorId: delDoctor.doctorId,
      slot: slot,
      date: parsedDate,
      patientData: registeredPatient || null,
    };

    console.log("obj", obj);

    // return

    handelOpenAppointmentBooking(obj);
  };

  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["viewSlotAllDoctorNs"] === true}
        continuous={true}
        showSkipButton={true}
        styles={{
          buttonClose: {
            display: "none",
          },
        }}
      />
      <div className="AnalyticsDashboardContainer">
        <div className="AnalyticsDashboardSubHeader bookSlotOne">
          <IconButton
            onClick={() => {
              console.log("closing");
              handelCloseViewBookAppointment();
              closeAll();
            }}
          >
            <ArrowBack />
          </IconButton>
          {t("book_doctor_appointment")}
        </div>
        <div className="anDashCardContainArea">
          <div className="fullContainArea">
            <div className="cuHeaderGrp">
              <div className="formSearchGrp">
                <div className="formElement width250">
                  <FormControl className="formControl bookSlotTwo">
                    <InputLabel id="selectDoctor">
                      {t("select_doctor")}
                    </InputLabel>
                    <Select
                      required
                      labelId="selectDoctor"
                      id="select_Doctor"
                      // value={selectDoctor || ""}
                      value={
                        selectDoctor === undefined ||
                        selectDoctor === "undefined"
                          ? ""
                          : selectDoctor
                      }
                      label={t("select_doctor")}
                      onChange={handleChangselectDoctor}
                      className="formInputField"
                      variant="outlined"
                      renderValue={(value) => {
                        const selectedDoctor = listOfDoctorForAppointment.find(
                          (doc) => doc.doctorId === value
                        );
                        return `${selectedDoctor?.doctorName}`;
                      }}
                    >
                      {listOfDoctorForAppointment.map((doctor) => (
                        <MenuItem key={doctor.doctorId} value={doctor.doctorId}>
                          <div
                            style={{ padding: "4px 12px" }}
                            className="sDocOptList"
                          >
                            <div className="sDname">{doctor.doctorName}</div>
                            <div className="sDElm">
                              {doctor.specializationName}
                            </div>
                            <div className="sDElmGrp">
                              <div className="sDElm">
                                {t("experience")}:{" "}
                                <span>{doctor.experience}</span>
                              </div>
                              <div className="sDElm">
                                {t("fees")}:{" "}
                                <span>
                                  {doctor.fees} {doctor.feesCurrency}
                                </span>
                              </div>
                              <div className="sDElm">
                                {t("language")}:{" "}
                                <span>
                                  {/* {doctor.languages} */}
                                  {doctor.languages &&
                                    doctor.languages.split(",").length > 0 &&
                                    doctor.languages
                                      .split(",")
                                      .map((langName, index) => {
                                        return (
                                          t(
                                            langName
                                              .replace(/\s+\(/g, "_")
                                              .replace(/\)/g, "")
                                              .replace(/\s+/g, "_")
                                          ) +
                                          (index ===
                                          doctor.languages.split(",").length - 1
                                            ? ""
                                            : ", ")
                                        );
                                      })}
                                </span>
                              </div>
                              {/* <div className="sDElm">
                Patient in Queue: <span>{doctor.queueCount}</span>
              </div> */}
                            </div>
                          </div>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="formElement width180">
                  <FormControl className="formControl selectMonth">
                    <InputLabel id="monthSelect" className="setCompletionDate">
                      {t("slots_date_from")}
                    </InputLabel>
                    <ReactDatePicker
                      locale={currentLocale}
                      toggleCalendarOnIconClick
                      yearDropdownItemNumber={100}
                      scrollableMonthYearDropdown
                      showIcon
                      labelId="monthSelect"
                      className="formDatePicker bookSlotThree"
                      selected={monthSelect}
                      minDate={new Date()}
                      maxDate={
                        new Date(new Date().setMonth(new Date().getMonth() + 4))
                      }
                      dateFormat="dd-MM-yyyy"
                      onChange={(date) => {
                        handleMonthSelect(date);
                      }}
                    />
                  </FormControl>
                </div>
              </div>
            </div>

            <div className="days-header-actionBtn">
              <IconButton onClick={() => handlePastSevenDaysDate()}>
                <ArrowBackIosNewRounded />
              </IconButton>

              <div className="days-header">
                {extractedData.map((day) => {
                  // Determine the appropriate class name for each day
                  let dayClass = "day-box bookSlotFour";

                  if (day.slotDate === selectedDay) {
                    dayClass += " active";
                  } else if (
                    day.availableCount === 0 ||
                    day.availableCount === "0"
                  ) {
                    dayClass += " slotUnavailable";
                  }

                  return (
                    <div
                      key={day.slotDate}
                      className={dayClass}
                      onClick={() => setSelectedDay(day.slotDate)} // Update the selected day
                    >
                      <div className="daysDate">
                        <div className="ddHighTxt">{day.slotDate}</div>
                        <div className="ddSubTxt">{t(day.dayName)}</div>
                      </div>
                      <div className="slotsStatus">
                        {day.availableCount > 0 && (
                          <div className="slotsAvailable">
                            <div className="daysIcon">
                              <AccessibleRounded />
                            </div>
                            <div className="slotsDtl">
                              <div className="sdHtxt">
                                {day.availableCount} {t("slots")}{" "}
                              </div>
                              <div className="sdSubTxt">{t("available")}</div>
                            </div>
                          </div>
                        )}
                        <div className="slotsBooked">
                          <div className="daysIcon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotsDtl">
                            <div className="sdHtxt">
                              {day.availableCount > 0
                                ? `${day.bookedCount} ${t("slots")}`
                                : `${day.bookedCount} ${t("booked")}`}
                            </div>

                            {/* <div className="sdHtxt">
                              {day.bookedCount} slots{" "}
                            </div> */}
                            {day.availableCount > 0 ? (
                              <div className="sdSubTxt">{t("booked")}</div>
                            ) : (
                              <div className="sdSubTxt">
                                {t("slots_unavailable")}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              <IconButton onClick={handleFutureSevenDaysDate}>
                <ArrowForwardIosRounded />
              </IconButton>
            </div>

            <div className="cuContainArea">
              <div className="appoinmentBookingSlot">
                <div className="slots-container">
                  {extractedData
                    .filter((day) => day.slotDate === selectedDay)
                    .flatMap((day) =>
                      day.slots.map((slot) => {
                        let slotClass = "slot-box bookSlotFive";
                        if (slot?.patientId && slot.patientId !== 0) {
                          slotClass += " booked";
                        } else if (
                          bookAppointClass &&
                          slot.slotId === selectedSlotId
                        ) {
                          slotClass += " active";
                        } else if (
                          !bookAppointClass &&
                          slot.slotId === selectedSlotId
                        ) {
                          slotClass += "";
                        }

                        return (
                          <div
                            key={slot.slotId}
                            className={slotClass}
                            onClick={() => {
                              if (!slot.patientId) {
                                setSelectedSlotId(slot.slotId);
                                // handelOpenAppointmentBooking();
                              }
                              handleSubmitData(slot);
                              if (run["viewSlotAllDoctorNs"] === true) {
                                setRun({
                                  slotBookConfirmns: true,
                                });
                              }
                            }}
                          >
                            <div className="slotInfo">
                              <div className="icon">
                                <AccessibleRounded />
                              </div>
                              <div className="slotTime">
                                {" "}
                                {slot.fromTime} - {slot.toTime}
                              </div>
                            </div>
                            {slot?.patientName &&
                              slot.patientName.trim() !== "" && (
                                <div className="bookedPatInfo">
                                  <div className="patDtl">
                                    <div className="patgender">
                                      {slot?.patientGender &&
                                        slot.patientGender.toLowerCase() ===
                                          "male" && <MaleRounded />}
                                      {slot?.patientGender &&
                                        slot.patientGender.toLowerCase() ===
                                          "female" && <FemaleRounded />}
                                    </div>
                                    <div className="patName">
                                      {slot.patientName}
                                    </div>
                                  </div>
                                  <div className="patAge">
                                    {slot.patientAge}
                                  </div>
                                </div>
                              )}
                          </div>
                        );
                      })
                    )}
                </div>

                {/* <div className="slots-container">
                  {extractedData.flatMap(day => day.slots.map(slot => (
                    <div
                      key={slot.slotId}
                      className={`slot-box ${slot.patientId ? 'booked' : 'active'}`}
                      onClick={() => {
                        if (!slot.patientId) {
                          handelOpenAppointmentBooking();
                        }
                      }}
                    >
                      <div className="slotInfo">
                        <div className="icon">
                          <AccessibleRounded />
                        </div>
                        <div className="slotTime"> {slot.fromTime} - {slot.toTime}</div>
                      </div>
                      {slot.patientId && (
                        <div className="bookedPatInfo">
                          <div className="patDtl">
                            <div className="patgender">
                              <MaleRounded />
                            </div>
                            <div className="patName">Sanket Santra</div>
                          </div>
                          <div className="patAge">32Y 6M 26D</div>
                        </div>
                      )}
                    </div>
                  )))}
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
