import React, { useContext, useEffect, useState } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController,
  RadialLinearScale,
  PolarAreaController,
} from "chart.js";

import {
  Accessible,
  Add,
  ManageHistoryRounded,
  VisibilityOutlined,
} from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import { Bar, Pie, PolarArea } from "react-chartjs-2";
import { ArrowRepeat, Eye } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import Joyride from "react-joyride";
import { TourContext } from "../../../../../common/TourProvider";

export default function CardDoctorConsultations({
  handleOpenViewDoctorConsultList,
  chartDataOfItem,
  fetchDashBoardData,
}) {
  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    LineController,
    BarController,
    RadialLinearScale,
    PolarAreaController
  );
  const { t } = useTranslation();

  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  //  console.log('chartDataOfItem***' , chartDataOfItem);
  //  console.log('chartDataOfItem***' , chartDataOfItem.todayDoctorRxMedInvRefCount)

  useEffect(() => {
    setBookSteps([
      {
        target: ".docConsultStepOne",
        content: "This is the section where you can view doctor consultations",
        placement: "bottom",
        disableBeacon: true,
      },
      {
        target: ".docConsultStepTwo",
        content:
          "This is the section where you can view doctor consultations data and refresh the charts.",
        placement: "bottom",
        disableBeacon: true,
      },
      {
        target: ".docConsultStepTwoandthree",
        content:
          "This chart shows the distribution of patient data across different categories. Hover over a segment to view details.",
        placement: "bottom",
        disableBeacon: true,
      },
      {
        target: ".docConsultStepThree",
        content:
          "This chart shows day-wise consultation counts. It helps you analyze trends over time.",
        placement: "left",
        disableBeacon: true,
      },
      {
        target: ".docConsultStepFour",
        content: "Click here to view detailed consultations list.",
        placement: "top",
        disableBeacon: true,
      },
    ]);
  }, []);

  const { todayDoctorRxMedInvRefCount } = chartDataOfItem || {};

  const noOfPrescription = todayDoctorRxMedInvRefCount?.noOfPrescription || 0;

  const labels = [
    todayDoctorRxMedInvRefCount?.noMedicine === 0 ? "" : t("no_medicine"),
    todayDoctorRxMedInvRefCount?.overmedication === 0
      ? ""
      : t("overmedication"),
    todayDoctorRxMedInvRefCount?.test === 0 ? "" : t("test"),
    todayDoctorRxMedInvRefCount?.refer === 0 ? "" : t("refer"),
  ];

  const dataValues = [
    todayDoctorRxMedInvRefCount?.noMedicine === 0
      ? noOfPrescription
      : todayDoctorRxMedInvRefCount?.noMedicine,
    todayDoctorRxMedInvRefCount?.overmedication === 0
      ? noOfPrescription
      : todayDoctorRxMedInvRefCount?.overmedication,
    todayDoctorRxMedInvRefCount?.test === 0
      ? noOfPrescription
      : todayDoctorRxMedInvRefCount?.test,
    todayDoctorRxMedInvRefCount?.refer === 0
      ? noOfPrescription
      : todayDoctorRxMedInvRefCount?.refer,
  ];

  const backgroundColors = [
    todayDoctorRxMedInvRefCount?.noMedicine === 0
      ? "rgb(84, 84, 84, 1)"
      : "rgba(79, 212, 0, 1)",
    todayDoctorRxMedInvRefCount?.overmedication === 0
      ? "rgb(84, 84, 84, 1)"
      : "rgba(241, 61, 60, 1)",
    todayDoctorRxMedInvRefCount?.test === 0
      ? "rgb(84, 84, 84, 1)"
      : "rgba(254, 162, 59, 1)",
    todayDoctorRxMedInvRefCount?.refer === 0
      ? "rgb(84, 84, 84, 1)"
      : "rgba(234, 184, 0, 1)",
  ];

  const data = todayDoctorRxMedInvRefCount
    ? {
        labels,
        datasets: [
          {
            label: "",
            data: dataValues,
            backgroundColor: backgroundColors,
            borderColor: "rgba(255, 255, 255, .6)",
            borderWidth: 1,
          },
        ],
      }
    : { labels: [], datasets: [] };

  const options = {
    responsive: true,
    scales: {
      r: {
        ticks: {
          display: false,
        },
        max: todayDoctorRxMedInvRefCount?.noOfPrescription || 100,
        min: 0,
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
  };

  const barLineData = {
    labels:
      chartDataOfItem?.dayWiseDoctorConsultation?.length > 0
        ? chartDataOfItem.dayWiseDoctorConsultation.map((item) => item.label)
        : [],
    datasets: [
      {
        label: "",
        data:
          chartDataOfItem?.dayWiseDoctorConsultation?.length > 0
            ? chartDataOfItem.dayWiseDoctorConsultation.map(
                (item) => item.count
              )
            : [],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const barLineOoptions = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "",
      },
    },
  };

  console.log("run doc", run["docConsultDc"], run);

  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["docConsultDc"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
      />
      <div className="anDashIndCard docConsultStepOne">
        <div className="anDashCardArea">
          <div className="andHeader">
            <div className="andCrdNM docConsultStepTwo">
              {t("doctor_consultations")}
              <IconButton
                className={`refreshIcon`}
                onClick={fetchDashBoardData}
              >
                <ArrowRepeat />
              </IconButton>
            </div>
            <div className="andCrdUnitCount">
              {/* <span title="Attrition"> */}
              {/* <Male /> */}
              {/* This Month: <span className="fbold"> 215421</span> */}
              {/* </span> */}

              {/* <span title="Tenure"> */}
              {/* <Female /> */}
              {/* This week: <span className="fbold">12154</span> */}
              {/* </span> */}
            </div>
          </div>

          <div className="andCrdGraf">
            <div className="anDLftGrf docConsultStepTwoandthree">
              <div className="centTxtShow">
                {/* <Pie
                  className="hw100"
                  type="doughnut"
                  data={data}
                  options={options}
                /> */}
                <PolarArea
                  className="hw100"
                  type="polarArea"
                  data={data}
                  options={options}
                />
                <div className="highlighted-text">
                  {/* <span>Total</span> */}
                  {/* <span className="highDataCount">1635</span> */}
                </div>
              </div>
            </div>
            <div className="anDRightGrf docConsultStepThree">
              <Bar
                className="hw100"
                type="bar"
                data={barLineData}
                options={barLineOoptions}
              />
              {/* <Bar data={barLineData} options={barLineOoptions} /> */}
            </div>
          </div>

          {/* <div className="andCrdData"></div> */}
          <div className="andCrdFooter">
            <Button
              className="dfultPrimaryBtn docConsultStepFour"
              startIcon={<Eye />}
              onClick={() => {
                handleOpenViewDoctorConsultList();
                if (run["docConsultDc"] === true) {
                  setRun({
                    viewDocConsultationDc: true,
                  });
                }
              }}
            >
              {t("view")}
            </Button>
            {/* <Button
              className="dfultPrimaryBtn "
              startIcon={<Accessible />}
              onClick={() => handelOpenViewBookAppointment()}
            >
              Appointment
            </Button> */}
          </div>
        </div>
      </div>
    </>
  );
}
