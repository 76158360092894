import { Cancel, Edit, Save } from "@mui/icons-material";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DashboardServices from "../../../../../services/DashboardServices";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export const PriorityComponent = ({ data, refreshData, removeFromStore }) => {
  let { t } = useTranslation();
  const [showEditPriority, setShowEditPriority] = useState(false);
  const [priority, setPriority] = useState("0");

  useEffect(() => {
    setPriority(data.priority);
  }, [data]);
  return (
    <>
      <div className="taskElementGrp">
        <div className="taskContainEditBtnGrp">
          <div className="tskElemHeding">{t("priority")}</div>
          {data.incoming === "N" && (
            <Tooltip arrow title={t("edit_priority")}>
              <IconButton
                className="editTimelineBtn"
                onClick={() => {
                  setShowEditPriority((prev) => !prev);
                }}
              >
                {!showEditPriority ? <Edit /> : <Cancel />}
              </IconButton>
            </Tooltip>
          )}
        </div>
        <div className="tskPrioArea">
          {/* statUrgent, statNormal, statImportant  used these three for diffrent priority */}
          {!showEditPriority && (
            <div
              class={`tskPriot  ${
                data.priority === 0
                  ? "statNormal"
                  : data.priority === 1
                  ? "statImportant"
                  : "statUrgent"
              }`}
            >
              <div class={`priStat `}>
                {data.priority === 0
                  ? t("regular")
                  : data.priority === 1
                  ? t("important")
                  : t("critical")}
              </div>
            </div>
          )}
          {showEditPriority && (
            <>
              <div className="taskContainEditBtnGrp">
                <div className="formElement">
                  <FormControl className="formControl">
                    <InputLabel id="selectPriority">{t("priority")}</InputLabel>
                    <Select
                      className="formInputField"
                      variant="outlined"
                      labelId="selectPriority"
                      id="priority-select"
                      value={priority}
                      label={t("priority")}
                      onChange={(event) => {
                        setPriority(event.target.value);
                      }}
                    >
                      <MenuItem value={"0"}>{t("routine")}</MenuItem>
                      <MenuItem value={"1"}> {t("important")} </MenuItem>
                      <MenuItem value={"2"}> {t("critical")} </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <IconButton
                  className="editTimelineBtn"
                  onClick={() => {
                    DashboardServices.updatePriority(
                      data.taskId,
                      priority
                    ).then((response) => {
                      if (response.data == 0) {
                        toast.error(t("task_could_not_be_updated"));
                      } else {
                        toast.success(t("task_updated_successfully"));
                        refreshData();
                        removeFromStore();
                      }
                    });
                  }}
                >
                  <Save />
                </IconButton>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
