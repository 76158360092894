import { ArrowBack } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ConsultationAnalyticDetails from "./detailsAnalytic/consultation_analytic/ConsultationAnalyticDetails";
import DoctorAnalyticDetails from "./detailsAnalytic/doctors_analytic/DoctorAnalyticDetails";

export default function ViewManagementAnalyticDashboard({
  menuId,
  onCloseMore,
  closeAll,
}) {
  const { t } = useTranslation();
  const [selectedMainMenu, setSelectedMainMenu] = useState("");
  const [selectedSubMenu, setSelectedSubMenu] = useState("");

  useEffect(() => {
    if (menuId) {
      switch (menuId) {
        case 53:
          setSelectedMainMenu("_consultation_");
          setSelectedSubMenu("_const_graph_");
          break;

        case 54:
          setSelectedMainMenu("_doctors_");
          setSelectedSubMenu("_doc_graph_");
          break;

        default:
          break;
      }
    }
  }, [menuId]);

  return (
    <>
      <div className="AnalyticsDashboardContainer">
        <div className="AnalyticsDashboardSubHeader">
          <IconButton
            onClick={() => {
              onCloseMore();
              closeAll();
            }}
          >
            <ArrowBack />
          </IconButton>
          {t("analytic_details")}
        </div>
        <div className="anDashCardContainArea">
          <div className="fullContainArea">
            <div className="cuContainArea insideAnDashboard">
              <aside className="leftMenu">
                <ul>
                  <li
                    className={`indSideMenu ${
                      selectedMainMenu === "_consultation_"
                        ? "activeMenuDashbord"
                        : ""
                    }`}
                  >
                    <div
                      className={`menuName ${
                        selectedSubMenu === "_const_graph_" ? "activeSM" : ""
                      }`}
                      onClick={() => {
                        setSelectedMainMenu("_consultation_");
                        setSelectedSubMenu("_const_graph_");
                      }}
                    >
                      {t("consultations")}
                    </div>
                    <ul>
                      {/* <li
                        className={`indSideMenu ${
                          selectedSubMenu === "_const_graph_" ? "activeSM" : ""
                        }`}
                      >
                        Overview
                      </li> */}
                      <li
                        className={`indSideMenu ${
                          selectedSubMenu === "_queue_history_"
                            ? "activeSM"
                            : ""
                        }`}
                        onClick={() => {
                          setSelectedMainMenu("_consultation_");
                          setSelectedSubMenu("_queue_history_");
                        }}
                      >
                        {t("queue_history")}
                      </li>
                    </ul>
                  </li>

                  <li
                    className={`indSideMenu ${
                      selectedMainMenu === "_doctors_"
                        ? "activeMenuDashbord"
                        : ""
                    }`}
                  >
                    <div
                      className={`menuName ${
                        selectedSubMenu === "_doc_graph_" ? "activeSM" : ""
                      }`}
                      onClick={() => {
                        setSelectedMainMenu("_doctors_");
                        setSelectedSubMenu("_doc_graph_");
                      }}
                    >
                      {t("doctors")}
                    </div>
                    <ul>
                      {/* <li
                        className={`indSideMenu ${
                          selectedSubMenu === "_doc_graph_" ? "activeSM" : ""
                        }`}
                       
                      >
                        Overview
                      </li> */}
                      <li
                        className={`indSideMenu ${
                          selectedSubMenu === "_doctors_details_"
                            ? "activeSM"
                            : ""
                        }`}
                        onClick={() => {
                          setSelectedMainMenu("_doctors_");
                          setSelectedSubMenu("_doctors_details_");
                        }}
                      >
                        {t("doctor_wise_consultation")}
                      </li>
                    </ul>
                  </li>
                </ul>
              </aside>
              <div className="rightDetailsAnContain">
                {selectedMainMenu === "_consultation_" && (
                  <ConsultationAnalyticDetails selectedMenu={selectedSubMenu} />
                )}
                {selectedMainMenu === "_doctors_" && (
                  <DoctorAnalyticDetails selectedMenu={selectedSubMenu} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
