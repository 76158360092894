import { Tooltip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

function GoodsAndServicesFormPreview({ tableDataList, ExpenditureGoods }) {
  const { t } = useTranslation();
  const calculateRowTotal = (rowId, qty, rate) => {
    console.log("calculated row total", qty, "  ", rate);
    const value = qty * rate;
    let temp = [...tableDataList];

    let itemIndex = temp.findIndex((obj) => obj.id === rowId);
    tableDataList[itemIndex].estimatedValue = value;
    return value ? value : 0;
  };

  const calculateTotalValue = () => {
    let totalVal = 0;
    for (let i = 0; i < tableDataList.length; i++) {
      const { indentQty, estimatedRate } = tableDataList[i];
      totalVal += parseInt(indentQty) * parseInt(estimatedRate);
    }
    return totalVal ? totalVal : 0;
  };
  return (
    <>
      <div className="indentFormtable">
        <div className="indtableResponsive">
          <table>
            <thead>
              <tr>
                <th className="w200">
                  {ExpenditureGoods === "services"
                    ? t("service_name")
                    : t("item_name") + "*"}
                </th>
                <th>
                  <Tooltip arrow title={t("desc_spec")}>
                    {t("desc_spec")}
                  </Tooltip>
                </th>
                <th>{t("ledger_head")} *</th>
                <th>{t("uom")} *</th>

                {ExpenditureGoods === "goods" && (
                  <th className="StockQty">{t("stock_qty")}</th>
                )}

                <th className="widthMin120">
                  <Tooltip arrow title={t("prev_purc_rate")}>
                    {t("prev_purc_rate")}
                  </Tooltip>
                </th>
                <th className="indntQunt">{t("indent_qty")} *</th>
                <th className="widthMin120">{t("estimated_rate")} *</th>
                <th className="widthMin120">{t("estimated_value")}</th>
              </tr>
            </thead>
            <tbody>
              {tableDataList &&
                tableDataList.map((item) => {
                  return (
                    <tr>
                      <td>{item.itemName}</td>
                      <td>
                        <div className="textOnly">{item.itemDescription}</div>
                      </td>
                      <td>
                        <div className="textOnly">{item.purpose.head}</div>
                      </td>
                      <td>
                        <div className="textOnly">{item.uom}</div>
                      </td>
                      {ExpenditureGoods === "goods" && (
                        <td>
                          <div className="textOnly">{item.currentStk}</div>
                        </td>
                      )}

                      <td>
                        <div className="textOnly">{item.prePurshaseRate}</div>
                      </td>
                      <td>
                        <div className="textOnly">{item.indentQty}</div>
                      </td>
                      <td>
                        <div className="textOnly">{item.estimatedRate}</div>
                      </td>
                      <td>
                        <div className="textOnly">
                          {calculateRowTotal(
                            item.id,
                            item.indentQty,
                            item.estimatedRate
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={ExpenditureGoods === "services" ? "7" : "8"}>
                  <div className="textOnly tvText">{t("total_value")}</div>
                </td>
                <td>
                  <div className="textOnly fw600">{calculateTotalValue()}</div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </>
  );
}

export default GoodsAndServicesFormPreview;
