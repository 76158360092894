import { Edit, ListAlt } from "@mui/icons-material";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ArrowLeft, People, Play, XLg } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { clearSelectedTask } from "../../../../../redux/reducers/rightSideTaskPanelSlice";
import {
  clearTaskModalData,
  moveToPreviousTask,
  pushLinkTask,
  setTaskModalData,
} from "../../../../../redux/reducers/taskTab";
import DashboardServices from "../../../../../services/DashboardServices";
import TaskService from "../../../../../services/TaskService";
import {
  getFormattedDate,
  GetLoginUserDetails,
} from "../../../../../utils/Utils";
import "../../../css/task.css";
import isAssignedByMe from "../../../taskUtil";
import { taskTabContext } from "../../TaskTabContainer";
import AssigneeModal from "../AssigneeModal";
import ConcernTaskModal from "../ConcernTaskModal";
import FileList from "../FileList";
import { IncomingFormPreview } from "../Forms/IncomingFormPreview";
import PmsFinalReviewForm from "../Forms/PmsFinalReviewForm";
import PmsManagerReviewForm from "../Forms/PmsManagerReviewForm";
import PmsSalaryAdjustmentForm from "../Forms/PmsSalaryAdjustmentForm";
import { PurchaseFormRenderer } from "../Forms/PurchaseFormRenderer";
import SelfEvaluationPmsForm from "../Forms/SelfEvaluationPmsForm";
import ForwardTaskModal from "../ForwardTaskModal";
import { LinkedTaskModule } from "../LinkedTaskModule";
import LinkTaskModal from "../LinkTaskModal";
import { EditDeadLineModal } from "../Modals/EditDeadLineModal";
import MeetingModal from "../Modals/MeetingModal";
import RightFlotingContainer from "../RightFlotingContainer";
import RightTaskFileUpload from "../RightTaskFileUpload";
import TemplateList from "../TemplateList";

export default function TaskTabObserver({
  data,
  refreshData,
  handleClose,
  removeFromStore,
}) {
  const { t } = useTranslation();
  const context = useContext(taskTabContext);
  const { setShowConvenientMeetingView } = context;
  const convinientSelecteduser = context.setSelectedUsers;
  const [showMeetingModal, setShowMeetingsModal] = useState(false);

  const [showFileModal, setShowFileUploadModal] = useState(false);

  const [formData, setFormData] = useState(null);
  const [showForm, setShowForm] = useState(false);

  const scrollRef = useRef();

  const audioUrl = useRef();
  // const [audioUrl, setAudioUrl] = useState();
  const loginUserDetail = GetLoginUserDetails();
  const [progressWidth, setProgressWidth] = useState(0);
  const progress = useRef(0);

  const [dateRemaning, setDateRemaning] = useState(0);
  const [assigneeList, setAssigneeList] = useState([]);
  const dispatch = useDispatch();
  const fileDocuments = useRef(null);
  const [showForwardTask, setShowForwardTask] = useState(false);
  const [userList, setUserList] = useState([]);
  const [showCreateLinkTask, setShowCreateLinkTask] = useState(false);

  const [showAddTemplate, setShowAddTemplate] = useState(false);
  const [recommendedTemplateList, setRecommendedTemplateList] = useState([]);
  const [addedTemplateList, setAddedTemplateList] = useState([]);
  const [tempOfOrgList, setTemplateOfOrgList] = useState([]);
  const [showConcernModal, setShowConcernModal] = useState(false);
  const [showEditDeadline, setShowEditDeadLine] = useState(false);

  const [showAudioModal, setShowAudioModal] = useState(false);
  const [showAssigneeList, setShowAssigneeList] = useState(false);

  const links = useSelector((state) => state.taskList.taskLinks);
  // const linkTaskHtml=useMemo(()=>{

  // },data)

  const handleTaskSubmission = (response) => {
    // Failure message start
    if (response.data.returnValue === "0") {
      toast.error(t("something_went_wrong"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (response.data.returnValue === "-1") {
      toast.error(t("link_task_not_completed_against_this_task"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (response.data.returnValue === "-2") {
      toast.error(t("task_has_been_cancelled"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (response.data.returnValue === "-3") {
      toast.error("task_has_been_closed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (response.data.returnValue === "-4") {
      toast.error(t("task_has_been_completed"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    // Failure message end

    // Success message start
    else if (response.data.returnValue === "1") {
      toast.success(t("task_completed_successfully"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (response.data.returnValue === "2") {
      toast.success(t("concern_raised_successfully"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (response.data.returnValue === "3") {
      toast.success(t("approved_successfully"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (response.data.returnValue === "4") {
      toast.success(t("task_progress_report_submitted_successfully"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    const temp = response.data.returnValue;
    if (temp === "1" || temp === "2" || temp === "3" || temp === "4") {
      setShowConvenientMeetingView(false);
      convinientSelecteduser([]);
      dispatch(clearSelectedTask());
      refreshData();
    }
    // Success message end
  };

  const fetchExpenseApprovalRequest = () => {
    if (
      data.taskNature === "PMS_SELF_EVALUATION" ||
      data.taskNature === "PMS_MANAGER_REVIEW" ||
      data.taskNature === "PMS_FINAL_REVIEW" ||
      data.taskNature === "PMS_SALARY_ADJUSTMENT"
    ) {
      setShowForm(true);
      return;
    }

    if (data.taskNature === "INTERVIEW_FEEDBACK") {
      TaskService.getProspectiveEmployeeById(data.formDataTransId).then(
        (response) => {
          if (response.data) {
            setFormData(response.data);
            setShowForm(true);
          }
        }
      );
    } else {
      TaskService.getExpenseApprovalRequest(data.formDataTransId).then(
        (response) => {
          if (response.data) {
            setFormData(response.data);
            setShowForm(true);
          }
        }
      );
    }
  };

  const handleClickFormButton = () => {
    fetchExpenseApprovalRequest();
  };

  const gotoPreviousTAsk = () => {
    dispatch(moveToPreviousTask());
  };

  const clearStoreData = () => {
    dispatch(clearTaskModalData());
  };

  const intervalProgressor = (timeDelay) => {
    const intervalId = setInterval(() => {
      setDateRemaning((prev) => {
        if (prev < 100) {
          return prev + 1;
        }
        clearInterval(intervalId);
        return prev;
      });
      // if (progress.current < 100) {
      //   setDateRemaning((prev) => prev + 1);
      //   // setProgressWidth((prev) => prev + 1);
      //   progress.current = progress.current + 1;
      // } else {
      //   clearInterval(intervalId);
      // }
    }, timeDelay);
  };

  const handleAddTemplate = async () => {
    const value = await DashboardServices.getAllTemplatesOfOrganization(
      loginUserDetail.orgId
    );
    setTemplateOfOrgList(value.data);

    const response = await DashboardServices.getTemplatesOfSubActivity(
      data.activityId,
      0
    );
    let notaddedTemplateList = [];
    if (response.data != null && response.data.length > 0) {
      response.data.forEach((template) => {
        let isTemplateAdded = false;
        addedTemplateList.forEach((addedTemplate) => {
          if (template.templateId === addedTemplate.templateId) {
            isTemplateAdded = true;
          }
        });
        if (!isTemplateAdded) {
          notaddedTemplateList.push(template);
        }
      });
      setRecommendedTemplateList(notaddedTemplateList);
    }

    setShowAddTemplate(true);
  };

  const clearAllModals = () => {
    setShowForwardTask(false);
    setShowConcernModal(false);
    setShowCreateLinkTask(false);
    setShowAssigneeList(false);
  };

  const handleChangeFile = async (fileList) => {
    const reqDto = fileList.map((file) => ({
      id: 0,
      taskDtlId: data.taskId,
      fileName: file.fileName,
      fileDataLink: file.fileDataLink,
      uploadedBy: loginUserDetail.userId,
      fileDisplayName: file.fileName,
    }));

    DashboardServices.uploadDocuments(
      loginUserDetail.orgId,
      loginUserDetail.userId,
      data.taskId,
      reqDto
    ).then((response) => {
      if (response.data) {
        const tempData = { ...data };
        if (tempData.documents === null) {
          tempData.documents = [...response.data];
        } else {
          tempData.documents = [...data.documents, ...response.data];
        }
        dispatch(setTaskModalData(tempData));
      }
    });
  };

  const getTaskDifference = () => {
    // const time1=task.taskCreationTime
    // const time2 =task.taskCompletionTime
    const creationTime = new Date(data && data.createdAt);
    const completionTime = new Date(data && data.completionTime);
    const currentTime = new Date();

    //check if already time passed

    const diffFromCurrent = currentTime - completionTime;
    if (diffFromCurrent > 0) {
      setDateRemaning(100);
      return;
    }

    // Calculate the time difference in milliseconds
    const totalTimeDifference = completionTime - creationTime;
    const timeDifferenceFromNow = currentTime - creationTime;
    const milisecondPerPercentIncrease = totalTimeDifference / 100;
    const currentPercent = (timeDifferenceFromNow * 100) / totalTimeDifference;
    setDateRemaning(
      Math.floor(currentPercent) < 100 ? Math.floor(currentPercent) : 100
    );
    progress.current = Math.floor(currentPercent);
    const nextPercent = Math.ceil(currentPercent);
    const difference = nextPercent - currentPercent;
    const diffInMilisecond = milisecondPerPercentIncrease * difference;
    const clearTimeoutVal = setTimeout(() => {
      if (currentPercent < 100) {
        clearTimeout(clearTimeoutVal);
        setDateRemaning((prev) => prev + 1);
        progress.current = progress.current + 1;
        if (milisecondPerPercentIncrease > 0) {
          intervalProgressor(milisecondPerPercentIncrease);
        }
      }
    }, diffInMilisecond);
  };

  const calculateDateProgress = () => {};
  const handleLinkTaskClick = async (task) => {
    //
    const values = await DashboardServices.getTaskById(task.taskId);
    dispatch(pushLinkTask(data));
    dispatch(setTaskModalData({ ...task, ...values.data }));
  };

  useEffect(() => {
    setProgressWidth(data.progress);
    calculateDateProgress();
    DashboardServices.getAssigneesOfTask(data.taskId).then((response) => {
      setAssigneeList(response.data);
    });

    DashboardServices.getOrgEmployees(loginUserDetail.orgId).then(
      (response) => {
        setUserList(response.data);
      }
    );

    getTaskDifference();
  }, [data]);

  return (
    <>
      <div className="taskViewPannel">
        <div className="taskViewContainer">
          <div className="taskHeaderPanel">
            <div className="taskControlBtnGrp"></div>

            <div className="taskActionBtnGrp">
              <div className="taskActBtn">
                <IconButton
                  aria-label="User"
                  onClick={(e) => {
                    // setOpenUserList(true);
                    // handleClickUl(e);
                    clearAllModals();
                    setShowAssigneeList(true);
                  }}
                >
                  <Tooltip title={t("user")} arrow>
                    <People />
                  </Tooltip>
                </IconButton>
              </div>

              <div className="taskActBtn closeBtnD10">
                <IconButton
                  aria-label="close"
                  onClick={(e) => {
                    setShowConvenientMeetingView(false);
                    convinientSelecteduser([]);
                    dispatch(clearTaskModalData());
                  }}
                >
                  <Tooltip title={t("close")} arrow>
                    <XLg />
                  </Tooltip>
                </IconButton>
              </div>
            </div>
          </div>
          <div className="taskContain">
            <div className="taskContainDetail">
              <div className="taskContDtlContainer">
                {links && links.length > 0 && (
                  <div className="linktaskBreadCrumb">
                    <IconButton onClick={gotoPreviousTAsk}>
                      <ArrowLeft />
                    </IconButton>
                    <div className="prentTaskNM">
                      {links &&
                        links.length > 0 &&
                        links[links.length - 1].taskName}
                    </div>
                  </div>
                )}
                <div className="taskTitleContrct">
                  <div className="taskTitleNM">{data.taskName}</div>
                </div>

                {data && data.taskDescription && (
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">{t("description")}</div>
                    <div className="tskElemntInfo">{data.taskDescription}</div>
                  </div>
                )}
                {data && data.audioNoteFileName && (
                  <div className="taskElementGrp">
                    <div className="tskElemntInfo">
                      <div className="playAudioNotes">
                        <IconButton
                          variant="outlined"
                          onClick={(e) => {
                            DashboardServices.generatePreSignedUrlS3Object(
                              data.audioNoteFileName,
                              loginUserDetail.orgId
                            ).then((response) => {
                              audioUrl.current = response.data;
                              setShowAudioModal(true);
                            });
                          }}
                        >
                          <Play />
                        </IconButton>
                        <span>{t("play_audio_message")}</span>
                      </div>
                    </div>
                  </div>
                )}
                {data && data.contractName && (
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">{t("contract")}</div>
                    <div className="taskContractNm">{data.contractName}</div>
                  </div>
                )}

                <div className="taskElementGrp">
                  <div className="tskElemHeding">{t("status")}</div>
                  <div className="tskTimeline">
                    <div className="tskTimBar">
                      <div className="progress">
                        <div
                          // ps50 ps80 ps100
                          className={`progress-done ${
                            progressWidth < 50
                              ? "ps50"
                              : progressWidth < 80
                              ? "ps80"
                              : "ps100"
                          }`}
                          style={{ width: `${progressWidth}%`, opacity: 1 }}
                        >
                          <span className="pwCount">{progressWidth}%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="taskElementGrp">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div className="tskElemHeding">{t("timeline")}</div>
                    {data.incoming === "N" && (
                      <Tooltip arrow title={t("edit_timeline")}>
                        <IconButton
                          className="editTimelineBtn"
                          onClick={() => {
                            setShowEditDeadLine(true);
                          }}
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                  <div className="tskTimeline">
                    <div className="tskTimBar">
                      <div className="progress">
                        <div
                          // up50 up80 up100
                          className={`progress-done ${
                            dateRemaning < 50
                              ? "up50"
                              : dateRemaning < 80
                              ? "up80"
                              : "up100"
                          }`}
                          style={{ width: `${dateRemaning}%`, opacity: 1 }}
                        ></div>
                      </div>
                    </div>
                    <div className="tskDtSE">
                      <div className="tskDTStart">
                        {getFormattedDate(data.createdAt)}
                      </div>
                      <div className="tskDTEnd">
                        {getFormattedDate(data.completionTime)}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Templates start */}

                <TemplateList
                  data={data}
                  handleAddTemplate={handleAddTemplate}
                  allowAddTemplate={false}
                />
                {/* Templates end */}

                {/* files start */}

                <FileList
                  data={data}
                  handleChangeFile={handleChangeFile}
                  setShowFileUploadModal={setShowFileUploadModal}
                  allowAddFile={false}
                />

                {/* {data &&
                  data.formDataTransId &&
                  data.formDataTransId !== "" &&
                  data.formDataTransId !== "0" && (
                    <div className="taskElementGrp">
                      <div className="tskElemHeding">{t("form_data")}</div>
                      <IconButton
                        className="elemntMrFileBtn"
                        variant="outlined"
                        onClick={handleClickFormButton}
                      >
                        <ListAlt />
                      </IconButton>
                    </div>
                  )} */}

                {/* files end */}

                <div className="taskElementGrp">
                  <div className="tskElemHeding">{t("priority")}</div>
                  <div className="tskPrioArea">
                    {/* statUrgent, statNormal, statImportant  used these three for diffrent priority */}
                    <div
                      class={`tskPriot  ${
                        data.priority === 0
                          ? "statNormal"
                          : data.priority === 1
                          ? "statImportant"
                          : "statUrgent"
                      }`}
                    >
                      <div class={`priStat `}>
                        {data.priority === 0
                          ? t("regular")
                          : data.priority === 1
                          ? t("important")
                          : t("critical")}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="taskElementsGrpWithTwo">
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">{t("activity")}</div>
                    <div className="tskElemntInfo">{data.activityName}</div>
                  </div>
                </div>

                <div className="taskElementsGrpWithTwo">
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">{t("assigned_by")}</div>
                    <div className="tskElemntInfo">
                      {data && isAssignedByMe(data.assignByUserId)
                        ? t("me")
                        : data.assignByUserName}
                    </div>
                  </div>
                </div>

                <LinkedTaskModule
                  data={data}
                  handleLinkTaskClick={(data) => handleLinkTaskClick(data)}
                />

                {data && data.comments && data.comments.length && (
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">{t("last_updated")}</div>
                    <div className="tskElemntInfo">
                      {data &&
                        data.comments &&
                        data.comments.length > 0 &&
                        `${
                          data.comments[data.comments.length - 1]
                            .commentsByUserName
                        } - ${getFormattedDate(
                          data.comments[data.comments.length - 1].commentsAt
                        )}`}
                      {/* Sandeep Mohapatra - 2:15PM, 06-12-2023 */}
                    </div>
                  </div>
                )}

                <div className="taskUpCommentGrp">
                  {data &&
                    data.comments.length > 0 &&
                    data.comments.map((comment) => (
                      <div className="taskUserComment">
                        <div className="tskUderImgDT">
                          <div className="tskUseComImg">
                            <img
                              src={comment.commentsByUserProfileUrl}
                              alt=""
                            />
                          </div>
                          <div className="tskUsrCNM">
                            <div className="tskUCNM">
                              {comment.commentsByUserName}
                            </div>
                            <div className="tskUCDT">
                              {comment && getFormattedDate(comment.commentsAt)}
                            </div>
                          </div>
                        </div>
                        <div className="taskUPComnt">{comment.comments}</div>
                      </div>
                    ))}
                </div>
                <div ref={scrollRef}></div>
              </div>
            </div>
          </div>
        </div>

        {showMeetingModal && (
          <MeetingModal
            handleClose={(e) => setShowMeetingsModal(false)}
            assigneeList={assigneeList}
            taskId={data.taskId}
          />
        )}

        {showForwardTask && (
          <ForwardTaskModal
            data={data}
            userList={userList}
            refreshData={refreshData}
            handleClose={() => {
              setShowForwardTask(false);
            }}
            clearStoreData={clearStoreData}
          />
        )}

        {showCreateLinkTask && (
          <LinkTaskModal
            userList={userList}
            data={data}
            handleClose={(e) => setShowCreateLinkTask(false)}
            refreshData={refreshData}
            removeFromStore={removeFromStore}
          />
        )}

        {showConcernModal && (
          <ConcernTaskModal
            data={data}
            refreshData={refreshData}
            handleClose={(e) => setShowConcernModal(false)}
            removeFromStore={removeFromStore}
          />
        )}

        {showAssigneeList && (
          <AssigneeModal
            assigneeList={assigneeList}
            handleClose={(e) => setShowAssigneeList(false)}
          />
        )}

        {showAudioModal && (
          <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
            <div className="addMoreAgendaModel">
              <div className="modelTitle">{t("audio_message")}</div>
              <div className="modActionBtnGrp">
                <audio controls>
                  <source src={`${audioUrl.current}`} />
                </audio>
              </div>

              <div className="modActionBtnGrp">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={() => {
                    setShowAudioModal(false);
                  }}
                >
                  {t("cancel")}
                </Button>
              </div>
            </div>
          </Box>
        )}

        {showEditDeadline && (
          <EditDeadLineModal
            createdAt={data.createdAt}
            completionTime={data.completionTime}
            closeModal={() => {
              setShowEditDeadLine(false);
            }}
            onClose={(e) => setShowEditDeadLine(false)}
            userList={userList}
            handleClose={(e) => setShowEditDeadLine(false)}
            previousAssigness={assigneeList}
            data={data}
            refreshData={refreshData}
            removeFromStore={removeFromStore}
          />
        )}
      </div>
      {showAddTemplate && (
        <RightFlotingContainer
          recommendedTemplateList={recommendedTemplateList}
          setRecommendedTemplateList={setRecommendedTemplateList}
          tempOfOrgList={tempOfOrgList}
          addedTemplateList={addedTemplateList}
          setAddedTemplateList={setAddedTemplateList}
          closeTemplate={() => {
            setShowAddTemplate(false);
            if (addedTemplateList && addedTemplateList.length > 0) {
              const temp = [...addedTemplateList];
              temp.forEach((template) => {
                template.taskDetailId = data.taskId;
              });
              DashboardServices.addTaskTemplate(
                loginUserDetail.userId,
                addedTemplateList,
                "OLD"
              ).then((response) => {
                const tempData = { ...data };
                if (tempData.templates) {
                  tempData.templates = [
                    ...tempData.templates,
                    ...addedTemplateList,
                  ];
                } else {
                  tempData.templates = [...addedTemplateList];
                }
                dispatch(setTaskModalData(tempData));
              });
            }
          }}
          mode={"old"}
        />
      )}
      {showFileModal && (
        <div className="RightFlotingContainerArea">
          <RightTaskFileUpload
            documents={data.documents}
            onClose={(e) => setShowFileUploadModal(false)}
            uploadFile={(data) => {
              handleChangeFile(data);
            }}
          />
        </div>
      )}

      {showForm &&
        (data.taskNature === "SUBMIT_RFP" ||
          data.taskNature === "PO_APPROVAL_REQUEST" ||
          data.taskNature === "GRN_ENTRY" ||
          data.taskNature === "PAYMENT_AGAINST_REIMBURSEMENT_REQUEST" ||
          data.taskNature === "PAYMENT_AGAINST_ADVANCE_REQUEST" ||
          data.taskNature === "PAYMENT_AGAINST_SIP_REQUEST" ||
          data.taskNature === "SEARCH_MANPOWER" ||
          data.taskNature === "SHORTLIST_RESUMES" ||
          data.taskNature === "INTERVIEW_FEEDBACK") && (
          <PurchaseFormRenderer
            data={data}
            taskId={data.taskId}
            taskNature={data.taskNature}
            formData={formData}
            handleClose={() => {
              setShowForm(false);
            }}
            handleSubmitPO={(date) => {
              if (date) {
                let reqDto = {
                  taskDetailId: data.taskId,
                  orgId: loginUserDetail.orgId,
                  documents: [],

                  taskCreator: "N",
                  taskForwordToUserIdAfterApproval: "",
                  additionalInfo: {
                    "Effective Date": date,
                  },
                  progress: data.progress,
                  concern: data.concern,
                  comments: data.comments,
                  submitButtonLabel: data.submitButtonLabel,
                  partialSubmitChecked: data.progress === 100 ? "N" : "Y",
                  previousAssigness: [],
                  currentAssigness: [],
                };

                // return;
                DashboardServices.updateProgressOfTask(
                  loginUserDetail.userId,
                  reqDto
                ).then((response) => {
                  handleTaskSubmission(response);
                });
              }
            }}
          />
        )}

      {showForm && data.taskNature === "PMS_SELF_EVALUATION" && (
        <SelfEvaluationPmsForm
          data={data}
          handleClose={() => setShowForm(false)}
          refreshData={refreshData}
          removeFromStore={removeFromStore}

        />
      )}

      {showForm && data.taskNature === "PMS_MANAGER_REVIEW" && (
        <PmsManagerReviewForm
          data={data}
          handleClose={() => setShowForm(false)}
          refreshData={refreshData}
          removeFromStore={removeFromStore}

        />
      )}

      {showForm && data.taskNature === "PMS_FINAL_REVIEW" && (
        <PmsFinalReviewForm
          data={data}
          handleClose={() => setShowForm(false)}
          refreshData={refreshData}
          removeFromStore={removeFromStore}

        />
      )}

      {showForm && data.taskNature === "PMS_SALARY_ADJUSTMENT" && (
        <PmsSalaryAdjustmentForm
          data={data}
          handleClose={() => setShowForm(false)}
          refreshData={refreshData}
          removeFromStore={removeFromStore}

        />
      )}

      {showForm && data.taskNature === "EXPENSE_APPROVAL_REQUEST" && (
        <IncomingFormPreview
          taskId={data.taskId}
          taskNature={data.taskNature}
          taskCreatedBy={data.assignByUserId}
          formData={formData}
          handleClose={() => {
            setShowForm(false);
          }}
          handleSubmitValue={() => {
            setShowForm();
            dispatch(clearSelectedTask());
            refreshData();
          }}
        />
      )}
    </>
  );
}
