import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import DashboardServices from "../../../../../services/DashboardServices";
import { useState } from "react";
import { GetLoginUserDetails } from "../../../../../utils/Utils";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { clearTaskModalData } from "../../../../../redux/reducers/taskTab";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const ReassignTaskModal = ({
  userList,
  assigneeList,
  data,
  refreshData,
  handleClose,
  removeFromStore,
}) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [reassignUser, setReassignUser] = useState([]);
  const loginUserDetail = GetLoginUserDetails();
  const [reassignReason, setReassignReason] = useState("");
  const [autocompleteInputValue, setAutocompleteInputValue] = useState("");

  // useEffect(() => {
  //   setReassignUser(
  //     assigneeList.map((user) => {
  //       const { userId, userName, profileUrl } = user;
  //       return { userId, userName, profileUrl };
  //     })
  //   );
  // }, []);

  const handleSubmitReassignTask = (e) => {
    let validForm = true;
    if (reassignReason.trim() === "") {
      toast.error(t("please_provide_reassign_reason"));
      validForm = false;
    }
    if (reassignUser.length === 0) {
      toast.error(t("please_select_atleast_one_user"));
      validForm = false;
    }
    if (!validForm) {
      return;
    }
    console.log("user id=", reassignUser);
    const reqdto = {
      taskDetailId: data.taskId,
      userIds: reassignUser.map((user) => user.userId),
      reassignReason: reassignReason,
    };

    DashboardServices.reassignTask(loginUserDetail.userId, reqdto).then(
      (response) => {
        if (response.data.returnValue === "1") {
          toast.success(t("task_successfully_reassigned"));
          removeFromStore();
          refreshData();
        } else {
          toast.error(t("failed_to_reassign_the_task_please_try_again"));
        }
      }
    );
  };

  const handleChangeAutoCompleteInputValue = (event, newInputValue) => {
    setAutocompleteInputValue(newInputValue);
  };

  const getFilteredData = () => {
    const taskCreator = data.assignByUserId;

    const filteredData = userList.filter((item) => {
      const isCreatorOrUser =
        item.userId !== loginUserDetail.userId && item.userId !== taskCreator;

      const isAlreadyAssigned = assigneeList.some((assignee) => {
        return assignee.userId === item.userId;
      });

      return isCreatorOrUser && !isAlreadyAssigned;
    });
    console.log("filtered data=", filteredData);
    return filteredData;
  };

  const filteredUsers = useMemo(getFilteredData, [
    data,
    userList,
    assigneeList,
    loginUserDetail.userId,
  ]);

  const computedList = useMemo(() => {
    let filtered = [];
    if (
      autocompleteInputValue.length > 0 &&
      autocompleteInputValue.trim() !== ""
    ) {
      filtered = filteredUsers.filter((option) =>
        option.userName
          .toLowerCase()
          .includes(autocompleteInputValue.toLowerCase())
      );
    }

    return filtered;
  }, [autocompleteInputValue, userList]);

  return (
    <>
      <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
        <div className="addMoreAgendaModel">
          <div className="modelTitle">{t("reassign_task")}</div>

          <div className="elementFormContainer">
            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  className="modelTextFild"
                  label={t("description")}
                  variant="outlined"
                  multiline
                  rows={4}
                  maxRows={7}
                  value={reassignReason}
                  onChange={(e) => setReassignReason(e.target.value)}
                />
              </FormControl>
            </div>

            <div className="formElement">
              <FormControl className="formControl">
                {/* <InputLabel id="adduser">Add User</InputLabel> */}
                <Autocomplete
                  multiple
                  noOptionsText={t("please_type_recipient_name")}
                  id="adduser-select"
                  className="modelformAutocompleteField"
                  variant="outlined"
                  value={reassignUser}
                  options={computedList}
                  inputValue={autocompleteInputValue}
                  getOptionLabel={(option) => option.userName}
                  getOptionSelected={(option) => {
                    console.log("option is ", option);
                    return option.userId;
                  }}
                  onChange={(e, newValue) => {
                    console.log("new value = ", newValue);
                    setReassignUser(newValue);
                  }}
                  onInputChange={handleChangeAutoCompleteInputValue}
                  renderOption={(props, option) => {
                    const dtl = option.userDtl ? `(${option.userDtl})` : "";
                    return (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {`${option.userName} ${dtl}`}
                      </Box>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      sx={{ color: "black" }}
                      {...params}
                      placeholder={t("search_user")}
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
              </FormControl>
            </div>
          </div>

          <div className="modActionBtnGrp">
            <Button
              className="dfultPrimaryBtn"
              onClick={(e) => {
                handleSubmitReassignTask();
              }}
            >
              {t("submit")}
            </Button>
            <Button className="dfultDarkSecondaryBtn" onClick={handleClose}>
              {t("cancel")}
            </Button>
          </div>
        </div>
      </Box>
    </>
  );
};

export default ReassignTaskModal;
