import { Box, Button, FormControl, TextField } from "@mui/material";
import React from "react";
import DashboardServices from "../../../../services/DashboardServices";
import { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { GetLoginUserDetails } from "../../../../utils/Utils";
import { useTranslation } from "react-i18next";
import { clearSelectedTask } from "../../../../redux/reducers/rightSideTaskPanelSlice";

const ConcernTaskModal = ({
  data,
  handleClose,
  refreshData,
  removeFromStore,
}) => {
  const loginUserDetail = GetLoginUserDetails();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [concernTitle, setConcernTitle] = useState("");

  return (
    <>
      <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
        <div className="addMoreAgendaModel">
          <div className="modelTitle">{t("add_concern_here")}</div>

          <div className="elementFormContainer">
            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  required
                  className="modelTextFild"
                  label={t("detail_the_concern")}
                  variant="outlined"
                  multiline
                  rows={4}
                  maxRows={7}
                  value={concernTitle}
                  onChange={(e) => {
                    setConcernTitle(e.target.value);
                  }}
                />
              </FormControl>
            </div>
          </div>

          <div className="modActionBtnGrp">
            <Button
              className="dfultPrimaryBtn"
              onClick={(e) => {
                if (concernTitle === "") {
                  toast.error(t("please_provide_concern_remark"));
                  return;
                }

                DashboardServices.concernTask(
                  data.taskId,
                  loginUserDetail.userId,
                  concernTitle
                ).then((response) => {
                  if (response.data && response.data.returnValue === "1") {
                    toast.success(t("task_concern_submitted_successfully"));
                    setConcernTitle("");
                    removeFromStore();
                    refreshData();
                    // dispatch(clearSelectedTask());
                  } else {
                    toast.error(
                      t("failed_to_record_your_concern_please_try_again_later")
                    );
                  }
                });
              }}
            >
              {t("submit")}
            </Button>
            <Button className="dfultDarkSecondaryBtn" onClick={handleClose}>
              {t("cancel")}
            </Button>
          </div>
        </div>
      </Box>
    </>
  );
};

export default ConcernTaskModal;
