import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Modal,
  Tooltip,
} from "@mui/material";
import React, { useRef } from "react";
import { XLg } from "react-bootstrap-icons";
import useFileUpload from "react-use-file-upload";
import { useState } from "react";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { convertFileToBase64_Split } from "../../../../../utils/FileUtils";
import {
  MAX_FILE_SIZE_TASK,
  VALID_FILE_EXTENSION_TASK,
} from "../../../../../constants/const_values";
import { Item } from "yjs";
import { useTranslation } from "react-i18next";

const PatientFileUpload = ({
  documents,
  setDocuments,
  onClose,
  previousMedicalRecords,
  setPreviousMedicalRecords,
  onSave,
}) => {
  const { t } = useTranslation();
  const [filesList, setFilesList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [duplicateFileName, setDuplicateFileName] = useState([]);
  const inputRef = useRef();
  useEffect(() => {
    setFilesList([]);
    console.log("documents are =", documents);
  }, []);

  const { handleDragDropEvent, setFiles } = useFileUpload();

  const validateAndProcessFiles = async (files) => {
    const tempFileList = [...filesList];

    const getFileDataObj = async (file) => {
      const dto = {
        fileName: file.name,
        fileData: await convertFileToBase64_Split(file),
        fileLink: "",
        id: 0,
      };
      return dto;
    };
    const values = await Promise.all(
      Array.from(files).map(
        (file) =>
          new Promise(async (resolve, reject) => {
            console.log("file name =", file);
            if (
              !VALID_FILE_EXTENSION_TASK.includes(
                file.name.split(".").pop().toLowerCase()
              )
            ) {
              // toast.warning(`Invalid File Type Of '${file.name}'`, {
              //   position: toast.POSITION.TOP_RIGHT,
              // });
              toast.warning(t("invalidFileType", { fileName: file.name }), {
                position: toast.POSITION.TOP_RIGHT,
              });
              resolve(null);
            } else if (file.size > 1024 * 1024 * MAX_FILE_SIZE_TASK) {
              // toast.warning(
              //   `'${file.name}' file need to be less then ${MAX_FILE_SIZE_TASK}MB`,
              //   {
              //     position: toast.POSITION.TOP_RIGHT,
              //   }
              // );
              toast.warning(
                t("fileSizeWarning", {
                  fileName: file.name,
                  maxFileSize: MAX_FILE_SIZE_TASK,
                }),
                {
                  position: toast.POSITION.TOP_RIGHT,
                }
              );
              resolve(null);
            } else if (
              filesList.find(({ fileName }) => fileName === file.name)
            ) {
              // toast.warning(`'${file.name}' Already Uploaded`, {
              //   position: toast.POSITION.TOP_RIGHT,
              // });
              toast.warning(t("fileAlreadyUploaded", { fileName: file.name }), {
                position: toast.POSITION.TOP_RIGHT,
              });
              const dto = getFileDataObj(file);
              resolve(null);
            } else if (
              documents.length > 0 &&
              documents.find((obj) => obj.fileName === file.name)
            ) {
              // toast.warning(`'${file.name}' Already Uploaded`, {
              //   position: toast.POSITION.TOP_RIGHT,
              // });
              toast.warning(t("fileAlreadyUploaded", { fileName: file.name }), {
                position: toast.POSITION.TOP_RIGHT,
              });
              const dto = getFileDataObj(file);
              resolve(null);
            } else {
              const dto = getFileDataObj(file);
              resolve(dto);
            }
          })
      )
    );
    console.log("values are ", values);
    const temp = values.filter((t) => t !== null);

    console.log("handelFileUpload", temp);
    setFilesList([...filesList, ...temp]);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleModal = () => {
    // const unmatchedObjects = filesList.filter(
    //   (obj) => !duplicateFileName.includes(obj.fileName)
    // );
    const tempDoc = [...documents, ...filesList];
    onSave && onSave(filesList);
    setDocuments(tempDoc);
    onClose();
    setOpenModal(false);
  };

  const removeFileFromDocument = (index) => {
    const tempDocuments = [...documents];
    tempDocuments.splice(index, 1);
    setDocuments(tempDocuments);
  };

  return (
    <>
      <div className="taskTempaltesPannel">
        <div className="taskTemplatesContainer">
          <div className="taskTempHeaderPanel">
            <div className="tskHedCon">{t("upload_file")}</div>
            <div className="taskActionBtnGrp">
              <div className="taskActBtn closeBtnD10">
                <IconButton aria-label="close" onClick={onClose}>
                  <Tooltip title={t("close")} arrow>
                    <XLg />
                  </Tooltip>
                </IconButton>
              </div>
            </div>
          </div>
          <div className="taskContain">
            <div className="taskContainDetail">
              <div
                onDragEnter={handleDragDropEvent}
                onDragOver={handleDragDropEvent}
                onDrop={(e) => {
                  handleDragDropEvent(e);
                  console.log("dropped file is =", e.dataTransfer.files);
                  const files = e.dataTransfer.files;
                  validateAndProcessFiles(files);
                  setFiles(e, "a");
                }}
                style={{
                  border: "1px solid #d1d1d1",
                  borderRadius: "12px",
                  padding: "10px",
                  color: "#ffffff",
                  width: "-webkit-fill-available",
                }}
              >
                <h1
                  style={{
                    margin: "0px",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  {t("upload_files")}
                </h1>

                {/* <p>Please use the form to your right to upload any file(s) of your choosing.</p> */}

                <div className="form-container">
                  {/* Display the files to be uploaded */}
                  <div //css={ListCSS}
                  >
                    <ul>
                      {filesList.map((name, index) => (
                        <li key={name.fileName}>
                          <span>{name.fileName}</span>

                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              const temp = [...filesList];
                              temp.splice(index, 1);
                              setFilesList(temp);
                            }}
                          >
                            &nbsp;&nbsp;
                            <font style={{ color: "red" }}>&#x2715;</font>
                          </span>
                        </li>
                      ))}
                      {/* {console.log("document in ul =", documents)} */}
                    </ul>
                  </div>

                  {/* Provide a drop zone and an alternative button inside it to upload files. */}
                  <div>
                    <p
                      style={{
                        display: "block",
                        position: "relative",
                        opacity: "0.6",
                        margin: "10px",
                        textAlign: "center",
                        fontStyle: "italic",
                      }}
                    >
                      {t("drag_and_drop_files_here")}
                    </p>

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => inputRef.current.click()}
                      sx={{ marginBottom: "10px" }}
                      style={{
                        backgroundColor: "#979797",
                        borderRadius: "20px",
                        width: "100%",
                      }}
                    >
                      {t("select_files")}
                    </Button>

                    {/* Hide the crappy looking default HTML input */}
                    <input
                      ref={inputRef}
                      type="file"
                      multiple
                      style={{ display: "none" }}
                      onChange={(e) => {
                        const files = e.target.files;
                        validateAndProcessFiles(files);
                        setFiles(e, "a");
                        inputRef.current.value = null;
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="uploadedFiles">
                <div className="hedUpFils">{t("selected_files_upload")}</div>
                <ul>
                  {documents?.map((document, index) => (
                    <li key={document.fileName}>
                      <span>{document.fileName}</span>

                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => removeFileFromDocument(index)}
                      >
                        &nbsp;&nbsp;
                        <font style={{ color: "red" }}>&#x2715;</font>
                      </span>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="uploadedFiles">
                <div className="hedUpFils">
                  {t("previously_uploaded_files")}
                </div>
                {previousMedicalRecords?.map((document, index) => (
                  <div key={document.fileName}>
                    <div className="formElement">
                      <FormControl className="formControl">
                        <FormGroup className="formRadioGroup">
                          <FormControlLabel
                            className="formRadioField"
                            label={document.fileName}
                            control={
                              <Checkbox
                                checked={document.isChecked || false}
                                onChange={(e) => {
                                  const temp = [...previousMedicalRecords];
                                  temp[index].isChecked = e.target.checked;
                                  setPreviousMedicalRecords(temp);
                                }}
                              />
                            }
                          />
                        </FormGroup>
                      </FormControl>
                    </div>

                    {/* <span
                        style={{ cursor: "pointer" }}
                        onClick={() => removeFileFromDocument(index)}
                      >
                        &nbsp;&nbsp;
                        <font style={{ color: "red" }}>&#x2715;</font>
                      </span> */}
                  </div>
                ))}
                {/* <ul>
                </ul> */}
              </div>

              <div className="uploadedFiles">
                <div>
                  {t("allowed_file_types")}:{" "}
                  <div>{VALID_FILE_EXTENSION_TASK.join(",") + "..."}</div>
                </div>
                {/* <div>Maximum file size {MAX_FILE_SIZE_TASK} MB</div> */}
                <div>
                  {t("maximum_file_size", { maxFileSize: MAX_FILE_SIZE_TASK })}
                </div>
              </div>
            </div>
          </div>
          <div className="taskFooterPanel">
            <div className="formBtnElement">
              <Button
                className="dfultPrimaryBtn"
                onClick={async (e) => {
                  // console.log("files = ", files);
                  // const base64data = await convertFileToBase64_Split(files[0]);
                  // console.log("base 64 data = ", base64data);

                  // const tempDocumentList = await Promise.all(
                  //   filesList.map(
                  //     (file) =>
                  //       new Promise(async (resolve, reject) => {
                  //         const fileDataLink = await convertFileToBase64_Split(
                  //           file
                  //         );
                  //         const obj = {
                  //           fileName: file.name,
                  //           fileDataLink: fileDataLink,
                  //         };
                  //         resolve(obj);
                  //       })
                  //   )
                  // );
                  // console.log("document list =", tempDocumentList);
                  const tempDoc = [...documents, ...filesList];

                  const previousFileDataList =
                    previousMedicalRecords?.filter(
                      (val) => val.isChecked === true
                    ) || [];

                  const checkDuplicate = previousFileDataList.some(
                    (previousFileData) => {
                      return filesList.some(
                        (file) => file.fileName === previousFileData.fileName
                      );
                    }
                  );

                  if (checkDuplicate) {
                    const duplicateFiles = filesList.filter((file) =>
                      previousFileDataList.some(
                        (prev) => file.fileName === prev.fileName
                      )
                    );

                    const duplicateFileNames = duplicateFiles.map(
                      (file) => file.fileName
                    );

                    setOpenModal(true);
                    setDuplicateFileName(duplicateFileNames);
                  } else {
                    onSave && onSave(filesList);
                    setDocuments(tempDoc);
                    onClose(e);
                  }
                  // const tempDoc = [...documents, ...filesList];
                  // console.log("tempDoc" , tempDoc);
                  // console.log("filesList" , filesList);
                  // console.log("documents" , documents);
                  // console.log("previousMedicalRecords" , previousMedicalRecords);
                  // const previousfileData = previousMedicalRecords.find(val => val.isChecked == true);
                  // console.log('previousfileData' , previousfileData)
                  // const checkDuplicate = previousfileData && filesList.some(val => val.fileName == previousfileData.fileName);
                  // console.log("checkDuplicate" , checkDuplicate);
                  // if(checkDuplicate){
                  // setOpenModal(true);
                  // setDuplicateFileName(previousfileData.fileName);
                  // }
                  // return
                  // onSave && onSave(filesList);
                  // setDocuments(tempDoc);
                  // onClose(e);
                }}
              >
                {t("save")}
              </Button>
              <Button className="dfultDarkSecondaryBtn" onClick={onClose}>
                {t("cancel")}
              </Button>
            </div>
          </div>

          {console.log("duplicateFileName", duplicateFileName)}

          {openModal && (
            <>
              <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
                <div className="addMoreAgendaModel">
                  {/* <div className="modelTitle">
                    {duplicateFileName} file is already there. Do you want to
                    continue ?
                  </div> */}
                  <div className="modelTitle">
                    {duplicateFileName.join(", ")} file(s) are already there. Do
                    you want to continue?
                  </div>

                  {/* <div className="modelTitle">
                  The following file(s) are already there. Do you want to continue?
                 <ul>
                {duplicateFileName.map((fileName, index) => (
                <li key={index}>{fileName}</li>
                ))}
                </ul>
                </div> */}

                  {/* <div className="elementFormContainer">
            
          <span className="titl">hello</span>
            
          </div> */}

                  <div className="modActionBtnGrp">
                    <Button className="dfultPrimaryBtn" onClick={handleModal}>
                      {t("yes")}
                    </Button>
                    <Button
                      className="dfultDarkSecondaryBtn"
                      onClick={handleCloseModal}
                    >
                      {t("no")}
                    </Button>
                  </div>
                </div>
              </Box>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default PatientFileUpload;
