import { Add, Edit, Search } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Modal,
  FormControlLabel,
  Switch,
  Tooltip,
  IconButton,
} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { ArrowClockwise, Trash } from "react-bootstrap-icons";
import {
  getCouponCodes,
  deactivateCouponCode,
  generateCouponCode,
} from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { GetLoginUserDetails } from "../../../../utils/Utils";
import Joyride from "react-joyride";
import { TourContext } from "../../../../common/TourProvider";

export default function DefineCoupon({
  handelOpenCreateCouponCode,
  refreshCouponCode,
}) {
  const userDetails = GetLoginUserDetails();
  const { t } = useTranslation();
  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  const [loading, setLoading] = useState(true);

  const [couponCodeData, setCouponCodeData] = useState([]);
  const [fiterCouponCodeData, setFilterCouponCodeData] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [couponStatus, setCouponStatus] = useState({});

  // useEffect(()=>{
  //   getCouponCodes()
  //   .then((response)=>{
  //     console.log('getCouponCodes', response.data)
  //     const updatedData = response.data.map(coupon => ({
  //       ...coupon,
  //       expireDate: coupon.expireDate.replace(/-/g, "/"),
  //       // coustomCode: coupon.coustomCode.replace(/.(?=.*?\.)/g, "X")
  //       // coustomCode: coupon.coustomCode.charAt(0) + "XXX" + coupon.coustomCode.slice(-1)
  //     }));
  //     setCouponCodeData(updatedData);
  //     setFilterCouponCodeData(updatedData);
  //   })
  // },[]);

  useEffect(() => {
    fetchCouponCode();
  }, [refreshCouponCode]);

  const fetchCouponCode = () => {
    getCouponCodes().then((response) => {
      const updatedData = response.data.map((coupon) => ({
        ...coupon,
        expireDate: coupon.expireDate.replace(/-/g, "/"),
      }));
      setCouponCodeData(updatedData);
      setFilterCouponCodeData(updatedData);

      // Set initial state of couponStatus to active for all custom codes
      const initialCouponStatus = {};
      updatedData.forEach((coupon) => {
        initialCouponStatus[coupon.coustomCode] = true;
      });
      setCouponStatus(initialCouponStatus);
      setLoading(false);
    });
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const filteredData = couponCodeData.filter(
      (coupon) =>
        coupon.purpose &&
        coupon.purpose.toLowerCase().includes(query.toLowerCase())
    );

    setFilterCouponCodeData(filteredData);
  };

  const handleChangeCupStatus = (event, coustomCode) => {
    const isChecked = event.target.checked;
    const matchedCoupon = fiterCouponCodeData.find(
      (coupon) => coupon.coustomCode === coustomCode
    );

    if (matchedCoupon) {
      const newCouponStatus = { ...couponStatus };
      newCouponStatus[coustomCode] =
        matchedCoupon.activeFlag === "Y" ? isChecked : false;
      setCouponStatus(newCouponStatus);

      console.log("matchedCoupon", matchedCoupon);

      if (!isChecked) {
        // const reqDto = {
        //   purpose: matchedCoupon.purpose,
        //   discountPc: matchedCoupon.discountPc,
        //   noOfTimesUseable: matchedCoupon.noOfTimesUseable,
        //   expireDate: matchedCoupon.expireDate,
        //   couponReceiverName: matchedCoupon.couponReceiverName,
        //   couponReceiverMailId: matchedCoupon.couponReceiverMailId,
        //   transType: "ORGANIZATION_ONBOARD",
        //   coustomCode: matchedCoupon.coustomCode,
        //   activeFlag: "N"
        // };

        // Call deactivateCouponCode to deactivate the coupon
        deactivateCouponCode(userDetails.userId, matchedCoupon.coustomCode)
          .then((response) => {
            console.log(response.data);
            if (response.data.returnValue === "1") {
              toast.success(t("coupon_code_deactivated_successfully"), {
                position: toast.POSITION.TOP_RIGHT,
              });
              fetchCouponCode();
            }
          })
          .catch((error) => {
            console.error("Error deactivating coupon:", error);
          });
      }
    } else {
      console.log(
        "No coupon found with the provided coustomCode:",
        coustomCode
      );
    }
  };

  //   const handleChangeCupStatus = (event , coustomCode) => {
  //     const isChecked = event.target.checked
  //     console.log('handleChangeCupStatus',event.target.checked , coustomCode);
  //     // setCouponStatus(event.target.checked);
  //     setCouponStatus((prevStatus) => ({
  //       ...prevStatus,
  //       [coustomCode]: isChecked,
  //     }));

  //     if (!isChecked) {
  //       const value = { coustomCode };
  //       const reqDto = value.coustomCode.toString();
  //       console.log('reqDto' , reqDto);

  // } else {
  //     console.log("No coupon found with the provided reqDto:", reqDto);
  // }

  //       // return;

  //       // deactivateCouponCode(userDetails.userId, reqDto)
  //       //   .then((response) => {
  //       //     console.log(response.data);
  //       //     if (response.data.returnValue === "1") {
  //       //       toast.success(response.data.message, {
  //       //         position: toast.POSITION.TOP_RIGHT,
  //       //       });
  //       //       fetchCouponCode();
  //       //     }
  //       //   })
  //       //   .catch((error) => {
  //       //     console.error("Error deactivating coupon:", error);
  //       //     // Handle error, show error toast, etc.
  //       //   });
  //     }
  //   };

  useEffect(()=>{
    setBookSteps([
    {
      target: '.orgCouponStepOne',
      content: 'Use this field to search for coupon codes based on their purpose.',
      disableBeacon: true,
    },
    {
      target: '.orgCouponStepTwo',
      content: 'Click here to add a new coupon code.',
      disableBeacon: true,
    },
    {
      target: '.orgCouponStepThree',
      content: 'Here are the details of your coupon codes, including purpose, discount, expiration, and status.',
      disableBeacon: true,
    },
    {
      target: '.taskListTable tbody tr:first-child',
      content: 'Each row shows the coupon details and provides options to activate/deactivate the coupon.',
      disableBeacon: true,
    },
    {
      target: '.orgCouponStepFive',
      content: 'This switch allows you to activate or deactivate the coupon code.',
      disableBeacon: true,
    }
  ]
  )
  },[])

  console.log("couponStatus", couponStatus);

  return (
    <>
     <Joyride
        steps={booksteps}
        run={run["viewCouponAD"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        styles={{
          buttonClose: {
            display: "none",
          },
        }}
      />
      <div className="fullContainArea">
        <div className="cuHeaderGrp">
          {/* <div className="cuhederTxt">Departmment List</div> */}
          <div className="searchTasklist orgCouponStepOne">
            <input
              type="text"
              class="serchTaskInput "
              placeholder={t("search_coupon")}
              value={searchQuery}
              onChange={handleSearch}
            />
            <IconButton className="seacrhIcon">
              <Search />
            </IconButton>
          </div>

          <div className="addUnitsBtn">
            {/* <Button
              startIcon={<AccountTree />}
              className="dfultDarkSecondaryBtn"
              // onClick={() => handleOpenUnitTreeView()}
            >
              Departmment Tree
            </Button> */}
            <Button
              startIcon={<Add />}
              className="dfultPrimaryBtn orgCouponStepTwo"
              onClick={() => {handelOpenCreateCouponCode();
                if (run["viewCouponAD"] === true) {
                  setRun({
                    addCouponAD: true,
                  });
                }
              }}
            >
              {t("add_coupon")}
            </Button>
          </div>
        </div>

        <div className="cuContainArea">
          {loading ? (
            <div className="center-progress" style={{ height: "65vh" }}>
              <CircularProgress sx={{ marginTop: "180px" }} />
            </div>
          ) : couponCodeData.length === 0 ? (
            <div class="noDataCard ">
              <span>{t("oops")}</span>
              {t("no_coupon_added_yet")}
              <br />
              <span className="sallMsg">
                {t("click_add_coupon_button_to_create_new_coupon")}
              </span>
            </div>
          ) : (
            <div className="TableContainer">
              <table className="taskListTable">
                <thead className="taskListtableHeader orgCouponStepThree">
                  <tr>
                    <th>{t("sl_no")}</th>
                    <th>{t("purpose")}</th>
                    <th>{t("discount")} %</th>
                    <th>{t("exp_date")}</th>
                    <th>{t("no_of_use")}</th>
                    <th>{t("name")}</th>
                    <th>{t("assigned_to")}</th>
                    <th className="width260">{t("code")}</th>
                    <th className="width100">{t("status")}</th>
                  </tr>
                </thead>
                <tbody className="scrolableTableBody">
                  {fiterCouponCodeData.map((coupon, index) => (
                    <tr key={index}>
                      <td>
                        <div className="tskLstTaskNM">{index + 1}.</div>
                      </td>
                      <td>
                        <Tooltip arrow title={coupon.purpose}>
                          <div className="tskLstTaskDescription">
                            {coupon.purpose}
                          </div>
                        </Tooltip>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">{coupon.discountPc}%</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">{coupon.expireDate}</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          {coupon.noOfTimesUseable}({t("time")})
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          {coupon.couponReceiverName}
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          {coupon.couponReceiverMailId}
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM couponCode">
                          {/* <span>{coupon.coustomCode}</span> */}
                          <span>
                            {coupon.coustomCode.charAt(0) +
                              "XXX" +
                              coupon.coustomCode.slice(-1)}
                          </span>
                        </div>
                      </td>
                      {/* <td>
                      <div className="tblActionBtnGrp">
                        <FormControlLabel
                          className="couponStatusSwitch"
                          control={
                            <Switch
                              // checked={couponStatus}
                              checked={couponStatus[coupon.coustomCode] || false}
                              onChange={(event)=> handleChangeCupStatus(event , coupon.coustomCode)}
                              color="primary"
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                          // label={couponStatus ? "Active" : "Deactive"}
                          label={couponStatus[coupon.coustomCode] ? "Active" : "Deactive"}
                        />
                      </div>
                    </td> */}
                      <td>
                        <div className="tblActionBtnGrp">
                          <FormControlLabel
                            className="couponStatusSwitch orgCouponStepFive"
                            control={
                              <Switch
                                checked={coupon.activeFlag === "Y"} // Check if activeFlag is "Y"
                                onChange={(event) =>
                                  handleChangeCupStatus(
                                    event,
                                    coupon.coustomCode
                                  )
                                }
                                color="primary"
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label={
                              coupon.activeFlag === "Y"
                                ? t("active")
                                : t("deactive")
                            } // Show Active/Deactive based on activeFlag
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
                {/* <tbody className="scrolableTableBody">
                <tr>
                  <td>
                    <div className="tskLstTaskNM">1.</div>
                  </td>
                  <td>
                    <Tooltip
                      arrow
                      title="Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s,"
                    >
                      <div className="tskLstTaskDescription">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                      </div>
                    </Tooltip>
                  </td>
                  <td>
                    <div className="tskLstTaskNM">100%</div>
                  </td>
                  <td>
                    <div className="tskLstTaskNM">11/11/2024</div>
                  </td>
                  <td>
                    <div className="tskLstTaskNM">1 (Time)</div>
                  </td>
                  <td>
                    <div className="tskLstTaskNM">
                      sanketsantra@miniontek.com
                    </div>
                  </td>
                  <td>
                    <div className="tskLstTaskNM couponCode">
                      <span>ZOXXXXXXXX0</span>
                      <Tooltip title="Resend Code" arrow>
                        <div className="tskLstTaskNM">
                          <Button
                            startIcon={<ArrowClockwise />}
                            className="addNRwBtn"
                          >
                            Send Code
                          </Button>
                        </div>
                      </Tooltip>
                    </div>
                  </td>

                  <td>
                    <div className="tblActionBtnGrp">
                      <FormControlLabel
                        className="couponStatusSwitch"
                        control={
                          <Switch
                            couponStatus={couponStatus}
                            onChange={handleChangeCupStatus}
                            color="primary"
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label={couponStatus ? "Active" : "Deactive"}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="tskLstTaskNM">1.</div>
                  </td>
                  <td>
                    <Tooltip
                      arrow
                      title="Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s,"
                    >
                      <div className="tskLstTaskDescription">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                      </div>
                    </Tooltip>
                  </td>
                  <td>
                    <div className="tskLstTaskNM">100%</div>
                  </td>
                  <td>
                    <div className="tskLstTaskNM">09/09/2024</div>
                  </td>
                  <td>
                    <div className="tskLstTaskNM">1 (Time)</div>
                  </td>
                  <td>
                    <div className="tskLstTaskNM">
                      uiux-designer@miniontek.com
                    </div>
                  </td>
                  <td>
                    <div className="tskLstTaskNM couponCode">
                      <span>HAXXXXXXXX0</span>
                      <Tooltip title="Resend Code" arrow>
                        <div className="tskLstTaskNM">
                          <Button
                            startIcon={<ArrowClockwise />}
                            className="addNRwBtn"
                          >
                            Send Code
                          </Button>
                        </div>
                      </Tooltip>
                    </div>
                  </td>

                  <td>
                    <div className="tblActionBtnGrp">
                      <FormControlLabel
                        className="couponStatusSwitch"
                        control={
                          <Switch
                            couponStatus={couponStatus}
                            onChange={handleChangeCupStatus}
                            color="primary"
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label={couponStatus ? "Active" : "Deactive"}
                      />
                    </div>
                  </td>
                </tr>
              </tbody> */}
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
