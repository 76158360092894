import React, { createContext, useState } from "react";

export const TourContext = createContext();

export const TourProvider = ({ children }) => {
  const [steps, setSteps] = useState([]);
  const [run, setRun] = useState({});

  const addStep = (newStep) => {
    setSteps((prev) => [...prev, newStep]);
  };

  return (
    <TourContext.Provider value={{ steps, run, setRun, addStep }}>
      {children}
    </TourContext.Provider>
  );
};
