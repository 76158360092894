import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import { NumericFormatCustom } from "./NumericFormatCustom";
import { ArrowDown, ArrowUp, FileArrowUp, Trash } from "react-bootstrap-icons";
import DateUtils from "../../../../../utils/DateUtils";
import { Add, Edit } from "@mui/icons-material";
import dayjs from "dayjs";
import { convertFileToBase64_Split } from "../../../../../utils/FileUtils";
import { useTranslation } from "react-i18next";

export const ReimbursementIncomingForm = ({
  state,
  dispatch,
  budgetHeadList,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const { t } = useTranslation();

  const [allTimeFormData, setAllTimeFormData] = useState({
    srnGrnNo: "",
    reason: "",
    invoceRcptNo: "",
    invoiceRcptDt: new Date(),
    fileName: "",
    fileDataLink: "",
    taxAmount: 0,
    estimatedValue: 0,
    totalAmount: 0,
    description: "",
    budgetHeadId: 0,
    budgetHeadName: "",
    expenseDtlId: 0,
    editMode: true,
  });

  const getFileDataObj = async (file) => {
    const dto = {
      fileName: file.name,
      fileDataLink: await convertFileToBase64_Split(file),
    };
    return dto;
  };

  const handleSaveAllTimeData = (data) => {
    let isValid = true;
    console.log("reimbursement form data =", data);
    return isValid;
  };

  const initializeAllTimeForeData = () => {
    setAllTimeFormData({
      srnGrnNo: "",
      reason: "",
      invoceRcptNo: "",
      invoiceRcptDt: new Date(),
      fileName: "",
      fileDataLink: "",
      taxAmount: 0,
      estimatedValue: 0,
      totalAmount: 0,
      description: "",
      budgetHeadId: 0,
      budgetHeadName: "",
      expenseDtlId: 0,
      editMode: true,
    });
  };

  return (
    <>
      <div className="indentFormtable">
        <div className="indtableResponsive">
          <table>
            <thead>
              <tr>
                <th>{t("description")}</th>
                <th>{t("reason")}</th>
                <th>{t("budget_head")}</th>
                <th>{t("invoiceReceiptNo")}</th>
                <th>{t("invoice_date")}</th>
                {(state.formDataCopy.expenseType === "goods" ||
                  state.formDataCopy.expenseType === "services") && (
                  <th>
                    {state.formDataCopy.expenseType === "goods"
                      ? t("grn")
                      : t("srn")}
                  </th>
                )}
                <th>{t("taxAmount")}</th>
                <th>{t("totalAmount")}</th>
                <th>{t("documents")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {state.formDataCopy.serviceGoodsReim &&
                state.formDataCopy.serviceGoodsReim.map((item, index) => {
                  // if (item.editMode) {
                  //   return (
                  //     <>
                  //       <tr>
                  //         <td>
                  //           <div className="formElement">
                  //             <FormControl className="formControl">
                  //               <TextField
                  //                 className="formTextFieldArea"
                  //                 size="small"
                  //                 step={1.0}
                  //                 inputProps={{ min: 0 }}
                  //                 value={item.itemDescriptions}
                  //                 onChange={(e) => {
                  //                   // const element = e.target.value;
                  //                   const getItem = [...reimbursementList];
                  //                   getItem[index].itemDescriptions =
                  //                     e.target.value;
                  //                   setReimbursementList(getItem);
                  //                 }}
                  //               />
                  //             </FormControl>
                  //           </div>
                  //         </td>

                  //         <td>
                  //           <div className="formElement">
                  //             <FormControl className="formControl">
                  //               <InputLabel id="selectPriority">
                  //                 Reason
                  //               </InputLabel>

                  //               <Select
                  //                 className="formInputField"
                  //                 variant="outlined"
                  //                 labelId="selectPriority"
                  //                 id="priority-select"
                  //                 value={reimbursementList[index].reason}
                  //                 onChange={(e) => {
                  //                   const getItem = [...reimbursementList];
                  //                   getItem[index].reason = e.target.value;
                  //                   setReimbursementList(getItem);
                  //                 }}
                  //               >
                  //                 <MenuItem value={""} key={""}>
                  //                   {"--Select--"}
                  //                 </MenuItem>
                  //                 <MenuItem
                  //                   value={"smallItems"}
                  //                   key={"smallItems"}
                  //                 >
                  //                   {"Small Items"}
                  //                 </MenuItem>

                  //                 <MenuItem
                  //                   value={"emergency"}
                  //                   key={"emergency"}
                  //                 >
                  //                   {"Emergency"}
                  //                 </MenuItem>

                  //                 <MenuItem value={"online"} key={"online"}>
                  //                   {"Online"}
                  //                 </MenuItem>
                  //                 <MenuItem
                  //                   value={"rateContract"}
                  //                   key={"rateContract"}
                  //                 >
                  //                   {"Rate Contract"}
                  //                 </MenuItem>
                  //                 <MenuItem value={"other"} key={"other"}>
                  //                   {"Other"}
                  //                 </MenuItem>
                  //               </Select>
                  //             </FormControl>
                  //           </div>
                  //         </td>

                  //         <td>
                  //           <div className="formElement">
                  //             <FormControl className="formControl">
                  //               <InputLabel id="selectLedgerHead">
                  //                 Budget Head
                  //               </InputLabel>
                  //               <Select
                  //                 className="formInputField"
                  //                 variant="outlined"
                  //                 labelId="selectLedgerHead"
                  //                 id="select-LedgerHead"
                  //                 label="Budget Head"
                  //                 value={reimbursementList[index].budgetHead}
                  //                 onChange={(e) => {
                  //                   const temp = [...reimbursementList];
                  //                   temp[index].budgetHead = e.target.value;
                  //                   setReimbursementList(temp);
                  //                 }}
                  //               >
                  //                 {budgetHeadList.length > 0 &&
                  //                   budgetHeadList.map((itemName, index) => (
                  //                     <MenuItem
                  //                       value={itemName}
                  //                       key={itemName.head + "_" + index}
                  //                     >
                  //                       {itemName.head}
                  //                     </MenuItem>
                  //                   ))}
                  //               </Select>
                  //             </FormControl>
                  //           </div>
                  //         </td>
                  //         <td>
                  //           <div className="formElement">
                  //             <FormControl className="formControl">
                  //               <TextField
                  //                 autoComplete="off"
                  //                 label="Invoice"
                  //                 variant="outlined"
                  //                 className="formTextFieldArea"
                  //                 value={reimbursementList[index].invoiceRcptNo}
                  //                 onChange={(e) => {
                  //                   // const element = e.target.value;
                  //                   const getItem = [...reimbursementList];
                  //                   getItem[index].invoiceRcptNo =
                  //                     e.target.value;
                  //                   setReimbursementList(getItem);
                  //                 }}
                  //               />
                  //             </FormControl>
                  //           </div>
                  //         </td>
                  //         <td>
                  //           <div className="formElement">
                  //             <FormControl className="formControl">
                  //               <InputLabel
                  //                 id="setCompletionDate"
                  //                 className="setCompletionDate"
                  //               >
                  //                 Invoice Date
                  //               </InputLabel>

                  //               <ReactDatePicker
                  //                 showIcon
                  //                 labelId="setCompletionDate"
                  //                 className="formDatePicker"
                  //                 maxDate={new Date()}
                  //                 dateFormat="dd/MM/yyyy"
                  //                 selected={
                  //                   reimbursementList[index].completionDate
                  //                 }
                  //                 onChange={(date) => {
                  //                   const getItem = [...reimbursementList];
                  //                   getItem[index].completionDate = date;
                  //                   setReimbursementList(getItem);
                  //                 }}
                  //               />
                  //             </FormControl>
                  //           </div>
                  //         </td>

                  //         {(state.formDataCopy.expenseType === "goods" ||
                  //           state.formDataCopy.expenseType === "services") && (
                  //           <td>
                  //             <div className="formElement">
                  //               <FormControl className="formControl">
                  //                 <TextField
                  //                   label=""
                  //                   name="numberformat"
                  //                   id="IndentQty"
                  //                   variant="outlined"
                  //                   className="formTextFieldArea"
                  //                   value={reimbursementList[index].grn}
                  //                   onChange={(e) => {
                  //                     const temp = [...reimbursementList];
                  //                     temp[index].grn = e.target.value;
                  //                     setReimbursementList(temp);
                  //                   }}
                  //                 />
                  //               </FormControl>
                  //             </div>
                  //           </td>
                  //         )}
                  //         <td>
                  //           <div className="formElement">
                  //             <FormControl className="formControl">
                  //               <TextField
                  //                 label=""
                  //                 name="numberformat"
                  //                 id="IndentQty"
                  //                 InputProps={{
                  //                   inputComponent: NumericFormatCustom,
                  //                 }}
                  //                 variant="outlined"
                  //                 className="formTextFieldArea"
                  //                 //   {...register("remTax")}
                  //                 value={reimbursementList[index].tax}
                  //                 onChange={(e) => {
                  //                   const tempReimbList = [
                  //                     ...reimbursementList,
                  //                   ];
                  //                   const temp = parseInt(e.target.value);
                  //                   if (temp < 100 && temp >= 0) {
                  //                     tempReimbList[index].tax = temp;
                  //                     setReimbursementList(tempReimbList);
                  //                   }
                  //                 }}
                  //               />
                  //             </FormControl>
                  //           </div>
                  //         </td>
                  //         <td>
                  //           <div className="formElement">
                  //             <FormControl className="formControl">
                  //               <TextField
                  //                 label=""
                  //                 name="numberformat"
                  //                 id="IndentQty"
                  //                 InputProps={{
                  //                   inputComponent: NumericFormatCustom,
                  //                 }}
                  //                 variant="outlined"
                  //                 className="formTextFieldArea"
                  //                 //   {...register("remTax")}
                  //                 value={reimbursementList[index].total}
                  //                 onChange={(e) => {
                  //                   const temp = parseInt(e.target.value);
                  //                   const tempReimbList = [
                  //                     ...reimbursementList,
                  //                   ];
                  //                   tempReimbList[index].total = temp;
                  //                   setReimbursementList(tempReimbList);
                  //                 }}
                  //               />
                  //             </FormControl>
                  //           </div>
                  //         </td>
                  //         <td>
                  //           <IconButton
                  //             onClick={(e) => {
                  //               console.log("icon button clicked");
                  //               const inputElement = document.querySelector(
                  //                 `#file_input_${index}`
                  //               );
                  //               if (inputElement) {
                  //                 inputElement.click();
                  //               }
                  //             }}
                  //           >
                  //             <FileArrowUp />
                  //           </IconButton>
                  //           <input
                  //             style={{ display: "none" }}
                  //             type="file"
                  //             id={`file_input_${index}`}
                  //             onChange={async (e) => {
                  //               console.log("files are", e.target.files);
                  //               const files = e.target.files;
                  //               const data = await getFileDataObj(files[0]);
                  //               const tempReimbList = [...reimbursementList];
                  //               tempReimbList[index].document = data;
                  //               setReimbursementList(tempReimbList);
                  //             }}
                  //           />
                  //         </td>

                  //         <td>
                  //           <>
                  //             <Button
                  //               onClick={() => {
                  //                 console.log("hello");
                  //                 const temp = [...reimbursementList];
                  //                 temp[index].editMode = false;
                  //                 setReimbursementList(temp);
                  //               }}
                  //             >
                  //               SAVE
                  //             </Button>

                  //             <Button
                  //               onClick={() => {
                  //                 if (reimbursementList.length === 1) {
                  //                   setReimbursementList([]);
                  //                   return;
                  //                 }
                  //                 const temp = [...reimbursementList];
                  //                 temp.splice(index, 1);
                  //                 if (temp.length === 0) {
                  //                   temp.push({
                  //                     updating: true,
                  //                     amount: 0,
                  //                     invoiceRcptDt: dayjs(new Date()),
                  //                     reimbursementItemId: 0,
                  //                     itemDescriptions: "",
                  //                     supportingDocumentsFileName: null,
                  //                     supportingDocumentsFileData: null,
                  //                     supportingDocumentsFileLink: null,
                  //                     invoiceRcptNo: "",
                  //                   });
                  //                 }
                  //                 setReimbursementList(temp);
                  //                 setTax(0);
                  //                 setTotal(0);
                  //               }}
                  //             >
                  //               <Trash />
                  //             </Button>
                  //           </>
                  //         </td>
                  //       </tr>
                  //     </>
                  //   );
                  // } else {
                  //   return (
                  //   );
                  // }
                  return (
                    <>
                      <tr>
                        <td>
                          <div className="textOnly">{item.description}</div>
                        </td>
                        <td>
                          <div className="textOnly">{item.reason}</div>
                        </td>
                        <td>
                          {item.editMode && (
                            <td>
                              <div className="formElement">
                                <FormControl className="formControl">
                                  <InputLabel id="selectLedgerHead">
                                    {t("budget_head")}
                                  </InputLabel>
                                  <Select
                                    className="formInputField"
                                    variant="outlined"
                                    labelId="selectLedgerHead"
                                    id="select-LedgerHead"
                                    label={t("budget_head")}
                                    value={item.budgetHeadId}
                                    onChange={(e) => {
                                      const temp = [
                                        ...state.formDataCopy.serviceGoodsReim,
                                      ];
                                      const value = e.target.value;
                                      temp[index].budgetHeadId = value.id;
                                      temp[index].budgetHeadName = value.head;
                                      dispatch({
                                        type: "updateReimbursementForm",
                                        payload: temp,
                                      });
                                    }}
                                  >
                                    {budgetHeadList.length > 0 &&
                                      budgetHeadList.map((itemName, index) => (
                                        <MenuItem
                                          value={itemName}
                                          key={itemName.head + "_" + index}
                                        >
                                          {itemName.head}
                                        </MenuItem>
                                      ))}
                                  </Select>
                                </FormControl>
                              </div>
                            </td>
                          )}
                          {!item.editMode && (
                            <div className="textOnly">
                              {item.budgetHeadName}
                            </div>
                          )}
                        </td>
                        <td>
                          <div className="textOnly">{item.invoceRcptNo}</div>
                        </td>
                        <td>
                          <div className="textOnly">{item.invoceRcptDt}</div>
                        </td>
                        <td>
                          <div className="textOnly">{item.srnGrnNo}</div>
                        </td>
                        <td>
                          <div className="textOnly">{item.taxAmount}</div>
                        </td>
                        <td>
                          <div className="textOnly">{item.totalAmount}</div>
                        </td>
                        <td>
                          <div className="textOnly">{item.fileName}</div>
                        </td>
                        <td>
                          {console.log("edit mode is=", item)}
                          {!item.editMode && (
                            <Tooltip title={"Edit Item"} placement="left">
                              <Button
                                className={
                                  !item.editMode &&
                                  isEditing &&
                                  `deactivatedEditButton`
                                }
                                disabled={!item.editMode && isEditing}
                                onClick={() => {
                                  setIsEditing(true);

                                  dispatch({
                                    type: "editReimbursementIndex",
                                    payload: index,
                                  });
                                }}
                              >
                                <Edit />
                              </Button>
                            </Tooltip>
                          )}

                          {item.editMode && (
                            <Button
                              onClick={(e) => {
                                setIsEditing(false);
                                dispatch({
                                  type: "editReimbursementIndex",
                                  payload: index,
                                });
                              }}
                            >
                              {t("save")}
                            </Button>
                          )}

                          <IconButton
                            onClick={() => {
                              setIsEditing(false);
                              dispatch({
                                type: "removeReimbursementByIndex",
                                payload: index,
                              });
                            }}
                          >
                            <Trash />
                          </IconButton>
                        </td>
                      </tr>
                    </>
                  );
                })}

              {/* <tr>
                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        className="formTextFieldArea"
                        size="small"
                        step={1.0}
                        inputProps={{ min: 0 }}
                        value={allTimeFormData.itemDescriptions}
                        onChange={(e) => {
                          // const element = e.target.value;
                          const getItem = { ...allTimeFormData };
                          getItem.description = e.target.value;
                          setAllTimeFormData(getItem);
                        }}
                      />
                    </FormControl>
                  </div>
                </td>

                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel id="selectPriority">Reason</InputLabel>

                      <Select
                        className="formInputField"
                        variant="outlined"
                        labelId="selectPriority"
                        id="priority-select"
                        value={allTimeFormData.reason}
                        onChange={(e) => {
                          const getItem = { ...allTimeFormData };
                          getItem.reason = e.target.value;
                          setAllTimeFormData(getItem);
                        }}
                      >
                        <MenuItem value={""} key={""}>
                          {"--Select--"}
                        </MenuItem>
                        <MenuItem value={"smallItems"} key={"smallItems"}>
                          {"Small Items"}
                        </MenuItem>

                        <MenuItem value={"emergency"} key={"emergency"}>
                          {"Emergency"}
                        </MenuItem>

                        <MenuItem value={"online"} key={"online"}>
                          {"Online"}
                        </MenuItem>
                        <MenuItem value={"rateContract"} key={"rateContract"}>
                          {"Rate Contract"}
                        </MenuItem>
                        <MenuItem value={"other"} key={"other"}>
                          {"Other"}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </td>

                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel id="selectLedgerHead">Budget Head</InputLabel>
                      <Select
                        className="formInputField"
                        variant="outlined"
                        labelId="selectLedgerHead"
                        id="select-LedgerHead"
                        label="Budget Head"
                        value={allTimeFormData.budgetHeadId}
                        onChange={(e) => {
                          const temp = { ...allTimeFormData };
                          const bHead = budgetHeadList.find(
                            (head) => head.id === e.target.value
                          );
                          temp.budgetHeadId = bHead.id;
                          temp.budgetHeadName = bHead.head;
                          setAllTimeFormData(temp);
                        }}
                      >
                        {budgetHeadList.length > 0 &&
                          budgetHeadList.map((itemName, index) => (
                            <MenuItem
                              value={itemName.id}
                              key={itemName.head + "_" + index}
                            >
                              {itemName.head}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                </td>

                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        autoComplete="off"
                        label="Invoice"
                        variant="outlined"
                        className="formTextFieldArea"
                        value={allTimeFormData.invoceRcptNo}
                        onChange={(e) => {
                          // const element = e.target.value;
                          const getItem = { ...allTimeFormData };
                          getItem.invoceRcptNo = e.target.value;
                          setAllTimeFormData(getItem);
                        }}
                      />
                    </FormControl>
                  </div>
                </td>
                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel
                        id="setCompletionDate"
                        className="setCompletionDate"
                      >
                        Invoice Date
                      </InputLabel>

                      <ReactDatePicker
                        showIcon
                        labelId="setCompletionDate"
                        className="formDatePicker"
                        maxDate={new Date()}
                        dateFormat="dd/MM/yyyy"
                        selected={allTimeFormData.invoiceRcptDt}
                        onChange={(date) => {
                          const getItem = { ...allTimeFormData };
                          getItem.invoceRcptDt = date;
                          setAllTimeFormData(getItem);
                        }}
                      />
                    </FormControl>
                  </div>
                </td>

                {(state.formDataCopy.expenseType === "goods" ||
                  state.formDataCopy.expenseType === "services") && (
                  <td>
                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          label=""
                          name="numberformat"
                          id="IndentQty"
                          variant="outlined"
                          className="formTextFieldArea"
                          value={allTimeFormData.srnGrnNo}
                          onChange={(e) => {
                            const temp = { ...allTimeFormData };
                            temp.srnGrnNo = e.target.value;
                            setAllTimeFormData(temp);
                          }}
                        />
                      </FormControl>
                    </div>
                  </td>
                )}
                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        label=""
                        name="numberformat"
                        id="IndentQty"
                        InputProps={{
                          inputComponent: NumericFormatCustom,
                        }}
                        variant="outlined"
                        className="formTextFieldArea"
                        //   {...register("remTax")}
                        value={allTimeFormData.taxAmount}
                        onChange={(e) => {
                          const tempReimbList = { ...allTimeFormData };
                          const temp = parseInt(e.target.value);
                          if (temp < 100 && temp >= 0) {
                            tempReimbList.taxAmount = temp;
                            setAllTimeFormData(tempReimbList);
                          }
                        }}
                      />
                    </FormControl>
                  </div>
                </td>
                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        label=""
                        name="numberformat"
                        id="IndentQty"
                        InputProps={{
                          inputComponent: NumericFormatCustom,
                        }}
                        variant="outlined"
                        className="formTextFieldArea"
                        //   {...register("remTax")}
                        value={allTimeFormData.total}
                        onChange={(e) => {
                          const temp = parseInt(e.target.value);
                          const tempReimbList = { ...allTimeFormData };
                          tempReimbList.total = temp;
                          setAllTimeFormData(tempReimbList);
                        }}
                      />
                    </FormControl>
                  </div>
                </td>
                <td>
                  <IconButton
                    onClick={(e) => {
                      console.log("icon button clicked");
                      const inputElement =
                        document.querySelector(`#file_input`);
                      if (inputElement) {
                        inputElement.click();
                      }
                    }}
                  >
                    <FileArrowUp />
                  </IconButton>
                  <input
                    style={{ display: "none" }}
                    type="file"
                    id={`file_input`}
                    onChange={async (e) => {
                      console.log("files are", e.target.files);
                      const files = e.target.files;
                      const data = await getFileDataObj(files[0]);
                      const tempReimbList = { ...allTimeFormData };
                      tempReimbList.document = data;
                      setAllTimeFormData(tempReimbList);
                    }}
                  />
                </td>
                <td>
                  <div className="iconBtnGroup">
                    <Button
                      className="saveBtn"
                      onClick={(e) => {
                        const temp = { ...allTimeFormData, editMode: false };
                        const tempBool = handleSaveAllTimeData(temp);

                        // initializeAllTimeForeData();
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </td>
              </tr> */}
            </tbody>
            <tfoot>
              {/* <tr>
            <td colSpan="8">
              <div className="textOnly tvText">Total Value</div>
            </td>
            <td>
              <div className="textOnly fw600">0</div>
            </td>
            <td colSpan="1"></td>
          </tr> */}
            </tfoot>
          </table>
        </div>
      </div>
    </>
  );
};
