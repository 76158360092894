import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { GetLoginUserDetails } from "../../../../utils/Utils";
// import ReactDatePicker from "react-datepicker";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { NumericFormatCustom } from "../../../task/view/component/Forms/NumericFormatCustom";
import { SalaryTable } from "./SalaryTable";
import { Bank, Trash } from "react-bootstrap-icons";
import { Add, Cancel, Edit, Gavel } from "@mui/icons-material";
import {
  getAllDepartment,
  getClientOfOrg,
  getContractList,
  getCountryList,
  getCurrencyList,
  getItemDetailsOfProformaInvoice,
  getItems,
  getOrgDetailsByOrgId,
  getPendingAppointmentCandidat,
  getPositionsOfOrganization,
  getProformaInvoicesPendingForTaxInvoces,
  getSalaryBreakUpOfLoi,
  getStateList,
  getStaticDDOfOrg,
  getUnits,
  unitDetilsForProforma,
} from "../../../../services/AdminService";
import DashboardServices from "../../../../services/DashboardServices";
import IntegerTextField from "../../../../common/IntegerTextField";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { VendorPaymentMethods } from "../../../task/view/component/Forms/VendorPaymentMethods";
import { TermsAndConditions } from "../../../task/view/component/Forms/TermsAndConditions";
import TaskService from "../../../../services/TaskService";
import { FloatTextField } from "../../../../common/FloatTextField";
import { UnitPaymentMethods } from "./UnitPaymentMethods";
import { enIN, es } from "date-fns/locale";

// Register locales with ReactDatePicker
registerLocale("en", enIN);
registerLocale("es", es);

export const TaxInvoice = forwardRef(
  ({ formData, setFormData, handleShowPreview }, taxRef) => {
    const loginUserDetails = GetLoginUserDetails();
    const loginUserDetail = GetLoginUserDetails();
    const [joiningdate, setjoiningdate] = useState(new Date());
    const { t, i18n } = useTranslation();
    const [unitsData, setUnitData] = useState([]);
    const [emplyeeUnits, setEmplyeeUnits] = useState("");
    const [showBankDetails, setShowBankDetails] = useState(false);
    const [selectedMethodId, setSelectedMethodId] = useState(0);

    const [selectedContractId, setSelectedContractId] = useState("");
    const [shipToData, setShipToData] = useState("");
    const [itemData, setItemData] = useState([]);
    const [orgTacList, setOrgTacList] = useState([]);
    const [selectedConditionList, setSelectedConditionList] = useState([]);
    const [showTaCDialog, setShowTaCDialog] = useState(false);

    const [totalAmount, setTotalAmount] = useState();
    const [roundOffAmount, setRoundOffAmount] = useState();
    const [paymentMethods, setPaymentMethods] = useState([]);

    const [allTermsAndConditions, setAllTermsAndConditions] = useState(null);

    const [defultCurrency, setdefultCurrency] = useState("");
    const [sellerTaxId, setsellerTaxId] = useState("");
    const [sellerEmailValue, setsellerEmailValue] = useState([]);
    const [sellerEmailId, setsellerEmailId] = useState("");
    const [sellerEmailInputId, setsellerEmailInputId] = useState("");

    const [buyerTaxId, setBuyerTaxId] = useState("");
    const [buyerEmailId, setBuyerEmailId] = useState("");
    const [selectSellerState, setSelectSellerState] = useState("");
    const [selectBuyerState, setSelectBuyerState] = useState("");
    const [currencyList, setCurrencyList] = useState([]);
    const [effectiveDate, setEffectiveDate] = useState(null);
    const [challanNumber, setChallanNumber] = useState("");
    const [poNumber, setPoNumber] = useState("");
    const [specialNote, setSpecialNote] = useState("");
    const [buyerCountryCode, setBuyerCountryCode] = useState("");
    const [itemUOMList, setItemUOMList] = useState([]);
    const [itemType, setItemType] = useState("goods");

    const [porformaList, setPorformaList] = useState([]);
    const [fromIssuedPorforma, setFromIssuedPorforma] = useState(false);

    const [selectedPendingPorforma, setSelectedPendingPorforma] =
      useState(null);

    const [selectedProformaInvoiceData, setSelectedProformaInvoiceData] =
      useState(null);
    const [userAddedTermsAndConditions, setUserAddedTermsAndConditions] =
      useState([]);

    // Determine the locale based on the current language
    const currentLocale = i18n.language === "es" ? "es" : "en";

    const [tableData, setTableData] = useState([]);
    const [addTableData, setAddTableData] = useState({
      proformaInvoId: 0,
      item: null,
      itemId: 0,
      itemName: "",
      uom: "",
      quantity: 0,
      rate: 0,
      disePc: 0,
      sgstPc: 0,
      cgstPc: 0,
      igstPc: 0,
      taxPc: 0,
      diseAmt: 0,
      sgstAmt: 0,
      amount: 0,
    });

    const fetchOrgTermsAndConditionData = () => {
      TaskService.getOrgTermsAndConditions(
        loginUserDetail.orgId,
        "PROFORMA INVOICE"
      ).then((response) => {
        if (response.data) {
          const tmpCondList = response.data;
          setOrgTacList(tmpCondList);
          setSelectedConditionList(tmpCondList);
        }
      });
    };

    useEffect(() => {
      console.log("add table data", addTableData);
    }, [addTableData]);

    const [contractData, setContractData] = useState([]);

    const handleJoiningDate = (date) => {
      setjoiningdate(date);
    };

    const filteredItemList = useMemo(() => {
      if (itemData && itemType) {
        return itemData?.filter((item) => item.type === itemType) || [];
      }
      return [];
    }, [itemData, itemType]);

    const { control } = useForm({});

    useEffect(() => {
      if (loginUserDetail.signleUnitId !== "0") {
        setEmplyeeUnits(loginUserDetail.signleUnitId);
      }

      if (loginUserDetail.signleUnitId == "0" && unitsData.length === 1) {
        setEmplyeeUnits(unitsData[0].unitId);
      }
    }, [unitsData]);

    useEffect(() => {
      if (emplyeeUnits && unitsData) {
        const result = unitsData.find((val) => val.unitId === emplyeeUnits);

        if (result) {
          setdefultCurrency(result.currency || "");
          setsellerTaxId(result.taxNo || "");
          setSelectSellerState(result.stateId || "");
          const orgEmail = result.orgEmail;
          const unitEmail = result.unitEmail;
          const userEmail = loginUserDetail.userEmailId;
          setsellerEmailValue([unitEmail, orgEmail, userEmail]);

          if (unitEmail) {
            setsellerEmailId(unitEmail);
          } else if (orgEmail) {
            setsellerEmailId(orgEmail);
          } else {
            setsellerEmailId(userEmail);
          }
        } else {
          console.log("No matching unit found");
          setdefultCurrency("");
          setsellerTaxId("");
          setsellerEmailValue([]);
          setSelectSellerState();
        }
      }
    }, [emplyeeUnits, unitsData]);

    useEffect(() => {
      if (selectedContractId && contractData) {
        const result = contractData.find(
          (val) => val.userId === selectedContractId
        );

        console.log("buyer", result);

        if (result) {
          if (!fromIssuedPorforma) {
            setBuyerTaxId(result.gstinTaxId || "");
            setBuyerEmailId(result.emailId || "");
          }
          setShipToData(result.address || "");
          setBuyerCountryCode(result.countryCode || "");
          setSelectBuyerState(result.stateId || "");
        } else {
          console.log("No matching unit found");

          if (!fromIssuedPorforma) {
            setBuyerTaxId("");
            setBuyerEmailId("");
          }

          setShipToData("");
          setBuyerCountryCode("");
          setSelectBuyerState();
        }
      }
    }, [selectedContractId, contractData]);

    const handleChangEemplyeeUnits = (event) => {
      setEmplyeeUnits(event.target.value);
    };

    const fetchPaymentMethods = () => {
      TaskService.getPaymentMethodsOfUnit(loginUserDetail.signleUnitId).then(
        (response) => {
          if (response.data) {
            setPaymentMethods(response.data);
          }
        }
      );
    };

    useEffect(() => {
      fetchContractData();
      fetchOrgTermsAndConditionData();
      fetchPaymentMethods();
      getCurrencyList().then((response) => {
        console.log("getCurrencyList", response.data);
        setCurrencyList(response.data);
      });

      TaskService.getItemUOM(loginUserDetail.orgId).then((response) => {
        setItemUOMList(response.data);
      });

      getProformaInvoicesPendingForTaxInvoces(
        loginUserDetails.signleUnitId
      ).then((response) => {
        if (response.data) {
          setPorformaList(response.data);
        }
      });
    }, []);

    const fetchContractData = () => {
      // getContractList(loginUserDetail.orgId).then((response) => {
      //   console.log("getContractList", response.data);
      //   setContractData(response.data);
      // });
      getClientOfOrg(loginUserDetail.orgId).then((response) => {
        console.log("getClientOfOrg", response.data);
        setContractData(response.data);
      });
    };

    console.log("contractData", contractData);

    const handleDelete = (id) => {
      setTableData((prevTableData) =>
        prevTableData.filter((row) => row.proformaInvoId !== id)
      );
    };

    const handleAddColumn = () => {
      let isValid = true;
      if (addTableData.itemId === 0) {
        toast.error(t("please_select_item"));
        isValid = false;
      }
      if (!addTableData.quantity) {
        toast.error(t("please_provide_quantity"));
        isValid = false;
      }
      if (!addTableData.rate) {
        toast.error(t("please_provide_rate"));
        isValid = false;
      }
      if (!isValid) {
        return;
      }

      const nextId =
        tableData.length > 0
          ? tableData[tableData.length - 1].proformaInvoId + 1
          : 1;

      setAddTableData((prevData) => ({
        ...prevData,
        proformaInvoId: nextId,
      }));

      setTableData((prevTableData) => [
        ...prevTableData,
        { ...addTableData, proformaInvoId: nextId },
      ]);

      setAddTableData({
        proformaInvoId: 0,
        itemId: 0,
        itemName: "",
        uom: "",
        quantity: 0,
        rate: 0,
        disePc: 0,
        sgstPc: 0,
        cgstPc: 0,
        igstPc: 0,
        taxPc: 0,
        diseAmt: 0,
        sgstAmt: 0,
        amount: 0,
        item: null,
      });
    };

    const subTotalAmount = useMemo(() => {
      if (!tableData || tableData.length === 0) {
        setTotalAmount(0);
        setRoundOffAmount(0);
        return 0;
      } else {
        let totalValue = 0;
        tableData?.forEach((item) => {
          totalValue += parseFloat(item.amount);
        });
        totalValue = parseFloat(totalValue.toFixed(2));
        const total = Math.round(totalValue);

        const roudnOff = parseFloat((total - totalValue).toFixed(2));
        setTotalAmount(total);
        setRoundOffAmount(roudnOff);
        return totalValue;
      }
    }, [tableData]);

    useEffect(() => {
      unitDetilsForProforma(loginUserDetail.orgId).then((response) => {
        console.log("response unit of proforma", response.data);
        setUnitData(response.data);
      });
      getItems(loginUserDetail.orgId).then((response) => {
        if (response.data) {
          setItemData(response.data);
        }
      });
    }, []);

    // useEffect(() => {
    //   getStaticDDOfOrg(
    //     loginUserDetails.orgId,
    //     "APPOINTMENT_TERMS_CONDITIONS"
    //   ).then((response) => {
    //     if (response.data) {
    //       setStaticDodList(response.data);
    //       setStaticDodListCopy(response.data);
    //     }
    //   });
    //   getPositionsOfOrganization(loginUserDetails.orgId).then((response) => {
    //     if (response.data) {
    //       setPositionList(response.data);
    //     }
    //   });
    //   getAllDepartment(loginUserDetails.orgId).then((response) => {
    //     if (response.data) {
    //       setDepartmentList(response.data);
    //     }
    //   });
    //   getCountryList(loginUserDetails.orgId).then((response) => {
    //     if (response.data) {
    //       setCountryList(response.data);
    //     }
    //   });
    //   getPendingAppointmentCandidat(loginUserDetails.orgId).then((response) => {
    //     if (response.data) {
    //       setLoiCandidateList(response.data);
    //     }
    //   });
    //   getOrgDetailsByOrgId(loginUserDetails.orgId).then((response) => {
    //     if (response.data) {
    //       setOrgData(response.data);
    //     }
    //   });
    //   getUnits(loginUserDetails.orgId).then((response) => {
    //     if (response.data) {
    //       setUnitList(response.data);
    //       setUnitData(response.data);
    //     }
    //   });
    //   DashboardServices.getOrgEmployees(loginUserDetail.orgId).then(
    //     (response) => {
    //       console.log("all contract  = ", response.data);
    //       setUserList(response.data);
    //     }
    //   );
    // }, []);

    const calculateAmount = () => {
      const { quantity, rate, disePc, cgstPc, sgstPc, igstPc, taxPc } =
        addTableData;

      console.log("rate", rate);

      const qty = parseFloat(quantity) || 0;
      const r = parseFloat(rate) || 0;
      const discount = parseFloat(disePc) || 0;

      // Calculate the basic amount
      let amount = qty * r;

      // Apply discount
      amount -= amount * (discount / 100);

      // Add tax based on organization code
      if (loginUserDetail.orgCountryCode === "356") {
        const cgst = (amount * (parseFloat(cgstPc) || 0)) / 100;
        const sgst = (amount * (parseFloat(sgstPc) || 0)) / 100;
        const igst = (amount * (parseFloat(igstPc) || 0)) / 100;
        amount += cgst + sgst + igst;
      } else {
        amount += (amount * (parseFloat(taxPc) || 0)) / 100;
      }

      // Update the amount in the state
      setAddTableData((prevData) => ({
        ...prevData,
        amount: amount.toFixed(2), // Store amount as a string with 2 decimal places
      }));

      console.log("amount", amount);
    };
    const calculateAmount1 = (data) => {
      const { quantity, rate, disePc, cgstPc, sgstPc, igstPc, taxPc } = data;

      console.log("rate", rate);

      const qty = parseFloat(quantity) || 0;
      const r = parseFloat(rate) || 0;
      const discount = parseFloat(disePc) || 0;

      // Calculate the basic amount
      let amount = qty * r;

      // Apply discount
      const diseAmt = amount * (discount / 100);
      let sgstAmt = 0;
      let igstAmt = 0;
      let cgstAmt = 0;
      let taxAmt = 0;
      amount -= diseAmt;

      // Add tax based on organization code
      if (loginUserDetail.orgCountryCode === "356") {
        cgstAmt = (amount * (parseFloat(cgstPc) || 0)) / 100;
        sgstAmt = (amount * (parseFloat(sgstPc) || 0)) / 100;
        igstAmt = (amount * (parseFloat(igstPc) || 0)) / 100;
        amount += cgstAmt + sgstAmt + igstAmt;
      } else {
        taxAmt = (amount * (parseFloat(taxPc) || 0)) / 100;
        amount += taxAmt;
      }

      // Update the amount in the state
      // setAddTableData(prevData => ({
      //     ...prevData,
      //     amount: amount.toFixed(2),  // Store amount as a string with 2 decimal places
      // }));

      return {
        amount: amount.toFixed(2),
        value: amount.toFixed(2),
        diseAmt: diseAmt.toFixed(2),
        sgstAmt: sgstAmt.toFixed(2),
        cgstAmt: cgstAmt.toFixed(2),
        igstAmt: igstAmt.toFixed(2),
        taxAmt: taxAmt.toFixed(2),
      };
    };

    const fetchProformaInvoiceData = (proformaInvoiceId) => {
      getItemDetailsOfProformaInvoice(proformaInvoiceId).then((response) => {
        if (response.data) {
          setSelectedProformaInvoiceData(response.data);
        }
      });
    };

    useEffect(() => {
      if (selectedProformaInvoiceData) {
        let temp = selectedProformaInvoiceData;
        if (temp) {
          const {
            paymentId,
            items,
            clientEmail,
            clientTaxId,
            clientId,
            sellerEmail,
            sellerTaxId,
            sellerUnitId,
            goodsService,
          } = temp;

          setEmplyeeUnits(sellerUnitId);
          setsellerTaxId(sellerTaxId);
          setsellerEmailId(sellerEmail);

          setBuyerTaxId(clientTaxId);
          setBuyerEmailId(clientEmail);
          const buyer = contractData?.find((item) => item.userId === clientId);
          setSelectedContractId(buyer.userId);
          if (goodsService) {
            setItemType(goodsService);
          }

          let itmList = [];
          items?.forEach((element) => {
            const foundItem = itemData.find(
              (item) => element.itemId === item.itemId
            );
            const dto = {
              proformaInvoId: 0,
              item: foundItem,
              itemId: element.itemId,
              itemName: element.itemName || "",
              uom: element.uom || "",
              itemDescription: element.itemDescription || "",
              quantity: element.quantity || 0,
              rate: element.rate || 0,
              disePc: element.disPc || 0,
              sgstPc: element.sgstPc || 0,
              cgstPc: element.sgstPc || 0,
              igstPc: element.igstPc || 0,
              taxPc: element.taxPc || 0,
              diseAmt: element.disAmt || 0,
              sgstAmt: element.sgstAmt || 0,
              amount: element.value || 0,
              value: element.value || 0,
            };
            const amount = calculateAmount1(dto);
            itmList.push({ ...dto, ...amount });
          });
          // setTableData(items || []);
          setTableData(itmList || []);
          const selectedPayment = paymentMethods.find(
            (item) => item.id === paymentId
          );
          if (selectedPayment) {
            setSelectedMethodId(selectedPayment);
          } else {
            setSelectedMethodId(0);
          }
          console.log("bank method =", selectedPayment);

          //   [
          //     {
          //         "slNo": 6,
          //         "termsConditionType": "Payments",
          //         "termsConditionHeading": "Heading 83sdf",
          //         "termsCondition": "Tac 8 sdfj df ahfhen df jsddfeadfd"
          //     }
          // ]

          const tempTac = temp?.termsConditions?.map((item) => {
            return {
              slNo: 7,
              termsConditionsHeading: item.termsConditionHeading || "",
              termsConditions: item.termsCondition || "",
              termsConditionsType: item.termsConditionType,
              transType: "",
            };
          });
          console.log("temp tac =", tempTac);

          setAllTermsAndConditions(tempTac || []);
        } else {
          setBuyerTaxId("");
          setBuyerEmailId("");
          setSelectedContractId("");
          setsellerTaxId("");
          setsellerEmailId("");
        }
      }
    }, [selectedProformaInvoiceData]);

    return (
      <>
        <div className="formElementGrp">
          <div className="formElement">
            <FormControl className="formControl">
              <FormGroup aria-label="position" row className="feformGroup">
                <FormControlLabel
                  className="formCheckBox"
                  // value="Cost Center"
                  control={
                    <Checkbox
                      value={fromIssuedPorforma}
                      onChange={(e) => {
                        setFromIssuedPorforma(e.target.checked);
                        if (!e.target.checked) {
                        }
                      }}
                    />
                  }
                  label={t("from_issued_Poforma")}
                  labelPlacement="End"
                />
              </FormGroup>
            </FormControl>
          </div>

          {fromIssuedPorforma && (
            <div className="formElement ">
              <FormControl className="formControl">
                <InputLabel id="employeeTitle">
                  {t("pending_invoices")}
                </InputLabel>

                <Select
                  labelId="employeeTitle"
                  id="selectemployeeTitle"
                  label={t("title")}
                  className="formInputField"
                  variant="outlined"
                  value={selectedPendingPorforma}
                  onChange={(e) => {
                    const item = e.target.value;
                    setSelectedPendingPorforma(e.target.value);
                    fetchProformaInvoiceData(item.proformaInvoiceId);
                  }}
                  renderValue={(value) => {
                    const invoiceNo = value?.proformaInvoiceNo
                      ? `(${value.proformaInvoiceNo})`
                      : "";
                    return `${value?.clientName}${invoiceNo} `;
                  }}
                >
                  {porformaList &&
                    porformaList.map((candidate) => (
                      <MenuItem value={candidate}>
                        {/* {`${candidate.clientName} ${candidate.invoiceAmount} (${candidate.invoiceDate})`} */}
                        <div
                          style={{ padding: "4px 12px" }}
                          className="sDocOptList"
                        >
                          <div className="sDname">{candidate.clientName}</div>
                          {candidate.invoiceDate && (
                            <div className="sDElm">
                              {t("date")}: {candidate.invoiceDate}
                            </div>
                          )}
                          {candidate.invoiceAmount && (
                            <div className="sDElm">
                              {t("amount")}: {candidate.invoiceAmount}
                            </div>
                          )}
                        </div>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          )}
        </div>

        <div class="tskElemHeding mt10">{t("seller_details")}</div>

        <div className="formElementGrp">
          <div className="formElement ">
            <FormControl className="formControl">
              <InputLabel id="selectUnits">{t("seller")}</InputLabel>
              <Select
                required
                labelId="selectUnits"
                id="employee-units"
                value={
                  emplyeeUnits === undefined || emplyeeUnits === "undefined"
                    ? ""
                    : emplyeeUnits
                }
                label={t("seller")}
                onChange={handleChangEemplyeeUnits}
                className="formInputField"
                variant="outlined"
                renderValue={(value) => {
                  const selectedContract = unitsData.find(
                    (doc) => doc.unitId === value
                  );
                  return `${selectedContract?.unitName}`;
                }}
              >
                {unitsData.map((unit, index) => (
                  <MenuItem key={index} value={unit.unitId}>
                    <div
                      style={{ padding: "4px 12px" }}
                      className="sDocOptList"
                    >
                      <div className="sDname">{unit.unitName}</div>
                      <div className="sDElm">{unit.address}</div>
                      {/* <div className="sDElmGrp">
                                  {unit?.unitEmail && (
                                  <div className="sDElm">
                                      unitEmail :{" "}
                                      <span>
                                        {unit.unitEmail}
                                      </span>
                                    </div>
                                  )}
                                    {unit?.currency && (
                                    <div className="sDElm">
                                    currency:{" "}
                                      <span>{unit.currency}</span>
                                    </div>
                                    )}
                                  </div> */}
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label={t("tax_gstin_id")}
                required
                placeholder={t("enter_seller_tax_gstin_id")}
                variant="outlined"
                className="formTextFieldArea"
                autoComplete="off"
                value={sellerTaxId}
                onChange={(e) => setsellerTaxId(e.target.value)}
              />
            </FormControl>
          </div>

          <div className="formElement">
            <FormControl className="formControl">
              <Autocomplete
                noOptionsText={t("please_type_email_id")}
                className="formAutocompleteField"
                variant="outlined"
                value={sellerEmailId}
                options={sellerEmailValue}
                getOptionLabel={(option) => option}
                freeSolo
                inputValue={sellerEmailInputId}
                onChange={(e, newValue) => {
                  console.log("new value = ", newValue);
                  setsellerEmailId(newValue);
                }}
                onInputChange={(e, newValue) => {
                  console.log("new value = ", newValue);
                  setsellerEmailInputId(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="formAutoComInputField autocompFildPlaceholder"
                    placeholder={t("seller_email_id")}
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
              />
            </FormControl>
          </div>
        </div>

        <div class="tskElemHeding mt10">{t("buyer_details")}</div>

        <div className="formElementGrp">
          <div className="formElement">
            <FormControl className="formControl">
              <InputLabel id="employeeTitle">{t("buyer_details")}</InputLabel>
              {/* <Controller
                name="employeeTitle"
                control={control}
                defaultValue={"Mr"}
                render={({ field }) => (
                )}
              /> */}
              <Select
                labelId="employeeTitle"
                id="selectemployeeTitle"
                label="Buyer"
                className="formInputField"
                value={
                  selectedContractId === undefined ||
                  selectedContractId === "undefined"
                    ? ""
                    : selectedContractId
                }
                onChange={(e) => setSelectedContractId(e.target.value)}
                variant="outlined"
                renderValue={(value) => {
                  const selectedContract = contractData.find(
                    (doc) => doc.userId === value
                  );
                  return `${selectedContract?.userName}`;
                }}
              >
                {contractData.map((contract) => (
                  <MenuItem
                    key={contract.userId}
                    value={contract.userId}
                    // value={contract.id}
                  >
                    <div
                      style={{ padding: "4px 12px" }}
                      className="sDocOptList"
                    >
                      <div className="sDname">{contract.userName}</div>
                      {/* <div className="sDname">{contract.contractName}</div> */}
                      {contract?.address && (
                        <div className="sDElm">{contract.address}</div>
                      )}
                      <div className="sDElmGrp">
                        {contract?.emailId && (
                          <div className="sDElm">
                            {t("emailId")}: <span>{contract.emailId}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label={t("tax")}
                required
                placeholder={t("enter_buyer_tax")}
                variant="outlined"
                className="formTextFieldArea"
                autoComplete="off"
                value={buyerTaxId}
                onChange={(e) => setBuyerTaxId(e.target.value)}
              />
            </FormControl>
          </div>

          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label={t("emailId")}
                // required
                placeholder={t("enter_email")}
                variant="outlined"
                className="formTextFieldArea"
                autoComplete="off"
                value={buyerEmailId}
                onChange={(e) => setBuyerEmailId(e.target.value)}
              />
            </FormControl>
          </div>
        </div>

        <div class="tskElemHeding mt10"></div>

        <div className="formElementGrp">
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label={t("place_of_supply")}
                required
                placeholder={t("enter_place_of_supply")}
                variant="outlined"
                className="formTextFieldArea"
                autoComplete="off"
                value={shipToData}
                onChange={(e) => setShipToData(e.target.value)}
                // InputLabelProps={{ shrink: email !== "" ? true : false }}
                // {...register("email")}
              />
            </FormControl>
          </div>

          <div className="formElement">
            <FormControl className="formControl">
              <InputLabel id="employeeJoingDate" className="setCompletionDate">
                {t("invoice_date")}*
              </InputLabel>
              <ReactDatePicker
                locale={currentLocale}
                showIcon
                labelId="employeeJoingDate"
                className="formDatePicker"
                selected={joiningdate}
                // minDate={new Date()}
                maxDate={new Date()}
                showYearDropdown
                yearDropdownItemNumber={100}
                scrollableYearDropdown
                showMonthDropdown
                dateFormat="dd/MM/yyyy"
                onChange={(date) => {
                  handleJoiningDate(date);
                }}
              />
            </FormControl>
          </div>

          <div className="formElement">
            <FormControl className="formControl">
              <InputLabel id="employeeJoingDate" className="setCompletionDate">
                {t("effective_date")}
              </InputLabel>
              <ReactDatePicker
                locale={currentLocale}
                showIcon
                labelId="employeeJoingDate"
                className="formDatePicker"
                selected={effectiveDate}
                minDate={new Date()}
                // maxDate={new Date()}
                showYearDropdown
                yearDropdownItemNumber={100}
                scrollableYearDropdown
                showMonthDropdown
                dateFormat="dd/MM/yyyy"
                onChange={(date) => {
                  setEffectiveDate(date);
                }}
              />
            </FormControl>
          </div>
        </div>

        <div className="formElementGrp">
          <div className="formElement">
            <FormControl className="formControl">
              <InputLabel id="selectUnits">{t("currency")}*</InputLabel>
              <Select
                required
                labelId="selectUnits"
                id="employee-units"
                value={defultCurrency}
                label={t("currency")}
                onChange={(e) => setdefultCurrency(e.target.value)}
                className="formInputField"
                variant="outlined"
              >
                {currencyList.map((currency) => (
                  <MenuItem key={currency} value={currency}>
                    {currency}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label={t("po_number")}
                // required
                placeholder={t("po_number")}
                variant="outlined"
                className="formTextFieldArea"
                autoComplete="off"
                value={poNumber}
                onChange={(e) => setPoNumber(e.target.value)}
                // InputLabelProps={{ shrink: email !== "" ? true : false }}
                // {...register("email")}
              />
            </FormControl>
          </div>
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label={t("challan_number")}
                // required
                placeholder={t("enter_challan_number")}
                variant="outlined"
                className="formTextFieldArea"
                autoComplete="off"
                value={challanNumber}
                onChange={(e) => setChallanNumber(e.target.value)}
                // InputLabelProps={{ shrink: email !== "" ? true : false }}
                // {...register("email")}
              />
            </FormControl>
          </div>
        </div>

        {/* <div className="formElementGrp">
          <div className="formElement width13">
            <FormControl className="formControl">
              <InputLabel id="selectItemType">Item Type</InputLabel>
              <Select
                required
                labelId="selectItemType"
                id="employee-units"
                value={itemType}
                label="Currency"
                onChange={(e) => setItemType(e.target.value)}
                className="formInputField"
                variant="outlined"
              >
                <MenuItem key={"goods"} value={"goods"}>
                  {"Goods"}
                </MenuItem>
                <MenuItem key={"services"} value={"services"}>
                  {"Services"}
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div> */}

        <div class="tskElemHeding mt10">{t("item_type")}</div>

        <div className="formElement">
          <FormControl className="formControl">
            <RadioGroup
              className="formRadioGroup"
              value={itemType}
              onChange={(e) => setItemType(e.target.value)}
            >
              <FormControlLabel
                className="formRadioField"
                value="goods"
                control={<Radio />}
                label={t("goods")}
              />
              <FormControlLabel
                className="formRadioField"
                value="services"
                control={<Radio />}
                label={t("services")}
              />
            </RadioGroup>
          </FormControl>
        </div>

        <div class="tskElemHeding mt10">{t("items_details")}</div>
        <div className="formElementGrp flex-wrap"></div>

        <div className="indentFormtable">
          <div className="indtableResponsive">
            <table>
              <thead>
                <tr>
                  <th className="w200">{t("item_name")}</th>
                  <th className="w200">{t("description")}</th>
                  <th className="w100">{t("quantity")}</th>
                  <th className="w100">{t("rate")}</th>
                  <th className="w100">{t("uom")}</th>
                  <th className="w100">{t("discount")}</th>
                  {buyerCountryCode == "356" &&
                  loginUserDetail.orgCountryCode == "356" ? (
                    <>
                      {selectSellerState !== "" &&
                      selectBuyerState !== "" &&
                      selectSellerState === selectBuyerState ? (
                        <>
                          <th className="indntQunt w100">CGST</th>
                          <th className="indntQunt w100">SGST</th>
                        </>
                      ) : (
                        <th className="indntQunt w100">IGST</th>
                      )}
                    </>
                  ) : (
                    <th className="indntQunt w100">{t("tax")}</th>
                  )}
                  <th className="w180">{t("amount")}</th>
                  <th className="mw80"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="formElement">
                      <FormControl className="formControl">
                        <InputLabel id="employeeTitle">
                          {t("item_name")}
                        </InputLabel>

                        <Select
                          labelId="employeeTitle"
                          id="selectemployeeTitle"
                          label={t("item_name")}
                          className="formInputField"
                          variant="outlined"
                          value={addTableData.item}
                          renderValue={(value) => {
                            const hsnCd = value.hsnCd ? `(${value.hsnCd})` : "";
                            return `${value?.itemName} ${hsnCd}`;
                          }}
                          onChange={(e) => {
                            const selectedItem = e.target.value;
                            setAddTableData((prevData) => ({
                              ...prevData,
                              itemId: selectedItem.itemId,
                              itemName: selectedItem
                                ? selectedItem.itemName
                                : "",
                              item: selectedItem,
                              itemDescription: selectedItem.itemDescription,
                              // uom: selectedItem.uom,
                            }));
                            // field.onChange(e.target.value);
                          }}
                        >
                          {filteredItemList?.map((item) => (
                            <MenuItem value={item}>
                              <div
                                style={{ padding: "4px 12px" }}
                                className="sDocOptList"
                              >
                                <div className="sDname">{item.itemName}</div>
                                {/* <div className="sDname">{contract.contractName}</div> */}
                                {/* {item?.itemDescription && (
                                  <div className="sDElm">
                                    {item.itemDescription}
                                  </div>
                                )} */}
                                <div className="sDElmGrp">
                                  {item?.hsnCd && (
                                    <div className="sDElm">
                                      hsnCd: <span>{item.hsnCd}</span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </td>
                  <td>
                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          label={t("description")}
                          variant="outlined"
                          className="formTextFieldArea"
                          // disabled={!item.editMode}
                          // multiline
                          // minRows={3}
                          // required
                          // fullWidth
                          size="small"
                          autoComplete="off"
                          value={addTableData.itemDescription}
                          onChange={(e) => {
                            setAddTableData((prevData) => {
                              let temp = {
                                ...prevData,
                                itemDescription: e.target.value,
                              };
                              return temp;
                            });
                          }}
                        />
                      </FormControl>
                    </div>
                  </td>
                  <td>
                    <div className="formElement inputPadding w100">
                      <FormControl className="formControl">
                        <IntegerTextField
                          label={t("quantity")}
                          variant="outlined"
                          className="formTextFieldArea"
                          // disabled={!item.editMode}
                          required
                          fullWidth
                          size="small"
                          autoComplete="off"
                          value={addTableData.quantity}
                          onChange={(data) => {
                            if (data) {
                              setAddTableData((prevData) => {
                                let temp = {
                                  ...prevData,
                                  quantity: data,
                                };
                                const amount = calculateAmount1(temp);
                                return { ...temp, ...amount };
                              });
                            }
                          }}
                        />
                      </FormControl>
                    </div>
                  </td>
                  <td>
                    <div className="formElement inputPadding w100">
                      <FormControl className="formControl">
                        <FloatTextField
                          label={t("rate")}
                          variant="outlined"
                          className="formTextFieldArea"
                          // disabled={!item.editMode}
                          required
                          fullWidth
                          size="small"
                          autoComplete="off"
                          value={addTableData.rate}
                          //   onChange={(e) => {
                          //     setAddTableData((prevData) => ({
                          //       ...prevData,
                          //       rate: e.target.value,
                          //     }));
                          //     calculateAmount();
                          // }}
                          onChange={(data) => {
                            setAddTableData((prevData) => {
                              let temp = {
                                ...prevData,
                                rate: data,
                              };
                              const amount = calculateAmount1(temp);
                              return { ...temp, ...amount };
                            });
                          }}
                        />
                      </FormControl>
                    </div>
                  </td>
                  <td>
                    <div className="formElement inputPadding w100">
                      <FormControl className="formControl">
                        {/* <TextField
                          label="Uom"
                          variant="outlined"
                          className="formTextFieldArea"
                          // disabled={!item.editMode}
                          // required
                          fullWidth
                          size="small"
                          autoComplete="off"
                          value={addTableData.uom}
                          onChange={(e) => {
                            setAddTableData((prevData) => {
                              let temp = {
                                ...prevData,
                                uom: e.target.value,
                              };
                              // const amount = calculateAmount1(temp);
                              return { ...temp };
                            });
                          }}
                        /> */}

                        <InputLabel id="selectUOM">{t("uom")}</InputLabel>
                        <Select
                          className="formInputField"
                          variant="outlined"
                          labelId="selectUOM"
                          id="select-UOM"
                          label={t("uom")}
                          value={addTableData.uom}
                          onChange={(e) => {
                            setAddTableData((prevData) => {
                              let temp = {
                                ...prevData,
                                uom: e.target.value,
                              };
                              return { ...temp };
                            });
                          }}
                        >
                          {itemUOMList.length > 0 &&
                            itemUOMList.map((item) => (
                              <MenuItem value={item} key={item}>
                                {item}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                  </td>
                  <td>
                    <div className="formElement inputPadding w100 ">
                      <FormControl className="formControl">
                        <FloatTextField
                          label={t("discountPercent")}
                          variant="outlined"
                          className="formTextFieldArea"
                          // disabled={!item.editMode}
                          //   required
                          autoComplete="off"
                          fullWidth
                          size="small"
                          value={addTableData.disePc}
                          max={100}
                          min={0}
                          onChange={(data) => {
                            setAddTableData((prevData) => {
                              let temp = {
                                ...prevData,
                                disePc: data,
                              };
                              const amount = calculateAmount1(temp);
                              return { ...temp, ...amount };
                            });
                          }}
                        />
                      </FormControl>
                    </div>
                  </td>

                  {buyerCountryCode == "356" &&
                  loginUserDetail.orgCountryCode == "356" ? (
                    <>
                      {selectSellerState !== "" &&
                      selectBuyerState !== "" &&
                      selectSellerState === selectBuyerState ? (
                        <>
                          <td>
                            <div className="formElement inputPadding w100">
                              <FormControl className="formControl">
                                <FloatTextField
                                  label="CGST(%)"
                                  variant="outlined"
                                  className="formTextFieldArea"
                                  autoComplete="off"
                                  // disabled={!item.editMode}
                                  //   required
                                  fullWidth
                                  size="small"
                                  value={addTableData.cgstPc}
                                  max={100}
                                  min={0}
                                  onChange={(data) => {
                                    setAddTableData((prevData) => {
                                      const temp = {
                                        ...prevData,
                                        cgstPc: data,
                                      };
                                      const amount = calculateAmount1(temp);
                                      return { ...temp, ...amount };
                                    });
                                  }}
                                />
                              </FormControl>
                            </div>
                          </td>

                          <td>
                            <div className="formElement inputPadding w100">
                              <FormControl className="formControl">
                                <FloatTextField
                                  label="SGST(%)"
                                  variant="outlined"
                                  className="formTextFieldArea"
                                  autoComplete="off"
                                  // disabled={!item.editMode}
                                  //   required
                                  fullWidth
                                  size="small"
                                  value={addTableData.sgstPc}
                                  max={100}
                                  min={0}
                                  onChange={(data) => {
                                    setAddTableData((prevData) => {
                                      const temp = {
                                        ...prevData,
                                        sgstPc: data,
                                      };
                                      const amount = calculateAmount1(temp);
                                      return { ...temp, ...amount };
                                    });
                                  }}
                                />
                              </FormControl>
                            </div>
                          </td>
                        </>
                      ) : (
                        <td>
                          <div className="formElement inputPadding w100">
                            <FormControl className="formControl">
                              <FloatTextField
                                label="IGST(%)"
                                variant="outlined"
                                className="formTextFieldArea"
                                autoComplete="off"
                                // disabled={!item.editMode}
                                //   required
                                fullWidth
                                size="small"
                                value={addTableData.igstPc}
                                max={100}
                                min={0}
                                onChange={(data) => {
                                  setAddTableData((prevData) => {
                                    const temp = {
                                      ...prevData,
                                      igstPc: data,
                                    };
                                    const amount = calculateAmount1(temp);
                                    return { ...temp, ...amount };
                                  });
                                }}
                              />
                            </FormControl>
                          </div>
                        </td>
                      )}
                    </>
                  ) : (
                    <td>
                      <div className="formElement inputPadding w100">
                        <FormControl className="formControl">
                          <FloatTextField
                            label={t("tax_percentage")}
                            variant="outlined"
                            className="formTextFieldArea"
                            autoComplete="off"
                            // disabled={!item.editMode}
                            // required
                            fullWidth
                            size="small"
                            value={addTableData.taxPc}
                            max={100}
                            min={0}
                            onChange={(data) => {
                              setAddTableData((prevData) => {
                                const temp = {
                                  ...prevData,
                                  taxPc: data,
                                };
                                const amount = calculateAmount1(temp);
                                return { ...temp, ...amount };
                              });
                            }}
                          />
                        </FormControl>
                      </div>
                    </td>
                  )}

                  <td>
                    <div className="formElement inputPadding">
                      <FormControl className="formControl">
                        <TextField
                          label={t("amount")}
                          variant="outlined"
                          className="formTextFieldArea"
                          autoComplete="off"
                          disabled={true}
                          // required
                          fullWidth
                          size="small"
                          // value={item.itemDescription}
                          // onChange={(e) => {
                          //   item.itemDescription = e.target.value;
                          //   setRefreshCount((r) => r + 1);
                          // }}
                          value={addTableData.amount || 0}
                          readOnly
                        />
                      </FormControl>
                    </div>
                  </td>
                  <td>
                    <div className="iconBtnGroup">
                      <Button className="saveBtn" onClick={handleAddColumn}>
                        {t("save")}
                      </Button>
                    </div>
                  </td>
                </tr>
                {tableData?.map((row, index) => (
                  <tr key={index}>
                    <td>{row.itemName}</td>
                    <td>{row.itemDescription}</td>
                    <td>
                      <div className="textOnly">{row.quantity}</div>
                    </td>
                    <td>
                      <div className="textOnly">{row.rate}</div>
                    </td>
                    <td>
                      <div className="textOnly">{row.uom}</div>
                    </td>
                    <td>
                      <div className="textOnly">{row.disePc}</div>
                    </td>
                    {buyerCountryCode == "356" &&
                    loginUserDetail.orgCountryCode == "356" ? (
                      <>
                        {selectSellerState !== "" &&
                        selectBuyerState !== "" &&
                        selectSellerState === selectBuyerState ? (
                          <>
                            <td>
                              <div className="textOnly">{row.cgstPc}</div>
                            </td>
                            <td>
                              <div className="textOnly">{row.sgstPc}</div>
                            </td>
                          </>
                        ) : (
                          <td>
                            <div className="textOnly">{row.igstPc}</div>
                          </td>
                        )}
                      </>
                    ) : (
                      <td>
                        <div className="textOnly">{row.taxPc}</div>
                      </td>
                    )}
                    <td>
                      <div className="textOnly">{row.amount}</div>
                    </td>
                    <td>
                      {/* <div className="iconBtnGroup">
                      <IconButton>
                        <Edit />
                      </IconButton>
                    </div> */}
                      <div className="iconBtnGroup">
                        <IconButton
                          onClick={() => handleDelete(row.proformaInvoId)}
                        >
                          <Trash />
                        </IconButton>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td
                    colSpan={
                      selectSellerState !== "" &&
                      selectBuyerState !== "" &&
                      selectSellerState === selectBuyerState
                        ? 8
                        : 7
                    }
                  >
                    <div className="textOnly tvText">{t("sub_total")}</div>
                  </td>
                  <td>
                    <div className="textOnly fw600">{subTotalAmount}</div>
                  </td>
                </tr>
                {/* {otherCharges &&
                  otherCharges.length > 0 &&
                  otherCharges.map((item) => {
                    return (
                      <tr>
                        <td colSpan={6}></td>
                        <td colSpan={1}>{item.itemCharge}</td>
                        <td colSpan={1}>
                          {item.chargesRate}
                          <span style={{ float: "right" }}>
                            <Tooltip title={t("remove")}>
                              <Trash
                                height={"1.2rem"}
                                width={"1.2rem"}
                                cursor={"pointer"}
                                onClick={() => {
                                  let tmpList = otherCharges.filter(
                                    (obj) =>
                                      JSON.stringify(obj) !==
                                      JSON.stringify(item)
                                  );
                                  setOtherCharges(tmpList);
                                }}
                              />
                            </Tooltip>
                          </span>
                        </td>
                      </tr>
                    );
                  })} */}

                {/* {showAddOtherCharges && (
                  <tr>
                    {!loginUserDetail.orgCountryCode == "356" && (
                      <td colSpan={3}></td>
                    )}
                    <td colSpan={2}>
                      <div className="formElement">
                        <FormControl className="formControl">
                          <TextField
                            size="small"
                            label={t("charge_description")}
                            className="formTextFieldArea"
                            value={otherChargesDescription}
                            onChange={(e) => {
                              setOtherChargesDescription(e.target.value);
                            }}
                          />
                        </FormControl>
                      </div>
                    </td>
                    <td colSpan={2}>
                      <div className="formElement">
                        <FormControl className="formControl">
                          <TextField
                            type="number"
                            className="formTextFieldArea"
                            size="small"
                            label={t("price")}
                            value={otherChargesValue}
                            onChange={(e) => {
                              setOtherChargesValue(e.target.value);
                            }}
                          />
                        </FormControl>
                      </div>
                    </td>

                    {loginUserDetail.orgCountryCode == "356" ? (
                      <>
                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                label="CGST(%)"
                                variant="outlined"
                                className="formTextFieldArea"
                                // disabled={!item.editMode}
                                //   required
                                fullWidth
                                size="small"
                                value={otherCgst}
                                //   onChange={(e) => {
                                //     setAddTableData((prevData) => ({
                                //       ...prevData,
                                //       cgstPc: e.target.value,
                                //     }));
                                //     calculateAmount();
                                // }}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setOtherCgst(value);
                                }}
                              />
                            </FormControl>
                          </div>
                        </td>

                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                label="SGST(%)"
                                variant="outlined"
                                className="formTextFieldArea"
                                // disabled={!item.editMode}
                                //   required
                                fullWidth
                                size="small"
                                value={otherSgst}
                                //   onChange={(e) => {
                                //     setAddTableData((prevData) => ({
                                //       ...prevData,
                                //       sgstPc: e.target.value,
                                //     }));
                                //     calculateAmount();
                                // }}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setOtherSgst(value);
                                }}
                              />
                            </FormControl>
                          </div>
                        </td>

                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                label="IGST(%)"
                                variant="outlined"
                                className="formTextFieldArea"
                                // disabled={!item.editMode}
                                //   required
                                fullWidth
                                size="small"
                                value={otherIgst}
                                //   onChange={(e) => {
                                //     setAddTableData((prevData) => ({
                                //       ...prevData,
                                //       igstPc: e.target.value,
                                //     }));
                                //     calculateAmount();
                                // }}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setOtherIgst(value);
                                }}
                              />
                            </FormControl>
                          </div>
                        </td>
                      </>
                    ) : (
                      <td>
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              label="Tax(%)"
                              variant="outlined"
                              className="formTextFieldArea"
                              // disabled={!item.editMode}
                              // required
                              fullWidth
                              size="small"
                              value={otherTax}
                              //     onChange={(e) => {
                              //       setAddTableData((prevData) => ({
                              //         ...prevData,
                              //         taxPc: e.target.value,
                              //       }));
                              //       calculateAmount();
                              //   }}
                              onChange={(e) => {
                                const value = e.target.value;
                                setOtherTax(value);
                              }}
                            />
                          </FormControl>
                        </div>
                      </td>
                    )}

                    <td colSpan={1}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Tooltip title={t("add_charge")}>
                          <Button
                            onClick={() => {
                              const temp = {
                                itemCharge: otherChargesDescription,
                                chargesRate: parseFloat(otherChargesValue),
                                chargesAmount: 0,
                                sgstPc: otherSgst,
                                cgstPc: otherCgst,
                                igstPc: otherIgst,
                                taxPc: otherTax,
                                sgstAmt: 0,
                                igstAmt: 0,
                                cgstAmt: 0,
                                taxAmt: 0,
                                taxInvoiceId: 0,
                              };
                              if (otherChargesDescription.trim() !== "") {
                                setOtherCharges((prev) => [...prev, temp]);
                                setOtherChargesDescription("");
                                setOtherChargesValue(0);
                                setShowAddOtherCharges(false);
                              }
                            }}
                          >
                            {t("save")}
                          </Button>
                        </Tooltip>
                        <Tooltip title={t("close")}>
                          <Button
                            onClick={() => {
                              setShowAddOtherCharges(false);
                            }}
                          >
                            <Cancel />
                          </Button>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                )} */}

                {/* {!showAddOtherCharges && (
                  <tr>
                    <td colSpan={7}></td>
                    <td colSpan={1}>
                      <Tooltip title={t("add_other_charges")}>
                        <Button
                          disabled={showAddOtherCharges}
                          onClick={() => {
                            setShowAddOtherCharges(true);
                          }}
                        >
                          <Add />
                        </Button>
                      </Tooltip>
                    </td>
                  </tr>
                )} */}

                <tr>
                  <td
                    colSpan={
                      selectSellerState !== "" &&
                      selectBuyerState !== "" &&
                      selectSellerState === selectBuyerState
                        ? 7
                        : 6
                    }
                  ></td>
                  <td colSpan={1}>{t("round_off")}</td>
                  {/* <td colSpan={1}>{calculateTotal(itemList, otherCharges)}</td> */}
                  <td colSpan={1}>{roundOffAmount}</td>
                </tr>
                <tr>
                  <td
                    colSpan={
                      selectSellerState !== "" &&
                      selectBuyerState !== "" &&
                      selectSellerState === selectBuyerState
                        ? 7
                        : 6
                    }
                  ></td>
                  <td colSpan={1}>{t("total")}</td>
                  {/* <td colSpan={1}>{calculateTotal(itemList, otherCharges)}</td> */}
                  <td colSpan={1}>{totalAmount}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>

        {/* <div class="tskElemHeding mt10">
              {t("probation_confirmation_period")}
            </div> */}

        {/* end salary table */}

        <div class="tskElemHeding mt10">{t("paymentMethod")}</div>

        <div className="formBtnElement">
          <Button
            variant="outlined"
            startIcon={<Bank />}
            className={` ${
              selectedMethodId != 0 ? "dfultDarkSecondaryBtn" : "comBtnOutline"
            }`}
            onClick={() => setShowBankDetails(true)}
          >
            {t("banking_details")}
          </Button>

          {showBankDetails && (
            // <UnitPaymentMethods
            //   closeModal={() => {
            //     setShowBankDetails(false);
            //   }}
            //   selectedMethodId={selectedMethodId}
            //   setSelectedMethodId={setSelectedMethodId}
            //   vendorUserId={loginUserDetail.userId}
            //   vendorPaymentMethods={paymentMethods}
            //   updateVendorPaymentMethod={() => {
            //     fetchPaymentMethods();
            //   }}
            //   vendorUnitId={loginUserDetail.signleUnitId}
            // />

            <UnitPaymentMethods
              closeModal={() => {
                setShowBankDetails(false);
              }}
              selectedMethodId={selectedMethodId}
              setSelectedMethodId={setSelectedMethodId}
              vendorUserId={loginUserDetail.userId}
              vendorPaymentMethods={paymentMethods}
              updateVendorPaymentMethod={() => {
                fetchPaymentMethods();
              }}
              vendorUnitId={loginUserDetail.signleUnitId}
            />
          )}
        </div>

        <div class="tskElemHeding mt10">{t("terms_and_conditions")}</div>

        <div className="formBtnElement">
          <Button
            variant="outlined"
            startIcon={<Gavel />}
            className={`${
              allTermsAndConditions?.length > 0
                ? "dfultDarkSecondaryBtn"
                : "comBtnOutline"
            }`}
            onClick={() => {
              setShowTaCDialog(true);
            }}
          >
            {t("terms_and_conditions")}
          </Button>
        </div>

        <div class="tskElemHeding mt10">{t("notes_special_instruction")}</div>

        <div className="formElementGrp">
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label={t("notes_special_instruction")}
                // required
                placeholder="Enter Notes"
                variant="outlined"
                className="formTextFieldArea"
                autoComplete="off"
                value={specialNote}
                // InputLabelProps={{ shrink: email !== "" ? true : false }}
                onChange={(e) => {
                  setSpecialNote(e.target.value);
                }}
              />
            </FormControl>
          </div>
        </div>

        <Button
          ref={taxRef}
          style={{ display: "none" }}
          onClick={(e) => {
            // const selectedBankingMethod = paymentMethods?.find(
            //   (item) => item.id === selectedMethodId
            // );
            const SellerUnitName = unitsData?.find(
              (item) => item.unitId === emplyeeUnits
            );
            const BuyerName = contractData?.find(
              (item) => item.userId === selectedContractId
            );

            const isItemPresentWithDifferentType = tableData?.some(
              (item) => item.item.type !== itemType
            );
            if (isItemPresentWithDifferentType) {
              toast.error(t("some_item_do_not_match_selected_item_type"));
              return;
            }

            const data = {
              sellerUnitId: emplyeeUnits,
              sellerName: SellerUnitName.unitName,
              sellerAdress: SellerUnitName.address,
              sellerTaxId: sellerTaxId,
              sellerEmailId:
                sellerEmailInputId !== "" ? sellerEmailInputId : sellerEmailId,
              buyerId: selectedContractId,
              buyerName: BuyerName ? BuyerName.userName : "",
              buyerAddress: BuyerName ? BuyerName.address : "",
              buyerTaxId: buyerTaxId,
              buyerEmailId: buyerEmailId,
              placeOfSupply: shipToData,
              invoiceDate: joiningdate,
              effectiveDate: effectiveDate,
              currency: defultCurrency,
              bankingMethod: selectedMethodId,
              // bankingMethod: selectedBankingMethod,
              allTermsAndConditions: allTermsAndConditions,
              tableData: tableData,
              poNumber,
              challanNumber,
              subTotalAmount,
              totalAmount,
              itemType,
              roundOffAmount,
              proformaInvoiceId:
                selectedPendingPorforma?.proformaInvoiceId || 0,
              note: specialNote || "",
            };
            console.log("value=", data);
            handleShowPreview(data);
          }}
        ></Button>

        {showTaCDialog && (
          <TermsAndConditions
            showTermsAndConditions={showTaCDialog}
            handelCloseLeaveBalanceModel={(e) => {
              setShowTaCDialog(false);
            }}
            conditionList={orgTacList}
            getTermsAndCondition={(data) => {
              setAllTermsAndConditions(data);
            }}
            selectedConditionList={selectedConditionList}
            setSelectedConditionList={setSelectedConditionList}
            userAddedTermsAndConditions={userAddedTermsAndConditions}
            setUserAddedTermsAndConditions={setUserAddedTermsAndConditions}
          />
        )}
      </>
    );
  }
);
