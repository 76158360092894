import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  TextField,
  Tooltip,
} from "@mui/material";
import React from "react";
import isAssignedByMe from "../../../taskUtil";
import { XLg } from "react-bootstrap-icons";
import { useState } from "react";
import { useEffect } from "react";
// import ReactDatePicker from "react-datepicker";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { useMemo } from "react";
import DashboardServices from "../../../../../services/DashboardServices";
import { GetLoginUserDetails } from "../../../../../utils/Utils";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { enIN, es } from "date-fns/locale";

// Register locales with ReactDatePicker
registerLocale("en", enIN);
registerLocale("es", es);

function RightSidePostpone({ selectedTasks, handleClose, refreshData }) {
  const loginUserDetail = GetLoginUserDetails();
  const { t, i18n } = useTranslation();
  const [postponeDataList, setPostponeDataList] = useState([]);

  // Determine the locale based on the current language
  const currentLocale = i18n.language === "es" ? "es" : "en";

  const shouldShowSubmitButton = useMemo(() => {
    let valid = true;
    for (let item of postponeDataList) {
      if (item.postponeTill === null || item.reason.trim() === "") {
        valid = false;
        break;
      }
    }
    return valid;
  }, [postponeDataList]);

  useEffect(() => {
    setPostponeDataList(
      selectedTasks.map((task) => ({
        reason: "",
        postponeTill: null,
      }))
    );
  }, []);

  const postponeTasks = async () => {
    const res = await Promise.all(
      selectedTasks.map((task, index) => {
        return new Promise((resolve, reject) => {
          const reqDto = {
            postponedReason: postponeDataList[index].reason,
            postponedTill: postponeDataList[index].postponeTill,
          };
          DashboardServices.postponedTask(
            task.taskId,
            loginUserDetail.userId,
            reqDto
          ).then((response) => {
            if (response.data && response.data.returnValue === "1") {
              resolve("ok");
            } else {
              resolve(null);
              // toast.error(`Could not raise concern for ${task.taskName}`);
              toast.error(
                t("could_not_raise_concern", { taskName: task.taskName })
              );
            }
          });
        });
      })
    );
    handleClose();
    refreshData();
  };

  return (
    <>
      <div className="taskViewPannel">
        <div className="taskViewContainer">
          <div className="taskHeaderPanel">
            <div className="taskTitleContrct">
              <div className="taskTitleNM">
                {t("postpone_tasks", { count: selectedTasks.length })}
              </div>
            </div>
            <div className="taskControlBtnGrp"></div>

            <div className="taskActionBtnGrp">
              <div className="taskActBtn closeBtnD10">
                <IconButton aria-label="close" onClick={handleClose}>
                  <Tooltip title={t("close")} arrow>
                    <XLg />
                  </Tooltip>
                </IconButton>
              </div>
            </div>
          </div>

          <div className="taskContain">
            <div className="taskContainDetail">
              <div className="taskContDtlContainer">
                {selectedTasks &&
                  selectedTasks.length > 0 &&
                  selectedTasks.map((task, index) => {
                    return (
                      <>
                        <div className="multipleUpdateTaskCard">
                          <div className="taskTitleContrct">
                            <div className="taskHeadingNM">{task.taskName}</div>
                          </div>
                          <div className=" commonUpdate">
                            <div className="formElement">
                              <FormControl className="formControl">
                                <TextField
                                  // sx={{ color: "white" }}
                                  label={t("postpone_reason")}
                                  required
                                  variant="outlined"
                                  className="descptionTextFild"
                                  multiline
                                  rows={3}
                                  maxRows={7}
                                  value={
                                    postponeDataList &&
                                    postponeDataList.length > 0 &&
                                    postponeDataList[index].reason
                                  }
                                  // value={reason}
                                  onChange={(e) => {
                                    const temp = [...postponeDataList];
                                    temp[index].reason = e.target.value;
                                    setPostponeDataList(temp);
                                  }}
                                />
                              </FormControl>
                            </div>

                            <div className="formElement">
                              <FormControl className="formControl">
                                <InputLabel
                                  id="setCompletionDate"
                                  className="setCompletionDate"
                                >
                                  {t("postpone_till")}*
                                </InputLabel>
                                <ReactDatePicker
                                  locale={currentLocale}
                                  showIcon
                                  labelId="setCompletionDate"
                                  className="formDatePicker"
                                  selected={
                                    postponeDataList &&
                                    postponeDataList.length > 0 &&
                                    postponeDataList[index].postponeTill
                                  }
                                  minDate={
                                    new Date(
                                      new Date().setDate(
                                        new Date().getDate() + 1
                                      )
                                    )
                                  }
                                  dateFormat="dd/MM/yyyy"
                                  onChange={(date) => {
                                    console.log("new date = ", date);
                                    const temp = [...postponeDataList];
                                    temp[index].postponeTill = date;
                                    setPostponeDataList(temp);
                                  }}
                                />
                              </FormControl>
                            </div>

                            <div
                              className="tskCretdByElmnt"
                              style={{ color: "#fff" }}
                            >
                              <span className="assuTag">
                                {t("created_by")} :
                              </span>
                              <span className="assuName">
                                {task && isAssignedByMe(task.createdByUserId)
                                  ? t("me")
                                  : task.createdByUserName}
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="taskFooterPanel">
            <div className="formBtnElement">
              <Button
                disabled={!shouldShowSubmitButton}
                className="dfultPrimaryBtn"
                onClick={(e) => {
                  postponeTasks();
                }}
              >
                {t("postpone")}
              </Button>
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={(e) => handleClose()}
              >
                {t("cancel")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RightSidePostpone;
