import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useMemo, useReducer, useState } from "react";
import { GoodsServicesIncomingForm } from "./GoodsServicesIncomingForm";
import { Plus, Trash, XLg } from "react-bootstrap-icons";
import {
  AttachFile,
  CloseFullscreen,
  DownloadForOfflineOutlined,
  HelpOutline,
  OpenInFull,
  Send,
} from "@mui/icons-material";
import { Controller } from "react-hook-form";
import TaskService from "../../../../../services/TaskService";
import { GetLoginUserDetails } from "../../../../../utils/Utils";
// import ReactDatePicker from "react-datepicker";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { toast } from "react-toastify";
import { TermsAndConditions } from "./TermsAndConditions";
import DateUtils from "../../../../../utils/DateUtils";
import { AddQuotation } from "./AddQuotation";
import { CustomModal } from "../Modals/CustomModal";
import { QuotationContainer } from "./QuotationContainer";
import { useTranslation } from "react-i18next";
import { enIN, es } from "date-fns/locale";

// Register locales with ReactDatePicker
registerLocale("en", enIN);
registerLocale("es", es);

export const RfpForm = ({ formData, handleClose, taskId, taskNature }) => {
  const fullScreenStyle = {
    width: "100%",
  };

  const { t, i18n } = useTranslation();
  const loginUserDetail = GetLoginUserDetails();
  const [orgVendorList, setOrgVendorList] = useState([]);
  const [orgTacList, setOrgTacList] = useState([]);
  const [selectedConditionList, setSelectedConditionList] = useState([]);
  const [defaultSlNo, setDefaultSlNo] = useState(0);
  const [selectableVendorList, setSelectableVendorList] = useState([]);
  const [selectedVendorList, setSelectedVendorList] = useState([]);
  const [selectedConvenientTime, setSelectedConvenientTime] = useState(
    new Date()
  );
  const [showTaCDialog, setShowTaCDialog] = useState({
    status: false,
    names: "",
  });
  const [completion, setCompletion] = useState(new Date());

  // Determine the locale based on the current language
  const currentLocale = i18n.language === "es" ? "es" : "en";

  const [hasSeenTAC, setHasSeenTAC] = useState(false);
  const [isCheckTac, setIsCheckTac] = useState(false);
  const [rfpTacList, setRfpTacList] = useState(null);
  const [comparisonDataList, setComparisonDataList] = useState([]);
  const [indentDetails, setIndentDetails] = useState(undefined);
  const [uniqueVendorList, setUniqueVendorList] = useState([]);
  const [tacViewedList, setTacViewList] = useState();

  const [selectedCostCenter, setSelectedCostCenter] = useState();
  const [selectedItemsIdList, setSelectedItemsIdList] = useState([]);
  const [selectedItemsList, setSelectedItemsList] = useState([]);
  const [showComparisonDialog, setShowComparisonDialog] = useState(false);
  const [rfpVendorIdForTAC, setRfpVendorIdForTAC] = useState(null);
  const [showRfpTac, setShowRfpTac] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState("");
  const [uniqueItemList, setUniqueItemList] = useState([]);
  const [reasonChoseVendorItem, setReasonChoseVendorItem] = useState("");
  const [tAcModalCheck, setTacModalCheck] = useState(false);

  const [showAddQuotationDialog, setShowAddQuotationDialog] = useState({
    status: false,
    vendorDetails: undefined,
  });

  const [selectedIndentItems, setSelectedIndentItems] = useState([]);

  const initialState = {
    isExpanded: false,
    formDataCopy: null,
    recurring: false,
    recurringPeriodList: [],
    repeatUntil: new Date(),
    recurringDocument: null,
    contractList: [],
    costCenterList: [],
    billToShipToList: [],
    orgAddressList: [],
    positionList: [],
    capexOpex: "",
    ProcessDirect: "",
  };

  const [ledgerHeadList, setLedgerHeadList] = useState([]);
  const [rfpVendorList, setRfpVendorList] = useState([]);
  const [TacByRfp, setTacByRfp] = useState([]);

  const reducer = (state, action) => {
    switch (action.type) {
      case "expandOrShrinkWindow":
        return { ...state, isExpanded: !state.isExpanded };

      case "addItemToManpower":
        let tempAddToManpower = [
          ...state.formDataCopy.manPowers,
          action.payload,
        ];
        // tempGoodsAndServices.push(action.payload);
        return {
          ...state,
          formDataCopy: {
            ...state.formDataCopy,
            manPowers: tempAddToManpower,
          },
        };

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const filteredBudgetHeadList = useMemo(() => {
    if (state.capexOpex === "CapitalExpenditure") {
      return ledgerHeadList.filter(
        (item) => item.category === "Capital Expense"
      );
    } else {
      return ledgerHeadList.filter(
        (item) => item.category === "Operating Expense"
      );
    }
  }, [ledgerHeadList, state.capexOpex]);

  const fetchRFPVendorData = () => {
    TaskService.getRfpVendors(taskId).then((response) => {
      if (response.data) {
        setRfpVendorList(response.data);
      }
    });
  };

  const handleClickAllItemsVendor = (vendorUserId) => {
    if (selectedVendor === vendorUserId) {
      setSelectedVendor("");
      setSelectedItemsList([]);
      setSelectedItemsIdList([]);
    } else {
      setSelectedVendor(vendorUserId);

      const dataList = comparisonDataList.filter(
        (item) => item.vendorUserId === vendorUserId
      );

      let tmpList = [];
      if (dataList.length > 0) {
        for (let i = 0; i < dataList.length; i++) {
          const element = dataList[i];
          tmpList.push(element.vendorQuotationId);
        }

        setSelectedItemsList(tmpList);
      }
    }
  };

  const handleClickSubmitChoseVendorItem = () => {
    if (reasonChoseVendorItem === "") {
      toast.error(t("please_enter_reason"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      const reqDto = {
        recommendationReason: reasonChoseVendorItem,
        vendorQuotationIds: selectedItemsList,
        orgId: loginUserDetail.orgId,
      };

      // return;

      // setLoading(true);
      TaskService.sendApprovalOfVendor(
        taskId,
        formData.expenseId,
        loginUserDetail.userId,
        reqDto
      ).then((response) => {
        if (response.data) {
          if (response.data.returnValue === "1") {
            toast.success(
              t("vendor_approval_has_been_successfully_submitted"),
              {
                position: toast.POSITION.TOP_RIGHT,
              }
            );
            setReasonChoseVendorItem("");
            setSelectedItemsList([]);
            setSelectedItemsIdList([]);
            setShowComparisonDialog(false);
            // handelSuccessSubmitData();
          } else {
            toast.error(
              t(
                "there_was_an_issue_submitting_the_vendor_approval_Please_try_again"
              ),
              {
                position: toast.POSITION.TOP_RIGHT,
              }
            );
          }
          setTacViewList(undefined);
          // setLoading(false);
        }
      });
    }
  };

  const renderItemDetails = (itemId, vendorUserId) => {
    const data = comparisonDataList.find(
      (item) => item.vendorUserId === vendorUserId && item.itemId === itemId
    );
    if (data) {
      return (
        <>
          <td>{data.quotationQty}</td>
          <td>{data.quotationRate}</td>
          <td style={{ textAlign: "center" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    selectedItemsList.length > 0 &&
                    selectedItemsList.includes(data.vendorQuotationId)
                  }
                />
              }
              onClick={() =>
                handleClickEachItem(data.vendorQuotationId, data.itemId)
              }
            />
          </td>
        </>
      );
    } else {
      return <td colSpan={3}></td>;
    }
  };
  const handleClickEachItem = (vendorQuotationId, itemId) => {
    if (selectedVendor !== "") {
      toast.error(t("you_select_all_items_from_a_vendor"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      let tmpList = selectedItemsList;
      let tmpIdList = selectedItemsIdList;
      //

      if (tmpList.find((item) => item === vendorQuotationId)) {
        tmpList = tmpList.filter((item) => item !== vendorQuotationId);
        tmpIdList = tmpIdList.filter((id) => id !== itemId);
      } else if (tmpIdList.includes(itemId)) {
        toast.error(t("item_already_selected_from_another_vendor"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        tmpList = [...tmpList, ...[vendorQuotationId]];
        tmpIdList = [...tmpIdList, ...[itemId]];
      }
      setSelectedItemsList(tmpList);
      setSelectedItemsIdList(tmpIdList);
    }
  };

  const fetchQuotationComparisonData = () => {
    if (taskNature === "SUBMIT_RFP") {
      TaskService.getQuotationComparison(formData.expenseId).then(
        (response) => {
          if (response.data) {
            const data = response.data;

            const vendorsUniqueByKey = [
              ...new Map(
                data.map((item) => [
                  item["vendorUserId"],
                  {
                    vendorName: item.vendorName,
                    vendorUserId: item.vendorUserId,
                    subTotal: item.subTotal,
                    total: item.total,
                    shippingAndOtherCharges: item.shippingAndOtherCharges,
                    advancePc: item.advancePc,
                    fileLink: item.fileLink,
                    fileName: item.fileName,
                    rfpVendorId: item.rfpVendorId,
                  },
                ])
              ).values(),
            ];
            const itemsUniqueByName = [
              ...new Map(
                data.map((item) => [
                  item["itemId"],
                  {
                    itemId: item.itemId,
                    itemName: item.itemName,
                    itemDescription: item.itemDescription,
                    varianceSpeciation: item.varianceSpeciation,
                    variance: item.variance,
                  },
                ])
              ).values(),
            ];

            setComparisonDataList(data);
            setUniqueVendorList(vendorsUniqueByKey);
            const temp = Array.apply(
              null,
              Array(vendorsUniqueByKey.length)
            ).map(function () {
              return false;
            });
            setTacViewList(temp);

            //
            setUniqueItemList(itemsUniqueByName);
          }
        }
      );
    }
  };

  const handleClickCompareData = () => {
    //  default less price check start
    //
    let lessPriceList = [];

    for (let i = 0; i < comparisonDataList.length; i++) {
      const { itemId, quotationRate, vendorQuotationId } =
        comparisonDataList[i];

      if (lessPriceList.length === 0) {
        lessPriceList.push({
          itemId,
          quotationRate,
          vendorQuotationId,
        });
      } else {
        const duplicateData = lessPriceList.find(
          (obj) => obj.itemId === itemId
        );
        if (duplicateData) {
          if (duplicateData.quotationRate > quotationRate) {
            const ind = lessPriceList.findIndex(
              (obj) => JSON.stringify(obj) === JSON.stringify(duplicateData)
            );
            lessPriceList[ind]["quotationRate"] = quotationRate;
            lessPriceList[ind]["vendorQuotationId"] = vendorQuotationId;
          }
        } else {
          lessPriceList.push({
            itemId,
            quotationRate,
            vendorQuotationId,
          });
        }
      }
    }

    let idList = [];
    let qutId = [];
    if (lessPriceList.length > 0) {
      for (let i = 0; i < lessPriceList.length; i++) {
        const { itemId, vendorQuotationId } = lessPriceList[i];
        idList.push(itemId);
        qutId.push(vendorQuotationId);
      }
    }
    setSelectedItemsIdList(idList);
    setSelectedItemsList(qutId);
    //  default less price check end
    setShowComparisonDialog(true);
  };

  const handleSubmitRfpToVendors = () => {
    if (selectedVendorList.length === 0) {
      toast.error(t("please_select_vendor"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (
      DateUtils.getDifferenceInMinutes(
        new Date(new Date().setHours(0, 0, 0, 0)),
        completion
      ) < 0
    ) {
      toast.error(t("please_select_correct_response_date"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    // else if (selectedConditionList.length < 1) {
    //   toast.error(`Please Select Term and Condition`, {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    // }
    else if (!rfpTacList || rfpTacList.length === 0) {
      toast.error(t("please_select_terms_and_conditions"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      let tmpIdList = [];
      for (let i = 0; i < selectedVendorList.length; i++) {
        const { userId } = selectedVendorList[i];
        tmpIdList = [...tmpIdList, ...[userId]];
      }
      const reqDto = {
        termsConditions: rfpTacList,
        vendorUserIds: tmpIdList,
        taskDetailId: taskId,
        indentId: formData.expenseId,
        responseBefore: DateUtils.getDateInDDMMYYYY(completion),
        orgId: loginUserDetail.orgId,
      };

      // console.log("reqDto=", reqDto);

      // return;
      TaskService.sendRfpToVendors(loginUserDetail.userId, reqDto).then(
        (response) => {
          if (response.data) {
            if (response.data.returnValue === "1") {
              toast.success(
                t("the_rpf_has_been_successfully_sent_to_the_vendors"),
                {
                  position: toast.POSITION.TOP_RIGHT,
                }
              );
              setSelectedVendorList([]);
              fetchOrgTermsAndConditionData();
              setIsCheckTac(false);
              setHasSeenTAC(false);
              fetchRFPVendorData();
            } else {
              toast.error(
                t("failed_to_send_the_request_for_proposal_please_try_again"),
                {
                  position: toast.POSITION.TOP_RIGHT,
                }
              );
            }
          }
        }
      );
    }
  };

  const handleShowTaCDialog = (e) => {
    let vendorNames = "";
    let tmpIdList = [];
    if (!hasSeenTAC) {
      setSelectedConditionList([...orgTacList]);
    }
    if (selectedVendorList.length > 0) {
      for (let i = 0; i < selectedVendorList.length; i++) {
        const { userId, userName } = selectedVendorList[i];
        tmpIdList.push(userId);
        vendorNames += `${userName}, `;
      }
      vendorNames = vendorNames.substring(0, vendorNames.length - 2);
      setShowTaCDialog({
        status: e.target.checked,
        idList: tmpIdList,
        names: vendorNames,
      });
      setTacModalCheck(e.target.checked);
      setHasSeenTAC(true);
    } else {
      toast.error(t("please_select_vendor"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleShowAddQuotationDialog = (value) => {
    //
    let indentItems = [];
    for (let i = 0; i < formData.serviceGoods.length; i++) {
      const element = formData.serviceGoods[i];
      element["quotationQty"] = element.indentQty;
      element["quotationRate"] = element.estimatedRate;
      element["quotationValue"] = element.estimatedValue;
      element["varianceSpeciation"] = "";
      element["variance"] = "";
      element["sgstPc"] = 0;
      element["cgstPc"] = 0;
      element["igstPc"] = 0;
      element["taxPc"] = 0;
      element["sgstAmt"] = 0;
      element["cgstAmt"] = 0;
      element["igstAmt"] = 0;
      element["taxAmt"] = 0;
      element["quotationUnitCost"] = 0;
      element["newQuotationValue"] = 0;
      indentItems.push(element);
    }
    setShowAddQuotationDialog({
      status: true,
      vendorDetails: value,
    });
    setSelectedIndentItems(indentItems);
  };

  const fetchOrgTermsAndConditionData = () => {
    TaskService.getOrgTermsAndConditions(loginUserDetail.orgId, "RFP").then(
      (response) => {
        if (response.data) {
          const tmpCondList = response.data;
          setOrgTacList(tmpCondList);
          setSelectedConditionList(tmpCondList);
          setDefaultSlNo(tmpCondList.length + 1);
        }
      }
    );
  };

  useEffect(() => {
    fetchQuotationComparisonData();
  }, [indentDetails]);

  useEffect(() => {
    //
    if (taskNature === "SUBMIT_RFP") {
      // TaskService.getIndentByIndentId(formData.expenseId).then((response) => {
      //   if (response.data) {
      //     //
      //     setIndentDetails(response.data);
      //     setSelectedCostCenter(response.data.costCenterId);
      //   }
      // });

      TaskService.getVendorOfOrg(loginUserDetail.orgId).then((response) => {
        if (response.data) {
          setOrgVendorList(response.data);
        }
      });

      fetchRFPVendorData();
      fetchOrgTermsAndConditionData();
    }
  }, []);

  useEffect(() => {
    if (orgVendorList.length > 0) {
      let tmpList = orgVendorList;
      if (orgVendorList.length > 0 && rfpVendorList.length > 0) {
        for (let i = 0; i < rfpVendorList.length; i++) {
          const { userId } = rfpVendorList[i];
          tmpList = tmpList.filter((obj) => obj.userId !== userId);
        }
      }
      setSelectableVendorList(tmpList);
    }
  }, [orgVendorList, rfpVendorList]);

  const handleChangeSelectVender = (data) => {
    let tmpList = selectedVendorList;
    if (data.length === 0) {
      tmpList = [];
      setHasSeenTAC(false);
    } else if (tmpList.includes(data)) {
      tmpList = tmpList.filter((obj) => obj !== data);
    } else {
      tmpList = [...data];
    }
    setSelectedVendorList(tmpList);
    tmpList.length === 0 && setIsCheckTac(false);
  };

  return (
    <>
      <div
        className="RightFlotingFormContainerArea"
        style={state.isExpanded ? fullScreenStyle : {}}
      >
        <div className="flottingContainer">
          <div className="flotHeaderPanel">
            <div class="flotHedCon">{t("rfp_preview")}</div>
            <div class="flotActionBtnGrp">
              <div class="flotActBtn closeBtnD10">
                <Tooltip
                  arrow
                  title={
                    state.isExpanded
                      ? t("exit_full_screen")
                      : t("enter_full_screen")
                  }
                >
                  <IconButton
                    onClick={(e) => {
                      dispatch({ type: "expandOrShrinkWindow" });
                    }}
                  >
                    {state.isExpanded ? <CloseFullscreen /> : <OpenInFull />}
                    {/* <Expand /> */}
                  </IconButton>
                </Tooltip>
              </div>
              <div class="flotActBtn closeBtnD10">
                <IconButton onClick={handleClose}>
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>
          <div className="flotContain">
            <div className="elementFormContainer previewSpace ">
              <div className="indentFormtable">
                <div className="indtableResponsive">
                  <table>
                    <thead>
                      <tr>
                        <th className="w200">{t("indent_no")}</th>
                        <th>{t("purpose")}</th>
                        <th>Schedule Date</th>
                        <th className="indntQunt">{t("created_by")}</th>
                        <th className="widthMin120">{t("approved_by")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <td>
                        <div className="textOnly">{formData.expenseNo}</div>
                      </td>
                      <td>
                        <div className="textOnly">{formData.purpose}</div>
                      </td>
                      <td>
                        <div className="textOnly">{formData.scheduledDate}</div>
                      </td>
                      <td>
                        <div className="textOnly">{formData.createdByName}</div>
                      </td>
                      <td>
                        <div className="textOnly">
                          {formData.approvedByName}
                        </div>
                      </td>
                    </tbody>
                    <tfoot></tfoot>
                  </table>
                </div>
                <div style={{ margin: "40px 0" }}></div>
                <div className="indtableResponsive">
                  <table>
                    <thead>
                      <tr>
                        <th className="w200">
                          {formData && formData.expenseType === "services"
                            ? t("service_name")
                            : t("item_name") + "*"}
                        </th>
                        <th>
                          <Tooltip
                            arrow
                            title={t("description_specification_mandatory")}
                          >
                            {t("desc_spec")}
                          </Tooltip>
                        </th>
                        <th>{t("budget_head")} *</th>
                        <th>{t("uom")} *</th>
                        {/* {state.formDataCopy &&
                  state.formDataCopy.expenseType === "services" && (
                    )} */}
                        <th className="StockQty">{t("stock_qty")}</th>
                        {/* <th className="widthMin120">
              <Tooltip arrow title="Previous Purchase Rate">
                Prev. Purc. Rate
              </Tooltip>
            </th> */}
                        <th className="indntQunt">{t("indent_qty")}. *</th>
                        <th className="widthMin120">{t("estimated_rate")} *</th>
                        <th className="widthMin120">{t("estimated_value")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {formData.serviceGoods &&
                        formData.serviceGoods.map((item) => {
                          return (
                            <tr>
                              <td>
                                <div className="textOnly">{item.itemName}</div>
                              </td>
                              <td>
                                <div className="textOnly">
                                  {item.itemDescription}
                                </div>
                              </td>
                              <td>
                                <div className="textOnly">
                                  {item.budgetHeadName}
                                </div>
                              </td>
                              <td>
                                <div className="textOnly">{item.uom}</div>
                              </td>
                              <td>
                                <div className="textOnly">
                                  {item.currentStk}
                                </div>
                              </td>
                              <td>
                                <div className="textOnly">{item.indentQty}</div>
                              </td>
                              <td>
                                <div className="textOnly">
                                  {item.estimatedRate}
                                </div>
                              </td>
                              <td>
                                <div className="textOnly">
                                  {item.estimatedValue}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                    <tfoot></tfoot>
                  </table>
                </div>
                <div style={{ margin: "40px 0" }}></div>

                <div className="formElementGroup">
                  <div className="formElement">
                    <FormControl className="formControl">
                      <Autocomplete
                        noOptionsText={t("please_type_recipient_name")}
                        multiple
                        className="formAutocompleteField"
                        variant="outlined"
                        value={selectedVendorList}
                        options={selectableVendorList}
                        getOptionLabel={(option) => option.userName}
                        onChange={(e, data) => handleChangeSelectVender(data)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="formAutoComInputField autocompFildPlaceholder"
                            required
                            label={t("select_vendors")}
                            size="small"
                          />
                        )}
                      />
                    </FormControl>
                  </div>
                </div>
                <div className="formElementGroup">
                  <div className="formElement width13">
                    <FormControl className="formControl">
                      <InputLabel
                        id="setResponseDate"
                        className="setCompletionDate"
                      >
                        {t("response_date")}*
                      </InputLabel>
                      <ReactDatePicker
                        locale={currentLocale}
                        toggleCalendarOnIconClick
                        showYearDropdown
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown
                        showMonthDropdown
                        showIcon
                        labelId="setResponseDate"
                        className="formDatePicker"
                        selected={completion}
                        minDate={new Date()}
                        dateFormat="dd/MM/yyyy"
                        onChange={(date) => {
                          setCompletion(date);
                        }}
                      />
                    </FormControl>
                  </div>
                  <div className="formElement height36">
                    <FormControl className="formControl">
                      <FormGroup className="formRadioGroup">
                        <FormControlLabel
                          className="formRadioField"
                          control={
                            <Checkbox
                              checked={tAcModalCheck}
                              onChange={(e) => {
                                if (e.target.checked === false) {
                                  setTacModalCheck(e.target.checked);
                                  return;
                                }
                                handleShowTaCDialog(e);
                              }}
                            />
                          }
                          label={t("terms_and_condition")}
                        />
                      </FormGroup>
                    </FormControl>
                  </div>

                  {/* <div className="formElement">
                    <div className={`formBtnElement`}>
                      <Button
                        variant="outlined"
                        startIcon={<AttachFile />}
                        className={`comBtnOutline ${
                          hasSeenTAC === true ? "activeStatus" : ""
                        } `}
                        onClick={handleShowTaCDialog}
                      >
                        T & C
                      </Button>
                    </div>
                  </div> */}
                </div>
                <div className="formElementGroup">
                  <div className="formElement">
                    <div className={`formBtnElement`}>
                      <Button
                        variant="outlined"
                        startIcon={<Send />}
                        className={`comBtnOutline  `}
                        onClick={handleSubmitRfpToVendors}
                      >
                        {t("send")}
                      </Button>
                    </div>
                  </div>
                </div>

                <div style={{ margin: "40px 0" }}></div>

                <div className="indtableResponsive">
                  <table>
                    <thead>
                      <tr>
                        <th className="w200">{t("name")}</th>
                        <th>{t("status")}</th>
                        <th>{t("action")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rfpVendorList &&
                        rfpVendorList.map((vendor, index) => {
                          return (
                            <tr>
                              <td>
                                <div className="textOnly">
                                  {vendor.userName}
                                </div>
                              </td>
                              <td>
                                <div className="textOnly">
                                  {vendor.status &&
                                    `${
                                      vendor.status === "P"
                                        ? t("rfp_sent_proposal_awaited")
                                        : ``
                                    }${
                                      vendor.status === "R"
                                        ? t("quotation_submitted")
                                        : ``
                                    }`}
                                </div>
                              </td>
                              <td>
                                <div className="textOnly">
                                  {vendor.status === "P" && (
                                    <Tooltip
                                      title={t("add_quotation")}
                                      placement="left"
                                    >
                                      <IconButton
                                        onClick={(e) => {
                                          handleShowAddQuotationDialog(vendor);
                                        }}
                                      >
                                        <Plus cursor={"pointer"} color="blue" />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                    <tfoot></tfoot>
                  </table>
                </div>
                <div style={{ margin: "40px 0" }}></div>

                {comparisonDataList.length > 0 && (
                  <div>
                    <Button
                      className="dfultPrimaryBtn"
                      onClick={handleClickCompareData}
                    >
                      {t("compare")}
                    </Button>
                  </div>
                )}
              </div>
              {/* <div className="indentFormtable"></div> */}
            </div>
          </div>
          <div className="flotFooterPanel">
            <div className="formBtnElement">
              {/* <Button
                className="dfultPrimaryBtn"
                // onClick={handleApproveRequest}
              >
                Approve Request
              </Button> */}
              <Button className="dfultDarkSecondaryBtn" onClick={handleClose}>
                {t("close")}
              </Button>
            </div>
          </div>
        </div>
      </div>
      {showTaCDialog && showTaCDialog.status && (
        <TermsAndConditions
          showTermsAndConditions={showTaCDialog.status}
          handelCloseLeaveBalanceModel={(e) => {
            setShowTaCDialog((prev) => ({ ...prev, status: false }));
          }}
          conditionList={orgTacList}
          userNames={showTaCDialog.names}
          getTermsAndCondition={(data) => {
            setRfpTacList(data);
          }}
          selectedConditionList={selectedConditionList}
          setSelectedConditionList={setSelectedConditionList}
        />
      )}

      {showAddQuotationDialog && showAddQuotationDialog.status && (
        // <>
        //   <div className="modelContainer" style={{ zIndex: "9999" }}>
        //     <Box className="modelContain animated width90vwModel  ">
        //       <IconButton
        //         className="closeModelIoc"
        //         onClick={(e) => {
        //           setShowAddQuotationDialog((prev) => ({
        //             ...prev,
        //             status: false,
        //           }));
        //         }}
        //       >
        //         <XLg />
        //       </IconButton>

        //       <div className="fwModelContainArea">
        //         <div className="modHeader">
        //           <div className="modHeaderText">Add Quotation </div>
        //         </div>

        //         <div className="modBody">
        //           <AddQuotation
        //             itemList={selectedIndentItems}
        //             setItemList={setSelectedIndentItems}
        //             rfpVendorId={
        //               showAddQuotationDialog.vendorDetails.rfpVendorId
        //             }
        //             vendorUserId={showAddQuotationDialog.vendorDetails.userId}
        //             handleSubmitData={() => {
        //               fetchRFPVendorData();
        //               fetchQuotationComparisonData();
        //               setShowAddQuotationDialog({
        //                 status: false,
        //                 vendorDetails: undefined,
        //               });
        //             }}
        //             expenseId={formData.expenseId}
        //             closeModal={() => {
        //               setShowAddQuotationDialog((prev) => ({
        //                 ...prev,
        //                 status: false,
        //               }));
        //             }}
        //           />
        //         </div>
        //       </div>
        //     </Box>
        //   </div>
        // </>
        <>
          <QuotationContainer
            itemList={selectedIndentItems}
            setItemList={setSelectedIndentItems}
            rfpVendorId={showAddQuotationDialog.vendorDetails.rfpVendorId}
            vendorUserId={showAddQuotationDialog.vendorDetails.userId}
            handleSubmitData={() => {
              fetchRFPVendorData();
              fetchQuotationComparisonData();
              setShowAddQuotationDialog({
                status: false,
                vendorDetails: undefined,
              });
            }}
            expenseId={formData.expenseId}
            closeModal={() => {
              setShowAddQuotationDialog((prev) => ({
                ...prev,
                status: false,
              }));
            }}
          />
        </>
      )}

      {showComparisonDialog && (
        <>
          <CustomModal
            modalTitle={t("compare_quotation")}
            style={{ zIndex: 10001 }}
            closeModal={() => {
              setShowComparisonDialog(false);
            }}
            className={"width90vwModel"}
          >
            <div className="modBody">
              <table className="hrActivityGroupTable">
                <thead>
                  <tr style={{ textAlign: "center" }}>
                    <th colSpan={4}>Details</th>
                    {uniqueVendorList.map((vend, index) => {
                      return (
                        <th colSpan={3} key={vend.vendorUserId}>
                          <div className="vendInfoComp">
                            <div>
                              <Button
                                onClick={() => {
                                  const temp = [...tacViewedList];
                                  temp[index] = true;
                                  setTacViewList([...temp]);

                                  TaskService.getTermsConditionsByRfpVendorId(
                                    vend.rfpVendorId
                                  ).then((response) => {
                                    console.log(
                                      "terms and conditions",
                                      response
                                    );
                                    setRfpVendorIdForTAC(vend.rfpVendorId);
                                    setTacByRfp(response.data);
                                    setShowRfpTac(true);
                                  });
                                }}
                                startIcon={<HelpOutline />}
                              >
                                {t("t_and_c")}
                              </Button>
                            </div>

                            <div>
                              {vend.vendorName}{" "}
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      selectedVendor === vend.vendorUserId
                                    }
                                  />
                                }
                                onClick={() =>
                                  handleClickAllItemsVendor(vend.vendorUserId)
                                }
                              />
                            </div>
                            <div>
                              <Tooltip
                                title={t("download_vendor_quotation")}
                                placement="top"
                              >
                                <Button
                                  disabled={vend.fileLink === "" ? true : false}
                                  startIcon={<DownloadForOfflineOutlined />}
                                  onClick={() => {
                                    fetch(vend.fileLink) // Replace with your public link
                                      .then((response) => response.blob())
                                      .then((blob) => {
                                        const url = URL.createObjectURL(blob);
                                        const link =
                                          document.createElement("a");
                                        link.href = url;
                                        link.download = vend.fileName; // Replace with the desired filename and extension
                                        link.click();
                                        URL.revokeObjectURL(url);
                                      });
                                  }}
                                >
                                  {t("download")}
                                </Button>
                              </Tooltip>
                            </div>
                          </div>
                        </th>
                      );
                    })}
                  </tr>

                  <tr style={{ textAlign: "center" }}>
                    <th>{t("item_name")}</th>
                    <th>{t("item_description")}</th>
                    <th>{t("variance_speciation")}</th>
                    <th>{t("variance")}</th>
                    {uniqueVendorList.map(() => {
                      return (
                        <>
                          <th>{t("quantity")}</th>
                          <th>{t("rate")}</th>
                          <th>{t("check")}</th>
                        </>
                      );
                    })}
                  </tr>
                </thead>

                <tbody>
                  {uniqueItemList.map((item) => {
                    return (
                      <tr>
                        <td>{item.itemName}</td>
                        <td>{item.itemDescription}</td>
                        <td>{item.varianceSpeciation}</td>
                        <td>{item.variance}</td>
                        {uniqueVendorList.map((vend) => {
                          return renderItemDetails(
                            item.itemId,
                            vend.vendorUserId
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <td rowSpan={4} colSpan={4}></td>

                    {uniqueVendorList.map((item, index) => (
                      <>
                        {/* {index > 0 && */}
                        <>
                          <td className="leftalign bgSubHead">
                            {t("sub_total")}
                          </td>
                          <td className="rightalign bgSubHead">
                            {item.subTotal}
                          </td>
                          <td rowSpan={4} colSpan={1}></td>
                        </>
                        {/* } */}
                      </>
                    ))}
                  </tr>
                  <tr>
                    {uniqueVendorList.map((item, index) => (
                      <>
                        <>
                          <td className="leftalign">{t("s_and_o_charges")}</td>
                          <td className="rightalign">
                            {item.shippingAndOtherCharges}
                          </td>
                        </>
                        {/* } */}
                      </>
                    ))}
                  </tr>
                  <tr>
                    {uniqueVendorList.map((item, index) => (
                      <>
                        {/* {index > 0 && */}
                        <>
                          <td className="leftalign bgFootHead">{t("total")}</td>
                          <td className="rightalign bgFootHead">
                            {item.total}
                          </td>
                        </>
                        {/* } */}
                      </>
                    ))}
                  </tr>
                  <tr>
                    {uniqueVendorList.map((item, index) => (
                      <>
                        <>
                          <td className="leftalign bgFootHeadlight">
                            {t("advance")}
                          </td>
                          <td className="rightalign bgFootHeadlight">
                            {item.advancePc}%
                          </td>
                        </>
                      </>
                    ))}
                  </tr>
                </tfoot>
              </table>

              <div>
                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      size="small"
                      className="modelTextFild"
                      disabled={selectedItemsList.length <= 0}
                      label={t("reason_for_selection")}
                      required
                      multiline
                      value={reasonChoseVendorItem}
                      onChange={(e) => setReasonChoseVendorItem(e.target.value)}
                    />
                  </FormControl>
                </div>
              </div>

              <div>
                <Button
                  className="dfultPrimaryBtn "
                  disabled={selectedItemsList.length <= 0}
                  onClick={() => {
                    let allview = false;
                    for (let i = 0; i < tacViewedList.length; i++) {
                      if (tacViewedList[i] === true) {
                        allview = true;
                      } else {
                        allview = false;
                        break;
                      }
                    }

                    if (allview === true) {
                      handleClickSubmitChoseVendorItem();
                    } else {
                      toast.error(t("please_view_all_t_and_c"), {
                        position: toast.POSITION.TOP_RIGHT,
                      });
                    }
                  }}
                >
                  {t("submit")}
                </Button>
              </div>
            </div>
          </CustomModal>
        </>
      )}

      {showRfpTac && (
        <CustomModal
          modalTitle={t("terms_and_conditions")}
          style={{ zIndex: 10001 }}
          closeModal={() => {
            setShowRfpTac(false);
          }}
          className={"width90vwModel"}
        >
          <div className="modBody">
            <div className="modResptable">
              <table>
                <thead>
                  <tr>
                    <th className="minWidth160">{t("added_by_vendor")}</th>
                    <th>{t("type")}</th>
                    <th>{t("heading")}</th>
                    <th>{t("terms_and_condition")}</th>
                    <th>{t("delete")}</th>
                  </tr>
                </thead>
                <tbody>
                  {TacByRfp &&
                    TacByRfp.map((condition, index) => (
                      <tr>
                        <td>
                          {condition.addedByVendor === "Y" ? t("yes") : t("no")}
                        </td>
                        <td>{condition.termsConditionsType}</td>
                        <td>{condition.termsConditionsHeading}</td>
                        <td>{condition.termsConditions}</td>
                        <td>
                          {" "}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Tooltip
                              title={t("delete_terms_and_conditions")}
                              placement="left"
                            >
                              <Button
                                onClick={() => {
                                  TaskService.deleteTermsAndConditions(
                                    condition.id,
                                    loginUserDetail.userId
                                  ).then((response) => {
                                    if (response.data.returnValue > 0) {
                                      toast.success(
                                        t(
                                          "terms_and_conditions_were_successfully_deleted"
                                        ),
                                        {
                                          position: toast.POSITION.TOP_RIGHT,
                                        }
                                      );

                                      TaskService.getTermsConditionsByRfpVendorId(
                                        rfpVendorIdForTAC
                                      ).then((response) => {
                                        console.log(
                                          "terms and conditions",
                                          response
                                        );
                                        setTacByRfp(response.data);
                                        setShowRfpTac(true);
                                      });
                                    }
                                  });
                                }}
                              >
                                <Trash />
                              </Button>
                            </Tooltip>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </CustomModal>
      )}
    </>
  );
};
