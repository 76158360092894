import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ArrowClockwise, XLg } from "react-bootstrap-icons";
import lrLogin from "../../images/login.png";
import {
  ArrowForward,
  CheckCircleOutline,
  Send,
  Visibility,
  VisibilityOff,
  Cancel,
  CheckCircle,
  CancelOutlined,
} from "@mui/icons-material";
import LoginSignupService from "../../../../services/LoginSignupService";
import { toast } from "react-toastify";
import {
  PERSONAL_ORG_ID,
  SELECT_MenuProps,
} from "../../../../constants/const_string";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetPassword } from "aws-amplify/auth";
import AppUtils from "../../../../utils/AppUtils";
import { GetLoginUserDetails } from "../../../../utils/Utils";
import { jwtDecode } from "jwt-decode";
import { getActiveOtpServices } from "../../../../services/AdminService";
import { useTranslation } from "react-i18next";
import SecureIndexedDB from "../../../../utils/IndexedDB";
import { AppInitializerContext } from "../../../../utils/AppInitializer";

export default function Register({ handelCloseLogRegContain, state }) {
  const { userLoginDetails, setUserLoginDetails } = useContext(
    AppInitializerContext
  );
  const navigate = useNavigate();
  const { t } = useTranslation();

  // register user password start

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [isValid, setIsValid] = useState(false);

  // to check if all password policy conditions are met
  useEffect(() => {
    const checkPasswordPolicy = () => {
      const hasUpperCase = newPassword.match(/[A-Z]/);
      const hasLowerCase = newPassword.match(/[a-z]/);
      const hasNumber = newPassword.match(/[0-9]/);
      const hasSpecialChar = /[!@#$%^&*(),.?":{}|~<>]/.test(newPassword);
      const isMinLength = newPassword.length >= 8;
      const isMatching = newPassword === confirmPassword;

      return (
        hasUpperCase &&
        hasLowerCase &&
        hasNumber &&
        hasSpecialChar &&
        isMinLength &&
        isMatching
      );
    };

    setIsValid(checkPasswordPolicy());
  }, [newPassword, confirmPassword]);

  const handleChangeNewPassword = (event) => {
    setNewPassword(event.target.value);
    getPasswordStrength(event.target.value);
  };

  const handleChangeConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const getPasswordStrength = (password) => {
    const meterBar = document.getElementById("meter-bar");
    const meterStatus = document.getElementById("meter-status");

    let strength = 0;

    if (password.match(/[A-Z]/)) {
      strength += 1;
    }

    if (password.match(/[a-z]/)) {
      strength += 1;
    }

    if (password.match(/[0-9]/)) {
      strength += 1;
    }

    if (password.match(/[!@#$%^&*(),.?":{}|~<>]/)) {
      strength += 1;
    }

    if (password.length >= 16) {
      strength += 1;
    }

    if (password.length < 8) {
      meterBar.style.background = "#a7a7a7";
      meterBar.style.width = "5%";
      meterStatus.style.color = "#a7a7a7";
      meterStatus.innerText = "Poor";
      return;
    }

    if (strength === 3) {
      meterBar.style.background = "#e6ae01";
      meterBar.style.width = "50%";
      meterStatus.style.color = "#e6ae01";
      meterStatus.innerText = "Medium";
    } else if (strength === 4) {
      meterBar.style.background = "#0f98e2";
      meterBar.style.width = "75%";
      meterStatus.style.color = "#0f98e2";
      meterStatus.innerText = "Strong";
    } else if (strength === 5) {
      meterBar.style.background = "#00ff33";
      meterBar.style.width = "100%";
      meterStatus.style.color = "#00ff33";
      meterStatus.innerText = "Very Strong";
    } else {
      meterBar.style.background = "#ff0000";
      meterBar.style.width = "25%";
      meterStatus.style.color = "#ff0000";
      meterStatus.innerText = "Weak";
    }
  };

  //signup section

  const [userName, setUserName] = useState(null);
  const [userLoginIdSU, setUserLoginIdSU] = useState(null);
  const [orgListSU, setOrgListSU] = useState([]);
  const [userPasswordSU, setUserPasswordSU] = useState(null);
  const [userConfPasswordSU, setUserConfPasswordSU] = useState(null);
  const [otpSU, setOtpSU] = useState(null);
  const [otpIdSU, setOtpIdSU] = useState(null);
  const [fetchingOrgListSU, setFetchingOrgListSU] = useState(false);
  const [selectOrgSU, setSelectOrgSU] = React.useState("");
  const [otpValidatedSU, setOtpValidatedSU] = useState(false);
  const [sendingOtpSU, setSendingOtpSU] = useState(false);
  const [validatingOtpSU, setValidatingOtpSU] = useState(false);

  const getAllOrgsAssociatedWithUserSU = async () => {
    // toast.info(
    //   "Otp has been sent successfully, please check your spam folder if not received"
    // );
    setFetchingOrgListSU(true);

    if (!userLoginIdSU || userLoginIdSU.trim() === "") {
      toast.error(t("please_enter_your_email_id"));
      setFetchingOrgListSU(false);
      return false;
    }

    if (!AppUtils.validateEmail(userLoginIdSU)) {
      toast.error(t("please_enter_valid_email_id"));
      setFetchingOrgListSU(false);
      return false;
    }

    if (!userName || userName.trim() === "") {
      toast.error(t("please_enter_your_name"));
      setFetchingOrgListSU(false);
      return false;
    }

    const resPerProfileExists =
      await LoginSignupService.checkIfPersonalProfileExists(
        userLoginIdSU.toLowerCase()
      );
    console.log(resPerProfileExists.data);
    if (resPerProfileExists.data.returnValue === "1") {
      toast.error(resPerProfileExists.data.message);
      setFetchingOrgListSU(false);
    } else {
      toast.info(t("sending_otp_please_wait"));
      const resSendOtp = await LoginSignupService.generateOtpForEmailValidation(
        userLoginIdSU.toLowerCase(),
        "NA"
      );

      if (resSendOtp.data.returnValue === "1") {
        toast.info(t("otp_sent_successfully"));
        setOtpIdSU(resSendOtp.data.message);
        setFetchingOrgListSU(false);
      } else {
        toast.error(t("something_went_wrong"));
      }

      console.log(resSendOtp.data);
    }
  };

  const validateOTPSU = async () => {
    if (!otpSU || otpSU.trim() === "") {
      toast.error(t("please_enter_otp"));
      return false;
    }
    setValidatingOtpSU(true);
    const resOTP = await LoginSignupService.validateOtp(otpIdSU, otpSU);
    if (resOTP.data.returnValue === "-1") {
      toast.error(t("fail_to_verify_otp"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setValidatingOtpSU(false);
    } else if (resOTP.data.returnValue === "0") {
      toast.error(t("fail_to_verify_otp"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setValidatingOtpSU(false);
    } else {
      toast.info(t("otp_verified_successfully"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setValidatingOtpSU(false);
      setOtpValidatedSU(true);
    }
  };

  const signupUser = async () => {
    const loggedInUserDtls = GetLoginUserDetails();
    if (loggedInUserDtls.orgId === PERSONAL_ORG_ID) {
      let reqDto = {
        userName:
          userName && userName !== "" && userName.replace(/\s+/g, " ").trim(),
        userEmailId: userLoginIdSU.toLowerCase(),
        userPassword: newPassword,
        userType: "ADMIN",
        loginMode: "WEB",
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        linkMeetingId: state?.eventData?.eventLink,
        // referralLinkId: null != refContactData ? refContactData.inviteLink : null,
      };
      console.log("signupUser(reqDto)", reqDto);
      setIsValid(false);
      const resSignup = await LoginSignupService.signupUser(reqDto);
      if (resSignup.data.loginReturnValue === "0") {
        toast.error(t("fail_to_sign_up"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.info(resSignup.data.loginMessage + ", rejoining the meeting...", {
          position: toast.POSITION.TOP_RIGHT,
        });

        let reqDto = {
          username: userLoginIdSU.toLowerCase() + "~" + PERSONAL_ORG_ID,
          password: newPassword,
        };

        console.log("signupdto", reqDto, state);
        const resLogin = await LoginSignupService.userLogin(reqDto);
        localStorage.clear();
        SecureIndexedDB.setItem("token", resLogin.data.token);
        const tokenData = jwtDecode(resLogin.data.token);
        setUserLoginDetails(tokenData);
        SecureIndexedDB.setItem("userProfileImg", tokenData.userProfileImage);
        if (tokenData.orgId) {
          getActiveOtpServices(tokenData.orgId).then((response) => {
            console.log("responseotpservicedata ===1200", response.data);
            if (Array.isArray(response.data) && response.data.length > 0) {
              SecureIndexedDB.setItem(
                "otpServices",
                JSON.stringify(response.data)
              );
            }
          });
        }
        navigate(`/dashboard`, {
          state: state,
        });
      }
    } else {
      let reqDto = {
        userName:
          userName && userName !== "" && userName.replace(/\s+/g, " ").trim(),
        userEmailId: userLoginIdSU.toLowerCase(),
        userPassword: newPassword,
        userType: null,
        loginMode: "WEB",
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        linkMeetingId: state?.eventData?.eventLink,
        orgId: loggedInUserDtls.orgId,
        // referralLinkId: null != refContactData ? refContactData.inviteLink : null,
      };
      console.log("signupUser(reqDto)", reqDto);

      // return;
      setIsValid(false);
      const resSignup = await LoginSignupService.createAccountOrgUser(reqDto);
      if (resSignup.data.loginReturnValue === "0") {
        toast.error(resSignup.data.loginMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.info(resSignup.data.loginMessage + ", rejoining the meeting...", {
          position: toast.POSITION.TOP_RIGHT,
        });

        let reqDto = {
          username: userLoginIdSU.toLowerCase() + "~" + loggedInUserDtls.orgId,
          password: newPassword,
        };

        console.log("signupdto", reqDto, state);
        const resLogin = await LoginSignupService.userLogin(reqDto);
        localStorage.clear();
        SecureIndexedDB.setItem("token", resLogin.data.token);
        const tokenData = jwtDecode(resLogin.data.token);
        setUserLoginDetails(tokenData);
        SecureIndexedDB.setItem("userProfileImg", tokenData.userProfileImage);
        if (tokenData.orgId) {
          getActiveOtpServices(tokenData.orgId).then((response) => {
            console.log("responseotpservicedata ===1100", response.data);
            if (Array.isArray(response.data) && response.data.length > 0) {
              SecureIndexedDB.setItem(
                "otpServices",
                JSON.stringify(response.data)
              );
            }
          });
        }
        navigate(`/dashboard`, {
          state: state,
        });
      }
    }
  };

  //
  //

  return (
    <>
      <div className="logRegFullContainer">
        <div className="closelrCon">
          <IconButton onClick={handelCloseLogRegContain}>
            <XLg />
          </IconButton>
        </div>
        <div className="logRegContain">
          <div className="lrContainer">
            <div className="lrLeftContain">
              <div className="statLrImg">
                <img src={lrLogin} alt="statLrImg" />
              </div>
            </div>
            <div className="lrRightContain">
              <div className="logRegForm">
                <div className="lrHead">{t("registration")}</div>

                <div className="fromGroup ">
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        disabled={otpIdSU != null}
                        label={t("full_name")}
                        required
                        variant="outlined"
                        className="formTextFieldArea"
                        onChange={(event) => setUserName(event.target.value)}
                      />
                    </FormControl>
                  </div>

                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel htmlFor="userEmailId">
                        {t("email_id")}
                      </InputLabel>
                      <OutlinedInput
                        id="userEmailIdSU"
                        className="formTextFieldArea areaWbtn"
                        type="text"
                        disabled={otpIdSU != null}
                        endAdornment={
                          <InputAdornment position="end">
                            {fetchingOrgListSU ? (
                              <>
                                <CircularProgress className="loading-bar-fetch-org" />
                              </>
                            ) : otpIdSU ? (
                              <IconButton
                                edge="end"
                                onClick={() => {
                                  // setUserLoginIdSU("");
                                  // setOrgListSU([]);
                                  setNewPassword("");
                                  setConfirmPassword("");
                                  setOtpIdSU(null);
                                  setOtpValidatedSU(false);
                                  setOtpSU(null);
                                }}
                              >
                                <CancelOutlined />
                              </IconButton>
                            ) : (
                              <IconButton
                                edge="end"
                                onClick={getAllOrgsAssociatedWithUserSU}
                              >
                                <ArrowForward />
                              </IconButton>
                            )}
                          </InputAdornment>
                        }
                        label={t("email_id")}
                        value={userLoginIdSU}
                        onChange={(event) =>
                          setUserLoginIdSU(event.target.value)
                        }
                      />
                    </FormControl>
                  </div>

                  {otpIdSU && (
                    <>
                      {!otpValidatedSU && (
                        <div className="formElement">
                          <FormControl className="formControl">
                            <InputLabel htmlFor="userEmailId">
                              {t("validate_otp")}
                            </InputLabel>
                            <OutlinedInput
                              id="userEmailId"
                              className="formTextFieldArea areaWbtn padR0"
                              type="text"
                              endAdornment={
                                <InputAdornment position="end">
                                  <Button
                                    endIcon={<CheckCircleOutline />}
                                    edge="end"
                                    className="otpBtn"
                                    onClick={validateOTPSU}
                                  >
                                    {t("validate")}
                                  </Button>
                                </InputAdornment>
                              }
                              label={t("validate_otp")}
                              onChange={(event) => setOtpSU(event.target.value)}
                            />
                          </FormControl>
                        </div>
                      )}

                      {otpValidatedSU && (
                        <>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <InputLabel htmlFor="outlined-adornment-new-password">
                                {t("new_password")}
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-new-password"
                                className="formTextFieldArea areaPassword"
                                type={showNewPassword ? "text" : "password"}
                                value={newPassword}
                                onChange={handleChangeNewPassword}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowNewPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {showNewPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                                label={t("new_password")}
                              />
                            </FormControl>
                          </div>

                          <div className="formElement">
                            <FormControl className="formControl">
                              <InputLabel htmlFor="outlined-adornment-confirm-password">
                                {t("confirm_password")}
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-confirm-password"
                                className="formTextFieldArea areaPassword"
                                type={showConfirmPassword ? "text" : "password"}
                                value={confirmPassword}
                                onChange={handleChangeConfirmPassword}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowConfirmPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {showConfirmPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                                label={t("confirm_password")}
                              />
                            </FormControl>
                          </div>

                          <div className="passwordPolocy">
                            <div className="meterWithText">
                              <div className="meter" id="meter">
                                <div className="meter-bar" id="meter-bar"></div>
                              </div>
                              <div className="meter-text" id="meter-text">
                                <span className="meterHead">
                                  {t("password_strength")}:{" "}
                                </span>
                                <span
                                  className="meter-status"
                                  id="meter-status"
                                >
                                  {t("too_short")}
                                </span>
                              </div>
                            </div>

                            <div className="passwrdPlcyCheckGrp">
                              <div className="passwrdPlcyCheckHead">
                                {t("password_should_contain")}
                              </div>

                              <div className="passwrdPlcyCheck">
                                <span id="one-upper-case-check">
                                  {newPassword.match(/[A-Z]/) <= 1 ? (
                                    <Cancel className="cancelIcon" />
                                  ) : (
                                    <CheckCircle className="checkIcon" />
                                  )}
                                </span>
                                <span>{t("one_upper_case_letter")}</span>
                              </div>
                              <div className="passwrdPlcyCheck">
                                <span id="one-lower-case-check">
                                  {newPassword.match(/[a-z]/) <= 1 ? (
                                    <Cancel className="cancelIcon" />
                                  ) : (
                                    <CheckCircle className="checkIcon" />
                                  )}
                                </span>
                                <span>{t("one_lower_case_letter")}</span>
                              </div>
                              <div className="passwrdPlcyCheck">
                                <span id="one-number-check">
                                  {newPassword.match(/[0-9]/) < 1 ? (
                                    <Cancel className="cancelIcon" />
                                  ) : (
                                    <CheckCircle className="checkIcon" />
                                  )}
                                </span>
                                <span>{t("one_number")}</span>
                              </div>
                              <div className="passwrdPlcyCheck">
                                <span id="one-specialChar-check">
                                  {/[!@#$%^&*(),.?":{}|~<>]/.test(newPassword) <
                                  1 ? (
                                    <Cancel className="cancelIcon" />
                                  ) : (
                                    <CheckCircle className="checkIcon" />
                                  )}
                                </span>
                                <span>{t("special_character")}</span>
                              </div>
                              <div className="passwrdPlcyCheck">
                                <span id="min-length-check">
                                  {newPassword.length < 8 ? (
                                    <Cancel className="cancelIcon" />
                                  ) : (
                                    <CheckCircle className="checkIcon" />
                                  )}
                                </span>
                                <span>{t("min_8_characters")}</span>
                              </div>
                              <div className="passwrdPlcyCheck">
                                <span id="pass-match-check">
                                  {newPassword === "" ||
                                  newPassword !== confirmPassword ? (
                                    <Cancel className="cancelIcon" />
                                  ) : (
                                    <CheckCircle className="checkIcon" />
                                  )}
                                </span>
                                <span>{t("passwords_mismatch")}</span>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>

                {/* <Button
                  variant="contained"
                  className="lrButton"
                  disabled={!isValid}
                  onClick={signupUser}
                  style={{ marginTop: "5px" }}
                >
                  REGISTER
                </Button> */}
                {otpValidatedSU && (
                  <Button
                    variant="contained"
                    className="lrButton"
                    disabled={!isValid}
                    onClick={signupUser}
                    // title={isValid ? "" : "Please enter your password"}
                    style={{ marginTop: "5px" }}
                  >
                    {t("register")}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
