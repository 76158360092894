import { Box, Grid, IconButton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "../css/admin.css";
import RightFlotingAdmin from "./component/RightFlotingAdmin";
import DefineUnits from "./component/DefineUnits";
import CreateUnits from "./component/CreateUnits";
import UnitTreeView from "./component/UnitTreeView";
import DefineDepartment from "./component/DefineDepartment";
import CreateDepartment from "./component/CreateDepartment";
import DefineDesignation from "./component/DefineDesignation";
import CreateDesignation from "./component/CreateDesignation";
import DefinePosition from "./component/DefinePosition";
import CreatePosition from "./component/CreatePosition";
import DefineCommittee from "./component/DefineCommittee";
import CreateCommittee from "./component/CreateCommittee";
import DefineExpenditure from "./component/DefineExpenditure";
import DefineCriticalRights from "./component/DefineCriticalRights";
import CreateOrgRights from "./component/CreateOrgRights";
import CreateExpenditurPower from "./component/CreateExpenditurPower";
import DefineTaxStatutory from "./component/DefineTaxStatutory";
import CreateTaxStatutory from "./component/CreateTaxStatutory";
import DefineStorage from "./component/DefineStorage";
import CreateStorage from "./component/CreateStorage";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import UpdateStorage from "./component/UpdateStorage";
import UpdateUnit from "./component/UpdateUnit";
import DefineSalaryHead from "./component/DefineSalaryHead";
import CerateSalaryHead from "./component/CerateSalaryHead";
import DefineCoupon from "./component/DefineCoupon";
import CreateCoupon from "./component/CreateCoupon";
import DefineActivityTemplate from "./component/DefineActivityTemplate";
import CreateActivityTemplate from "./component/CreateActivityTemplate";
import ViewActivityTemplate from "./component/ViewActivityTemplate";
import DefinePolicy from "./component/DefinePolicy";
import CreatePolicy from "./component/CreatePolicy";
import DefineTermsConditions from "./component/DefineTermsConditions";
import CreateTermsConditions from "./component/CreateTermsConditions";
import DefineAccessControl from "./component/DefineAccessControl";
import CreateAccessControl from "./component/CreateAccessControl";
import UpdateTermsConditions from "./component/UpdateTermsConditions";
import SelectTemplate from "../../organization/view/component/SelectTemplate";
import { GetLoginUserDetails } from "../../../utils/Utils";
import { ConfirmProvider } from "material-ui-confirm";
import DefineOrganization from "./component/DefineOrganization";
import UpdateOrganization from "./component/UpdateOrganization";
import DefineSetPosition from "./component/DefineSetPosition";
import { getOrgByOrgId, getOrgDetails } from "../../../services/AdminService";
import DefinePMS from "./component/DefinePMS";
import DefinePayroll from "./component/payroll/DefinePayroll";
import UpdateAccessControl from "./component/UpdateAccessControl";
import AddSalaryComponents from "./component/payroll/AddSalaryComponents";
import AddSalaryTemplate from "./component/payroll/AddSalaryTemplate";
import DefineSalaryComponent from "./component/DefineSalaryComponent";
import CreateSalaryComponent from "./component/CreateSalaryComponent";
import UpdateSalaryComponent from "./component/UpdateSalaryComponent";
import { DefineCriticalRightsNew } from "./component/DefineCriticalRightsNew";
import DefineOtpService from "./component/DefineOtpService";
import { useTranslation } from "react-i18next";
import DefineHealthcareConfig from "./component/healthcare/DefineHealthcareConfig";
import CreateSTP from "./component/healthcare/CreateSTP";
import EditStp from "./component/healthcare/EditStp";
import Joyride from "react-joyride";
import { useContext } from "react";
import { TourContext } from "../../../common/TourProvider";

export default function Admin() {
  const loginUserDetail = GetLoginUserDetails();
  const { t } = useTranslation();
  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  const [selectedMenu, setSelectedMenu] = useState(0);
  const [showFlotingContainer, setFlotingContainer] = useState(false);
  const [unitTreeView, setUnitTreeView] = useState(false);
  const [createDepartView, setcreateDepartView] = useState(false);
  const [selectDepartment, setSelectedDepartment] = useState(null);
  const [createDesignation, setCreateDesignation] = useState(false);
  const [selectedDesignation, setSelectedDesignation] = useState(null);
  const [createPosition, setCreatePosition] = useState(false);
  const [createCommittee, setCreateCommittee] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [selectedCommittee, setSelectedCommittee] = useState(null);
  const [refreshTaxStatutory, setRefreshTaxStatutory] = useState(1);

  const [refreshUnit, setRefreshUnit] = useState(false);

  const [updateUnits, setUpdateUnits] = useState(false);
  const [selectedUpdateUnits, setSelectedUpdateUnits] = useState(null);
  const [showAddTermsConditions, setShowAddTermsConditions] = useState(false);
  const [showAddAccessControl, setShowAddAccessControl] = useState(false);

  const [refreshTermsConditions, setRefreshTermsConditions] = useState(false);
  const [orgDetailsData, setOrgDetailsData] = useState(null);

  const [activeCreationMenu, setActiveCreationMenu] = useState("");
  const [addSalaryTemplate, setAddSalaryTemplate] = useState(false);
  const [addNewSalaryComponent, setAddNewSalaryComponent] = useState(false);
  const [addNewSTP, setAddNewSTP] = useState(false);
  const [editStp, setEditStp] = useState(false);
  const [editStpData, setEditStpData] = useState(null);

  const handelOpenAddNewSTP = () => {
    setAddNewSTP(true);
  };
  const handleOpenEditSTP = (data) => {
    setEditStp(true);
    setEditStpData(data);
  };
  const handelCloseAddNewSTP = () => {
    setAddNewSTP(false);
  };

  const handelCloseEditStp = () => {
    setEditStp(false);
    setEditStpData(null);
  };

  const handelOpenNewSalaryComponent = () => {
    setAddNewSalaryComponent(true);
  };
  const handelCloseNewSalaryComponent = () => {
    setAddNewSalaryComponent(false);
  };

  const [editSalaryComponent, setEditSalaryComponent] = useState(false);
  const [editSalaryComponentData, setEditSalaryComponentData] = useState(null);

  const handelOpnEditSalaryComponent = (data) => {
    setEditSalaryComponent(true);
    setEditSalaryComponentData(data);
  };
  const handelClsEditSalaryComponent = () => {
    setEditSalaryComponent(false);
    setEditSalaryComponentData(null);
  };

  const [refreshSalaryComponent, setRefreshSalaryComponent] = useState(false);

  const refreshSalaryComponentData = () => {
    setRefreshSalaryComponent(
      (prevRefreshSalaryComponent) => !prevRefreshSalaryComponent
    );
  };

  const handelCloseSalaryTemplate = () => {
    setAddSalaryTemplate(false);
  };
  const handelOpenSalaryTemplate = () => {
    setAddSalaryTemplate(true);
  };

  const refreshTermsConditionsData = () => {
    setRefreshTermsConditions(
      (prevRefreshTermsConditions) => !prevRefreshTermsConditions
    );
  };

  const handelCloseCreateTermsConditions = () => {
    setShowAddTermsConditions(false);
  };
  const handelOpenCreateTermsConditions = () => {
    setShowAddTermsConditions(true);
  };

  const [showSelectedTermsCondition, setShowSelectedTermsCondition] =
    useState(false);
  const [selecttedViewTermsCondition, setSelectedViewTermsCondition] =
    useState(null);

  const handelOpenEditTermsCondition = (userData) => {
    setSelectedViewTermsCondition(userData);
    setShowSelectedTermsCondition(true);
  };

  const handelCloseEditTermsCondition = () => {
    setShowSelectedTermsCondition(false);
    setSelectedViewTermsCondition(null);
  };

  const [refreshAccessControl, setRefreshAccessControl] = useState(false);

  const refreshAccessControlData = () => {
    setRefreshAccessControl(
      (prevRefreshAccessControl) => !prevRefreshAccessControl
    );
  };

  const handelCloseAssignAccessControl = () => {
    setShowAddAccessControl(false);
  };
  const handelOpenAssignAccessControl = () => {
    setShowAddAccessControl(true);
  };

  const [updatedAccessControlData, setUpdatedAccessControllerData] =
    useState(null);
  const [updatedAccessControlModel, setUpdatedAccessControlModel] =
    useState(false);

  const handleOpenUpdatedAccessControl = (data) => {
    setUpdatedAccessControllerData(data);
    setUpdatedAccessControlModel(true);
  };

  const handleCloseUpdatedAccessControl = () => {
    setUpdatedAccessControllerData(null);
    setUpdatedAccessControlModel(false);
  };

  const handleCloseUpdateUnits = () => {
    setUpdateUnits(false);
    setSelectedUpdateUnits(null);
  };
  const handleOpenUpdateUnits = (userData) => {
    setSelectedUpdateUnits(userData);
    setUpdateUnits(true);
  };

  const [updateOrgStorage, setUpdateOrgStorage] = useState(false);
  const [selectedUpdateStorage, setSelectedUpdateStorage] = useState(null);

  const handleCloseUpdateOrgStorage = () => {
    setUpdateOrgStorage(false);
    setSelectedUpdateStorage(null);
  };
  const handleOpenUpdateOrgStorage = (
    userData,
    totalUsedStorage,
    TotalStorageData
  ) => {
    setSelectedUpdateStorage({ userData, totalUsedStorage, TotalStorageData });
    setUpdateOrgStorage(true);
  };

  const [createOrgStorage, setCreateOrgStorage] = useState(false);

  const handleCloseCreateOrgStorage = () => {
    setCreateOrgStorage(false);
  };
  const handleOpenCreateOrgStorage = () => {
    setCreateOrgStorage(true);
  };

  const refreshUnitData = () => {
    setRefreshUnit((prevRefreshUnit) => !prevRefreshUnit);
  };

  const [refreshDepartment, setRefreshDepartment] = useState(false);

  const refresDepartmenthData = () => {
    setRefreshDepartment((prevRefreshDepartment) => !prevRefreshDepartment);
  };

  const [refreshDesignation, setRefreshDesignation] = useState(false);

  const refreshDesignationData = () => {
    setRefreshDesignation((prevRefreshDesignation) => !prevRefreshDesignation);
  };

  const [refreshPosition, setRefreshPosition] = useState(false);

  const refreshPositionData = () => {
    setRefreshPosition((prevRefreshPosition) => !prevRefreshPosition);
  };

  const [refreshCommittee, setRefreshCommittee] = useState(false);

  const refreshCommitteeData = () => {
    setRefreshCommittee((prevRefresh) => !prevRefresh);
  };

  const [refreshStorage, setRefreshStorage] = useState(false);

  const refreshStorageData = () => {
    setRefreshStorage((prevRefreshStorage) => !prevRefreshStorage);
  };

  const [refreshSalaryHead, setRefreshSalaryHead] = useState(false);

  const refreshSalaryHeadData = () => {
    setRefreshSalaryHead((prevRefreshSalaryHead) => !prevRefreshSalaryHead);
  };

  ///////////////////////////////////////////////////////////////////////////////
  const [refresh, setRefresh] = useState(false);
  const [createTaxStatutory, setCreateTaxStatutory] = useState(false);

  const refreshData = () => {
    setRefresh((prevRefresh) => !prevRefresh);
  };

  const handleCloseMenuButton = () => {
    setFlotingContainer(false);
  };
  const handleOpenMenuButton = () => {
    setFlotingContainer(true);
  };
  const handleOpenUnitTreeView = () => {
    setUnitTreeView(true);
  };
  const handleCloseUnitTreeView = () => {
    setUnitTreeView(false);
  };
  const handelCloseCrtDeprtView = () => {
    setcreateDepartView(false);
    setSelectedDepartment(null);
  };

  const handelOpenCrtDeprtView = (department) => {
    setSelectedDepartment(department);
    setcreateDepartView(true);
  };

  const handelOpenCrtDesig = (designation) => {
    setSelectedDesignation(designation);
    setCreateDesignation(true);
  };
  const handelCloseCrtDesig = () => {
    setSelectedDesignation(null);
    setCreateDesignation(false);
  };

  const handelOpenCrtPosition = (position) => {
    setSelectedPosition(position);
    setCreatePosition(true);
  };
  const handelCloseCrtPosition = () => {
    setSelectedPosition(null);
    setCreatePosition(false);
  };
  const handelOpenCrtCommittee = (Committee) => {
    setSelectedCommittee(Committee);
    setCreateCommittee(true);
  };
  const handelCloseCrtCommittee = () => {
    setSelectedCommittee(null);
    setCreateCommittee(false);
  };

  const [createOrgRight, setCreateOrgRight] = useState(false);
  const [selectedCriticalRights, setSelectedCriticalRights] = useState(null);

  const handelCloseCreateOrgRight = () => {
    setCreateOrgRight(false);
    setCreateOrgRight(null);
  };

  const handelOpenCreateOrgRight = (right) => {
    setSelectedCriticalRights(right);
    setCreateOrgRight(true);
  };

  const [createSalaryHead, setCreateSalaryHead] = useState(false);
  const handelCloseCreateSalaryHead = () => {
    setCreateSalaryHead(false);
  };

  const handelOpenCreateSalaryHead = () => {
    setCreateSalaryHead(true);
  };

  const [showAddActivityTemplate, setShowAddActivityTemplate] = useState(false);

  const [refreshActivityTemplate, setRefreshActivityTemplate] = useState(false);

  const refreshActivityTemplateData = () => {
    setRefreshActivityTemplate(
      (prevRefreshActivityTemplate) => !prevRefreshActivityTemplate
    );
  };

  const handelCloseActivityTemplate = () => {
    setShowAddActivityTemplate(false);
  };

  const handelOpenActivityTemplate = () => {
    setShowAddActivityTemplate(true);
  };

  const [showViewActivityTemplate, setViewAddActivityTemplate] =
    useState(false);
  const [showSelectedViewActivityTemplate, setSelectedViewAddActivityTemplate] =
    useState(null);

  const handelCloseViewActivityTemplate = () => {
    setViewAddActivityTemplate(false);
    setSelectedViewAddActivityTemplate(null);
  };

  const handelOpenViewActivityTemplate = (data) => {
    setSelectedViewAddActivityTemplate(data);
    setViewAddActivityTemplate(true);
  };

  const [createCouponCode, setCreateCouponCode] = useState(false);

  const [refreshCouponCode, setRefreshCouponCode] = useState(false);

  const refreshCouponCodeData = () => {
    setRefreshCouponCode((prevRefreshCouponCode) => !prevRefreshCouponCode);
  };

  const handelCloseCreateCouponCode = () => {
    setCreateCouponCode(false);
  };

  const handelOpenCreateCouponCode = () => {
    setCreateCouponCode(true);
  };

  const [createExpPowr, setCreateExpPowr] = useState(false);
  const [selectExpPowr, setSelectExpPowr] = useState(null);

  const handelCloseCreateExpPwr = () => {
    setCreateExpPowr(false);
    setSelectExpPowr(null);
  };
  const handelOpenCreateExpPwr = (right) => {
    setSelectExpPowr(right);
    setCreateExpPowr(true);
  };

  const handelClosecreateTaxStatutory = () => {
    setCreateTaxStatutory(false);
  };
  const handelOpencreateTaxStatutory = () => {
    setCreateTaxStatutory(true);
  };

  const [showViewPolicies, setViewPolicies] = useState(false);

  const handelOpenViewPolicies = () => {
    setViewPolicies(true);
  };
  const handelCloseViewPolicies = () => {
    setViewPolicies(false);
  };

  const [showAddPolicies, setAddPolicies] = useState(false);

  const handelOpenAddPolicies = () => {
    setAddPolicies(true);
  };
  const handelCloseAddPolicies = () => {
    setAddPolicies(false);
  };

  const [showUpdateOrg, setShowUpdateOrg] = useState(false);
  const [showUpdateOrgData, setshowUpdateOrgData] = useState(null);

  const handelOpenEditUpdateOrg = (userData) => {
    setshowUpdateOrgData(userData);
    setShowUpdateOrg(true);
  };

  const handelCloseEditUpdateOrg = () => {
    setShowUpdateOrg(false);
    setshowUpdateOrgData(null);
  };

  const [refreshOrganisation, setRefreshOrganisation] = useState(false);

  const refreshOrganisationData = () => {
    setRefreshOrganisation(
      (prevRefreshOrganisation) => !prevRefreshOrganisation
    );
  };

  const handleCloseAll = () => {
    setFlotingContainer(false);
    setUnitTreeView(false);
    setcreateDepartView(false);
    setCreateDesignation(false);
    setCreatePosition(false);
    setCreateCommittee(false);
    setUpdateUnits(false);
    setShowAddTermsConditions(false);
    setShowAddAccessControl(false);
    setShowSelectedTermsCondition(false);
    setUpdateOrgStorage(false);
    setCreateOrgStorage(false);
    setCreateTaxStatutory(false);
    setCreateOrgRight(false);
    setCreateSalaryHead(false);
    setShowAddActivityTemplate(false);
    setCreateCouponCode(false);
    setCreateExpPowr(false);
    setAddPolicies(false);
    setAddNewSTP(false);
  };

  // button group scroll left right start
  const containerRef = useRef(null);

  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      setShowLeftArrow(container.scrollLeft > 0);
      setShowRightArrow(
        container.scrollLeft < container.scrollWidth - container.clientWidth
      );
    }
  }, [containerRef.current?.scrollLeft]);

  const scrollLeft = () => {
    if (containerRef.current) {
      console.log("Scrolling left...");
      containerRef.current.scrollLeft -= 300; // Adjust scroll speed as needed
      setShowRightArrow(true);
      setShowLeftArrow(containerRef.current.scrollLeft > 0);
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      console.log("Scrolling Right...");
      containerRef.current.scrollLeft += 300; // Adjust scroll speed as needed
      setShowLeftArrow(true);
      setShowRightArrow(
        containerRef.current.scrollLeft <
          containerRef.current.scrollWidth - containerRef.current.clientWidth
      );
    }
  };

  useEffect(() => {
    fetchgetOrgDetails();
  }, [refreshOrganisation]);

  const fetchgetOrgDetails = () => {
    getOrgByOrgId(loginUserDetail.orgId)
      .then((response) => {
        console.log("getOrgDetails", response.data);
        setOrgDetailsData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching getOrgDetails", error);
      });
  };

  return (
    <>
    <Joyride
        steps={
          [{
            target: ".adminContentStepOne",
            content:
              "This are all the feature in Support Admin . please Click on any One to View Details",
            disableBeacon: true,
          },]
         }
        run={run["AdminDetailsAppAs"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        styles={{
          buttonClose: {
            display: "none",
          },
        }}
        locale={{
          last: "Next",
        }}
      />
      <Box className="adminContainerArea" sx={{ bgcolor: "dark.pageBg" }}>
        <Grid container spacing={0} className="adminContainArea">
          <Grid item xs={12}>
            <div className="AdminWorkSpace">
              <div className="hmTabsContainer adminContentStepOne">
                {showLeftArrow && (
                  <IconButton onClick={scrollLeft} className="lrarrowBtn left">
                    <ArrowBackIos />
                  </IconButton>
                )}
                <div className="hmTabsGroup noscrollBar" ref={containerRef}>
                  <button
                    className={`hmTabsBtn ${
                      selectedMenu === 0 ? `hmBtnActive` : ``
                    }`}
                    // onClick={() => setSelectedMenu(0)}
                    onClick={() => {
                      setSelectedMenu(0);
                      handleCloseAll();
                      if (run["AdminDetailsAppAs"] === true) {
                        setRun({
                          viewOrgAD: true,
                        });
                      }
                    }}
                  >
                    <div className="hmTabLayer">
                      <div className="hmTabContnt">
                        <span className="hmTbText">{t("organization")}</span>
                      </div>
                      <div className="hmTabActvIndcator"></div>
                    </div>
                  </button>
                  {orgDetailsData &&
                    orgDetailsData.singleUnit &&
                    orgDetailsData.singleUnit === "N" && (
                      <button
                        className={`hmTabsBtn ${
                          selectedMenu === 1 ? `hmBtnActive` : ``
                        }`}
                        // onClick={() => setSelectedMenu(0)}
                        onClick={() => {
                          setSelectedMenu(1);
                          handleCloseAll();
                          if (run["AdminDetailsAppAs"] === true) {
                            setRun({
                              viewUnitAD: true,
                            });
                          }
                        }}
                      >
                        <div className="hmTabLayer">
                          <div className="hmTabContnt">
                            <span className="hmTbText">{t("units")}</span>
                          </div>
                          <div className="hmTabActvIndcator"></div>
                        </div>
                      </button>
                    )}

                  <button
                    className={`hmTabsBtn ${
                      selectedMenu === 17 ? `hmBtnActive` : ``
                    }`}
                    onClick={() => {
                      setSelectedMenu(17);
                      handleCloseAll();
                      if (run["AdminDetailsAppAs"] === true) {
                        setRun({
                          viewPositionAD: true,
                        });
                      }
                    }}
                  >
                    <div className="hmTabLayer">
                      <div className="hmTabContnt">
                        <span className="hmTbText">{t("position")}</span>
                      </div>
                      <div className="hmTabActvIndcator"></div>
                    </div>
                  </button>

                  {/* {loginUserDetail.orgName === "Miniontek" && (
                    <button
                      className={`hmTabsBtn ${
                        selectedMenu === 19 ? `hmBtnActive` : ``
                      }`}
                      onClick={() => {
                        setSelectedMenu(19);
                        handleCloseAll();
                      }}
                    >
                      <div className="hmTabLayer">
                        <div className="hmTabContnt">
                          <span className="hmTbText">Payroll</span>
                        </div>
                        <div className="hmTabActvIndcator"></div>
                      </div>
                    </button>
                  )} */}

                  <button
                    className={`hmTabsBtn ${
                      selectedMenu === 20 ? `hmBtnActive` : ``
                    }`}
                    onClick={() => {
                      setSelectedMenu(20);
                      handleCloseAll();
                      if (run["AdminDetailsAppAs"] === true) {
                        setRun({
                          viewSalaryComponetAD: true,
                        });
                      }
                    }}
                  >
                    <div className="hmTabLayer">
                      <div className="hmTabContnt">
                        <span className="hmTbText">
                          {t("salary_component")}
                        </span>
                      </div>
                      <div className="hmTabActvIndcator"></div>
                    </div>
                  </button>

                  {/* <button
                    className={`hmTabsBtn ${
                      selectedMenu === 2 ? `hmBtnActive` : ``
                    }`}
                    // onClick={() => setSelectedMenu(1)}
                    onClick={() => {
                      setSelectedMenu(2);
                      handleCloseAll();
                    }}
                  >
                    <div className="hmTabLayer">
                      <div className="hmTabContnt">
                        <span className="hmTbText">Department</span>
                      </div>
                      <div className="hmTabActvIndcator"></div>
                    </div>
                  </button>

                  <button
                    className={`hmTabsBtn ${
                      selectedMenu === 3 ? `hmBtnActive` : ``
                    }`}
                    // onClick={() => setSelectedMenu(2)}
                    onClick={() => {
                      setSelectedMenu(3);
                      handleCloseAll();
                    }}
                  >
                    <div className="hmTabLayer">
                      <div className="hmTabContnt">
                        <span className="hmTbText">Designation</span>
                      </div>
                      <div className="hmTabActvIndcator"></div>
                    </div>
                  </button>

                  <button
                    className={`hmTabsBtn ${
                      selectedMenu === 4 ? `hmBtnActive` : ``
                    }`}
                    // onClick={() => setSelectedMenu(3)}
                    onClick={() => {
                      setSelectedMenu(4);
                      handleCloseAll();
                    }}
                  >
                    <div className="hmTabLayer">
                      <div className="hmTabContnt">
                        <span className="hmTbText">Position</span>
                      </div>
                      <div className="hmTabActvIndcator"></div>
                    </div>
                  </button> */}

                  <button
                    className={`hmTabsBtn ${
                      selectedMenu === 5 ? `hmBtnActive` : ``
                    }`}
                    // onClick={() => setSelectedMenu(4)}
                    onClick={() => {
                      setSelectedMenu(5);
                      handleCloseAll();
                      if (run["AdminDetailsAppAs"] === true) {
                        setRun({
                          viewCommitteeAD: true,
                        });
                      }
                    }}
                  >
                    <div className="hmTabLayer">
                      <div className="hmTabContnt">
                        <span className="hmTbText">{t("committee")}</span>
                      </div>
                      <div className="hmTabActvIndcator"></div>
                    </div>
                  </button>

                  <button
                    className={`hmTabsBtn ${
                      selectedMenu === 6 ? `hmBtnActive` : ``
                    }`}
                    // onClick={() => setSelectedMenu(5)}
                    onClick={() => {
                      setSelectedMenu(6);
                      handleCloseAll();
                      if (run["AdminDetailsAppAs"] === true) {
                        setRun({
                          viewBudgetHeadAD: true,
                        });
                      }
                    }}
                  >
                    <div className="hmTabLayer">
                      <div className="hmTabContnt">
                        <span className="hmTbText">{t("budget_head")}</span>
                      </div>
                      <div className="hmTabActvIndcator"></div>
                    </div>
                  </button>

                  <button
                    className={`hmTabsBtn ${
                      selectedMenu === 7 ? `hmBtnActive` : ``
                    }`}
                    // onClick={() => setSelectedMenu(6)}
                    onClick={() => {
                      setSelectedMenu(7);
                      handleCloseAll();
                      if (run["AdminDetailsAppAs"] === true) {
                        setRun({
                          viewCriticalRightsAD: true,
                        });
                      }
                    }}
                  >
                    <div className="hmTabLayer">
                      <div className="hmTabContnt">
                        <span className="hmTbText">{t("critical_rights")}</span>
                      </div>
                      <div className="hmTabActvIndcator"></div>
                    </div>
                  </button>

                  <button
                    className={`hmTabsBtn ${
                      selectedMenu === 8 ? `hmBtnActive` : ``
                    }`}
                    // onClick={() => setSelectedMenu(7)}
                    onClick={() => {
                      setSelectedMenu(8);
                      handleCloseAll();
                      if (run["AdminDetailsAppAs"] === true) {
                        setRun({
                          viewStatutoryInsuranceAD: true,
                        });
                      }
                    }}
                  >
                    <div className="hmTabLayer">
                      <div className="hmTabContnt">
                        <span className="hmTbText">
                          {t("statutory_and_insurance")}
                        </span>
                      </div>
                      <div className="hmTabActvIndcator"></div>
                    </div>
                  </button>

                  <button
                    className={`hmTabsBtn ${
                      selectedMenu === 9 ? `hmBtnActive` : ``
                    }`}
                    // onClick={() => setSelectedMenu(8)}
                    onClick={() => {
                      setSelectedMenu(9);
                      handleCloseAll();
                      if (run["AdminDetailsAppAs"] === true) {
                        setRun({
                          viewStorageAD: true,
                        });
                      }
                    }}
                  >
                    <div className="hmTabLayer">
                      <div className="hmTabContnt">
                        <span className="hmTbText">{t("storage")}</span>
                      </div>
                      <div className="hmTabActvIndcator"></div>
                    </div>
                  </button>
                  {/* <button
                    className={`hmTabsBtn ${
                      selectedMenu === 10 ? `hmBtnActive` : ``
                    }`}
                    // onClick={() => setSelectedMenu(9)}
                    onClick={() => {
                      setSelectedMenu(10);
                      handleCloseAll();
                    }}
                  >
                    <div className="hmTabLayer">
                      <div className="hmTabContnt">
                        <span className="hmTbText">Salary Head</span>
                      </div>
                      <div className="hmTabActvIndcator"></div>
                    </div>
                  </button> */}
                  {loginUserDetail.orgName === "Miniontek" && (
                    <button
                      className={`hmTabsBtn ${
                        selectedMenu === 11 ? `hmBtnActive` : ``
                      }`}
                      // onClick={() => setSelectedMenu(10)}
                      onClick={() => {
                        setSelectedMenu(11);
                        handleCloseAll();
                        if (run["AdminDetailsAppAs"] === true) {
                          setRun({
                            viewCouponAD: true,
                          });
                        }
                      }}
                    >
                      <div className="hmTabLayer">
                        <div className="hmTabContnt">
                          <span className="hmTbText">{t("coupon")}</span>
                        </div>
                        <div className="hmTabActvIndcator"></div>
                      </div>
                    </button>
                  )}
                  <button
                    className={`hmTabsBtn ${
                      selectedMenu === 12 ? `hmBtnActive` : ``
                    }`}
                    // onClick={() => setSelectedMenu(11)}
                    onClick={() => {
                      setSelectedMenu(12);
                      handleCloseAll();
                      if (run["AdminDetailsAppAs"] === true) {
                        setRun({
                          viewActivityAD: true,
                        });
                      }
                    }}
                  >
                    <div className="hmTabLayer">
                      <div className="hmTabContnt">
                        <span className="hmTbText">{t("activity")}</span>
                      </div>
                      <div className="hmTabActvIndcator"></div>
                    </div>
                  </button>
                  <button
                    className={`hmTabsBtn ${
                      selectedMenu === 13 ? `hmBtnActive` : ``
                    }`}
                    // onClick={() => setSelectedMenu(12)}
                    onClick={() => {
                      setSelectedMenu(13);
                      handleCloseAll();
                      if (run["AdminDetailsAppAs"] === true) {
                        setRun({
                          viewPolicyAD: true,
                        });
                      }
                    }}
                  >
                    <div className="hmTabLayer">
                      <div className="hmTabContnt">
                        <span className="hmTbText">{t("policy")}</span>
                      </div>
                      <div className="hmTabActvIndcator"></div>
                    </div>
                  </button>
                  <button
                    className={`hmTabsBtn ${
                      selectedMenu === 14 ? `hmBtnActive` : ``
                    }`}
                    // onClick={() => setSelectedMenu(13)}
                    onClick={() => {
                      setSelectedMenu(14);
                      handleCloseAll();
                      if (run["AdminDetailsAppAs"] === true) {
                        setRun({
                          viewtermAndConditionAD: true,
                        });
                      }
                    }}
                  >
                    <div className="hmTabLayer">
                      <div className="hmTabContnt">
                        <span className="hmTbText">
                          {t("terms_and_conditions")}
                        </span>
                      </div>
                      <div className="hmTabActvIndcator"></div>
                    </div>
                  </button>
                  <button
                    className={`hmTabsBtn ${
                      selectedMenu === 15 ? `hmBtnActive` : ``
                    }`}
                    // onClick={() => setSelectedMenu(14)}
                    onClick={() => {
                      setSelectedMenu(15);
                      handleCloseAll();
                      if (run["AdminDetailsAppAs"] === true) {
                        setRun({
                          viewAccessControlAD: true,
                        });
                      }
                    }}
                  >
                    <div className="hmTabLayer">
                      <div className="hmTabContnt">
                        <span className="hmTbText">{t("access_control")}</span>
                      </div>
                      <div className="hmTabActvIndcator"></div>
                    </div>
                  </button>
                  <button
                    className={`hmTabsBtn ${
                      selectedMenu === 16 ? `hmBtnActive` : ``
                    }`}
                    // onClick={() => setSelectedMenu(15)}
                    onClick={() => {
                      setSelectedMenu(16);
                      handleCloseAll();
                      if (run["AdminDetailsAppAs"] === true) {
                        setRun({
                          viewWebsiteAD: true,
                        });
                      }
                    }}
                  >
                    <div className="hmTabLayer">
                      <div className="hmTabContnt">
                        <span className="hmTbText">{t("website")}</span>
                      </div>
                      <div className="hmTabActvIndcator"></div>
                    </div>
                  </button>

                  <button
                    className={`hmTabsBtn ${
                      selectedMenu === 18 ? `hmBtnActive` : ``
                    }`}
                    onClick={() => {
                      setSelectedMenu(18);
                      handleCloseAll();
                      if (run["AdminDetailsAppAs"] === true) {
                        setRun({
                          viewPMSAD: true,
                        });
                      }
                    }}
                  >
                    <div className="hmTabLayer">
                      <div className="hmTabContnt">
                        <span className="hmTbText">{t("pms")}</span>
                      </div>
                      <div className="hmTabActvIndcator"></div>
                    </div>
                  </button>
                  <button
                    className={`hmTabsBtn ${
                      selectedMenu === 21 ? `hmBtnActive` : ``
                    }`}
                    onClick={() => {
                      setSelectedMenu(21);
                      handleCloseAll();
                      if (run["AdminDetailsAppAs"] === true) {
                        setRun({
                          viewOTPServiceAD: true,
                        });
                      }
                    }}
                  >
                    <div className="hmTabLayer">
                      <div className="hmTabContnt">
                        <span className="hmTbText">{t("otp_service")}</span>
                      </div>
                      <div className="hmTabActvIndcator"></div>
                    </div>
                  </button>
                  <button
                    className={`hmTabsBtn ${
                      selectedMenu === 22 ? `hmBtnActive` : ``
                    }`}
                    onClick={() => {
                      setSelectedMenu(22);
                      handleCloseAll();
                      if (run["AdminDetailsAppAs"] === true) {
                        setRun({
                          viewhealthCareConfigAD: true,
                        });
                      }
                    }}
                  >
                    <div className="hmTabLayer">
                      <div className="hmTabContnt">
                        <span className="hmTbText">
                          {t("healthcare_config")}
                        </span>
                      </div>
                      <div className="hmTabActvIndcator"></div>
                    </div>
                  </button>
                </div>
                {showRightArrow && (
                  <IconButton
                    onClick={scrollRight}
                    className="lrarrowBtn right"
                  >
                    <ArrowForwardIos />
                  </IconButton>
                )}
              </div>

              {/* units tabs contain start */}
              {selectedMenu === 0 && (
                <DefineOrganization
                  handelOpenEditUpdateOrg={handelOpenEditUpdateOrg}
                  refreshOrganisation={refreshOrganisation}
                  orgDetailsData={orgDetailsData}
                />
              )}

              {selectedMenu === 1 && (
                <DefineUnits
                  handleOpenMenuButton={handleOpenMenuButton}
                  handleOpenUnitTreeView={handleOpenUnitTreeView}
                  refreshUnit={refreshUnit}
                  handleOpenUpdateUnits={handleOpenUpdateUnits}
                />
              )}
              {/* units tabs contain end */}

              {/* Department tabs  start */}
              {/* {selectedMenu === 2 && (
                <DefineDepartment
                  handelOpenCrtDeprtView={handelOpenCrtDeprtView}
                  refreshDepartment={refreshDepartment}
                />
              )} */}
              {/* Department tabs  end */}

              {/* Designation tabs  start */}
              {/* {selectedMenu === 3 && (
                <DefineDesignation
                  handelOpenCrtDesig={handelOpenCrtDesig}
                  refreshDesignation={refreshDesignation}
                />
              )} */}
              {/* Designation tabs  end */}

              {/* Position tabs  start */}
              {/* {selectedMenu === 4 && (
                <DefinePosition
                  handelOpenCrtPosition={handelOpenCrtPosition}
                  refreshPosition={refreshPosition}
                />
              )} */}
              {/* Position tabs  end */}

              {/* units tabs Commitee start */}
              {selectedMenu === 5 && (
                <DefineCommittee
                  handelOpenCrtCommittee={handelOpenCrtCommittee}
                  refreshCommittee={refreshCommittee}
                />
              )}
              {/* units tabs Commitee end */}

              {/* Expenditure tabs  start */}
              {selectedMenu === 6 && <DefineExpenditure />}
              {/* Expenditure tabs  end */}

              {/* DefineCriticalRights tabs  start */}
              {/* {selectedMenu === 7 && (
                <DefineCriticalRights
                  handelOpenCreateOrgRight={handelOpenCreateOrgRight}
                  handelOpenCreateExpPwr={handelOpenCreateExpPwr}
                  refresh={refresh}
                />
              )} */}

              {selectedMenu === 7 && (
                <DefineCriticalRightsNew
                  handelOpenCreateOrgRight={handelOpenCreateOrgRight}
                  handelOpenCreateExpPwr={handelOpenCreateExpPwr}
                  refresh={refresh}
                  orgDetailsData={orgDetailsData}
                />
              )}

              {/* DefineCriticalRights tabs  end */}

              {/* Tax and statutory tabs  start */}
              {selectedMenu === 8 && (
                <DefineTaxStatutory
                  handelOpencreateTaxStatutory={handelOpencreateTaxStatutory}
                  refreshTaxStatutory={refreshTaxStatutory}
                />
              )}
              {/* Tax and statutory tabs  End */}

              {/* Storage tabs  start */}
              {selectedMenu === 9 && (
                <DefineStorage
                  handleOpenCreateOrgStorage={handleOpenCreateOrgStorage}
                  handleOpenUpdateOrgStorage={handleOpenUpdateOrgStorage}
                  refreshStorage={refreshStorage}
                />
              )}
              {/* Storage tabs  End */}

              {/* Salary Head tabs  start */}
              {selectedMenu === 10 && (
                <DefineSalaryHead
                  handelOpenCreateSalaryHead={handelOpenCreateSalaryHead}
                  refreshSalaryHead={refreshSalaryHead}
                />
              )}
              {/* Salary Head tabs  End */}

              {/* Coupon tabs  start */}
              {selectedMenu === 11 && (
                <DefineCoupon
                  handelOpenCreateCouponCode={handelOpenCreateCouponCode}
                  refreshCouponCode={refreshCouponCode}
                />
              )}
              {/* Coupon tabs  End */}

              {/* ActivityTemplate start */}
              {selectedMenu === 12 && (
                <DefineActivityTemplate
                  handelOpenActivityTemplate={handelOpenActivityTemplate}
                  refreshActivityTemplate={refreshActivityTemplate}
                  handelOpenViewActivityTemplate={
                    handelOpenViewActivityTemplate
                  }
                />
              )}
              {/* ActivityTemplate end */}

              {/* Policy start */}
              {selectedMenu === 13 && (
                <DefinePolicy
                  handelCloseViewPolicies={handelCloseViewPolicies}
                  handelOpenAddPolicies={handelOpenAddPolicies}
                  refresh={refresh}
                />
              )}
              {/* Policy end */}

              {/* Terms & condition start */}
              {selectedMenu === 14 && (
                <DefineTermsConditions
                  handelOpenCreateTermsConditions={
                    handelOpenCreateTermsConditions
                  }
                  refreshTermsConditions={refreshTermsConditions}
                  handelOpenEditTermsCondition={handelOpenEditTermsCondition}
                />
              )}
              {/* Terms & condition end */}

              {/* Terms & condition start */}
              {selectedMenu === 15 && (
                <DefineAccessControl
                  handelOpenAssignAccessControl={handelOpenAssignAccessControl}
                  refreshAccessControl={refreshAccessControl}
                  handleOpenUpdatedAccessControl={
                    handleOpenUpdatedAccessControl
                  }
                />
              )}
              {/* Terms & condition end */}

              {/* Website Template */}
              {selectedMenu === 16 && (
                <ConfirmProvider>
                  <SelectTemplate
                    admin={true}
                    onboardingData={{
                      orgId: loginUserDetail.orgId,
                      subdomain: loginUserDetail.subdomain,
                    }}
                  />
                </ConfirmProvider>
              )}
              {/* Website Template  */}

              {/* set pesition start */}
              {selectedMenu === 17 && (
                <DefineSetPosition orgDetailsData={orgDetailsData} />
              )}
              {/* set pesition end */}

              {/* PMS start */}
              {selectedMenu === 18 && <DefinePMS />}
              {/* PMS end */}

              {/* start payroll*/}
              {selectedMenu === 19 && (
                <DefinePayroll
                  handelOpenASC={setActiveCreationMenu}
                  handelOpenSalaryTemplate={handelOpenSalaryTemplate}
                />
              )}
              {/* end payroll*/}

              {/* set Salary Component start */}
              {selectedMenu === 20 && (
                <DefineSalaryComponent
                  handelOpenNewSalaryComponent={handelOpenNewSalaryComponent}
                  handelOpnEditSalaryComponent={handelOpnEditSalaryComponent}
                  refreshSalaryComponent={refreshSalaryComponent}
                />
              )}
              {/*  set Salary Component end */}

              {selectedMenu === 21 && <DefineOtpService />}

              {selectedMenu === 22 && (
                <DefineHealthcareConfig
                  handelOpenAddNewSTP={handelOpenAddNewSTP}
                  handleOpenEditSTP={handleOpenEditSTP}
                />
              )}
            </div>

            {showFlotingContainer && (
              <RightFlotingAdmin>
                <CreateUnits
                  handleCloseMenuButton={handleCloseMenuButton}
                  refreshUnitData={refreshUnitData}
                  orgDetailsData={orgDetailsData}
                />
              </RightFlotingAdmin>
            )}
            {unitTreeView && (
              <RightFlotingAdmin>
                <UnitTreeView
                  handleCloseUnitTreeView={handleCloseUnitTreeView}
                />
              </RightFlotingAdmin>
            )}

            {createDepartView && (
              <RightFlotingAdmin>
                <CreateDepartment
                  handelCloseCrtDeprtView={handelCloseCrtDeprtView}
                  refresDepartmenthData={refresDepartmenthData}
                  selectDepartment={selectDepartment}
                />
              </RightFlotingAdmin>
            )}
            {createDesignation && (
              <RightFlotingAdmin>
                <CreateDesignation
                  handelCloseCrtDesig={handelCloseCrtDesig}
                  selectedDesignation={selectedDesignation}
                  refreshDesignationData={refreshDesignationData}
                />
              </RightFlotingAdmin>
            )}
            {createPosition && (
              <RightFlotingAdmin>
                <CreatePosition
                  handelCloseCrtPosition={handelCloseCrtPosition}
                  selectedPosition={selectedPosition}
                  refreshPositionData={refreshPositionData}
                  setSelectedPosition={setSelectedPosition}
                />
              </RightFlotingAdmin>
            )}
            {createCommittee && (
              <RightFlotingAdmin>
                <CreateCommittee
                  handelCloseCrtCommittee={handelCloseCrtCommittee}
                  selectedCommittee={selectedCommittee}
                  refreshCommitteeData={refreshCommitteeData}
                />
              </RightFlotingAdmin>
            )}

            {createOrgRight && (
              <RightFlotingAdmin>
                <CreateOrgRights
                  handelCloseCreateOrgRight={handelCloseCreateOrgRight}
                  onSuccess={refreshData}
                  selectedCriticalRights={selectedCriticalRights}
                />
              </RightFlotingAdmin>
            )}

            {createExpPowr && (
              <RightFlotingAdmin>
                <CreateExpenditurPower
                  handelCloseCreateExpPwr={handelCloseCreateExpPwr}
                  onSuccess={refreshData}
                  selectExpPowr={selectExpPowr}
                />
              </RightFlotingAdmin>
            )}

            {createTaxStatutory && (
              <RightFlotingAdmin>
                <CreateTaxStatutory
                  handelClosecreateTaxStatutory={handelClosecreateTaxStatutory}
                  setRefreshTaxStatutory={setRefreshTaxStatutory}
                  close={() => {
                    setCreateTaxStatutory(false);
                  }}
                />
              </RightFlotingAdmin>
            )}

            {createOrgStorage && (
              <RightFlotingAdmin>
                <CreateStorage
                  handleCloseCreateOrgStorage={handleCloseCreateOrgStorage}
                  refreshStorageData={refreshStorageData}
                />
              </RightFlotingAdmin>
            )}

            {updateOrgStorage && (
              <RightFlotingAdmin>
                <UpdateStorage
                  handleCloseUpdateOrgStorage={handleCloseUpdateOrgStorage}
                  selectedUpdateStorage={selectedUpdateStorage}
                  refreshStorageData={refreshStorageData}
                />
              </RightFlotingAdmin>
            )}

            {updateUnits && (
              <RightFlotingAdmin>
                <UpdateUnit
                  handleCloseUpdateUnits={handleCloseUpdateUnits}
                  selectedUpdateUnits={selectedUpdateUnits}
                  refreshUnitData={refreshUnitData}
                />
              </RightFlotingAdmin>
            )}
            {createSalaryHead && (
              <RightFlotingAdmin>
                <CerateSalaryHead
                  handelCloseCreateSalaryHead={handelCloseCreateSalaryHead}
                  refreshSalaryHeadData={refreshSalaryHeadData}
                />
              </RightFlotingAdmin>
            )}

            {loginUserDetail.orgName === "Miniontek" && createCouponCode && (
              <RightFlotingAdmin>
                <CreateCoupon
                  handelCloseCreateCouponCode={handelCloseCreateCouponCode}
                  refreshCouponCodeData={refreshCouponCodeData}
                />
              </RightFlotingAdmin>
            )}

            {showAddActivityTemplate && (
              <RightFlotingAdmin>
                <CreateActivityTemplate
                  handelCloseActivityTemplate={handelCloseActivityTemplate}
                  refreshActivityTemplateData={refreshActivityTemplateData}
                />
              </RightFlotingAdmin>
            )}
            {showViewActivityTemplate && (
              <RightFlotingAdmin>
                <ViewActivityTemplate
                  handelCloseViewActivityTemplate={
                    handelCloseViewActivityTemplate
                  }
                  refreshActivityTemplateData={refreshActivityTemplateData}
                  showSelectedViewActivityTemplate={
                    showSelectedViewActivityTemplate
                  }
                />
              </RightFlotingAdmin>
            )}

            {showAddPolicies && (
              <RightFlotingAdmin>
                <CreatePolicy
                  handelCloseAddPolicies={handelCloseAddPolicies}
                  refreshData={refreshData}
                />
              </RightFlotingAdmin>
            )}

            {showAddTermsConditions && (
              <RightFlotingAdmin>
                <CreateTermsConditions
                  handelCloseCreateTermsConditions={
                    handelCloseCreateTermsConditions
                  }
                  refreshTermsConditionsData={refreshTermsConditionsData}
                />
              </RightFlotingAdmin>
            )}

            {showSelectedTermsCondition && (
              <RightFlotingAdmin>
                <UpdateTermsConditions
                  handelCloseEditTermsCondition={handelCloseEditTermsCondition}
                  selecttedViewTermsCondition={selecttedViewTermsCondition}
                  refreshTermsConditionsData={refreshTermsConditionsData}
                />
              </RightFlotingAdmin>
            )}

            {showAddAccessControl && (
              <RightFlotingAdmin>
                <CreateAccessControl
                  handelCloseAssignAccessControl={
                    handelCloseAssignAccessControl
                  }
                  refreshAccessControlData={refreshAccessControlData}
                />
              </RightFlotingAdmin>
            )}

            {showUpdateOrg && (
              <RightFlotingAdmin>
                <UpdateOrganization
                  handelCloseEditUpdateOrg={handelCloseEditUpdateOrg}
                  showUpdateOrgData={showUpdateOrgData}
                  refreshOrganisationData={refreshOrganisationData}
                />
              </RightFlotingAdmin>
            )}

            {updatedAccessControlModel && (
              <RightFlotingAdmin>
                <UpdateAccessControl
                  handleCloseUpdatedAccessControl={
                    handleCloseUpdatedAccessControl
                  }
                  refreshAccessControlData={refreshAccessControlData}
                  updatedAccessControlData={updatedAccessControlData}
                />
              </RightFlotingAdmin>
            )}

            {activeCreationMenu !== "" && (
              <RightFlotingAdmin>
                <AddSalaryComponents
                  activeCreationMenu={activeCreationMenu}
                  setActiveCreationMenu={() => setActiveCreationMenu("")}
                />
              </RightFlotingAdmin>
            )}
            {addSalaryTemplate && (
              <RightFlotingAdmin>
                <AddSalaryTemplate
                  handelCloseSalaryTemplate={handelCloseSalaryTemplate}
                />
              </RightFlotingAdmin>
            )}

            {addNewSalaryComponent && (
              <RightFlotingAdmin>
                <CreateSalaryComponent
                  handelCloseNewSalaryComponent={handelCloseNewSalaryComponent}
                  refreshSalaryComponentData={refreshSalaryComponentData}
                />
              </RightFlotingAdmin>
            )}

            {editSalaryComponent && (
              <RightFlotingAdmin>
                <UpdateSalaryComponent
                  handelClsEditSalaryComponent={handelClsEditSalaryComponent}
                  editSalaryComponentData={editSalaryComponentData}
                  refreshSalaryComponentData={refreshSalaryComponentData}
                />
              </RightFlotingAdmin>
            )}

            {addNewSTP && (
              <RightFlotingAdmin>
                <CreateSTP handelCloseAddNewSTP={handelCloseAddNewSTP} />
              </RightFlotingAdmin>
            )}
            {editStp && (
              <RightFlotingAdmin>
                <EditStp
                  handelCloseEditStp={handelCloseEditStp}
                  editStpData={editStpData}
                />
              </RightFlotingAdmin>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
