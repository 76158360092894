import { Add, Cancel, Edit } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { NumericFormatCustom } from "./NumericFormatCustom";
import { useEffect } from "react";
import TaskService from "../../../../../services/TaskService";
import { GetLoginUserDetails } from "../../../../../utils/Utils";
import { AddItemModal } from "./AddItemModal";
import { getItemUOM } from "../../../../../services/AdminService";
import { Trash } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

function GoodsServicesForm({
  ExpenditureGoods,
  ProcessDirect,
  tableDataList,
  setTableDataList,
  previewMode,
  budgetHeadList,
  otherCharges,
  setOtherCharges,
}) {
  const loginUserDetail = GetLoginUserDetails();
  const { t } = useTranslation();
  const [ledgerHeadRefresh, setLedgerHeadRefresh] = useState(0);
  const [orgItemList, setOrgItemList] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [refreshCount, setRefreshCount] = useState(0);
  const [itemUOMList, setItemUOMList] = useState([]);
  const [openAddNewItemModal, setOpenAddNewItemModal] = useState(false);
  const [shouldUpdate, setShouldUpdate] = useState(1);
  const [allTimeFormData, setAllTimeFormData] = useState({
    id: new Date().valueOf(),
    itemName: "",
    itemDescription: "",
    itemId: "",
    uom: "",
    stockQty: 0,
    indentQty: 0,
    purpose: "",
    prePurshaseRate: 0,
    estimatedRate: 0,
    estimatedValue: 0,
    editMode: true,
  });

  const [showAddOtherCharges, setShowAddOtherCharges] = useState(false);
  const [otherChargesDescription, setOtherChargesDescription] = useState("");
  const [otherChargesValue, setOtherChargesValue] = useState(0);

  const [valuesInd, setValuesInd] = useState({
    numberformat: "0",
  });

  const initializeAllTimeForeData = () => {
    setAllTimeFormData({
      id: new Date().valueOf(),
      itemName: "",
      itemDescription: "",
      itemId: "",
      uom: "",
      stockQty: 0,
      indentQty: 0,
      purpose: "",
      prePurshaseRate: 0,
      estimatedRate: 0,
      estimatedValue: 0,
      editMode: true,
    });
  };

  useEffect(() => {
    // TaskService.getBudgetHeads(loginUserDetail.orgId).then((response) => {
    //   if (response.data) {
    //     console.log("fetchLedgerHeadData", response.data);
    //     setLedgerHeadList(response.data);
    //   }
    // });

    TaskService.getItemUOM(loginUserDetail.orgId).then((response) => {
      setItemUOMList(response.data);
    });
    setRefreshCount((prev) => prev + 1);
  }, [shouldUpdate]);

  useEffect(() => {
    if (ExpenditureGoods === "goods" || ExpenditureGoods === "services") {
      TaskService.getItemsByType(loginUserDetail.orgId, ExpenditureGoods).then(
        (response) => {
          let tmpList = [];
          if (response.data && response.data.length > 0) {
            response.data.forEach((item) => {
              tmpList.push({
                ...item,
                ...{ label: item.itemName },
              });
            });
          }
          setOrgItemList(tmpList);
        }
      );
    }
  }, [ExpenditureGoods, shouldUpdate]);

  const calculateRowTotal = (rowId, qty, rate) => {
    console.log("calculated row total", qty, "  ", rate);
    const value = qty * rate;
    let itemIndex = tableDataList.findIndex((obj) => obj.id === rowId);
    tableDataList[itemIndex].estimatedValue = value;
    return value ? value : 0;
  };
  const calculateAllTimeTotalRow = (rowId, qty, rate) => {
    console.log("calculated row total", qty, "  ", rate);
    const value = qty * rate;
    allTimeFormData.estimatedValue = value;
    return value ? value : 0;
  };

  const calculateTotalValue = () => {
    let totalVal = 0;
    for (let i = 0; i < tableDataList.length; i++) {
      const { indentQty, estimatedRate } = tableDataList[i];
      totalVal += parseInt(indentQty) * parseInt(estimatedRate);
    }
    return totalVal ? totalVal : 0;
  };

  const calculateSubTotal = (itemList) => {
    let totalVal = 0;
    for (let i = 0; i < itemList.length; i++) {
      const { indentQty, estimatedRate } = itemList[i];
      totalVal += parseInt(indentQty) * parseInt(estimatedRate);
    }
    return totalVal ? totalVal : 0;
  };

  const handleSaveNewItem = (item) => {
    if (item.itemName == null || item.itemName === "") {
      toast.error(t("please_select_item_or_add_new_item"), {
        position: toast.POSITION.TOP_RIGHT,
      });

      return false;
    } else if (item.itemDescription == null || item.itemDescription === "") {
      toast.error(t("Please_enter_description"), {
        position: toast.POSITION.TOP_RIGHT,
      });

      return false;
    } else if (item.purpose === "") {
      toast.error(t("please_select_budget_head"), {
        position: toast.POSITION.TOP_RIGHT,
      });

      return false;
    } else if (item.indentQty <= 0) {
      toast.error(t("please_enter_indent_quantity"), {
        position: toast.POSITION.TOP_RIGHT,
      });

      return false;
    } else if (item.estimatedRate <= 0) {
      toast.error(t("estimated_rate_cannot_be_less_then_or_equal_0"), {
        position: toast.POSITION.TOP_RIGHT,
      });

      return false;
    } else if (item.estimatedValue <= 0) {
      toast.error(t("estimated_value_cannot_be_less_then_or_equal_0"), {
        position: toast.POSITION.TOP_RIGHT,
      });

      return false;
    } else if (item.purpose === "") {
      toast.error(t("please_select_purpose_on_row"), {
        position: toast.POSITION.TOP_RIGHT,
      });

      return false;
    } else {
      // tableDataList[index].editMode = false;

      setTableDataList((prev) => [...prev, item]);
      return true;
    }
  };

  const handleClickRemoveRow = (row) => {
    const filterData = tableDataList.filter(
      (obj) => JSON.stringify(obj) !== JSON.stringify(row)
    );
    setTableDataList(filterData);
  };

  const handleSaveItem = (index) => {
    if (tableDataList.length > 0) {
      if (
        tableDataList[index].itemName == null ||
        tableDataList[index].itemName === ""
      ) {
        toast.error(t("please_select_item_or_add_new_item"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      } else if (
        tableDataList[index].itemDescription == null ||
        tableDataList[index].itemDescription === ""
      ) {
        toast.error(t("Please_enter_description"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      } else if (tableDataList[index].purpose === "") {
        toast.error(t("please_select_budget_head"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      } else if (tableDataList[index].indentQty <= 0) {
        toast.error(t("please_enter_indent_quantity"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      } else if (tableDataList[index].estimatedRate <= 0) {
        toast.error(t("estimated_rate_cannot_be_less_then_or_equal_0"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      } else if (tableDataList[index].estimatedValue <= 0) {
        toast.error(t("estimated_value_cannot_be_less_then_or_equal_0"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      } else if (tableDataList[index].purpose === "") {
        toast.error(t("please_select_purpose_on_row"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      } else {
        tableDataList[index].editMode = false;
        setTableDataList([...tableDataList]);
        setIsEditing(false);
      }
    }
  };
  const handelChangeItem = (row, value) => {
    let tempValue = value;
    if (value === null) {
      tempValue = {
        itemName: "",
        itemDescription: "",
        itemId: "",
        defaultUom: null,
        currentStk: 0,
        lastPurchaseRate: 0,
      };
    }
    const {
      itemName,
      itemDescription,
      itemId,
      defaultUom,
      currentStk,
      lastPurchaseRate,
    } = tempValue;

    let itemIndex = tableDataList.findIndex(
      (obj) => JSON.stringify(obj) === JSON.stringify(row)
    );

    const temp = [...tableDataList];
    temp[itemIndex].itemName = itemName;
    temp[itemIndex].itemDescription = itemDescription;
    temp[itemIndex].itemId = itemId;
    temp[itemIndex].uom = defaultUom;
    temp[itemIndex].currentStk = currentStk;
    temp[itemIndex].prePurshaseRate = parseFloat(lastPurchaseRate);
    temp[itemIndex].estimatedRate = parseFloat(lastPurchaseRate);
    setTableDataList(temp);
    // setRefreshCount(refreshCount + 1);
  };

  const handleClickAddRow = () => {
    if (tableDataList.length > 0) {
      let ind;
      tableDataList.forEach((item, index) => {
        console.log("item=", item);
        if (item.editMode === true) {
          console.log("element found at ", index);
          ind = index;
        }
      });
      let len;
      if (ind === undefined) {
        len = 0;
      } else {
        len = ind;
        // return
      }
      console.log("index=", len, tableDataList);
      if (
        tableDataList[len].itemName == null ||
        tableDataList[len].itemName === ""
      ) {
        toast.error(t("please_select_item_or_add_new_item"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      } else if (
        tableDataList[len].itemDescription == null ||
        tableDataList[len].itemDescription === ""
      ) {
        toast.error(t("Please_enter_description"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      } else if (tableDataList[len].purpose === "") {
        toast.error(t("please_select_the_ledger"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      } else if (tableDataList[len].indentQty <= 0) {
        toast.error(t("please_enter_indent_quantity"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      } else if (tableDataList[len].estimatedRate <= 0) {
        toast.error(t("estimated_rate_cannot_be_less_then_or_equal_0"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      } else if (tableDataList[len].estimatedValue <= 0) {
        toast.error(t("estimated_value_cannot_be_less_then_or_equal_0"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      } else if (tableDataList[len].purpose === "") {
        toast.error(t("please_select_purpose_on_row"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      } else {
        tableDataList[len].editMode = false;

        const newObj = {
          id: new Date().valueOf(),
          itemName: "",
          itemDescription: "",
          itemId: "",
          uom: "",
          stockQty: 0,
          indentQty: 0,
          purpose: "",
          prePurshaseRate: 0,
          estimatedRate: 0,
          estimatedValue: 0,
          editMode: true,
        };
        setTableDataList([...tableDataList, newObj]);
        setIsEditing(true);
      }
    }
    const newObj = {
      id: new Date().valueOf(),
      itemName: "",
      itemDescription: "",
      itemId: "",
      uom: "",
      stockQty: 0,
      indentQty: 0,
      purpose: "",
      prePurshaseRate: 0,
      estimatedRate: 0,
      estimatedValue: 0,
      editMode: true,
      currentStk: 0,
    };
    setTableDataList([...tableDataList, newObj]);
    setIsEditing(true);
  };

  return (
    <>
      <div className="indentFormtable">
        <div className="indtableResponsive">
          <table>
            <thead>
              <tr>
                <th className="w200">
                  {ExpenditureGoods === "services"
                    ? t("service_name")
                    : t("item_name")}
                </th>
                <th>
                  <Tooltip arrow title="Description / Specification mandatory">
                    {t("desc_spec")}
                  </Tooltip>
                </th>
                <th>{t("budget_head")} *</th>
                <th>{t("uom")} *</th>
                {ExpenditureGoods === "goods" && (
                  <th className="StockQty">{t("stock_qty")}</th>
                )}
                {/* <th className="widthMin120">
                  <Tooltip arrow title="Previous Purchase Rate">
                    Prev. Purc. Rate
                  </Tooltip>
                </th> */}
                <th className="indntQunt">{t("indent_qty")} *</th>
                <th className="widthMin120">{t("estimated_rate")} *</th>
                <th className="widthMin120">{t("estimated_value")}</th>
                <th className="mw80">
                  {/* <div className="iconBtnGroup">
                    <Tooltip arrow title="Add new Item">
                      <IconButton onClick={handleClickAddRow}>
                        <Add />
                      </IconButton>
                    </Tooltip>
                  </div> */}
                </th>
              </tr>
            </thead>
            <tbody>
              {console.log("table data list", tableDataList)}
              {tableDataList.length > 0 &&
                tableDataList.map((item, index) => {
                  if (item.editMode) {
                    return (
                      <tr>
                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <Autocomplete
                                className="formAutocompleteField"
                                variant="outlined"
                                disabled={!item.editMode}
                                disableClearable
                                options={orgItemList}
                                defaultValue={item.itemName}
                                onChange={(event, newValue) => {
                                  // console.log("newvalue=", newValue);
                                  handelChangeItem(item, newValue);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    className="formAutoComInputField autocompFildPlaceholder"
                                    {...params}
                                    label={t("type_to_search")}
                                  />
                                )}
                                size="small"
                              />
                            </FormControl>
                            {/* <FormControl className="formControl">
                                <InputLabel id="selectItems">Items</InputLabel>
                                <Select
                                  className="formInputField"
                                  variant="outlined"
                                  labelId="selectItems"
                                  id="select-Items"
                                  value={showItems}
                                  label="Items"
                                  onChange={handleselectItems}
                                >
                                  <MenuItem value={"laptop"}>Laptop</MenuItem>
                                  <MenuItem value={"keyboard"}>
                                    {" "}
                                    Keyboard{" "}
                                  </MenuItem>
                                  <MenuItem value={"mouse"}> Mouse </MenuItem>
                                  <MenuItem value={"monitor"}> Monitor </MenuItem>
                                  <MenuItem value={"printer"}> Printer </MenuItem>
                                </Select>
                              </FormControl> */}
                          </div>
                        </td>
                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                label={t("description_specification_mandatory")}
                                variant="outlined"
                                className="formTextFieldArea"
                                disabled={!item.editMode}
                                required
                                fullWidth
                                size="small"
                                value={item.itemDescription}
                                onChange={(e) => {
                                  item.itemDescription = e.target.value;
                                  setRefreshCount((r) => r + 1);
                                }}
                              />
                            </FormControl>
                          </div>
                        </td>
                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <InputLabel id="selectLedgerHead">
                                {t("budget_head")}
                              </InputLabel>
                              <Select
                                className="formInputField"
                                variant="outlined"
                                labelId="selectLedgerHead"
                                id="select-LedgerHead"
                                label={t("budget_head")}
                                disabled={!item.editMode}
                                value={item.purpose}
                                onChange={(e) => {
                                  item.purpose = e.target.value;
                                  setLedgerHeadRefresh((r) => r + 1);
                                }}
                              >
                                {budgetHeadList.length > 0 &&
                                  budgetHeadList.map((itemName, index) => (
                                    <MenuItem
                                      value={itemName}
                                      key={itemName.head + "_" + index}
                                    >
                                      {itemName.head}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </div>
                        </td>
                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <InputLabel id="selectUOM">{t("uom")}</InputLabel>
                              <Select
                                className="formInputField"
                                variant="outlined"
                                labelId="selectUOM"
                                id="select-UOM"
                                label={t("uom")}
                                disabled={!item.editMode}
                                value={item.uom}
                                onChange={(e) => {
                                  item.uom = e.target.value;
                                  setRefreshCount((r) => r + 1);
                                }}
                              >
                                {itemUOMList.length > 0 &&
                                  itemUOMList.map((item) => (
                                    <MenuItem value={item} key={item}>
                                      {item}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </div>
                        </td>
                        <td>
                          <div className="textOnly">
                            {refreshCount >= 0 && item.currentStk}
                          </div>
                        </td>
                        <td>
                          <div className="textOnly">
                            {refreshCount >= 0 && item.prePurshaseRate}
                          </div>
                        </td>
                        {ExpenditureGoods === "goods" && (
                          <td>
                            <div className="formElement">
                              <FormControl className="formControl">
                                <TextField
                                  disabled={!item.editMode}
                                  label={t("indent_qty")}
                                  value={valuesInd.indentQty}
                                  name="numberformat"
                                  id="IndentQty"
                                  InputProps={{
                                    inputComponent: NumericFormatCustom,
                                  }}
                                  defaultValue={item.indentQty}
                                  onChange={(e) => {
                                    const temp = parseInt(e.target.value);
                                    if (isNaN(temp)) {
                                      item.indentQty = "";
                                    } else {
                                      item.indentQty = temp;
                                    }
                                    setRefreshCount((r) => r + 1);
                                  }}
                                  variant="outlined"
                                  className="formTextFieldArea"
                                />
                              </FormControl>
                            </div>
                          </td>
                        )}
                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                disabled={!item.editMode}
                                label={t("estimated_rate")}
                                required
                                name="numberformat"
                                id="EstimatedRate"
                                InputProps={{
                                  inputComponent: NumericFormatCustom,
                                }}
                                variant="outlined"
                                className="formTextFieldArea"
                                value={item.estimatedRate}
                                onChange={(e) => {
                                  item.estimatedRate = e.target.value;
                                  setRefreshCount((r) => r + 1);
                                }}
                              />
                            </FormControl>
                          </div>
                        </td>
                        <td>
                          <div className="textOnly">
                            {refreshCount >= 0 &&
                              calculateRowTotal(
                                item.id,
                                item.indentQty,
                                item.estimatedRate
                              )}
                          </div>
                        </td>
                        <td>
                          <div className="iconBtnGroup">
                            {item.editMode === true && (
                              <Button
                                className="saveBtn"
                                onClick={(e) => handleSaveItem(index)}
                              >
                                {t("save")}
                              </Button>
                            )}
                            {item.editMode === false && (
                              <Tooltip title={t("edit_item")} placement="left">
                                <IconButton
                                  className={
                                    item.editMode === false &&
                                    isEditing &&
                                    `deactivatedEditButton`
                                  }
                                  disabled={
                                    item.editMode === false && isEditing
                                  }
                                  onClick={() => {
                                    setIsEditing(true);
                                    tableDataList[index].editMode = true;
                                    setTableDataList([...tableDataList]);
                                  }}
                                >
                                  <Edit />
                                </IconButton>
                              </Tooltip>
                            )}
                            <IconButton
                              onClick={() => {
                                if (item.editMode === true) {
                                  setIsEditing(false);
                                }
                                handleClickRemoveRow(item);
                              }}
                            >
                              <Trash />
                            </IconButton>
                          </div>
                        </td>
                      </tr>
                    );
                  } else {
                    return (
                      <tr>
                        <td>{item.itemName}</td>
                        <td>
                          <div className="textOnly">{item.itemDescription}</div>
                        </td>
                        <td>
                          <div className="textOnly">{item.purpose.head}</div>
                        </td>
                        <td>
                          <div className="textOnly">{item.uom}</div>
                        </td>
                        {ExpenditureGoods === "goods" && (
                          <td>
                            <div className="textOnly">{item.currentStk}</div>
                          </td>
                        )}

                        {/* <td>
                          <div className="textOnly">{item.prePurshaseRate}</div>
                        </td> */}
                        <td>
                          <div className="textOnly">{item.indentQty}</div>
                        </td>
                        <td>
                          <div className="textOnly">{item.estimatedRate}</div>
                        </td>
                        <td>
                          <div className="textOnly">
                            {calculateRowTotal(
                              item.id,
                              item.indentQty,
                              item.estimatedRate
                            )}
                          </div>
                        </td>
                        <td>
                          <div className="iconBtnGroup">
                            {item.editMode === false && (
                              <Tooltip title={t("edit_item")} placement="left">
                                <IconButton
                                  className={
                                    item.editMode === false &&
                                    isEditing &&
                                    `deactivatedEditButton`
                                  }
                                  disabled={
                                    item.editMode === false && isEditing
                                  }
                                  onClick={() => {
                                    console.log("edit button is =");
                                    const temp = [...tableDataList];
                                    temp[index].editMode = true;
                                    setTableDataList(temp);
                                  }}
                                >
                                  <Edit />
                                </IconButton>
                              </Tooltip>
                            )}
                            <IconButton
                              onClick={() => {
                                if (item.editMode === true) {
                                  setIsEditing(false);
                                }
                                handleClickRemoveRow(item);
                              }}
                            >
                              <Trash />
                            </IconButton>
                          </div>
                        </td>
                      </tr>
                    );
                  }
                })}

              <tr>
                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <Autocomplete
                        className="formAutocompleteField"
                        variant="outlined"
                        disabled={!allTimeFormData.editMode}
                        disablePortal
                        options={orgItemList}
                        defaultValue={allTimeFormData.itemName}
                        value={allTimeFormData.itemName}
                        onChange={(event, newValue) => {
                          // handelChangeItem(allTimeFormData, newValue)
                          let temp = { ...allTimeFormData };
                          if (newValue === null) {
                            temp.itemName = "";
                            temp.itemDescription = "";
                            temp.uom = "";
                            temp.itemId = 0;
                            setAllTimeFormData(temp);

                            return;
                          }
                          temp.itemName = newValue.itemName;
                          temp.itemDescription = newValue.itemDescription;
                          temp.uom = newValue.defaultUom;
                          temp.currentStk = newValue.currentStk;
                          temp.itemId = newValue.itemId;
                          setAllTimeFormData(temp);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t("type_to_search")}
                            className="formAutoComInputField autocompFildPlaceholder"
                          />
                        )}
                        size="small"
                      />
                    </FormControl>
                    {/* <FormControl className="formControl">
                                <InputLabel id="selectItems">Items</InputLabel>
                                <Select
                                  className="formInputField"
                                  variant="outlined"
                                  labelId="selectItems"
                                  id="select-Items"
                                  value={showItems}
                                  label="Items"
                                  onChange={handleselectItems}
                                >
                                  <MenuItem value={"laptop"}>Laptop</MenuItem>
                                  <MenuItem value={"keyboard"}>
                                    {" "}
                                    Keyboard{" "}
                                  </MenuItem>
                                  <MenuItem value={"mouse"}> Mouse </MenuItem>
                                  <MenuItem value={"monitor"}> Monitor </MenuItem>
                                  <MenuItem value={"printer"}> Printer </MenuItem>
                                </Select>
                              </FormControl> */}
                  </div>
                </td>
                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        label={t("description_specification_mandatory")}
                        variant="outlined"
                        className="formTextFieldArea"
                        disabled={!allTimeFormData.editMode}
                        required
                        fullWidth
                        size="small"
                        value={allTimeFormData.itemDescription}
                        onChange={(e) => {
                          allTimeFormData.itemDescription = e.target.value;
                          setRefreshCount((r) => r + 1);
                        }}
                      />
                    </FormControl>
                  </div>
                </td>
                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel id="selectLedgerHead">
                        {t("budget_head")}
                      </InputLabel>
                      <Select
                        className="formInputField"
                        variant="outlined"
                        labelId="selectLedgerHead"
                        id="select-LedgerHead"
                        label={t("budget_head")}
                        disabled={!allTimeFormData.editMode}
                        value={allTimeFormData.purpose}
                        onChange={(e) => {
                          allTimeFormData.purpose = e.target.value;
                          setLedgerHeadRefresh((r) => r + 1);
                        }}
                      >
                        {budgetHeadList.length > 0 &&
                          budgetHeadList.map((itemName, index) => (
                            <MenuItem
                              value={itemName}
                              key={itemName.head + "_" + index}
                            >
                              {itemName.head}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                </td>
                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel id="selectUOM">{t("uom")}</InputLabel>
                      <Select
                        className="formInputField"
                        variant="outlined"
                        labelId="selectUOM"
                        id="select-UOM"
                        label={t("uom")}
                        disabled={!allTimeFormData.editMode}
                        value={allTimeFormData.uom}
                        onChange={(e) => {
                          allTimeFormData.uom = e.target.value;
                          setRefreshCount((r) => r + 1);
                        }}
                      >
                        {itemUOMList.length > 0 &&
                          itemUOMList.map((allTimeFormData) => (
                            <MenuItem
                              value={allTimeFormData}
                              key={allTimeFormData}
                            >
                              {allTimeFormData}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                </td>
                {ExpenditureGoods === "goods" && (
                  <td>
                    <div className="textOnly">
                      {refreshCount >= 0 && allTimeFormData.currentStk
                        ? allTimeFormData.currentStk
                        : 0}
                    </div>
                  </td>
                )}
                {/* <td>
                  <div className="textOnly">
                    {refreshCount >= 0 && allTimeFormData.prePurshaseRate}
                  </div>
                </td> */}
                {
                  <td>
                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          // disabled={!allTimeFormData.editMode}
                          label={t("indent_qty")}
                          value={allTimeFormData.indentQty}
                          name="numberformat"
                          id="IndentQty"
                          InputProps={{
                            inputComponent: NumericFormatCustom,
                          }}
                          // defaultValue={allTimeFormData.indentQty}
                          onChange={(e) => {
                            const temp = parseInt(e.target.value);
                            if (isNaN(temp)) {
                              allTimeFormData.indentQty = "";
                            } else {
                              allTimeFormData.indentQty = temp;
                            }
                            setRefreshCount((r) => r + 1);
                          }}
                          variant="outlined"
                          className="formTextFieldArea"
                        />
                      </FormControl>
                    </div>
                  </td>
                }
                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        disabled={!allTimeFormData.editMode}
                        label={t("estimated_rate")}
                        required
                        name="numberformat"
                        id="EstimatedRate"
                        InputProps={{
                          inputComponent: NumericFormatCustom,
                        }}
                        variant="outlined"
                        className="formTextFieldArea"
                        value={allTimeFormData.estimatedRate}
                        onChange={(e) => {
                          allTimeFormData.estimatedRate = e.target.value;
                          setRefreshCount((r) => r + 1);
                        }}
                      />
                    </FormControl>
                  </div>
                </td>
                <td>
                  <div className="textOnly">
                    {refreshCount >= 0 &&
                      allTimeFormData &&
                      calculateAllTimeTotalRow(
                        allTimeFormData.id,
                        allTimeFormData.indentQty,
                        allTimeFormData.estimatedRate
                      )}
                  </div>
                </td>
                <td>
                  <div className="iconBtnGroup">
                    {allTimeFormData.editMode === true && (
                      <Button
                        className="saveBtn"
                        onClick={(e) => {
                          const temp = {
                            ...allTimeFormData,
                            editMode: false,
                          };
                          // setTableDataList((prev) => [...prev, temp]);
                          const bool = handleSaveNewItem(temp);
                          bool && initializeAllTimeForeData();
                        }}
                      >
                        {t("save")}
                      </Button>
                    )}
                  </div>
                </td>
              </tr>

              {ProcessDirect && ProcessDirect === "Direct" && (
                <>
                  <tr>
                    <td colSpan={ExpenditureGoods === "goods" ? "6" : "5"}></td>
                    <td colSpan={1}>{t("sub_total")}</td>
                    <td colSpan={1}>{calculateSubTotal(tableDataList)}</td>
                  </tr>

                  {otherCharges &&
                    otherCharges.length > 0 &&
                    otherCharges.map((item) => {
                      return (
                        <tr>
                          <td
                            colSpan={ExpenditureGoods === "goods" ? "6" : "5"}
                          ></td>
                          <td colSpan={1}>{item.chargesLabel}</td>
                          <td colSpan={1}>{item.chargesAmount}</td>
                          <td>
                            <span style={{ float: "right" }}>
                              <Tooltip title={t("remove")}>
                                <Trash
                                  height={"1.2rem"}
                                  width={"1.2rem"}
                                  cursor={"pointer"}
                                  onClick={() => {
                                    let tmpList = otherCharges.filter(
                                      (obj) =>
                                        JSON.stringify(obj) !==
                                        JSON.stringify(item)
                                    );
                                    setOtherCharges(tmpList);
                                  }}
                                />
                              </Tooltip>
                            </span>
                          </td>
                        </tr>
                      );
                    })}

                  {showAddOtherCharges && (
                    <tr>
                      <td colSpan={4}></td>
                      <td colSpan={2}>
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              disabled={!allTimeFormData.editMode}
                              name="numberformat"
                              id="EstimatedRate"
                              variant="outlined"
                              className="formTextFieldArea"
                              label={t("charge_description")}
                              value={otherChargesDescription}
                              onChange={(e) => {
                                setOtherChargesDescription(e.target.value);
                              }}
                            />
                          </FormControl>
                        </div>
                      </td>
                      <td colSpan={2}>
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              disabled={!allTimeFormData.editMode}
                              name="numberformat"
                              id="EstimatedRate"
                              InputProps={{
                                inputComponent: NumericFormatCustom,
                              }}
                              variant="outlined"
                              className="formTextFieldArea"
                              label={t("price")}
                              value={otherChargesValue}
                              onChange={(e) => {
                                setOtherChargesValue(e.target.value);
                              }}
                            />
                          </FormControl>
                        </div>
                      </td>
                      <td colSpan={1}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Tooltip title={t("add_charge")}>
                            <Button
                              onClick={() => {
                                const temp = {
                                  chargesLabel: otherChargesDescription,
                                  chargesAmount: parseFloat(otherChargesValue),
                                };
                                if (otherChargesDescription.trim() !== "") {
                                  setOtherCharges((prev) => [...prev, temp]);
                                  setOtherChargesDescription("");
                                  setOtherChargesValue(0);
                                  setShowAddOtherCharges(false);
                                }
                              }}
                            >
                              {t("save")}
                            </Button>
                          </Tooltip>
                          <Tooltip title={t("close")}>
                            <Button
                              onClick={() => {
                                setShowAddOtherCharges(false);
                              }}
                            >
                              <Cancel />
                            </Button>
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  )}

                  {!showAddOtherCharges && (
                    <tr>
                      <td colSpan={8}></td>
                      <td colSpan={1}>
                        <Tooltip title={t("add_other_charges")}>
                          <Button
                            disabled={showAddOtherCharges}
                            onClick={() => {
                              setShowAddOtherCharges(true);
                            }}
                          >
                            <Add />
                          </Button>
                        </Tooltip>
                      </td>
                      {/* <TableCell colSpan={1}>12457</TableCell> */}
                    </tr>
                  )}
                </>
              )}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={ExpenditureGoods === "goods" ? "7" : "6"}>
                  <div className="textOnly tvText">{t("total_value")}</div>
                </td>
                <td>
                  <div className="textOnly fw600">
                    {refreshCount && calculateTotalValue()}
                  </div>
                </td>
                <td colSpan="1"></td>
              </tr>
            </tfoot>
          </table>
        </div>
        {!previewMode && (
          <div className="taskVDContBtn mt5">
            <Button
              variant="contained"
              startIcon={<Add />}
              onClick={(e) => {
                setOpenAddNewItemModal(true);
              }}
            >
              {t("add_new_items")}
            </Button>
          </div>
        )}
      </div>
      {openAddNewItemModal && (
        // <AddItemModal showAddModal={openAddNewItemModal} />
        <AddItemModal
          showAddModal={openAddNewItemModal}
          handleClose={() => {
            getItemUOM(loginUserDetail.orgId).then((response) => {
              setItemUOMList(response.data);
            });
            setOpenAddNewItemModal(false);
          }}
          // setShowAddModal={setopenAddNewItemModalue}
          setShouldUpdate={() => {
            console.log("should update called");
            // setShouldUpdate(shouldUpdate + 1)
            setShouldUpdate((prev) => prev + 1);
          }}
          type={ExpenditureGoods}
          from={t("user")}
        />
      )}
    </>
  );
}

export default GoodsServicesForm;
