import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController,
} from "chart.js";
import { Bar, Chart, Doughnut, Line, Pie } from "react-chartjs-2";
import { Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import { getDashboardDataOfItem } from "../../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
// import faker from "faker";
import { useTranslation } from "react-i18next";

export default function CardRoutineWork({
  handelOpenAddRoutineWork,
  handelOpenViewRoutineWork,
  chartDataOfItem,
  menuModuleList,
  userMenuList,
}) {
  console.log("menuModuleList", menuModuleList);
  const { t } = useTranslation();
  // const userDetails = jwtDecode( SecureIndexedDB.getItem("token"));

  // const [chartDataOfItem, setChartDataOfItem] = useState([]);

  // useEffect(() => {
  //   getDashboardDataOfItem(userDetails.orgId, userDetails.userId).then(
  //     (response) => {
  //       console.log("getDashboardDataOfItem", response.data);
  //       setChartDataOfItem(response.data);
  //     }
  //   );
  // }, [userDetails.orgId, userDetails.userId]);

  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    LineController,
    BarController
  );

  // const activityWiseRoutineWorkData = chartDataOfItem.activityWiseRoutineWork || [];
  // console.log('activityWiseRoutineWorkData', activityWiseRoutineWorkData);

  // const data = {
  //   labels: activityWiseRoutineWorkData.map(item => item.label),
  //   datasets: [
  //     {
  //       label: "# Employee",
  //       data:  activityWiseRoutineWorkData.map(item => item.count),
  //       backgroundColor: ["#00ffff", "#1bff00", "#ff9900", "#5600ff"],
  //       hoverBackgroundColor: ["#00ffff", "#1bff00", "#ff9900", "#5600ff"],
  //       borderWidth: 0,
  //       type: "doughnut",
  //     },
  //   ],
  // };

  const activityWiseRoutineWorkData =
    chartDataOfItem.activityWiseRoutineWork || [];

  const hasZeroCount = activityWiseRoutineWorkData.some(
    (item) => item.count === 0
  );

  const datasets = hasZeroCount
    ? [
        {
          label: "",
          data: activityWiseRoutineWorkData.map((item) => item.count),
          backgroundColor: activityWiseRoutineWorkData.map((item) =>
            item.count === 0 ? "#A9A9A9" : "#00ffff"
          ),
          hoverBackgroundColor: activityWiseRoutineWorkData.map((item) =>
            item.count === 0 ? "#A9A9A9" : "#00ffff"
          ),
          borderWidth: 0,
          type: "doughnut",
        },
      ]
    : [
        {
          label: "",
          data: activityWiseRoutineWorkData.map((item) => item.count),
          backgroundColor: ["#00ffff", "#1bff00", "#ff9900", "#5600ff"],
          hoverBackgroundColor: ["#00ffff", "#1bff00", "#ff9900", "#5600ff"],
          borderWidth: 0,
          type: "doughnut",
        },
      ];

  const labels = hasZeroCount
    ? activityWiseRoutineWorkData.map((item) =>
        item.count === 0 ? t("no_data") : item.label
      )
    : activityWiseRoutineWorkData.map((item) => item.label);

  const data = {
    labels: labels,
    datasets: datasets,
  };

  const options = {
    cutout: "80%",

    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 6,
          color: "#d2d2d2",
          font: {
            size: 12,
            weight: "light",
          },
        },
      },
    },
  };

  // curent People status bar and line chart combo start

  const optionsLeave = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 12,
          color: "#d2d2d2",
          font: {
            size: 12,
            weight: "light",
          },
        },
      },
      title: {
        display: false,
        text: "Line Chart",
      },
    },
  };

  // const labels = [
  //   "January",
  //   "February",
  //   "March",
  //   "April",
  //   "May",
  //   "June",
  //   "July",
  // ];

  const dayWiseRoutineWorkData = chartDataOfItem.dayWiseRoutineWork || [];
  console.log("dayWiseRoutineWorkData", dayWiseRoutineWorkData);

  const dataLeave = {
    labels: dayWiseRoutineWorkData.map((item) => item.label),
    datasets: [
      {
        fill: true,
        // label: "Employee On Leave",
        data: dayWiseRoutineWorkData.map((item) => item.count),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
  // curent People status bar and line chart combo end
  return (
    <>
      <div className="anDashIndCard">
        <div className="anDashCardArea">
          <div className="andHeader">
            <div className="andCrdNM">{t("routine_work")}</div>
            <div className="andCrdUnitCount">
              {/* <span title="Attrition">
                Todays Task: <span className="fbold">12%</span>
              </span> */}

              {/* <span title="Tenure">
                Tenure: <span className="fbold">2 Yrs</span>
              </span> */}
            </div>
          </div>

          <div className="andCrdGraf">
            <div className="anDLftGrf">
              <div className="centTxtShow">
                <Pie
                  className="hw100"
                  type="doughnut"
                  data={data}
                  options={options}
                />
                <div className="highlighted-text">
                  {/* <span>Total</span> */}
                  <span className="highDataCount">
                    {chartDataOfItem.totalRoutineWork}
                  </span>
                </div>
              </div>
            </div>
            <div className="anDRightGrf">
              <Line
                className="hw100"
                type="line"
                data={dataLeave}
                options={optionsLeave}
              />
              {/* <Bar data={barLineData} options={barLineOoptions} /> */}
            </div>
          </div>
          {/* <div className="andCrdData"></div> */}
          <div className="andCrdFooter">
            {userMenuList &&
              menuModuleList &&
              userMenuList.includes(
                menuModuleList.find(
                  (item) => item.menuName === "Routine Work View"
                ).menuId
              ) && (
                <Button
                  className="dfultPrimaryBtn "
                  onClick={() => handelOpenViewRoutineWork()}
                >
                  {t("view")}
                </Button>
              )}

            {userMenuList &&
              menuModuleList &&
              userMenuList.includes(
                menuModuleList.find(
                  (item) => item.menuName === "Routine Work Add"
                ).menuId
              ) && (
                <Button
                  startIcon={<Add />}
                  className="dfultPrimaryBtn "
                  onClick={() => handelOpenAddRoutineWork()}
                >
                  {t("add")}
                </Button>
              )}
          </div>
        </div>
      </div>
    </>
  );
}
