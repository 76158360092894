import { Add, ExpandMoreRounded } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { Trash } from "react-bootstrap-icons";
// import ReactDatePicker from "react-datepicker";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import {
  getFilteredList,
  getHealthDto,
  MAX_SUGGESTED_RESULT,
} from "./consultationUtil";
import DashboardServices from "../../../../../../../services/DashboardServices";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import DateUtils from "../../../../../../../utils/DateUtils";
import { enIN, es } from "date-fns/locale";
import SecureIndexedDB from "../../../../../../../utils/IndexedDB";

// Register locales with ReactDatePicker
registerLocale("en", enIN);
registerLocale("es", es);

export default function HistoryRiskFactor({ updateData, data, extraData }) {
  const { t, i18n } = useTranslation();
  const [accordionExpanded, setAccordionExpanded] = useState(true);

  const [since, setSince] = useState("");
  const [surgeryDate, setSurgeryDate] = useState(null);
  const [pastIllnessDate, setPastIllnessDate] = useState(null);

  // Determine the locale based on the current language
  const currentLocale = i18n.language === "es" ? "es" : "en";

  const [selectedRiscFactor, setSelectedRiscFactor] = useState(null);
  const [riscFactorSearchString, setRiscFactorSearchString] = useState("");

  const [risFactorList, setRiscFactorList] = useState([]);
  const [userRiscFactorList, setUserRiscFactorList] = useState([]);

  //past illness

  const [pastIllness, setPassIllness] = useState("");
  const [userPastIllnessList, setUserPassIllnessList] = useState([]);

  const [selectedPastIllness, setSelectedPastIllness] = useState(null);
  const [pastIllnessString, setPastIllnessString] = useState("");
  const [diseaseList, setDiseaseList] = useState([]);

  //Previous procedure

  const [previousSurgery, setPreviousSurgery] = useState("");
  const [previousSurgeryList, setPreviousSurgeryList] = useState([]);

  const [procedureList, setProcedureList] = useState([]);
  const [selectedProcedure, setSelectedProcedure] = useState(null);
  const [userProcedureList, setUserProcedureList] = useState([]);
  const [procedureString, setProcedureString] = useState("");

  const [riskFactorSince, setRiskFactorSicne] = useState(null);

  const [headingString, setHeadingString] = useState("");

  const suggestedDiseaseList = useMemo(() => {
    const pastIllnessIdList = userPastIllnessList.map(
      (item) => item.illness.id
    );

    let filteredData = diseaseList.filter((disease) => {
      // let symptomVal = "C";
      // if (extraData.queuePatientGender === "Male") {
      //   symptomVal = "M";
      // }
      // if (extraData.queuePatientGender === "Female") {
      //   symptomVal = "F";
      // }
      const alreadyTaken = pastIllnessIdList.some(
        (item) => item === disease.id
      );
      return !alreadyTaken;
    });

    const filteredList = getFilteredList(
      filteredData,
      pastIllnessString,
      "diseaseName"
    );
    return filteredList.slice(0, MAX_SUGGESTED_RESULT);
  }, [pastIllnessString, diseaseList, userPastIllnessList]);

  const suggestedProcedure = useMemo(() => {
    const userProcedureIdList = userProcedureList.map(
      (item) => item.surgery.id
    );

    let filteredData = procedureList.filter((procedure) => {
      let symptomVal = "C";
      if (extraData.queuePatientGender === "Male") {
        symptomVal = "Male";
      }
      if (extraData.queuePatientGender === "Female") {
        symptomVal = "Female";
      }

      const alreadyTaken = userProcedureIdList.some(
        (item) => item === procedure.id
      );
      return (
        !alreadyTaken &&
        (procedure.gender === symptomVal || procedure.gender === "C")
      );
    });

    const filteredList = getFilteredList(
      filteredData,
      procedureString,
      "procedureName"
    );
    return filteredList.slice(0, MAX_SUGGESTED_RESULT);
  }, [procedureList, procedureString, userProcedureList]);

  const getDiseases = async () => {
    const diseasetring = await SecureIndexedDB.getItem("diseaseList");
    if (diseasetring) {
      setDiseaseList(JSON.parse(diseasetring));
      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getDiseases(reqDto).then((response) => {
      if (response.data) {
        setDiseaseList(response.data);
        SecureIndexedDB.setItem("diseaseList", JSON.stringify(response.data));
      }
    });
  };

  const getProcedures = async () => {
    const lifeStyleString = await SecureIndexedDB.getItem("procedureList");
    if (lifeStyleString) {
      setProcedureList(JSON.parse(lifeStyleString));
      return;
    }
    const reqDto = await getHealthDto();
    DashboardServices.getProcedures(reqDto).then((response) => {
      if (response.data) {
        setProcedureList(response.data);
        SecureIndexedDB.setItem("procedureList", JSON.stringify(response.data));
      }
    });
  };

  useEffect(() => {
    let temp = [];
    let str = userRiscFactorList
      .map((item) => {
        const sinceString = item.since ? `(${item.since})` : "";
        return `${item.riskFactor.riskFactor} ${sinceString}`;
      })
      .join(", ");
    if (str?.length > 0) {
      temp.push(str);
    }

    str = userPastIllnessList
      .map((item) => {
        const dateString = item.date
          ? `(${dayjs(item.date).format("DD-MMM-YYYY")})`
          : "";
        return `${item.illness.diseaseName} ${dateString}`;
      })
      .join(", ");

    if (str?.length > 0) {
      temp.push(str);
    }
    str = userProcedureList
      .map((item) => {
        const dateString = item.date
          ? `(${dayjs(item.date).format("DD-MMM-YYYY")})`
          : "";
        return `${item.surgery.procedureName} ${dateString}`;
      })
      .join(", ");
    if (str?.length > 0) {
      temp.push(str);
    }

    const tempStr = temp.join("  ||  ");
    setHeadingString(tempStr);
  }, [userRiscFactorList, userProcedureList, userPastIllnessList]);

  const handlePastProcedure = () => {
    if (!data || !data.prevSurgery) {
      if (extraData && extraData?.reqValue?.procedures?.length > 0) {
        const tempArray = extraData?.reqValue?.procedures?.map((item) => {
          const procedureObj = procedureList.find(
            (procdure) =>
              procdure.procedureName === item.procedureName &&
              procdure.gender === extraData.queuePatientGenders
          );
          let tempProcedure = null;
          if (procedureObj) {
            tempProcedure = {
              id: procedureObj.id,
              procedureName: item.procedureName,
              gender: procedureObj.gender,
              locallyAdded: false,
              previouslyAdded: true,
              prevId: item.id,
            };
          } else {
            tempProcedure = {
              id: uuidv4(),
              procedureName: item.procedureName,
              gender: "",
              locallyAdded: true,
              previouslyAdded: true,
              prevId: item.id,
            };
          }
          const temp = {
            surgery: tempProcedure,
            date: item.procedureDate,
          };
          return temp;
        });
        setUserProcedureList((prev) => {
          const temp = [...tempArray];
          updateData({ prevSurgery: temp });
          return temp;
        });
      }
    }
  };

  const handlePastIllness = () => {
    if (!data || !data.pastIllness) {
      if (extraData && extraData.reqValue && extraData.reqValue.diseases) {
        const tempArray = extraData.reqValue.diseases.map((item, index) => {
          const prevDiseases = diseaseList.find((disease) => {
            return (
              disease.diseaseName === item.diseaseName &&
              disease.gender === extraData.queuePatientGenders
            );
          });
          let tempPastIllness = null;
          if (prevDiseases) {
            tempPastIllness = {
              id: prevDiseases.id,
              diseaseName: item.diseaseName,
              icd11code: item.icd11code,
              snowmedCt: item.snowmedCt,
              previouslyAdded: true,
              prevId: item.id,
            };
          } else {
            tempPastIllness = {
              id: uuidv4(),
              diseaseName: item.diseaseName,
              icd11code: item.icd11code,
              snowmedCt: item.snowmedCt,
              previouslyAdded: true,
              prevId: item.id,
            };
          }
          const temp = {
            illness: tempPastIllness,
            date: new Date(),
          };
          return temp;
        });
        setUserPassIllnessList((prev) => {
          const temp = [...tempArray];
          updateData({ pastIllness: temp });
          return temp;
        });
      }
    }
  };

  useEffect(() => {
    handlePastIllness();
    handlePastProcedure();

    getRiskFactors();
    getRiskFactorSince();
    getDiseases();
    getProcedures();
  }, []);

  useEffect(() => {
    if (data.riskFactor) {
      setUserRiscFactorList(data.riskFactor);
    }
    if (data.prevSurgery) {
      setUserProcedureList(data.prevSurgery);
    }

    if (data.pastIllness) {
      setUserPassIllnessList(data.pastIllness);
    }
  }, [data]);

  // useEffect(() => {
  //   handlePastIllness()
  //   handlePastProcedure()
  // }, [diseaseList, extraData, procedureList]);

  const initializeForm = () => {
    setSelectedRiscFactor(null);
    setRiscFactorSearchString("");
    setSince("");
  };

  const initializePastIllness = () => {
    setSelectedPastIllness(null);
    setPastIllnessString("");
    setPastIllnessDate(null);
  };

  const initializeProcedure = () => {
    setSelectedProcedure(null);
    setProcedureString("");
    setSurgeryDate(null);
  };

  const suggestedRiscFactor = useMemo(() => {
    const riskIdList = userRiscFactorList.map((item) => item.riskFactor.id);

    let filteredData = risFactorList.filter((risk) => {
      const alreadyTaken = riskIdList.some((id) => id === risk.id);
      return !alreadyTaken;
    });

    const filteredList = getFilteredList(
      filteredData,
      riscFactorSearchString,
      "riskFactor"
    );
    return filteredList.slice(0, MAX_SUGGESTED_RESULT);
  }, [riscFactorSearchString, risFactorList, userRiscFactorList]);

  const getRiskFactors = async () => {
    const riskString = await SecureIndexedDB.getItem("riskList");
    if (riskString) {
      setRiscFactorList(JSON.parse(riskString));
      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getRiskFactors(reqDto).then((response) => {
      if (response.data) {
        setRiscFactorList(response.data);
        SecureIndexedDB.setItem("riskList", JSON.stringify(response.data));
      }
    });
  };

  const getRiskFactorSince = async () => {
    const symptomString = await SecureIndexedDB.getItem("riskFactorSince");
    if (symptomString) {
      setRiskFactorSicne(JSON.parse(symptomString));
      return;
    }
    const reqDto = await getHealthDto();
    DashboardServices.getRiskFactorSince(reqDto).then((response) => {
      if (response.data) {
        setRiskFactorSicne(response.data);
        SecureIndexedDB.setItem(
          "riskFactorSince",
          JSON.stringify(response.data)
        );
      }
    });
  };

  const handleChangeSince = (event) => {
    setSince(event.target.value);
  };
  return (
    <>
      <Accordion
        className="docInputElmntAcod"
        expanded={accordionExpanded}
        onChange={() => {
          setAccordionExpanded((prev) => !prev);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreRounded />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="acodHeader"
        >
          <div className="tskElemHeding">
            {t("history_risk_factor")}:
            <span>{!accordionExpanded && headingString}</span>
            {/* <span>symptoms gose here when acordion not expend</span> */}
          </div>
        </AccordionSummary>
        <AccordionDetails className="acodDetails">
          <div className="complaintList">
            <ul>
              {userRiscFactorList &&
                userRiscFactorList.map((item, index) => {
                  return (
                    <li>
                      <div className="complstDtlGrp">
                        <div className="complstDtl  ">
                          <div className="compntName">
                            {item.riskFactor.riskFactor}
                          </div>
                          {item.since && (
                            <div className="compinfoGRP">
                              <div className="compStime">
                                <span>{t("since")}:</span>
                                <span className="data">{item.since}</span>
                              </div>
                            </div>
                          )}
                        </div>
                        {extraData?.isDoctor && (
                          <IconButton
                            className="removeItemBtn"
                            onClick={() => {
                              const temp = [...userRiscFactorList];
                              temp.splice(index, 1);
                              updateData({ riskFactor: temp });
                              setUserRiscFactorList(temp);
                            }}
                          >
                            <Trash />
                          </IconButton>
                        )}
                      </div>
                    </li>
                  );
                })}

              {extraData?.isDoctor && (
                <li>
                  <div className="addNewformGrp">
                    <div className="formElement">
                      <FormControl className="formControl">
                        <Autocomplete
                          freeSolo
                          className="formAutocompleteField"
                          variant="outlined"
                          value={selectedRiscFactor}
                          options={suggestedRiscFactor}
                          inputValue={riscFactorSearchString}
                          onChange={(e, newValue) => {
                            setSelectedRiscFactor(newValue);
                          }}
                          onInputChange={(e, value, reason) => {
                            // if (e && e.target) {
                            //   setRiscFactorSearchString(e.target.value);
                            // }

                            if (e && reason === "input") {
                              console.log("changed input =", value);
                              setRiscFactorSearchString(e.target.value);
                            } else if (reason === "reset") {
                              setRiscFactorSearchString(value);
                            } else if (reason === "clear") {
                              setRiscFactorSearchString(value);
                            }

                            // else {
                            //   setRiscFactorSearchString("");
                            // }
                          }}
                          getOptionLabel={(option) => option.riskFactor}
                          renderOption={(props, item) => {
                            return (
                              <li {...props} key={item.id}>
                                {item.riskFactor}
                              </li>
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              className="formAutoComInputField autocompFildPlaceholder"
                              placeholder={t("search_history_and_risk_factor")}
                              label={t("history_and_risk_factor")}
                              required
                              InputProps={{
                                ...params.InputProps,
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </div>

                    <div className="formElement mxW-150">
                      <FormControl className="formControl">
                        <InputLabel id="since-select-label">
                          {t("since")}
                        </InputLabel>
                        <Select
                          labelId="since-select-label"
                          id="since-select"
                          value={since}
                          label={t("since")}
                          onChange={handleChangeSince}
                          className="formInputField"
                          variant="outlined"
                        >
                          <MenuItem value={null}>{t("select_since")}</MenuItem>
                          {riskFactorSince &&
                            riskFactorSince.map((item) => {
                              return <MenuItem value={item}>{item} </MenuItem>;
                            })}
                        </Select>
                      </FormControl>
                    </div>
                    <Button
                      className="dfultPrimaryBtn "
                      startIcon={<Add />}
                      onClick={() => {
                        let tempRiscFactor = selectedRiscFactor;
                        if (!tempRiscFactor) {
                          if (!riscFactorSearchString.trim()) {
                            setRiscFactorSearchString("");
                            toast.error(t("please_provide_risk_factor"));
                            return;
                          }
                          tempRiscFactor = {
                            id: uuidv4(),
                            riskFactor: riscFactorSearchString,
                            gender: "",
                            locallyAdded: true,
                          };
                        }
                        setUserRiscFactorList((prev) => {
                          const temp = [
                            ...prev,
                            { riskFactor: tempRiscFactor, since },
                          ];
                          updateData({ riskFactor: temp });
                          return temp;
                        });
                        initializeForm();
                      }}
                    >
                      {t("add")}
                    </Button>
                  </div>
                </li>
              )}
            </ul>
          </div>

          <div className="tskElemHeding hilightHeading">
            {t("past_illness")}
          </div>

          <ul className="withOutList pastIllness">
            {userPastIllnessList &&
              userPastIllnessList.map((item, index) => {
                return (
                  <li>
                    <div className="complstDtlGrp">
                      <div className="complstDtl  ">
                        <div className="compntName">
                          {item.illness.diseaseName}
                        </div>
                        {item.date && (
                          <div className="compinfoGRP">
                            <div className="compStime">
                              <span>{t("reported_date")}:</span>
                              <span className="data">
                                {dayjs(item.date).format("DD-MMM-YYYY")}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                      {extraData?.isDoctor && !item.illness.previouslyAdded && (
                        <IconButton
                          className="removeItemBtn"
                          onClick={() => {
                            const temp = [...userPastIllnessList];
                            temp.splice(index, 1);
                            updateData({ pastIllness: temp });
                            setUserPassIllnessList(temp);
                          }}
                        >
                          <Trash />
                        </IconButton>
                      )}
                    </div>
                  </li>
                );
              })}

            {extraData?.isDoctor && (
              <li>
                <div className="addNewformGrp">
                  <div className="formElement">
                    <FormControl className="formControl">
                      <Autocomplete
                        freeSolo
                        className="formAutocompleteField"
                        variant="outlined"
                        value={selectedPastIllness}
                        options={suggestedDiseaseList}
                        inputValue={pastIllnessString}
                        onChange={(e, newValue) => {
                          setSelectedPastIllness(newValue);
                        }}
                        onInputChange={(e, value) => {
                          setPastIllnessString(value);
                        }}
                        getOptionLabel={(option) => option.diseaseName}
                        renderOption={(props, item) => {
                          return (
                            <li {...props} key={item.id}>
                              {item.diseaseName}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="formAutoComInputField autocompFildPlaceholder"
                            placeholder={t("add_past_illness")}
                            label={t("past_illnesses")}
                            required
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </div>

                  <div className="formElement mxW-150">
                    <FormControl className="formControl">
                      <InputLabel
                        id="pastIllnessDate"
                        className="setCompletionDate"
                      >
                        {t("select_date")}
                      </InputLabel>
                      <ReactDatePicker
                        locale={currentLocale}
                        showYearDropdown
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown
                        showMonthDropdown
                        showIcon
                        toggleCalendarOnIconClick
                        labelId="pastIllnessDate"
                        className="formDatePicker"
                        selected={pastIllnessDate}
                        maxDate={new Date()}
                        dateFormat="dd-MMM-yyyy"
                        onChange={(date) => {
                          console.log("new date = ", date);
                          setPastIllnessDate(date);
                        }}
                      />
                    </FormControl>
                  </div>

                  <Button
                    className="dfultPrimaryBtn "
                    startIcon={<Add />}
                    onClick={() => {
                      if (!pastIllnessString?.trim()) {
                        setPastIllnessString("");
                        toast.error(t("please_provide_illness_name"));
                        return;
                      }
                      let tempPastIllness = selectedPastIllness;
                      if (!tempPastIllness) {
                        tempPastIllness = {
                          id: uuidv4(),
                          diseaseName: pastIllnessString,
                          icd11code: "",
                          snowmedCt: "",
                        };
                      }
                      setUserPassIllnessList((prev) => {
                        const temp = [
                          ...prev,
                          { illness: tempPastIllness, date: pastIllnessDate },
                        ];

                        updateData({ pastIllness: temp });
                        return temp;
                      });
                      initializePastIllness();
                    }}
                  >
                    {t("add")}
                  </Button>
                </div>
              </li>
            )}
          </ul>

          <div className="tskElemHeding hilightHeading">
            {t("previous_procedure")}
          </div>

          <ul className="withOutList pastIllness">
            {userProcedureList &&
              userProcedureList.map((item, index) => {
                return (
                  <li>
                    <div className="complstDtlGrp">
                      <div className="complstDtl  ">
                        <div className="compntName">
                          {item.surgery.procedureName}
                        </div>
                        {item.date && (
                          <div className="compinfoGRP">
                            <div className="compStime">
                              <span>{t("reported_date")}:</span>
                              <span className="data">
                                {dayjs(
                                  DateUtils.convertDateFormat(
                                    item.date,
                                    "DD-MM-YYYY"
                                  )
                                ).format("DD-MMM-YYYY")}
                                {/* {item.date} */}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                      {extraData?.isDoctor && !item.surgery.previouslyAdded && (
                        <IconButton
                          className="removeItemBtn"
                          onClick={() => {
                            const temp = [...userProcedureList];
                            temp.splice(index, 1);
                            updateData({ prevSurgery: temp });

                            setUserProcedureList(temp);
                          }}
                        >
                          <Trash />
                        </IconButton>
                      )}
                    </div>
                  </li>
                );
              })}

            {extraData?.isDoctor && (
              <li>
                <div className="addNewformGrp">
                  <div className="formElement">
                    <FormControl className="formControl">
                      <Autocomplete
                        freeSolo
                        className="formAutocompleteField"
                        variant="outlined"
                        value={selectedProcedure}
                        options={suggestedProcedure}
                        inputValue={procedureString}
                        onChange={(e, newValue) => {
                          setSelectedProcedure(newValue);
                        }}
                        onInputChange={(e, value) => {
                          setProcedureString(value);
                        }}
                        getOptionLabel={(option) => option.procedureName}
                        renderOption={(props, item) => {
                          return (
                            <li {...props} key={item.id}>
                              {item.procedureName}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="formAutoComInputField autocompFildPlaceholder"
                            placeholder={t("add_previous_procedure")}
                            label={t("previous_procedure")}
                            required
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </div>

                  <div className="formElement mxW-150">
                    <FormControl className="formControl">
                      <InputLabel
                        id="setsurgeryDate"
                        className="setCompletionDate"
                      >
                        {t("surgery_date") + "*"}
                      </InputLabel>
                      <ReactDatePicker
                        locale={currentLocale}
                        showYearDropdown
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown
                        showMonthDropdown
                        showIcon
                        toggleCalendarOnIconClick
                        labelId="setsurgeryDate"
                        className="formDatePicker"
                        selected={surgeryDate}
                        maxDate={new Date()}
                        dateFormat="dd-MMM-yyyy"
                        onChange={(date) => {
                          console.log("new date = ", date);
                          setSurgeryDate(date);
                        }}
                      />
                    </FormControl>
                  </div>

                  <Button
                    className="dfultPrimaryBtn "
                    startIcon={<Add />}
                    onClick={() => {
                      let tempProcedure = selectedProcedure;
                      let isValid = true;
                      if (!procedureString?.trim()) {
                        setProcedureString("");
                        toast.error(t("please_provide_procedure_name"));
                        isValid = false;
                      }

                      if (!surgeryDate) {
                        toast.error(t("please_select_surgery_date"));
                        isValid = false;
                      }
                      if (!isValid) {
                        return;
                      }

                      if (!tempProcedure) {
                        tempProcedure = {
                          id: uuidv4(),
                          // foodName: procedureString,
                          procedureName: procedureString,
                          gender: "",
                          locallyAdded: true,
                        };
                      }
                      setUserProcedureList((prev) => {
                        const temp = [
                          ...prev,
                          {
                            surgery: tempProcedure,
                            date: surgeryDate
                              ? dayjs(surgeryDate).format("DD-MM-YYYY")
                              : null,
                          },
                        ];
                        updateData({ prevSurgery: temp });
                        return temp;
                      });
                      initializeProcedure();
                    }}
                  >
                    {t("add")}
                  </Button>
                </div>
              </li>
            )}
          </ul>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
