import { Button, FormControl, IconButton, InputLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Download, FileExcel, Trash, XLg } from "react-bootstrap-icons";
import userImg from "../../images/prahant.jpg";
// import ReactDatePicker from "react-datepicker";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DownloadForOfflineTwoTone, Edit, Mail } from "@mui/icons-material";
import DateUtils from "../../../../utils/DateUtils";
import AttendanceService from "../../../../services/AttendanceService";
import { GetLoginUserDetails } from "../../../../utils/Utils";
import { DownloadCircle } from "iconoir-react";
import * as XLSX from "xlsx";
import { useTranslation } from "react-i18next";
import { enIN, es } from "date-fns/locale";

// Register locales with ReactDatePicker
registerLocale("en", enIN);
registerLocale("es", es);

export default function ViewUserAttendance({
  handelCloseViewUserAttend,
  viewUserAttendanceData,
}) {
  const loginUserDetails = GetLoginUserDetails();
  const [attendanceData, setAttendanceData] = useState(null);
  const { t, i18n } = useTranslation();

  const [selectedDate, setSelectedDate] = useState(
    viewUserAttendanceData.selectedDate
  );

  // Determine the locale based on the current language
  const currentLocale = i18n.language === "es" ? "es" : "en";

  const changeCurrentDate = (newDate) => {
    setSelectedDate(newDate);
    AttendanceService.getAllAttendanceData(
      loginUserDetails.orgId,
      DateUtils.getDateInYYYYMM(newDate)
    ).then((response) => {
      console.log("getAllAttendanceData", response.data);
      groupUserData(response.data);
    });
  };

  const groupUserData = (userData) => {
    const data = userData.reduce((acc, curr) => {
      const { userId, attendanceDate, ...rest } = curr;
      if (!acc[userId]) {
        acc[userId] = {};
      }
      if (!acc[userId][attendanceDate]) {
        acc[userId][attendanceDate] = {};
      }
      acc[userId][attendanceDate] = { ...curr };
      return acc;
    }, {});
    console.log("grouped Data", data);
    setAttendanceData(data[viewUserAttendanceData.userDetails.userId]);
  };

  useEffect(() => {
    setAttendanceData(viewUserAttendanceData.attendanceDetails);
  }, []);

  const exportToExcel = () => {
    let processedJSONArray = [];

    let employeeId = Object.values(attendanceData)[0].employeeId;
    let employeeName = Object.values(attendanceData)[0].userName;
    Object.keys(attendanceData).forEach((dateDto) => {
      let jsonObj = {};
      jsonObj["DATE"] = dateDto;
      jsonObj["IN_TIME"] = attendanceData[dateDto].inTime;
      jsonObj["IN_TIME_IMAGE"] = attendanceData[dateDto].inTimeImage;
      jsonObj["OUT_TIME"] = attendanceData[dateDto].outTime;
      jsonObj["OUT_TIME_IMAGE"] = attendanceData[dateDto].outTimeImage;
      jsonObj["HOURS"] =
        attendanceData[dateDto].inTime &&
        attendanceData[dateDto].outTime &&
        DateUtils.getDiffToHoursAndMinutes(
          attendanceData[dateDto].inTime,
          attendanceData[dateDto].outTime
        );
      jsonObj["STATUS"] =
        attendanceData[dateDto].status == null
          ? "ABSENT"
          : attendanceData[dateDto].status;
      processedJSONArray.push(jsonObj);
    });
    let fileName = `${employeeId}_${employeeName}_${DateUtils.getDateInYYYYMM(
      selectedDate
    )}`;
    const worksheet = XLSX.utils.json_to_sheet(processedJSONArray);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      "ATTENDANCE_" + DateUtils.getDateInYYYYMM(selectedDate)
    );
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  return (
    <>
      <div className="rightFlotingPanel">
        <div className="rightFlotingContainer">
          <div className="rfContHeader">
            <div className="rfcHeadText">{t("attendance_report")}</div>
            <div className="rfcHActionBtnGrp">
              <div className="actionBtn closeBtnD10">
                <IconButton onClick={() => handelCloseViewUserAttend()}>
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>

          <div className="rfContContain">
            <div className="rfContContainDetails">
              <div className="elementFormContainer">
                {/* start user info */}
                <div className="indUsrAttend">
                  <div className="usrAttendInfoImg">
                    <div className="usrImg">
                      <img
                        src={
                          viewUserAttendanceData.userDetails.userProfileImage
                        }
                        alt={t("username_gose_here")}
                      />
                    </div>
                    <div className="usrInfo">
                      <div className="userNm">
                        {viewUserAttendanceData.userDetails.userName}
                      </div>
                      <div className="usrId">
                        {viewUserAttendanceData.userDetails.employeeId}
                      </div>
                      <div className="usrDep">
                        {viewUserAttendanceData.userDetails.departmentName}
                      </div>
                    </div>
                  </div>

                  <div className="dnPayShilp">
                    <div className="attendanceDatePicker ">
                      <InputLabel className="attndInpLabl">
                        {t("select_month")}
                      </InputLabel>
                      <ReactDatePicker
                        locale={currentLocale}
                        showIcon
                        className="dashAttendanceDT"
                        dateFormat={"MM-yyyy"}
                        selected={selectedDate}
                        onChange={(date) => changeCurrentDate(date)}
                        showMonthYearPicker
                        maxDate={new Date()}
                      />
                    </div>

                    {/* <div className="dnSlip">
                      <Button variant="outlined" startIcon={<Mail />}>
                        Pay Slip
                      </Button>
                    </div> */}
                    <div className="dnSlip">
                      <Button
                        variant="outlined"
                        startIcon={<DownloadForOfflineTwoTone />}
                        onClick={exportToExcel}
                      >
                        {t("download")}
                      </Button>
                    </div>
                  </div>
                </div>
                {/* end user info */}

                {attendanceData != null && (
                  <div className="cuContainArea">
                    <div className="TableContainer">
                      <table className="taskListTable">
                        <thead className="taskListtableHeader">
                          <tr>
                            <th className="">{t("date")}</th>
                            <th className="">{t("check_in")}</th>
                            <th className="">{t("check_out")}</th>
                            <th className="">{t("hours")}</th>
                            <th className="">{t("status")}</th>
                          </tr>
                        </thead>
                        <tbody className="scrolableTableBody">
                          {Object.keys(attendanceData).map((date) => {
                            let attendanceDto = attendanceData[date];
                            return (
                              <tr>
                                <td>
                                  <div className="attndTabText">{date}</div>
                                </td>
                                {attendanceDto.isWeekend !== "Y" &&
                                  !attendanceDto.holidayName &&
                                  !attendanceDto.leaveTypeId && (
                                    <>
                                      <td>
                                        <div className="attndTabText">
                                          {attendanceDto.inTime &&
                                            DateUtils.getTimeInHHMMAMPM(
                                              attendanceDto.inTime
                                            )}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="attndTabText">
                                          {attendanceDto.outTime &&
                                            DateUtils.getTimeInHHMMAMPM(
                                              attendanceDto.outTime
                                            )}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="attndTabText">
                                          {attendanceDto.inTime &&
                                            attendanceDto.outTime &&
                                            DateUtils.getDiffToHoursAndMinutes(
                                              attendanceDto.inTime,
                                              attendanceDto.outTime
                                            )}
                                        </div>
                                      </td>
                                      <td>
                                        {attendanceDto.status === "PRESENT" && (
                                          <div className="attndTabText">
                                            <span className="attndRemark present">
                                              {t("present")}
                                            </span>
                                          </div>
                                        )}
                                        {(attendanceDto.status == null ||
                                          attendanceDto.status ===
                                            "ABSENT") && (
                                          <div className="attndTabText">
                                            <span className="attndRemark absent">
                                              {t("absent")}
                                            </span>
                                          </div>
                                        )}
                                      </td>{" "}
                                    </>
                                  )}
                                {attendanceDto.isWeekend === "Y" && (
                                  <>
                                    {attendanceDto.status === "PRESENT" && (
                                      <>
                                        <td>
                                          <div className="attndTabText">
                                            {attendanceDto.inTime &&
                                              DateUtils.getTimeInHHMMAMPM(
                                                attendanceDto.inTime
                                              )}
                                          </div>
                                        </td>
                                        <td>
                                          <div className="attndTabText">
                                            {attendanceDto.outTime &&
                                              DateUtils.getTimeInHHMMAMPM(
                                                attendanceDto.outTime
                                              )}
                                          </div>
                                        </td>
                                        <td>
                                          <div className="attndTabText">
                                            {attendanceDto.inTime &&
                                              attendanceDto.outTime &&
                                              DateUtils.getDiffToHoursAndMinutes(
                                                attendanceDto.inTime,
                                                attendanceDto.outTime
                                              )}
                                          </div>
                                        </td>
                                        <td>
                                          {attendanceDto.status ===
                                            "PRESENT" && (
                                            <div className="attndTabText">
                                              <span className="attndRemark weekend">
                                                {t("weekend_present")}
                                              </span>
                                            </div>
                                          )}
                                          {(attendanceDto.status == null ||
                                            attendanceDto.status ===
                                              "ABSENT") && (
                                            <div className="attndTabText">
                                              <span className="attndRemark weekend">
                                                {t("weekend_absent")}
                                              </span>
                                            </div>
                                          )}
                                        </td>{" "}
                                      </>
                                    )}
                                    {(attendanceDto.status == null ||
                                      attendanceDto.status === "ABSENT") && (
                                      <>
                                        <td colSpan={3}>
                                          <div className="attndTabText remarkAttend">
                                            {t(
                                              "sending_positive_vibes_for_the_weekend_to_you_enjoy_your_well_deserved_break"
                                            )}
                                          </div>
                                        </td>
                                        <td>
                                          <div className="attndTabText">
                                            <span className="attndRemark weekend">
                                              {t("weekend")}
                                            </span>
                                          </div>
                                        </td>
                                      </>
                                    )}
                                  </>
                                )}
                                {attendanceDto.holidayName !== "" &&
                                  attendanceDto.holidayName != null && (
                                    <>
                                      {(attendanceDto.inTime != null ||
                                        attendanceDto.outTime != null) && (
                                        <>
                                          <td>
                                            <div className="attndTabText">
                                              {attendanceDto.inTime &&
                                                DateUtils.getTimeInHHMMAMPM(
                                                  attendanceDto.inTime
                                                )}
                                            </div>
                                          </td>
                                          <td>
                                            <div className="attndTabText">
                                              {attendanceDto.outTime &&
                                                DateUtils.getTimeInHHMMAMPM(
                                                  attendanceDto.outTime
                                                )}
                                            </div>
                                          </td>
                                          <td>
                                            <div className="attndTabText">
                                              {attendanceDto.inTime &&
                                                attendanceDto.outTime &&
                                                DateUtils.getDiffToHoursAndMinutes(
                                                  attendanceDto.inTime,
                                                  attendanceDto.outTime
                                                )}
                                            </div>
                                          </td>
                                          <td>
                                            {attendanceDto.status ===
                                              "PRESENT" && (
                                              <div className="attndTabText">
                                                <span className="attndRemark holiday">
                                                  {attendanceDto.holidayName}(
                                                  {t("present")})
                                                </span>
                                              </div>
                                            )}
                                            {(attendanceDto.status == null ||
                                              attendanceDto.status ===
                                                "ABSENT") && (
                                              <div className="attndTabText">
                                                <span className="attndRemark holiday">
                                                  {attendanceDto.holidayName} (
                                                  {t("absent")})
                                                </span>
                                              </div>
                                            )}
                                          </td>{" "}
                                        </>
                                      )}
                                      {(attendanceDto.status == null ||
                                        attendanceDto.status === "HOLIDAY") && (
                                        <>
                                          <td colSpan={3}>
                                            <div className="attndTabText remarkAttend">
                                              {t("happy_holidays")}
                                            </div>
                                          </td>
                                          <td>
                                            <div className="attndTabText">
                                              <span className="attndRemark holiday">
                                                {attendanceDto.holidayName}
                                              </span>
                                            </div>
                                          </td>
                                        </>
                                      )}
                                    </>
                                  )}
                                {attendanceDto.leaveTypeId !== 0 &&
                                  attendanceDto.leaveTypeId != null && (
                                    <>
                                      {(attendanceDto.status == null ||
                                        attendanceDto.status === "LEAVE") && (
                                        <>
                                          <td colSpan={3}>
                                            <div className="attndTabText remarkAttend">
                                              {t("leave")}
                                            </div>
                                          </td>
                                          <td>
                                            <div className="attndTabText">
                                              <span className="attndRemark leave">
                                                {attendanceDto.leaveType}
                                              </span>
                                            </div>
                                          </td>
                                        </>
                                      )}
                                    </>
                                  )}
                              </tr>
                            );
                          })}
                          {/* <tr>
                        <td>
                          <div className="attndTabText">01-04-2024</div>
                        </td>
                        <td>
                          <div className="attndTabText">11.30 AM</div>
                        </td>
                        <td>
                          <div className="attndTabText">07.15 PM</div>
                        </td>
                        <td>
                          <div className="attndTabText">7.50</div>
                        </td>
                        <td>
                          <div className="attndTabText">
                            <span className="attndRemark present">Present</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="attndTabText">02-04-2024</div>
                        </td>
                        <td>
                          <div className="attndTabText">10.30 AM</div>
                        </td>
                        <td>
                          <div className="attndTabText">07.00 PM</div>
                        </td>
                        <td>
                          <div className="attndTabText">8.30</div>
                        </td>
                        <td>
                          <div className="attndTabText">
                            <span className="attndRemark absent">Absent</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="attndTabText">03-04-2024</div>
                        </td>
                        <td colSpan={3}>
                          <div className="attndTabText remarkAttend">
                            Sending positive vibes for the weekend to you, Enjoy
                            your well-deserved break.
                          </div>
                        </td>
                        <td>
                          <div className="attndTabText">
                            <span className="attndRemark weekend">Weekend</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="attndTabText">04-04-2024</div>
                        </td>
                        <td colSpan={3}>
                          <div className="attndTabText remarkAttend">
                            Wishing you a well-deserved break and a wonderful
                            vacation.
                          </div>
                        </td>
                        <td>
                          <div className="attndTabText">
                            <span className="attndRemark leave">
                              Pay without leave
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="attndTabText">05-04-2024</div>
                        </td>
                        <td colSpan={3}>
                          <div className="attndTabText remarkAttend">
                            May this day of freedom fill your hearts with
                            happiness and thankfulness.
                          </div>
                        </td>

                        <td>
                          <div className="attndTabText">
                            <span className="attndRemark holiday">
                              Independence Day
                            </span>
                          </div>
                        </td>
                      </tr> */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* <div className="rfContFooter">
            <div className="formBtnElement">
              <Button className="dfultPrimaryBtn">Save</Button>

              <Button className="dfultDarkSecondaryBtn">Cancel</Button>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}
