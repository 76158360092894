import axios from "axios";
import { jwtDecode } from "jwt-decode";
import React, {
  lazy,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import EventAlert from "../../../common/EventAlert";
import { RouteGuard } from "../../../config/router";
import {
  apiEndPoint,
  collaboraDomain,
  mailTopicName,
  socketEndPoint,
} from "../../../constants/url_provider";
import {
  getUnreadMessages,
  handleHistory,
  updateHistory,
} from "../../../redux/reducers/dashboardMailSlice";
import {
  dashboardSocket,
  doctorSocket,
  mailSocket,
  meetingSocket,
} from "../../../socket";
import {
  GetLoginUserDetails,
  getLoginUserDetailsWithoutContext,
} from "../../../utils/Utils";
import ChatContainer from "../../chat/view/ChatContainer";
import HomeContainer from "./component/HomeContainer";
import Tabs from "./Tabs";
import TopNavbar from "./TopNavbar";
// import EmailContainerWrapper from "../../email/view/EmailContainerWrapper";
import { ConfirmProvider } from "material-ui-confirm";
import DriveContainer from "../../drive/view/DriveContainer";
import MeetingsTabContainer from "../../scheduler/view/component/meetings/view/MeetingsTabContainer";
// import SchedulerContainer from "../../scheduler/view/SchedulerContainer";
import Admin from "../../admin/view/Admin";
// import TaskTabContainer from "../../task/view/TaskTabContainer";
import dayjs from "dayjs";
import { createContext } from "react";
import { toast } from "react-toastify";
import CallInvite from "../../../common/CallInvite";
import EmailService from "../../../services/EmailService";
import WhiteBoard from "../../scheduler/view/component/meetings/view/WhiteBoard";
import SchRightMeetingDtlsContainer from "../../scheduler/view/SchRightMeetingDtlsContainer";
// import mySound from "../assets/media/bell2.wav";
// import AnalyticsDashboard from "../../analyticsDashboard/view/AnalyticsDashboard";
// import ZoeyBot from "../../../common/ZoeyBot";
import { useLocation, useNavigate } from "react-router-dom";
// import DocumentEditor from "../../../utils/DocumentEditor";
// import CreateTaskContainer from "../../task/view/CreateTaskContainer";
import { TourProvider } from "@reactour/tour";
import { useTranslation } from "react-i18next";
import ConfirmDialog from "../../../common/ConfirmDialog";
import { ManagementDashboardSteps } from "../../../common/tour_step/ManagementDashboardSteps";
// import { TourProviderWithContext } from "../../../common/TourContext";
import DashboardServices from "../../../services/DashboardServices";
import LoginSignupService from "../../../services/LoginSignupService";
import ProfileService from "../../../services/ProfileService";
import AppUtils from "../../../utils/AppUtils";
import SubscriptionOptions from "../../organization/view/SubscriptionOptions";
import { getHealthDto } from "../../scheduler/view/component/meetings/view/consultation/consultationUtil";
import ExcalidrawApp from "../../scheduler/view/component/meetings/view/ExcalidrawApp";
import TranscriptionDetailsViewOnly from "../../scheduler/view/component/meetings/view/TranscriptionDetailsViewOnly";
import DocumentTemplateViewer from "../../task/view/component/DocumentTemplateViewer";
import FirstTimeLoginReset from "./FirstTimeLoginReset";
import NewTabContent from "./NewTabContent";
import { BookAppointmentFromNurseStep } from "../../../common/tour_step/BookAppointmentFromNurseStep";
import { RegisterPatientSteps } from "../../../common/tour_step/RegisterPatientSteps";
import { BookConsultationSteps } from "../../../common/tour_step/BookConsultationSteps";
import { PatientDocumentSteps } from "../../../common/tour_step/PatientDocumentSteps";
import { useTour } from "@reactour/tour";
import { TourContext } from "../../../common/TourProvider";
import Joyride from "react-joyride";
import SecureIndexedDB from "../../../utils/IndexedDB";
import { PolicyModal } from "./PolicyModal";

// import "../../../common/css/responsiveCSS/smallPhones-max-576.css";

const EmailContainerWrapper = lazy(() =>
  import("../../email/view/EmailContainerWrapper")
);
// const ZoeyBot = lazy(() => import("../../../common/ZoeyBot"));

const DocumentEditor = lazy(() => import("../../../utils/DocumentEditor"));

const CreateTaskContainer = lazy(() =>
  import("../../task/view/CreateTaskContainer")
);

const AnalyticsDashboard = lazy(() =>
  import("../../analyticsDashboard/view/AnalyticsDashboard")
);

const SchedulerContainer = lazy(() =>
  import("../../scheduler/view/SchedulerContainer")
);

const TaskTabContainer = lazy(() => import("../../task/view/TaskTabContainer"));

export const AddTabContext = createContext();

// console.log("enLang", Object.keys(enLang).length);
// console.log("esLang", Object.keys(esLang).length);

// console.log("enLang", new Set(Object.keys(enLang)).size);
// console.log("esLang", new Set(Object.keys(esLang)).size);

// const esKeys = new Set(Object.keys(esLang));
// const missingKeysInEsLang = Object.keys(enLang).filter(
//   (key) => !esKeys.has(key)
// );

// // const enKeys = new Set(Object.keys(enLang));
// // const missingKeysInEnLang = Object.keys(esLang).filter(
// //   (key) => !enKeys.has(key)
// // );

// console.log("Keys in esLang missing from enLang:", missingKeysInEsLang);

// // const esKeys = new Set(Object.keys(esLang));
// // const missingInEsLang = Object.entries(enLang).filter(
// //   ([key]) => !esKeys.has(key)
// // );

// // const missingInJsonFormat = Object.fromEntries(missingInEsLang);

// // console.log(JSON.stringify(missingInJsonFormat, null, 2));

export default function DashboardPage() {
  const loginUserDetail = GetLoginUserDetails();
  console.log("DashboardPage", loginUserDetail);
  const { t } = useTranslation();
  const { setIsOpen } = useTour();
  // const [playSound] = useSound(mySound);
  const [gapiInited, setGapiInited] = useState(false);
  const [accessToken, setAccessToken] = useState(null);

  const loginUser = GetLoginUserDetails();

  const dispatch = useDispatch();
  // const [historyId, setHistoryId] = useState(null);
  const historyId = useRef(null);

  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedEventWB, setSelectedEventWB] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [eventAlertSelData, setEventAlertSelData] = useState(null);
  const [callInviteData, setCallInviteData] = useState(null);
  const [tabState, setTabState] = useState([]);
  const [firstTimeState, setFirstTimeState] = useState(true);
  const navigate = useNavigate();
  const tabCopy = useRef(null);
  const tabIndex = useRef(null);
  const activeTabId = useRef(null);
  const divRef = useRef();
  const [showLogoutConf, setShowLogoutConf] = useState(false);
  const [showPolicyModal, setShowPolicyModal] = useState(false);
  const [policyData, setPolicyData] = useState([]);
  const [isPolicyAcordionOpenArray, setIsPolicyAcordionOpenArray] = useState(
    []
  );

  const [tabs, setTabs] = useState([]);

  // check for active user start
  useEffect(() => {
    if (loginUserDetail && loginUserDetail.userType !== "GUEST") {
      DashboardServices.checkUserActiveOrNot(loginUserDetail.userId)
        .then((res) => {
          if (res.data != null && res.data === 0) {
            handleResponseEmployeeDeactivate();
          }
        })
        .catch((err) => {
          console.error(err.toString());
        });
    }
  }, [loginUserDetail]);
  // check for active user end

  useEffect(() => {
    const homeId = uuidv4();

    // if (loginUserDetail.orgId !== "ORG000000000000") {
    //   DashboardServices.getUserOnboardingPolicy(
    //     loginUserDetail.orgId,
    //     loginUserDetail.userId
    //   ).then((response) => {
    //     if (response.data && response.data.length > 0) {
    //       setPolicyData(response.data);
    //       setShowPolicyModal(true);
    //       setIsPolicyAcordionOpenArray(
    //         [...Array(response.data.length)].map((item) => ({
    //           show: true,
    //           accepted: false,
    //         }))
    //       );
    //     }
    //   });
    // }

    const handleEscButton = (e) => {
      if (e.key === "Escape" && document.fullscreenElement) {
        toast.error("Escape Button Called");
        e.preventDefault();
        handleFullscreen();
      }
    };

    const root = document.querySelector("#root");

    // window.addEventListener("keydown", handleEscButton);

    // document.addEventListener("fullscreenchange", handleFullScreenChange);

    let meetingTab = null;
    if (state && state.eventData) {
      console.log("state-->>", state.eventData);
      const key = uuidv4();
      meetingTab = {
        key: key,
        title: `${state.eventData.eventTitle}`,
        content: (
          <ConfirmProvider>
            <MeetingsTabContainer eventData={state.eventData} tabKey={key} />
          </ConfirmProvider>
        ),
        isDraggable: true,
        type: "Meeting",
      };
      // addTab({
      //   key: key,
      //   title: `${state.eventData.eventTitle}`,
      //   content: (
      //     <ConfirmProvider>
      //       <MeetingsTabContainer eventData={state.eventData} tabKey={key} />
      //     </ConfirmProvider>
      //   ),
      //   isDraggable: true,
      //   type: "Meeting",
      //   // tabIndex: getAllTabs().length,
      // });

      activeTabId.current = key;
    }
    let fileShareTab = null;
    if (state && state.driveFileDetails) {
      console.log("state-->>", state.driveFileDetails);
      const key = uuidv4();
      fileShareTab = {
        key: key,
        title: t("file_viewer"),
        content: (
          <DocumentEditor
            officeUrl={`${collaboraDomain}/browser/abd9205/cool.html?WOPISrc=${apiEndPoint}/wopi/files/${state.driveFileDetails.fileId}`}
            fileId={state.driveFileDetails.fileId}
            userId={loginUser.userId}
            sharedBy={state.driveFileDetails.sharedBy}
            fileName={state.driveFileDetails.fileName}
          />
        ),
        isDraggable: true,
      };
      // addTab({
      //   key: key,
      //   title: "File Viewer",
      //   content: (
      //     <DocumentEditor
      //       officeUrl={`${collaboraDomain}/browser/abd9205/cool.html?WOPISrc=${apiEndPoint}/wopi/files/${state.driveFileDetails.fileId}`}
      //       fileId={state.driveFileDetails.fileId}
      //       userId={loginUser.userId}
      //       sharedBy={state.driveFileDetails.sharedBy}
      //       fileName={state.driveFileDetails.fileName}
      //     />
      //   ),
      //   isDraggable: true,
      // });
      activeTabId.current = key;
    }

    if (loginUserDetail) {
      ProfileService.getDefaultLandingTab(loginUserDetail.userId).then(
        (response) => {
          let tabsList = [];

          if (response.data) {
            if (AppUtils.isPersonal(loginUser)) {
              if (loginUserDetail.userType !== "GUEST") {
                if (response.data === "INBOX") {
                  tabsList.push({
                    key: homeId,
                    title: t("inbox"),
                    content: (
                      <HomeContainer
                        setEventData={(eventData) => addMeetingTab(eventData)}
                      />
                    ),
                    isDraggable: false,
                  });
                  tabsList.push({
                    key: uuidv4(),
                    title: t("Apps"),
                    content: <NewTabContent tabIndex={tabs.length} />,
                    isDraggable: true,
                    type: "Apps",
                  });
                } else if (response.data === "APPS") {
                  tabsList.push({
                    key: uuidv4(),
                    title: t("Apps"),
                    content: <NewTabContent tabIndex={tabs.length} />,
                    isDraggable: true,
                    type: "Apps",
                  });

                  tabsList.push({
                    key: homeId,
                    title: t("inbox"),
                    content: (
                      <HomeContainer
                        setEventData={(eventData) => addMeetingTab(eventData)}
                      />
                    ),
                    isDraggable: false,
                  });
                }
              }
              if (meetingTab) {
                tabsList.push(meetingTab);
              }
              if (fileShareTab) {
                tabsList.push(fileShareTab);
              }
              setTabs(tabsList);
            } else {
              if (loginUserDetail.userType !== "GUEST") {
                if (response.data === "DASHBOARD") {
                  tabsList.push({
                    key: uuidv4(),
                    title: t("dashboard"),
                    content: <AnalyticsDashboard />,
                    isDraggable: false,
                  });
                  tabsList.push({
                    key: homeId,
                    title: t("inbox"),
                    content: (
                      <HomeContainer
                        setEventData={(eventData) => addMeetingTab(eventData)}
                      />
                    ),
                    isDraggable: false,
                  });
                  tabsList.push({
                    key: uuidv4(),
                    title: t("Apps"),
                    content: <NewTabContent tabIndex={tabs.length} />,
                    isDraggable: true,
                    type: "Apps",
                  });
                } else if (response.data === "INBOX") {
                  tabsList.push({
                    key: homeId,
                    title: t("inbox"),
                    content: (
                      <HomeContainer
                        setEventData={(eventData) => addMeetingTab(eventData)}
                      />
                    ),
                    isDraggable: false,
                  });
                  tabsList.push({
                    key: uuidv4(),
                    title: t("dashboard"),
                    content: <AnalyticsDashboard />,
                    isDraggable: false,
                  });
                  tabsList.push({
                    key: uuidv4(),
                    title: t("Apps"),
                    content: <NewTabContent tabIndex={tabs.length} />,
                    isDraggable: true,
                    type: "Apps",
                  });
                } else if (response.data === "APPS") {
                  tabsList.push({
                    key: uuidv4(),
                    title: t("Apps"),
                    content: <NewTabContent tabIndex={tabs.length} />,
                    isDraggable: true,
                    type: "Apps",
                  });

                  tabsList.push({
                    key: uuidv4(),
                    title: t("dashboard"),
                    content: <AnalyticsDashboard />,
                    isDraggable: false,
                  });

                  tabsList.push({
                    key: homeId,
                    title: t("inbox"),
                    content: (
                      <HomeContainer
                        setEventData={(eventData) => addMeetingTab(eventData)}
                      />
                    ),
                    isDraggable: false,
                  });
                }
              }
              if (meetingTab) {
                tabsList.push(meetingTab);
              }
              if (fileShareTab) {
                tabsList.push(fileShareTab);
              }

              setTabs(tabsList);
            }
          } else {
            let tabsTemp2 = [];
            if (loginUserDetail.userType !== "GUEST") {
              if (loginUserDetail.orgId !== "ORG000000000000") {
                tabsTemp2.push({
                  key: uuidv4(),
                  title: t("dashboard"),
                  content: <AnalyticsDashboard />,
                  isDraggable: false,
                });
              }

              tabsTemp2.push({
                key: homeId,
                title: t("inbox"),
                content: (
                  <HomeContainer
                    setEventData={(eventData) => addMeetingTab(eventData)}
                  />
                ),
                isDraggable: false,
              });
              tabsTemp2.push({
                key: uuidv4(),
                title: t("Apps"),
                content: <NewTabContent tabIndex={tabs.length} />,
                isDraggable: true,
                type: "Apps",
              });
            }

            if (meetingTab) {
              tabsTemp2.push(meetingTab);
            }
            if (fileShareTab) {
              tabsTemp2.push(fileShareTab);
            }

            setTabs(tabsTemp2);
            // setTabs([
            //   ...(loginUserDetail.orgId === "ORG000000000000"
            //     ? []
            //     : [
            //         {
            //           key: uuidv4(),
            //           title: "Dashboard",
            //           content: <AnalyticsDashboard />,
            //           isDraggable: false,
            //         },
            //       ]),
            //   {
            //     key: homeId,
            //     title: "Inbox",
            //     content: (
            //       <HomeContainer
            //         setEventData={(eventData) => addMeetingTab(eventData)}
            //       />
            //     ),
            //     isDraggable: false,
            //   },
            //   {
            //     key: uuidv4(),
            //     title: "Apps",
            //     content: <NewTabContent tabIndex={tabs.length} />,
            //     isDraggable: true,
            //     type: "Apps",
            //   },
            //   meetingTab
            // ]);
          }
        }
      );

      if (
        loginUserDetail.zhUserType === "DOCTOR" ||
        loginUserDetail.zhUserType === "NURSE" ||
        loginUserDetail.zhUserType === "LT"
      ) {
        getDoctorData();
      } else {
        DashboardServices.getfeedbackMaster(
          loginUserDetail.orgId,
          "PATIENT"
        ).then((response) => {
          if (response.data) {
            SecureIndexedDB.setItem(
              "patientFeedbackQuestion",
              JSON.stringify(response.data)
            );
          }
        });
      }
    }
    if (!loginUserDetail) {
      navigate("/");
    }

    const intervalId = setInterval(async () => {
      const loginDetails = await getLoginUserDetailsWithoutContext();
      if (!loginDetails) {
        navigate("/");
      }
    }, 5000);

    // const tempTab = [...tabs];

    // setTabs((prev) => prev);

    // if (firstTimeState) {
    // }
    // const token = await SecureIndexedDB.getItem("tabItems");
    // console.log("token Value =", token);
    // if (token) {
    //   const tokenJson = JSON.parse(token);
    //   console.log(tokenJson);
    //   setFirstTimeState(false);
    //   tokenJson.forEach((element) => {
    //     addTabAccordingToTitle(element);
    //   });
    //   // setActiveTab(0);
    //   SecureIndexedDB.setItem("tabItems", JSON.stringify([]));
    // }

    setActiveTab(0);

    // if (!state) {

    //   setTabs((prev) => {
    //     let actTabId =  SecureIndexedDB.getItem("activeTabId");

    //     actTabId = JSON.parse(actTabId);
    //     if (!actTabId) {
    //       activeTabId.current = homeId;
    //       setFirstTimeState(false);
    //       return prev;
    //     }
    //     if (actTabId.title === "Dashboard") {
    //       const tabDocument = prev.find((item) => item.title === "Dashboard");
    //       activeTabId.current = tabDocument.key;
    //       setFirstTimeState(false);
    //       return prev;
    //     } else if (actTabId.title === "Home") {
    //       activeTabId.current = homeId;
    //       setFirstTimeState(false);
    //       return prev;
    //     } else {
    //       const tabDocument = prev.find((item) => item.key === actTabId.key);

    //       if (tabDocument) {
    //         activeTabId.current = tabDocument.key;
    //       } else {
    //         activeTabId.current = homeId;
    //       }
    //     }

    //     setFirstTimeState(false);
    //     return prev;
    //   });
    // }

    return () => {
      const root = document.querySelector("#root");

      // window.removeEventListener("keydown", handleEscButton);

      // document.removeEventListener("fullscreenchange", handleFullScreenChange);

      clearInterval(intervalId);
    };
  }, []);

  const handleReceiveMessageFromCenter = (data) => {
    toast.info(`${data?.messageFrom}: ${data?.message}`);
  };

  useEffect(() => {
    doctorSocket.on("receiveMessageFromCenter", handleReceiveMessageFromCenter);
    return () => {
      doctorSocket.off(
        "receiveMessageFromCenter",
        handleReceiveMessageFromCenter
      );
    };
  }, [doctorSocket]);

  // useEffect(() => {
  //   if (centerSocket != null) {
  //     centerSocket.emit("requestOnlineDoctor");
  //     centerSocket.on("responseOnlineDoctor", (doctorIdList) => {
  //       console.log("responseOnlineDoctor", doctorIdList);
  //     });
  //   }

  //   return () => {
  //     centerSocket.off("responseOnlineDoctor");
  //   };
  // }, [centerSocket]);

  const getDoctorData = async () => {
    console.log("getDoctorData called");
    const reqDto = await getHealthDto();
    console.log("getDoctorData called", reqDto);
    DashboardServices.getGenerics(reqDto).then((response) => {
      if (response.data) {
        const genericList = response.data;
        DashboardServices.getGenericswithRanking(loginUserDetail.userId).then(
          (rankingResponse) => {
            if (rankingResponse) {
              let rankedMed = rankingResponse.data;
              let rankedMedFullData = [];

              let notRankedMedFullData = [];

              genericList.forEach((item) => {
                const index = rankedMed.findIndex((medId) => medId === item.id);
                if (index >= 0) {
                  rankedMedFullData.push(item);
                  rankedMed.splice(index, 1);
                } else {
                  notRankedMedFullData.push(item);
                }
              });
              console.log("ranked total=", genericList.length);

              console.log(
                "ranked Item len=",
                rankedMedFullData.length,
                " non ranked item len= ",
                notRankedMedFullData.length
              );

              console.log("ranked Item =", rankedMedFullData);
              SecureIndexedDB.setItem(
                "doctor_generic",
                JSON.stringify(rankedMedFullData)
              );
              SecureIndexedDB.setItem(
                "generic",
                JSON.stringify(notRankedMedFullData)
              );

              // toast.success("successfully fetched Data");
            } else {
              toast.error(t("could_not_fetch_generic_ranking"));
              return;
            }
          }
        );
      } else {
        toast.error(t("could_not_fetch_generic_data"));
      }
    });

    DashboardServices.getDiets(reqDto).then((response) => {
      if (response.data) {
        SecureIndexedDB.setItem("dietList", JSON.stringify(response.data));
      }
    });

    DashboardServices.getDiseases(reqDto).then((response) => {
      if (response.data) {
        SecureIndexedDB.setItem("diseaseList", JSON.stringify(response.data));
      }
    });

    DashboardServices.getSymptoms(reqDto).then((response) => {
      if (response.data) {
        SecureIndexedDB.setItem("symptomList", JSON.stringify(response.data));
      }
    });

    DashboardServices.getMedicalTestList(reqDto).then((response) => {
      if (response.data) {
        SecureIndexedDB.setItem(
          "medicalTestList",
          JSON.stringify(response.data)
        );
      }
    });

    DashboardServices.getRiskFactors(reqDto).then((response) => {
      if (response.data) {
        SecureIndexedDB.setItem("riskList", JSON.stringify(response.data));
      }
    });

    DashboardServices.getFoodAllergies(reqDto).then((response) => {
      if (response.data) {
        SecureIndexedDB.setItem("foodAllergies", JSON.stringify(response.data));
      }
    });

    DashboardServices.getSymptomSince(reqDto).then((response) => {
      if (response.data) {
        SecureIndexedDB.setItem("symptomSince", JSON.stringify(response.data));
      }
    });
    DashboardServices.getHereditaryDiseases(reqDto).then((response) => {
      if (response.data) {
        SecureIndexedDB.setItem(
          "heriditaryDisease",
          JSON.stringify(response.data)
        );
      }
    });
    DashboardServices.getDurations(reqDto).then((response) => {
      if (response.data) {
        SecureIndexedDB.setItem("durationList", JSON.stringify(response.data));
      }
    });
    DashboardServices.getFrequencies(reqDto).then((response) => {
      if (response.data) {
        SecureIndexedDB.setItem("frequencyList", JSON.stringify(response.data));
      }
    });

    DashboardServices.getBrands(reqDto).then((response) => {
      if (response.data) {
        // SecureIndexedDB.setItem("brandList", JSON.stringify(response.data));
      }
    });

    DashboardServices.getProcedures(reqDto).then((response) => {
      if (response.data) {
        SecureIndexedDB.setItem("procedureList", JSON.stringify(response.data));
      }
    });
    DashboardServices.getLifeStyles(reqDto).then((response) => {
      if (response.data) {
        SecureIndexedDB.setItem("lifeStyleList", JSON.stringify(response.data));
      }
    });

    DashboardServices.getRoutes(reqDto).then((response) => {
      if (response.data) {
        SecureIndexedDB.setItem("routeList", JSON.stringify(response.data));
      }
    });

    DashboardServices.getFormDosages(reqDto).then((response) => {
      if (response.data) {
        SecureIndexedDB.setItem("dosageList", JSON.stringify(response.data));
      }
    });

    DashboardServices.getVitalUnits(reqDto).then((response) => {
      if (response.data) {
        SecureIndexedDB.setItem("vitalUnitList", JSON.stringify(response.data));
      }
    });

    DashboardServices.getSigns(reqDto).then((response) => {
      if (response.data) {
        SecureIndexedDB.setItem("signList", JSON.stringify(response.data));
      }
    });

    DashboardServices.getRiskFactorSince(reqDto).then((response) => {
      if (response.data) {
        SecureIndexedDB.setItem(
          "riskFactorSince",
          JSON.stringify(response.data)
        );
      }
    });

    DashboardServices.getSymptomSeverity(reqDto).then((response) => {
      if (response.data) {
        SecureIndexedDB.setItem(
          "symptomSeverity",
          JSON.stringify(response.data)
        );
      }
    });

    DashboardServices.getRelationships(reqDto).then((response) => {
      if (response.data) {
        SecureIndexedDB.setItem(
          "relationshipList",
          JSON.stringify(response.data)
        );
      }
    });

    DashboardServices.getComorbidities(reqDto).then((response) => {
      if (response.data) {
        SecureIndexedDB.setItem(
          "comorbodityList",
          JSON.stringify(response.data)
        );
      }
    });

    DashboardServices.getSubstanceAbuse(reqDto).then((response) => {
      if (response.data) {
        SecureIndexedDB.setItem(
          "substanceAbuseList",
          JSON.stringify(response.data)
        );
      }
    });

    DashboardServices.getSystemicIllness(reqDto).then((response) => {
      if (response.data) {
        SecureIndexedDB.setItem(
          "substanceIllnessList",
          JSON.stringify(response.data)
        );
      }
    });

    DashboardServices.getPhyExams(reqDto).then((response) => {
      if (response.data) {
        SecureIndexedDB.setItem(
          "physicalExamList",
          JSON.stringify(response.data)
        );
      }
    });

    DashboardServices.getHospitalsOfOrg(loginUserDetail.orgId).then(
      (response) => {
        if (response.data) {
          SecureIndexedDB.setItem(
            "hospitalList",
            JSON.stringify(response.data)
          );
        }
      }
    );

    DashboardServices.getSpecializations(reqDto).then((response) => {
      if (response.data) {
        SecureIndexedDB.setItem(
          "specializationList",
          JSON.stringify(response.data)
        );
      }
    });

    DashboardServices.getAvailableDoctorsofUnit(
      loginUserDetail.signleUnitId
    ).then((response) => {
      if (response.data) {
        SecureIndexedDB.setItem(
          "unitDoctorList",
          JSON.stringify(response.data)
        );
      }
    });

    if (loginUser.zhUserType === "DOCTOR") {
      DashboardServices.getfeedbackMaster(loginUserDetail.orgId, "DOCTOR").then(
        (response) => {
          if (response.data) {
            SecureIndexedDB.setItem(
              "drFeedbackQuestions",
              JSON.stringify(response.data)
            );
          }
        }
      );
    }

    if (
      loginUserDetail.zhUserType === "NURSE" ||
      loginUserDetail.zhUserType === "LT"
    ) {
      DashboardServices.getfeedbackMaster(
        loginUserDetail.orgId,
        "PATIENT"
      ).then((response) => {
        if (response.data) {
          SecureIndexedDB.setItem(
            "patientFeedbackQuestion",
            JSON.stringify(response.data)
          );
        }
      });
    }
  };

  const overrideLStorageTabId = (index) => {
    const tabListItem = tabs[index];
    if (tabListItem) {
      activeTabId.current = tabListItem.key;
      const data = JSON.stringify({
        title: tabListItem.title,
        key: tabListItem.key,
      });
      SecureIndexedDB.setItem("activeTabId", data);
    }
  };

  const addTabAccordingToTitle = (element) => {
    switch (element.title) {
      case "Task":
        addTab({
          key: element.key,
          title: "Task",
          content: <TaskTabContainer />,
          isDraggable: true,
        });
        break;

      case "Scheduler":
        addTab({
          key: element.key,
          title: "Scheduler",
          content: <SchedulerContainer />,
          isDraggable: true,
        });
        break;

      case "Email":
        addTab({
          key: element.key,
          title: "Email",
          content: <EmailContainerWrapper />,
          isDraggable: true,
        });
        break;

      case "Chat":
        addTab({
          key: element.key,
          title: "Chat",
          content: <ChatContainer />,
          isDraggable: true,
        });
        break;

      case "Meeting Room":
        addTab({
          key: element.key,
          title: "Meeting Room",
          content: (
            <ConfirmProvider>
              <MeetingsTabContainer
                tabKey={element.key}
                joinRoomId={element.data}
              />
            </ConfirmProvider>
          ),
          isDraggable: true,
        });
        break;

      case "Drive":
        addTab({
          key: element.key,
          title: "Drive",
          content: <DriveContainer addTab={addTab} />,
          isDraggable: true,
        });
        break;

      case "Admin":
        addTab({
          key: element.key,
          title: "Admin",
          content: <Admin />,
          isDraggable: true,
        });
        break;

      case "Instant Meeting":
        const key = element.key;
        addTab({
          key: key,
          title: "Instant Meeting",
          content: (
            <ConfirmProvider>
              <MeetingsTabContainer
                startInstantMeeting={false}
                joinRoomId={element.data}
                tabKey={key}
              />
            </ConfirmProvider>
          ),
          isDraggable: true,
          type: "Meeting",
        });

        // addTab({
        //   key: element.key,
        //   title: "Meeting Room",
        //   content: (
        //     <ConfirmProvider>
        //       <MeetingsTabContainer />
        //     </ConfirmProvider>
        //   ),
        //   isDraggable: true,
        // });

        break;
      case "Create Task":
        addTab({
          key: element.key,
          title: "Create Task",
          content: <CreateTaskContainer />,
          isDraggable: true,
        });
        break;

      case "":
        addTab({
          key: element.key,
          title: "Create Organization",
          content: <SubscriptionOptions />,
          isDraggable: true,
        });
        break;

      default:
        const filePattern = /^File_.*/;
        if (filePattern.test(element.title)) {
          addTab({
            key: element.key,
            title: element.title,
            content: (
              <DocumentTemplateViewer
                file={element.data}
                tabKey={element.key}
              />
            ),
            isDraggable: true,
          });
          return;
        } else if (element.type === "File Viewer") {
          addTab({
            key: element.key,
            title: element.title,
            content: (
              <DocumentEditor
                officeUrl={`${collaboraDomain}/browser/abd9205/cool.html?WOPISrc=${apiEndPoint}/wopi/files/${element.data.fileId}`}
                fileId={element.data.fileId}
                userId={loginUser.userId}
                sharedBy={element.data.sharedBy}
                fullScreen={element.data.fullScreen}
                fileName={element.data.fileName}
                tabKey={element.key}
              />
            ),
            isDraggable: true,
            type: "File Viewer",
          });
        } else if (element.type === "Whiteboard") {
          const roomNumber = element.title.split("_")[1];
          addTab({
            key: element.key,
            title: element.title,
            content: <ExcalidrawApp roomNumber={roomNumber} newTab={true} />,
            isDraggable: true,
            type: element.type,
          });
        } else if (element.type === "Transcription") {
          addTab({
            key: element.key,
            title: element.title,
            content: (
              <TranscriptionDetailsViewOnly
                handleMenuCloseButton={() => {}}
                roomNumber={element.data.eventLink}
                selEvent={element.data}
                tabKey={element.key}
              />
            ),
            isDraggable: true,
            type: "Transcription",
          });
        }

        break;
    }
  };

  const saveTabStates = (tabs, data) => {
    const value = tabs.filter((tab) => {
      return tab.title !== t("home") && tab.title !== t("dashboard");
    });

    if (tabs && tabs.length > 0) {
      const indx = tabState.findIndex((tab) => {
        return tab.key === data.key;
      });
      if (indx === -1) {
        setTabState((prev) => {
          console.log("prev=", prev);
          return [...prev, data];
        });
      } else {
        setTabState((prev) => {
          const temp = [...prev];
          temp[indx].title = data.title;
          temp[indx].key = data.key;
          temp[indx].data = undefined;
          return temp;
        });
      }
    }
    // const list = value.map((tab) => {
    //   return { title: tab.title, key: tab.key };
    // });

    // setTabState(list);
    // SecureIndexedDB.setItem("tabItems", JSON.stringify(list));
  };

  const handleTabUpdate = () => {
    if (tabState && tabState.length > 0) {
      SecureIndexedDB.setItem("tabItems", JSON.stringify(tabState));
    } else {
      SecureIndexedDB.setItem("tabItems", JSON.stringify([]));
    }
  };

  useEffect(() => {
    //error is happening when joining meetings via link hence commented
    // return;

    handleTabUpdate();
  }, [tabState]);

  useEffect(() => {
    const removeIndex = [];
    // Handle When Tab is removed
    if (tabs) {
      if (activeTabId.current) {
        const tabIndex = tabs.findIndex(
          (tab) => tab.key === activeTabId.current
        );
        if (tabIndex >= 0) {
          setActiveTab(tabIndex);
          // overrideLStorageTabId(tabIndex);
          activeTabId.current = null;
        }
      }
    }

    for (let i = 0; i < tabState.length; i++) {
      const tabStateKey = tabState[i].key;
      const index = tabs.findIndex((tab) => tab.key === tabStateKey);
      if (index === -1) {
        removeIndex.push(i);
      }
    }

    removeIndex.forEach((indx) => {
      setTabState((prev) => {
        const temp = [...prev];
        temp.splice(indx, 1);
        return temp;
      });
    });
  }, [tabs]);

  const changeTabContent = (index, tabObject) => {
    let tempKey = tabObject.key;
    console.log("changeTabContent1", index, tabObject);
    const selTab = { ...tabs[index] };
    console.log("changeTabContent2", selTab);
    selTab.content = tabObject.content;
    selTab.title = tabObject.title;
    if (tabObject.title === "Instant Meeting" && !tabObject.replaceTabKey) {
      selTab.type = "Meeting";
      selTab.key = tabObject.key;
    } else {
      selTab.type = null;
      tempKey = selTab.key;
    }
    const updatedList = [...tabs];
    updatedList[index] = selTab;

    saveTabStates(updatedList, { title: selTab.title, key: selTab.key });

    setTabs(updatedList);
    return tempKey;
  };

  const addTab = (tabObject) => {
    console.log("addTab", tabObject);
    console.log("all tabs", tabs);

    if (tabObject.type === "New") {
      if (tabObject.replaceTabKey) {
        const indexOfTab = tabs.findIndex(
          (tab) => tab.key === tabObject.replaceTabKey
        );
        const tempKey = changeTabContent(indexOfTab, tabObject);
        if (!firstTimeState) {
          // activeTabId.current = tempKey;
          const data = JSON.stringify({
            title: tabObject.title,
            key: tempKey,
            type: tabObject.type,
          });
          SecureIndexedDB.setItem("activeTabId", data);
        }
        return -1;
      } else {
        const tempKey = changeTabContent(tabObject.tabIndex, tabObject);
        if (!firstTimeState) {
          // activeTabId.current = tempKey;
          const data = JSON.stringify({
            title: tabObject.title,
            key: tempKey,
            type: tabObject.type,
          });

          SecureIndexedDB.setItem("activeTabId", data);
        }
        return -1;
      }
    } else {
      const presentIndex = tabs.findIndex((tab) => tab.key === tabObject.key);
      if (presentIndex !== -1) {
        setActiveTab(presentIndex);
        // overrideLStorageTabId(presentIndex);
      } else {
        const setTabFunc = (prev) => {
          const tempTab = [...prev];
          tempTab.push(tabObject);
          tabCopy.current = tempTab;
          saveTabStates(tempTab, {
            title: tabObject.title,
            key: tabObject.key,
            type: tabObject.type,
          });

          tabIndex.current = tempTab.length - 1;

          setActiveTab(tempTab.length - 1);
          // overrideLStorageTabId(tempTab.length - 1);
          return tempTab;
        };
        setTabs(setTabFunc);
      }
    }

    if (!firstTimeState) {
      // activeTabId.current = tabObject.key;
      const data = JSON.stringify({
        title: tabObject.title,
        key: tabObject.key,
      });

      SecureIndexedDB.setItem("activeTabId", data);
    }
  };

  const updateTab = (updateData, tabKey) => {
    const temp = [...tabState];
    const tabIndex = temp.findIndex((item) => item.key === tabKey);
    if (tabIndex >= 0) {
      temp[tabIndex] = { ...temp[tabIndex], ...updateData };
    }
    setTabState(temp);
  };

  const closeTab = (tabKey) => {
    const temp = [...tabs];
    const indexOfTab = temp.findIndex((tab) => tab.key === tabKey);
    temp.splice(indexOfTab, 1);
    setTabs(temp);
    setActiveTab(1);
  };

  const getAllTabs = () => {
    return tabs;
  };

  const refreshTabs = (tabKey) => {
    const homeId = uuidv4();
    ProfileService.getDefaultLandingTab(loginUserDetail.userId).then(
      (response) => {
        let tabsList = [];

        if (response.data) {
          if (AppUtils.isPersonal(loginUser)) {
            if (response.data === "INBOX") {
              tabsList.push({
                key: homeId,
                title: t("inbox"),
                content: (
                  <HomeContainer
                    setEventData={(eventData) => addMeetingTab(eventData)}
                  />
                ),
                isDraggable: false,
              });
              tabsList.push({
                key: uuidv4(),
                title: t("Apps"),
                content: <NewTabContent tabIndex={tabs.length} />,
                isDraggable: true,
                type: "Apps",
              });
            } else if (response.data === "APPS") {
              tabsList.push({
                key: uuidv4(),
                title: t("Apps"),
                content: <NewTabContent tabIndex={tabs.length} />,
                isDraggable: true,
                type: "Apps",
              });

              tabsList.push({
                key: homeId,
                title: t("inbox"),
                content: (
                  <HomeContainer
                    setEventData={(eventData) => addMeetingTab(eventData)}
                  />
                ),
                isDraggable: false,
              });
            }

            setTabs(tabsList);
          } else {
            if (response.data === "DASHBOARD") {
              tabsList.push({
                key: uuidv4(),
                title: t("dashboard"),
                content: <AnalyticsDashboard />,
                isDraggable: false,
              });
              tabsList.push({
                key: homeId,
                title: t("inbox"),
                content: (
                  <HomeContainer
                    setEventData={(eventData) => addMeetingTab(eventData)}
                  />
                ),
                isDraggable: false,
              });
              tabsList.push({
                key: uuidv4(),
                title: t("Apps"),
                content: <NewTabContent tabIndex={tabs.length} />,
                isDraggable: true,
                type: "Apps",
              });
            } else if (response.data === "INBOX") {
              tabsList.push({
                key: homeId,
                title: t("inbox"),
                content: (
                  <HomeContainer
                    setEventData={(eventData) => addMeetingTab(eventData)}
                  />
                ),
                isDraggable: false,
              });
              tabsList.push({
                key: uuidv4(),
                title: t("dashboard"),
                content: <AnalyticsDashboard />,
                isDraggable: false,
              });
              tabsList.push({
                key: uuidv4(),
                title: t("Apps"),
                content: <NewTabContent tabIndex={tabs.length} />,
                isDraggable: true,
                type: "Apps",
              });
            } else if (response.data === "APPS") {
              tabsList.push({
                key: uuidv4(),
                title: t("Apps"),
                content: <NewTabContent tabIndex={tabs.length} />,
                isDraggable: true,
                type: "Apps",
              });

              tabsList.push({
                key: uuidv4(),
                title: t("dashboard"),
                content: <AnalyticsDashboard />,
                isDraggable: false,
              });

              tabsList.push({
                key: homeId,
                title: t("inbox"),
                content: (
                  <HomeContainer
                    setEventData={(eventData) => addMeetingTab(eventData)}
                  />
                ),
                isDraggable: false,
              });
            }

            setTabs(tabsList);
          }
        } else {
          setTabs([
            ...(loginUserDetail.orgId === "ORG000000000000"
              ? []
              : [
                  {
                    key: uuidv4(),
                    title: t("dashboard"),
                    content: <AnalyticsDashboard />,
                    isDraggable: false,
                  },
                ]),
            {
              key: homeId,
              title: t("inbox"),
              content: (
                <HomeContainer
                  setEventData={(eventData) => addMeetingTab(eventData)}
                />
              ),
              isDraggable: false,
            },
            {
              key: uuidv4(),
              title: t("Apps"),
              content: <NewTabContent tabIndex={tabs.length} />,
              isDraggable: true,
              type: "Apps",
            },
          ]);
        }
      }
    );
  };

  const addMeetingTab = (eventData) => {
    let newTabs = tabs.filter((tabDto) => tabDto.title !== "MeetingDemo");
    console.log("newTabs", newTabs);
    console.log("addMeetingTab", eventData);
    let dto = {
      title: "Meeting " + eventData.eventLink,
      content: (
        <ConfirmProvider>
          <MeetingsTabContainer
            eventData={eventData}
            openWhiteBoard={(eventData) => {
              openWhiteBoard(eventData);
            }}
          />
        </ConfirmProvider>
      ),
      isDraggable: true,
    };
    newTabs.push(dto);

    setTabs(newTabs);
    setSelectedEvent(eventData);
    // toast.info(`Joining ${eventData.eventTitle}, please wait`);
    toast.info(
      t("joining_event_please_wait", { eventTitle: eventData.eventTitle })
    );
  };

  const openWhiteBoard = (eventData) => {
    let newTabs = tabs.filter((tabDto) => tabDto.title !== "Whiteboard");
    console.log("newTabs", newTabs);
    console.log("openWhiteBoard", eventData);
    let dto = {
      key: uuidv4(),
      title: t("whiteboard"),
      content: <WhiteBoard eventData={eventData} />,
      isDraggable: true,
    };
    newTabs.push(dto);

    setTabs(newTabs);
    setSelectedEventWB(eventData);
    // toast.info(`Opening whiteboard, please wait`);
    toast.info(t("opening_whiteboard_please_wait"));
  };

  const gapiLoaded = () => {
    window.gapi.load("client", initializeGapiClient);
  };

  const initializeGapiClient = async () => {
    console.log("initialize value =");
    await window.gapi.client.init({
      // apiKey: API_KEY,
      discoveryDocs: [
        "https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest",
        "https://www.googleapis.com/discovery/v1/apis/people/v1/rest",
      ],
    });

    // await window.gapi.auth.setToken({
    //   access_token: accessToken,
    // });

    setGapiInited(true);
    // listLabels();
    // getEmailList(["INBOX", "CATEGORY_PERSONAL"], "-in:draft -in:sent");
  };

  const hitApi = async () => {
    console.log("hitting api");
    try {
      const response = await axios.get(
        `${socketEndPoint}/api/mail/authenticate/${loginUser.userId}`
      );
      if (response.data.url) {
        // window.open(response.data.url, "_blank");
      } else {
        handleAccessTokenData(response.data);
      }
    } catch (error) {
      console.log("error happened", error);
    }
  };

  const authenticate = async () => {
    const expiry_date = await SecureIndexedDB.getItem("expiry_date");
    const access_token = await SecureIndexedDB.getItem("access_token");
    const timestamp = parseInt(expiry_date, 10);
    const currentTimestamp = Date.now();
    const timeDifference = timestamp - currentTimestamp;
    if (timeDifference > 2 * 60 * 1000) {
      setAccessToken(access_token);
      setTimeout(() => {
        hitApi();
      }, timeDifference - 2 * 60 * 1000);
      console.log("");
    } else {
      hitApi();
    }

    if (expiry_date) {
      console.log("typeof expiry date = ", typeof expiry_date);
    }
  };

  const handleAccessTokenData = async (data) => {
    const tempData = data;
    console.log("jwt token data ", tempData.id_token);
    const decodedData = jwtDecode(tempData.id_token);
    const { email, name } = decodedData;
    // setAccessToken(tempData.access_token);
    SecureIndexedDB.setItem("current_mailId", email);
    SecureIndexedDB.setItem("email_user_name", name);
    SecureIndexedDB.setItem("access_token", tempData.access_token);
    SecureIndexedDB.setItem("expiry_date", tempData.expiry_date);
  };

  const { state } = useLocation();

  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.defer = true;
    script.src = "https://apis.google.com/js/api.js";
    script.onload = gapiLoaded;

    const startMailIntegration = async () => {
      try {
        document.body.appendChild(script);
        const expiry_date = await SecureIndexedDB.getItem("expiry_date");
        const access_token = await SecureIndexedDB.getItem("access_token");
        if (access_token) {
          authenticate();
          const addUserInServer = async () => {
            const email = await SecureIndexedDB.getItem("current_mailId");
            const response = await axios.get(
              `${socketEndPoint}/api/mail/mapUserEmail/${loginUser.userId}/${email}`
            );
            console.log("user sync value=", response);
          };
          addUserInServer();
        } else {
          const checkAuthentication = async () => {
            const response = await axios.get(
              `${socketEndPoint}/api/mail/checkAuthentication/${loginUser.userId}`
            );
            if (response.data.returnStatus === 0) {
            } else if (response.data.url) {
              // window.open(response.data.url, "_blank");
            } else {
              handleAccessTokenData(response.data);
            }
          };
          if (loginUserDetail) {
            //checkAuthentication();
          }
        }
      } catch (err) {
        console.log(err);
      }
    };

    startMailIntegration();

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handelSubmitLogout = async () => {
    const reqDto = {
      tokenId: null,
      device: navigator.userAgent,
      platform: "WEB",
    };
    try {
      LoginSignupService.clearFcmToken(loginUser.userId, reqDto);
    } catch (err) {
      console.log(err);
    }

    localStorage.clear();
    if (window.caches) {
      window.caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          window.caches.delete(cacheName);
        });
      });
    }
    window.location.replace(
      `${window.location.protocol}//${window.location.host}`
    );
  };

  const handleResponseEmployeeDeactivate = (data) => {
    setShowLogoutConf(true);
    // toast.info(`You have been disabled from your organization`);
    toast.info(t("disabled_from_organization"));
    setTimeout(() => handelSubmitLogout(), 5000);
  };

  useEffect(() => {
    dashboardSocket?.on(
      "responseEmployeeDeactivate",
      handleResponseEmployeeDeactivate
    );
    return () => {
      dashboardSocket?.off(
        "responseEmployeeDeactivate",
        handleResponseEmployeeDeactivate
      );
    };
  }, [dashboardSocket]);

  useEffect(() => {
    if (mailSocket && loginUser) {
      mailSocket.emit("joinMailUser", { userId: loginUser.userId });
      mailSocket.on("receiveTokenFromServer", async (tokenObj) => {
        console.log("receiveTokenFromServer", tokenObj);
        try {
          handleAccessTokenData(tokenObj);
        } catch (error) {
          console.error(error);
        }
      });
      mailSocket.on("historyList", async (historyList) => {
        console.log("received history ids  = ", historyList);
        // toast.error("new Email added")
        if (Array.isArray(historyList.historyId)) {
          dispatch(handleHistory(historyId.current, historyList.historyId[0]));
        } else {
          dispatch(handleHistory(historyId.current, historyList.historyId));
        }
      });
    }
  }, [mailSocket]);

  useEffect(() => {
    // console.log(" gapi inited = ", gapiInited, " access Token = ", accessToken);
    const setTokenInGapi = async () => {
      if (gapiInited && accessToken) {
        await window.gapi.auth.setToken({
          access_token: accessToken,
        });
        // listLabels();
        dispatch(getUnreadMessages());
        // dispatch(clearUnreadMessages());
        // const req1 = await window.gapi.client.people.people.get({
        //   resourceName: "people/me",
        //   personFields: "names,emailAddresses,photos",
        // });

        // if (req1.result) {
        //   const displayName = req1.result.names[0].displayName;
        //   SecureIndexedDB.setItem("email_user_name", displayName);
        // }

        try {
          const request = await window.gapi.client.gmail.users.watch({
            userId: "me",
            resource: {
              labelIds: ["INBOX", "CATEGORY_PERSONAL"], // Set the label IDs to watch for new messages in the inbox
              labelFilterAction: "include",
              topicName: mailTopicName,
            },
          });

          console.log("user initial history= ", request);
          historyId.current = request.result.historyId;
          dispatch(updateHistory(request.result.historyId));
        } catch (error) {
          console.log("******* important error happened *******", error);
          if (error.status && error.status === 403) {
            const token_exp_time = parseInt(
              await SecureIndexedDB.getItem("expiry_date")
            );
            const currentTime = dayjs().valueOf();
            if (token_exp_time > currentTime) {
              toast.error(t("something_went_wrong"));
              EmailService.getNewRefreshToken(loginUser.userId).then(
                (response) => {
                  if (response.data && response.data.url) {
                    window.open(response.data.url, "_blank");
                  }
                }
              );
            }
          }
        }
      }
    };
    try {
      setTokenInGapi();
    } catch (err) {
      console.log(err);
    }
  }, [accessToken, gapiInited]);

  const handleFullscreen = () => {
    let docRootRef = null;
    // docRootRef = document.querySelector("#root");
    docRootRef = document.documentElement;
    // docRootRef = divRef.current;

    if (!document.fullscreenElement) {
      if (docRootRef.requestFullscreen) {
        docRootRef.requestFullscreen();
      } else if (docRootRef.mozRequestFullScreen) {
        // Firefox
        docRootRef.mozRequestFullScreen();
      } else if (docRootRef.webkitRequestFullscreen) {
        // Chrome, Safari and Opera
        docRootRef.webkitRequestFullscreen();
      } else if (docRootRef.msRequestFullscreen) {
        // IE/Edge
        docRootRef.msRequestFullscreen();
      } else {
        return;
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        // Firefox
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        // Chrome, Safari and Opera
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        // IE/Edge
        document.msExitFullscreen();
      } else {
        return;
      }
    }
  };

  useEffect(() => {
    meetingSocket.on("callInviteReceived", async (data) => {
      console.log("callInviteReceived", data);
      setCallInviteData(data);
      // playSound();
      // setSelectedData(null);
    });
  }, [meetingSocket]);

  useEffect(() => {
    // if (!("zhUserType" in loginUserDetail)) {
    //   // toast.info("Session invalid, logging out");
    //   setTimeout(() => {
    //     const reqDto = {
    //       tokenId: null,
    //       device: navigator.userAgent,
    //       platform: "WEB",
    //     };
    //     try {
    //       LoginSignupService.clearFcmToken(loginUser.userId, reqDto);
    //     } catch (err) {
    //       console.log(err);
    //     }
    //     localStorage.clear();
    //     if (window.caches) {
    //       window.caches.keys().then((cacheNames) => {
    //         cacheNames.forEach((cacheName) => {
    //           window.caches.delete(cacheName);
    //         });
    //       });
    //     }
    //     window.location.replace(
    //       `${window.location.protocol}//${window.location.host}`
    //     );
    //   }, 2000);
    // }
  });

  const [firstTimeLoginReset, setFirstTimeLoginReset] = useState(false);

  useEffect(() => {
    if (loginUserDetail?.userType !== "GUEST") {
      DashboardServices.changePasswordRequiredorNot(
        loginUserDetail.userId
      ).then((response) => {
        if (response.data === "N") {
          setFirstTimeLoginReset(false);
        } else {
          setFirstTimeLoginReset(true);
        }
      });
    }
  }, []);

  // start react tour element

  // const [steps, setSteps] = useState([]);

  // useEffect(() => {
  //   const updateSelectedTour = () => {
  //     const tourValue =  SecureIndexedDB.getItem("tourValue") || "";
  //     switch (tourValue) {
  //       case "bookAppointment":
  //         setSteps(BookAppointmentFromNurseStep);
  //         break;
  //       case "managementDashboard":
  //         setSteps(ManagementDashboardSteps);
  //         break;
  //       case "registerPatient":
  //         setSteps(RegisterPatientSteps);
  //         break;
  //       case "bokConsult":
  //         setSteps(BookConsultationSteps);
  //         break;
  //       case "patientDocm":
  //         setSteps(PatientDocumentSteps);
  //         break;
  //       default:
  //         setSteps([]);
  //         console.log("No steps found for this tour");
  //     }
  //     setSelectedTour(tourValue);
  //   };
  //   updateSelectedTour();
  //   window.addEventListener("storage", updateSelectedTour);

  //   return () => {
  //     window.removeEventListener("storage", updateSelectedTour);
  //   };
  // }, [localStorage]);

  const ConsultSteps = [
    {
      selector: ".nsBookConsultsteps",
      content:
        "You can book Consulation for a patient from here . you need to click on 'add' button to start the process",
    },
  ];

  const ReviewAndReferSteps = [
    {
      selector: ".nsRevRefsteps",
      content: "You can see all the Review Patient and Refer Patient ",
    },
  ];

  const bookOrViewAppointMentNS = [
    {
      selector: ".nsAppointment",
      content:
        "You can book a doctor appointment by clicking the 'Appointment' button and view all appointments by clicking the 'View Appointment' button.",
    },
  ];

  const ConsultStepsJr = [
    {
      target: ".nsBookConsultsteps",
      content:
        "You can book Consulation for a patient from here . you need to click on 'add' button to start the process",
    },
  ];

  // useEffect(() => {
  //   const updateSelectedTour = () => {
  //     const tourValue =  SecureIndexedDB.getItem("tourValue") || "";
  //     switch (tourValue) {
  //       case "bookAppointment":
  //         setSteps(bookOrViewAppointMentNS);
  //         setIsOpen(true);
  //         break;
  //       case "managementDashboard":
  //         setSteps(ManagementDashboardSteps);
  //         setIsOpen(true);
  //         break;
  //       case "registerPatient":
  //         setSteps(RegisterPatientSteps);
  //         setIsOpen(true);
  //         break;
  //       case "bokConsult":
  //         setSteps(ConsultSteps);
  //         setIsOpen(true);
  //         break;
  //       case "patientDocm":
  //         setSteps(PatientDocumentSteps);
  //         setIsOpen(true);
  //         break;
  //       case "revAndRefPat":
  //         setSteps(ReviewAndReferSteps);
  //         setIsOpen(true);
  //         break;
  //       default:
  //         setSteps([]);
  //         console.log("No steps found for this tour");
  //     }
  //     setSelectedTour(tourValue);
  //   };

  //   // Update on load and listen for changes
  //   updateSelectedTour();
  //   window.addEventListener("storage", updateSelectedTour);
  //   window.addEventListener("custom-tour-update", updateSelectedTour);

  //   // Cleanup listeners on unmount
  //   return () => {
  //     window.removeEventListener("storage", updateSelectedTour);
  //     window.removeEventListener("custom-tour-update", updateSelectedTour);
  //   };
  // }, []);

  // console.log("selectedTour", selectedTour);
  // console.log("steps", steps);

  // const disableBody = () => {
  //   const targetElement = document.querySelectorAll(".anDashCardContainArea");
  //   for (let i = 0; i < targetElement.length; i++) {
  //     targetElement[i].style.overflowY = "hidden";
  //   }
  // };

  // const enableBody = () => {
  //   const targetElement = document.querySelectorAll(".anDashCardContainArea");
  //   for (let i = 0; i < targetElement.length; i++) {
  //     targetElement[i].style.overflowY = "overlay";
  //   }
  // };

  // end react tour element

  return (
    <>
      {/* <button onClick={() => setRun(true)}>Start Tour</button> */}

      {loginUser && (
        <div
          ref={divRef}
          // onKeyDown={() => {
          //   toast.error("Key Pressed");
          // }}
        >
          <AddTabContext.Provider
            value={{
              addTab,
              updateTab,
              getAllTabs,
              handleFullscreen,
              closeTab,
              refreshTabs,
              tabs,
            }}
          >
            <DndProvider backend={HTML5Backend}>
              <RouteGuard url={"dashboard"} />
              <TopNavbar />
              <Tabs
                tabs={tabs}
                setTabs={setTabs}
                selectedEvent={selectedEvent}
                setSelectedEvent={setSelectedEvent}
                selectedEventWB={selectedEventWB}
                setSelectedEventWB={setSelectedEventWB}
                activeTab={activeTab}
                setActiveTab={(data) => {
                  setActiveTab(data);
                  // overrideLStorageTabId(data);
                }}
              />
              <EventAlert
                viewEvent={(eventDto) => {
                  console.log("EventAlert---->>>>>>", eventDto);
                  let tempDto = {
                    eventTitle: eventDto.title,
                    eventId: eventDto.eventId,
                    eventDescription: eventDto.description,
                    eventStartTime: eventDto.start,
                    eventEndTime: eventDto.end,
                    eventLocation: eventDto.location,
                    eventLink: eventDto.link,
                    eventType: eventDto.eventType,
                    eventSource: eventDto.source,
                    eventSourceData: eventDto.sourceData,
                    eventWebinarSlug: eventDto.webinarSlug,
                    eventWebinarStreamKey: eventDto.webinarStreamKey,
                  };
                  setEventAlertSelData(tempDto);
                  console.log("EventAlert---->>>>>>", tempDto);
                }}
              />
              {/* Start Bot */}
              {/* <ZoeyBot /> */}
              {/* End Bot */}
              {/* <BottomMenu /> */}
              {eventAlertSelData && (
                <SchRightMeetingDtlsContainer
                  selectedData={eventAlertSelData}
                  setSelectedData={setEventAlertSelData}
                  closeFromDashboard={async (action) => {
                    setEventAlertSelData(null);
                  }}
                />
              )}
              {callInviteData && (
                <CallInvite
                  mySound={() => {}}
                  callInviteData={callInviteData}
                  setCallInviteData={setCallInviteData}
                  viewEvent={(eventDto) => {
                    console.log("EventAlert---->>>>>>", eventDto);
                    let tempDto = {
                      eventTitle: eventDto.title,
                      eventId: eventDto.eventId,
                      eventDescription: eventDto.description,
                      eventStartTime: eventDto.start,
                      eventEndTime: eventDto.end,
                      eventLocation: eventDto.location,
                      eventLink: eventDto.link,
                      eventType: eventDto.eventType,
                      eventSource: eventDto.source,
                      eventSourceData: eventDto.sourceData,
                    };
                    setEventAlertSelData(tempDto);
                    console.log("EventAlert---->>>>>>", tempDto);
                  }}
                />
              )}
            </DndProvider>
          </AddTabContext.Provider>
        </div>
      )}

      {firstTimeLoginReset && (
        <FirstTimeLoginReset
          openModal={firstTimeLoginReset}
          closeModal={() => {
            setFirstTimeLoginReset(false);
          }}
        />
      )}

      {showPolicyModal && (
        <PolicyModal
          policyData={policyData}
          closeModal={() => {
            setShowPolicyModal(false);
          }}
        />
      )}

      {showLogoutConf && (
        <ConfirmDialog
          open={showLogoutConf}
          title="Confirmation"
          message="Do you want to logout?"
          onCancel={() => {}}
          onOk={() => {}}
        >
          {/* <LogoutEvents /> */}
          <div>Your accoutn has been deactivated. You will be logged soon.</div>
        </ConfirmDialog>
      )}
    </>
  );
}
