import {
  ArrowBackIosNew,
  ArrowForwardIos,
  Audiotrack,
  Cancel,
  DescriptionOutlined,
  Edit,
  Pause,
  VideoCall,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  Popover,
  TextField,
  Tooltip,
} from "@mui/material";
import { CheckCircle, Plus, Refresh, Send } from "iconoir-react";
import React, { useEffect, useRef, useState } from "react";
import { ArrowLeft, People, Play, XLg } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  getFormattedDate,
  GetLoginUserDetails,
} from "../../../../../utils/Utils";
import DashboardServices from "../../../../../services/DashboardServices";
import {
  clearTaskModalData,
  moveToPreviousTask,
  pushLinkTask,
  setTaskModalData,
} from "../../../../../redux/reducers/taskTab";
import { convertFileToBase64_Split } from "../../../../../utils/FileUtils";
import TemplateList from "../TemplateList";
import ForwardTaskModal from "../ForwardTaskModal";
import ResumeModal from "../ResumeModal";
import LinkTaskModal from "../LinkTaskModal";
import DisagreeTask from "../DisagreeTask";
import AssigneeModal from "../AssigneeModal";
import SignOff from "../SignOff";
import ReopenModal from "../ReopenModal";
import CloseTask from "../CloseTask";
import RightFlotingContainer from "../RightFlotingContainer";
import FileList from "../FileList";
import RightTaskFileUpload from "../RightTaskFileUpload";
import { LinkedTaskModule } from "../LinkedTaskModule";
import { useTimeProgressor } from "../hooks/useTimerProgressor";
import { useTranslation } from "react-i18next";
import { EditDeadLineModal } from "../Modals/EditDeadLineModal";
import { PriorityComponent } from "../TaskComponent/PriorityComponent";
import { TaskNameModal } from "../Modals/TaskNameModal";
import { DescriptionComponent } from "../Modals/DescriptionComponent";
import { TaskContractModal } from "../Modals/TaskContractModal";

const TaskTabReopenPostponed = ({ data, refreshData, removeFromStore }) => {
  let { t } = useTranslation();
  const [showEditDeadline, setShowEditDeadLine] = useState(false);

  console.log("task list postpone");
  const [showFileModal, setShowFileUploadModal] = useState(false);

  const dispatch = useDispatch();
  const loginUserDetail = GetLoginUserDetails();
  const fileInputRef = useRef(null);
  const dateRemaning = useTimeProgressor(data.createdAt, data.completionTime);

  const elementRef = useRef(null);
  const [arrowDisable, setArrowDisable] = useState(true);

  const [showAddressConcern, setShowAddressConcern] = useState(false);
  const [addressConcernRemark, setAddressConcernRemark] = useState("");

  const [assigneeList, setAssigneeList] = useState([]);
  const [newAssigneeList, setNewAssigneeList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState({});
  const [reassignUser, setReassignUser] = useState(null);

  const [showForwardTask, setShowForwardTask] = useState(false);
  const [forwardReason, setForwardReason] = useState("");

  const [showCreateLinkTask, setShowCreateLinkTask] = useState(false);
  const [linkTaskDescription, setLinkTaskDescription] = useState("");
  const [showLinkFileUpload, setShowFileUploadDialog] = useState(false);
  const [linkDocuments, setLinkDocuments] = useState([]);

  const [tempOfOrgList, setTemplateOfOrgList] = useState([]);
  const [addedTemplateList, setAddedTemplateList] = useState([]);
  const [recommendedTemplateList, setRecommendedTemplateList] = useState([]);
  const [showAddTemplate, setShowAddTemplate] = useState(false);

  const [anchorUl, setAnchorUl] = React.useState(null);
  const openUl = Boolean(anchorUl);
  const ulId = openUl ? "simple-popover" : undefined;
  const fileDocuments = useRef(null);
  const commentInputRef = useRef();
  const [showAssigneeList, setShowAssigneeList] = useState(false);

  const [showDisagreeModal, setShowDisAgreeModal] = useState(false);

  const [showReassignTask, setShowReassignTask] = useState(false);

  const numberList = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  const [tempUpdatePercent, setTempUpdatePercent] = useState(0);

  const [reassignReason, setReassignReason] = useState("");
  const [showResumeModal, setShowResumeModal] = useState(false);
  const [showAudioModal, setShowAudioModal] = useState(false);
  const audioUrl = useRef();
  const [showReopenModal, setShowReopenModal] = useState(false);
  const [showCancelTask, setShowCancelTask] = useState(false);
  const [showCloseTask, setShowCloseTask] = useState(false);

  const links = useSelector((state) => state.taskList.taskLinks);

  const gotoPreviousTAsk = () => {
    dispatch(moveToPreviousTask());
  };

  const handleCloseUl = () => {
    setAnchorUl(null);
  };

  const hasPassed = () => {
    console.log("date =", data.postponedTill);

    const parts = data.postponedTill.split("-");
    const dateObject = new Date(parts[2], parts[1] - 1, parts[0]);
    const targetDate = dayjs(dateObject);
    const currentDate = dayjs();
    const hasPassed =
      targetDate.isBefore(currentDate) || targetDate.isSame(currentDate);
    return hasPassed;
  };

  const clearAllModals = () => {
    setShowResumeModal(false);
    setShowAssigneeList(false);
    setShowReopenModal(false);
  };

  const [tempDivWidth, setTempDivWidth] = useState(0);

  useEffect(() => {
    if (elementRef.current) {
      setTempDivWidth(elementRef.current.offsetWidth);
    }
  }, [elementRef]);

  const handleAddTemplate = async () => {
    const value = await DashboardServices.getAllTemplatesOfOrganization(
      loginUserDetail.orgId
    );
    setTemplateOfOrgList(value.data);

    const response = await DashboardServices.getTemplatesOfSubActivity(
      data.activityId,
      0
    );
    let notaddedTemplateList = [];
    if (response.data != null && response.data.length > 0) {
      response.data.forEach((template) => {
        let isTemplateAdded = false;
        addedTemplateList.forEach((addedTemplate) => {
          if (template.templateId === addedTemplate.templateId) {
            isTemplateAdded = true;
          }
        });
        if (!isTemplateAdded) {
          notaddedTemplateList.push(template);
        }
      });
      setRecommendedTemplateList(notaddedTemplateList);
    }
    console.log("hello");
    setShowAddTemplate(true);
  };

  const handleSubmitReassignTask = (e) => {
    console.log("user id=", reassignUser);
    const reqdto = {
      taskDetailId: data.taskId,
      toUserId: reassignUser.userId,
      reassignReason: reassignReason,
      progress: 0,
    };

    DashboardServices.reassignTask(loginUserDetail.userId, reqdto).then(
      (response) => {
        if (response.data.returnValue === "1") {
          toast.success(response.data.message);
          dispatch(clearTaskModalData());
          refreshData();
        } else {
          toast.error(response.data.message);
        }
      }
    );
  };

  const handleChangeFile = async (fileList) => {
    const reqDto = fileList.map((file) => ({
      id: 0,
      taskDtlId: data.taskId,
      fileName: file.fileName,
      fileDataLink: file.fileDataLink,
      uploadedBy: loginUserDetail.userId,
      fileDisplayName: file.fileName,
    }));

    console.log("reqDto = ", reqDto);

    DashboardServices.uploadDocuments(
      loginUserDetail.orgId,
      loginUserDetail.userId,
      data.taskId,
      reqDto
    ).then((response) => {
      console.log("document sent is ", response.data);
      if (response.data) {
        const tempData = { ...data };
        if (tempData.documents === null) {
          tempData.documents = [...response.data];
        } else {
          tempData.documents = [...data.documents, ...response.data];
        }
        dispatch(setTaskModalData(tempData));
      }
    });
    console.log("file document = ", fileDocuments.current);
  };

  const handleLinkTaskClick = async (task) => {
    // console.log("task id =", taskId);
    const values = await DashboardServices.getTaskById(task.taskId);
    dispatch(pushLinkTask(data));
    dispatch(setTaskModalData({ ...task, ...values.data }));
  };

  useEffect((e) => {
    DashboardServices.getAssigneesOfTask(data.taskId).then((response) => {
      setAssigneeList(response.data);
    });

    DashboardServices.getOrgEmployees(loginUserDetail.orgId).then(
      (response) => {
        console.log("all contract  = ", response.data);
        setUserList(response.data);
      }
    );
  }, []);

  return (
    <>
      <div className="taskViewPannel">
        <div className="taskViewContainer">
          <div className="taskHeaderPanel">
            <div className="taskControlBtnGrp">
              {!hasPassed(data.postponedTill) && (
                <div className="taskVDContBtn">
                  <Button
                    variant="contained"
                    startIcon={<Pause />}
                    onClick={(e) => {
                      clearAllModals();
                      setShowResumeModal(true);
                    }}
                  >
                    {`Resumes on ${data.postponedTill}`}
                  </Button>
                </div>
              )}
              {hasPassed(data.postponedTill) && (
                <>
                  <div className="taskVDContBtn">
                    <Button
                      variant="contained"
                      startIcon={<Pause />}
                      onClick={(e) => {
                        clearAllModals();
                        setShowReopenModal(true);
                      }}
                    >
                      {`Reopen`}
                    </Button>
                  </div>
                  <div className="taskVDContBtn">
                    <Button
                      variant="contained"
                      startIcon={<Cancel />}
                      onClick={(e) => {
                        clearAllModals();
                        setShowCancelTask(true);
                      }}
                    >
                      {`Cancel`}
                    </Button>
                  </div>
                </>
              )}
              <div className="taskVDContBtn"></div>
            </div>

            <div className="taskActionBtnGrp">
              <div className="taskActBtn">
                <IconButton
                  aria-label="User"
                  onClick={(e) => {
                    // setOpenUserList(true);
                    // handleClickUl(e);
                    setShowAssigneeList(true);
                  }}
                >
                  <Tooltip title="User" arrow>
                    <People />
                  </Tooltip>
                </IconButton>
                <Popover
                  id={ulId}
                  open={openUl}
                  anchorEl={anchorUl}
                  onClose={handleCloseUl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <ul>
                    {assigneeList &&
                      assigneeList.map((assignee) => <li>{assignee.name}</li>)}
                  </ul>
                  {/* <Typography sx={{ p: 2 }}>
                        The content of the Popover.
                      </Typography> */}
                </Popover>
              </div>

              <div className="taskActBtn closeBtnD10">
                <IconButton
                  aria-label="close"
                  onClick={(e) => {
                    dispatch(clearTaskModalData());
                  }}
                >
                  <Tooltip title="Close" arrow>
                    <XLg />
                  </Tooltip>
                </IconButton>
              </div>
            </div>
          </div>
          <div className="taskContain">
            <div className="taskContainDetail">
              <div className="taskContDtlContainer">
                {links && links.length > 0 && (
                  <div className="linktaskBreadCrumb">
                    <IconButton onClick={gotoPreviousTAsk}>
                      <ArrowLeft />
                    </IconButton>
                    <div className="prentTaskNM">
                      {links &&
                        links.length > 0 &&
                        links[links.length - 1].taskName}
                    </div>
                  </div>
                )}
                {/* <div className="taskTitleContrct">
                  <div className="taskTitleNM">{data && data.taskName}</div>
                </div>
                <div className="taskElementGrp">
                  <div className="tskElemHeding">Description</div>
                  <div className="tskElemntInfo">
                    {data && data.taskDescription}
                  </div>
                </div> */}

                <TaskNameModal
                  data={data}
                  refreshData={refreshData}
                  removeFromStore={removeFromStore}
                />

                <DescriptionComponent
                  data={data}
                  refreshData={refreshData}
                  removeFromStore={removeFromStore}
                />

                {data && data.audioNoteFileName && (
                  <div className="taskElementGrp">
                    <div className="tskElemntInfo">
                      <div className="tskElemntInfo">
                        <IconButton
                          className="elemntMrFileBtn"
                          variant="outlined"
                          onClick={(e) => {
                            DashboardServices.generatePreSignedUrlS3Object(
                              data.audioNoteFileName,
                              loginUserDetail.orgId
                            ).then((response) => {
                              audioUrl.current = response.data;
                              setShowAudioModal(true);
                            });
                          }}
                        >
                          <Play />
                        </IconButton>
                        <span>Play Audio Message</span>
                      </div>
                    </div>
                  </div>
                )}
                <TaskContractModal
                  data={data}
                  refreshData={refreshData}
                  removeFromStore={removeFromStore}
                />

                {/* {data &&
                  data.contractName &&
                  data.contractName.trim() !== "" && (
                    <div className="taskElementGrp">
                      <div className="tskElemHeding">Contract</div>
                      <div className="taskContractNm">
                        {data && data.contractName}
                      </div>
                    </div>
                  )} */}

                {/* <div className="taskElementGrp">
                      <div className="tskElemHeding">Status</div>
                      <div className="tskTimeline">
                        <div className="tskTimBar">
                          <div className="progress">
                            <div
                              // ps50 ps80 ps100
                              className="progress-done ps50"
                              style={{ width: `${progressWidth}%`, opacity: 1 }}
                            >
                              <span className="pwCount">{progressWidth}%</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}

                <div className="taskElementGrp">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div className="tskElemHeding">{t("timeline")}</div>
                    {data.incoming === "N" && (
                      <Tooltip arrow title={t("edit_timeline")}>
                        <IconButton
                          className="editTimelineBtn"
                          onClick={() => {
                            setShowEditDeadLine(true);
                          }}
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                  <div className="tskTimeline">
                    <div className="tskTimBar">
                      <div className="progress">
                        <div
                          // up50 up80 up100
                          className={`progress-done ${
                            data && dateRemaning < 50
                              ? "up50"
                              : dateRemaning < 80
                              ? "up80"
                              : "up100"
                          }`}
                          style={{ width: `${dateRemaning}%`, opacity: 1 }}
                        ></div>
                      </div>
                    </div>
                    <div className="tskDtSE">
                      <div className="tskDTStart">
                        {getFormattedDate(data.createdAt)}
                      </div>
                      <div className="tskDTEnd">
                        {getFormattedDate(data.completionTime)}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Templates start */}

                {/* <div className="taskElementGrp">
                      <div className="tskElemHeding">Template</div>
    
                      <div class="elementFileListedGrp">
                        <div className="horaizonScroll">
                          <IconButton
                            className="goPrevousBtn"
                            onClick={() => {
                              handleHorizantalScroll(
                                elementRef.current,
                                25,
                                200,
                                -10
                              );
                            }}
                            disabled={arrowDisable}
                          >
                            <ArrowBackIosNew />
                          </IconButton>
                          <div class="elemntFilsgrp" ref={elementRef}>
                            {data &&
                              data.templates.map((template) => (
                                <div className="elemntFile">
                                  <Tooltip
                                    title={template.fileDisplayName}
                                    arrow
                                    className="elmFileTolTip"
                                  >
                                    <span className="elemntFilIoc">
                                      <DescriptionOutlined />
                                    </span>
                                    <span className="elemntFilNm">
                                      {template.fileDisplayName}
                                    </span>
                                  </Tooltip>
                                </div>
                              ))}
                          </div>
                          {tempDivWidth > 270 && (
                            <IconButton
                              className="goNextBtn"
                              onClick={() => {
                                handleHorizantalScroll(
                                  elementRef.current,
                                  25,
                                  200,
                                  10
                                );
                              }}
                            >
                              <ArrowForwardIos />
                            </IconButton>
                          )}
                        </div>
                        <div class="elmntAddMrFiles">
                          <Button
                            className="elemntMrFileBtn"
                            variant="outlined"
                            onClick={handleAddTemplate}
                          >
                            <Plus />
                          </Button>
                        </div>
                      </div>
                    </div> */}

                {/* Templates end */}
                <TemplateList
                  data={data}
                  tempDivWidth={tempDivWidth}
                  handleAddTemplate={handleAddTemplate}
                />

                {/* files start */}

                {/* <div className="taskElementGrp">
                    <div className="tskElemHeding">Files</div>
  
                    <div class="elementFileListedGrp">
                      <div className="horaizonScroll">
                        <IconButton
                          className="goPrevousBtn"
                          onClick={() => {
                            filehandleHorizantalScroll(
                              fileElementRef.current,
                              25,
                              200,
                              -10
                            );
                          }}
                          disabled={filearrowDisable}
                        >
                          <ArrowBackIosNew />
                        </IconButton>
  
                        <div class="elemntFilsgrp" ref={fileElementRef}>
                          {data &&
                            data.documents &&
                            data.documents.map((doc) => (
                              <div className="elemntFile">
                                <Tooltip
                                  title={doc.fileDisplayName}
                                  arrow
                                  className="elmFileTolTip"
                                >
                                  <span className="elemntFilIoc">
                                    <DescriptionOutlined />
                                  </span>
                                  <span className="elemntFilNm">
                                    {doc.fileDisplayName}
                                  </span>
                                </Tooltip>
                              </div>
                            ))}
                        </div>
                        {fileDivWidth > 370 && (
                          <IconButton
                            className="goNextBtn"
                            onClick={() => {
                              filehandleHorizantalScroll(
                                fileElementRef.current,
                                25,
                                200,
                                10
                              );
                            }}
                          >
                            <ArrowForwardIos />
                          </IconButton>
                        )}
                      </div>
                      <div class="elmntAddMrFiles">
                        <Button
                          className="elemntMrFileBtn"
                          variant="outlined"
                          onClick={handleAddnewFile}
                        >
                          <Plus />
                        </Button>
                        <input
                          type="file"
                          ref={fileInputRef}
                          onChange={handleChangeFile}
                          style={{ display: "none" }}
                        ></input>
                      </div>
                    </div>
                  </div> */}

                <FileList
                  data={data}
                  tempDivWidth={tempDivWidth}
                  handleChangeFile={handleChangeFile}
                  setShowFileUploadModal={setShowFileUploadModal}
                />

                {/* files end */}

                {/* <div className="taskElementGrp">
                  <div className="tskElemHeding">Priority</div>
                  <div className="tskPrioArea">
                    <div
                      class={`tskPriot  ${
                        data.priority === 0
                          ? "statNormal"
                          : data.priority === 1
                          ? "statImportant"
                          : "statUrgent"
                      }`}
                    >
                      <div class="priStat">
                        {data.priority === 0
                          ? "Regular"
                          : data.priority === 1
                          ? "Important"
                          : "Critical"}
                      </div>
                    </div>
                  </div>
                </div> */}

                <PriorityComponent
                  data={data}
                  refreshData={refreshData}
                  removeFromStore={removeFromStore}
                />

                <div className="taskElementsGrpWithTwo">
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">Activity</div>
                    <div className="tskElemntInfo">
                      {data && data.activityName}
                    </div>
                  </div>
                </div>

                <div className="taskElementsGrpWithTwo">
                  {/* <div className="taskElementGrp">
                        <div className="tskElemHeding">Assigned to</div>
                        <div className="tskElemntInfo">
                          {data && data.assignByUserName}
                        </div>
                      </div> */}
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">Postpone By</div>
                    <div className="tskElemntInfo">
                      {data && data.approveRejectConcernedPostponedByUserName}
                    </div>
                  </div>
                </div>
                <div className="taskElementsGrpWithTwo">
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">Postpone Till</div>
                    <div className="tskElemntInfo">
                      {data && data.postponedTill}
                    </div>
                  </div>
                </div>
                <div className="taskElementsGrpWithTwo">
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">Postpone Reason</div>
                    <div className="tskElemntInfo">
                      {data && data.postponedReason}
                    </div>
                  </div>
                </div>

                <LinkedTaskModule
                  data={data}
                  handleLinkTaskClick={(data) => handleLinkTaskClick(data)}
                />

                <div className="taskElementGrp">
                  <div className="tskElemHeding">Last updated</div>
                  <div className="tskElemntInfo">
                    {data &&
                      data.comments &&
                      data.comments.length &&
                      `${
                        data.comments[data.comments.length - 1]
                          .commentsByUserName
                      } - ${getFormattedDate(
                        data.comments[data.comments.length - 1].commentsAt
                      )}`}
                  </div>
                </div>

                {/* <div className="taskElementGrp">
                      <div className="tskElemHeding">Description</div>
                      <div className="tskElemntInfo">
                        Installing Adobe Creative Suite on my machine is crucial for
                        maximizing efficiency and ensuring seamless collaboration
                        within our creative projects.
                      </div>
                    </div> */}

                <div className="taskUpCommentGrp">
                  {data &&
                    data.comments.length > 0 &&
                    data.comments.map((comment) => (
                      <div className="taskUserComment">
                        <div className="tskUderImgDT">
                          <div className="tskUseComImg">
                            <img
                              src={comment.commentsByUserProfileUrl}
                              alt=""
                            />
                          </div>
                          <div className="tskUsrCNM">
                            <div className="tskUCNM">
                              {comment.commentsByUserName}
                            </div>
                            <div className="tskUCDT">
                              {comment && getFormattedDate(comment.commentsAt)}
                            </div>
                          </div>
                        </div>
                        <div className="taskUPComnt">{comment.comments}</div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="taskFooterPanel">
                <div className="tskInputGrp">
                  <input
                    ref={commentInputRef}
                    type="text"
                    className="tskComntInput"
                    placeholder="Update comment here"
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        console.log("Enter key pressed");
                        sendComment(event);
                      }
                    }}
                  />
                  <IconButton className="tskComtSndBtn" onClick={sendComment}>
                    <Send />
                  </IconButton>
                </div>
              </div> */}
        </div>
        {showAddressConcern && (
          <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
            <div className="addMoreAgendaModel">
              <div className="modelTitle">Address Concern</div>

              <div className="modInputFild">
                <TextField
                  id="outlined-basic"
                  label="Concern Remark"
                  variant="outlined"
                  value={addressConcernRemark}
                  onChange={(e) => setAddressConcernRemark(e.target.value)}
                />
              </div>

              {/* <div className="formElement">
                    <FormControl className="formControl">
                      <Autocomplete
                        id="adduser-select"
                        className="formAutocompleteField"
                        disableClearable
                        variant="outlined"
                        options={getFilteredData()}
                        getOptionLabel={(option) => option.userName}
                        getOptionSelected={(option) => {
                          console.log("option is ", option);
                          return option.userId;
                        }}
                        value={selectedUsers}
                        onChange={(e, newValue) => {
                          console.log("new value = ", newValue);
                          setSelectedUsers(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="formAutoComInputField"
                            placeholder="Search User"
                            InputProps={{
                              ...params.InputProps,
                              type: "search",
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </div> */}

              <div className="modActionBtnGrp">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={(e) => {
                    if (addressConcernRemark.trim() === "") {
                      toast.error("Please provide your concern response");
                      return;
                    }

                    // const reqDto = {
                    //   comments: addressConcernRemark,
                    //   taskDetailId: data.taskId,
                    //   // progress: data.progress,
                    //   orgId: loginUserDetail.orgId,
                    //   previousAssigness:
                    //     assigneeList && assigneeList.length > 0
                    //       ? assigneeList.map((item) => item.id)
                    //       : [],
                    //   currentAssigness:
                    //     selectedUsers.length > 0
                    //       ? selectedUsers
                    //       : assigneeList.map((item) => item.id),
                    // };

                    DashboardServices.addressConcernOfTask(
                      data.taskId,
                      loginUserDetail.userId,
                      addressConcernRemark
                    ).then((response) => {
                      if (response.data && response.data.returnValue === "1") {
                        toast.success(response.data.message, {
                          position: toast.POSITION.TOP_RIGHT,
                        });
                        setShowAddressConcern(false);
                        dispatch(clearTaskModalData());
                      } else {
                        toast.error(response.data.message, {
                          position: toast.POSITION.TOP_RIGHT,
                        });
                      }
                      setAddressConcernRemark("");
                      refreshData();
                      // setSelectedUsers([]);
                    });
                  }}
                >
                  Submit
                </Button>
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => {
                    setShowAddressConcern(false);
                    setAddressConcernRemark("");
                    setSelectedUsers([]);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Box>
        )}

        {/* {showForwardTask && (
              <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
                <div className="addMoreAgendaModel">
                  <div className="modelTitle">Forward Task</div>
                  <div
                    className="modInputFild"
                    style={{ border: "1px solid black" }}
                  >
                    <TextField
                      sx={{ color: "balck !important" }}
                      label="Forward Remark"
                      variant="outlined"
                      multiline
                      rows={4}
                      maxRows={7}
                      value={forwardReason}
                      onChange={(e) => setForwardReason(e.target.value)}
                    />
                  </div>
    
                  <div className="formElement">
                    <FormControl className="formControl">
                      <Autocomplete
                        id="adduser-select"
                        disableClearable
                        className="formAutocompleteField"
                        variant="outlined"
                        options={getFilteredUser()}
                        getOptionLabel={(option) => option.userName}
                        getOptionSelected={(option) => {
                          console.log("option is ", option);
                          return option.userId;
                        }}
                        onChange={(e, newValue) => {
                          console.log("new value = ", newValue);
                          setSelectedUsers(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="formAutoComInputField"
                            placeholder="Search User"
                            InputProps={{
                              ...params.InputProps,
                              type: "search",
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </div>
    
                  <div className="modActionBtnGrp">
                    <Button
                      className="dfultPrimaryBtn"
                      onClick={(e) => {
                        handleSubmitTransferTask();
                      }}
                    >
                      Submit
                    </Button>
                    <Button
                      className="dfultDarkSecondaryBtn"
                      onClick={() => {
                        setShowForwardTask(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </Box>
            )} */}
        {showForwardTask && (
          <ForwardTaskModal
            data={data}
            userList={userList}
            refreshData={refreshData}
            handleClose={() => {
              setShowForwardTask(false);
            }}
          />
        )}

        {showReassignTask && (
          <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
            <div className="addMoreAgendaModel">
              <div className="modelTitle">Reassing Task</div>

              <div
                className="modInputFild"
                style={{ padding: "20px 10px 10px 0px" }}
              >
                Select Your Progress Of Task
              </div>
              <div className="workUpdateBtnGrp">
                {numberList &&
                  numberList.map((number) => (
                    <Button
                      className={`${
                        tempUpdatePercent === number ? "activeStatus" : ""
                      }`}
                      variant="contained"
                      // disabled={number <= updatePercentValue}
                      onClick={(e) => {
                        setTempUpdatePercent(number);
                        console.log("");
                      }}
                    >
                      {number}
                    </Button>
                  ))}
              </div>

              <div
                className="modInputFild"
                style={{ border: "1px solid black" }}
              >
                <TextField
                  sx={{ color: "balck !important" }}
                  label="Description"
                  variant="outlined"
                  multiline
                  rows={4}
                  maxRows={7}
                  value={reassignReason}
                  onChange={(e) => setReassignReason(e.target.value)}
                />
              </div>

              <div className="modInputFile">
                <Autocomplete
                  id="adduser-select"
                  disableClearable
                  className="formAutocompleteField"
                  variant="outlined"
                  options={userList}
                  getOptionLabel={(option) => option.userName}
                  getOptionSelected={(option) => {
                    console.log("option is ", option);
                    return option.userId;
                  }}
                  onChange={(e, newValue) => {
                    console.log("new value = ", newValue);
                    setReassignUser(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      sx={{ color: "black" }}
                      {...params}
                      placeholder="Search User"
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                      }}
                    />
                  )}
                />
              </div>

              <div className="modActionBtnGrp">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={(e) => {
                    handleSubmitReassignTask();
                  }}
                >
                  Submit
                </Button>
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => {
                    setShowReassignTask(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Box>
        )}

        {showResumeModal && (
          <ResumeModal
            data={data}
            refreshData={refreshData}
            handleClose={(e) => setShowResumeModal(false)}
          />
        )}

        {showCreateLinkTask && (
          <LinkTaskModal
            userList={userList}
            data={data}
            handleClose={(e) => setShowCreateLinkTask(false)}
            refreshData={refreshData}
            removeFromStore={removeFromStore}
          />
        )}

        {showDisagreeModal && (
          <DisagreeTask
            assignByUserId={data.assignByUserId}
            userList={userList}
            onClose={() => {
              setShowDisAgreeModal(false);
            }}
            refreshData={refreshData}
            taskDetailId={data.taskId}
          />
        )}

        {showAssigneeList && (
          <AssigneeModal
            assigneeList={assigneeList}
            handleClose={(e) => setShowAssigneeList(false)}
          />
        )}
        {showEditDeadline && (
          <EditDeadLineModal
            createdAt={data.createdAt}
            completionTime={data.completionTime}
            closeModal={() => {
              setShowEditDeadLine(false);
            }}
            onClose={(e) => setShowEditDeadLine(false)}
            userList={userList}
            handleClose={(e) => setShowEditDeadLine(false)}
            previousAssigness={assigneeList}
            data={data}
            refreshData={refreshData}
            removeFromStore={removeFromStore}
          />
        )}

        {showAudioModal && (
          <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
            <div className="addMoreAgendaModel">
              <div className="modelTitle">Audio Message</div>
              <div className="modActionBtnGrp">
                <audio controls>
                  <source src={`${audioUrl.current}`} />
                </audio>
              </div>

              <div className="modActionBtnGrp">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={() => {
                    setShowAudioModal(false);
                  }}
                >
                  Ok
                </Button>
                {/* <Button
                      className="dfultDarkSecondaryBtn"
                      onClick={() => {
                        setShowAudioModal(false);
                      }}
                    >
                      Ok
                    </Button> */}
              </div>
            </div>
          </Box>
        )}

        {showReopenModal && (
          <ReopenModal
            taskId={data.taskId}
            handleClose={(e) => {
              setShowReopenModal(false);
            }}
            refreshData={refreshData}
          />
        )}
      </div>
      {showAddTemplate && (
        <RightFlotingContainer
          recommendedTemplateList={recommendedTemplateList}
          setRecommendedTemplateList={setRecommendedTemplateList}
          tempOfOrgList={tempOfOrgList}
          addedTemplateList={addedTemplateList}
          setAddedTemplateList={setAddedTemplateList}
          closeTemplate={() => {
            setShowAddTemplate(false);
            if (addedTemplateList && addedTemplateList.length > 0) {
              const temp = [...addedTemplateList];
              temp.forEach((template) => {
                template.taskDetailId = data.taskId;
              });
              DashboardServices.addTaskTemplate(
                loginUserDetail.userId,
                addedTemplateList,
                "OLD"
              ).then((response) => {
                console.log("addTaskTemplate", response.data);
                const tempData = { ...data };
                if (tempData.templates) {
                  tempData.templates = [
                    ...tempData.templates,
                    ...addedTemplateList,
                  ];
                } else {
                  tempData.templates = [...addedTemplateList];
                }
                dispatch(setTaskModalData(tempData));
              });
            }
          }}
          mode={"old"}
        />
      )}

      {showFileModal && (
        <div className="RightFlotingContainerArea">
          <RightTaskFileUpload
            documents={data.documents}
            onClose={(e) => setShowFileUploadModal(false)}
            uploadFile={(data) => {
              handleChangeFile(data);
            }}
          />
        </div>
      )}
    </>
  );
};

export default TaskTabReopenPostponed;
