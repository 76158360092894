import authHeader from "../config/auth-header-config";
import http from "../config/http-common";

class LoginSignupService {
  checkIfMailIdExists(loginId) {
    return http.get(`userLogin/checkIfMailIdExists/${loginId}`, {
      headers: authHeader(),
    });
  }

  getOrgListByLoginId(loginId) {
    return http.get(`org/getOrgListByLoginId/${loginId}`, {
      headers: authHeader(),
    });
  }

  userLogin(data) {
    return http.post(`authenticate`, data);
  }

  generateOtpForEmailValidation(data, subdomain) {
    let authHeaderVal = authHeader().Authorization;
    const config = {
      headers: {
        "Content-type": "text/plain",
        Authorization: authHeaderVal,
      },
    };
    return http.post(
      `userSignup/generateOtpForEmailValidation/${subdomain}`,
      data,
      config
    );
  }

  validateOtp(otpId, otpCode) {
    return http.post(`userSignup/validateOtp/${otpId}/${otpCode}`, {
      headers: authHeader(),
    });
  }

  signupUser(data) {
    return http.post(`userSignup`, data, { headers: authHeader() });
  }

  generateOtpForPasswordResetNew(data, subdomain) {
    let authHeaderVal = authHeader().Authorization;
    const config = {
      headers: {
        "Content-type": "text/plain",
        Authorization: authHeaderVal,
      },
    };
    return http.post(
      `userSignup/generateOtpForPasswordReset/${subdomain}`,
      data,
      config
    );
  }

  changePassword(data) {
    return http.post(`userSignup/changePassword`, data, {
      headers: authHeader(),
    });
  }

  clearFcmToken(userId, reqDto) {
    return http.put(`/userLogin/clearFcmToken/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }

  //   getUserDetails(loginId) {
  //     let authHeaderVal = authHeader().Authorization;
  //     const config = {
  //       headers: {
  //         "Content-type": "text/plain",
  //         Authorization: authHeaderVal,
  //       },
  //     };
  //     console.log(config);
  //     return http.put(`userLogin/getUserDetails`, loginId, config);
  //   }

  //   getUserDetailsAndSetToken(loginId) {
  //     let authHeaderVal = authHeader().Authorization;
  //     const config = {
  //       headers: {
  //         "Content-type": "text/plain",
  //         Authorization: authHeaderVal,
  //       },
  //     };
  //     console.log(config);
  //     return http.put(`userLogin/getUserDetails`, loginId, config);
  //   }

  //   createJWTToken(token) {
  //     return "Bearer " + token;
  //   }

  //   generateOtpForPasswordReset(data) {
  //     console.log(data);
  //     let authHeaderVal = authHeader().Authorization;
  //     const config = {
  //       headers: {
  //         "Content-type": "text/plain",
  //         Authorization: authHeaderVal,
  //       },
  //     };
  //     return http.post(`userSignup/generateOtpForPasswordReset`, data, config);
  //   }

  //   changePasswordByUserId(userId, data) {
  //     let authHeaderVal = authHeader().Authorization;
  //     const config = {
  //       headers: {
  //         "Content-type": "text/plain",
  //         Authorization: authHeaderVal,
  //       },
  //     };
  //     return http.post(`userLogin/changePassword/${userId}`, data, config);
  //   }

  //   updateFCMTokenIdOfUser(userId, fcmTokenId) {
  //     console.log(userId, fcmTokenId);
  //     let authHeaderVal = authHeader().Authorization;
  //     const config = {
  //       headers: {
  //         "Content-type": "text/plain",
  //         Authorization: authHeaderVal,
  //       },
  //     };
  //     return http.put(`userLogin/updateFcmTokenId/${userId}`, fcmTokenId, config);
  //   }

  //   duplicateSubdomain(subdomain) {
  //     console.log(subdomain);
  //     return http.get(`org/duplicateSubdomain/${subdomain}`, {
  //       headers: authHeader(),
  //     });
  //   }

  //   getNextMeetingAndPendingTasks(userId) {
  //     console.log(userId);
  //     return http.get(`userLogin/getNextMeetingAndPendingTasks/${userId}`, {
  //       headers: authHeader(),
  //     });
  //   }

  //   signupWithInviteLink(data) {
  //     //console.log(data);
  //     return http.post(`/userSignup/signupWithInviteLink`, data, {
  //       headers: authHeader(),
  //     });
  //   }

  checkIfPersonalProfileExists(loginId) {
    console.log(loginId);
    return http.get(`userLogin/checkIfPersonalProfileExists/${loginId}`, {
      headers: authHeader(),
    });
  }
  verifyToken(reqDto) {
    //console.log(data);
    return http.post(`/userLogin/verifyToken`, reqDto, {
      headers: authHeader(),
    });
  }
  getOrgDetailsBySubdomain(subdomain) {
    console.log(subdomain);
    return http.get(`org/getOrgDetailsBySubdomain/${subdomain}`, {
      headers: authHeader(),
    });
  }

  createAccountOrgUser(data) {
    return http.post(`userSignup/createAccountOrgUser`, data, {
      headers: authHeader(),
    });
  }

  getRandomKeyForCrypto() {
    return http.get(`userLogin/getRandomKeyForCrypto`, {
      headers: authHeader(),
    });
  }

  //   updateFcmTokenIdDeviceBased(userId, reqDto) {
  //     return http.put(
  //       `/userLogin/updateFcmTokenIdDeviceBased/${userId}`,
  //       reqDto,
  //       {
  //         headers: authHeader(),
  //       }
  //     );
  //   }
}

export default new LoginSignupService();
