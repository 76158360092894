import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ArrowClockwise, XLg } from "react-bootstrap-icons";
import lrLogin from "../../images/login.png";
import {
  ArrowForward,
  CheckCircleOutline,
  Send,
  Visibility,
  VisibilityOff,
  Cancel,
  CheckCircle,
  CancelOutlined,
} from "@mui/icons-material";
import LoginSignupService from "../../../../services/LoginSignupService";
import { toast } from "react-toastify";
import {
  PERSONAL_ORG_ID,
  SELECT_MenuProps,
} from "../../../../constants/const_string";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetPassword } from "aws-amplify/auth";
import AppUtils from "../../../../utils/AppUtils";
import { jwtDecode } from "jwt-decode";
import { validateEmail } from "../../../../utils/Utils";
import { getActiveOtpServices } from "../../../../services/AdminService";
import { useTranslation } from "react-i18next";
import { AppInitializerContext } from "../../../../utils/AppInitializer";
import SecureIndexedDB from "../../../../utils/IndexedDB";
import { AgreementModal } from "./AgreementModal";

export default function LoginRegister({ handelCloseLogRegContain }) {
  const { userLoginDetails, setUserLoginDetails } = useContext(
    AppInitializerContext
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPasswordLogin = (event) => {
    event.preventDefault();
  };

  const [showLoginForm, setShowLoginForm] = useState(true);
  const [showRegistrationForm, setShowRegistrationForm] = useState(false);
  const [showForgotPassForm, setShowForgotPassForm] = useState(false);
  const [showAgreementModal, setShowAgreementModal] = useState(false);
  const [hasAgreed, setHasAgreed] = useState(false);

  const handelOpenLogin = () => {
    setShowLoginForm(true);
    setShowRegistrationForm(false);
    setShowForgotPassForm(false);
  };
  const handelOpenRegistration = () => {
    setShowLoginForm(false);
    setShowRegistrationForm(true);
    setShowForgotPassForm(false);
    setShowAgreementModal(false);
  };

  const handelOpenAcceptModal = () => {
    if (hasAgreed) {
      handelOpenRegistration();
      return;
    }
    setShowLoginForm(true);
    setShowAgreementModal(true);
    setShowRegistrationForm(false);
    setShowForgotPassForm(false);
  };

  const handelOpenForgotPassword = () => {
    setUserLoginId(null);
    setUserLoginIdFP(null);
    setShowForgotPassForm(true);
    setShowLoginForm(false);
    setShowRegistrationForm(false);
  };

  // register user password start

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [isValid, setIsValid] = useState(false);

  // to check if all password policy conditions are met
  useEffect(() => {
    const checkPasswordPolicy = () => {
      const hasUpperCase = newPassword.match(/[A-Z]/);
      const hasLowerCase = newPassword.match(/[a-z]/);
      const hasNumber = newPassword.match(/[0-9]/);
      const hasSpecialChar = /[!@#$%^&*(),.?":{}|~<>]/.test(newPassword);
      const isMinLength = newPassword.length >= 8;
      const isMatching = newPassword === confirmPassword;

      return (
        hasUpperCase &&
        hasLowerCase &&
        hasNumber &&
        hasSpecialChar &&
        isMinLength &&
        isMatching
      );
    };

    setIsValid(checkPasswordPolicy());
  }, [newPassword, confirmPassword]);

  const saveCredential = ({ username, password }) => {
    if (window.PasswordCredential && navigator.credentials) {
      const credentials = new window.PasswordCredential({
        id: username,
        password: password,
      });

      return navigator.credentials.store(credentials);
    }
  };

  const handleChangeNewPassword = (event) => {
    setNewPassword(event.target.value);
    getPasswordStrength(event.target.value);
  };

  const handleChangeConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const getPasswordStrength = (password) => {
    const meterBar = document.getElementById("meter-bar");
    const meterStatus = document.getElementById("meter-status");

    let strength = 0;

    if (password.match(/[A-Z]/)) {
      strength += 1;
    }

    if (password.match(/[a-z]/)) {
      strength += 1;
    }

    if (password.match(/[0-9]/)) {
      strength += 1;
    }

    if (password.match(/[!@#$%^&*(),.?":{}|~<>]/)) {
      strength += 1;
    }

    if (password.length >= 16) {
      strength += 1;
    }

    if (password.length < 8) {
      meterBar.style.background = "#a7a7a7";
      meterBar.style.width = "5%";
      meterStatus.style.color = "#a7a7a7";
      meterStatus.innerText = "Poor";
      return;
    }

    if (strength === 3) {
      meterBar.style.background = "#e6ae01";
      meterBar.style.width = "50%";
      meterStatus.style.color = "#e6ae01";
      meterStatus.innerText = "Medium";
    } else if (strength === 4) {
      meterBar.style.background = "#0f98e2";
      meterBar.style.width = "75%";
      meterStatus.style.color = "#0f98e2";
      meterStatus.innerText = "Strong";
    } else if (strength === 5) {
      meterBar.style.background = "#00ff33";
      meterBar.style.width = "100%";
      meterStatus.style.color = "#00ff33";
      meterStatus.innerText = "Very Strong";
    } else {
      meterBar.style.background = "#ff0000";
      meterBar.style.width = "25%";
      meterStatus.style.color = "#ff0000";
      meterStatus.innerText = "Weak";
    }
  };

  // register user password end

  //login section

  //login id is email id
  const [userLoginId, setUserLoginId] = useState(null);
  const [userPassword, setUserPassword] = useState(null);
  const [orgList, setOrgList] = useState([]);
  const [fetchingOrgList, setFetchingOrgList] = useState(false);
  const loginIdRef = useRef(null);
  const [checkingCreds, setCheckingCreds] = useState(false);

  const getAllOrgsAssociatedWithUser = async () => {
    setFetchingOrgList(true);

    if (!userLoginId || userLoginId.trim() === "") {
      toast.error(t("please_enter_your_email_id"));
      loginIdRef.current.focus();
      setFetchingOrgList(false);
      return false;
    }

    if (!validateEmail(userLoginId)) {
      toast.error(t("please_enter_valid_email_id"));
      loginIdRef.current.focus();
      setFetchingOrgList(false);
      return false;
    }

    console.log("getAllOrgsAssociatedWithUser", userLoginId);
    const resOrgList = await LoginSignupService.getOrgListByLoginId(
      userLoginId.toLowerCase()
    );
    console.log(resOrgList.data);
    setFetchingOrgList(false);
    if (resOrgList.data.length > 0) {
      let selOrg = resOrgList.data.find((obj) => obj.orgId === PERSONAL_ORG_ID);
      if (!selOrg) {
        setSelectOrg(resOrgList.data[0].orgId);
      } else {
        setSelectOrg(
          resOrgList.data.find((obj) => obj.orgId === PERSONAL_ORG_ID).orgId
        );
      }
      setOrgList(resOrgList.data);
    } else {
      setOrgList([]);
      toast.error(t("no_accounts_found"));
    }
  };

  const [selectOrg, setSelectOrg] = React.useState("");
  const handleChangeSelectOrg = (event) => {
    console.log(event.target.value);
    setSelectOrg(event.target.value);
  };

  const loginPersonalUser = async () => {
    if (!userLoginId || userLoginId.trim() === "") {
      toast.error(t("please_enter_your_email_id"));
      loginIdRef.current.focus();
      setFetchingOrgList(false);
      return false;
    }

    if (!validateEmail(userLoginId)) {
      toast.error(t("please_enter_valid_email_id"));
      loginIdRef.current.focus();
      setFetchingOrgList(false);
      return false;
    }

    if (!userPassword || userPassword.trim() === "") {
      toast.error(t("please_enter_your_password"));
      return false;
    }

    if (!selectOrg) {
      toast.error(t("please_select_an_organization_to_login"));
      return false;
    }

    setCheckingCreds(true);

    let reqDto = {
      username: userLoginId.toLowerCase() + "~" + selectOrg,
      password: userPassword,
    };
    console.log("onSubmitSignIn", reqDto);
    try {
      const resLogin = await LoginSignupService.userLogin(reqDto);

      if (resLogin.status === 401 || resLogin.data === "INVALID_CREDENTIALS") {
        toast.error(t("invalid_credentials"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        setCheckingCreds(false);
        return;
      }

      if (selectOrg === PERSONAL_ORG_ID) {
        console.log("personal", resLogin.data);
        localStorage.clear();
        SecureIndexedDB.setItem("token", resLogin.data.token);
        const tokenData = jwtDecode(resLogin.data.token);
        setUserLoginDetails(tokenData);
        SecureIndexedDB.setItem("userProfileImg", tokenData.userProfileImage);
        if (tokenData.orgId) {
          getActiveOtpServices(tokenData.orgId).then((response) => {
            console.log("responseotpservicedata === 3333", response.data);
            if (Array.isArray(response.data) && response.data.length > 0) {
              SecureIndexedDB.setItem(
                "otpServices",
                JSON.stringify(response.data)
              );
            }
          });
        }
        try {
          const credentialPromise = await saveCredential({
            username: userLoginId,
            password: userPassword,
          });
        } catch (e) {
          console.log("Problem Storing credential");
        }

        navigate(`/dashboard`, {
          state: {
            // userName: resLogin.data.userName,
            // userId: resLogin.data.userId,
            // referralLinkId:
            //   null != refContactData ? refContactData.inviteLink : null,
          },
        });
      } else {
        console.log("org user", resLogin.data);
        const selectedOrg = orgList.find((obj) => obj.orgId === selectOrg);
        toast.success(
          t("login_successful_redirecting_to_organization_website")
        );
        try {
          const credentialPromise = await saveCredential({
            username: userLoginId,
            password: userPassword,
          });
        } catch (e) {
          console.log("Problem Storing credential");
        }
        setTimeout(() => {
          window.open(
            window.location.protocol +
              "//" +
              selectedOrg.subdomain +
              "." +
              window.location.host +
              "?uId=" +
              resLogin.data.token,
            "_blank"
          );
        }, 2000);

        setCheckingCreds(false);
        setOrgList([]);
      }
    } catch (err) {
      toast.error(t("invalid_credentials"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setCheckingCreds(false);
    }
  };
  //
  //

  //forgot password section

  const [userLoginIdFP, setUserLoginIdFP] = useState(null);
  const [orgListFP, setOrgListFP] = useState([]);
  const [userPasswordFP, setUserPasswordFP] = useState(null);
  const [userConfPasswordFP, setUserConfPasswordFP] = useState(null);
  const [otp, setOtp] = useState(null);
  const [otpId, setOtpId] = useState(null);
  const [fetchingOrgListFP, setFetchingOrgListFP] = useState(false);
  const [selectOrgFP, setSelectOrgFP] = React.useState("");
  const [otpValidated, setOtpValidated] = useState(false);
  const [sendingOtp, setSendingOtp] = useState(false);
  const [validatingOtp, setValidatingOtp] = useState(false);

  const getAllOrgsAssociatedWithUserFP = async () => {
    setFetchingOrgListFP(true);

    if (!userLoginIdFP || userLoginIdFP.trim() === "") {
      toast.error(t("please_enter_your_email_id"));
      // loginIdRef.current.focus();
      setFetchingOrgListFP(false);
      return false;
    }

    if (!validateEmail(userLoginIdFP)) {
      toast.error(t("please_enter_valid_email_id"));
      setFetchingOrgListFP(false);
      return false;
    }

    console.log("getAllOrgsAssociatedWithUserFP", userLoginId);
    const resOrgList = await LoginSignupService.getOrgListByLoginId(
      userLoginIdFP.toLowerCase()
    );
    console.log(resOrgList.data);
    setFetchingOrgListFP(false);
    if (resOrgList.data.length > 0) {
      const selOrg = resOrgList.data.find(
        (obj) => obj.orgId === PERSONAL_ORG_ID
      );
      if (!selOrg) {
        setSelectOrgFP(resOrgList.data[0].orgId);
      } else {
        setSelectOrgFP(
          resOrgList.data.find((obj) => obj.orgId === PERSONAL_ORG_ID).orgId
        );
      }

      setOrgListFP(resOrgList.data);
    } else {
      setOrgListFP([]);
      toast.error(t("no_accounts_found"));
    }
  };

  const handleChangeSelectOrgFP = (event) => {
    console.log(event.target.value);
    if (otpId) {
      setOtpId(null);
    }

    if (otpValidated) {
      setOtpValidated(false);
      // setNewPassword(null);
      // setConfirmPassword(null);
      setOtp(null);
      setOtpId(null);
    }
    setSelectOrgFP(event.target.value);
  };

  const sendOTP = async () => {
    const selectedOrgFP = orgListFP.find((obj) => obj.orgId === selectOrgFP);
    setSendingOtp(true);
    const resOtp = await LoginSignupService.generateOtpForPasswordResetNew(
      userLoginIdFP.toLowerCase(),
      selectedOrgFP.subdomain
    );

    console.log(resOtp.data);
    if (resOtp.data.returnValue === "1") {
      setOtpId(resOtp.data.message);
      toast.info(t("otp_sent_successfully"));
    } else {
      toast.error(t("something_went_wrong"));
    }
    setSendingOtp(false);
  };

  const validateOTP = async () => {
    if (!otp || otp.trim() === "") {
      toast.error(t("please_enter_otp"));
      return false;
    }
    setValidatingOtp(true);
    const resOTP = await LoginSignupService.validateOtp(otpId, otp);
    if (resOTP.data.returnValue === "-1") {
      toast.error(resOTP.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setValidatingOtp(false);
    } else if (resOTP.data.returnValue === "0") {
      toast.error(resOTP.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setValidatingOtp(false);
    } else {
      toast.info(t("otp_verified_successfully"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setValidatingOtp(false);
      setOtpValidated(true);
    }
  };

  const changePassword = async () => {
    const selectedOrgFP = orgListFP.find((obj) => obj.orgId === selectOrgFP);
    let reqDto = {
      userPassword: newPassword,
      userEmailId: userLoginIdFP.toLowerCase(),
      subdomain: selectedOrgFP.subdomain ? selectedOrgFP.subdomain : "",
    };
    const resChangePass = await LoginSignupService.changePassword(reqDto);

    if (resChangePass.data.returnValue === "0") {
      toast.error(resChangePass.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      // setLoading(false);
    } else {
      try {
        saveCredential({
          username: userLoginIdFP.trim(),
          password: newPassword,
        });
      } catch (e) {
        console.log("Password could not be saved");
      }
      toast.info(resChangePass.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setOtpValidated(false);
      // setNewPassword(null);
      // setConfirmPassword(null);
      setOtp(null);
      setOtpId(null);
      setShowForgotPassForm(false);
      setUserLoginIdFP(null);
      setOrgListFP([]);
      setShowLoginForm(true);
    }
  };

  //
  //

  //signup section

  const [userName, setUserName] = useState(null);
  const [userLoginIdSU, setUserLoginIdSU] = useState(null);
  const [orgListSU, setOrgListSU] = useState([]);
  const [userPasswordSU, setUserPasswordSU] = useState(null);
  const [userConfPasswordSU, setUserConfPasswordSU] = useState(null);
  const [otpSU, setOtpSU] = useState(null);
  const [otpIdSU, setOtpIdSU] = useState(null);
  const [fetchingOrgListSU, setFetchingOrgListSU] = useState(false);
  const [selectOrgSU, setSelectOrgSU] = React.useState("");
  const [otpValidatedSU, setOtpValidatedSU] = useState(false);
  const [sendingOtpSU, setSendingOtpSU] = useState(false);
  const [validatingOtpSU, setValidatingOtpSU] = useState(false);
  const [showResendButton, setShowResendButton] = useState(false);
  const [timeLeft, setTimeLeft] = useState(120);
  const countdownStartRef = useRef(Date.now());
  const [fetchingOrgListSUResendOTP, setFetchingOrgListSUResendOTP] =
    useState(false);
  const [otpValidatedForResend, setOtpValidatedForResend] = useState(false);

  const startCountdown = () => {
    countdownStartRef.current = Date.now();
    setTimeLeft(120);

    const timer = setInterval(() => {
      const elapsedTime = Math.floor(
        (Date.now() - countdownStartRef.current) / 1000
      );
      const newTimeLeft = 120 - elapsedTime;

      if (newTimeLeft <= 0) {
        clearInterval(timer);
        setShowResendButton(true);
        setOtpValidatedForResend(true);
        setTimeLeft(0);
      } else {
        setTimeLeft(newTimeLeft);
      }
    }, 1000);

    return () => clearInterval(timer);
  };

  // const handleVisibilityChange = () => {
  //     if (document.visibilityState === "visible") {
  //         const elapsedTime = Math.floor((Date.now() - countdownStartRef.current) / 1000);
  //         const newTimeLeft = 120 - elapsedTime;

  //         if (newTimeLeft <= 0) {
  //             setShowResendButton(true);
  //             setTimeLeft(0);
  //         } else {
  //             setShowResendButton(false);
  //             setTimeLeft(newTimeLeft);
  //         }
  //     }
  // };

  const getAllOrgsAssociatedWithUserSU = async () => {
    // toast.info(
    //   "Otp has been sent successfully, please check your spam folder if not received"
    // );
    setFetchingOrgListSU(true);

    if (!userLoginIdSU || userLoginIdSU.trim() === "") {
      toast.error(t("please_enter_your_email_id"));
      setFetchingOrgListSU(false);
      return false;
    }

    if (!validateEmail(userLoginIdSU)) {
      toast.error(t("please_enter_valid_email_id"));
      setFetchingOrgListSU(false);
      return false;
    }

    if (!userName || userName.trim() === "") {
      toast.error(t("please_enter_your_name"));
      setFetchingOrgListSU(false);
      return false;
    }

    console.log("getAllOrgsAssociatedWithUserSU", userLoginId);
    const resPerProfileExists =
      await LoginSignupService.checkIfPersonalProfileExists(
        userLoginIdSU.toLowerCase()
      );
    console.log(resPerProfileExists.data);
    if (resPerProfileExists.data.returnValue === "1") {
      toast.error(resPerProfileExists.data.message);
      setFetchingOrgListSU(false);
    } else {
      toast.info(t("sending_otp_please_wait"));
      const resSendOtp = await LoginSignupService.generateOtpForEmailValidation(
        userLoginIdSU.toLowerCase(),
        "NA"
      );

      if (resSendOtp.data.returnValue === "1") {
        toast.info(t("otp_sent_successfully"));
        setOtpIdSU(resSendOtp.data.message);
        setFetchingOrgListSU(false);
        setShowResendButton(false);
        startCountdown();
      } else {
        toast.error(t("something_went_wrong"));
      }

      console.log(resSendOtp.data);
    }
  };

  const getAllOrgsAssociatedWithUserSUResendOTP = async () => {
    // toast.info(
    //   "Otp has been sent successfully, please check your spam folder if not received"
    // );
    setFetchingOrgListSUResendOTP(true);
    // setShowResendButton(false);

    if (!userLoginIdSU || userLoginIdSU.trim() === "") {
      toast.error(t("please_enter_your_email_id"));
      setFetchingOrgListSUResendOTP(false);
      return false;
    }

    if (!validateEmail(userLoginIdSU)) {
      toast.error(t("please_enter_valid_email_id"));
      setFetchingOrgListSUResendOTP(false);
      return false;
    }

    if (!userName || userName.trim() === "") {
      toast.error(t("please_enter_your_name"));
      setFetchingOrgListSUResendOTP(false);
      return false;
    }

    console.log("getAllOrgsAssociatedWithUserSU", userLoginId);
    const resPerProfileExists =
      await LoginSignupService.checkIfPersonalProfileExists(
        userLoginIdSU.toLowerCase()
      );
    console.log(resPerProfileExists.data);
    if (resPerProfileExists.data.returnValue === "1") {
      toast.error(resPerProfileExists.data.message);
      setFetchingOrgListSU(false);
    } else {
      toast.info(t("sending_otp_please_wait"));
      const resSendOtp = await LoginSignupService.generateOtpForEmailValidation(
        userLoginIdSU.toLowerCase(),
        "NA"
      );

      if (resSendOtp.data.returnValue === "1") {
        toast.info(t("otp_sent_successfully"));
        setOtpIdSU(resSendOtp.data.message);
        setFetchingOrgListSUResendOTP(false);
        setShowResendButton(false);
        setOtpValidatedForResend(false);
        startCountdown();
      } else {
        toast.error(t("something_went_wrong"));
      }

      console.log(resSendOtp.data);
    }
  };

  const handleChangeSelectOrgSU = (event) => {
    console.log(event.target.value);
    if (otpId) {
      setOtpId(null);
    }

    if (otpValidated) {
      setOtpValidated(false);
      // setNewPassword(null);
      // setConfirmPassword(null);
      setOtp(null);
      setOtpId(null);
    }
    setSelectOrgSU(event.target.value);
  };

  const sendOTPSU = async () => {
    const selectedOrgSU = orgListSU.find((obj) => obj.orgId === selectOrgSU);
    setSendingOtp(true);
    const resOtp = await LoginSignupService.generateOtpForPasswordResetNew(
      userLoginIdSU.toLowerCase(),
      selectedOrgSU.subdomain
    );

    console.log(resOtp.data);
    if (resOtp.data.returnValue === "1") {
      setOtpId(resOtp.data.message);
      toast.info(t("otp_sent_successfully"));
    } else {
      toast.error(t("something_went_wrong"));
    }
    setSendingOtp(false);
  };

  const validateOTPSU = async () => {
    if (!otpSU || otpSU.trim() === "") {
      toast.error(t("please_enter_otp"));
      return false;
    }
    setValidatingOtpSU(true);
    const resOTP = await LoginSignupService.validateOtp(otpIdSU, otpSU);
    if (resOTP.data.returnValue === "-1") {
      toast.error(resOTP.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setValidatingOtpSU(false);
    } else if (resOTP.data.returnValue === "0") {
      toast.error(resOTP.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setValidatingOtpSU(false);
    } else {
      toast.info(t("otp_verified_successfully"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setValidatingOtpSU(false);
      setOtpValidatedSU(true);
    }
  };

  const changePasswordSU = async () => {
    const selectedOrgSU = orgListSU.find((obj) => obj.orgId === selectOrgSU);
    let reqDto = {
      userPassword: newPassword,
      userEmailId: userLoginIdSU.toLowerCase(),
      subdomain: selectedOrgSU.subdomain ? selectedOrgSU.subdomain : "",
    };
    const resChangePass = await LoginSignupService.changePassword(reqDto);

    if (resChangePass.data.returnValue === "0") {
      toast.error(resChangePass.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      // setLoading(false);
    } else {
      toast.info(resChangePass.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setOtpValidated(false);
      // setNewPassword(null);
      // setConfirmPassword(null);
      setOtp(null);
      setOtpId(null);
      setShowForgotPassForm(false);
      setUserLoginIdFP(null);
      setOrgListFP([]);
      setShowLoginForm(true);
    }
  };

  const signupUser = async () => {
    let reqDto = {
      userName:
        userName && userName !== "" && userName.replace(/\s+/g, " ").trim(),
      userEmailId: userLoginIdSU.toLowerCase(),
      userPassword: newPassword,
      userType: "ADMIN",
      loginMode: "WEB",
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      // referralLinkId: null != refContactData ? refContactData.inviteLink : null,
    };
    console.log("signupUser(reqDto)", reqDto);
    setIsValid(false);
    const resSignup = await LoginSignupService.signupUser(reqDto);
    if (resSignup.data.loginReturnValue === "0") {
      toast.error(resSignup.data.loginMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.info(resSignup.data.loginMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });

      let reqDto = {
        username: userLoginIdSU.toLowerCase() + "~" + PERSONAL_ORG_ID,
        password: newPassword,
      };

      const resLogin = await LoginSignupService.userLogin(reqDto);
      localStorage.clear();
      SecureIndexedDB.setItem("token", resLogin.data.token);
      const tokenData = jwtDecode(resLogin.data.token);
      setUserLoginDetails(tokenData);
      SecureIndexedDB.setItem("userProfileImg", tokenData.userProfileImage);
      if (tokenData.orgId) {
        getActiveOtpServices(tokenData.orgId).then((response) => {
          console.log("responseotpservicedata ===", response.data);
          if (Array.isArray(response.data) && response.data.length > 0) {
            SecureIndexedDB.setItem(
              "otpServices",
              JSON.stringify(response.data)
            );
          }
        });
      }
      navigate(`/dashboard`, {
        state: {
          // userName: resLogin.data.userName,
          // userId: resLogin.data.userId,
          // referralLinkId:
          //   null != refContactData ? refContactData.inviteLink : null,
        },
      });
    }
  };

  //
  //

  return (
    <>
      <div className="logRegFullContainer">
        <div className="closelrCon">
          <IconButton onClick={handelCloseLogRegContain}>
            <XLg />
          </IconButton>
        </div>
        <div className="logRegContain">
          <div className="lrContainer">
            <div className="lrLeftContain">
              <div className="statLrImg">
                <img src={lrLogin} alt="statLrImg" />
              </div>
            </div>
            <div className="lrRightContain">
              {showLoginForm && (
                <div className="logRegForm">
                  <div className="lrHead">{t("login")}</div>

                  <div className="fromGroup ">
                    <div className="formElement">
                      <FormControl className="formControl">
                        <InputLabel htmlFor="userEmailId">
                          {t("email_id")}
                        </InputLabel>
                        <OutlinedInput
                          ref={loginIdRef}
                          id="userEmailId"
                          className="formTextFieldArea areaWbtn"
                          type="text"
                          autoComplete="new-password"
                          disabled={orgList.length > 0}
                          endAdornment={
                            <InputAdornment position="end">
                              {fetchingOrgList ? (
                                <>
                                  <CircularProgress className="loading-bar-fetch-org" />
                                </>
                              ) : orgList.length > 0 ? (
                                <IconButton
                                  edge="end"
                                  onClick={() => {
                                    setUserLoginId("");
                                    setOrgList([]);
                                  }}
                                >
                                  <CancelOutlined />
                                </IconButton>
                              ) : (
                                <IconButton
                                  edge="end"
                                  onClick={getAllOrgsAssociatedWithUser}
                                >
                                  <ArrowForward />
                                </IconButton>
                              )}
                            </InputAdornment>
                          }
                          label={t("email_id")}
                          value={userLoginId}
                          onChange={(event) => {
                            setUserLoginId(event.target.value);
                          }}
                        />
                      </FormControl>
                    </div>

                    {orgList && orgList.length > 0 && (
                      <>
                        {" "}
                        <div className="formElement">
                          <FormControl className="formControl">
                            <InputLabel id="selectOrg">
                              {t("select_organization")}
                            </InputLabel>
                            <Select
                              className="formInputField"
                              variant="outlined"
                              labelId="selectOrg"
                              id="organization-select"
                              value={selectOrg}
                              label={t("select_organization")}
                              onChange={handleChangeSelectOrg}
                              MenuProps={SELECT_MenuProps}
                            >
                              {orgList.map((orgDto) => {
                                return (
                                  <MenuItem
                                    value={orgDto.orgId}
                                    key={orgDto.orgId}
                                  >
                                    {orgDto.orgName}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="formElement">
                          <FormControl className="formControl">
                            <InputLabel htmlFor="outlined-adornment-password">
                              {t("password")}
                            </InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-password"
                              className="formTextFieldArea areaPassword"
                              type={showPassword ? "text" : "password"}
                              value={userPassword}
                              onChange={(event) =>
                                setUserPassword(event.target.value)
                              }
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPasswordLogin}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                              label={t("password")}
                            />
                          </FormControl>
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    className="fogetPass actionText"
                    onClick={handelOpenForgotPassword}
                  >
                    {t("forgot_password")}
                  </div>
                  {orgList &&
                    orgList.length > 0 &&
                    (checkingCreds ? (
                      <Button
                        variant="contained"
                        className="lrButton"
                        onClick={loginPersonalUser}
                      >
                        <CircularProgress className="loading-bar-check-creds" />
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        className="lrButton"
                        onClick={loginPersonalUser}
                      >
                        {t("login")}
                      </Button>
                    ))}
                  <div className="LogRegisterUser">
                    {t("do_not_have_an_account")}{" "}
                    <span
                      className="actionText"
                      // onClick={handelOpenRegistration}
                      onClick={handelOpenAcceptModal}
                    >
                      {t("sign_up_now")}
                    </span>
                  </div>
                </div>
              )}

              {showRegistrationForm && (
                <div className="logRegForm">
                  <div className="lrHead">{t("registration")}</div>

                  <div className="fromGroup ">
                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          disabled={otpIdSU != null}
                          label={t("full_name")}
                          required
                          variant="outlined"
                          className="formTextFieldArea"
                          onChange={(event) => setUserName(event.target.value)}
                        />
                      </FormControl>
                    </div>

                    <div className="formElement">
                      <FormControl className="formControl">
                        <InputLabel htmlFor="userEmailId">
                          {t("email_id")}
                        </InputLabel>
                        <OutlinedInput
                          id="userEmailIdSU"
                          className="formTextFieldArea areaWbtn"
                          type="text"
                          disabled={otpIdSU != null}
                          endAdornment={
                            <InputAdornment position="end">
                              {fetchingOrgListSU ? (
                                <>
                                  <CircularProgress className="loading-bar-fetch-org" />
                                </>
                              ) : otpIdSU ? (
                                <IconButton
                                  edge="end"
                                  onClick={() => {
                                    // setUserLoginIdSU("");
                                    // setOrgListSU([]);
                                    setNewPassword("");
                                    setConfirmPassword("");
                                    setOtpIdSU(null);
                                    setOtpValidatedSU(false);
                                    setOtpSU(null);
                                  }}
                                >
                                  <CancelOutlined />
                                </IconButton>
                              ) : (
                                <IconButton
                                  edge="end"
                                  onClick={getAllOrgsAssociatedWithUserSU}
                                >
                                  <ArrowForward />
                                </IconButton>
                              )}
                            </InputAdornment>
                          }
                          label={t("email_id")}
                          value={userLoginIdSU}
                          onChange={(event) =>
                            setUserLoginIdSU(event.target.value)
                          }
                        />
                      </FormControl>
                    </div>

                    {otpIdSU && (
                      <>
                        {!otpValidatedSU && !otpValidatedForResend && (
                          <div className="formElement">
                            <FormControl className="formControl">
                              <InputLabel htmlFor="userEmailId">
                                {t("validate_otp")}
                              </InputLabel>
                              <OutlinedInput
                                id="userEmailId"
                                className="formTextFieldArea areaWbtn padR0"
                                type="text"
                                // endAdornment={
                                //   <InputAdornment position="end">
                                //     <Button
                                //       endIcon={<CheckCircleOutline />}
                                //       edge="end"
                                //       className="otpBtn"
                                //       onClick={validateOTPSU}
                                //     >
                                //       Validate
                                //     </Button>
                                //   </InputAdornment>
                                // }
                                endAdornment={
                                  timeLeft > 0 ? (
                                    <InputAdornment position="end">
                                      <Button
                                        endIcon={<CheckCircleOutline />}
                                        edge="end"
                                        className="otpBtn"
                                        onClick={validateOTPSU}
                                      >
                                        {t("validate")}
                                      </Button>
                                    </InputAdornment>
                                  ) : null
                                }
                                label={t("validate_otp")}
                                onChange={(event) =>
                                  setOtpSU(event.target.value)
                                }
                              />
                            </FormControl>
                          </div>
                        )}

                        {!otpValidatedSU && (
                          <>
                            {!showResendButton ? (
                              <div className="formElement">
                                <div className="otpTimer">{`Time left: ${timeLeft}s`}</div>
                              </div>
                            ) : (
                              <div className="formElement">
                                <Button
                                  variant="contained"
                                  onClick={() =>
                                    getAllOrgsAssociatedWithUserSUResendOTP()
                                  }
                                  disabled={fetchingOrgListSUResendOTP}
                                >
                                  {fetchingOrgListSUResendOTP ? (
                                    <CircularProgress
                                      size={24}
                                      color="inherit"
                                    />
                                  ) : (
                                    <>{t("resend_otp")}</>
                                  )}
                                </Button>
                              </div>
                            )}
                          </>
                        )}

                        {/* {!otpValidatedSU && (
{!showResendButton ? (
   <div className="formElement">
                            <div className="otpTimer">{`Time left: ${timeLeft}s`}</div>
                            </div>
                            ) : (
                              <div className="formElement">   
                          <Button variant="contained" onClick={()=>getAllOrgsAssociatedWithUserSUResendOTP()}>
                         {fetchingOrgListSUResendOTP ? (
                  <CircularProgress size={24} color="inherit"  disabled={fetchingOrgListSUResendOTP}/>
                ) : (
                  <>Resend OTP</>
                )}
                       </Button>
                       </div>
                        )}
                        )} */}

                        {otpValidatedSU && (
                          <>
                            <div className="formElement">
                              <FormControl className="formControl">
                                <InputLabel htmlFor="outlined-adornment-new-password">
                                  {t("new_password")}
                                </InputLabel>
                                <OutlinedInput
                                  id="outlined-adornment-new-password"
                                  className="formTextFieldArea areaPassword"
                                  type={showNewPassword ? "text" : "password"}
                                  value={newPassword}
                                  onChange={handleChangeNewPassword}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowNewPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                      >
                                        {showNewPassword ? (
                                          <VisibilityOff />
                                        ) : (
                                          <Visibility />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                  label={t("new_password")}
                                />
                              </FormControl>
                            </div>

                            <div className="formElement">
                              <FormControl className="formControl">
                                <InputLabel htmlFor="outlined-adornment-confirm-password">
                                  {t("confirm_password")}
                                </InputLabel>
                                <OutlinedInput
                                  id="outlined-adornment-confirm-password"
                                  className="formTextFieldArea areaPassword"
                                  type={
                                    showConfirmPassword ? "text" : "password"
                                  }
                                  value={confirmPassword}
                                  onChange={handleChangeConfirmPassword}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowConfirmPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                      >
                                        {showConfirmPassword ? (
                                          <VisibilityOff />
                                        ) : (
                                          <Visibility />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                  label={t("confirm_password")}
                                />
                              </FormControl>
                            </div>

                            <div className="passwordPolocy">
                              <div className="meterWithText">
                                <div className="meter" id="meter">
                                  <div
                                    className="meter-bar"
                                    id="meter-bar"
                                  ></div>
                                </div>
                                <div className="meter-text" id="meter-text">
                                  <span className="meterHead">
                                    {t("password_strength")}:{" "}
                                  </span>
                                  <span
                                    className="meter-status"
                                    id="meter-status"
                                  >
                                    {t("too_short")}
                                  </span>
                                </div>
                              </div>

                              <div className="passwrdPlcyCheckGrp">
                                <div className="passwrdPlcyCheckHead">
                                  {t("password_should_contain")}
                                </div>

                                <div className="passwrdPlcyCheck">
                                  <span id="one-upper-case-check">
                                    {newPassword.match(/[A-Z]/) <= 1 ? (
                                      <Cancel className="cancelIcon" />
                                    ) : (
                                      <CheckCircle className="checkIcon" />
                                    )}
                                  </span>
                                  <span>{t("one_upper_case_letter")}</span>
                                </div>
                                <div className="passwrdPlcyCheck">
                                  <span id="one-lower-case-check">
                                    {newPassword.match(/[a-z]/) <= 1 ? (
                                      <Cancel className="cancelIcon" />
                                    ) : (
                                      <CheckCircle className="checkIcon" />
                                    )}
                                  </span>
                                  <span>{t("one_lower_case_letter")}</span>
                                </div>
                                <div className="passwrdPlcyCheck">
                                  <span id="one-number-check">
                                    {newPassword.match(/[0-9]/) < 1 ? (
                                      <Cancel className="cancelIcon" />
                                    ) : (
                                      <CheckCircle className="checkIcon" />
                                    )}
                                  </span>
                                  <span>{t("one_number")}</span>
                                </div>
                                <div className="passwrdPlcyCheck">
                                  <span id="one-specialChar-check">
                                    {/[!@#$%^&*(),.?":{}|~<>]/.test(
                                      newPassword
                                    ) < 1 ? (
                                      <Cancel className="cancelIcon" />
                                    ) : (
                                      <CheckCircle className="checkIcon" />
                                    )}
                                  </span>
                                  <span>{t("special_character")}</span>
                                </div>
                                <div className="passwrdPlcyCheck">
                                  <span id="min-length-check">
                                    {newPassword.length < 8 ? (
                                      <Cancel className="cancelIcon" />
                                    ) : (
                                      <CheckCircle className="checkIcon" />
                                    )}
                                  </span>
                                  <span>{t("min_8_characters")}</span>
                                </div>
                                <div className="passwrdPlcyCheck">
                                  <span id="pass-match-check">
                                    {newPassword === "" ||
                                    newPassword !== confirmPassword ? (
                                      <Cancel className="cancelIcon" />
                                    ) : (
                                      <CheckCircle className="checkIcon" />
                                    )}
                                  </span>
                                  <span>{t("passwords_mismatch")}</span>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>

                  {otpValidatedSU && (
                    <Button
                      variant="contained"
                      className="lrButton"
                      disabled={!isValid}
                      onClick={signupUser}
                      // title={isValid ? "" : "Please enter your password"}
                    >
                      {t("register")}
                    </Button>
                  )}
                  <div className="LogRegisterUser">
                    {t("already_have_an_account")}{" "}
                    <span className="actionText" onClick={handelOpenLogin}>
                      {t("sign_in_now")}
                    </span>
                  </div>
                </div>
              )}
  
              {showAgreementModal && (
                <AgreementModal
                  handelOpenRegistration={() => {
                    setHasAgreed(true);
                    handelOpenRegistration();
                  }}
                  closeAcceptModal={() => {
                    setShowAgreementModal(false);
                  }}
                />
              )}

              {showForgotPassForm && (
                <div className="logRegForm">
                  <div className="lrHead">{t("forgot_password")}</div>

                  <div className="fromGroup ">
                    <div className="formElement">
                      <FormControl className="formControl">
                        <InputLabel htmlFor="userEmailId">
                          {t("email_id")}
                        </InputLabel>
                        <OutlinedInput
                          id="userEmailIdFP"
                          className="formTextFieldArea areaWbtn"
                          type="text"
                          disabled={orgListFP.length > 0}
                          endAdornment={
                            <InputAdornment position="end">
                              {fetchingOrgListFP ? (
                                <>
                                  <CircularProgress className="loading-bar-fetch-org" />
                                </>
                              ) : orgListFP.length > 0 ? (
                                <IconButton
                                  edge="end"
                                  onClick={() => {
                                    setUserLoginIdFP("");
                                    setOrgListFP([]);
                                    setOtpId(null);
                                    setOtpValidated(false);
                                    // setNewPassword(null);
                                    // setConfirmPassword(null);
                                    setOtp(null);
                                  }}
                                >
                                  <CancelOutlined />
                                </IconButton>
                              ) : (
                                <IconButton
                                  edge="end"
                                  onClick={getAllOrgsAssociatedWithUserFP}
                                >
                                  <ArrowForward />
                                </IconButton>
                              )}
                            </InputAdornment>
                          }
                          label={t("email_id")}
                          value={userLoginId}
                          onChange={(event) =>
                            setUserLoginIdFP(event.target.value)
                          }
                        />
                      </FormControl>
                    </div>

                    <div className="formElement">
                      {orgListFP && orgListFP.length > 0 && (
                        <FormControl className="formControl">
                          <InputLabel id="selectOrg">
                            {t("select_organization")}
                          </InputLabel>
                          <Select
                            // disabled={otpId != null}
                            className="formInputField"
                            variant="outlined"
                            labelId="selectOrg"
                            id="organization-select"
                            value={selectOrgFP}
                            label={t("select_organization")}
                            onChange={handleChangeSelectOrgFP}
                            MenuProps={SELECT_MenuProps}
                          >
                            {orgListFP.map((orgDto) => {
                              return (
                                <MenuItem
                                  value={orgDto.orgId}
                                  key={orgDto.orgId}
                                >
                                  {orgDto.orgName}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      )}
                    </div>

                    {otpId && !otpValidated && (
                      <div className="formElement">
                        <FormControl className="formControl">
                          <InputLabel htmlFor="userEmailId">
                            {t("validate_otp")}
                          </InputLabel>
                          <OutlinedInput
                            id="userEmailId"
                            className="formTextFieldArea areaWbtn padR0"
                            type="text"
                            onChange={(event) => {
                              setOtp(event.target.value);
                            }}
                            endAdornment={
                              <InputAdornment position="end">
                                {validatingOtp ? (
                                  <Button edge="end">
                                    <CircularProgress className="loading-bar-fetch-org" />
                                  </Button>
                                ) : (
                                  <Button
                                    endIcon={<CheckCircleOutline />}
                                    edge="end"
                                    className="otpBtn"
                                    onClick={validateOTP}
                                  >
                                    {t("validate")}
                                  </Button>
                                )}
                              </InputAdornment>
                            }
                            label={t("validate_otp")}
                          />
                        </FormControl>
                      </div>
                    )}

                    {otpValidated && (
                      <>
                        {" "}
                        <div className="formElement">
                          <FormControl className="formControl">
                            <InputLabel htmlFor="outlined-adornment-new-password">
                              {t("new_password")}
                            </InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-new-password"
                              className="formTextFieldArea areaPassword"
                              type={showNewPassword ? "text" : "password"}
                              value={newPassword}
                              onChange={handleChangeNewPassword}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowNewPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showNewPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                              label={t("new_password")}
                            />
                          </FormControl>
                        </div>
                        <div className="formElement">
                          <FormControl className="formControl">
                            <InputLabel htmlFor="outlined-adornment-confirm-password">
                              {t("confirm_password")}
                            </InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-confirm-password"
                              className="formTextFieldArea areaPassword"
                              type={showConfirmPassword ? "text" : "password"}
                              value={confirmPassword}
                              onChange={handleChangeConfirmPassword}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowConfirmPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showConfirmPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                              label={t("confirm_password")}
                            />
                          </FormControl>
                        </div>
                        <div className="passwordPolocy">
                          <div className="meterWithText">
                            <div className="meter" id="meter">
                              <div className="meter-bar" id="meter-bar"></div>
                            </div>
                            <div className="meter-text" id="meter-text">
                              <span className="meterHead">
                                {t("password_strength")}:{" "}
                              </span>
                              <span className="meter-status" id="meter-status">
                                {t("too_short")}
                              </span>
                            </div>
                          </div>

                          <div className="passwrdPlcyCheckGrp">
                            <div className="passwrdPlcyCheckHead">
                              {t("password_should_contain")}
                            </div>

                            <div className="passwrdPlcyCheck">
                              <span id="one-upper-case-check">
                                {newPassword.match(/[A-Z]/) <= 1 ? (
                                  <Cancel className="cancelIcon" />
                                ) : (
                                  <CheckCircle className="checkIcon" />
                                )}
                              </span>
                              <span>{t("one_upper_case_letter")}</span>
                            </div>
                            <div className="passwrdPlcyCheck">
                              <span id="one-lower-case-check">
                                {newPassword.match(/[a-z]/) <= 1 ? (
                                  <Cancel className="cancelIcon" />
                                ) : (
                                  <CheckCircle className="checkIcon" />
                                )}
                              </span>
                              <span>{t("one_lower_case_letter")}</span>
                            </div>
                            <div className="passwrdPlcyCheck">
                              <span id="one-number-check">
                                {newPassword.match(/[0-9]/) < 1 ? (
                                  <Cancel className="cancelIcon" />
                                ) : (
                                  <CheckCircle className="checkIcon" />
                                )}
                              </span>
                              <span>{t("one_number")}</span>
                            </div>
                            <div className="passwrdPlcyCheck">
                              <span id="one-specialChar-check">
                                {/[!@#$%^&*(),.?":{}|~<>]/.test(newPassword) <
                                1 ? (
                                  <Cancel className="cancelIcon" />
                                ) : (
                                  <CheckCircle className="checkIcon" />
                                )}
                              </span>
                              <span>{t("special_character")}</span>
                            </div>
                            <div className="passwrdPlcyCheck">
                              <span id="min-length-check">
                                {newPassword.length < 8 ? (
                                  <Cancel className="cancelIcon" />
                                ) : (
                                  <CheckCircle className="checkIcon" />
                                )}
                              </span>
                              <span>{t("min_8_characters")}</span>
                            </div>
                            <div className="passwrdPlcyCheck">
                              <span id="pass-match-check">
                                {newPassword === "" ||
                                newPassword !== confirmPassword ? (
                                  <Cancel className="cancelIcon" />
                                ) : (
                                  <CheckCircle className="checkIcon" />
                                )}
                              </span>
                              <span>{t("passwords_mismatch")}</span>
                            </div>
                          </div>
                        </div>{" "}
                      </>
                    )}
                  </div>

                  {otpValidated && (
                    <Button
                      variant="contained"
                      className="lrButton"
                      disabled={!isValid}
                      onClick={changePassword}
                    >
                      {t("change_password")}
                    </Button>
                  )}
                  {orgListFP &&
                    orgListFP.length > 0 &&
                    (sendingOtp ? (
                      <Button variant="contained" className="lrButton">
                        <CircularProgress className="loading-bar-check-creds" />
                      </Button>
                    ) : otpId ? (
                      <></>
                    ) : (
                      <Button
                        variant="contained"
                        className="lrButton"
                        onClick={sendOTP}
                      >
                        {t("send_otp")}
                      </Button>
                    ))}
                  <div className="LogRegisterUser">
                    {t("already_have_an_account")}{" "}
                    <span className="actionText" onClick={handelOpenLogin}>
                      {t("sign_in_now")}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
