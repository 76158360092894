import React, { useEffect, useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import {
  ArrowBackIosNewRounded,
  ArrowLeft,
  Close,
  InfoOutlined,
} from "@mui/icons-material";
import {
  getDateInDDMMYYAMPM,
  GetLoginUserDetails,
} from "../../../../utils/Utils";
import { useTranslation } from "react-i18next";

export default function ConversationHeader({
  selectedContact,
  setSelectedContact,
  groupChatUserList,
  showGroupInfoIcon,
  setShowGroupInfo,
  online,
  closeFromDashboard,
}) {
  const loginUser = GetLoginUserDetails();
  const { t } = useTranslation();
  const [subHeader, setSubHeader] = useState("");

  // render group members or user last seen start
  useEffect(() => {
    if (selectedContact != null) {
      if (selectedContact.chatType === "G") {
        let nameString = "";
        for (let index = 0; index < groupChatUserList.length; index++) {
          const { userId, userName, activeFlag } = groupChatUserList[index];
          if (activeFlag === "Y" && userId !== loginUser.userId) {
            nameString = `${nameString}${userName}, `;
          }
        }
        nameString = `${nameString.substring(0, nameString.length - 2)}`;
        if (
          groupChatUserList.filter(
            ({ userId, activeFlag }) =>
              userId === loginUser.userId && activeFlag === "Y"
          ).length > 0
        ) {
          nameString = `${nameString} and Me`;
        }
        setSubHeader(nameString);
      } else {
        if (online) {
          setSubHeader("Online");
        } else {
          setSubHeader(
            selectedContact?.lastSeenTime != null &&
              selectedContact?.lastSeenTime != ""
              ? getDateInDDMMYYAMPM(selectedContact?.lastSeenTime)
              : ""
          );
        }
      }
    }
  }, [selectedContact, groupChatUserList]);
  // render group members or user last seen end

  return (
    <div className="chatConvoHeader">
      <div className="chatConvoUserInfo">
        <div className="mobChatBack">
          <IconButton onClick={() => setSelectedContact(null)}>
            <ArrowBackIosNewRounded />
          </IconButton>
        </div>
        <div className="chatConvoUsrImg">
          <img src={selectedContact?.chatProfileUrl} alt={t("profile_photo")} />
        </div>
        <div className="chatConvoUsrDtl">
          <div className="ctCnvUNm">{selectedContact?.chatName}</div>
          <div className="ctCnvUDeg">{subHeader}</div>
        </div>
      </div>

      <div className="chatConvoUserActIcon">
        {selectedContact?.chatType === "G" && showGroupInfoIcon && (
          <>
            <Tooltip title={t("group_info")}>
              <IconButton aria-label="more" onClick={setShowGroupInfo}>
                <InfoOutlined />
              </IconButton>
            </Tooltip>
          </>
        )}

        <IconButton
          className="closeBtnForDashboard"
          aria-label="Close Header Selected"
          onClick={closeFromDashboard}
        >
          <Close />
        </IconButton>
      </div>
    </div>
  );
}
