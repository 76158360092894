import { Close } from "@mui/icons-material";
import { Button, IconButton, Tooltip } from "@mui/material";
import React, { useRef, useState } from "react";
import { Eye } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { apiEndPoint, collaboraDomain } from "../../../constants/url_provider";
import DashboardServices from "../../../services/DashboardServices";
import { getLoginUserDetailsLocal } from "../../../utils/Utils";
import { CustomModal } from "../../task/view/component/Modals/CustomModal";

export const PolicyModal = ({ closeModal, policyData }) => {
  const { t } = useTranslation();

  let userDetails = getLoginUserDetailsLocal();
  const numberofPolicy = useRef(0);
  const tAcCheckboxRef = useRef();
  const [isPolicyAcordionOpenArray, setIsPolicyAcordionOpenArray] = useState(
    []
  );
  const [showDocument, setShowDocument] = useState(false);
  const [fileName, setFileName] = useState("");
  const [isTACAccepted, setIsTACAccepted] = useState(false);

  const initialize = async () => {
    const onboardingPolicyList = policyData?.filter(
      (item) => item.onboardChecklist === "Y"
    );
    setIsPolicyAcordionOpenArray(
      [...Array(onboardingPolicyList.length)].map((item) => ({
        show: true,
        accepted: false,
      }))
    );
  };

  useState(async () => {
    initialize();
  }, []);

  const handleSubmitPolicy = () => {
    setIsTACAccepted(false);
    const isAllAccepted = isPolicyAcordionOpenArray.reduce(
      (prevVal, currentVal, index) => {
        if (prevVal === false) {
          return false;
        } else {
          if (currentVal.hasSeenDocument === true) {
            return true;
          } else {
            return false;
          }
        }
      },
      true
    );
    console.log("is accepted = ", isAllAccepted);

    if (isAllAccepted) {
      // return;
      policyData.forEach((item) => {
        DashboardServices.logUserPolicyAcceptance(
          item.id,
          userDetails.userId,
          userDetails.orgId
        ).then((response) => {
          console.log("Accepted", response);
          if (response.data.returnValue > 0) {
            numberofPolicy.current += 1;
            if (numberofPolicy.current === policyData.length) {
              toast.success(t("successfully_accepted_policies"), {
                position: toast.POSITION.TOP_RIGHT,
              });
              closeModal();
            }
          } else {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
      });
    } else {
      toast.warning(t("please_view_all_policy_documents"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const renderIFrame = () => {
    let officeUrl = `${collaboraDomain}/browser/abd9205/cool.html?WOPISrc=${apiEndPoint}/wopi/files/${fileName}`;
    console.log(officeUrl);
    return (
      <div style={{ width: "100%" }}>
        <IconButton
          className="closeIfremBtn"
          onClick={() => {
            setShowDocument(false);
            setFileName(null);
          }}
        >
          <Close />
        </IconButton>
        <iframe
          title="collabora"
          style={{
            height: "90vh",
          }}
          name="embed_office"
          src={officeUrl}
          width="100%"
          height="100vh"
        ></iframe>
      </div>
    );
  };
  return (
    <>
      <CustomModal
        modalTitle={t("privacyPolicy")}
        closeModal={() => {
          //   closeModal();
        }}
        showCloseButton={false}
        // className={"width90vwModel"}
      >
        <>
          {isPolicyAcordionOpenArray.length > 0 && (
            <>
              <div class="policy-container">
                <div class="policy-items">
                  <ul class="policy-accordion">
                    {policyData
                      ?.filter((item) => {
                        return item.onboardChecklist === "Y";
                      })
                      ?.map((item, index) => {
                        return (
                          <>
                            <li
                              className={
                                isPolicyAcordionOpenArray[index].hasSeenDocument
                                  ? "activePP"
                                  : ""
                              }
                            >
                              <div className="privecyPolicyInd">
                                <div className="pripolicyname">
                                  {item.policy}
                                </div>
                                <Tooltip arrow title={t("click_to_view")}>
                                  <IconButton
                                    onClick={() => {
                                      setFileName(item.systemFileName);
                                      setShowDocument(true);
                                      setIsPolicyAcordionOpenArray((prev) => {
                                        prev[index].hasSeenDocument = true;
                                        return [...prev];
                                      });
                                    }}
                                  >
                                    <Eye />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </li>
                          </>
                        );
                      })}
                  </ul>
                </div>
              </div>
              <div style={{ margin: "16px 0" }}>
                <input
                  type="checkbox"
                  id={`accept-tAc`}
                  ref={tAcCheckboxRef}
                  checked={isTACAccepted}
                  style={{ marginRight: "10px" }}
                  onChange={(e) => {
                    setIsTACAccepted(e.target.checked);
                  }}
                />
                {/* <label for={`accept-tAc`}> */}
                <span>
                  By acknowledging and accepting all the terms and conditions
                  outlined in the above policies, I understand that any
                  violation may subject me to penalties as determined by the
                  organization's policies and applicable laws.
                </span>
                {/* </label> */}
              </div>
              <div className="policy-submit-container">
                <Button
                  onClick={handleSubmitPolicy}
                  disabled={!isTACAccepted}
                  className={isTACAccepted ? "dfultPrimaryBtn" : ""}
                >
                  Submit And Login
                </Button>
              </div>
            </>
          )}
        </>
      </CustomModal>

      {showDocument && (
        <CustomModal
          modalTitle={t("document")}
          closeModal={() => {
            //   closeModal();
          }}
          showCloseButton={false}
          className={"width90vwModel"}
        >
          {showDocument && (
            <>
              <div style={{ overflow: "auto", width: "100%" }}>
                {renderIFrame()}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    marginRight: "10px",
                    cursor: "pointer",
                    padding: "8px",
                  }}
                  onClick={() => {
                    setShowDocument(false);
                    setFileName(null);
                  }}
                >
                  {t("close")}
                </div>
              </div>
            </>
          )}
        </CustomModal>
      )}
    </>
  );
};
