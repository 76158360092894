import { Add } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import React, { useState } from "react";
import { Trash, XLg } from "react-bootstrap-icons";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";

export default function DriveDragDropFiles({
  droppedFiles,
  handleCancelModal,
  removeFile,
  handleDragAndDropUpload,
}) {
  const { t } = useTranslation();
  console.log("droppedFiles", droppedFiles);

  return (
    <>
      <div className="flotingAreaContainer">
        <div className="flotingAreaClose">
          <div className="facHeader">{t("uploaded_files")}</div>
          <IconButton className="CloseBtn" onClick={() => handleCancelModal()}>
            <XLg />
          </IconButton>
        </div>

        <div className="elementFormContainer">
          <div class="elementListGrp">
            <div class="elementHeding">{t("files")}</div>

            <ul className="elmListInfo">
              {droppedFiles.map((file, index) => (
                <li key={index}>
                  <span className="rolsNm">
                    {index + 1}. {file.name}
                  </span>
                  <IconButton
                    className="rmvBtn"
                    onClick={() => removeFile(index)}
                  >
                    <Trash />
                  </IconButton>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="elementFooter">
          <div className="formBtnElement">
            <Button
              className="dfultPrimaryBtn"
              onClick={() => handleDragAndDropUpload()}
            >
              {t("upload")}
            </Button>

            <Button
              className="dfultDarkSecondaryBtn"
              onClick={() => handleCancelModal()}
            >
              {t("cancel")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
