import { Add, Edit } from "@mui/icons-material";
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React from "react";
import { FilePlus, Trash } from "react-bootstrap-icons";
// import ReactDatePicker from "react-datepicker";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { convertFileToBase64_Split } from "../../../../../utils/FileUtils";
import { useState } from "react";
import {
  getDateInDDMMYYAMPM,
  GetLoginUserDetails,
} from "../../../../../utils/Utils";
import dayjs from "dayjs";
import { useEffect } from "react";
import {
  getRightsToSelect,
  getStatutoryInsurancePaymentsList,
} from "../../../../../services/AdminService";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { enIN, es } from "date-fns/locale";

// Register locales with ReactDatePicker
registerLocale("en", enIN);
registerLocale("es", es);

export const StatutoryAndInsurancePaymentForm = ({
  statutoryList,
  setStatutoryList,
  budgetHeadList,
}) => {
  const loginUserDetail = GetLoginUserDetails();
  const { t, i18n } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [typeList, setTypeList] = useState([]);

  // Determine the locale based on the current language
  const currentLocale = i18n.language === "es" ? "es" : "en";

  const [allTimeFormData, setAllTimeFormData] = useState({
    type: "",
    budgetHead: "",
    category: "",
    dueDate: new Date(),
    amount: 0,
    description: "",
    document: null,
    editMode: true,
  });
  const handleClickAddRow = () => {
    console.log("handle click called");
    const obj = {
      billingPeriod: null,
      dueDate: new Date(),
      GRN: "",
    };
    setStatutoryList((prev) => {
      return [...prev, obj];
    });
  };

  const initializeAllTimeForeData = () => {
    setAllTimeFormData({
      type: "",
      budgetHead: "",
      category: "",
      dueDate: new Date(),
      amount: 0,
      description: "",
      document: null,
      editMode: true,
    });
  };

  const handleClickRemoveRow = (index) => {
    if (statutoryList.length === 0) {
      setStatutoryList([]);
      return;
    }
    let temp = [...statutoryList];
    temp.splice(index, 1);
    setStatutoryList(temp);
  };

  const getFileDataObj = async (file) => {
    const dto = {
      fileName: file.name,
      fileDataLink: await convertFileToBase64_Split(file),
    };
    return dto;
  };

  const handleAddDataToList = (data) => {
    let isValid = true;
    if (data.type === "") {
      toast.error(t("please_select_type"));
      isValid = false;
    }
    if (data.amount === 0) {
      toast.error(t("amount_cannot_be_zero"));
      isValid = false;
    }
    if (data.budgetHead === "") {
      toast.error(t("please_select_budget_head"));
      isValid = false;
    }

    if (data.description === "") {
      toast.error(t("please_provide_description"));
      isValid = false;
    }
    if (!isValid) {
      return isValid;
    }
    setStatutoryList((prev) => [...prev, data]);
    return isValid;
  };

  useEffect(() => {
    getStatutoryInsurancePaymentsList(loginUserDetail.orgId).then(
      (response) => {
        if (response.data) {
          setTypeList(response.data);
        }
      }
    );
  }, []);

  return (
    <>
      <div className="indentFormtable">
        <div className="indtableResponsive">
          <table>
            <thead>
              <tr>
                <th className="indntQunt">{t("types")}</th>
                <th className="indntQunt">{t("budgetHead")}</th>
                <th className="widthMin120">{t("dueDate")}</th>
                <th className="indntQunt">{t("amount")}</th>
                <th className="indntQunt">{t("description")}</th>
                <th className="indntQunt">{t("document")}</th>
                <th className="mw80"></th>
              </tr>
            </thead>
            <tbody>
              {statutoryList.length > 0 &&
                statutoryList.map((item, index) => {
                  if (item.editMode) {
                    return (
                      <tr>
                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <InputLabel id="selectItems">
                                {t("types")}
                              </InputLabel>
                              <Select
                                className="formInputField"
                                variant="outlined"
                                labelId="selectItems"
                                id="select-Items"
                                value={statutoryList[index].type}
                                label={t("types")}
                                onChange={(e) => {
                                  const temp = [...statutoryList];
                                  const data = e.target.value;
                                  let dateParts =
                                    data.dueRenewalDate.split("-");
                                  let dayObject = new Date(
                                    dateParts[2],
                                    dateParts[1] - 1,
                                    dateParts[0]
                                  );
                                  temp[index].dueDate = dayObject;
                                  temp[index].type = data;
                                  setStatutoryList(temp);
                                }}
                              >
                                {typeList?.map((type) => {
                                  return (
                                    <MenuItem value={type}>
                                      {type.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </div>
                        </td>

                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <InputLabel id="selectLedgerHead">
                                {t("budgetHead")}
                              </InputLabel>
                              <Select
                                className="formInputField"
                                variant="outlined"
                                labelId="selectLedgerHead"
                                id="select-LedgerHead"
                                label={t("budgetHead")}
                                value={statutoryList[index].budgetHead}
                                onChange={(e) => {
                                  const temp = [...statutoryList];
                                  temp[index].budgetHead = e.target.value;
                                  setStatutoryList(temp);
                                }}
                              >
                                {budgetHeadList.length > 0 &&
                                  budgetHeadList.map((itemName, index) => (
                                    <MenuItem
                                      value={itemName}
                                      key={itemName.head + "_" + index}
                                    >
                                      {itemName.head}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </div>
                        </td>

                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <InputLabel
                                id="setRepeatUntilDate"
                                className="setCompletionDate"
                              >
                                {t("dueDate")}
                              </InputLabel>
                              <ReactDatePicker
                                locale={currentLocale}
                                showIcon
                                labelId="setRepeatUntilDate"
                                className="formDatePicker"
                                showYearDropdown
                                yearDropdownItemNumber={100}
                                scrollableYearDropdown
                                showMonthDropdown
                                minDate={new Date()}
                                dateFormat="dd/MMM/yyyy"
                                selected={statutoryList[index].dueDate}
                                onChange={(newDate) => {
                                  const temp = [...statutoryList];
                                  temp[index].dueDate = newDate;
                                  setStatutoryList(temp);
                                }}
                              />
                            </FormControl>
                          </div>
                        </td>
                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                label={t("amount")}
                                variant="outlined"
                                className="formTextFieldArea"
                                fullWidth
                                size="small"
                                autoComplete="off"
                                value={statutoryList[index].amount}
                                onChange={(e) => {
                                  const temp = [...statutoryList];
                                  let value = e.target.value;
                                  if (isNaN(value)) {
                                    return;
                                  }
                                  temp[index].amount = value;
                                  setStatutoryList(temp);
                                }}
                              />
                            </FormControl>
                          </div>
                        </td>
                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                label={t("description")}
                                variant="outlined"
                                className="formTextFieldArea"
                                fullWidth
                                size="small"
                                autoComplete="off"
                                value={statutoryList[index].description}
                                onChange={(e) => {
                                  const temp = [...statutoryList];
                                  temp[index].description = e.target.value;
                                  setStatutoryList(temp);
                                }}
                              />
                            </FormControl>
                          </div>
                        </td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <IconButton
                              aria-label="addFile"
                              onClick={(e) => {
                                console.log("icon button clicked");
                                const inputElement = document.querySelector(
                                  `#file_input_${index}`
                                );
                                if (inputElement) {
                                  inputElement.click();
                                }
                              }}
                            >
                              <FilePlus />
                            </IconButton>
                            <div>
                              {item.document && (
                                <>
                                  <span>{item.document.fileName}</span>
                                  <IconButton
                                    onClick={async () => {
                                      const temp = [...statutoryList];
                                      // const files = e.target.files;
                                      // console.log("files are", files);
                                      // const data = await getFileDataObj(
                                      //   files[0]
                                      // );
                                      temp[index].document = null;
                                      setStatutoryList(temp);
                                    }}
                                  >
                                    <Trash />
                                  </IconButton>
                                </>
                              )}
                            </div>
                            <input
                              style={{ display: "none" }}
                              type="file"
                              id={`file_input_${index}`}
                              onChange={async (e) => {
                                const files = e.target.files;
                                const data = await getFileDataObj(files[0]);
                                const temp = [...statutoryList];
                                temp[index].document = data;
                                setStatutoryList(temp);
                              }}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="iconBtnGroup">
                            {item.editMode === true && (
                              <Button
                                className="saveBtn"
                                onClick={(e) => {
                                  const temp = [...statutoryList];
                                  temp[index].editMode = false;
                                  setStatutoryList(temp);
                                  setIsEditing(false);
                                }}
                              >
                                {t("save")}
                              </Button>
                            )}
                            <IconButton
                              onClick={() => {
                                handleClickRemoveRow(index);
                              }}
                            >
                              <Trash />
                            </IconButton>
                          </div>
                        </td>
                      </tr>
                    );
                  } else {
                    return (
                      <tr>
                        <td>
                          <div className="textOnly">{item.type.name}</div>
                        </td>
                        <td>
                          <div className="textOnly">{item.budgetHead.head}</div>
                        </td>
                        <td>
                          <div className="textOnly">
                            {dayjs(item.dueDate).format("DD-MM-YYYY")}
                          </div>
                        </td>

                        <td>
                          <div className="textOnly">{item.amount}</div>
                        </td>
                        <td>
                          <div className="textOnly">{item.description}</div>
                        </td>
                        <td>
                          <div className="textOnly">
                            {item.document && item.document.fileName}
                          </div>
                        </td>
                        <td>
                          {item.editMode === false && (
                            <Tooltip title={t("edit_item")} placement="left">
                              <IconButton
                                className={
                                  item.editMode === false &&
                                  isEditing &&
                                  `deactivatedEditButton`
                                }
                                disabled={item.editMode === false && isEditing}
                                onClick={() => {
                                  setIsEditing(true);
                                  const temp = [...statutoryList];
                                  temp[index].editMode = true;
                                  setStatutoryList([...temp]);
                                }}
                              >
                                <Edit />
                              </IconButton>
                            </Tooltip>
                          )}
                          <IconButton
                            onClick={() => {
                              if (item.editMode === true) {
                                setIsEditing(false);
                              }
                              handleClickRemoveRow(item);
                            }}
                          >
                            <Trash />
                          </IconButton>
                        </td>
                      </tr>
                    );
                  }
                })}

              <tr>
                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel id="selectItems">{t("types")}</InputLabel>
                      <Select
                        className="formInputField"
                        variant="outlined"
                        labelId="selectItems"
                        id="select-Items"
                        value={allTimeFormData.type}
                        label={t("types")}
                        onChange={(e) => {
                          const temp = { ...allTimeFormData };
                          const data = e.target.value;

                          let dayObject = "";
                          if (
                            data.dueRenewalDate &&
                            typeof data.dueRenewalDate === "string"
                          ) {
                            const dateParts = data.dueRenewalDate.split("-");
                            dayObject = new Date(
                              dateParts[2],
                              dateParts[1] - 1,
                              dateParts[0]
                            );
                          }
                          temp.dueDate = dayObject;

                          temp.type = data;
                          setAllTimeFormData(temp);
                        }}
                      >
                        {typeList &&
                          typeList.map((type) => {
                            return (
                              <MenuItem value={type}>{type.name}</MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </div>
                </td>

                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel id="selectLedgerHead">
                        {t("budgetHead")}
                      </InputLabel>
                      <Select
                        className="formInputField"
                        variant="outlined"
                        labelId="selectLedgerHead"
                        id="select-LedgerHead"
                        label={t("budgetHead")}
                        value={allTimeFormData.budgetHead}
                        onChange={(e) => {
                          const temp = { ...allTimeFormData };
                          temp.budgetHead = e.target.value;
                          setAllTimeFormData(temp);
                        }}
                      >
                        {budgetHeadList.length > 0 &&
                          budgetHeadList.map((itemName, index) => (
                            <MenuItem
                              value={itemName}
                              key={itemName.head + "_" + index}
                            >
                              {itemName.head}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                </td>

                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel
                        id="setRepeatUntilDate"
                        className="setCompletionDate"
                      >
                        {t("dueDate")}
                      </InputLabel>
                      {console.log("all Time Form Data =", allTimeFormData)}
                      <ReactDatePicker
                        locale={currentLocale}
                        showIcon
                        labelId="setRepeatUntilDate"
                        className="formDatePicker"
                        minDate={new Date()}
                        dateFormat="dd/MMM/yyyy"
                        showYearDropdown
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown
                        showMonthDropdown
                        selected={allTimeFormData.dueDate}
                        onChange={(newDate) => {
                          const temp = { ...allTimeFormData };
                          temp.dueDate = newDate;
                          setAllTimeFormData(temp);
                        }}
                      />
                    </FormControl>
                  </div>
                </td>
                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        label={t("amount")}
                        variant="outlined"
                        className="formTextFieldArea"
                        fullWidth
                        size="small"
                        autoComplete="off"
                        value={allTimeFormData.amount}
                        onChange={(e) => {
                          const temp = { ...allTimeFormData };
                          let value = e.target.value;
                          if (isNaN(value)) {
                            return;
                          }
                          temp.amount = value;
                          setAllTimeFormData(temp);
                        }}
                      />
                    </FormControl>
                  </div>
                </td>
                <td>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        label={t("description")}
                        variant="outlined"
                        className="formTextFieldArea"
                        fullWidth
                        size="small"
                        autoComplete="off"
                        value={allTimeFormData.description}
                        onChange={(e) => {
                          const temp = { ...allTimeFormData };
                          temp.description = e.target.value;
                          setAllTimeFormData(temp);
                        }}
                      />
                    </FormControl>
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <IconButton
                      aria-label="addFile"
                      onClick={(e) => {
                        console.log("icon button clicked");
                        const inputElement =
                          document.querySelector(`#file_input_`);
                        if (inputElement) {
                          inputElement.click();
                        }
                      }}
                    >
                      <FilePlus />
                    </IconButton>
                    {allTimeFormData.document && (
                      <div>
                        <span>{allTimeFormData.document.fileName}</span>
                        <IconButton
                          onClick={() => {
                            const temp = { ...allTimeFormData };
                            temp.document = null;
                            setAllTimeFormData(temp);
                          }}
                        >
                          <Trash />
                        </IconButton>
                      </div>
                    )}
                    <input
                      style={{ display: "none" }}
                      type="file"
                      id={`file_input_`}
                      onChange={async (e) => {
                        const files = e.target.files;
                        console.log("files are", files);
                        const data = await getFileDataObj(files[0]);
                        const temp = { ...allTimeFormData };
                        temp.document = data;
                        setAllTimeFormData(temp);
                        e.target.value = null;
                      }}
                    />
                  </div>
                </td>
                <td>
                  <div className="iconBtnGroup">
                    <Button
                      className="saveBtn"
                      onClick={(e) => {
                        const temp = { ...allTimeFormData, editMode: false };
                        const tempBool = handleAddDataToList(temp);

                        tempBool && initializeAllTimeForeData();
                      }}
                    >
                      {t("save")}
                    </Button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
