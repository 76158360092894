import authHeader from "../config/auth-header-config";
import http from "../config/http-common";

export const getFolders = (userId) => {
  return http.get(`/drive/getFolders/${userId}`, {
    headers: authHeader(),
  });
};

export const getUserStorageDetails = (userId) => {
  return http.get(`/drive/getUserStorageDetails/${userId}`, {
    headers: authHeader(),
  });
};

export const getUploadedFilesOutsideFolder = (userId) => {
  return http.get(`/drive/getUploadedFilesOutsideFolder/${userId}`, {
    headers: authHeader(),
  });
};

export const getUploadedFilesInsideFolder = (folderId) => {
  return http.get(`/drive/getUploadedFilesInsideFolder/${folderId}`, {
    headers: authHeader(),
  });
};

export const createFolder = (folderName, userId) => {
  let authHeaderVal = authHeader().Authorization;
  const config = {
    headers: {
      "Content-type": "text/plain",
      Authorization: authHeaderVal,
    },
  };
  console.log(config);
  return http.post(`drive/createFolder/${userId}`, folderName, config);
};

export const uploadFileToDrive = (formData) => {
  let authHeaderVal = authHeader().Authorization;
  const config = {
    headers: {
      "Content-type": "multipart/form-data",
      Authorization: authHeaderVal,
    },
  };

  return http.post(`/drive/uploadFile`, formData, config);
};

export const deleteFileDrive = (fileId, userId, orgId) => {
  return http.put(`drive/deleteFile/${fileId}/${userId}/${orgId}`, {
    headers: authHeader(),
  });
};

export const downloadFileAWSV2 = (reqDto) => {
  return http.put(`/misc/downloadFileAWSV2Blob`, reqDto, {
    headers: authHeader(),
  });
};

export const checkIfUserHasFileAccess = (fileId, userId) => {
  return http.get(`/drive/checkIfUserHasFileAccess/${fileId}/${userId}`, {
    headers: authHeader(),
  });
};

export const getUsersWithFileAccess = (fileId) => {
  return http.get(`/drive/getUsersWithFileAccess/${fileId}`, {
    headers: authHeader(),
  });
};

export const updateFileAccess = (reqDto, userId) => {
  return http.post(`/drive/updateFileAccess/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

export const updateLastAccessedTime = (fileId, userId, sharedBy) => {
  return http.put(
    `/drive/updateLastAccessedTime/${fileId}/${userId}/${sharedBy}`,
    {
      headers: authHeader(),
    }
  );
};

export const getUploadedFiles = (userId) => {
  return http.get(`/drive/getUploadedFiles/` + userId, {
    headers: authHeader(),
  });
};

export const downloadFileDrive = (fileName, folderName) => {
  return http.get(`/drive/downloadFileDrive/${fileName}/${folderName}`, {
    headers: authHeader(),
  });
};

export const updateUserFilePermission = (permission, fileId, userId) => {
  return http.put(
    `/drive/updateUserFilePermission/${permission}/${fileId}/${userId}`,
    {
      headers: authHeader(),
    }
  );
};
export const getAllDetailsByShareHash = (shareHash) => {
  let authHeaderVal = authHeader().Authorization;
  const config = {
    headers: {
      "Content-type": "text/plain",
      Authorization: authHeaderVal,
    },
  };
  console.log(config);
  return http.post(`drive/getAllDetailsByShareHash`, shareHash, config);
};

export const downloadFileDriveFolder = (fileName, folderName, folderId) => {
  return http.get(
    `/drive/downloadFileDriveFolder/${fileName}/${folderName}/${folderId}`,
    {
      headers: authHeader(),
    }
  );
};
