import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  TextField,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import DashboardServices from "../../../../services/DashboardServices";
import { GetLoginUserDetails } from "../../../../utils/Utils";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const ForwardTask = ({ data, userList, handleClose, refreshData }) => {
  const { t } = useTranslation();
  const [selectedUsers, setSelectedUsers] = useState(null);
  const loginUserDetail = GetLoginUserDetails();
  const [assigneeList, setAssigneeList] = useState([]);
  const [autocompleteInputValue, setAutocompleteInputValue] = useState("");

  useEffect(() => {
    DashboardServices.getAssigneesOfTask(data.taskId).then((response) => {
      setAssigneeList(response.data);
    });
  }, []);

  const getFilteredData = () => {
    const taskCreator = data.assignByUserId;

    const filteredData = userList.filter((item) => {
      const isCreatorOrUser =
        item.userId !== loginUserDetail.userId && item.userId !== taskCreator;

      const isAlreadyAssigned = assigneeList.some((assignee) => {
        return assignee.userId === item.userId;
      });

      return isCreatorOrUser && !isAlreadyAssigned;
    });
    console.log("filtered data=", filteredData);
    return filteredData;
  };

  const filteredUsers = useMemo(getFilteredData, [
    data,
    userList,
    assigneeList,
    loginUserDetail.userId,
  ]);

  const computedList = useMemo(() => {
    let filtered = [];
    if (
      autocompleteInputValue.length > 0 &&
      autocompleteInputValue.trim() !== ""
    ) {
      filtered = filteredUsers.filter((option) =>
        option.userName
          .toLowerCase()
          .includes(autocompleteInputValue.toLowerCase())
      );
    }

    return filtered;
  }, [autocompleteInputValue, userList]);

  const handleChangeAutoCompleteInputValue = (event, newInputValue) => {
    setAutocompleteInputValue(newInputValue);
  };

  return (
    <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
      <div className="addMoreAgendaModel">
        <div className="modelTitle">{t("forward_task")}</div>

        <div className="elementFormContainer">
          {/* <div className="formElement">
            <FormControl className="formControl">
              <Autocomplete
                id="adduser-select"
                disableClearable
                className="modelformAutocompleteField"
                variant="outlined"
                value={selectedUsers}
                options={filteredUsers}
                getOptionLabel={(option) => option.userName}
                getOptionSelected={(option) => {
                  console.log("option is ", option);
                  return option.userId;
                }}
                onChange={(e, newValue) => {
                  console.log("new value = ", newValue);
                  setSelectedUsers(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="formAutoComInputField"
                    placeholder="Assign User*"
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
              />
            </FormControl>
          </div> */}

          <div className="formElement">
            <FormControl className="formControl">
              {/* <InputLabel id="adduser">Add User</InputLabel> */}
              <Autocomplete
                id="adduser-select"
                className="formAutocompleteField"
                variant="outlined"
                noOptionsText={t("please_type_recipient_name")}
                value={selectedUsers}
                options={computedList}
                getOptionLabel={(option) => option.userName}
                inputValue={autocompleteInputValue}
                onChange={(e, newValue) => {
                  console.log("new value = ", newValue);
                  setSelectedUsers(newValue);
                }}
                onInputChange={handleChangeAutoCompleteInputValue}
                getOptionSelected={(option) => {
                  console.log("option is ", option);
                  return option.userId;
                }}
                renderOption={(props, option) => {
                  return (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {`${option.userName} ( ${
                        option.userDtl ? option.userDtl : ""
                      } )`}
                    </Box>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="modelformAutocompleteField"
                    placeholder={t("assign_recipient")}
                    required
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
              />
            </FormControl>
          </div>
        </div>
        <div className="modActionBtnGrp">
          <Button
            className="dfultPrimaryBtn"
            onClick={() => {
              if (
                !selectedUsers ||
                (selectedUsers && selectedUsers.length === 0)
              ) {
                toast.error(t("please_select_atleast_one_user"));
              }
              console.log("forward Task submitting");
              const reqDto = {
                taskDetailId: data.taskId,
                forwardToUserId: selectedUsers.userId,
              };
              console.log("reqdto =", reqDto);
              //   return;
              DashboardServices.forwardApprovedTask(
                loginUserDetail.userId,
                reqDto
              ).then((response) => {
                if (response.data.returnValue === "1") {
                  toast.success(
                    t("the_approved_task_has_been_successfully_forwarded")
                  );
                  handleClose();
                  refreshData();
                } else {
                  toast.error(
                    "failed_to_forward_the_approved_task_please_try_again"
                  );
                }
                console.log(response.data);
              });
            }}
          >
            {t("submit")}
          </Button>
          <Button className="dfultDarkSecondaryBtn" onClick={handleClose}>
            {t("cancel")}
          </Button>
        </div>
      </div>
    </Box>
  );
};

export default ForwardTask;
