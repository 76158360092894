import React, { useMemo } from "react";
import vcicon from "../../images/VideoConference.png";
import storageicon from "../../images/Storage.png";
import ofSuiteicon from "../../images/OfficeSuite.png";
import comingSoonImg from "../../images/coming-soon1.png";
import analitic from "../../images/analitic.png";
import aiIcon from "../../images/aiIcon.png";
import webBilderIoc from "../../images/webbilder.png";
import webinerIoc from "../../images/webiner.png";
import crmIoc from "../../images/crm.png";
import { Button } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function PersonalPlan({
  activeStep,
  professionalData,
  personalData,
  handelClickShowPro,
  handlepaymentModelOpen,
  handelOrgOpenModel,
}) {
  const { t } = useTranslation();
  const [featuresArray, setFeaturesArray] = useState([]);
  const [personalIconArray, setPersonalIconArray] = useState([]);
  const first = useMemo(() => {
    if (featuresArray) {
      const sliced = featuresArray.slice(0, 2);
      console.log("sliced value =", sliced);
      return sliced;
    } else {
      return [];
    }
  }, [featuresArray]);
  const second = useMemo(() => {
    if (featuresArray) {
      const sliced = featuresArray.slice(2, 3);
      console.log("sliced value =", sliced);
      return sliced;
    } else {
      return [];
    }
  }, [featuresArray]);

  // const third = useMemo(() => {
  //   if (featuresArray) {
  //     const sliced = featuresArray.slice(5, 8);
  //     console.log("sliced value =", sliced);
  //     return sliced;
  //   } else {
  //     return [];
  //   }
  // }, [featuresArray]);
  // const third = useMemo();

  useEffect(() => {
    if (personalData) {
      const { otherKeys, featureMap } = personalData;
      const perDataArray = Array.from(featureMap.entries());
      console.log("professional data array =", personalData);
      perDataArray.sort((entry1, entry2) => {
        const featuresLength1 = entry1[1].features.length;
        const featuresLength2 = entry2[1].features.length;

        return featuresLength2 - featuresLength1;
      });
      setFeaturesArray(perDataArray);
      let tempPersonal = Array.from(professionalData.featureMap.entries());
      // const
      tempPersonal = tempPersonal.map((item) => item[1].headerIcon);
      // console.log();
      setPersonalIconArray(tempPersonal);
    }
  }, [personalData]);

  return (
    <>
      <div className="persoanlPlanContainer">
        <div className="personalPlanView">
          <div className="planContainer">
            <div className="planHeader personalHead">
              <div className="planName">{t("personal")}</div>

              <div className="planPrice">{t("free")}</div>
            </div>
            <div className="planDetails">
              {/* <div className="plantext">
                The personal version of Zoyel gives you access to mail office
                suite, storage and more - everything you need to run your daily
                business.
              </div> */}
              <div className="plantext">{t("personal_version")}</div>
              <div className="planServGrid">
                {first &&
                  first.map((item) => {
                    return (
                      <>
                        <div className="gridElement">
                          <div className="gridElHead">
                            <div className="gridElicon">
                              <img src={item[1].headerIcon} alt="" />
                            </div>
                            {item[1].headerName}
                          </div>
                          <div className="girdElListFun">
                            <ul>
                              {item[1].features &&
                                item[1].features.map((feat) => {
                                  return <li>{feat}</li>;
                                })}
                            </ul>
                          </div>
                        </div>
                      </>
                    );
                  })}
                {second &&
                  second.map((item) => {
                    return (
                      <>
                        <div className="gridElement tall">
                          <div className="gridElHead">
                            <div className="gridElicon">
                              <img src={item[1].headerIcon} alt="" />
                            </div>
                            <div className="gridElNm">{item[1].headerName}</div>
                          </div>
                          <div className="girdElListFun">
                            <ul>
                              {item[1].features &&
                                item[1].features.map((feat) => {
                                  return <li>{feat}</li>;
                                })}
                            </ul>
                          </div>
                        </div>
                      </>
                    );
                  })}
                {/* <div className="gridElement">
                  <div className="gridElHead">
                    <div className="gridElicon">
                      <img src={storageicon} alt="" />
                    </div>
                    <div className="gridElNm">Storage</div>
                  </div>
                  <div className="girdElListFun">
                    <ul>
                      <li>1 GB of storage in Zoyel Drive.</li>
                    </ul>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="otherPlanShow">
          <div className="professionalPlanOverView">
            <div className="planContainer">
              <div
                className="planHeader  professionalhead"
                onClick={() => handelClickShowPro()}
              >
                <div className="plNm_Price">
                  <div className="planName">{t("professional")}</div>
                  <div className="planprice">
                    <span>$1</span>
                    {t("per_user_month")}
                  </div>
                </div>

                <div className="upgredBtn">
                  <Button
                    onClick={(e) => {
                      if (activeStep === 1) {
                        handelOrgOpenModel();
                        return;
                      }
                      handlepaymentModelOpen(e);
                    }}
                  >
                    {t("upgrade")}
                  </Button>
                </div>
              </div>
              <div className="planDetailsShow">
                <div className="proGrigInfo">
                  <div className="proIconGridShow">
                    <div className="proIocGrid">
                      <img src={vcicon} alt="" />
                    </div>
                    <div className="proIocGrid">
                      <img src={storageicon} alt="" />
                    </div>
                    <div className="proIocGrid">
                      <img src={ofSuiteicon} alt="" />
                    </div>
                    <div className="proIocGrid noRbord">
                      <img src={analitic} alt="" />
                    </div>
                    <div className="proIocGrid">
                      <img src={aiIcon} alt="" />
                    </div>
                    <div className="proIocGrid">
                      <img src={crmIoc} alt="" />
                    </div>
                    <div className="proIocGrid">
                      <img src={webinerIoc} alt="" />
                    </div>
                    <div className="proIocGrid noRbord">
                      <img src={webBilderIoc} alt="" />
                    </div>
                  </div>
                  {/* <div className="proInfodtl">
                    More Space, More Users, More Features. Upgrade to
                    Professional to get more out of your Zoyel account!
                  </div> */}
                  <div className="proInfodtl">{t("more_space")}</div>
                </div>

                <div className="proInfoBtnGrp">
                  <Button
                    className="proBtnInfo"
                    onClick={() => handelClickShowPro()}
                  >
                    {t("view_details")}
                  </Button>
                  <Button
                    className="proBtnInfo"
                    onClick={(e) => {
                      if (activeStep === 1) {
                        handelOrgOpenModel();
                        return;
                      }
                      handlepaymentModelOpen(e);
                    }}
                  >
                    {t("upgrade_now")}
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className="enterprisePlanOverView">
            <div className="planContainer">
              <div className="planHeader enterprisehead">
                <div className="planName">{t("enterprise")}</div>
              </div>
              <div className="planDetails">
                <div className="comingSoonImg">
                  <img src={comingSoonImg} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
