import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { CircularProgress, IconButton } from "@mui/material";
import React, { useState } from "react";

import { Check } from "iconoir-react";
import { toast } from "react-toastify";
import {
  MIN_CHAR_CHAT_GROUP_NAME,
  MIN_USER_IN_CHAT_GROUP,
} from "../../../constants/const_values";
import { createGroup } from "../../../services/ChatService";
import { GetLoginUserDetails } from "../../../utils/Utils";
import AddGroupUser from "./component/AddGroupUser";
import CreateGroupInfo from "./component/CreateGroupInfo";
import { useTranslation } from "react-i18next";

export default function CreateChatGroupDetails({ onClickBack }) {
  const loginUser = GetLoginUserDetails();
  const { t } = useTranslation();
  const [step, setStep] = useState(1);

  const [selectedUserList, setSelectedUserList] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [groupIcon, setGroupIcon] = useState({ fileName: "", fileData: "" });
  const [loading, setLoading] = useState(false);

  // handle submit create group data start
  const handleSubmitCreateGroup = async () => {
    if (loginUser != null) {
      let userList = [];

      for (let index = 0; index < selectedUserList.length; index++) {
        const { userId } = selectedUserList[index];
        userList.push(userId);
      }

      const reqDto = {
        groupName: groupName,
        users: userList,
        groupFileName: groupIcon.fileName,
        groupFileData: groupIcon.fileData,
      };

      setLoading(true);
      await createGroup(loginUser.userId, loginUser.orgId, reqDto).then(
        (response) => {
          if (response.data != null) {
            if (response.data > 0) {
              toast.success(t("group_created_successfully"), {
                position: toast.POSITION.TOP_RIGHT,
              });
              setLoading(false);
              onClickBack(0);
            } else {
              toast.error(t("failed_to_create_group"), {
                position: toast.POSITION.TOP_RIGHT,
              });
              setLoading(false);
            }
          }
        }
      );
    }
  };
  // handle submit create group data end

  return (
    <>
      <div className="newChatGrpPanel">
        {/* header section create new group start */}
        <div className="chatLftContHead">
          <div
            className="bckIoc"
            role="button"
            onClick={() => (step === 1 ? onClickBack(1) : setStep(1))}
          >
            <ArrowBack />
          </div>
          <div className="nchdText">
            {step === 1 && "Add Participants"}
            {step === 2 && "New Group"}
          </div>
        </div>
        {/* header section create new group end */}

        <div className="chatCnctLstCont">
          {/* step 1 add the users  */}
          {step === 1 && (
            <AddGroupUser
              selectedUserList={selectedUserList}
              setSelectedUserList={setSelectedUserList}
            />
          )}

          {step === 2 && (
            <CreateGroupInfo
              groupName={groupName}
              setGroupName={setGroupName}
              groupIcon={groupIcon}
              setGroupIcon={setGroupIcon}
            />
          )}
        </div>

        <div className="grpCretFooter">
          {step === 1 && selectedUserList.length >= MIN_USER_IN_CHAT_GROUP && (
            <IconButton
              aria-label="Create Group"
              className="cretGrpButton"
              onClick={() => setStep(2)}
            >
              <ArrowForward />
            </IconButton>
          )}

          {step === 2 && groupName.length >= MIN_CHAR_CHAT_GROUP_NAME && (
            <IconButton
              aria-label="Create Group"
              className="cretGrpButton"
              onClick={handleSubmitCreateGroup}
            >
              {loading ? <CircularProgress /> : <Check />}
            </IconButton>
          )}
        </div>
      </div>
    </>
  );
}
