import { React, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import NavBarLP from "./component/NavBarLP";
import LoginRegister from "./component/LoginRegister";
import FooterLP from "./component/FooterLP";
import "../css/kycVerification.css";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
// import ReactDatePicker from "react-datepicker";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Add, CheckCircle, CheckCircleOutline } from "@mui/icons-material";
import { Trash, Upload } from "react-bootstrap-icons";
import { enIN, es } from "date-fns/locale";

// Register locales with ReactDatePicker
registerLocale("en", enIN);
registerLocale("es", es);


export default function KycVerification() {
  const { t , i18n} = useTranslation();
  const [showLogReg, setShowLogReg] = useState(false);
  const [compLegalStructure, setcompLegalStructure] = useState("");
  const [numberOfEmployee, setcompNumberOfEmployee] = useState("");
  const [revenueGenerated, setRevenueGenerated] = useState("");
  const [showSelect, setShowSelect] = useState(false);
  const [sanctionedContry, setSanctionedContry] = useState("");
  const [dateofbirth, setDateofbirth] = useState(new Date());
  const [dateofbirthUBO, setDateofbirthUBO] = useState(new Date());
  const [dateofbirthManager, setDateofbirthManager] = useState(new Date());

  // Determine the locale based on the current language
  const currentLocale = i18n.language === "es" ? "es" : "en";

  const handleRadioChange = (event) => {
    setShowSelect(event.target.value === "yes");
  };

  const handleChangeSanctionedContry = (event) => {
    setSanctionedContry(event.target.value);
  };

  const handleChangeCompLegalStructure = (event) => {
    setcompLegalStructure(event.target.value);
  };
  const handleChangeRevenueGenerated = (event) => {
    setRevenueGenerated(event.target.value);
  };

  const handleChangeNumberOfEmployee = (event) => {
    setcompNumberOfEmployee(event.target.value);
  };

  const handelCloseLogRegContain = () => {
    setShowLogReg(false);
  };

  const handelOpenLogRegContain = () => {
    setShowLogReg(true);
  };
  const scrollableNavRef = useRef(null);

  // start file upload
  const [fileData, setFileData] = useState({});
  const fileInputRefs = useRef({});

  const items = [
    "Trade licenses",
    "MOA / AOA",
    "Emirates ID and passport of the owners and the manager",
    "Company Address",
    "Bank details",
    "Benches trade license",
  ];

  const openFileInput = (item) => {
    fileInputRefs.current[item].click();
  };

  const handleFileChange = (event, item) => {
    const file = event.target.files[0];
    if (file) {
      setFileData((prevData) => ({
        ...prevData,
        [item]: { name: file.name, size: file.size },
      }));
    }
  };

  const formatFileSize = (size) => {
    const units = ["B", "KB", "MB", "GB"];
    let index = 0;
    while (size >= 1024 && index < units.length - 1) {
      size /= 1024;
      index++;
    }
    return `${size.toFixed(2)} ${units[index]}`;
  };

  const removeFile = (item) => {
    setFileData((prevData) => {
      const updatedData = { ...prevData };
      delete updatedData[item];
      return updatedData;
    });
  };
  // end file upload

  // kyc verification agree/consent
  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <>
      <div className="lp-full-container" ref={scrollableNavRef}>
        <NavBarLP
          handelOpenLogRegContain={handelOpenLogRegContain}
          scrollableNavRef={scrollableNavRef}
        />
        {showLogReg && (
          <LoginRegister handelCloseLogRegContain={handelCloseLogRegContain} />
        )}
        <>
          <div className="kycVerfContainer">
            <div className="kycVerfyForm">
              <div className="kycCerfyHeading">KYC Verification</div>
              <div className="questionGroup">
                <div className="tskElemHeding mt10">Type of services </div>
                <div className="formElement">
                  <FormControl className="formControl">
                    <RadioGroup
                      className="formRadioGroup"
                      labelId="setTasktype"
                      name="typeOfServices"
                    >
                      <FormControlLabel
                        className="formRadioField"
                        value="VENDOR"
                        control={<Radio />}
                        label="VENDOR"
                      />
                      <FormControlLabel
                        className="formRadioField"
                        value="CLIENT"
                        control={<Radio />}
                        label="CLIENT"
                      />
                      <FormControlLabel
                        className="formRadioField"
                        value="BUSINESSPARTNER"
                        control={<Radio />}
                        label="BUSINESS PARTNER"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>

              <div className="questionGroup">
                <div className="tskElemHeding mt10">Organization details </div>
                <div className="formElementGroup">
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        label="Organization Name"
                        required
                        variant="outlined"
                        autoComplete="off"
                        className="formTextFieldArea"
                        helperText="As per Trade license"
                      />
                    </FormControl>
                  </div>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        label="Trade license no "
                        required
                        variant="outlined"
                        autoComplete="off"
                        className="formTextFieldArea"
                        helperText="As per Trade license"
                      />
                    </FormControl>
                  </div>
                </div>
              </div>

              <div className="questionGroup">
                <div className="tskElemHeding mt10">
                  What is the company's legal structure, such as an LLC or a
                  Free Zone Company?
                </div>
                <div className="formElement">
                  <FormControl className="formControl">
                    <InputLabel id="compLeglStruc">
                      Select your answer *
                    </InputLabel>
                    <Select
                      labelId="compLeglStruc"
                      id="compLegalStructure"
                      className="formInputField"
                      variant="outlined"
                      value={compLegalStructure}
                      label="Select your answer *"
                      required
                      onChange={handleChangeCompLegalStructure}
                    >
                      <MenuItem value="cc">Civil Company</MenuItem>
                      <MenuItem value="fzc">Free Zone Company </MenuItem>
                      <MenuItem value="llc">
                        Company Limited Liability Company (LLC)
                      </MenuItem>
                      <MenuItem value="osc">Off shore Company </MenuItem>
                      <MenuItem value="PrJSC">
                        Private Joint Stock Company (PrJSC)
                      </MenuItem>
                      <MenuItem value="PJSC">
                        Public Joint Stock Company (PJSC)
                      </MenuItem>
                      <MenuItem value="sp">Sole Proprietorship</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div className="questionGroup">
                <div className="tskElemHeding mt10">
                  What is the size of your business in terms of number of
                  employees?
                </div>
                {/* <div className="formElement">
                <FormControl className="formControl">
                  <RadioGroup
                    className="formRadioGroup"
                    name="numberOfEmployee"
                  >
                    <FormControlLabel
                      className="formRadioField"
                      value="1to10"
                      control={<Radio />}
                      label="1 to 10"
                    />
                    <FormControlLabel
                      className="formRadioField"
                      value="11to30"
                      control={<Radio />}
                      label="11 to 30"
                    />
                    <FormControlLabel
                      className="formRadioField"
                      value="31to100"
                      control={<Radio />}
                      label="31 to 100"
                    />
                    <FormControlLabel
                      className="formRadioField"
                      value="101to1000"
                      control={<Radio />}
                      label="101 to 1000"
                    />
                    <FormControlLabel
                      className="formRadioField"
                      value="greaterthen1000"
                      control={<Radio />}
                      label="Greater then 1000"
                    />
                  </RadioGroup>
                </FormControl>
              </div> */}
                <div className="formElement">
                  <FormControl className="formControl">
                    <InputLabel id="numberOfEmployeeLable">
                      Select your answer *
                    </InputLabel>
                    <Select
                      labelId="numberOfEmployeeLable"
                      id="numberOfEmployee"
                      className="formInputField"
                      variant="outlined"
                      value={numberOfEmployee}
                      label="Select your answer *"
                      required
                      onChange={handleChangeNumberOfEmployee}
                    >
                      <MenuItem value="1to10">1 to 10</MenuItem>
                      <MenuItem value="11to30">11 to 30 </MenuItem>
                      <MenuItem value="31to100">31 to 100</MenuItem>
                      <MenuItem value="101to1000">101 to 1000</MenuItem>
                      <MenuItem value="greaterthen1000">
                        Greater then 1000
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div className="questionGroup">
                <div className="tskElemHeding mt10">
                  What is the average annual turnover or revenue generated by
                  your business?
                </div>
                <div className="formElement">
                  <FormControl className="formControl">
                    <InputLabel id="revenue-generated">
                      Select your answer *
                    </InputLabel>
                    <Select
                      labelId="revenue-generated"
                      id="revenueGenerated"
                      className="formInputField"
                      variant="outlined"
                      value={revenueGenerated}
                      label="Select your answer *"
                      required
                      onChange={handleChangeRevenueGenerated}
                    >
                      <MenuItem value="cc">0 AED TO 150,000 AED</MenuItem>
                      <MenuItem value="fzc">
                        150,000 AED TO 400,000 AED{" "}
                      </MenuItem>
                      <MenuItem value="llc">
                        400,000 AED TO 1 Million AED
                      </MenuItem>
                      <MenuItem value="osc">
                        Greater than 1 Million AED{" "}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div className="questionGroup">
                <div className="tskElemHeding mt10">
                  Is your organization based out of UAE, and have representative
                  office or branch's in UAE <sup>*</sup>
                </div>
                <div className="formElement">
                  <FormControl className="formControl">
                    <RadioGroup
                      className="formRadioGroup"
                      labelId="setTasktype"
                      name="orgLocationUAE"
                    >
                      <FormControlLabel
                        className="formRadioField"
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        className="formRadioField"
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>

              <div className="questionGroup">
                <div className="tskElemHeding mt10">
                  Are any of the business owners, directors, or significant
                  shareholders considered politically exposed person (PEP)?{" "}
                  <sup>*</sup>
                </div>
                <div className="formElement">
                  <FormControl className="formControl">
                    <RadioGroup
                      className="formRadioGroup"
                      labelId="setTasktype"
                      name="ownerPEP"
                    >
                      <FormControlLabel
                        className="formRadioField"
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        className="formRadioField"
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                    <FormHelperText>
                      (A politically exposed person (PEP) is an individual who
                      holds, or has held, a prominent public position or role in
                      a government, military, judiciary, or international
                      organization)
                    </FormHelperText>
                  </FormControl>
                </div>
              </div>

              <div className="questionGroup">
                <div className="tskElemHeding mt10">
                  Are any of the business owners, directors, or significant
                  shareholders immediate family members or close associates of
                  politically exposed persons?<sup>*</sup>
                </div>
                <div className="formElement">
                  <FormControl className="formControl">
                    <RadioGroup
                      className="formRadioGroup"
                      labelId="setTasktype"
                      name="businessBodyPEP"
                    >
                      <FormControlLabel
                        className="formRadioField"
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        className="formRadioField"
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>

              <div className="questionGroup">
                <div className="tskElemHeding mt10">
                  Does your organization/company engage in business activities
                  with sanctioned countries? <sup>*</sup>
                </div>

                <div className="formElement">
                  <FormControl className="formControl">
                    <RadioGroup
                      className="formRadioGroup"
                      labelId="setTasktype"
                      name="businessBodyPEP"
                      onChange={handleRadioChange}
                    >
                      <FormControlLabel
                        className="formRadioField"
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        className="formRadioField"
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                    <FormHelperText>
                      (Ex: Iran, North Korea, Russia, Cuba, Venezuela, Syria,
                      Myanmar (Burma), North Sudan/ South Sudan, Somalia,
                      Belarus, Yemen.)
                    </FormHelperText>
                  </FormControl>
                </div>

                {showSelect && (
                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel id="selectcountry">
                        Select country *
                      </InputLabel>
                      <Select
                        labelId="selectcountry"
                        id="sanctionedContry"
                        className="formInputField"
                        variant="outlined"
                        value={sanctionedContry}
                        label="Select country *"
                        required
                        onChange={handleChangeSanctionedContry}
                      >
                        <MenuItem value="cc">Iran</MenuItem>
                        <MenuItem value="NorthKorea">North Korea</MenuItem>
                        <MenuItem value="Russia">Russia</MenuItem>
                        <MenuItem value="Cuba">Cuba</MenuItem>
                        <MenuItem value="Venezuela">Venezuela</MenuItem>
                        <MenuItem value="Syria">Syria</MenuItem>
                        <MenuItem value="Myanmar">Myanmar (Burma)</MenuItem>
                        <MenuItem value="NorthSudanSouthSudan">
                          North Sudan/ South Sudan
                        </MenuItem>
                        <MenuItem value="Somalia">Somalia</MenuItem>
                        <MenuItem value="Belarus">Belarus</MenuItem>
                        <MenuItem value="Yemen">Yemen</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                )}
              </div>

              <div className="questionGroup">
                <div className="tskElemHeding mt10">
                  Are there any significant changes anticipated in your business
                  operations, ownership, or structure in the near future?
                  <sup>*</sup>
                </div>
                <div className="formElement">
                  <FormControl className="formControl">
                    <RadioGroup
                      className="formRadioGroup"
                      labelId="setTasktype"
                      name="businesAnticipated"
                    >
                      <FormControlLabel
                        className="formRadioField"
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        className="formRadioField"
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>

              <div className="indentFormtable">
                <div className="tskElemHeding mt10 mb6">
                  Partner Details <sup>*</sup>
                </div>
                <div className="kycTableDtl">
                  <table>
                    <thead>
                      <tr>
                        <th>Name of partner </th>
                        <th>Nationality </th>
                        <th>Date of birth </th>
                        <th className="w80"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="textOnly">Sanket Santra</div>
                        </td>
                        <td>
                          <div className="textOnly">Indian</div>
                        </td>
                        <td>
                          <div className="textOnly">25th Dec 1990</div>
                        </td>
                        <td>
                          <div className="kycActionBtn">
                            <IconButton className="removeRowBtn">
                              <Trash />
                            </IconButton>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                label="Full name of partner"
                                required
                                variant="outlined"
                                autoComplete="off"
                                className="formTextFieldArea"
                              />
                            </FormControl>
                          </div>
                        </td>
                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                label="Nationality"
                                required
                                variant="outlined"
                                autoComplete="off"
                                className="formTextFieldArea"
                              />
                            </FormControl>
                          </div>
                        </td>
                        <td>
                          <div className="formElement w150">
                            <FormControl className="formControl">
                              <InputLabel
                                id="dateOfBirth"
                                className="setCompletionDate"
                              >
                                Date of birth *
                              </InputLabel>
                              <ReactDatePicker
                                locale={currentLocale}
                                showIcon
                                toggleCalendarOnIconClick
                                labelId="dateOfBirth"
                                className="formDatePicker"
                                selected={dateofbirth}
                                minDate={new Date()}
                                dateFormat="dd/MM/yyyy"
                                onChange={(date) => {
                                  setDateofbirth(date);
                                }}
                              />
                            </FormControl>
                          </div>
                        </td>
                        <td>
                          <div className="iconBtnGroup">
                            <Button className="saveBtn" startIcon={<Add />}>
                              Add
                            </Button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="indentFormtable">
                <div className="tskElemHeding mt10 mb6">
                  UBO Details <sup>*</sup>
                </div>
                <div className="kycTableDtl">
                  <table>
                    <thead>
                      <tr>
                        <th>UBO Full </th>
                        <th>Nationality </th>
                        <th>Date of birth </th>
                        <th>Percentage of ownership</th>
                        <th className="w80"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="textOnly">Sanket Santra</div>
                        </td>
                        <td>
                          <div className="textOnly">Indian</div>
                        </td>
                        <td>
                          <div className="textOnly">25th Dec 1990</div>
                        </td>
                        <td>
                          <div className="textOnly">12%</div>
                        </td>
                        <td>
                          <div className="kycActionBtn">
                            <IconButton className="removeRowBtn">
                              <Trash />
                            </IconButton>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                label="Full name of UBO"
                                required
                                variant="outlined"
                                autoComplete="off"
                                className="formTextFieldArea"
                              />
                            </FormControl>
                          </div>
                        </td>
                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                label="Nationality"
                                required
                                variant="outlined"
                                autoComplete="off"
                                className="formTextFieldArea"
                              />
                            </FormControl>
                          </div>
                        </td>
                        <td>
                          <div className="formElement w150">
                            <FormControl className="formControl">
                              <InputLabel
                                id="dateOfBirthUBO"
                                className="setCompletionDate"
                              >
                                Date of birth *
                              </InputLabel>
                              <ReactDatePicker
                                locale={currentLocale}
                                showIcon
                                toggleCalendarOnIconClick
                                labelId="dateOfBirthUBO"
                                className="formDatePicker"
                                selected={dateofbirthUBO}
                                minDate={new Date()}
                                dateFormat="dd/MM/yyyy"
                                onChange={(date) => {
                                  setDateofbirthUBO(date);
                                }}
                              />
                            </FormControl>
                          </div>
                        </td>
                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                label="Enter ownership %"
                                required
                                variant="outlined"
                                autoComplete="off"
                                className="formTextFieldArea"
                              />
                            </FormControl>
                          </div>
                        </td>
                        <td>
                          <div className="iconBtnGroup">
                            <Button className="saveBtn" startIcon={<Add />}>
                              Add
                            </Button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="indentFormtable">
                <div className="tskElemHeding mt10 mb6">
                  Manager Details <sup>*</sup>
                </div>
                <div className="kycTableDtl">
                  <table>
                    <thead>
                      <tr>
                        <th>Name of Manager </th>
                        <th>Nationality </th>
                        <th>Date of birth </th>
                        <th className="w80"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="textOnly">Sanket Santra</div>
                        </td>
                        <td>
                          <div className="textOnly">Indian</div>
                        </td>
                        <td>
                          <div className="textOnly">25th Dec 1990</div>
                        </td>
                        <td>
                          <div className="kycActionBtn">
                            <IconButton className="removeRowBtn">
                              <Trash />
                            </IconButton>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                label="Full name of Manager"
                                required
                                variant="outlined"
                                autoComplete="off"
                                className="formTextFieldArea"
                              />
                            </FormControl>
                          </div>
                        </td>
                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                label="Nationality"
                                required
                                variant="outlined"
                                autoComplete="off"
                                className="formTextFieldArea"
                              />
                            </FormControl>
                          </div>
                        </td>
                        <td>
                          <div className="formElement w150">
                            <FormControl className="formControl">
                              <InputLabel
                                id="dateOfBirthManager"
                                className="setCompletionDate"
                              >
                                Date of birth *
                              </InputLabel>
                              <ReactDatePicker
                                locale={currentLocale}
                                showIcon
                                toggleCalendarOnIconClick
                                labelId="dateOfBirthManager"
                                className="formDatePicker"
                                selected={dateofbirthManager}
                                minDate={new Date()}
                                dateFormat="dd/MM/yyyy"
                                onChange={(date) => {
                                  setDateofbirthManager(date);
                                }}
                              />
                            </FormControl>
                          </div>
                        </td>
                        <td>
                          <div className="iconBtnGroup">
                            <Button className="saveBtn" startIcon={<Add />}>
                              Add
                            </Button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="indentFormtable">
                <div className="tskElemHeding mt10 mb6">
                  Contact person details <sup>*</sup>
                </div>
                <div className="kycTableDtl">
                  <table>
                    <thead>
                      <tr>
                        <th>Contact person Name</th>
                        <th>Contact No </th>
                        <th>Email Address</th>
                        <th className="w80"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="textOnly">Sanket Santra</div>
                        </td>
                        <td>
                          <div className="textOnly">+91 7384979442</div>
                        </td>
                        <td>
                          <div className="textOnly">
                            uiux-designer@miniontek.com
                          </div>
                        </td>
                        <td>
                          <div className="kycActionBtn">
                            <IconButton className="removeRowBtn">
                              <Trash />
                            </IconButton>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                label="Full name"
                                required
                                variant="outlined"
                                autoComplete="off"
                                className="formTextFieldArea"
                              />
                            </FormControl>
                          </div>
                        </td>
                        <td>
                          <div className="custContactNo">
                            <select
                              // disabled={patientData?.patientExternalSourceId}
                              name="countryCode"
                              id="countryCode"
                              className="countryCode"
                              // value={
                              //   patientData?.patientPhCountryCode ||
                              //   emplyPhnCC ||
                              //   ""
                              // }
                              // onChange={(event) =>
                              //   updatePatientData(
                              //     "patientPhCountryCode",
                              //     event.target.value
                              //   )
                              // }
                              // onChange={(event) => {
                              //   const selectedValue =
                              //     event.target.value !== ""
                              //       ? event.target.value
                              //       : patientData?.patientPhCountryCode &&
                              //         patientData.patientPhCountryCode !== ""
                              //       ? patientData.patientPhCountryCode
                              //       : emplyPhnCC && emplyPhnCC !== ""
                              //       ? emplyPhnCC
                              //       : "";
                              //   updatePatientData(
                              //     "patientPhCountryCode",
                              //     selectedValue
                              //   );
                              //   switch (selectedValue) {
                              //     case "+91":
                              //       setPhoneRegexPattern(/^[6-9]\d{9}$/);
                              //       break;
                              //     case "+52":
                              //       setPhoneRegexPattern(/^[1-9]\d{9}$/);
                              //       break;
                              //     case "+971":
                              //       setPhoneRegexPattern(
                              //         /^(?:\50|51|52|55|56|2|3|4|6|7|9)\d{7}$/
                              //       );
                              //       break;
                              //     default:
                              //       setPhoneRegexPattern(/^[0-9]{10,}$/);
                              //       break;
                              //   }
                              // }}
                            >
                              <option value="+91" selected>
                                +91
                              </option>
                              <option value="+1">+1</option>
                              <option value="+635">+635</option>
                            </select>
                            <div className="formElement">
                              <FormControl className="formControl">
                                <TextField
                                  autoComplete="off"
                                  label={t("contact_number")}
                                  variant="outlined"
                                  className="formTextFieldArea"
                                />
                              </FormControl>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                label="Email id"
                                required
                                variant="outlined"
                                autoComplete="off"
                                className="formTextFieldArea"
                              />
                            </FormControl>
                          </div>
                        </td>
                        <td>
                          <div className="iconBtnGroup">
                            <Button className="saveBtn" startIcon={<Add />}>
                              Add
                            </Button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="indentFormtable">
                <div className="tskElemHeding mt10 mb6">
                  Kindly Upload Relevant Documents ( PDF, Word, Excel, PNG, JPG,
                  JPEG )
                </div>
                <div className="kycTableDtl">
                  <table>
                    <thead>
                      <tr>
                        <th>Document Name</th>
                        <th>File Name</th>
                        <th className="w140">Upload File</th>
                      </tr>
                    </thead>
                    <tbody>
                      {items.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <div className="textOnly dfAc">
                              {fileData[item] ? (
                                <CheckCircle className="fileAvailable" />
                              ) : (
                                <CheckCircleOutline className="fileNotAvailable" />
                              )}
                              <span>{item}</span>
                            </div>
                          </td>
                          <td>
                            {fileData[item] && (
                              <div className="uplodedFileName">
                                <span className="name">
                                  {fileData[item].name} (
                                  {formatFileSize(fileData[item].size)})
                                </span>
                                <div className="kycActionBtn">
                                  <IconButton
                                    className="removeRowBtn"
                                    onClick={() => removeFile(item)}
                                  >
                                    <Trash />
                                  </IconButton>
                                </div>
                              </div>
                            )}
                          </td>
                          <td>
                            <Tooltip
                              followCursor
                              arrow
                              title="Please select only PDF , Word , Excel, PNG, JPG, JPEG file"
                            >
                              <Button
                                startIcon={<Upload />}
                                className="comBtnOutline w140"
                                onClick={() => openFileInput(item)}
                              >
                                <input
                                  ref={(ref) =>
                                    (fileInputRefs.current[item] = ref)
                                  }
                                  type="file"
                                  hidden
                                  onChange={(e) => handleFileChange(e, item)}
                                  accept=".pdf, .doc, .docx, .xls, .xlsx,.png, .jpg, .jpeg"
                                />
                                Upload File
                              </Button>
                            </Tooltip>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="questionGroup">
                <div className="tskElemHeding mt10 mb6">
                  Terms And Condition<sup>*</sup>
                </div>

                <div className="formElement">
                  <FormControl className="formControl">
                    <FormGroup
                      aria-label="position"
                      row
                      className="feformGroup"
                    >
                      <FormControlLabel
                        className={
                          checked ? "formCheckBox checked" : "formCheckBox"
                        }
                        control={
                          <Checkbox
                            checked={checked}
                            onChange={handleCheckboxChange}
                          />
                        }
                        label="I/we confirm that the information provided in this AML KYC form is true, accurate, and complete to the best of my/our knowledge. I/we understand that providing false or misleading information may result in legal consequences."
                        labelPlacement="End"
                        sx={{
                          color: checked ? "#fff" : "#878787",
                          transition: "color 0.3s ease",
                        }}
                      />
                    </FormGroup>
                  </FormControl>
                </div>
              </div>
              {/* end from */}
            </div>
          </div>
        </>

        <FooterLP />
      </div>
    </>
  );
}
