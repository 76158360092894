import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { enLang } from "./languages/en-lang";
import { esLang } from "./languages/es-lang";

const savedLanguage = localStorage.getItem("language") || "en";

export default i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enLang },
    es: { translation: esLang },
  },
  lng: savedLanguage ,
  fallbacking: "en",
  interpolation: { escapeValue: false },
});
