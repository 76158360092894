import { CheckBox, DragIndicator } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import { XLg } from "react-bootstrap-icons";
import {
  // getPersonsOfOrg,
  getOrgEmployees,
  getMappedUnmappedMenusOfUser,
  mapMenusToUser,
} from "../../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import DashboardServices from "../../../../../services/DashboardServices";
import { GetLoginUserDetails } from "../../../../../utils/Utils";

export default function UnitWiseInvesticationTest({
  handleCloseUnitInvestigationAdmin,
  addUnitInvestigationAdminData,
}) {
  console.log("addUnitInvestigationAdminData", addUnitInvestigationAdminData);
  const userDetails = GetLoginUserDetails();
  const { t } = useTranslation();
  const [userData, setUserData] = useState([]);
  const [testNameData, setTestNameData] = useState(
    addUnitInvestigationAdminData.testName
  );

  const [loading, setLoading] = useState(false);

  const [selectAllCheck, setSelectAllCheck] = useState(false);
  const [selectNoneCheck, setSelectNoneCheck] = useState(false);

  const [selectedUserName, setSelectedUserName] = useState("");

  const [mapDataOfUser, setMapDataOfUser] = useState([]);
  const [switchStates, setSwitchStates] = useState([]);

  useEffect(() => {
    fetchMapMenuForUser();
  }, [userDetails.orgId, addUnitInvestigationAdminData.testId]);

  const fetchMapMenuForUser = () => {
    if ((userDetails.orgId, addUnitInvestigationAdminData)) {
      DashboardServices.getAllUnitsMappedWithTest(
        userDetails.orgId,
        addUnitInvestigationAdminData.testId
      )
        .then((response) => {
          // console.log("getMappedUnmappedMenusOfUser", response.data);
          setMapDataOfUser(response.data);
          const initialSwitchStates = response.data.map(
            (item) => item.activeFlag === "Y"
          );
          // console.log("initialSwitchStates", initialSwitchStates);
          // setSwitchStates(initialSwitchStates);
          const allTrue = initialSwitchStates.every((state) => state === true);

          if (allTrue) {
            setSwitchStates(initialSwitchStates);
            setSelectAllCheck(true);
          } else {
            setSwitchStates(initialSwitchStates);
          }
        })
        .catch((error) => {
          // console.error("Error fetching activeFlag menus:", error);
          //   toast.error(t("failed_to_fetch_mapped_menus") , {
          //     position: toast.POSITION.TOP_RIGHT,
          //   });
        });
    }
  };

  // Function to handle changes in switch state
  const handleChange = (index) => (event) => {
    const newState = [...switchStates];
    newState[index] = event.target.checked;
    setSwitchStates(newState);

    const allFalse = newState.every((state) => !state);
    setSelectAllCheck(allFalse);

    const allTrue = newState.every((state) => state);
    setSelectAllCheck(allTrue);

    const selectNone = newState.every((state) => {
      // console.log("select none", state);
      return state === false;
    });
    // console.log("selectNone", selectNone);
    setSelectNoneCheck(selectNone);
  };

  // console.log("switchStates", switchStates);

  // const handleSelectAll = () => {
  //   setSwitchStates(new Array(switchStates.length).fill(true));
  //   setSelectAllCheck(true);
  //   setSelectNoneCheck(false);
  // };

  // const handleSelectNone = () => {
  //   setSwitchStates(new Array(switchStates.length).fill(false));
  //   setSelectNoneCheck(true);
  //   setSelectAllCheck(false);
  // };

  const handleSelectAll = (event) => {
    // console.log("handleSelectAll triggered", event.target.checked);
    const val = event.target.checked;
    if (val) {
      setSwitchStates(new Array(switchStates.length).fill(true));
      setSelectAllCheck(true);
    } else {
      setSwitchStates(new Array(switchStates.length).fill(false));
      setSelectAllCheck(false);
    }
    // console.log("switchStates after select all:", switchStates);
  };

  // const handleSelectAll = () => {
  //   console.log("handleSelectAll triggered");
  //   setSwitchStates(new Array(switchStates.length).fill(true));
  //   setSelectAllCheck(true);
  //   setSelectNoneCheck(false);
  //   console.log("switchStates after select all:", switchStates);
  // };

  const handleSubmit = () => {
    // console.log("handleSubmit");
    setLoading(true);

    const reqDto = mapDataOfUser.map((item, index) => ({
      unitId: item.unitId,
      unitName: item.unitName,
      activeFlag: switchStates[index] ? "Y" : "N",
    }));

    // console.log("reqDto", reqDto);

    // console.log("selectedUserId", selectedUserId);

    const reqDtoFiltered = reqDto.filter((item) => item.activeFlag === "Y");

    // console.log("reqDtoFiltered", reqDtoFiltered);

    const reqDtoUnit = reqDtoFiltered.map((item) => item.unitId);

    // console.log("reqDtoUnit", reqDtoUnit);

    // setLoading(false);
    //   return ;

    DashboardServices.mapTestToUnits(
      addUnitInvestigationAdminData.testId,
      reqDtoUnit
    ).then((response) => {
      // console.log("mapMenusToUser", response.data);
      if (response.data === 1) {
        toast.success(t("successfully_added_unit_for_test"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        setSelectedUserName("");
        fetchMapMenuForUser();
        // setSwitchStates([]);
        setSelectAllCheck(false);
        setSelectNoneCheck(false);
        setLoading(false);
      } else {
        toast.error(t("error_in_adding_unit_for_test"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
      }
    });
  };

  return (
    <>
      <div className="flotingAreaContainer">
        <div className="flotingAreaClose">
          <div className="facHeader">{t("unit_list")}</div>

          <IconButton
            className="CloseBtn"
            onClick={() => handleCloseUnitInvestigationAdmin()}
          >
            <XLg />
          </IconButton>
        </div>

        {loading ? (
          <div className="meetingVEContainer">
            <div className="center-progress" style={{ height: "65vh" }}>
              <CircularProgress sx={{ marginTop: "180px" }} />
            </div>
          </div>
        ) : (
          <div className="elementFormContainer">
            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  // hiddenLabel
                  label={t("test_name")}
                  //    required
                  autoComplete="off"
                  variant="outlined"
                  className="formTextFieldArea"
                  value={testNameData}
                  onChange={(e) => setTestNameData(e.target.value)}
                  disabled
                />
              </FormControl>
            </div>

            <div className="formElement">
              <ul className="accessControlList">
                {mapDataOfUser.map((menuItem, index) => (
                  <li key={index}>
                    <div className="acsConLstInd">
                      <div className="accessName">
                        <span className="acnIoc">
                          <DragIndicator />
                        </span>
                        <span className="acnName">{menuItem.unitName}</span>
                      </div>
                      <div className="accessControlBtn tblActionBtnGrp">
                        <FormControlLabel
                          className="couponStatusSwitch"
                          control={
                            <Switch
                              checked={switchStates[index]}
                              onChange={handleChange(index)}
                              color="primary"
                            />
                          }
                          label={
                            switchStates[index] ? t("enable") : t("disable")
                          }
                        />
                      </div>
                    </div>
                  </li>
                ))}
                {/* <li>
                    <div className='acsConLstInd'>
                        <div className='accessName'>
                            <span className='acnIoc'><DragIndicator/></span>
                            <span className='acnName'>Accounts and Invoices - Add Accounts and Invoices</span>
                        </div>
                        <div className='accessControlBtn tblActionBtnGrp'>
                            <FormControlLabel
                                className="couponStatusSwitch"
                                control={<Switch checked={isChecked} onChange={handleChange} color="primary" />}
                                label={isChecked ? "Enable" : "Disable"}
                            />
                         </div>
                    </div>
                </li> */}
              </ul>
            </div>
          </div>
        )}

        <div className="elementFooter">
          <div className="formBtnElement">
            <Button
              className="dfultPrimaryBtn"
              onClick={() => handleSubmit()}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <>{t("save")}</>
              )}
            </Button>
            {!loading && (
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={() => handleCloseUnitInvestigationAdmin()}
              >
                {t("cancel")}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
