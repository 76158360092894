import { Add, Edit, FormatListBulleted, Save } from "@mui/icons-material";
import {
  Autocomplete,
  ButtonGroup,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { NodePlusFill, Trash } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { jwtDecode } from "jwt-decode";
import DashboardServices from "../../../../../services/DashboardServices";
import { GetLoginUserDetails } from "../../../../../utils/Utils";
import Joyride from "react-joyride";
import { useContext } from "react";
import { TourContext } from "../../../../../common/TourProvider";

export const VitalUnit = () => {
  const userDetails = GetLoginUserDetails();

  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  const { t } = useTranslation();
  const [vitalUnitData, setVitalUnitData] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [editVitalName, setEditVitalName] = useState("");
  const [editVitalUnit, setEditVitalUnit] = useState("");

  useEffect(() => {
    fetchVitalUnitData();
  }, []);

  useEffect(()=>{
  setBookSteps([
    {
      target: ".vitalConfigStepOne",
      content: "Here you can view and edit vital unit configurations for your organization.",
      placement: "bottom",
      disableBeacon: true,
    },
    {
      target: ".vitalConfigStepTwo",
      content: "This column displays the unique code for each vital.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: ".vitalConfigStepThree",
      content: "This column shows the name of the vital.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: ".vitalConfigStepThreeFour",
      content: "This column shows the Unit of the vital.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: ".vitalConfigStepFour",
      content: "This column has Edit button to modify Specific vital details and Save to apply changes.",
      placement: "top",
      disableBeacon: true,
    },

  ])
  },[])

  const fetchVitalUnitData = () => {
    if (userDetails) {
      const reqDto = {
        orgId: userDetails.orgId,
        countryCode: userDetails.orgCountryCode,
      };

      DashboardServices.getVitalConfigs(reqDto).then((response) => {
        console.log("get Vital unit Data", response.data);
        setVitalUnitData(response.data);
      });
    }
  };

  console.log("vitalUnitData", vitalUnitData);

  const handleEdit = (index, vital) => {
    setEditIndex(index);
    setEditVitalName(vital.vitalName || "");
    setEditVitalUnit(vital.vitalUnits[0]);
  };

  const handleSave = (index) => {
    const vital = vitalUnitData[index];
    const uniqueUnits = [...new Set(vital.vitalUnits)];
    const rearrangedUnits = [
      editVitalUnit,
      ...uniqueUnits.filter((unit) => unit !== editVitalUnit),
    ];

    const reqDto = {
      vitalName: editVitalName,
      vitalCode: vital.vitalCode,
      orgId: vital.orgId,
      countryCode: vital.countryCode,
      vitalUnits: rearrangedUnits,
    };

    console.log("reqDto", reqDto);

    DashboardServices.updateVitalConfig(userDetails.userId, reqDto).then(
      (response) => {
        console.log("response", response.data);
        if (response.data == "1") {
          toast.success(t("vital_updated_successfully"));
          setEditIndex(null);
          fetchVitalUnitData();
        } else {
          toast.error(t("something_went_wrong"));
        }
      }
    );
  };

  return (
    <>
          <Joyride
        steps={booksteps}
        run={run["viewVitalConfigAD"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        styles={{
          buttonClose: {
            display: "none",
          },
        }}
      />
      <div className="fullContainArea vitalConfigStepOne">
        <div className="cuHeaderGrp">
          <div className="cuhederTxt">{t("vital_unit")}</div>
        </div>
        <div className="cuContainArea">
          <div className="TableContainer organizationPosition responsiveTable">
            <table className="taskListTable">
              <thead className="taskListtableHeader">
                <tr>
                  <th className="mxWidth250 vitalConfigStepTwo">
                    <div className="txtWithBtn">{t("vital_code")}</div>
                  </th>

                  <th className="mxWidth250 vitalConfigStepThree">
                    <div className="txtWithBtn ">
                      {t("organization_vital_name")}
                    </div>
                  </th>

                  <th className="mxWidth150 vitalConfigStepThreeFour">
                    <div className="txtWithBtn">{t("vital_units")}</div>
                  </th>
                  <th className="mxWidth150 vitalConfigStepFour">
                    <div className="txtWithBtn">{t("action")}</div>
                  </th>
                </tr>
              </thead>
              <tbody className="scrolableTableBody">
                {vitalUnitData.map((vital, index) => (
                  <tr key={index}>
                    <td>
                      <div className="orgPText">{vital.vitalCode}</div>
                    </td>
                    <td>
                      {editIndex === index ? (
                        <div className="orgPText">
                          <TextField
                            className="formInputSelect"
                            autoComplete="off"
                            size="small"
                            value={editVitalName}
                            onChange={(e) => setEditVitalName(e.target.value)}
                          />
                        </div>
                      ) : (
                        <div className="orgPText">
                          {vital.vitalName ? vital.vitalName : t("n_a")}
                        </div>
                      )}
                    </td>
                    <td>
                      {editIndex === index ? (
                        <Select
                          className="formInputSelect"
                          hiddenLabel
                          size="small"
                          value={editVitalUnit}
                          onChange={(e) => setEditVitalUnit(e.target.value)}
                        >
                          {vital.vitalUnits.map((unit) => (
                            <MenuItem key={unit} value={unit}>
                              {unit}
                            </MenuItem>
                          ))}
                        </Select>
                      ) : (
                        <div className="orgPText">{vital.vitalUnits[0]}</div>
                      )}
                    </td>
                    <td>
                      <div className="orgPText">
                        <ButtonGroup
                          variant="contained"
                          className="buttonContainerGrp"
                        >
                          {editIndex === index ? (
                            <Tooltip title={t("save_vital")}>
                              <IconButton onClick={() => handleSave(index)}>
                                <Save />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title={t("edit_vital")}>
                              <IconButton
                                onClick={() => handleEdit(index, vital)}
                              >
                                <Edit />
                              </IconButton>
                            </Tooltip>
                          )}
                        </ButtonGroup>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
