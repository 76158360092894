import { Button } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { CustomModal } from "../../../task/view/component/Modals/CustomModal";
import { getLoginUserDetailsLocal } from "../../../../utils/Utils";
import { useTranslation } from "react-i18next";

export const AgreementModal = ({
  handelOpenRegistration,
  closeAcceptModal,
}) => {
  const tAcCheckboxRef = useRef();
  const [isTACAccepted, setIsTACAccepted] = useState(false);
  const { t } = useTranslation();

  const longDivRef = useRef(null);
  const [isAtEnd, setIsAtEnd] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // If the last element of the long div is visible, show the other div
        setIsAtEnd(entry.isIntersecting);
      },
      {
        root: null, // Uses viewport as the root
        threshold: 1.0, // Fully visible
      }
    );

    const target = longDivRef.current?.lastElementChild; // Target the last child
    if (target) observer.observe(target);

    return () => {
      if (target) observer.unobserve(target);
    };
  }, []);

  return (
    <>
      <CustomModal
        modalTitle={t("disclaimer_heading")}
        closeModal={() => {
          //   closeModal();
          closeAcceptModal();
        }}
        // showCloseButton={false}
        // className={"width90vwModel"}
      >
        <>
          <div class="disclamer-container">
            <div class="policy-items" ref={longDivRef}>
              <p>{t("signup_disclaimer_1")}</p>
              <p>{t("signup_disclaimer_2")}</p>
              <p>{t("signup_disclaimer_3")}</p>
              <p>{t("signup_disclaimer_4")}</p>
              <p>{t("signup_disclaimer_5")}</p>
              <p>{t("signup_disclaimer_6")}</p>
              <p>{t("signup_disclaimer_7")}</p>
              <p>{t("signup_disclaimer_8")}</p>
              <p>{t("signup_disclaimer_9")}</p>
              <p>{t("signup_disclaimer_10")}</p>
              <p>{t("signup_disclaimer_11")}</p>
              <p>{t("signup_disclaimer_12")}</p>
              <p>{t("signup_disclaimer_13")}</p>
            </div>
          </div>

          {isAtEnd && (
            <div className="disclamerFooter">
              <div className="disclamerCheckbox">
                <input
                  type="checkbox"
                  id={`accept-tAc`}
                  ref={tAcCheckboxRef}
                  checked={isTACAccepted}
                  style={{ marginRight: "10px" }}
                  onChange={(e) => {
                    setIsTACAccepted(e.target.checked);
                  }}
                />
                {/* <label for={`accept-tAc`}> */}
                <span>
                  {/* By acknowledging and accepting all the terms and conditions
                  outlined in the above policies, I understand that any
                  violation may subject me to penalties as determined by the
                  organization's policies and applicable laws. */}
                  {t("signup_disclaimer_14")}
                </span>
                {/* </label> */}
              </div>
              <div className="policy-submit-container">
                <Button
                  onClick={() => {
                    handelOpenRegistration();
                  }}
                  disabled={!isTACAccepted}
                  className={
                    isTACAccepted ? "dfultPrimaryBtn" : "dfultDarkSecondaryBtn"
                  }
                >
                  {t("submit_and_continue")}
                  {/* Submit And Continue */}
                </Button>
              </div>
            </div>
          )}
        </>
      </CustomModal>
    </>
  );
};
