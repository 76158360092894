import React, { useState, useEffect } from "react";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController,
} from "chart.js";

import { Bar, Chart, Doughnut, Pie } from "react-chartjs-2";
import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import { getDashboardDataOfItem } from "../../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
// import faker from "faker";

export default function CardAttendance({
  handelOpenViewAttendance,
  chartDataOfItem,
  menuModuleList,
  userMenuList,
}) {
  console.log("menuModuleList", menuModuleList);
  const { t } = useTranslation();
  // const userDetails = jwtDecode( SecureIndexedDB.getItem("token"));

  // const [chartDataOfItem, setChartDataOfItem] = useState([]);

  // useEffect(() => {
  //   getDashboardDataOfItem(userDetails.orgId, userDetails.userId).then(
  //     (response) => {
  //       console.log("getDashboardDataOfItem", response.data);
  //       setChartDataOfItem(response.data);
  //     }
  //   );
  // }, [userDetails.orgId, userDetails.userId]);

  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    LineController,
    BarController
  );
  // const data = {
  //   labels: ['Work From Home', 'Work from Office'],
  //   datasets: [
  //     {
  //       label: "# Employee",
  //       data: [chartDataOfItem.todayWrokFromHome , chartDataOfItem.todayWorkAtOffice],
  //       backgroundColor: ["#FFEAA7", "#F39C12", "#1ABC9C", "#FF6B6B"],
  //       hoverBackgroundColor: ["#FFEAA7", "#F39C12", "#1ABC9C", "#FF6B6B"],
  //       borderWidth: 0,
  //       type: "doughnut",
  //     },
  //   ],
  // };

  const data = {
    labels: [],
    datasets: [],
  };

  if (
    chartDataOfItem.todayWorkFromHome !== 0 ||
    chartDataOfItem.todayWorkAtOffice !== 0
  ) {
    if (chartDataOfItem.todayWorkFromHome !== 0) {
      data.labels.push(t("work_from_home"));
      data.datasets.push({
        data: [chartDataOfItem.todayWorkFromHome],
        backgroundColor: ["#FFEAA7"],
        hoverBackgroundColor: ["#FFEAA7"],
        borderWidth: 0,
        type: "doughnut",
      });
    }

    if (chartDataOfItem.todayWorkAtOffice !== 0) {
      data.labels.push(t("work_at_office"));
      data.datasets.push({
        data: [chartDataOfItem.todayWorkAtOffice],
        backgroundColor: ["#F39C12"],
        hoverBackgroundColor: ["#F39C12"],
        borderWidth: 0,
        type: "doughnut",
      });
    }
  } else {
    data.labels.push(t("no_data"));
    data.datasets.push({
      data: [1],
      backgroundColor: ["#A9A9A9"],
      borderWidth: 0,
      hoverBackgroundColor: ["#A9A9A9"],
    });
  }

  const options = {
    cutout: "80%",

    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 6,
          color: "#d2d2d2",
          font: {
            size: 12,
            weight: "light",
          },
        },
      },
    },
  };

  // curent People status bar and line chart combo start

  const barLineData1 = {
    labels: ["Sales", "IT", "Accounts", "HR", "PR", "Leagle"],
    datasets: [
      // {
      //   label: "Appointments",
      //   backgroundColor: "rgba(255,255,255,.7)",
      //   borderColor: "rgba(140,120,230,1)",
      //   borderWidth: 2,
      //   hoverBackgroundColor: "rgba(255,255,255,1)",
      //   hoverBorderColor: "rgba(140,120,230,1)",
      //   data: [470, 208, 140, 409, 586, 530],
      //   type: "line",
      //   fill: false,
      // },
      {
        label: "Attrition rate",
        backgroundColor: "rgba(255,255,255,.7)",
        borderColor: "rgba(237,50,55,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(255,255,255,1)",
        hoverBorderColor: "rgba(237,50,55,1)",
        data: [165, 109, 310, 121, 256, 105],
        type: "line",
        fill: false,
      },
      {
        label: "Headcount",
        backgroundColor: "rgba(149,165,166,0.7)",

        borderWidth: 0,
        hoverBackgroundColor: "rgba(149,165,166,1)",
        data: [570, 600, 580, 700, 856, 1000],
        type: "bar",
        barThickness: 10,
      },
    ],
  };

  const labels = ["Sales", "IT", "Accounts", "HR", "PR", "Leagle", "Admin"];

  const dailyAbsentPresentCountData =
    chartDataOfItem.dailyAbsentPresentCount || [];
  console.log("dailyAbsentPresentCountData", dailyAbsentPresentCountData);

  // const barLineData = {
  //   labels,
  //   datasets: [
  //     {
  //       label: "Attrition rate",
  //       backgroundColor: "rgba(255,255,255,.7)",
  //       borderColor: "rgba(255,255,255,1)",
  //       borderWidth: 2,
  //       hoverBackgroundColor: "rgba(255,255,255,1)",
  //       hoverBorderColor: "rgba(237,50,55,1)",
  //       data: [265, 19, 170, 121, 246, 346, 260],
  //       type: "line",
  //       fill: false,
  //     },
  //     {
  //       label: "Female",
  //       data: [570, 500, 180, 620, 316, 290, 344],
  //       backgroundColor: "#FF6384",
  //       barThickness: 16,
  //     },
  //     {
  //       label: "Male",
  //       data: [400, 300, 340, 145, 326, 170, 344],
  //       backgroundColor: "#36A2EB",
  //       barThickness: 16,
  //     },
  //     {
  //       label: "Others",
  //       data: [70, 100, 350, 348, 436, 372, 344],
  //       backgroundColor: "#FFCE56",
  //       barThickness: 16,
  //     },
  //   ],
  // };

  const barLineData = {
    labels: dailyAbsentPresentCountData.map((item) => item.date),
    datasets: [
      {
        label: t("absent_count"),
        backgroundColor: "rgba(255, 99, 132, 0.7)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(255, 99, 132, 1)",
        hoverBorderColor: "rgba(255, 99, 132, 1)",
        data: dailyAbsentPresentCountData.map((item) => item.absentCount),
      },
      {
        label: t("present_count"),
        backgroundColor: "rgba(54, 162, 235, 0.7)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(54, 162, 235, 1)",
        hoverBorderColor: "rgba(54, 162, 235, 1)",
        data: dailyAbsentPresentCountData.map((item) => item.presentCount),
      },
    ],
  };

  const barLineOoptions = {
    cutout: "90%",

    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 10,
          color: "#d2d2d2",
          font: {
            size: 12,
            weight: "light",
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    borderRadius: 4,
  };
  // curent People status bar and line chart combo end

  return (
    <>
      <div className="anDashIndCard">
        <div className="anDashCardArea">
          <div className="andHeader">
            <div className="andCrdNM">{t("attendance")}</div>
            <div className="andCrdUnitCount">
              {/* <span title="Attrition">
                Attrition: <span className="fbold">12%</span>
              </span> */}

              {/* <span title="Tenure">
                Absenteeism:{" "}
                <span className="fbold">
                  {chartDataOfItem.absenteeism} Person
                </span>
              </span> */}
            </div>
          </div>

          <div className="andCrdGraf">
            <div className="anDLftGrf">
              <div className="centTxtShow">
                <Pie
                  className="hw100"
                  type="doughnut"
                  data={data}
                  options={options}
                />
                <div className="highlighted-text">
                  {/* <span>Total</span> */}
                  <span className="highDataCount">
                    {chartDataOfItem.todayPresentPc}
                  </span>
                </div>
              </div>
            </div>
            <div className="anDRightGrf">
              <Bar
                className="hw100"
                type="bar"
                data={barLineData}
                options={barLineOoptions}
              />
              {/* <Bar data={barLineData} options={barLineOoptions} /> */}
            </div>
          </div>
          {/* <div className="andCrdData"></div> */}
          <div className="andCrdFooter">
            {userMenuList &&
              menuModuleList &&
              userMenuList.includes(
                menuModuleList.find((item) => item.menuName === "Attendance")
                  .menuId
              ) && (
                <Button
                  className="dfultPrimaryBtn "
                  onClick={() => handelOpenViewAttendance()}
                >
                  {t("view")}
                </Button>
              )}
            {/* <Button
              startIcon={<Add />}
              className="dfultPrimaryBtn "
              //   onClick={() => handelOpenAddPeople()}
            >
              Add
            </Button> */}
          </div>
        </div>
      </div>
    </>
  );
}
