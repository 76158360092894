import {
  FormControl,
  IconButton,
  MenuItem,
  Popover,
  Select,
} from "@mui/material";
import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from "chart.js";
import React, { useContext, useState } from "react";
import { ArrowRepeat, ArrowsAngleExpand } from "react-bootstrap-icons";
import { Bar } from "react-chartjs-2";
import { jwtDecode } from "jwt-decode";
import { getUnits } from "../../../../../services/AdminService";
import { useEffect } from "react";
import DashboardServices from "../../../../../services/DashboardServices";
import { useTranslation } from "react-i18next";
import { GetLoginUserDetails } from "../../../../../utils/Utils";
import Joyride from "react-joyride";
import { TourContext } from "../../../../../common/TourProvider";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController
);

export default function CardManagementConsultation({
  chartDataOfItem,
  showMoreBtn = true,
  onOpenMore,
}) {
  const { t } = useTranslation();

  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);
  // stacked chart
  const userDetails = GetLoginUserDetails();

  console.log("userDetails", userDetails);

  const [managementData, setManagementData] = useState({});
  const [showMoreAnchorEl, setShowMoreAnchorEl] = useState(null);


  useEffect(()=>{
    setBookSteps([
      {
        target: ".msConsultation",
        content: "This section displays card management consultations.",
        disableBeacon: true,
      },
      {
        target: ".msConsultationStepOne",
        content: "Click the refresh icon to update data.",
        disableBeacon: true,
      },
      {
        target: ".msConsultationStepTwo",
        content: "Use this dropdown to filter data by specific units.",
        disableBeacon: true,
      },
      {
        target: ".msConsultationStepThree",
        content: "Toggle between daily or monthly views using this filter.",
        disableBeacon: true,
      },
      {
        target: ".msConsultationStepFour",
        content: "Click here to view the chart in full screen.",
        disableBeacon: true,
      },
      {
        target: ".msConsultationStepFive",
        content: "This chart visualizes consultation data.Current day status with number of registrations, consults, patients in queue, repeat patients, cancelled patients, active center's & doctors.",
        disableBeacon: true,
      },
     
      {
        target: ".msConsultationStepSix",
        content: "This bar Chart Represent Consultation , No. of repeat patient. , No of doctors doing consultation.",
        disableBeacon: true,
      },
    ]);    
  },[])

  useEffect(() => {
    if (chartDataOfItem) {
      setManagementData(chartDataOfItem);
    }
  }, [chartDataOfItem]);

  const [unitsData, setUnitData] = useState([]);
  const [selectUnits, setSelectUnits] = useState(0);

  useEffect(() => {
    getUnits(userDetails.orgId).then((response) => {
      console.log("getUnits", response.data);
      setUnitData(response.data);
    });
  }, [userDetails.orgId]);

  // useEffect(()=>{
  //   if(userDetails.signleUnitId){
  //   console.log('userDetails.signleUnitId', userDetails.signleUnitId)
  //   setSelectUnits(userDetails.signleUnitId)
  //   }
  // },[])

  const handleChangeUnits = (event) => {
    console.log("handleChangePUnits", event.target.value);
    setSelectUnits(event.target.value);
  };

  const data = {
    labels: ["Repeat", "Queue", "Consultation", "Visit"],
    datasets: [
      {
        label: "Visit",
        data: [653],
        backgroundColor: "#00acc1",
      },
      {
        label: "Consultation",
        data: [754],
        backgroundColor: "#00bcd4",
      },
      {
        label: "Queue",
        data: [867],
        backgroundColor: "#26c6da",
      },
      {
        label: "Repeat",
        data: [1274],
        backgroundColor: "#4dd0e1",
      },
    ],
  };

  const options = {
    plugins: {
      tooltip: {
        enabled: false, // Disable tooltips
      },
      legend: {
        display: false, // Hide legend
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true, // Stacked bars along X-axis
        display: false, // Hides X-axis line
      },
      y: {
        stacked: true, // Stacked bars along Y-axis
        grid: {
          display: false, // Disable grid lines
        },
        ticks: {
          color: "#fff", // White-colored labels (months)
          font: {
            size: 10,
          },
        },
      },
    },
    elements: {
      bar: {
        borderSkipped: false, // No borders on top or bottom of bars
        borderRadius: 0, // Square edges for bars
      },
    },
  };

  console.log("managementData bar data", managementData?.mdConsultations);

  const managementBarData = managementData?.mdConsultations || [];
  const consultation =
    managementBarData.map((entry) => entry.consultation) || [];
  const dateOrMnth = managementBarData.map((entry) => entry.dateOrMnth) || [];
  const doctorConsulted =
    managementBarData.map((entry) => entry.doctorConsulted) || [];
  const repeatConsultation =
    managementBarData.map((entry) => entry.repeatConsultation) || [];
  const unitConsulted =
    managementBarData.map((entry) => entry.unitConsulted) || [];

  console.log("Consultation Data:", consultation);
  console.log("DateOrMnth Data:", dateOrMnth);
  console.log("DoctorConsulted Data:", doctorConsulted);
  console.log("RepeatConsultation Data:", repeatConsultation);
  console.log("UnitConsulted Data:", unitConsulted);

  // Bar Chart Data
  const barLineData = {
    // labels: [
    //   "Jan",
    //   "Feb",
    //   "Mar",
    //   "Apr",
    //   "May",
    //   "Jun",
    //   "Jul",
    //   "Aug",
    //   "Sep",
    //   "Oct",
    //   "Nov",
    //   "Dec",
    // ],
    labels: dateOrMnth,
    datasets: [
      {
        label: t("center"),
        type: "line",
        // data: [8, 4, 10, 8, 2, 4, 3, 7, 2, 5, 6, 9],
        data: unitConsulted,
        borderColor: "rgba(255, 206, 86, 1)",
        backgroundColor: "rgba(255, 206, 86, .5)",
        fill: false,
        tension: 0.3,
        hidden: true,
      },
      {
        label: t("repeat"),
        type: "line",
        // data: [150, 110, 130, 150, 120, 120, 180, 110, 160, 140, 120, 110],
        data: repeatConsultation,

        borderColor: "rgba(73, 245, 255, 1)",
        backgroundColor: "rgba(0, 158, 167,1)",
        fill: false,
        tension: 0.3,
      },
      {
        label: t("doctor"),
        type: "line",
        // data: [120, 140, 160, 170, 110, 130, 160, 140, 150, 120, 140, 150],
        data: doctorConsulted,
        borderColor: "rgba(106, 251, 154, 1)",
        backgroundColor: "rgba(0, 154, 51, 1)",
        fill: false,
        tension: 0.3,
        hidden: true,
      },
      {
        label: t("consultation"),
        backgroundColor: "rgba(153, 153, 153, 1)",
        borderColor: "rgba(206,206,206,1)",
        borderWidth: 1,
        borderRadius: 4,
        hoverBackgroundColor: "rgba(153, 153, 153,0.4)",
        hoverBorderColor: "rgba(95,104,103,1)",
        // data: [250, 210, 300, 350, 260, 200, 280, 310, 260, 240, 340, 280],
        data: consultation,
        barThickness: 16,
      },
    ],
  };

  const barLineOptions = {
    responsive: true,
    scales: {
      x: {
        display: true,
        grid: {
          color: "#545454",
        },
      },
      y: {
        display: true,
        grid: {
          color: "#545454",
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          boxWidth: 10,
          padding: 6,
        },
      },
    },
  };

  // const barLineOptions = {
  //   responsive: true,
  //   plugins: {
  //     datalabels: {
  //       display: false,
  //     },
  //     legend: {
  //       display: true,
  //       position: "bottom",
  //       labels: {
  //         boxWidth: 16,
  //         padding: 10,
  //       },
  //     },
  //     title: {
  //       display: true,
  //       text: "",
  //     },
  //   },
  //   barThickness: 10,
  //   maxBarThickness: 20,
  //   minBarLength: 2,
  // };
  const [filterBy, setFilterBy] = useState("Daily");
  const handleChangefilterop = (event) => {
    setFilterBy(event.target.value);
  };

  // const [filterByCenter, setFilterByCenter] = useState("allcenter");
  // const handleChangefilterCenter = (event) => {
  //   setFilterByCenter(event.target.value);
  // };

  // refreshManagementConsultation

  useEffect(() => {
    fetchRefreshManagementData();
  }, [selectUnits, filterBy]);

  const fetchRefreshManagementData = () => {
    if (filterBy && userDetails) {
      const reqDto = {
        unitId: selectUnits,
        orgId: userDetails.orgId,
        userId: userDetails.userId,
        periodDailyMnthly: filterBy,
        dataLimit: 12,
      };
      console.log("reqDto", reqDto);
      DashboardServices.refreshManagementConsultation(reqDto).then(
        (response) => {
          console.log("response", response.data);
          setManagementData(response.data);
        }
      );
    }
  };
  return (
    <>
    <Joyride
        steps={booksteps}
        run={run["manageConsultAD"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        styles={{
          buttonClose: {
            display: "none",
          },
        }}
      />
      <div className="anDashIndCard manageWidth50 msConsultation">
        <div className="anDashCardArea">
          <div className="andHeader ">
            <div className="andCrdNM ">
              {t("consultation")}
              <IconButton
                className={`refreshIcon msConsultationStepOne`}
                onClick={() => fetchRefreshManagementData()}
              >
                <ArrowRepeat />
              </IconButton>
            </div>
            <div className="andCrdUnitCount cardFrom">
              <FormControl size="small" className="formControl w110 msConsultationStepTwo">
                <Select
                  className="formInputField"
                  labelId="filterBy"
                  id="filterBy-select"
                  value={selectUnits}
                  onChange={handleChangeUnits}
                >
                  {/* <MenuItem value={"allcenter"}>All Center</MenuItem>
                  <MenuItem value={"cen1"}>Center One</MenuItem>
                  <MenuItem value={"cen2"}>Center Two</MenuItem>
                  <MenuItem value={"cen3"}>Center Three</MenuItem> */}
                  <MenuItem key={0} value={0}>
                    {t("all")}
                  </MenuItem>
                  {unitsData.map((unitsData) => (
                    <MenuItem key={unitsData.unitId} value={unitsData.unitId}>
                      {unitsData.unitName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl size="small" className="formControl msConsultationStepThree">
                <Select
                  className="formInputField"
                  labelId="filterBy"
                  id="filterBy-select"
                  value={filterBy}
                  onChange={handleChangefilterop}
                >
                  {/* <MenuItem value={"yearly"}>Yearly</MenuItem> */}
                  <MenuItem value={"Monthly"}>{t("monthly")}</MenuItem>
                  <MenuItem value={"Daily"}>{t("daily")}</MenuItem>
                </Select>
              </FormControl>
            </div>

            {showMoreBtn && (
              <IconButton
                className="openFullScrnIcon msConsultationStepFour"
                onMouseEnter={(e) => setShowMoreAnchorEl(e.currentTarget)}
                onMouseLeave={() => setShowMoreAnchorEl(null)}
                onClick={onOpenMore}
              >
                <ArrowsAngleExpand />
              </IconButton>
            )}
          </div>

          <Popover
            id="mouse-over-popover"
            sx={{ pointerEvents: "none" }}
            open={Boolean(showMoreAnchorEl)}
            anchorEl={showMoreAnchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={() => setShowMoreAnchorEl(null)}
            disableRestoreFocus
          >
            <div className="opFulScrn">{t("see_more")}</div>
          </Popover>

          <div className="andCrdGraf align-item-center">
            <div className="anDLftGrf msConsultationStepFive">
              <div className="listOfDataConst">
                <div className="todayIndc">{t("today")}</div>
                <ul>
                  <li>
                    <div className="lstDataInd">
                      <div className="lstName">{t("visit")}:</div>
                      <div className="lstValue list1">
                        {managementData?.mdConsultationVisitCount}
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="lstDataInd">
                      <div className="lstName">{t("consultation")}:</div>
                      <div className="lstValue list2">
                        {managementData?.mdConsultationConsultationCount}
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="lstDataInd">
                      <div className="lstName">{t("queue")}:</div>
                      <div className="lstValue list3">
                        {managementData?.mdConsultationQueueCount}
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="lstDataInd">
                      <div className="lstName">{t("repeat")}:</div>
                      <div className="lstValue list4">
                        {managementData?.mdConsultationRepeatCount}
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="lstDataInd">
                      <div className="lstName">{t("left")}:</div>
                      <div className="lstValue list5">
                        {managementData?.mdConsultationLeftCount}
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="lstDataInd">
                      <div className="lstName">{t("clinic")}:</div>
                      <div className="lstValue list6">
                        {managementData?.mdConsultationClinicCount}
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="lstDataInd">
                      <div className="lstName">{t("doctor")}:</div>
                      <div className="lstValue list7">
                        {managementData?.mdConsultationDoctorCount}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="anDRightGrf msConsultationStepSix">
              <Bar
                className="hw100"
                type="bar"
                data={barLineData}
                options={barLineOptions}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
