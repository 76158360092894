import React, { useEffect, useState } from "react";
import "../css/organization.css";
import PersonalPlan from "./component/PersonalPlan";
import ProfesionalPlan from "./component/ProfesionalPlan";
import PaymentModel from "./component/payment/PaymentModel";
import CreateOrganization from "./component/CreateOrganization";
import PaymentService from "../../../services/PaymentService";
import { GetLoginUserDetails } from "../../../utils/Utils";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import WebsiteTemplateService from "../../../services/WebsiteTemplateService";
import OnboardingSlider from "./component/OnboardingSlider";
import { toast } from "react-toastify";
import { stripeKey } from "../../../constants/url_provider";
import { useTranslation } from "react-i18next";

// const stripePromise = loadStripe(
//   "pk_test_51MHn49EJpxma8o1MhWPAn6yBo0o4qpMqDKf2bln8Rqe6TodwjPSRiW9QEiDpwbohrA6YBtlWfX8G8v47MmHNUEns00o447ysov"
// );

const stripePromise = loadStripe(stripeKey);

export default function SubscriptionOptions() {
  const { t } = useTranslation();
  const loginUserDetail = GetLoginUserDetails();
  const [editionList, setEditionList] = useState([]);
  const [showPersonalPlan, setPersonalPlan] = useState(true);
  const [showProPlan, setProPlan] = useState(false);
  const [showCreateOrgModel, setCreateOrgModel] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [editionId, setEditionId] = useState(2);
  const [discountedValue, setDiscountedValue] = useState(null);
  const [personalData, setPersonalData] = useState(null);
  const [professionalData, setProfessionalData] = useState(null);
  const [subscribed, setSubscribed] = React.useState(null);
  const [activeStep, setActiveStep] = React.useState(0);
  const [createdOrgDetails, setCreatedOrgDetails] = React.useState(false);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [onboardingData, setOnboardingData] = useState(null);

  const appearance = {
    theme: "stripe",
  };

  const options = {
    clientSecret,
    appearance,
  };

  useEffect(() => {
    let reqDto = {
      itemId: editionId,
      itemHeader: "SUBSCRIPTION",
      itemUnits: "",
      amtToPay: 1,
      recepientEmailId: loginUserDetail.userEmailId,
    };
    PaymentService.createPaymentIntent(
      loginUserDetail.userId,
      loginUserDetail.orgId,
      reqDto
    ).then((response) => {
      console.log(response.data);
      const obj = JSON.parse(response.data.paymentIntentRawJson);
      console.log(obj);
      if (response.data.returnValue === "1") {
        setClientSecret(response.data.clientSecret);
      } else {
        alert(t("something_went_wrong"));
      }
    });
    PaymentService.getEditionsWithPricingList(loginUserDetail.orgId).then(
      (response) => {
        const data = response.data;
        const personal = data[0];
        const professional = data[1];
        const personalProcessedData = processData(personal);
        setPersonalData(personalProcessedData);
        const professionalProcessedData = processData(professional);
        setProfessionalData(professionalProcessedData);
      }
    );
    PaymentService.checkIfEditionPurchased(loginUserDetail.userId).then(
      (response) => {
        console.log("checkIfEditionPurchased", response.data);
        if (response.data.returnValue === "1") {
          setSubscribed(true);
          setCreateOrgModel(true);
          setCreatedOrgDetails(response.data);
          setActiveStep(1);
          //write some code to check if the user has selected template
        } else {
          setShowOnboarding(false);
          // WebsiteTemplateService.getLatestOrgCreatedDtls(
          //   loginUserDetail.userId
          // ).then((response) => {
          //   console.log("getLatestOrgCreatedDtls", response.data);
          //   if (response.data && response.data.orgId) {
          //     setShowOnboarding(false);
          //     // setSubscribed(true);
          //     // setActiveStep(2);
          //     // setCreateOrgModel(true);
          //     // setShowOnboarding(true);
          //     // setCreatedOrgDetails(response.data);
          //     // toast.info(
          //     //   "You have already created organization. Thank You for onboarding."
          //     // );
          //   } else {
          //     // setSubscribed(false);
          //     setShowOnboarding(false);
          //   }
          // });
        }
      }
    );
  }, []);
  const processData = (data) => {
    const featureMap = new Map();
    const { featuresMapData, ...otherKeys } = data;
    console.log("******* feature map *********", featuresMapData);
    for (let i of featuresMapData) {
      const headerId = i.headerId;
      const mapVal = featureMap.get(headerId);
      if (mapVal) {
        mapVal.features.push(i.featureName);
        featureMap.set(headerId, mapVal);
      } else {
        featureMap.set(headerId, {
          headerIcon: i.headerIcon,
          headerName: i.headerName,
          features: [i.featureName],
        });
      }
    }
    return { otherKeys, featureMap };
  };

  const handelOrgOpenModel = () => {
    setCreateOrgModel(true);
    setShowPaymentModel(false);
  };
  const handelOrgCloseModel = () => {
    setCreateOrgModel(false);
  };

  const [showPaymentModel, setShowPaymentModel] = useState(false);
  const handlepaymentModelOpen = () => {
    setShowPaymentModel(true);
  };
  const handlepaymentModelClose = () => {
    setShowPaymentModel(false);
  };

  const handelClickShowPro = () => {
    setProPlan(true);
    setPersonalPlan(false);
  };
  const handelClickShowPersonal = () => {
    setProPlan(false);
    setPersonalPlan(true);
  };
  return (
    <>
      {!showOnboarding && (
        <div className="subcriptionContainerArea">
          <div className="subcriptionContainer">
            <div className="showActivePlan">
              {showPersonalPlan && (
                <PersonalPlan
                  activeStep={activeStep}
                  personalData={personalData}
                  professionalData={professionalData}
                  handelClickShowPro={handelClickShowPro}
                  handlepaymentModelOpen={handlepaymentModelOpen}
                  handelOrgOpenModel={handelOrgOpenModel}
                />
              )}
              {showProPlan && (
                <ProfesionalPlan
                  activeStep={activeStep}
                  personalData={personalData}
                  professionalData={professionalData}
                  handelClickShowPersonal={handelClickShowPersonal}
                  handlepaymentModelOpen={handlepaymentModelOpen}
                  handelOrgOpenModel={handelOrgOpenModel}
                />
              )}
            </div>
          </div>
        </div>
      )}

      {showPaymentModel && (
        <Elements options={options} stripe={stripePromise}>
          <PaymentModel
            showPaymentModel={showPaymentModel}
            handlepaymentModelClose={handlepaymentModelClose}
            handelOrgOpenModel={handelOrgOpenModel}
            setActiveStep={setActiveStep}
          />
        </Elements>
      )}

      {showCreateOrgModel && (
        <CreateOrganization
          showCreateOrgModel={showCreateOrgModel}
          handelOrgCloseModel={handelOrgCloseModel}
          setShowOnboarding={setShowOnboarding}
          setCreateOrgModel={setCreateOrgModel}
          setOnboardingData={setOnboardingData}
        />
      )}
      {showOnboarding && <OnboardingSlider onboardingData={onboardingData} />}
    </>
  );
}
