import {
  DescriptionOutlined,
  NoteAltOutlined,
  CheckCircle,
  Verified,
  CancelRounded,
  Add,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  IconButton,
  MenuItem,
  Modal,
  Popover,
  Select,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Plus, Sticky, Trash } from "react-bootstrap-icons";
// import ReactDatePicker from "react-datepicker";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import userImg1 from "../images/2.jpg";
import userImg2 from "../images/3.jpg";
import userImg3 from "../images/4.jpg";
import userImg4 from "../images/5.jpg";
import {
  addParticipants,
  addPrivateNotes,
  changeConvenerChairperson,
  deletePrivateNotes,
  getChairpersonOrConvenerList,
  getEventParticipants,
  getPrivateNotes,
  removeParticipantOfEvent,
  rescheduleEvent,
} from "../../../../../../services/MeetingService";
import {
  GetLoginUserDetails,
  validateEmail,
} from "../../../../../../utils/Utils";
import { BadgeCheck } from "iconoir-react";
import { getChatAllContacts } from "../../../../../../services/ChatService";
import { toast } from "react-toastify";
import { meetingSocket, schedulerSocket } from "../../../../../../socket";
import {
  MEETING_TYPES_WITH_CHAIRPERSON,
  NO_PROFILE_IMAGE,
} from "../../../../../../constants/const_string";
import axios from "axios";
import { socketEndPoint } from "../../../../../../constants/url_provider";
import DateUtils from "../../../../../../utils/DateUtils";
import AppUtils from "../../../../../../utils/AppUtils";
import { sendMeetingInviteToExternalUsers } from "../../../../../../services/SchedulerService";
import { useTranslation } from "react-i18next";
import { enIN, es } from "date-fns/locale";

// Register locales with ReactDatePicker
registerLocale("en", enIN);
registerLocale("es", es);

export default function MeetingsEdits({
  editMode,
  selectedEvent,
  setSelectedEvent,
  saveChangesClicked,
  saveBtnRef,
  setEditMode,
  setSaveChangesClicked,
  closeFromDashboard,
  setShowTopOptions,
  checkForMeetingEdits,
  setCheckForMeetingEdits,
  setPendingActions,
  pendingActions,
}) {
  const loginUser = GetLoginUserDetails();
  const { t, i18n } = useTranslation();

  //////////////////////////////////////////////////////

  // Determine the locale based on the current language
  const currentLocale = i18n.language === "es" ? "es" : "en";

  const [anchorEl, setAnchorEl] = useState(null);

  const openPopover = Boolean(anchorEl);
  const popoverId = openPopover ? "simple-popover" : undefined;

  const [meetingParticipants, setMeetingParticipants] = useState([]);
  const [newContactButton, setNewContactButton] = useState(false);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [guestData, setGuestData] = useState([
    { guestName: "", guestEmail: "" },
  ]);
  const [loaderForGuest, setLoaderForGuest] = useState(false);

  const handleNewContactButton = (event) => {
    setAnchorEl(event.currentTarget);
    setNewContactButton(true);
  };

  const handleCloseNeCwontactButtonModal = () => {
    setAnchorEl(null);
    setNewContactButton(false);
    setUserName("");
    setUserEmail("");
  };

  const handleUserNameChange = (event) => {
    console.log("handleUserNameChange", event.target.value);
    setUserName(event.target.value);
  };

  const handleUserEmailChange = (event) => {
    setUserEmail(event.target.value);
  };

  const handleFormSubmit = () => {
    setLoaderForGuest(true);

    if (userName.trim() === "") {
      toast.error(t("please_enter_user_name"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoaderForGuest(false);
      return;
    }

    if (userEmail.trim() === "") {
      toast.error(t("please_enter_user_email"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoaderForGuest(false);
      return;
    }

    if (userEmail.trim() !== "" && !validateEmail(userEmail)) {
      toast.error(t("please_enter_valid_email"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoaderForGuest(false);
      return;
    }

    const isDuplicate = meetingParticipants.some(
      (participant) =>
        participant.userName === userName + " ( " + userEmail + " )" ||
        participant.userName.includes(userEmail)
    );

    if (isDuplicate) {
      toast.error(t("participant_with_this_email_already_exists"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoaderForGuest(false);
      return;
    }

    const newParticipant = {
      calledBy: "",
      chairperson: "",
      convener: "",
      id: null,
      orgId: "",
      outSide: "",
      profileImage:
        "https://ahlan-s3.s3.me-south-1.amazonaws.com/images/no-profile.png",
      userId: "",
      userName: userName + " ( " + userEmail + " )",
    };

    setMeetingParticipants([...meetingParticipants, newParticipant]);
    setNewContactButton(false);
    setAnchorEl(null);
    setUserName("");
    setUserEmail("");
    setLoaderForGuest(false);

    toast.success(t("new_guest_added_successfully"), {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  /////////////////////////////////////////////////////

  const [intervalId, setIntervalId] = useState(null);

  console.log("selectedEvent", selectedEvent);

  // useEffect(() => {
  //   if (intervalId == null) {
  //     let intervalIdTemp = setInterval(() => {
  //       var minutesDifference =
  //         (new Date(selectedEvent.eventStartTime) - new Date()) / (1000 * 60);
  //       if (minutesDifference <= 5) {
  //         toast.error(
  //           `You cannot change the ${selectedEvent.eventType.toLowerCase()} within 5 minutes or after the ${selectedEvent.eventType.toLowerCase()} start time`
  //         );
  //         setEditMode(false);
  //         return false;
  //       } else {
  //         return false;
  //       }
  //     }, 1000);
  //     setIntervalId(intervalIdTemp);
  //   }

  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, []);

  // const [meetingParticipants, setMeetingParticipants] = useState([]);
  const [userPrivateNotes, setUserPrivateNotes] = useState([]);

  const [meetingParticipantsDeleted, setMeetingParticipantsDeleted] = useState(
    []
  );
  const [userPrivateNotesDeleted, setUserPrivateNotesDeleted] = useState([]);

  const [metTitle, setMetTitle] = useState("UI Discussion");
  const [startDate, setStartDate] = useState(
    new Date(selectedEvent.eventStartTime)
  );
  const [endDate, setEndDate] = useState(new Date(selectedEvent.eventEndTime));
  // const [frmtime, setfrmtime] = useState([7.0]);

  const [fromTimeList, setFromTimeList] = useState([]);
  const [toTimeList, setToTimeList] = useState([]);
  const [fromTime, setFromTime] = useState(
    new Date(selectedEvent.eventStartTime).toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    })
  );
  const [toTime, setToTime] = useState(
    new Date(selectedEvent.eventEndTime).toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    })
  );
  const [startDay, setStartDay] = useState(
    new Date(selectedEvent.eventStartTime).toLocaleDateString("en-US", {
      weekday: "long",
    })
  );
  const [endDay, setEndDay] = useState(
    new Date(selectedEvent.eventEndTime).toLocaleDateString("en-US", {
      weekday: "long",
    })
  );

  const [allUsersList, setAllUsersList] = useState([]);
  const [selectedGuest, setSelectedGuest] = useState(null);
  const [selectedGuestInput, setSelectedGuestInput] = useState("");

  const selectfrmtimeChange = (event) => {
    // setfrmtime(event.target.value);
  };
  const [openAddAgenda, setAddAgenda] = useState(false);

  const handelOpenAgenda = () => {
    setAddAgenda(!openAddAgenda);
  };

  const handelCloseAgenda = () => {
    setAddAgenda(false);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openDelConf, setOpenDelConf] = React.useState(false);
  const handleOpenDelConf = () => setOpenDelConf(true);
  const handleCloseDelConf = () => setOpenDelConf(false);

  useEffect(() => {
    if (selectedEvent.eventSource === "google") {
      console.log("source", selectedEvent.eventSourceData);
      if (selectedEvent.eventSourceData.attendees) {
        let newList = selectedEvent.eventSourceData.attendees.map((obj) => ({
          userName: obj.email,
          convener: obj.organizer ? "Y" : "N",
          responseStatus: obj.responseStatus,
          profileImage: NO_PROFILE_IMAGE,
        }));
        setMeetingParticipants(newList);
      }
    } else {
      getEventParticipants(selectedEvent.eventId).then((response) => {
        let sortedData = [];
        if (response.data.length > 1) {
          // sortedData = response.data.sort(
          //   (a, b) => (b.convener === "Y") - (a.convener === "Y")
          // );
          // sortedData = response.data.sort((a, b) => {
          //   if (a.convener === "Y" && b.convener !== "Y") return -1;
          //   if (a.chairperson === "Y" && b.chairperson !== "Y") return -1;
          //   if (b.convener === "Y" && a.convener !== "Y") return 1;
          //   if (b.chairperson === "Y" && a.chairperson !== "Y") return 1;
          //   return 0;
          // });
          sortedData = response.data.sort((a, b) => {
            if (a.convener === "Y" && b.convener !== "Y") return -1;
            if (b.convener === "Y" && a.convener !== "Y") return 1;
            if (a.chairperson === "Y" && b.chairperson !== "Y") return -1;
            if (b.chairperson === "Y" && a.chairperson !== "Y") return 1;
            return 0;
          });
        } else {
          sortedData = response.data;
        }
        console.log("sortedData-->>>", sortedData);
        setMeetingParticipants(sortedData);
      });
    }
    if (selectedEvent.eventSource !== "google") {
      getPrivateNotes(loginUser.userId, selectedEvent.eventId).then(
        (response) => {
          setUserPrivateNotes(response.data);
        }
      );
    }

    if (isToday(new Date(selectedEvent.eventStartTime))) {
      setFromTimeList(
        generateTimeIntervalsRoundedTo15MinutesFromCurrentTimeAMPM(
          new Date(selectedEvent.eventStartTime)
        )
      );
    } else {
      let currentDate = new Date(selectedEvent.eventStartTime);
      currentDate.setHours(0, 0, 0, 0);
      setFromTimeList(
        generateTimeIntervalsRoundedTo15MinutesFromCurrentTimeAMPM(currentDate)
      );
    }

    if (isToday(new Date(selectedEvent.eventEndTime))) {
      setToTimeList(
        generateTimeIntervalsRoundedTo15MinutesFromCurrentTimeAMPM(
          new Date(selectedEvent.eventEndTime)
        )
      );
    } else {
      let currentDate = new Date(selectedEvent.eventEndTime);
      currentDate.setHours(0, 0, 0, 0);
      setToTimeList(
        generateTimeIntervalsRoundedTo15MinutesFromCurrentTimeAMPM(currentDate)
      );
    }

    // let endTimeStart = new Date(selectedEvent.eventEndTime);

    // endTimeStart.setMinutes(endTimeStart.getMinutes() + 15);

    // setToTimeList(
    //   generateTimeIntervalsRoundedTo15MinutesFromCurrentTimeAMPM(endTimeStart)
    // );

    if (selectedEvent.eventSource !== "google") {
      getChatAllContacts(loginUser.userId, loginUser.orgId).then((response) => {
        console.log("getChatAllContacts", response.data);
        setAllUsersList(response.data);
      });
    }

    console.log("fromTime", fromTime);
    console.log("toTime", toTime);
  }, [selectedEvent.eventId]);

  function generateTimeIntervalsRoundedTo15MinutesFromCurrentTimeAMPM(
    baseDate
  ) {
    const intervals = [];
    const totalMinutesInDay = 24 * 60;

    // Get the current date and time
    // const currentDate = new Date();
    const currentHours = baseDate.getHours();
    const currentMinutes = baseDate.getMinutes();

    // Calculate the total minutes from the current time to the end of the day
    const remainingMinutesInDay =
      totalMinutesInDay - (currentHours * 60 + currentMinutes);

    console.log(
      "remainingMinutesInDay",
      remainingMinutesInDay,
      currentHours,
      currentMinutes
    );

    // Calculate the next multiple of 15 minutes from the current time
    const nextMultipleOf15 = Math.ceil(currentMinutes / 15) * 15;
    console.log(nextMultipleOf15);

    for (
      let minutes = nextMultipleOf15;
      currentHours + Math.floor(minutes / 60) <= 23 && minutes % 60 <= 45;
      minutes += 15
    ) {
      const hours = Math.floor(minutes / 60);
      const mins = minutes % 60;

      let period = "AM";
      let formattedHours = currentHours + Math.floor(minutes / 60);

      if (formattedHours >= 12) {
        period = "PM";
        formattedHours = formattedHours === 12 ? 12 : formattedHours - 12;
      }

      const formattedTime = `${String(formattedHours).padStart(
        2,
        "0"
      )}:${String(mins).padStart(2, "0")} ${period}`;

      // console.log(minutes, hours, mins, formattedHours, period, formattedTime);
      intervals.push(formattedTime);
    }

    return intervals;
  }

  const add24HoursToDate = (date) => {
    if (date.getHours() >= 12) {
      let newDate = new Date(new Date(date).getTime() + 24 * 60 * 60 * 1000);
      // newDate.setDate(newDate.getDate() + 1);
      // console.log("add12HoursTDate 1", newDate);
      return newDate;
    } else {
      let newDate = new Date(date);
      newDate.setHours(newDate.getHours() + 24);
      // console.log("add12HoursTDate 2", newDate);
      return newDate;
    }
  };

  const isToday = (date) => {
    return (
      date.getFullYear() === new Date().getFullYear() &&
      date.getMonth() === new Date().getMonth() &&
      date.getDate() === new Date().getDate()
    );
  };

  const onChangeStartDate = (selectedDate) => {
    console.log("selectedDate Start", selectedDate);
    let allowedTime = [];
    if (isToday(selectedDate)) {
      selectedDate = new Date();
      allowedTime =
        generateTimeIntervalsRoundedTo15MinutesFromCurrentTimeAMPM(
          selectedDate
        );
      setFromTime(allowedTime[0]);
      setFromTimeList(allowedTime);
      setToTime(allowedTime[1]);
      setToTimeList(allowedTime.slice(1));
    } else {
      selectedDate.setHours(0, 0, 0, 0);
      allowedTime =
        generateTimeIntervalsRoundedTo15MinutesFromCurrentTimeAMPM(
          selectedDate
        );
      setFromTimeList(allowedTime);
      // setToTime(allowedTime[1]);
      // setToTimeList(allowedTime.slice(1));
    }

    setStartDate(selectedDate);
    setStartDay(
      selectedDate.toLocaleDateString("en-US", {
        weekday: "long",
      })
    );

    let fromTimeSplice = fromTime.match(/(\d+):(\d+) ([APMapm]{2})/);
    var hours = parseInt(fromTimeSplice[1], 10);
    var minutes = parseInt(fromTimeSplice[2], 10);
    var period = fromTimeSplice[3].toUpperCase();

    if (
      parseInt(fromTimeSplice[1], 10) === 11 &&
      parseInt(fromTimeSplice[2], 10) === 45 &&
      fromTimeSplice[3].toUpperCase() === "PM"
    ) {
      let endDateNew = new Date(selectedDate);
      endDateNew.setDate(endDateNew.getDate() + 1);
      endDateNew.setHours(0, 0, 0, 0);
      setEndDate(new Date(endDateNew).getTime() + 30 * 60000);
      setEndDay(
        endDateNew.toLocaleDateString("en-US", {
          weekday: "long",
        })
      );
    } else {
      if (period === "PM" && hours !== 12) {
        hours += 12;
      }
      let endDateNew = new Date(selectedDate);
      endDateNew.setHours(hours, minutes + 15, 0, 0);
      allowedTime =
        generateTimeIntervalsRoundedTo15MinutesFromCurrentTimeAMPM(endDateNew);
      setToTime(allowedTime[0]);
      setToTimeList(allowedTime);
      setEndDate(new Date(selectedDate).getTime() + 30 * 60000);
      setEndDay(
        selectedDate.toLocaleDateString("en-US", {
          weekday: "long",
        })
      );
    }
  };

  const onChangeStartTime = (selectedTime) => {
    console.log("onChangeStartTime", selectedTime);
    setFromTime(selectedTime);
    // Parse the time string using a regular expression
    var match = selectedTime.match(/(\d+):(\d+) ([APMapm]{2})/);

    if (match) {
      var hours = parseInt(match[1], 10);
      var minutes = parseInt(match[2], 10);
      var period = match[3].toUpperCase();
      console.log(hours, minutes, period);

      if (hours === 11 && minutes === 30 && period === "PM") {
        console.log("inside", hours, minutes, period);
        let currentDate = new Date(startDate);
        setEndDate(currentDate);
        setEndDay(
          currentDate.toLocaleDateString("en-US", {
            weekday: "long",
          })
        );
        setToTime("11:45 PM");
        setToTimeList(["11:45 PM"]);
      } else if (hours === 11 && minutes === 45 && period === "PM") {
        let currentDate = new Date(startDate);
        currentDate.setHours(0, 0, 0, 0);
        currentDate.setDate(currentDate.getDate() + 1);
        let allowedTime =
          generateTimeIntervalsRoundedTo15MinutesFromCurrentTimeAMPM(
            currentDate
          );
        setEndDate(currentDate);
        setEndDay(
          currentDate.toLocaleDateString("en-US", {
            weekday: "long",
          })
        );
        setToTime(allowedTime[1]);
        setToTimeList(allowedTime);
      } else {
        if (period === "PM" && hours !== 12) {
          hours += 12;
        }
        var currentDate = new Date(startDate);
        currentDate.setHours(hours, minutes, 0, 0);
        let allowedTime =
          generateTimeIntervalsRoundedTo15MinutesFromCurrentTimeAMPM(
            currentDate
          );
        setToTime(allowedTime[1]);
        setToTimeList(allowedTime.slice(1));
        setEndDate(currentDate);
        setEndDay(
          currentDate.toLocaleDateString("en-US", {
            weekday: "long",
          })
        );
      }
    } else {
      console.log("Invalid time format");
    }
  };

  const checkIfTwoDateAreSame = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };

  const onChangeEndDate = (selectedDate) => {
    console.log("selectedDate", selectedDate);
    setEndDate(selectedDate);
    setEndDay(
      selectedDate.toLocaleDateString("en-US", {
        weekday: "long",
      })
    );

    let fromTimeSplice = fromTime.match(/(\d+):(\d+) ([APMapm]{2})/);
    let fromTimeHours = parseInt(fromTimeSplice[1], 10);
    let fromTimeMinutes = parseInt(fromTimeSplice[2], 10);
    let fromTimePeriod = fromTimeSplice[3].toUpperCase();

    if (checkIfTwoDateAreSame(selectedDate, startDate)) {
      if (
        fromTimeHours === 11 &&
        fromTimeMinutes === 45 &&
        fromTimePeriod === "PM"
      ) {
        //check here else handle while selecting from date
      } else {
        if (fromTimePeriod === "PM") {
          fromTimeHours += 12;
        }
        selectedDate.setHours(fromTimeHours, fromTimeMinutes + 15, 0, 0);
        let allowedTime =
          generateTimeIntervalsRoundedTo15MinutesFromCurrentTimeAMPM(
            selectedDate
          );

        setToTime(allowedTime[0]);
        setToTimeList(allowedTime);
      }
    } else {
      selectedDate.setHours(0, 0, 0, 0);
      let allowedTime =
        generateTimeIntervalsRoundedTo15MinutesFromCurrentTimeAMPM(
          selectedDate
        );

      // setToTime(allowedTime[0]);
      setToTimeList(allowedTime);
    }
  };

  const onChangeEndTime = (selectedTime) => {
    setToTime(selectedTime);
  };

  const [selectedParticipant, setSelectedParticipant] = React.useState(null);
  //remove participant pop over

  const [anchorElRP, setAnchorElRP] = React.useState(null);

  const handleClickRP = (event) => {
    setAnchorElRP(event.currentTarget);
  };

  const handleCloseRP = () => {
    setAnchorElRP(null);
  };

  const openRP = Boolean(anchorElRP);
  const idRP = openRP ? "simple-popover" : undefined;
  const buttonRPRef = useRef(null);
  const buttonRPCancelRef = useRef(null);
  const buttonApplyForAllRec = useRef(null);
  const buttonApplyForAllRecReschedule = useRef(null);
  const buttonApplyForAllRecAddParticipant = useRef(null);
  const buttonApplyForAllRecRemoveParticipant = useRef(null);
  //remove participant pop over
  const [loadingRP, setLoadingRP] = useState(false);
  const removeParticipant = () => {
    // console.log(selectedParticipant, buttonRPForAllRec.current.checked);
    buttonRPRef.current.disabled = true;
    buttonRPRef.current.style.backgroundColor = "gray !important";
    buttonRPRef.current.textContent = t("please_wait");
    buttonRPRef.current.style.cursor = "not-allowed";

    buttonRPCancelRef.current.style.display = "none";
    // buttonRPForAllRec.current.style.display = "none";
    // buttonRPForAllRec.current.disabled = true;
    // return;
    if (selectedParticipant.id) {
      // removeParticipantOfEvent(
      //   selectedParticipant.id,
      //   selectedParticipant.userId,
      //   selectedEvent.eventType,
      //   buttonRPForAllRec.current.checked ? "Y" : "N"
      // ).then((response) => {
      //   console.log("removeParticipant", response.data);
      //   if (response.data.returnValue === "1") {
      //     const updatedList = meetingParticipants.filter(
      //       (participantDto) => participantDto.id !== selectedParticipant.id
      //     );
      //     setMeetingParticipants(updatedList);
      //     setAnchorElRP(null);
      //   }
      // });
      const updatedList = meetingParticipants.filter(
        (participantDto) => participantDto.id !== selectedParticipant.id
      );
      setMeetingParticipants(updatedList);
      setAnchorElRP(null);
      if (
        selectedEvent.eventType === "Recurring Event" &&
        buttonApplyForAllRecRemoveParticipant &&
        buttonApplyForAllRecRemoveParticipant.current &&
        buttonApplyForAllRecRemoveParticipant.current.checked
      ) {
        selectedParticipant.applyForAllRecurringEvents = "Y";
      } else {
        selectedParticipant.applyForAllRecurringEvents = "N";
      }

      setMeetingParticipantsDeleted((prevList) => [
        ...prevList,
        selectedParticipant,
      ]);
    } else {
      // const updatedList = meetingParticipants.filter(
      //   (participantDto) => participantDto.userId !== selectedParticipant.userId
      // );
      // setMeetingParticipants(updatedList);
      // setAnchorElRP(null);
      if (selectedParticipant.userId === "") {
        const updatedList = meetingParticipants.filter(
          (participantDto) =>
            participantDto.userName !== selectedParticipant.userName
        );
        setMeetingParticipants(updatedList);
        setAnchorElRP(null);
      } else {
        const updatedList = meetingParticipants.filter(
          (participantDto) =>
            participantDto.userId !== selectedParticipant.userId
        );
        setMeetingParticipants(updatedList);
        setAnchorElRP(null);
      }
    }
  };

  const onClickDelPvtNote = (event, eventNoteDto) => {
    console.log(event, eventNoteDto);
    event.target.disabled = true;

    // return;
    if (eventNoteDto.eventNoteId) {
      // deletePrivateNotes(loginUser.userId, eventNoteDto.eventNoteId).then(
      //   (response) => {
      //     console.log(response.data);
      //     const updatedList = userPrivateNotes.filter(
      //       (userPrivateNoteDto) =>
      //         userPrivateNoteDto.eventNoteId !== eventNoteDto.eventNoteId
      //     );
      //     setUserPrivateNotes(updatedList);
      //   }
      // );
      const updatedList = userPrivateNotes.filter(
        (userPrivateNoteDto) =>
          userPrivateNoteDto.eventNoteId !== eventNoteDto.eventNoteId
      );
      setUserPrivateNotes(updatedList);
      setUserPrivateNotesDeleted((prevList) => [...prevList, eventNoteDto]);
    } else {
      const updatedList = userPrivateNotes.filter(
        (userPrivateNoteDto) =>
          userPrivateNoteDto.notingTime !== eventNoteDto.notingTime
      );
      setUserPrivateNotes(updatedList);
    }
  };

  //add pvt note pop over

  const [anchorElPN, setAnchorElPN] = React.useState(null);

  const handleClickPN = (event) => {
    setAnchorElPN(event.currentTarget);
  };

  const handleClosePN = () => {
    setAnchorElPN(null);
  };

  const openPN = Boolean(anchorElPN);
  const idPN = openPN ? "add-pvtNotePopOver" : undefined;
  // const [openAddPvtNote, setOpenAddPvtNote] = useState(false);

  // const handleOpenAddPvtNote = () => {
  //   setOpenAddPvtNote(true);
  // };

  // const handleClosePvtNote = () => {
  //   setOpenAddPvtNote(false);
  // };

  const addPNRef = useRef(null);
  const cancelPNRef = useRef(null);
  const inputPNRef = useRef(null);
  const [pvtNote, setPvtNote] = useState(null);

  const onClickAddPvtNote = () => {
    console.log(pvtNote);
    if (pvtNote === null || pvtNote.trim() === "") {
      console.log("pvtNote");
      inputPNRef.current.style.borderColor = "red";
      inputPNRef.current.placeholder = "Please add note...";
      return;
    }
    // return;
    addPNRef.current.disabled = true;
    const userNotesDto = {
      eventNoteId: null,
      notes: pvtNote,
      notingTime: new Date().getTime(),
    };
    setUserPrivateNotes((prevList) => [...prevList, userNotesDto]);
    handleClosePN();
    setPvtNote(null);
    // setAnchorElPN(null);
    return;

    addPrivateNotes(loginUser.userId, selectedEvent).then((response) => {
      console.log(response.data);
      getPrivateNotes(loginUser.userId, selectedEvent.eventId).then(
        (response) => {
          setUserPrivateNotes(response.data);
        }
      );
    });
  };

  const [newConvenerUserId, setNewConvenerUserId] = useState(null);
  const [newConvenerPID, setNewConvenerPID] = useState(null);
  const [newChairpersonUserId, setNewChairpersonUserId] = useState(null);
  const [newChairpersonPID, setNewChairpersonPID] = useState(null);
  const makeConvenor = (participantDto) => {
    console.log(participantDto);

    if (newConvenerUserId) {
      console.log(newConvenerUserId, participantDto.userId);
      setNewConvenerUserId(participantDto.userId);
      setNewConvenerPID(participantDto.id);
      setMeetingParticipants((prevList) => {
        return prevList.map((item) => {
          if (item.userId === newConvenerUserId) {
            // Return a new object with the updated property
            return { ...item, convener: "N" };
          }
          // Return the unchanged object
          return item;
        });
      });
      setMeetingParticipants((prevList) => {
        return prevList.map((item) => {
          if (item.userId === participantDto.userId) {
            // Return a new object with the updated property
            return { ...item, convener: "Y" };
          }
          // Return the unchanged object
          return item;
        });
      });
    } else {
      getChairpersonOrConvenerList(selectedEvent.eventId, "convener").then(
        (response) => {
          console.log(response.data);
          let exConUserId = response.data[0].userId;
          console.log(exConUserId, participantDto.userId);
          // return;
          setNewConvenerUserId(participantDto.userId);
          setNewConvenerPID(participantDto.id);
          setMeetingParticipants((prevList) => {
            return prevList.map((item) => {
              if (item.userId === exConUserId) {
                // Return a new object with the updated property
                return { ...item, convener: "N" };
              }
              // Return the unchanged object
              return item;
            });
          });
          setMeetingParticipants((prevList) => {
            return prevList.map((item) => {
              if (item.userId === participantDto.userId) {
                // Return a new object with the updated property
                return { ...item, convener: "Y" };
              }
              // Return the unchanged object
              return item;
            });
          });
        }
      );
    }
  };

  const makeChairperson = (participantDto) => {
    console.log(participantDto);

    if (newChairpersonUserId) {
      console.log(newChairpersonUserId, participantDto.userId);
      setNewChairpersonUserId(participantDto.userId);
      setNewChairpersonPID(participantDto.id);
      setMeetingParticipants((prevList) => {
        return prevList.map((item) => {
          if (item.userId === newChairpersonUserId) {
            // Return a new object with the updated property
            return { ...item, chairperson: "N" };
          }
          // Return the unchanged object
          return item;
        });
      });
      setMeetingParticipants((prevList) => {
        return prevList.map((item) => {
          if (item.userId === participantDto.userId) {
            // Return a new object with the updated property
            return { ...item, chairperson: "Y" };
          }
          // Return the unchanged object
          return item;
        });
      });
    } else {
      getChairpersonOrConvenerList(selectedEvent.eventId, "chairperson").then(
        (response) => {
          console.log(response.data);

          setNewChairpersonUserId(participantDto.userId);
          setNewChairpersonPID(participantDto.id);

          if (response.data && response.data.length > 0) {
            let exChairPerUserId = response.data[0].userId;
            console.log(exChairPerUserId, participantDto.userId);
            setMeetingParticipants((prevList) => {
              return prevList.map((item) => {
                if (item.userId === exChairPerUserId) {
                  // Return a new object with the updated property
                  return { ...item, chairperson: "N" };
                }
                // Return the unchanged object
                return item;
              });
            });
          }

          setMeetingParticipants((prevList) => {
            return prevList.map((item) => {
              if (item.userId === participantDto.userId) {
                // Return a new object with the updated property
                return { ...item, chairperson: "Y" };
              }
              // Return the unchanged object
              return item;
            });
          });
        }
      );
    }
  };

  // const saveBtnRef = useRef(null);
  // const cancelBtnRef = useRef(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("muntaj useEffect =", selectedEvent);
    // const
  }, []);

  useEffect(() => {
    var minutesDifference =
      (new Date(selectedEvent.eventStartTime) - new Date()) / (1000 * 60);

    if (minutesDifference <= 5) {
      // toast.error(
      //   `You cannot save any changes to the ${selectedEvent.eventType.toLowerCase()} within 5 minutes or after the ${selectedEvent.eventType.toLowerCase()} start time`
      // );
      toast.error(
        t("cannotSaveChanges", {
          eventType: selectedEvent.eventType.toLowerCase(),
        }),
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      return;
    }

    if (saveChangesClicked) {
      if (selectedEvent.eventSource !== "google") {
        saveChangesMade();
      } else {
        saveChangesMadeGoogle();
      }
    }
  }, [saveChangesClicked]);

  const saveChangesMadeGoogle = async () => {
    let orgStartTime = new Date(selectedEvent.eventStartTime);
    let orgEndTIme = new Date(selectedEvent.eventEndTime);

    let newStartTime = new Date(startDate);
    let fromTimeSplice = fromTime.match(/(\d+):(\d+) ([APMapm]{2})/);
    let fromHours = parseInt(fromTimeSplice[1], 10);
    var fromMinutes = parseInt(fromTimeSplice[2], 10);
    var fromPeriod = fromTimeSplice[3].toUpperCase();
    if (fromPeriod.toUpperCase() === "PM" && fromHours !== 12) {
      fromHours += 12;
    }
    newStartTime.setHours(fromHours, fromMinutes, 0, 0);

    let newEndTime = new Date(endDate);
    let toTimeSplice = toTime.match(/(\d+):(\d+) ([APMapm]{2})/);
    let toHours = parseInt(toTimeSplice[1], 10);
    var toMinutes = parseInt(toTimeSplice[2], 10);
    var toPeriod = toTimeSplice[3].toUpperCase();
    if (toPeriod.toUpperCase() === "PM" && fromHours !== 12) {
      toHours += 12;
    }
    newEndTime.setHours(toHours, toMinutes, 0, 0);

    if (
      orgStartTime.getTime() !== newStartTime.getTime() ||
      orgEndTIme.getTime() !== newEndTime.getTime()
    ) {
      console.log("reschedule");

      if (rescheduleReason == null || rescheduleReason === "") {
        toast.error(t("please_enter_the_reason_for_rescheduling_the_meeting"));
        setSaveChangesClicked(false);
        return;
      }
    }

    toast.info(t("saving_changes_please_wait"));
    saveBtnRef.current.disabled = true;
    saveBtnRef.current.style.backgroundColor = "gray !important";
    saveBtnRef.current.textContent = t("please_wait");
    saveBtnRef.current.style.cursor = "not-allowed";
    setShowTopOptions(false);
    setLoading(true);
    let eventType =
      selectedEvent.eventType === "Recurring Event"
        ? "Event"
        : selectedEvent.eventType;

    //reschedule

    if (
      orgStartTime.getTime() !== newStartTime.getTime() ||
      orgEndTIme.getTime() !== newEndTime.getTime()
    ) {
      selectedEvent.eventSourceData.start.dateTime = newStartTime;
      selectedEvent.eventSourceData.start.timeZone =
        DateUtils.getFullTimeZoneName();
      selectedEvent.eventSourceData.end.dateTime = newEndTime;
      selectedEvent.eventSourceData.end.timeZone =
        DateUtils.getFullTimeZoneName();
      // let reqDto = {
      //   fromDate: newStartTime,
      //   toDate: newEndTime,

      // };
      console.log(
        " selectedEvent.eventSourceData.",
        selectedEvent.eventSourceData
      );

      let reqDto = {
        modifiedEventDto: selectedEvent.eventSourceData,
      };
      // return;
      const respUpdateEvent = await axios.put(
        `${socketEndPoint}/api/gcal/updateEvent/${loginUser.userId}/${selectedEvent.eventId}`,
        reqDto
      );
      console.log("respUpdateEvent", respUpdateEvent);

      if (respUpdateEvent.data.returnValue === "1") {
        setSaveChangesClicked(false);
        setEditMode(false);
        setLoading(false);
        setShowTopOptions(true);
        closeFromDashboard("refreshNC");
        toast.success(t("event_updated_successfully"));
      } else {
        // toast.success(respUpdateEvent.data.message);
        toast.error(t("something_went_wrong"));
        setLoading(false);
        setShowTopOptions(true);
        setSaveChangesClicked(false);
      }
    }
  };

  const checkIfParametersModified = () => {
    let returnObj = {
      rescheduled: false,
      participantsAdded: false,
      participantsDeleted: false,
      privateNotesAdded: false,
      privateNotesDeleted: false,
      convenorChanged: false,
      chairpersonChanged: false,
    };

    let orgStartTime = new Date(selectedEvent.eventStartTime);
    let orgEndTIme = new Date(selectedEvent.eventEndTime);

    let newStartTime = new Date(startDate);
    let fromTimeSplice = fromTime.match(/(\d+):(\d+) ([APMapm]{2})/);
    let fromHours = parseInt(fromTimeSplice[1], 10);
    var fromMinutes = parseInt(fromTimeSplice[2], 10);
    var fromPeriod = fromTimeSplice[3].toUpperCase();
    if (fromPeriod.toUpperCase() === "PM" && fromHours !== 12) {
      fromHours += 12;
    }
    newStartTime.setHours(fromHours, fromMinutes, 0, 0);

    let newEndTime = new Date(endDate);
    let toTimeSplice = toTime.match(/(\d+):(\d+) ([APMapm]{2})/);
    let toHours = parseInt(toTimeSplice[1], 10);
    var toMinutes = parseInt(toTimeSplice[2], 10);
    var toPeriod = toTimeSplice[3].toUpperCase();
    if (toPeriod.toUpperCase() === "PM" && fromHours !== 12) {
      toHours += 12;
    }
    newEndTime.setHours(toHours, toMinutes, 0, 0);

    if (
      orgStartTime.getTime() !== newStartTime.getTime() ||
      orgEndTIme.getTime() !== newEndTime.getTime()
    ) {
      returnObj.rescheduled = true;
    }

    let newParticipants = meetingParticipants.filter(
      (participantDto) => participantDto.id == null
    );
    if (newParticipants.length > 0) {
      returnObj.participantsAdded = true;
    }

    console.log("checkIfParametersModified", newConvenerPID);
    if (newConvenerPID) {
      returnObj.convenorChanged = true;
    }

    if (newChairpersonPID) {
      returnObj.chairpersonChanged = true;
    }

    if (
      meetingParticipantsDeleted != null &&
      meetingParticipantsDeleted.length > 0
    ) {
      returnObj.participantsDeleted = true;
    }

    if (userPrivateNotes.findIndex((dto) => dto.eventNoteId === null) !== -1) {
      returnObj.privateNotesAdded = true;
    }

    if (userPrivateNotesDeleted.length > 0) {
      returnObj.privateNotesDeleted = true;
    }

    return returnObj;
  };

  const saveChangesMade = async () => {
    let orgStartTime = new Date(selectedEvent.eventStartTime);
    let orgEndTIme = new Date(selectedEvent.eventEndTime);

    let newStartTime = new Date(startDate);
    let fromTimeSplice = fromTime.match(/(\d+):(\d+) ([APMapm]{2})/);
    let fromHours = parseInt(fromTimeSplice[1], 10);
    var fromMinutes = parseInt(fromTimeSplice[2], 10);
    var fromPeriod = fromTimeSplice[3].toUpperCase();
    if (fromPeriod.toUpperCase() === "PM" && fromHours !== 12) {
      fromHours += 12;
    }
    newStartTime.setHours(fromHours, fromMinutes, 0, 0);

    let newEndTime = new Date(endDate);
    let toTimeSplice = toTime.match(/(\d+):(\d+) ([APMapm]{2})/);
    let toHours = parseInt(toTimeSplice[1], 10);
    var toMinutes = parseInt(toTimeSplice[2], 10);
    var toPeriod = toTimeSplice[3].toUpperCase();
    if (toPeriod.toUpperCase() === "PM" && fromHours !== 12) {
      toHours += 12;
    }
    newEndTime.setHours(toHours, toMinutes, 0, 0);

    if (
      orgStartTime.getTime() !== newStartTime.getTime() ||
      orgEndTIme.getTime() !== newEndTime.getTime()
    ) {
      console.log("reschedule");

      if (rescheduleReason == null || rescheduleReason === "") {
        toast.error(t("please_enter_the_reason_for_rescheduling_the_meeting"));
        setSaveChangesClicked(false);
        return;
      }
    }

    const returnObj = checkIfParametersModified();

    let textToDisplay = "";
    if (returnObj.rescheduled) {
      textToDisplay += "Meeting rescheduled, ";
    }
    if (returnObj.participantsAdded) {
      textToDisplay += "Participants added, ";
    }
    if (returnObj.participantsDeleted) {
      textToDisplay += "Participants removed, ";
    }
    if (returnObj.convenorChanged) {
      textToDisplay += "Convener changed, ";
    }
    if (returnObj.chairpersonChanged) {
      textToDisplay += "Chairperson changed, ";
    }
    if (returnObj.privateNotesAdded) {
      textToDisplay += "Private notes added, ";
    }
    if (returnObj.privateNotesDeleted) {
      textToDisplay += "Private notes deleted, ";
    }

    if (textToDisplay !== "") {
      textToDisplay.substring(0, textToDisplay.length - 1);
    }

    // toast.info(`Saving following changes, ${textToDisplay} please wait...`);
    toast.info(t("savingChanges", { textToDisplay }), {
      position: toast.POSITION.TOP_RIGHT,
    });
    saveBtnRef.current.disabled = true;
    saveBtnRef.current.style.backgroundColor = "gray !important";
    saveBtnRef.current.textContent = t("please_wait");
    saveBtnRef.current.style.cursor = "not-allowed";
    setShowTopOptions(false);
    setLoading(true);
    let eventType =
      selectedEvent.eventType === "Recurring Event"
        ? "Event"
        : selectedEvent.eventType;

    // Filter out participants without userId and update guestData state
    let validParticipants = meetingParticipants.filter(
      (participant) => participant.userId !== ""
    );
    let guestParticipants = meetingParticipants.filter(
      (participant) => participant.userId === ""
    );

    if (guestParticipants.length > 0) {
      const guestDataArray = guestParticipants.map((participant) => {
        const parts = participant.userName.split("(");
        const guestName = parts[0].trim();
        const guestEmail = parts[1].split(")")[0].trim();
        return { guestName, guestEmail };
      });
      console.log("guestDataArray", guestDataArray);
      // setGuestData(guestDataArray);
      const invitePromises = guestDataArray.map((guest) => {
        const newGuestReqDto = {
          personName: guest.guestName,
          officialEmailId: guest.guestEmail,
        };

        return sendMeetingInviteToExternalUsers(
          selectedEvent.eventLink,
          loginUser.userId,
          newGuestReqDto
        ).then((response) => {
          console.log("guest response.data", response.data);
          if (response.data.returnValue !== "1") {
            throw new Error(response.data.message);
          }
          return response.data.message;
        });
      });

      Promise.all(invitePromises)
        .then((messages) => {
          // Show a single success message
          toast.success(t("all_guests_added_successfully"), {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .catch((error) => {
          console.error("Error sending invites:", error);
          toast.error(t("failed_to_add_some_guests"), {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }

    //reschedule

    if (
      orgStartTime.getTime() !== newStartTime.getTime() ||
      orgEndTIme.getTime() !== newEndTime.getTime()
    ) {
      let reqDto = {
        eventId: selectedEvent.eventId,
        length: (newEndTime - newStartTime) / (1000 * 60),
        startTime: newStartTime,
        endTime: newEndTime,
        rescheduleReason: rescheduleReason,
      };
      console.log(reqDto);
      await rescheduleEvent(loginUser.userId, reqDto).then((response) => {
        console.log("rescheduleEvent--->>>>", response.data);
        let newData = selectedEvent;
        newData.eventStartTime = newStartTime;
        newData.eventEndTime = newEndTime;
        setSelectedEvent(newData);
      });
    }

    // return;
    let newParticipants = validParticipants.filter(
      (participantDto) => participantDto.id == null
    );
    if (newParticipants.length > 0) {
      console.log("new participants");
      const updatedParticipants = newParticipants.map((participant) => ({
        ...participant,
        convener: "N",
        chairperson: "N",
      }));
      await addParticipants(
        selectedEvent.eventId,
        loginUser.userId,
        eventType,
        buttonApplyForAllRecAddParticipant.current
          ? buttonApplyForAllRecAddParticipant.current?.checked
            ? "Y"
            : "N"
          : "N",
        updatedParticipants
      ).then((response) => {
        console.log("1", new Date());
        console.log(response);
      });
    }

    let newEventParticipants = newParticipants;
    if (newParticipants.length > 0) {
      newEventParticipants = await getEventParticipants(selectedEvent.eventId);
    }

    if (newConvenerPID) {
      await changeConvenerChairperson(selectedEvent.eventId, loginUser.userId, {
        changeToEventParticipantsId: newConvenerPID,
        type: "convener",
      });
    } else if (newParticipants.length > 0 && newConvenerUserId != null) {
      const newConvDto = newEventParticipants.data.find(
        (user) => user.userId === newConvenerUserId
      );

      await changeConvenerChairperson(selectedEvent.eventId, loginUser.userId, {
        changeToEventParticipantsId: newConvDto.id,
        type: "convener",
      });
    }

    if (newChairpersonPID) {
      await changeConvenerChairperson(selectedEvent.eventId, loginUser.userId, {
        changeToEventParticipantsId: newChairpersonPID,
        type: "chairperson",
      });
    } else if (newParticipants.length > 0 && newChairpersonUserId != null) {
      const newChairpersonDto = newEventParticipants.data.find(
        (user) => user.userId === newChairpersonUserId
      );
      await changeConvenerChairperson(selectedEvent.eventId, loginUser.userId, {
        changeToEventParticipantsId: newChairpersonDto.id,
        type: "chairperson",
      });
    }

    if (
      meetingParticipantsDeleted != null &&
      meetingParticipantsDeleted.length > 0
    ) {
      for (let index = 0; index < meetingParticipantsDeleted.length; index++) {
        const partDto = meetingParticipantsDeleted[index];
        let responseDelPar = await removeParticipantOfEvent(
          partDto.id,
          loginUser.userId,
          eventType,
          partDto.applyForAllRecurringEvents === "Y" ? "Y" : null
        );
        console.log(responseDelPar.data);
      }

      // meetingParticipantsDeleted.forEach(async (partDto) => {
      //   let responseDelPar = await removeParticipantOfEvent(
      //     partDto.id,
      //     loginUser.userId,
      //     eventType,
      //     partDto.applyForAllRecurringEvents === "Y" ? "Y" : null
      //   );
      //   console.log(responseDelPar.data);
      // });
    }

    //participants add

    //private notes add
    console.log(userPrivateNotes);
    await userPrivateNotes.forEach(async (noteDto) => {
      if (noteDto.eventNoteId == null) {
        let responseAddPvtNote = await addPrivateNotes(
          loginUser.userId,
          selectedEvent.eventId,
          noteDto.notes
        );
        console.log(responseAddPvtNote);
      }
    });

    //private notes add
    //private notes delete
    console.log(userPrivateNotesDeleted);
    userPrivateNotesDeleted.forEach(async (noteDto) => {
      let responseDeletePvtNote = await deletePrivateNotes(
        loginUser.userId,
        noteDto.eventNoteId
      );
      console.log(responseDeletePvtNote);
    });

    //private notes delete

    setSaveChangesClicked(false);

    setEditMode(false);
    setLoading(false);
    setShowTopOptions(true);
    closeFromDashboard("refreshNC");

    //send updates through socket
    try {
      meetingSocket.emit("update", {
        userId: selectedEvent.eventId,
        eventParticipants: meetingParticipants,
      });

      console.log("Here--->>>>>", "updateSchedulerOfEventParticipantsClient");

      schedulerSocket.emit("updateSchedulerOfEventParticipantsClient", {
        userId: selectedEvent.eventId,
        eventParticipants: meetingParticipants,
      });
    } catch (err) {
      console.log(err);
    }

    //reschedule
  };

  const [rescheduleReason, setRescheduleReason] = useState(null);
  useEffect(() => {
    let orgStartTime = new Date(selectedEvent.eventStartTime);
    let orgEndTIme = new Date(selectedEvent.eventEndTime);

    let newStartTime = new Date(startDate);
    let fromTimeSplice = fromTime.match(/(\d+):(\d+) ([APMapm]{2})/);
    let fromHours = parseInt(fromTimeSplice[1], 10);
    var fromMinutes = parseInt(fromTimeSplice[2], 10);
    var fromPeriod = fromTimeSplice[3].toUpperCase();
    if (fromPeriod.toUpperCase() === "PM" && fromHours !== 12) {
      fromHours += 12;
    }
    newStartTime.setHours(fromHours, fromMinutes, 0, 0);

    let newEndTime = new Date(endDate);
    let toTimeSplice = toTime.match(/(\d+):(\d+) ([APMapm]{2})/);
    let toHours = parseInt(toTimeSplice[1], 10);
    var toMinutes = parseInt(toTimeSplice[2], 10);
    var toPeriod = toTimeSplice[3].toUpperCase();
    if (toPeriod.toUpperCase() === "PM" && toHours !== 12) {
      toHours += 12;
    }
    newEndTime.setHours(toHours, toMinutes, 0, 0);

    console.log(orgStartTime, newStartTime);
    console.log(orgEndTIme, newEndTime);

    if (
      orgStartTime.getTime() !== newStartTime.getTime() ||
      orgEndTIme.getTime() !== newEndTime.getTime()
    ) {
      setRescheduleReason("");
    } else {
      setRescheduleReason(null);
    }
  }, [startDate, endDate, fromTime, toTime]);

  const searchUserFilteredList = useMemo(() => {
    let filtered = [];
    if (selectedGuestInput.length > 0 && selectedGuestInput.trim() !== "") {
      filtered = allUsersList.filter((option) =>
        option.userName.toLowerCase().includes(selectedGuestInput.toLowerCase())
      );
    }

    return filtered;
  }, [selectedGuestInput, allUsersList]);

  useEffect(() => {
    if (checkForMeetingEdits) {
      const returnObj = checkIfParametersModified();
      console.log("checkIfParametersModified", returnObj);
      setPendingActions(returnObj);
    }
  }, [checkForMeetingEdits]);

  return (
    <>
      <div className="meetingsEditedView">
        {!loading && (
          <div className="meetEditContainer">
            <div className="medtTitleDateTime">
              {editMode ? (
                <div
                  className="medtTitle"
                  style={{ color: "white", fontSize: "24px" }}
                >
                  {selectedEvent?.eventTitle}
                </div>
              ) : (
                <div className="medtTitle">
                  <input
                    type="text"
                    className="mtTextField"
                    placeholder={t("type_meeting_title")}
                    value={metTitle}
                    onChange={(e) => setMetTitle(e.target.value)}
                    style={{ width: `${metTitle.length}ch` }}
                  />
                </div>
              )}
              <div className="medDateTime">
                <div
                  className="frmDateTime"
                  style={{
                    display: "flex !important",
                    flexWrap: "wrap",
                    justifyContent: "center !important",
                  }}
                >
                  <div className="frmDateInput">
                    <div className="frmDaysPick">{startDay},</div>
                    {/* START TIME */}
                    <ReactDatePicker
                      locale={currentLocale}
                      minDate={new Date()}
                      maxDate={new Date().getTime() + 1051200 * 60000}
                      className="frmdatePick"
                      selected={startDate}
                      onChange={(selectedDate) =>
                        onChangeStartDate(selectedDate)
                      }
                      dateFormat="dd-MMM-yyyy"
                    />
                  </div>
                  <div className="frmTimeInput">
                    <Select
                      value={fromTime}
                      onChange={(event) =>
                        onChangeStartTime(event.target.value)
                      }
                      className="slctFrmTime"
                      sx={{ maxWidth: "115px !important" }}
                    >
                      {fromTimeList.map((fromTime) => {
                        return <MenuItem value={fromTime}>{fromTime}</MenuItem>;
                      })}
                    </Select>
                  </div>
                </div>

                <span className="spanElement">{t("to")}</span>

                <div
                  className="toDateTime"
                  style={{
                    flexWrap: "wrap",
                    justifyContent: "center !important",
                  }}
                >
                  <div className="frmDateInput">
                    <div className="frmDaysPick">{endDay},</div>
                    {/* END TIME */}
                    <ReactDatePicker
                      locale={currentLocale}
                      minDate={startDate}
                      maxDate={add24HoursToDate(startDate)}
                      className="frmdatePick"
                      selected={endDate}
                      onChange={(selectedDate) => onChangeEndDate(selectedDate)}
                      dateFormat="dd-MMM-yyyy"
                    />
                  </div>
                  <div className="toTimeInput">
                    <Select
                      value={toTime}
                      onChange={(event) => onChangeEndTime(event.target.value)}
                      className="slctFrmTime"
                      sx={{ maxWidth: "115px !important" }}
                    >
                      {toTimeList.map((toTime) => {
                        return <MenuItem value={toTime}>{toTime}</MenuItem>;
                      })}
                    </Select>
                  </div>
                </div>
              </div>
            </div>

            {rescheduleReason != null && (
              <div className="medtTitleDateTime">
                <input
                  type="text"
                  className="mtTextField reschedule-reason"
                  placeholder={t("reschedule_reason")}
                  value={rescheduleReason}
                  onChange={(e) => setRescheduleReason(e.target.value)}
                  style={{
                    width: `${rescheduleReason.length}ch`,
                  }}
                />
              </div>
            )}

            {/* {rescheduleReason != null &&
              selectedEvent.eventType === "Recurring Event" && (
                <div class="meetEdAgendaElement" style={{ padding: "5px" }}>
                  <div class="metAgendaElementTitle">
                    <input
                      style={{ width: "16px", height: "16px" }}
                      type="checkbox"
                      ref={buttonApplyForAllRecReschedule}
                    />
                    <span style={{ fontSize: "17px" }}>
                      Reschedule all related recurring events
                    </span>
                  </div>
                </div>
              )} */}

            {!editMode && (
              <div className="meetEdAgendaElement">
                <div class="metAgendaElementTitle">
                  <span>{t("agenda")}</span>
                </div>
                <div class="metAgendaElementEditedGrp">
                  <div className="metAgendaListed">
                    <div className="metAgendaName">
                      <Tooltip
                        title="Lorem ipsum dolor sit amet.. Lorem ipsum dolor sit amet.."
                        arrow
                      >
                        <span className="metAgendaNM">
                          Lorem ipsum dolor sit amet Lorem ipsum dolor sit
                          amet..
                        </span>
                      </Tooltip>
                    </div>
                    <div className="metAgndFilsgrp">
                      <div className="metAgndFil">
                        <Tooltip
                          title="Requisition-form.doc"
                          arrow
                          className="metFileTolTip"
                        >
                          <span className="metAgenFilIoc">
                            <DescriptionOutlined />
                          </span>
                          <span className="metAgenFilNm">
                            Requisition-form.doc
                          </span>
                        </Tooltip>
                      </div>
                      <div className="metAgndFil">
                        <Tooltip
                          title="Finance-doc.doc"
                          arrow
                          className="metFileTolTip"
                        >
                          <span className="metAgenFilIoc">
                            <DescriptionOutlined />
                          </span>
                          <span className="metAgenFilNm">Finance-doc.doc</span>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="metAgnAddMrFiles">
                      <Button className="metAgendaMrFileBtn" variant="outlined">
                        <Plus />
                      </Button>
                    </div>
                  </div>
                  <div className="metAgendaListed">
                    <div className="metAgendaName">
                      <Tooltip
                        title="Lorem ipsum dolor sit amet.. Lorem ipsum dolor sit amet.."
                        arrow
                      >
                        <span className="metAgendaNM">
                          Lorem ipsum dolor sit amet Lorem ipsum dolor sit
                          amet..
                        </span>
                      </Tooltip>
                    </div>
                    <div className="metAgndFilsgrp">
                      <div className="metAgndFil">
                        <Tooltip
                          title="Requisition-form.doc"
                          arrow
                          className="metFileTolTip"
                        >
                          <span className="metAgenFilIoc">
                            <DescriptionOutlined />
                          </span>
                          <span className="metAgenFilNm">
                            Requisition-form.doc
                          </span>
                        </Tooltip>
                      </div>
                      <div className="metAgndFil">
                        <Tooltip
                          title="Finance-doc.doc"
                          arrow
                          className="metFileTolTip"
                        >
                          <span className="metAgenFilIoc">
                            <DescriptionOutlined />
                          </span>
                          <span className="metAgenFilNm">Finance-doc.doc</span>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="metAgnAddMrFiles">
                      <Button className="metAgendaMrFileBtn" variant="outlined">
                        <Plus />
                      </Button>
                    </div>
                  </div>
                  <div className="metAgendaListed">
                    <div className="metAgendaName">
                      <Tooltip
                        title="Lorem ipsum dolor sit amet.. Lorem ipsum dolor sit amet.."
                        arrow
                      >
                        <span className="metAgendaNM">
                          Lorem ipsum dolor sit amet Lorem ipsum dolor sit
                          amet..
                        </span>
                      </Tooltip>
                    </div>
                    <div className="metAgndFilsgrp">
                      <div className="metAgndFil">
                        <Tooltip
                          title="Requisition-form.doc"
                          arrow
                          className="metFileTolTip"
                        >
                          <span className="metAgenFilIoc">
                            <DescriptionOutlined />
                          </span>
                          <span className="metAgenFilNm">
                            Requisition-form.doc
                          </span>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="metAgnAddMrFiles">
                      <Button className="metAgendaMrFileBtn" variant="outlined">
                        <Plus />
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="metAddMRAgendaBtn">
                  <Button variant="outlined" onClick={handelOpenAgenda}>
                    Add Agenda
                  </Button>
                  <Button variant="outlined" onClick={handleOpen}>
                    Add Agenda
                  </Button>
                </div>
              </div>
            )}

            {!editMode && (
              <div class="meetEDDesElement">
                <div class="metDtlElementTitle">
                  <span>Description</span>
                </div>
                <div class="metEDDtlElementDtil">
                  <span>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text.
                  </span>
                </div>
              </div>
            )}

            {!editMode && (
              <div className="meetEDDesElement">
                <div class="metDtlElementTitle">
                  <span>Location</span>
                </div>
                <div class="metEDDtlElementDtil">
                  <span>
                    10-F, SP Mukherjee Road, Kolkata, West Bengal 700026
                  </span>
                </div>
              </div>
            )}
            {selectedEvent.eventSource !== "google" && (
              <div className="meetGustDtlElement">
                <div className="metGustDtlElementTitle">
                  <span>Add Guests</span>
                </div>
                <div className="meetingAddGuestGrup">
                  {/* <Button>Add Guests</Button> */}
                  <div className="searchGustAC">
                    <Autocomplete
                      noOptionsText="Please type to search"
                      id="participantSelect"
                      sx={{
                        width: 300,
                        backgroundColor: "#333",
                        borderRadius: "10px",
                        color: "white",
                      }}
                      value={selectedGuest}
                      onChange={(event, selectedValue) => {
                        console.log("newValue", selectedValue);
                        setMeetingParticipants((prevList) => [
                          ...prevList,
                          {
                            id: null,
                            userId: selectedValue.userId,
                            userName: selectedValue.userName,
                            profileImage: selectedValue.profileUrl,
                            convener: "",
                            chairperson: "",
                            outSide: selectedValue.userType === "I" ? "N" : "Y",
                            calledBy: "N",
                            orgId: loginUser.orgId,
                          },
                        ]);
                        setSelectedGuest(null);
                        setSelectedGuestInput("");
                        // }
                      }}
                      inputValue={selectedGuestInput}
                      onInputChange={(event, newInputValue) => {
                        setSelectedGuestInput(newInputValue);
                      }}
                      options={searchUserFilteredList}
                      autoHighlight
                      getOptionLabel={(option) => option.userName}
                      getOptionDisabled={(option) =>
                        meetingParticipants.some(
                          (userDto) => userDto.userId === option.userId
                        )
                      }
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          <img
                            loading="lazy"
                            width="20"
                            srcSet={`${option.profileUrl}`}
                            src={`${option.profileUrl}`}
                            alt=""
                          />
                          {option.userName}
                          {option.userType === "I" ? (
                            <span style={{ padding: "5px" }}>
                              <Tooltip
                                title={t("belongs_to_your_organization")}
                              >
                                {/* <BadgeCheck /> */}
                                <Verified />
                              </Tooltip>
                            </span>
                          ) : (
                            <></>
                          )}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          InputProps={
                            {
                              // style: { color: "white !important" },
                            }
                          }
                          {...params}

                          // label="Select a participant"

                          // inputProps={{
                          //   ...params.inputProps,
                          //   autoComplete: "new-password", // disable autocomplete and autofill
                          // }}
                        />
                      )}
                    />
                    {/* {selectedEvent.eventType === "Recurring Event" &&
                    selectedGuest != null && (
                      <div className="addParticipantBtn">
                        <Button
                          onClick={() => {
                            setMeetingParticipants((prevList) => [
                              ...prevList,
                              {
                                id: null,
                                userId: selectedGuest.userId,
                                userName: selectedGuest.userName,
                                profileImage: selectedGuest.profileUrl,
                                convener: "",
                                chairperson: "",
                                outSide:
                                  selectedGuest.userType === "I" ? "N" : "Y",
                                calledBy: "N",
                                orgId: loginUser.orgId,
                                applyForAllRecurringEvents:
                                  buttonApplyForAllRecAddParticipant.current
                                    ? buttonApplyForAllRecAddParticipant.current
                                        ?.checked
                                      ? "Y"
                                      : "N"
                                    : "N",
                              },
                            ]);
                            setSelectedGuest(null);
                            setSelectedGuestInput("");
                          }}
                        >
                          ADD
                        </Button>
                      </div>
                    )} */}
                  </div>

                  <div className="addGustNewMeetBtn">
                    <Tooltip title={t("add_a_new_guest")}>
                      <Button
                        startIcon={<Add />}
                        className="dfultPrimaryBtn"
                        onClick={handleNewContactButton}
                      >
                        {t("new_guest")}
                      </Button>
                    </Tooltip>
                    <Popover
                      id={popoverId}
                      open={openPopover}
                      anchorEl={anchorEl}
                      onClose={handleCloseNeCwontactButtonModal}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                    >
                      <div
                        className="popOverContain"
                        style={{ padding: 20, minWidth: "280px" }}
                      >
                        <div className="modelTitle">
                          {t("enter_user_details")}
                        </div>
                        <div
                          className="elementFormContainer"
                          style={{ padding: "20px 0px 10px 0px" }}
                        >
                          <FormControl className="formControl">
                            <TextField
                              required
                              className="modInputFieldGuestAdd"
                              label={t("user_name")}
                              variant="outlined"
                              value={userName}
                              onChange={handleUserNameChange}
                            />
                          </FormControl>

                          <FormControl className="formControl">
                            <TextField
                              required
                              className="modInputFieldGuestAdd"
                              label={t("user_email")}
                              variant="outlined"
                              value={userEmail}
                              onChange={handleUserEmailChange}
                            />
                          </FormControl>
                        </div>
                        <div className="modActionBtnGrp">
                          <Button
                            className="dfultPrimaryBtn"
                            onClick={handleFormSubmit}
                            disabled={loaderForGuest}
                          >
                            {loaderForGuest ? (
                              <CircularProgress size={24} color="inherit" />
                            ) : (
                              <>{t("add")}</>
                            )}
                          </Button>
                          <Button
                            className="dfultDarkSecondaryBtn"
                            onClick={handleCloseNeCwontactButtonModal}
                          >
                            {t("cancel")}
                          </Button>
                        </div>
                      </div>
                    </Popover>
                  </div>
                </div>
                <div className="metGustLstGrp">
                  {meetingParticipants.map((participantDto) => {
                    return (
                      <>
                        <div
                          className={`metGustLstInd`}
                          style={{ cursor: "unset" }}
                        >
                          <div className="metGstImg">
                            <img src={participantDto.profileImage} alt="" />
                            {/* <span className="metConVerd">
                            <CheckCircle />
                          </span> */}
                            {participantDto.availability === "Y" && (
                              <span
                                className="metConVerd"
                                title={t("available")}
                              >
                                <CheckCircle />
                              </span>
                            )}
                            {participantDto.availability === "N" && (
                              <span
                                className="metConRej"
                                title={t("unavailable")}
                              >
                                <CancelRounded />
                              </span>
                            )}
                          </div>
                          <div className="metGstName">
                            {participantDto.userName}
                            {participantDto.outSide === "N" ? (
                              <span style={{ padding: "5px" }}>
                                <Tooltip
                                  title={t("belongs_to_your_organization")}
                                >
                                  {/* <BadgeCheck /> */}
                                  <Verified />
                                </Tooltip>
                              </span>
                            ) : (
                              <></>
                            )}

                            {participantDto.convener === "Y" && (
                              <span className="metConvenor">
                                ({t("convener")})
                              </span>
                            )}
                            {participantDto.chairperson === "Y" && (
                              <span className="metConvenor">
                                ({t("chairperson")})
                              </span>
                            )}
                          </div>
                          {console.log("participantDto", participantDto)}
                          {participantDto.userId &&
                            participantDto.convener !== "Y" && (
                              <div className="mkAsConvenorBtnGrp">
                                <Button
                                  onClick={() => {
                                    if (participantDto.availability === "Y") {
                                      makeConvenor(participantDto);
                                    } else {
                                      // toast.error(
                                      //   participantDto.userName +
                                      //     " is not available for the meeting, unable to transfer convenor rights"
                                      // );
                                      toast.error(
                                        t("participantUnavailable", {
                                          userName: participantDto.userName,
                                        }),
                                        {
                                          position: toast.POSITION.TOP_RIGHT,
                                        }
                                      );
                                    }
                                  }}
                                >
                                  {t("make_convener")}
                                </Button>
                              </div>
                            )}
                          {participantDto.userId &&
                            participantDto.chairperson !== "Y" &&
                            MEETING_TYPES_WITH_CHAIRPERSON.includes(
                              selectedEvent.eventType
                            ) && (
                              <div className="mkAsConvenorBtnGrp">
                                <Button
                                  onClick={() => {
                                    if (participantDto.availability === "Y") {
                                      makeChairperson(participantDto);
                                    } else {
                                      // toast.error(
                                      //   participantDto.userName +
                                      //     " is not available for the meeting, unable to transfer chairperson rights"
                                      // );
                                      toast.error(
                                        t("participantUnavailableChairperson", {
                                          userName: participantDto.userName,
                                        }),
                                        {
                                          position: toast.POSITION.TOP_RIGHT,
                                        }
                                      );
                                    }
                                  }}
                                >
                                  {t("make_chairperson")}
                                </Button>
                              </div>
                            )}
                          {(participantDto.convener === "N" ||
                            participantDto.convener === "") &&
                            (participantDto.chairperson === "N" ||
                              participantDto.chairperson === "") && (
                              <div className="mkAsConvenorBtnGrp">
                                <IconButton
                                  aria-describedby={idRP}
                                  onClick={(event) => {
                                    handleClickRP(event);
                                    setSelectedParticipant(participantDto);
                                  }}
                                >
                                  <Trash />
                                </IconButton>
                                <Popover
                                  id={idRP}
                                  open={openRP}
                                  anchorEl={anchorElRP}
                                  onClose={handleCloseRP}
                                  anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                  }}
                                  transformOrigin={{
                                    vertical: "center",
                                    horizontal: "right",
                                  }}
                                >
                                  <div style={{ padding: "20px" }}>
                                    <div className="modelTitle">
                                      {t("confirmation")}
                                    </div>
                                    <div
                                      className="modInputFild"
                                      style={{ padding: "20px 10px 10px 0px" }}
                                    >
                                      {/* Remove {selectedParticipant?.userName}{" "}
                                      from guest list? */}
                                      {t("removeFromGuestList", {
                                        userName: selectedParticipant?.userName,
                                      })}
                                    </div>

                                    {selectedEvent.eventType ===
                                      "Recurring Event" && (
                                      <div
                                        className="modActionBtnGrp"
                                        style={{ padding: "5px 0px 5px 0px" }}
                                      >
                                        <input
                                          type="checkbox"
                                          ref={
                                            buttonApplyForAllRecRemoveParticipant
                                          }
                                        />
                                        {t("remove_for_all_recurring_events")}
                                      </div>
                                    )}

                                    <div className="modActionBtnGrp">
                                      <Button
                                        className="dfultPrimaryBtn"
                                        onClick={() => removeParticipant()}
                                        ref={buttonRPRef}
                                      >
                                        {t("remove")}
                                      </Button>
                                      <Button
                                        className="dfultDarkSecondaryBtn"
                                        onClick={handleCloseRP}
                                        ref={buttonRPCancelRef}
                                      >
                                        {t("cancel")}
                                      </Button>
                                    </div>
                                    {/* <div
                                  className="modActionBtnGrp"
                                  style={{ marginTop: "5px" }}
                                >
                                  <Button
                                    className="dfultPrimaryBtn"
                                    onClick={() => removeParticipant("Y")}
                                    // ref={buttonRefRP}
                                  >
                                    Remove from all Recurring Events
                                  </Button>
                                </div> */}
                                  </div>
                                </Popover>
                              </div>
                            )}
                        </div>
                      </>
                    );
                  })}
                  {/* <div className="metGustLstInd gustAsConvenor">
                <div className="metGstImg">
                  <img src={userImg1} alt="" />
                  <span className="metConVerd">
                    <CheckCircle />
                  </span>
                </div>
                <div className="metGstName">
                  Aiyasha Hasan<span className="metConvenor">(Convenor)</span>
                </div>
                <div className="mkAsConvenorBtnGrp">
                  <Button>Make Convenor</Button>
                </div>
              </div> */}
                  {/*
              <div className="metGustLstInd">
                <div className="metGstImg">
                  <img src={userImg2} alt="" />
                  <span className="metConVerd">
                    <CheckCircle />
                  </span>
                </div>
                <div className="metGstName">
                  Sanket Santra<span className="metConvenor">(Convenor)</span>
                </div>
                <div className="mkAsConvenorBtnGrp">
                  <Button>Make Convenor</Button>
                </div>
              </div>

              <div className="metGustLstInd">
                <div className="metGstImg">
                  <img src={userImg3} alt="" />
                  <span className="metConVerd">
                    <CheckCircle />
                  </span>
                </div>
                <div className="metGstName">
                  Prashant Saha<span className="metConvenor">(Convenor)</span>
                </div>
                <div className="mkAsConvenorBtnGrp">
                  <Button>Make Convenor</Button>
                </div>
              </div>

              <div className="metGustLstInd">
                <div className="metGstImg">
                  <img src={userImg4} alt="" />
                  <span className="metConVerd">
                    <CheckCircle />
                  </span>
                </div>
                <div className="metGstName">
                  Sk. Aftabuddin<span className="metConvenor">(Convenor)</span>
                </div>
                <div className="mkAsConvenorBtnGrp">
                  <Button>Make Convenor</Button>
                </div>
              </div> */}
                  {selectedEvent.eventType === "Recurring Event" &&
                    meetingParticipants.some((obj) => obj.id === null) && (
                      <>
                        <div
                          className="modActionBtnGrp"
                          style={{ padding: "10px 0px 10px 0px" }}
                        >
                          <input
                            style={{ width: "16px", height: "16px" }}
                            type="checkbox"
                            ref={buttonApplyForAllRecAddParticipant}
                          />
                          <span style={{ fontSize: "17px", color: "white" }}>
                            {t(
                              "add_new_participants_for_all_related_recurring_events"
                            )}
                          </span>
                        </div>
                        <div
                          className="modActionBtnGrp"
                          style={{ padding: "2px 0px 2px 0px" }}
                        >
                          <span style={{ fontSize: "10px", color: "red" }}>
                            *
                            {t(
                              "convenor_chairperson_needs_to_be_changed_individually_for_each_recurring_event"
                            )}
                          </span>
                        </div>
                      </>
                    )}
                </div>
              </div>
            )}

            {false && selectedEvent.eventSource !== "google" && (
              <div class="meetEdAgendaElement">
                <div class="metAgendaElementTitle">
                  <span>{t("private_notes")}</span>
                </div>
                <div class="metAgendaElementEditedGrp">
                  {userPrivateNotes.map((userPvtNoteDto) => {
                    return (
                      <>
                        {" "}
                        <div className="metAgendaListed">
                          <div className="metAgendaName">
                            <Tooltip title={`${userPvtNoteDto.notes}`} arrow>
                              <span
                                className="metAgendaNM"
                                style={{ maxWidth: "unset" }}
                              >
                                {userPvtNoteDto.notes}
                              </span>
                            </Tooltip>
                          </div>
                          <div className="metAgnAddMrFiles">
                            <Button
                              className="metAgendaMrFileBtn"
                              variant="outlined"
                              onClick={(event) =>
                                onClickDelPvtNote(event, userPvtNoteDto)
                              }
                            >
                              <Trash />
                            </Button>
                          </div>
                        </div>
                      </>
                    );
                  })}
                  <Button variant="outlined" onClick={handleClickPN}>
                    {t("add_private_notes")}
                  </Button>
                  <Popover
                    id={idPN}
                    open={openPN}
                    anchorEl={anchorElPN}
                    onClose={handleClosePN}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <div style={{ padding: "20px" }}>
                      <div className="modelTitle" style={{ padding: "5px" }}>
                        {t("add_private_notes")}
                      </div>
                      <div
                        className="modInputFild"
                        style={{ padding: "10px 0px 10px 0px" }}
                      >
                        <input
                          value={pvtNote}
                          onChange={(event) => setPvtNote(event.target.value)}
                          type="text"
                          placeholder={t("type_here")}
                          ref={inputPNRef}
                        />
                      </div>

                      <div className="modActionBtnGrp">
                        <Button
                          className="dfultPrimaryBtn"
                          onClick={() => onClickAddPvtNote()}
                          ref={addPNRef}
                        >
                          {t("add")}
                        </Button>
                        <Button
                          className="dfultDarkSecondaryBtn"
                          onClick={handleClosePN}
                          ref={cancelPNRef}
                        >
                          {t("cancel")}
                        </Button>
                      </div>
                    </div>
                  </Popover>
                </div>
              </div>
            )}

            {/* <div className="meetAddNoteBTn">
            <Button startIcon={<Sticky />}>Add Notes</Button>
          </div>

          <div className="meetMOMBtn">
            <Button startIcon={<NoteAltOutlined />}>
              Minutes of the Meeting
            </Button>
          </div> */}
            {/* 
            {selectedEvent.eventType === "Recurring Event" && (
              <div class="meetEdAgendaElement" style={{ padding: "5px" }}>
                <div class="metAgendaElementTitle">
                  <input
                    style={{ width: "16px", height: "16px" }}
                    type="checkbox"
                    ref={buttonApplyForAllRec}
                  />
                  <span style={{ fontSize: "17px" }}>
                    Apply Changes for all recurring events
                  </span>
                </div>
              </div>
            )} */}
          </div>
        )}
        {loading && (
          <div className="center-progress" style={{ height: "65vh" }}>
            <CircularProgress sx={{ marginTop: "180px" }} />
          </div>
        )}
        {/* {openAddPvtNote && (
          <div className="addMoreAgendaModel">
            <div className="modelTitle">Add Private Note</div>
            <div className="modInputFild">
              <input
                value={pvtNote}
                onChange={(event) => setPvtNote(event.target.value)}
                type="text"
                placeholder="Type Here...."
                ref={inputPNRef}
              />
            </div>
            <div className="modAtchFileBtn">
              <label class="btn-up">
                <input type="file" hidden />
                Attach File
              </label>
            </div>
            <div className="modActionBtnGrp">
              <Button
                className="dfultPrimaryBtn"
                onClick={() => onClickAddPvtNote()}
                ref={addPNRef}
              >
                Add
              </Button>
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={handleClosePvtNote}
                ref={cancelPNRef}
              >
                Cancel
              </Button>
            </div>
          </div>
        )} */}

        {/* <div style={{ padding: "20px" }}>
          <div className="modelTitle" style={{ padding: "5px" }}>
            <span> </span>
          </div>
        </div> */}

        {/* {editMode && (
          <div className="metngVEFooterPnl">
            <Button
              className="dfultPrimaryBtn"
              onClick={() => saveChangesMade()}
              ref={saveBtnRef}
            >
              Save Changes
            </Button>
          </div>
        )} */}
        <Modal open={open} onClose={handleClose}>
          <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
            <div className="addMoreAgendaModel">
              <div className="modelTitle">{t("add_agenda")}</div>
              <div className="modInputFild">
                <input type="text" placeholder="Add Agenda" />
              </div>
              <div className="modAtchFileBtn">
                <label class="btn-up">
                  <input type="file" hidden />
                  {t("attach_file")}
                </label>
              </div>
              <div className="modActionBtnGrp">
                <Button className="dfultPrimaryBtn">Save</Button>
                <Button className="dfultDarkSecondaryBtn" onClick={handleClose}>
                  {t("cancel")}
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
}
