import { ArrowBack, ReceiptLong, Search } from "@mui/icons-material";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Receipt } from "react-bootstrap-icons";
import { getPayrollEmployees } from "../../../../services/AdminService";
import { GetLoginUserDetails } from "../../../../utils/Utils";
import { checkOtp } from "../../../../utils/OtpServiceUtils";
import { useTranslation } from "react-i18next";

export default function ViewPayroll({
  handelOpenDetailPayroll,
  handelCloseViewPayroll,
  handelOpenPayrollPayslip,
  closeAll,
  refreshEmployPayroll,
}) {
  const userDetails = GetLoginUserDetails();
  const { t } = useTranslation();

  const [payrollDataOfOrg, setPayrollDataOfOrg] = useState([]);
  const [filterPayrollDataOfOrg, setFilterPayrollDataOfOrg] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    fetchPayrollData();
  }, [refreshEmployPayroll]);

  // const fetchPayrollData = () => {
  //   getPayrollEmployees(userDetails.orgId)
  //     .then((response) => {
  //       console.log("fetchPayrollData", response.data);
  //       setPayrollDataOfOrg(response.data);
  //       setFilterPayrollDataOfOrg(response.data);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching units", error);
  //       setLoading(false);
  //     });
  // };

  const fetchPayrollData = async () => {
    try {
      const { otp, requestId } = await checkOtp("/payroll/getPayrollEmployees");
      console.log("getPayrollEmployeesOTP ------->>>>", otp, requestId);

      const reqDto = {
        requestId: requestId,
        otp: otp,
        orgId: userDetails.orgId,
      };

      if (reqDto) {
        const response = await getPayrollEmployees(reqDto);
        console.log("fetchPayrollData", response.data);

        setPayrollDataOfOrg(response.data);
        setFilterPayrollDataOfOrg(response.data);
      }
    } catch (error) {
      console.error("Error fetching payroll data", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const filteredData = payrollDataOfOrg.filter(
      (payroll) =>
        payroll.personName &&
        payroll.personName.toLowerCase().includes(query.toLowerCase())
    );

    setFilterPayrollDataOfOrg(filteredData);
  };

  return (
    <>
      <div className="AnalyticsDashboardContainer">
        <div className="AnalyticsDashboardSubHeader">
          <IconButton
            onClick={() => {
              handelCloseViewPayroll();
              closeAll();
            }}
          >
            <ArrowBack />
          </IconButton>
          {t("employee_payroll")}
        </div>
        <div className="anDashCardContainArea">
          <div className="fullContainArea">
            <div className="cuHeaderGrp">
              {/* <div className="cuhederTxt">Departmment List</div> */}
              <div className="searchTasklist">
                <input
                  type="text"
                  class="serchTaskInput"
                  placeholder={t("search_employee")}
                  value={searchQuery}
                  onChange={handleSearch}
                />
                <IconButton className="seacrhIcon">
                  <Search />
                </IconButton>
              </div>
            </div>

            <div className="cuContainArea">
              {/* <div class="noDataCard ">
                  <span>Oops!!</span>No Employee Define yet !!
                  <br />
                  <span className="sallMsg">
                    Click Add Employee Button to create new Employee
                  </span>
                </div> */}

              {loading ? (
                <div className="center-progress" style={{ height: "65vh" }}>
                  <CircularProgress sx={{ marginTop: "180px" }} />
                </div>
              ) : payrollDataOfOrg.length === 0 ? (
                <div class="noDataCard ">
                  <span>{t("oops")}</span>
                  {t("no_employee_payroll_added_yet")}
                  <br />
                </div>
              ) : (
                <div className="TableContainer">
                  <table className="taskListTable">
                    <thead className="taskListtableHeader">
                      <tr>
                        <th>{t("employee_name")}</th>
                        <th>{t("id")}</th>
                        <th>{t("position")}</th>
                        <th>{t("salary_type")}</th>
                        <th>{t("employement_status")}</th>
                        <th>{t("ctc")}</th>
                        <th className="width100">{t("action")}</th>
                      </tr>
                    </thead>
                    {/* <tbody className="scrolableTableBody">
                    <tr>
                      <td>
                        <div className="tskLstTaskNM">Sanket Santra</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">MT0005</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">Sr. UI/UX Designer</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">Full Time</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">Confirmed</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">7.58 LPA</div>
                      </td>

                      <td>
                        <div className="tblActionBtnGrp">
                          <Tooltip title="Salary Structure" arrow>
                            <IconButton
                              onClick={() => handelOpenDetailPayroll()}
                            >
                              <Receipt />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Pay Slip" arrow>
                            <IconButton
                              onClick={() => handelOpenPayrollPayslip()}
                            >
                              <ReceiptLong />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  </tbody> */}
                    <tbody className="scrolableTableBody">
                      {filterPayrollDataOfOrg.map((employee, index) => (
                        <tr key={index}>
                          <td>
                            {" "}
                            <div className="tskLstTaskNM">
                              {employee.personName}
                            </div>
                          </td>
                          <td>
                            {" "}
                            <div className="tskLstTaskNM">
                              {employee.employeeId}
                            </div>
                          </td>
                          <td>
                            {" "}
                            <div className="tskLstTaskNM">
                              {employee.positionName}
                            </div>
                          </td>
                          <td>
                            {" "}
                            <div className="tskLstTaskNM">
                              {employee.employmentType}
                            </div>
                          </td>
                          <td>
                            {" "}
                            <div className="tskLstTaskNM">
                              {employee.empStatus}
                            </div>
                          </td>
                          <td>
                            {" "}
                            <div className="tskLstTaskNM">
                              {employee.currentCTC}
                            </div>
                          </td>
                          <td>
                            <div className="tblActionBtnGrp">
                              <Tooltip title={t("salary_structure")} arrow>
                                <IconButton
                                  onClick={() =>
                                    handelOpenDetailPayroll(employee)
                                  }
                                >
                                  <Receipt />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title={t("pay_slip")} arrow>
                                <IconButton
                                  onClick={() => handelOpenPayrollPayslip()}
                                >
                                  <ReceiptLong />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
