import { io } from "socket.io-client";
import { socketEndPoint } from "./constants/url_provider";
import { getLoginUserDetailsWithoutContext } from "./utils/Utils";

const socket = io(socketEndPoint);
const dashboardSocket = io(`${socketEndPoint}/dashboard`);
const chatSocket = io(`${socketEndPoint}/main-chat`);
const mailSocket = io(`${socketEndPoint}/mail`);
const meetingSocket = io(`${socketEndPoint}/meeting`);
const schedulerSocket = io(`${socketEndPoint}/scheduler`);
const driveSocket = io(`${socketEndPoint}/drive`);
const doctorSocket = io(`${socketEndPoint}/doctor`);
const centerSocket = io(`${socketEndPoint}/center`);
const supportSocket = io(`${socketEndPoint}/support`);
const staticDataSocket = io(`${socketEndPoint}/static`);

const setUpSocket = async () => {
  const loginUser = await getLoginUserDetailsWithoutContext();
  if (loginUser != null) {
    dashboardSocket.emit("joinDashboardUser", { userId: loginUser.userId });
    chatSocket.emit("joinChatUser", { userId: loginUser.userId });
    mailSocket.emit("joinMailUser", { userId: loginUser.userId });
    meetingSocket.emit("joinMeetingUser", { userId: loginUser.userId });
    schedulerSocket.emit("joinSchedulerUser", { userId: loginUser.userId });
    driveSocket.emit("joinDriveUser", { userId: loginUser.userId });
    supportSocket.emit("joinSupportUser", { userId: loginUser.userId });
    staticDataSocket.emit("join");
    // staticDataSocket.emit("requestData", {
    //   orgId: loginUser.orgId,
    //   countryCode: loginUser.orgCountryCode,
    // });
    // staticDataSocket.on("responseData", (obj) => {
    //   console.log("staticDataSocket ", obj);
    // });

    if (
      ["AHL000000001299", "AHL000000000842"].includes(loginUser.userId) ||
      loginUser.zhUserType === "DOCTOR"
    ) {
      doctorSocket.emit("join", {
        userId: loginUser.userId,
        orgId: loginUser.orgId,
        // unitId: loginUser.signleUnitId,
      });
      // doctorSocket.on("disconnect", () => {
      //   // toast.error("Doctor Socket disconnected");
      //   doctorSocket.emit("join", {
      //     userId: loginUser.userId,
      //     orgId: loginUser.orgId,
      //     unitId: loginUser.signleUnitId,
      //   });
      // });
    }
    if (
      ["AHL000000000603"].includes(loginUser.userId) ||
      loginUser.zhUserType === "NURSE" ||
      loginUser.zhUserType === "LT"
    ) {
      centerSocket.emit("join", {
        userId: loginUser.userId,
        unitId: loginUser.signleUnitId,
      });

      // centerSocket.on("disconnect", () => {
      //   // toast.error("Client Socket disconnected");
      //   centerSocket.emit("join", {
      //     userId: loginUser.userId,
      //     unitId: loginUser.signleUnitId,
      //   });
      // });
    }
  }
};

try {
  setTimeout(() => {
    setUpSocket();
  }, 10000);
} catch (error) {
  console.error("setUpSocket", error);
}

export {
  socket,
  dashboardSocket,
  chatSocket,
  mailSocket,
  meetingSocket,
  schedulerSocket,
  driveSocket,
  doctorSocket,
  centerSocket,
  supportSocket,
  staticDataSocket,
};
