import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import PaymentService from "../../../../services/PaymentService";
import { toast, ToastContainer } from "react-toastify";
// import DriveService from "../../services/DriveService";
import { upgradeStorage } from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import { GetLoginUserDetails } from "../../../../utils/Utils";
import { useTranslation } from "react-i18next";

export default function CheckoutForm(props) {
  const { t } = useTranslation();
  const userDetails = GetLoginUserDetails();
  const stripe = useStripe();
  const elements = useElements();

  const [email, setEmail] = useState(userDetails.userEmailId);
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const response = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        //return_url: "http://localhost:8080/ahlanapi/payment/stripe/returnUrl",
      },
      redirect: "if_required",
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    console.log("stripe response", response);
    if (response.error) {
      setMessage(response.error.message);
    } else {
      setMessage(`Payment Succeeded`);
      toast.success(t("payment_successful"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      let reqDto = {
        paymentStatus: response.paymentIntent.status,
        invoiceId: 0,
        paymentMethodId: response.paymentIntent.payment_method,
        amount: props.amtToPay
          ? props.amtToPay
          : response.paymentIntent.amount / 100,
        paymentIntentId: response.paymentIntent.id,
        paymentResponseObject: JSON.stringify(response.paymentIntent),
      };

      PaymentService.capturePayment(
        userDetails.orgId,
        userDetails.userId,
        reqDto
      ).then((response) => {
        console.log("capturePayment", response.data);
        console.log("capturePayment", props.itemHeader);
        switch (props.itemHeader) {
          case "SUBSCRIPTION":
            PaymentService.addSubscription(
              props.itemId,
              userDetails.orgId,
              userDetails.userId
            ).then((response) => {
              console.log("addSubscription", response.data);
              try {
                if (props.coupon) {
                  let reqDto = {
                    couponCode: props.coupon.couponCode,
                    amountBeforeDiscount:
                      props.selectedEdition.currency +
                      " " +
                      props.selectedEdition.firstTimeActivationPrice,
                    amountAfterDiscount: props.selectedEdition.currency + " 0",
                    transId: "",
                    transType: "ORGANIZATION_ONBOARD",
                  };
                  PaymentService.couponUsed(reqDto, userDetails.userId).then(
                    (response) => {
                      console.log(response.data);
                    }
                  );
                }
              } catch (err) {
                console.log(err);
              }
              //   props.handleNext();
            });
            break;
          case "STORAGE":
            let reqDto = {
              storage: props.itemUnits,
            };
            upgradeStorage(reqDto, userDetails.userId, userDetails.orgId).then(
              (response) => {
                console.log("upgradeStorage", response.data);
                props.onSuccess();
                props.handlePaymentModal();
                props.handleCloseCreateOrgStorage();
              }
            );
            break;
          default:
            break;
        }
      });
    }
    // if (error.type === "card_error" || error.type === "validation_error") {
    //   setMessage(error.message);
    // } else {
    //   setMessage("An unexpected error occurred.");
    // }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      {/* {console.log(email)}
      <LinkAuthenticationElement
        id="link-authentication-element"
        onChange={(e) => setEmail(e.target.value)}
        value={email}
        onReady={(e) => setEmail(userDetails.userEmailId)}
      /> */}
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <button
        disabled={isLoading || !stripe || !elements}
        id="submit"
        className="payBtnStripe"
      >
        <span id="button-text">
          {isLoading ? (
            <div className="spinner-stripe" id="spinner"></div>
          ) : props.itemHeader === "SUBSCRIPTION" ? (
            `Pay ${props.currencySymbol + "" + props.amtToPay}`
          ) : (
            `Pay Now`
          )}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
